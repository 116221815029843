import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import styles from "../../home/modal_reclamo/modal.module.css";
import {useContext} from "react";
import DBContext from "../../../context/DBContext";
import LangContext from "../../../context/LangContext";


export const ModalTabInspector = ({show, onHide, claimForm, handleModal}) => {
    const {especie, setCambioEspecie, cambioEspecie} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);

    //encontrar la especie con la id que tengo
    //const especieEncontrada = especie.find((especie) => especie.id === claimForm.cargo.fruits.id);
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                // size={width > 768 ? "md" : "sm"}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {traduccion?.transport?.datos}<br/> {traduccion?.transport?.datos2}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {
                            claimForm?.transportId === 1 ? (
                                <>
                                    <Row>

                                        <Col xs={4} className={styles.columna}>
                                            <p className={styles.titulo}>{traduccion.maritime?.vessel}</p>
                                            <p>{claimForm?.vessel}</p>
                                        </Col>

                                        <Col xs={4} className={styles.columna}>
                                            <p className={styles.titulo}>BL/AWB</p>
                                            <p>{claimForm?.blAwb}</p>
                                        </Col>

                                        <Col xs={4} className={styles.columna}>
                                            <p className={styles.titulo}>{traduccion?.claim?.exporter}</p>
                                            <p>{claimForm?.exporterName}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} className={styles.columna}>
                                            <p className={styles.titulo}>POL</p>
                                            <p>{claimForm?.pol}</p>
                                        </Col>
                                        <Col xs={6} className={styles.columna}>
                                            <p className={styles.titulo}>POD</p>
                                            <p>{claimForm?.pod}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/*<Col xs={6} className={styles.columna}>*/}
                                        {/*    <p className={styles.titulo}>Shipper</p>*/}
                                        {/*    <p>{claimForm?.shipperName}</p>*/}
                                        {/*</Col>*/}
                                        <Col xs={6} className={styles.columna}>
                                            <p className={styles.titulo}>Consignee</p>
                                            <p>{claimForm?.consigneeName}</p>
                                        </Col>
                                        <Col xs={4} className={styles.columna}>
                                            <p className={styles.titulo}>Carrier</p>
                                            <p>{claimForm?.carrierName}</p>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col xs={4} className={styles.columna}>
                                            <p className={styles.titulo}>ATA</p>
                                            <p>{claimForm?.ata?.split('T')[0]}</p>
                                        </Col>
                                        <Col xs={4} className={styles.columna}>
                                            <p className={styles.titulo}>TimeBar</p>
                                            <p>{claimForm?.timeBar?.split('T')[0]}</p>
                                        </Col>


                                        <Col xs={12} className={styles.columna}>
                                            <p style={{display: 'flex', justifyContent: 'space-between'}}
                                               className={styles.titulo}>Fruits</p>
                                            {
                                                claimForm?.cargo?.fruits?.map((fruit, index) => (
                                                    <span style={{padding: '0 .8rem'}}
                                                          key={index}>{fruit?.name || especie.find((e) => e.id == fruit.id)?.description} - {fruit.boxes} </span>
                                                ))
                                            }
                                        </Col>
                                        <Row>
                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>Reclaimed Amount</p>
                                                <p>{claimForm?.cargo?.reclaimedAmount}</p>
                                            </Col>
                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>ETD</p>
                                                <p>{claimForm?.cargo?.etd?.split('T')[0]}</p>
                                            </Col>
                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>ETA</p>
                                                <p>{claimForm?.cargo?.eta?.split('T')[0]}</p>
                                            </Col>

                                        </Row>

                                    </Row>
                                </>
                            ) : (
                                claimForm?.transportId === 2 ? (
                                    <>
                                        <Row>
                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>BL/AWB</p>
                                                <p>{claimForm?.blAwb ? claimForm?.blAwb.replace('|||', ' ') : ''}</p>
                                            </Col>

                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>{traduccion?.claim?.exporter}</p>
                                                <p>{claimForm?.exporterName}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} className={styles.columna}>
                                                <p className={styles.titulo}>POL</p>
                                                <p>{claimForm?.pol}</p>
                                            </Col>
                                            <Col xs={6} className={styles.columna}>
                                                <p className={styles.titulo}>POD</p>
                                                <p>{claimForm?.pod}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {/*<Col xs={6} className={styles.columna}>*/}
                                            {/*    <p className={styles.titulo}>Shipper</p>*/}
                                            {/*    <p>{claimForm?.shipperName}</p>*/}
                                            {/*</Col>*/}
                                            <Col xs={6} className={styles.columna}>
                                                <p className={styles.titulo}>Consignee</p>
                                                <p>{claimForm?.consigneeName}</p>
                                            </Col>
                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>Carrier</p>
                                                <p>{claimForm?.vessel}</p>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>ATA</p>
                                                <p>{claimForm?.ata?.split('T')[0]}</p>
                                            </Col>
                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>TimeBar</p>
                                                <p>{claimForm?.timeBar?.split('T')[0]}</p>
                                            </Col>


                                            <Col xs={12} className={styles.columna}>
                                                <p style={{display: 'flex', justifyContent: 'space-between'}}
                                                   className={styles.titulo}>Fruits</p>
                                                {
                                                    claimForm?.cargo?.fruits?.map((fruit, index) => (
                                                        <span style={{padding: '0 .8rem'}}
                                                              key={index}>{fruit?.name || especie.find((e) => e.id == fruit.id)?.description} - {fruit.boxes} </span>
                                                    ))
                                                }
                                            </Col>
                                            <Row>
                                                <Col xs={4} className={styles.columna}>
                                                    <p className={styles.titulo}>Reclaimed Amount</p>
                                                    <p>{claimForm?.cargo?.reclaimedAmount}</p>
                                                </Col>
                                                <Col xs={4} className={styles.columna}>
                                                    <p className={styles.titulo}>ETD</p>
                                                    <p>{claimForm?.cargo?.etd?.split('T')[0]}</p>
                                                </Col>
                                                <Col xs={4} className={styles.columna}>
                                                    <p className={styles.titulo}>ETA</p>
                                                    <p>{claimForm?.cargo?.eta?.split('T')[0]}</p>
                                                </Col>

                                            </Row>

                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <Row>
                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>CRT</p>
                                                <p>{claimForm?.blAwb}</p>
                                            </Col>

                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>{traduccion?.claim?.exporter}</p>
                                                <p>{claimForm?.exporterName}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} className={styles.columna}>
                                                <p className={styles.titulo}>POL</p>
                                                <p>{claimForm?.pol}</p>
                                            </Col>
                                            <Col xs={6} className={styles.columna}>
                                                <p className={styles.titulo}>POD</p>
                                                <p>{claimForm?.pod}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {/*<Col xs={6} className={styles.columna}>*/}
                                            {/*    <p className={styles.titulo}>Shipper</p>*/}
                                            {/*    <p>{claimForm?.shipperName}</p>*/}
                                            {/*</Col>*/}
                                            <Col xs={6} className={styles.columna}>
                                                <p className={styles.titulo}>Consignee</p>
                                                <p>{claimForm?.consigneeName}</p>
                                            </Col>
                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>Carrier</p>
                                                <p>{claimForm?.carrierName}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>ATA</p>
                                                <p>{claimForm?.ata?.split('T')[0]}</p>
                                            </Col>
                                            <Col xs={4} className={styles.columna}>
                                                <p className={styles.titulo}>TimeBar</p>
                                                <p>{claimForm?.timeBar?.split('T')[0]}</p>
                                            </Col>

                                            <Col xs={12} className={styles.columna}>
                                                <p style={{display: 'flex', justifyContent: 'space-between'}}
                                                   className={styles.titulo}>Fruits</p>
                                                {
                                                    claimForm?.cargo?.fruits?.map((fruit, index) => (
                                                        <span style={{padding: '0 .8rem'}}
                                                              key={index}>{fruit?.name || especie.find((e) => e.id == fruit.id)?.description} - {fruit.boxes} </span>
                                                    ))
                                                }
                                            </Col>
                                            <Row>
                                                <Col xs={4} className={styles.columna}>
                                                    <p className={styles.titulo}>Reclaimed Amount</p>
                                                    <p>{claimForm?.cargo?.reclaimedAmount}</p>
                                                </Col>
                                                <Col xs={4} className={styles.columna}>
                                                    <p className={styles.titulo}>ETD</p>
                                                    <p>{claimForm?.cargo?.etd?.split('T')[0]}</p>
                                                </Col>
                                                <Col xs={4} className={styles.columna}>
                                                    <p className={styles.titulo}>ETA</p>
                                                    <p>{claimForm?.cargo?.eta?.split('T')[0]}</p>
                                                </Col>

                                            </Row>

                                        </Row>
                                    </>
                                )

                            )
                        }

                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Button variant={"warning"} onClick={onHide}>{traduccion?.transport?.editar}</Button>
                    </div>
                    <Button variant={"success"} onClick={handleModal}>{traduccion?.transport?.guardar}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
