import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
// import {BrowserRouter} from "react-router-dom";
import {DBProvider} from "./context/DBContext";
// import {Login} from "./pages/Login/Login";
import {Ruta} from "./pages/Login/Ruta";
import './styles/customBootstrap.scss';
import {LangProvider} from "./context/LangContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    // <React.StrictMode>
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
            <DBProvider>
                <LangProvider>
                {/*<BrowserRouter>*/}
                    {/*<Login/>*/}
                    {/*<App/>*/}

                    <Ruta/>
                {/*</BrowserRouter>*/}
                </LangProvider>
            </DBProvider>
        </DevSupport>
    // </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
