import stylesSurv from "../surveyors/new_surveyor.module.css";
import styles from "./profile.module.css"
import React, {useContext, useEffect, useState} from "react";
import {Col, Row, Form, Button} from "react-bootstrap";
import LangContext from "../../../context/LangContext";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import DBContext from "../../../context/DBContext";
import {toast} from "react-toastify";

export const NewProfile = ({oneProfile,}) => {
    const {traduccion} = useContext(LangContext);
    let {cambioProfile, setCambioProfiles, profiles} = useContext(DBContext);

    const [profileObj, setProfileObj] = useState({
        "name": "",
        "status": "",
        "modules": [],
        "alerts": [],
        "privileges": [],
        "dashboard": []
    });

    useEffect(() => {
        if (oneProfile !== "") setProfileObj({
            ...profileObj,
            "name": oneProfile.name,
            "status": oneProfile.status,
            "modules": oneProfile.modules ?? [],
            "alerts": oneProfile.alerts ?? [],
            "privileges": oneProfile.privileges ?? [],
            "dashboard": oneProfile.dashboard ?? []
        });
    }, [oneProfile]);

    const handleProfileA = (e, itemId) => {
        if (e.target.name === "status" || e.target.name === "name") {
            //console.log(e.target.value);
            setProfileObj({
                ...profileObj,
                [e.target.name]: e.target.value
            });

        } else {
            let lista = profileObj[e.target.name];
            //console.log(profileObj)
            //console.log(lista)
            if (lista.includes(itemId)) {
                lista = lista.filter((item) => item !== itemId);
            } else {
                lista.push(itemId);
            }
            setProfileObj({
                ...profileObj,
                [e.target.name]: lista
            });
        }

    }
    const handlePut = () => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }
        if (oneProfile?.id !== undefined && oneProfile?.id !== null) {
            axios.put(`${urlConfig.allUrl}${urlConfig.portAccount}/api/v1/accounts/profile/${oneProfile.id}`, profileObj, header)
                //axios.put(`${urlConfig.urlKite}${urlConfig.portAccount}/api/v1/accounts/profile/${oneProfile.id}`, profileObj, header)

                .then((response) => {
                    setCambioProfiles(!cambioProfile);
                    toast.success(`${traduccion?.success?.successActProf}`);
                })
                .catch((error) => {
                    //console.log(error)
                    toast.error(`${traduccion?.errores?.actProf}`);
                })
        } else {
            axios.post(`${urlConfig.allUrl}${urlConfig.portAccount}/api/v1/accounts/profile`, profileObj, header)
                .then((response) => {
                    setCambioProfiles(!cambioProfile);
                    toast.success(`${traduccion?.success?.successNewProf}`);
                })
                .catch((error) => {
                    //console.log(error)
                    toast.error(`${traduccion?.errores?.newProf}`);
                })
        }
    }

    const listaStatus = [
        {
            id: 1,
            nombre: "Activo"
        },
        {
            id: 2,
            nombre: "Inactivo"
        }];

    const listaTimelineStatus = [
        {
            id: 1,
            nombre: "New", estado: false
        },
        {
            id: 2,
            nombre: "Survey Report", estado: false
        },
        {
            id: 3,
            nombre: "Pending documents", estado: false
        },
        {
            id: 4,
            nombre: "Ready to Claim", estado: false
        },
        {
            id: 5,
            nombre: "Closed", estado: false
        },
        {
            id: 6,
            nombre: "Out of Court", estado: false
        },
        {
            id: 7,
            nombre: "In court", estado: false
        },
        {
            id: 8,
            nombre: "Recovered", estado: false
        },
        {
            id: 9,
            nombre: "Not Recovered", estado: false
        },
        {
            id: 10,
            nombre: "Signed release", estado: false
        }

    ];

    const listaModulos = [
        {
            id: 1,
            nombre: "Claims",
            estado: false
        },
        {
            id: 2,
            nombre: "Stuffing",
            estado: false
        },
        {
            id: 3,
            nombre: "Exporters",
            estado: false
        },
        {
            id: 4,
            nombre: "Surveyors",
            estado: false
        },
        {
            id: 5,
            nombre: "Companies",
            estado: false
        },
        {
            id: 6,
            nombre: "Invoiced",
            estado: false
        },
        {
            id: 7,
            nombre: "Damages",
            estado: false
        },
        {
            id: 8,
            nombre: "Fruits",
            estado: false
        },
        {
            id: 9,
            nombre: "Currencies",
            estado: false
        },
        {
            id: 10,
            nombre: "Users",
            estado: false
        },
        {
            id: 11,
            nombre: "Profiles",
            estado: false
        },
        {
            id: 12,
            nombre: "Countries",
            estado: false
        },


    ];

    const listaAlertas = [
        {
            id: 1,
            nombre: "All",
            estado: false
        },
        {
            id: 2,
            nombre: "New claim",
            estado: false
        },
        {
            id: 3,
            nombre: "Change of status",
            estado: false
        },
        {
            id: 4,
            nombre: "Time bar",
            estado: false
        },
        {
            id: 5,
            nombre: "No changes",
            estado: false
        },
        {
            id: 6,
            nombre: "New application",
            estado: false
        },
        {
            id: 7,
            nombre: "Survey done",
            estado: false
        }];

    const listaGraficas = [
        {
            id: 1,
            nombre: "Recoveries/Legal",
            estado: false
        },
        {
            id: 2,
            nombre: "Finance",
            estado: false
        },
        {
            id: 3,
            nombre: "Operations/Claims",
            estado: false
        },
        {
            id: 4,
            nombre: "Administration",
            estado: false
        },
        {
            id: 5,
            nombre: "Surveyors",
            estado: false
        },
    ]

    const profileObject = {
        profileName: "",
        profileStatus: "",
        profileModules: listaModulos,
        profileAlerts: listaAlertas,
        profileDashboard: listaGraficas
    }
    const [newProfile, setNewProfile] = useState(profileObject);

    const compareIfExist = (array, id) => array.some((item) => item === id);


    //console.log(profileObj)

    return (
        <>
            <div className={stylesSurv.title_surveyor} style={{marginBottom: 15}}>
                <div>{traduccion?.mantenedoresII?.nuevoPerfil}</div>
            </div>
            <div className={styles.grid_container_all}>
                <div className={styles.justBorders}>
                    <Form.Group>

                        <Form.Label htmlFor={"profileName"}>{traduccion?.mantenedoresII?.nombrePerfil}</Form.Label>
                        <Form.Control className={styles.selects_input_style} value={profileObj.name}
                                      onChange={handleProfileA} id={"profileName"} name={"name"} type="text"
                                      placeholder={traduccion?.mantenedoresII?.nombrePerfil}/>

                        <Form.Label style={{marginTop: '1rem'}}
                                    htmlFor={"profileStatus"}>{traduccion?.clients?.status}</Form.Label>
                        <Form.Select className={styles.selects_input_style} value={profileObj.status}
                                     onChange={handleProfileA} name="status" id={"profileStatus"}>
                            <option>{traduccion?.mantenedoresII?.selectStatus}</option>
                            {/*<option key={item.id} value={item.id}>{item.nombre}</option>*/}

                            {listaStatus.map((item, idx) => {
                                return (
                                    <option key={idx}
                                            value={item.id === 1}>{item.nombre === 'Activo' ? `${traduccion?.mantendores?.activo}` : `${traduccion?.mantendores?.inactivo}`}</option>
                                )
                            })}
                        </Form.Select>

                    </Form.Group>
                    <Button style={{padding: '0.5rem 2rem', marginTop: '.5rem'}} onClick={handlePut}>
                        {traduccion?.mantenedoresII?.save}
                    </Button>
                </div>
                <div className={styles.justBorders}>
                    <div className={stylesSurv.title}>{traduccion?.mantenedoresII?.modulos}</div>
                    <div style={{width: '100%'}}>
                        <Form.Group>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                {/*<div className={styles.grid_container}>*/}
                                {/*    <Form.Check key={1} type="checkbox" label={newProfile.profileModules[0].nombre}  />*/}
                                {/*</div>*/}
                                <div className={styles.grid_container}>

                                    {newProfile.profileModules.map((item, idx) => {
                                        return (
                                            <Form.Check key={idx} name={"modules"}
                                                        checked={compareIfExist(profileObj.modules, item.id)}
                                                        onChange={(e) => handleProfileA(e, item.id)} type="checkbox"
                                                        label={item.nombre}/>
                                        )
                                    })}
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div>

                </div>
                <div className={styles.justBorders}>
                    <div className={stylesSurv.title}>{traduccion?.mantenedoresII?.alertas}</div>
                    <div style={{width: '100%'}}>
                        <Form.Group>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                {/*<div className={styles.grid_container}>*/}
                                {/*    <Form.Check key={1} type="checkbox" label={newProfile.profileAlerts[0].nombre}  />*/}
                                {/*</div>*/}
                                <div className={styles.grid_container}>
                                    {newProfile.profileAlerts.map((item, idx) => {
                                        return (
                                            <Form.Check key={idx} name={"alerts"}
                                                        checked={compareIfExist(profileObj.alerts, item.id)}
                                                        onChange={(e) => handleProfileA(e, item.id)} type="checkbox"
                                                        label={item.nombre}/>
                                        )
                                    })}
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div></div>
                <div className={styles.justBorders}>
                    <div className={stylesSurv.title}>{traduccion?.mantenedoresII?.privilages}</div>
                    <div style={{width: '100%'}}>
                        <Form.Group>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <div className={styles.grid_container}>
                                    {/*<Form.Check key={99} type="checkbox" label="All"  />*/}
                                </div>
                                <div className={styles.grid_container}>
                                    {listaTimelineStatus.map((item, idx) => {
                                        return (
                                            <Form.Check key={idx} name={"privileges"}
                                                        checked={compareIfExist(profileObj.privileges, item.id)}
                                                        onChange={(e) => handleProfileA(e, item.id)} type="checkbox"
                                                        label={item.nombre}/>
                                        )
                                    })}
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>

                <div></div>
                <div className={styles.justBorders}>
                    <div className={stylesSurv.title}>{traduccion?.mantenedoresII?.dashboard}</div>
                    <div style={{width: '100%'}}>
                        <Form.Group>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <div className={styles.grid_container}>
                                    {newProfile.profileDashboard.map((item, idx) => {
                                        return (
                                            <Form.Check key={idx} name={"dashboard"}
                                                        checked={compareIfExist(profileObj.dashboard, item.id)}
                                                        onChange={(e) => handleProfileA(e, item.id)} type="checkbox"
                                                        label={item.nombre}/>
                                        )
                                    })}
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </>
    );
}
