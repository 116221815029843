import React, {useContext} from 'react';
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {Button, Form} from "react-bootstrap";
import {BsSearch} from "react-icons/bs";
import {FiRefreshCcw} from "react-icons/fi";
import LangContext from "../../../context/LangContext";
import {useParamsUrl} from "../useParamsUrl";
import {urlConfig} from "../../../config/urlConfig";

const FiltroCurrency = ({searchCurrency, refreshCurrencies}) => {

    const {traduccion} = useContext(LangContext);

    const listaStatusSimple =
        [
            {id: 1, nombre: `${traduccion?.mantendores?.activo}`, value: 1},
            {id: 2, nombre: `${traduccion?.mantendores?.inactivo}`, value: 2}
        ];

    const {searchUrl, handleSearch} = useParamsUrl(
        `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/currency/search`
    );

    const search = () => {
        searchCurrency(searchUrl);
    }

    return (
        <div className={stylesfiltro.filtro_total} style={{margin: 0, width: '100%'}}>
            <div className={stylesfiltro.filtros_busqueda}>
                <p className={stylesfiltro.title_filter}>{traduccion?.clients?.filtros}</p>
                <div className={stylesfiltro.opciones_busqueda}>

                    <div>
                        <label htmlFor="container_select">{traduccion?.clients?.name}</label>
                        <Form.Control
                            style={{backgroundColor: '#f1f3fe', boxShadow: 'inset 1px 1px 3px #ccc'}}
                            onChange={handleSearch} name="description" type="text"
                            placeholder={traduccion?.mantenedoresII?.nombre}/>
                    </div>
                    <div>
                        <label htmlFor="estado">{traduccion?.clients?.status}</label>
                        <Form.Select onChange={handleSearch} className="form-label" id="estado"
                                     style={{backgroundColor: '#f1f3fe', boxShadow: 'inset 1px 1px 3px #ccc'}}
                                     name="status">
                            <option value="" defaultValue={true}>{traduccion?.clients?.selectOption}</option>

                            {listaStatusSimple.map((item) => {
                                return (
                                    <option key={item.id} value={item.value}>{item.nombre}</option>
                                )
                            })}
                        </Form.Select>

                    </div>
                    <Button className={stylesfiltro.boton_filtro} variant="primary" onClick={search}>
                        <BsSearch/>
                    </Button>
                    <div></div>
                    {/*
                    <Button className={stylesfiltro.boton_filtro} variant="primary" onClick={refreshCurrencies}>
                        <FiRefreshCcw/>
                    </Button>
                    */}
                </div>
            </div>
        </div>
    );
};

export default FiltroCurrency;
