import {toast, ToastContainer} from "react-toastify";
import stylesSurv from "../surveyors/new_surveyor.module.css";
import {Button, Form} from "react-bootstrap";
import styles from "../company_surveyors/new_company.module.css";
import React, {useContext, useEffect, useState} from "react";
import LangContext from "../../../context/LangContext";
import DBContext from "../../../context/DBContext";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";

export const NewUsers = ({oneUser,newUser}) => {
    const {traduccion} = useContext(LangContext);
    const {setCambioRsk,cambioRsk} = useContext(DBContext);
    const listaStatus = [
        {id: 1, nombre: "Activo"},
        {id: 2, nombre: "Inactivo"},
    ];

    // const listaPerfiles = [
    //     {id: 1, nombre: "Administrador"},
    //     {id: 2, nombre: "Recuperos"},
    //     {id: 3, nombre: "Reclamos"},
    // ]
    const [listaPerfiles, setListaPerfiles] = useState([{id:1, name:"Loading..."}]);
    const [userObj, setUserObj] = useState({
        "firstName": "",
        "lastName": "",
        "profileId": null,
        "phone": "",
        "email": "",
        "headOfArea": false,
        "status": true,
    });
    useEffect(() => {
        if (oneUser !== ""){
            setUserObj({
                "firstName": oneUser.firstName,
                "lastName": oneUser.lastName,
                "profileId": oneUser.account.profile_id,
                "phone": oneUser.account.phone,
                "email": oneUser.account.email,
                "headOfArea": oneUser.headOfArea,
                "status": oneUser.status,
            })
        }
    }, []);

    const token = localStorage.getItem("token")
    useEffect(() => {
        axios.get(`${urlConfig.profileUrl}`, {
            headers: {
                Authorization: token?.toString()
            }
        }).then((res) => {
            //console.log("PROFILES--->", res.data)
            setListaPerfiles(res.data)
        }).catch((err) => {
            //console.log(err)
        })
    }, []);

    const handleInput = (e) => {
        //console.log(e.target.value)
        //console.log(e.target.name)
        setUserObj({
            ...userObj,
            [e.target.name]: e.target.value
        })
    }
    const handlePost = () => {
        const config = {
            headers: {
                Authorization: token?.toString().replace("Bearer ", "")
            }
        }
        if (oneUser ==="") {
            axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/rsk/`, userObj,config ).then((res) => {
                //console.log(res.data)
                toast.success(`${traduccion?.success?.successNewUsu}`)
                setCambioRsk(!cambioRsk);
            }).catch((err) => {
                if(err.response.data.message){
                    if(err.response.data.message.search("Account already exists") !== -1){
                        toast.error(`${traduccion?.errores?.accExist}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }else {
                        toast.error(err.response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                }else {
                    toast.error(`${traduccion?.errores?.newUsu}`)
                }
            })
        } else {
            axios.put(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/rsk/${oneUser.id}`, userObj, config).then((res) => {
                //console.log(res.data)
                toast.success(`${traduccion?.success?.successActUsu}`)
                setCambioRsk(!cambioRsk);
            }).catch((err) => {
                //console.log(err)
                if(err.response.data.message){
                    toast.error(err.response.data.message)
                }else{
                    toast.error(`${traduccion?.errores?.acrUsu}`)
                }
            })
        }
    }
    //console.log(oneUser)
    //console.log(userObj)
    return (
        <>
            {/*{newUser === 1 &&<div>Editar {oneUser.firstName}</div>}*/}
            {/*{newUser === 2 &&<div>Nuevo</div>}*/}

                <ToastContainer />
                <div className={stylesSurv.title_surveyor} style={{marginBottom:15}}>
                    <div>{userObj.firstName !== "" ?
                        `${traduccion?.mantenedoresII?.editarUsuario}`
                        :
                        `${traduccion?.mantenedoresII?.crearUsuario}`
                    }</div>
                </div>
                <div style={{display:'flex', justifyContent:'left', width:'100%', marginTop:'2rem'}}>
                    <div className={stylesSurv.all_surveyors}>
                        <div className={stylesSurv.title_surveyor} style={{justifySelf:'left'}}>
                            {traduccion?.mantenedoresII?.datosDeContacto}
                        </div>

                        <Form.Group>
                        <Form.Label style={{marginTop:'1rem'}} htmlFor="nombre">{traduccion?.mantenedoresII?.nombre}</Form.Label>
                            <Form.Control
                                controlId="nombre"
                                value={userObj.firstName}
                                onChange={handleInput}
                                className={styles.selects_input_details}  type="text"
                                placeholder={traduccion?.mantenedoresII?.nombre} name="firstName"/>
                        <Form.Label htmlFor="nombre">{traduccion?.mantenedoresII?.apellido}</Form.Label>
                        <Form.Control
                            controlId="apellido"
                            value={userObj.lastName}
                            onChange={handleInput}
                            className={styles.selects_input_details}  type="text"
                            placeholder={traduccion?.mantenedoresII?.apellido} name="lastName"/>

                            <Form.Label htmlFor="correo">{traduccion?.login?.correo}</Form.Label>
                            <Form.Control
                                controlId="correo"
                                value={userObj.email}
                                onChange={handleInput}
                                style={{minWidth:'30ch'}}
                                className={styles.selects_input_details}  type="email"
                                placeholder={traduccion?.login?.correo} name="email"/>

                            <Form.Label htmlFor="telefono">{traduccion?.mantenedoresII?.phone}</Form.Label>
                            <Form.Control
                                controlId="telefono"
                                value={userObj.phone}
                                onChange={handleInput}
                                className={styles.selects_input_details}  type="text"
                                placeholder={traduccion?.mantenedoresII?.phone} name="phone"/>

                            <Form.Label htmlFor="perfil">{traduccion?.mantenedoresII?.perfil}</Form.Label>
                            <Form.Select
                                onChange={handleInput}
                                className={styles.selects_input_details} id="perfil" name="profileId">
                                <option value="" defaultValue={true}>{traduccion?.clients?.selectOption}</option>

                                {listaPerfiles.map((item,idx) => {
                                    return (
                                        <option key={idx} value={item.id} selected={item.id ===userObj.profileId}>{item.name}</option>
                                    )
                                })}
                            </Form.Select>

                            <div className={styles.selects_input_details} style={{padding:'.3rem', borderRadius:'5px', marginBottom:'.7rem'}}>
                                {/*<Form.Label htmlFor="perfil">{traduccion?.mantenedoresII?.jefedearea}</Form.Label>*/}
                                <Form.Check
                                    checked={userObj?.headOfArea}
                                    onChange={()=>{setUserObj({...userObj, headOfArea: !userObj.headOfArea})}}
                                    type-={"checkbox"}
                                    label={traduccion?.mantenedoresII?.jefedearea}
                                    id="perfil" name="headOfArea">
                                </Form.Check>
                            </div>

                            <Form.Label htmlFor="estado">Estado</Form.Label>
                            <Form.Select
                                onChange={handleInput}
                                className={styles.selects_input_details} id="estado" name="status" value={userObj.status}>
                                <option value="" defaultValue={true}>{traduccion?.clients?.selectOption}</option>

                                {listaStatus.map((item,idx) => {
                                    return (
                                        <option key={idx} value={idx+1 === 1 ? true:false} selected={idx ===userObj.statusId}>{item.nombre}</option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Button
                            onClick={handlePost}
                        >
                            {traduccion?.mantenedoresII?.save}
                        </Button>
                    </div>
                </div>


        </>
    );
}
