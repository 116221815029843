export const a = {
    "id": "",
    "ata": "",
    "awb": "",
    "bl": "",
    "blAwbDate": "",
    "claimDate": "",
    "companyName": "",
    "consigneeName": "",
    "createdTime": "",
    "distance": 0,
    "exporterName": "",
    "exporterId": "",
    "inspectorStatus": 1,
    "packingTimeline": 1,
    "pod": "",
    "pol": "",
    "recoveredAmount": 0,
    "currencyId": 1,
    "status": 10,
    "statusDays": 1,
    "surveyFee": 0,
    "timeBar": "",
    "totalPrice": 0,
    "longitude": 0,
    "latitude": 0,
    "totalTime": 0,
    "updateTime": "",
    "scheduleDate": "",
    "vessel": "",
    "surveyorRefNumber": "",
    "comment": "",
    "commentDate": "",
    "transportId": 1,
    "timeLine": 10,
    "stuffingDate": "",
    "surveyDate": "",
    "cargo": {
        "reclaimedAmount": 0,
        "currency": 1,
        "damages": [],
        "fruits": [],
        "containerNumber": "",
        "temperature": "",
        "ventilation": "",
        "ca": false,
        "coldTreatment": false,
        "eta": "",
        "etd": "",
    },

}

// export const startClaim = {
//     "id":170,
//     "ata":"2022-12-23T00:00:00.000+00:00",
//     "blAwb":"ETTT-912341",
//     "blAwbDate":"2022-12-23T00:00:00.000+00:00",
//     "claimDate":null,
//     "companyName":"SIIGROUP Exportaciones",
//     "consigneeName":"DON JUAN",
//     "createdTime":"2022-12-22T22:22:26.331+00:00",
//     "exporterName":"SIIGROUP Exportaciones",
//     "carrierName":"EAST TENNESSEE TRUCK LINES INC",
//     "shipperName":"SIIGROUP Shipper",
//     "inspectorStatus":null,
//     "packingTimeline":null,
//     "pod":"Concepcion",
//     "pol":"Puerto montt",
//     "recoveredAmount":1500,
//     "status":1,
//     "statusDays":1,
//     "surveyFee":0,
//     "timeBar":"2022-12-23T00:00:00.000+00:00",
//     "totalPrice":0,
//     "longitude":-0.08603244575278834,
//     "latitude":51.50742726621612,
//     "totalTime":0,
//     "updateTime":"2022-12-22T22:22:26.323+00:00",
//     "scheduleDate":null,
//     "vessel":"VESELBET1",
//     "surveyorRefNumber":null,
//     "comment":null,
//     "commentDate":null,
//     "currencyId":2,
//     "transportId":1,
//     "timeline":[],
//     "cargo":{
//         "containerNumber":"MTNA12512412",
//         "temperature":-4,
//         "ventilation":-10,
//         "ca":false,
//         "coldTreatment":true,
//         "etd":"2022-12-15T00:00:00.000+00:00",
//         "eta":"2022-12-28T00:00:00.000+00:00",
//         "reclaimedAmount":2500,
//         "currency":2,
//         "damagesId":[
//             2
//         ],
//         "fruits":[
//             {
//                 "id":35,
//                 "boxes":50,
//                 "name":"Strawberries",
//                 "fruits":{
//                     "packaging":[
//                         {
//                             "id":155,
//                             "boxes":50,
//                             "packageDate":"2022-12-15T00:00:00.000+00:00"
//                         }
//                     ],
//                     "harvesting":[
//                         {
//                             "id":217,
//                             "boxes":50,
//                             "harvestDate":"2022-12-08T00:00:00.000+00:00"
//                         }
//                     ]
//                 }
//             },
//             {
//                 "id":38,
//                 "boxes":200,
//                 "name":"Tomatoes",
//                 "fruits":{
//                     "packaging":[
//                         {
//                             "id":156,
//                             "boxes":100,
//                             "packageDate":"2022-12-08T00:00:00.000+00:00"
//                         },
//                         {
//                             "id":157,
//                             "boxes":100,
//                             "packageDate":"2022-12-16T00:00:00.000+00:00"
//                         }
//                     ],
//                     "harvesting":[
//                         {
//                             "id":218,
//                             "boxes":50,
//                             "harvestDate":"2022-12-15T00:00:00.000+00:00"
//                         },
//                         {
//                             "id":219,
//                             "boxes":50,
//                             "harvestDate":"2022-12-22T00:00:00.000+00:00"
//                         },
//                         {
//                             "id":220,
//                             "boxes":25,
//                             "harvestDate":"2022-12-22T00:00:00.000+00:00"
//                         },
//                         {
//                             "id":221,
//                             "boxes":75,
//                             "harvestDate":"2022-12-15T00:00:00.000+00:00"
//                         }
//                     ]
//                 }
//             }
//         ],
//         "damages":[
//             {
//                 "id":2,
//                 "description":"Humedad"
//             }
//         ]
//     },
//     "referenceNumbers":{
//         "refNumber1":"670071JNISFHBJF",
//         "refNumber2":"682414ASFASFHB",
//         "refNumber3":null,
//         "refNumber4":null,
//         "refNumber5":null
//     },
//     "hasMap":true,
//     "documents": {
//         "assignmentLetter": "",
//         "assignmentRights": "",
//         "consolidatedReport": "",
//         "controlledAtmosphere": "",
//         "thermograph": "",
//         "thermograph2": "",
//         "survey": "",
//         "photograph": "",
//         "receipt": "",
//         "notice": "",
//         "settlement": "",
//         "destruction": "",
//         "shippingDocuments": [],
//         "calculation": {
//             "creditNote":[],
//             "extraExpenses":[],
//             "marketPrices":[],
//             "salesResults":[]
//         }
//     },
//     "lossCalculation":[
//         {
//             "amount": 1200,
//             "amountId": 1,
//             "currency": 6
//         },
//         {
//             "amount": 1500,
//             "amountId": 1,
//             "currency": 6
//         },
//         {
//             "amount": 1800,
//             "amountId": 1,
//             "currency": 6
//         }
//     ],
//     "lossTypeId": 1
// }


export const startClaim = {
    "id": "",
    "ata": "",
    "awb": "",
    "blAwb": "",
    "blAwbDate": "",
    "claimDate": "",
    "companyName": "",
    "carrierName": "",
    "countryId": "",
    "shipperName": "",
    "consigneeName": "",
    "createdTime": "",
    "distance": 0,
    "exporterName": "",
    "inspectorStatus": 1,
    "packingTimeline": 1,
    "pod": "",
    "pol": "",
    "dischargeDate": "",
    "gateOutDate": "",
    "recoveredAmount": 0,
    "status": 1,
    "statusDays": 1,
    "surveyFee": 0,
    "timeBar": "",
    "totalPrice": 0,
    "longitude": 0,
    "latitude": 0,
    "totalTime": 0,
    "updateTime": "",
    "scheduleDate": "",
    "vessel": "",
    "surveyorRefNumber": "",
    "comment": "",
    "commentDate": "",
    "currencyId": 1,
    "transportId": 1,
    "timeline": [],
    "paymentDate": "",
    "cargo": {
        "damagesId":[],
        "damages": [],
        "containerNumber": "",
        "temperature": "",
        "ventilation": "",
        "ca": false,
        "coldTreatment": false,
        "eta": "",
        "etd": "",
        "reclaimedAmount": 0,
        "currency": 1,
        "fruits": []
    },
    "documents": {
        "assignmentLetter": "",
        "assignmentRights": "",
        "consolidatedReport": "",
        "controlledAtmosphere": "",
        "thermograph": "",
        "thermograph2": "",
        "survey": "",
        "photograph": "",
        "notice": "",
        "destruction": "",
        "receipt": "",
        "settlement": "",
        "other": "",
        "shippingDocuments": [],
        "calculation": {
            "creditNote":[],
            "extraExpenses":[],
            "marketPrices":[],
            "salesResults":[]
        }
    },
    "referenceNumbers":{
        "refNumber1": "",
        "refNumber2": "",
        "refNumber3": "",
        "refNumber4": "",
        "refNumber5": "",
    },
    "lossCalculation":[
        {
            "amount": 0,
            "amountId": 1,
            "currency": 1
        },
        {
            "amount": 0,
            "amountId": 2,
            "currency": 1
        },
        {
            "amount": 0,
            "amountId": 3,
            "currency": 1
        },
        {
            "amount": 0,
            "amountId": 4,
            "currency": 1
        },
        {
            "amount": 0,
            "amountId": 7,
            "currency": 1
        }
    ],
    "lossTypeId": 1
}