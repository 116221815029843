import 'react-chat-elements/dist/main.css'
import {Avatar, ChatItem, MessageBox} from 'react-chat-elements'
import axios from "axios";
import {urlConfig} from "../../../../config/urlConfig";
import {useContext, useEffect, useRef, useState} from "react";
import styles from "../documentos/documentos.module.css";
import AccountContext from "../../../../context/DBContext";
import {Button, Form} from "react-bootstrap";
import LangContext from "../../../../context/LangContext";

export const ChatSurveyor = ({claimForm,inChat}) => {
    const [mensajesChat, setMensajesChat] = useState([]);
    const [mensajeParaEnviar, setMensajeParaEnviar] = useState("");
    const {session} = useContext(AccountContext);
    const {traduccion} = useContext(LangContext);

    const chatContainer = useRef(null);

    const loadChat = () => {
        //console.log(inChat)
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me/message/${claimForm.id}`,
            {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            })
            .then((res) => {
                //console.log(res.data)
                setMensajesChat(res.data);
            }).catch((error) => {
            //console.log(error);
        })
    }
    const postChat = (mensaje) => {
        //console.log(inChat)
        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me/message/${claimForm.id}`,
            {message: mensaje},
            {
                headers: {
                    Authorization: localStorage.getItem("token").replace("Bearer ","")
                }
            })
            .then((res) => {
                //console.log(res.data)
                setMensajesChat(res.data);
                scrollToMyRef();
            }).catch((error) => {
            //console.log(error);
        })
    }
    let intervaloChatId;

    const stopChatInterval = () => {
        clearInterval(intervaloChatId);
        intervaloChatId = null;
    };

    useEffect(() => {
        scrollToMyRef();
        stopChatInterval();
        if (inChat) {
            intervaloChatId = setInterval(loadChat, 3000);
        }
        return stopChatInterval; // detener el intervalo cuando el componente se desmonte
    }, [inChat]);
    useEffect(()=>{
        loadChat()
        scrollToMyRef();
    },[])


    const handleKeyEnter = (e) => {
        if (e.key === 'Enter') {
            postChat(mensajeParaEnviar)
            setMensajeParaEnviar("")
        }
    }

    const scrollToMyRef = () => {
        const scroll =
            chatContainer.current.scrollHeight -
            chatContainer.current.clientHeight;
            chatContainer.current.scrollTo(0, scroll);
    };

    return (
        <>
            <div style={{textAlign:'center', padding:'1rem', fontSize:'1.5rem'}}>{traduccion?.others?.chatWithSurv}</div>
            <div ref={chatContainer} style={{margin:'1rem', paddingTop:'2rem', maxHeight:`${mensajesChat.length >0? '70vh' : 0}`,overflowY:`${mensajesChat.length >0? 'scroll' : 'hidden'}`}} className={mensajesChat.length >0 ? styles.gradient_historico2 : styles.inicia}>
                {mensajesChat.length >0 ? mensajesChat.map((mensaje,idx) => {
                    if (mensaje.isExporter === true){
                        return (<>
                            {/*<div style={{display:'flex', justifyContent:'end'}}>*/}
                            {/*    <Avatar src={mensaje.senderPhoto} size={'large'} type='circle'/>*/}
                            {/*</div>*/}
                        <MessageBox
                            avatar={session.logo}
                            title={mensaje.sender}
                            className={styles.mensaje}
                            position={'right'}
                            type={'text'}
                            text={mensaje.message}
                            date={new Date(mensaje.date)}
                            key={idx}
                        />
                        </>)
                    }else{
                        return (
                            <>
                         <MessageBox
                            avatar={mensaje.senderPhoto}
                            position={'left'}
                            type={'text'}
                            className={styles.mensajeRes}
                            title={mensaje.sender}
                            text={mensaje.message}
                            date={new Date(mensaje.date)}
                            unread={0}
                            id={idx}
                            key={idx}/>
                            </> )
                    }
                  }) : <div >{traduccion?.others?.startChatWithSurveyor}</div>
                }
            </div>
            <div style={{justifyContent:'center',margin:'0 3rem', marginBottom:'1rem'}}>
                <Form.Control
                    controlId="telefono"
                    value={mensajeParaEnviar}
                    onChange={(e) => setMensajeParaEnviar(e.target.value)}
                    className={styles.selects_input_details}  type="text"
                    placeholder={traduccion?.others?.messagePlaceholder} name="mensaje"
                    onKeyUp={handleKeyEnter}

                />
                <Button onClick={()=>postChat(mensajeParaEnviar)}>{traduccion?.others?.send}</Button>
            </div>
            </>
    );
}
