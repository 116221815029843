import {useEffect, useState} from "react";
export const useParamsUrl  = (url) => {

    const [params, setParams] = useState({});
    const [searchUrl, setSearchUrl] = useState("");

    useEffect(() => {
        const baseUrl = `${url}?`;
        const stringParams = new URLSearchParams(params);
        setSearchUrl(baseUrl.concat(stringParams.toString()));
    }, [params])
    const handleSearch = (e) => {
        e.preventDefault()
        setParams({
            ...params,
            [e.target.name]: e.target.value
        })
    }
    //console.log("searchUrl, sin paginacion", searchUrl)
    return {searchUrl, handleSearch}
}

