import React, {useContext, useEffect, useRef, useState} from "react";
import DBContext from "../../../context/DBContext";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import styles from "../surveyors/mantenedor_surveyor.module.css";
import {Link} from "react-router-dom";
import {Button, Form, Pagination, Table} from "react-bootstrap";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import {BsArrowCounterclockwise, BsPencil, BsSearch, BsTrash} from "react-icons/bs";
import {NewSpecie} from "./NewSpecie";
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import LangContext from "../../../context/LangContext";
import {toast} from "react-toastify";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import FiltroSpecies from "./FiltroSpecies";
import Pager from "../orden_cobro/Pager";

export const Specie = ({newSpecie, setNewSpecie, setRouteActive, listaStatus}) => {
    const [oneSpecie, setOneSpecie] = useState({});
    const {especie, setCambioEspecie, cambioEspecie} = useContext(DBContext);
    const [form, setForm] = useState({});
    const {traduccion} = useContext(LangContext);
    //Paginacion
    const [perPage, setPerPage] = useState(10);
    const [active, setActive] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [items, setItems] = useState([]);

    const [especieFullEjemplo, setEspecieFullEjemplo] = useState(especie);
    const [specieSearch, setSpecieSearch] = useState(especie);

    const initialDescription = useRef(true);
    const initialFruitStatus = useRef(true);
    const isSearchUrl = useRef(false);

    const [description, setDescription] = useState(true);
    const [fruitStatus, setFruitStatus] = useState(true);

    const [fruitList, setFruitList] = useState([]);
    const [loading, setLoading] = useState(true);

    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/fruit/search?page=0&size=${perPage}`);

    const [searchUrl, setSearchUrl] = useState(null);

    const searchFruits = (url) => {
        setSearchUrl(url)
    };

    useEffect(() => {
        if (searchUrl === null) return;
        searchFruit(searchUrl);
    }, [searchUrl])

    const searchFruit = () => {
        setLoading(true);
        let searchUrlSize = searchUrl.toString().includes("size=") ? searchUrl : searchUrl + `&size=${perPage}`;
        return axios.get(searchUrlSize.toString(), {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            setFruitList(res.data.fruits)
            setTotalPages(res.data.totalPages - 1)
            isSearchUrl.current = true;
        }).catch((error) => {
            console.log({error})
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.get(url, header).then((response) => {
            setFruitList(response.data.fruits)
            setTotalPages(response.data.totalPages - 1)
        }).catch((error) => {
            console.log({error})
        }).finally(() => {
            setLoading(false);
        })
    }, [url]);


    const handleNewSpecie = () => {
        setOneSpecie({
            description: "",
            statusId: 1,
            damagesId: [],
            damages: [],
            newVariety: [],
            currentVariety: []
        });
        setNewSpecie(1)
    }
    const goToStart = () => {
        setRouteActive("/")
    }

    const editSpecie = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.get(`${urlConfig.especieUrl}${id}`, header)
            .then((response) => {
                //console.log(response.data)
                let dataRes = response.data
                setOneSpecie(dataRes);
                setNewSpecie(1)
            }).catch((error) => {
            //console.log(error);
        })
    }

    const deleteSpecie = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.delete(`${urlConfig.especieUrl}${id}`, header)
            .then((response) => {
                toast.success(`${traduccion?.mantendores?.elimSuccess}`)
                setCambioEspecie(!cambioEspecie);
            }).catch((error) => {
            if (error.response.data.message.includes("constraint")) {
                toast.error(`${traduccion?.mantendores?.constraintError}`)
            } else {
                toast.error(`${traduccion?.mantendores?.elimError}`)
            }
            //console.log(error);
        })
    }

    const goToPage = (page) => {
        if (isSearchUrl.current) {
            let paginatedUrl = searchUrl.toString().includes("page=") ? searchUrl.toString().replace(/page=\d+/, `page=${page}`) : searchUrl + `&page=${page}`
            paginatedUrl = paginatedUrl.includes("size=") ? paginatedUrl : paginatedUrl + `&size=${perPage}`;
            setUrl(paginatedUrl)
            setActive(page)
        } else {
            let paginatedUrl = url.toString().replace(/page=\d+/, `page=${page}`);
            setUrl(paginatedUrl)
            setActive(page)
        }
    }

    useEffect(() => {
        if (initialFruitStatus.current) {
            initialFruitStatus.current = false;
            return;
        }
        if (!fruitStatus) {
            if(searchUrl === null) {
                if (!url.includes("sort=fruitStatusEntity,desc") && url.includes("sort=fruitStatusEntity,asc")) {
                    setUrl(url.replace("&sort=fruitStatusEntity,asc", "&sort=fruitStatusEntity,desc"));
                } else {
                    setUrl(url + "&sort=fruitStatusEntity,desc");
                }
            }else{
                if (!searchUrl.includes("sort=fruitStatusEntity,desc") && searchUrl.includes("sort=fruitStatusEntity,asc")) {
                    setSearchUrl(searchUrl.replace("&sort=fruitStatusEntity,asc", "&sort=fruitStatusEntity,desc"));
                } else {
                    setSearchUrl(searchUrl + "&sort=fruitStatusEntity,desc");
                }
            }
        } else {
            if(searchUrl === null) {
                if (!url.includes("sort=fruitStatusEntity,asc") && url.includes("sort=fruitStatusEntity,desc")) {
                    setUrl(url.replace("&sort=fruitStatusEntity,desc", "&sort=fruitStatusEntity,asc"))
                } else {
                    setUrl(url + "&sort=fruitStatusEntity,asc");
                }
            }else{
                if (!searchUrl.includes("sort=fruitStatusEntity,asc") && searchUrl.includes("sort=fruitStatusEntity,desc")) {
                    setSearchUrl(searchUrl.replace("&sort=fruitStatusEntity,desc", "&sort=fruitStatusEntity,asc"))
                } else {
                    setSearchUrl(searchUrl + "&sort=fruitStatusEntity,asc");
                }
            }
        }
    }, [fruitStatus]);

    useEffect(() => {
        if (initialDescription.current) {
            initialDescription.current = false;
            return;
        }
        if (!description) {
            if(searchUrl === null) {
                if (!url.includes("sort=description,desc") && url.includes("sort=description,asc")) {
                    setUrl(url.replace("&sort=description,asc", "&sort=description,desc"));
                } else {
                    setUrl(url + "&sort=description,desc");
                }
            }else{
                if (!searchUrl.includes("sort=description,desc") && searchUrl.includes("sort=description,asc")) {
                    setSearchUrl(searchUrl.replace("&sort=description,asc", "&sort=description,desc"));
                } else {
                    setSearchUrl(searchUrl + "&sort=description,desc");
                }
            }
        } else {
            if(searchUrl === null) {
                if (!url.includes("sort=description,asc") && url.includes("sort=description,desc")) {
                    setUrl(url.replace("&sort=description,desc", "&sort=description,asc"))
                } else {
                    setUrl(url + "&sort=description,asc");
                }
            }else {
                if (!searchUrl.includes("sort=description,asc") && searchUrl.includes("sort=description,desc")) {
                    setSearchUrl(searchUrl.replace("&sort=description,desc", "&sort=description,asc"))
                } else {
                    setSearchUrl(searchUrl + "&sort=description,asc");
                }
            }
        }
    }, [description]);


    const resetSort = () => {
        initialDescription.current = true;
        initialFruitStatus.current = true;
        setDescription(true);
        setFruitStatus(true);
        setUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/fruit/search?page=0&size=${perPage}`)
    }

    const sortParams = (sort) => {
        if (sort === "description") {
            setDescription(!description);
        }
        if (sort === "fruitStatusEntity") {
            setFruitStatus(!fruitStatus);
        }
    }

    return (
        <>
            <div className={styles.all_surveyor}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>>
                    {newSpecie === 0 &&
                        <span className={styles.bold}>{traduccion?.mantendores?.especie}</span>
                    }
                    {(newSpecie === 1 && oneSpecie.id !== undefined) &&
                        <><span onClick={() => setNewSpecie(0)}
                                className={styles.like_a}>{traduccion?.mantendores?.especie}</span>>
                            <span className={styles.bold}>{traduccion?.mantendores?.editEspecie}</span></>
                    }
                    {(newSpecie === 1 && oneSpecie.id === undefined) &&
                        <><span onClick={() => setNewSpecie(0)}
                                className={styles.like_a}>{traduccion?.mantendores?.especie}</span>>
                            <span className={styles.bold}>{traduccion?.mantendores?.nuevaEspecie}</span></>
                    }
                </div>
                {newSpecie === 0
                    &&
                    <>
                        <div className={styles.title_surveyor}>
                            <p>{traduccion?.mantendores?.nuevaEspecie}</p>
                            <Button className={styles.title_button} variant="primary"
                                    onClick={handleNewSpecie}>{traduccion?.mantendores?.nuevaEspecie}</Button>
                        </div>
                        {/*------------ Filtro ------------*/}
                        <FiltroSpecies searchFruits={searchFruits}/>
                        <div className={stylesTabla.contiene_tabla}>
                            <Table borderless hover className={stylesTabla.tabla}>
                                <thead>
                                <tr>
                                    <th>{traduccion?.mantendores?.nombre} <a
                                        onClick={() => sortParams("description")}> {description ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th>{traduccion?.clients?.status} <a
                                        onClick={() => sortParams("fruitStatusEntity")}> {fruitStatus ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th><a onClick={() => resetSort()}><BsArrowCounterclockwise/></a></th>
                                </tr>
                                </thead>
                                <tbody>

                                {fruitList.map((datos, idx) =>
                                    <tr key={idx} className={stylesTabla.tr_data}>
                                        <td>{datos.description}</td>
                                        <td>{datos.fruitStatusEntity.id === 1 ? `${traduccion?.mantendores?.activo}` : `${traduccion?.mantendores?.inactivo}`}</td>
                                        <td>
                                            <div className={stylesTabla.botones_botones_surveyor}
                                                 style={{display: 'flex'}}>
                                                <div onClick={() => editSpecie(datos.id)}
                                                     className={stylesTabla.boton_icono}>
                                                    <BsPencil/>
                                                </div>
                                                <div
                                                    onClick={() => deleteSpecie(datos.id)}
                                                    className={stylesTabla.boton_icono}>
                                                    <BsTrash style={{color: 'red'}}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                        <Pager totalPages={totalPages} active={active} goToPage={goToPage}/>
                    </>

                }
                {newSpecie === 1 && <NewSpecie oneSpecie={oneSpecie}/>}


            </div>
        </>
    )
}
