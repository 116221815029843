import React, {useContext, useEffect, useState} from "react";
import DBContext from "../../../context/DBContext";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import {toast, ToastContainer} from "react-toastify";
import stylesSurv from "../surveyors/new_surveyor.module.css";
import {Button, Form} from "react-bootstrap";
import styles from "../company_surveyors/new_company.module.css";
import LangContext from "../../../context/LangContext";

export const NewCurrency = ({oneCurrency, setNewCurrency}) => {
    let {cambioCurrency, setCambioCurrency} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);

    const [currencyObj, setCurrencyObj] = useState({
        "description": "",
        "statusId": null,
    });
    const listaStatus = ["Activo", "Inactivo"];

    useEffect(() => {
        setCurrencyObj({
            ...currencyObj,
            id: oneCurrency.id,
            description: oneCurrency.description,
            statusId: oneCurrency.currencyStatusEntity?.id,
        })
    }, [oneCurrency]);

    const handleCurrency = (e) => {
        setCurrencyObj({
            ...currencyObj,
            [e.target.name]: e.target.value,
        })
    }

    const handlePost = () => {
        if (currencyObj.description === "" || currencyObj.statusId === undefined || currencyObj.statusId === null) {
            toast.error(`${traduccion?.errores?.allFilled}`);
            return;
        }

        let token = localStorage.getItem("token");
        const noBearerToken = token.toString().replace("Bearer ", "")
        let header = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': noBearerToken
            }
        }
        if (oneCurrency?.id !== undefined) {
            axios.put(urlConfig.currencyUrl + oneCurrency.id, currencyObj, header)
                .then((response) => {
                    setCambioCurrency(!cambioCurrency);
                    toast.success(`${traduccion?.success?.successActCurr}`);
                })
                .catch((error) => {
                    toast.error(`${traduccion?.errores?.errCurr}`);

                })
        } else if (currencyObj.name !== "" && currencyObj.status !== "") {
            axios.get(urlConfig.currencyUrl, header).then((response) => {
                console.log('Primero entraste al get')
                let dataRes = [response.data];
                console.log(typeof (dataRes))
                console.log('QUE TIENE DATA RES', dataRes)
                let currencyList = dataRes.map((currency) => currency.description);
                console.log('que tiene currencyList', currencyList)
                if (currencyList.includes(currencyObj.description)) {
                    toast.error(`${traduccion?.errores?.currExist}`);
                } else {
                    axios.post(urlConfig.currencyUrl, currencyObj, header).then((response) => {
                        console.log('Primero entraste al post')
                        setCambioCurrency(!cambioCurrency);
                        toast.success(`${traduccion?.success?.successNewCurr}`);
                    }).catch((error) => {
                        console.log({error});
                    })
                }
            }).catch((error) => {
                console.log({error});
            })
        }

    }
    return (
        <>
            <ToastContainer/>
            <div className={stylesSurv.title_surveyor} style={{marginBottom: 15}}>
                <div>{traduccion?.mantenedoresII?.manteMoneda}</div>
            </div>
            <div style={{display: 'flex', justifyContent: 'left', width: '100%', marginTop: '2rem'}}>
                <div className={stylesSurv.all_surveyors}>
                    <div className={stylesSurv.title_surveyor} style={{justifySelf: 'left'}}>
                        {oneCurrency.name !== undefined ?
                            <p>{traduccion?.mantenedoresII?.editMoneda}</p> :
                            <p>{traduccion?.mantenedoresII?.nuevaMoneda}</p>
                        }
                    </div>

                    <div>{traduccion?.mantenedoresII?.nombreMoneda}</div>
                    <Form.Group controlId="nombre">
                        <Form.Control
                            value={currencyObj.description}
                            onChange={handleCurrency}
                            className={styles.selects_input_details} type="text"
                            placeholder={traduccion?.mantenedoresII?.nombreMoneda} name="description"/>
                    </Form.Group>

                    <div>{traduccion?.mantenedoresII?.estado}</div>
                    <Form.Select onChange={handleCurrency} className={styles.selects_input_details} id="estado"
                                 name="statusId">
                        <option value="" defaultValue={true}>{traduccion?.clients?.selectOption}</option>

                        {listaStatus.map((item, idx) => {
                            return (
                                <option key={idx} value={idx + 1}
                                        selected={idx + 1 === currencyObj.statusId}>{item}</option>
                            )
                        })}
                    </Form.Select>

                    <Button onClick={handlePost}>
                        {traduccion?.mantenedoresII?.save}
                    </Button>
                </div>
            </div>
        </>
    )
}
