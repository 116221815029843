import React, {useContext, useEffect, useRef, useState} from 'react';

import {Button, Form, Pagination, Table} from "react-bootstrap";
import styles from "./billingtabla.module.css";
import axios from "axios";
import {toast} from "react-toastify";
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {AiOutlineEye} from "react-icons/ai";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import LangContext from "../../../context/LangContext";
import {useNavigate} from "react-router";
import {urlConfig} from "../../../config/urlConfig";
import {startClaim} from "../../../config/initial";


const TableBilling=({active, setActive, listaClaim, setListaClaim, perPage, setPerPage, url,setUrl,totalPages, setTotalPages,
                        setSelectedClaim, selectedClaim, setCobroObject, cobroObject, setCobroIds, cobroIds})=>{

    const {traduccion} = useContext(LangContext);
    const navigate = useNavigate();

    const token = localStorage.getItem("token");

    const initialNameOrder = useRef(true);
    const initialContainerNumberOrder = useRef(true);
    const initialClaimDateOrder = useRef(true);
    const initialInspectionDateOrder = useRef(true);
    const initialRskFeeOrder = useRef(true);
    const initialSurveyorFeeOrder = useRef(true);
    const initialStatusOrder = useRef(true);

    const [nameOrder, setNameOrder] = useState(true);
    const [containerNumberOrder, setContainerNumberOrder] = useState(true);
    const [claimDateOrder, setClaimDateOrder] = useState(true);
    const [inspectionDateOrder, setInspectionDateOrder] = useState(true);
    const [rskFeeOrder, setRskFeeOrder] = useState(true);
    const [surveyorFeeOrder, setSurveyorFeeOrder] = useState(true);
    const [statusOrder, setStatusOrder] = useState(true);


    useEffect(() => {
        if (initialNameOrder.current) {
            initialNameOrder.current = false;
            return;
        }
        if (!nameOrder) {
            if (!url.includes("sort=na,desc") && url.includes("sort=na,asc")) {
                setUrl(url.replace("&sort=na,asc", "&sort=na,desc"));
            } else {
                setUrl(url + "&sort=na,desc");
            }
        } else {
            if (!url.includes("sort=na,asc") && url.includes("sort=na,desc")) {
                setUrl(url.replace("&sort=na,desc", "&sort=na,asc"))
            } else {
                setUrl(url + "&sort=na,asc");
            }
        }
    }, [nameOrder]);

    useEffect(() => {
        if (initialContainerNumberOrder.current) {
            initialContainerNumberOrder.current = false;
            return;
        }
        if (!containerNumberOrder) {
            if (!url.includes("sort=cn,desc") && url.includes("sort=cn,asc")) {
                setUrl(url.replace("&sort=cn,asc", "&sort=cn,desc"));
            } else {
                setUrl(url + "&sort=cn,desc");
            }
        } else {
            if (!url.includes("sort=cn,asc") && url.includes("sort=cn,desc")) {
                setUrl(url.replace("&sort=cn,desc", "&sort=cn,asc"))
            } else {
                setUrl(url + "&sort=cn,asc");
            }
        }
    }, [containerNumberOrder]);

    useEffect(() => {
        if (initialClaimDateOrder.current) {
            initialClaimDateOrder.current = false;
            return;
        }
        if (!claimDateOrder) {
            if (!url.includes("sort=cd,desc") && url.includes("sort=cd,asc")) {
                setUrl(url.replace("&sort=cd,asc", "&sort=cd,desc"));
            } else {
                setUrl(url + "&sort=cd,desc");
            }
        } else {
            if (!url.includes("sort=cd,asc") && url.includes("sort=cd,desc")) {
                setUrl(url.replace("&sort=cd,desc", "&sort=cd,asc"))
            } else {
                setUrl(url + "&sort=cd,asc");
            }
        }
    }, [claimDateOrder]);

    useEffect(() => {
        if (initialInspectionDateOrder.current) {
            initialInspectionDateOrder.current = false;
            return;
        }
        if (!inspectionDateOrder) {
            if (!url.includes("sort=ind,desc") && url.includes("sort=ind,asc")) {
                setUrl(url.replace("&sort=ind,asc", "&sort=ind,desc"));
            } else {
                setUrl(url + "&sort=ind,desc");
            }
        } else {
            if (!url.includes("sort=ind,asc") && url.includes("sort=ind,desc")) {
                setUrl(url.replace("&sort=ind,desc", "&sort=ind,asc"))
            } else {
                setUrl(url + "&sort=ind,asc");
            }
        }
    }, [inspectionDateOrder]);


    useEffect(() => {
        if (initialRskFeeOrder.current) {
            initialRskFeeOrder.current = false;
            return;
        }
        if (!rskFeeOrder) {
            if (!url.includes("sort=rf,desc") && url.includes("sort=rf,asc")) {
                setUrl(url.replace("&sort=rf,asc", "&sort=rf,desc"));
            } else {
                setUrl(url + "&sort=rf,desc");
            }
        } else {
            if (!url.includes("sort=rf,asc") && url.includes("sort=rf,desc")) {
                setUrl(url.replace("&sort=rf,desc", "&sort=rf,asc"))
            } else {
                setUrl(url + "&sort=rf,asc");
            }
        }
    }, [rskFeeOrder]);

    useEffect(() => {
        if (initialSurveyorFeeOrder.current) {
            initialSurveyorFeeOrder.current = false;
            return;
        }
        if (!surveyorFeeOrder) {
            if (!url.includes("sort=sf,desc") && url.includes("sort=sf,asc")) {
                setUrl(url.replace("&sort=sf,asc", "&sort=sf,desc"));
            } else {
                setUrl(url + "&sort=sf,desc");
            }
        } else {
            if (!url.includes("sort=sf,asc") && url.includes("sort=sf,desc")) {
                setUrl(url.replace("&sort=sf,desc", "&sort=sf,asc"))
            } else {
                setUrl(url + "&sort=sf,asc");
            }
        }
    }, [surveyorFeeOrder]);

    useEffect(() => {
        if (initialStatusOrder.current) {
            initialStatusOrder.current = false;
            return;
        }
        if (!statusOrder) {
            if (!url.includes("sort=st,desc") && url.includes("sort=sf,asc")) {
                setUrl(url.replace("&sort=sf,asc", "&sort=sf,desc"));
            } else {
                setUrl(url + "&sort=st,desc");
            }
        } else {
            if (!url.includes("sort=sf,asc") && url.includes("sort=sf,desc")) {
                setUrl(url.replace("&sort=sf,desc", "&sort=sf,asc"))
            } else {
                setUrl(url + "&sort=sf,asc");
            }
        }
    }, [statusOrder]);

    useEffect(() => {
        getSortClaims(url);
    }, [url])


    //-----------Paginacion-----------

    const getClaims = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setListaClaim(res.data)
            setTotalPages(res.data.totalPages-1)

        }).catch((error) => {
            //console.log(error);
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }

    const getSortClaims = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setListaClaim(res.data)
            setTotalPages(res.data.totalPages-1)

        }).catch((error) => {
            toast.error(`${traduccion?.errores?.errorSorting}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }

    const goToPage = (page) => {
        //console.log("GO TO PAGE", page)
        url =(url.replace(/page=\d+/, `page=${page}`))
        //console.log("URL", url)
        setUrl(url)
        setActive(page)
        getClaims(url)
    }

    const handlePerPage = (e) => {
        let page = e.target.value
        //console.log("PAGINANDO...", page)
        setPerPage(page)
        // url =(url.replace(/size=\d+/, `size=${page}`))
        // url =(url.replace(/page=\d+/, `page=0`))
        // setUrl(url)
        // setActive(0)
    }

    useEffect(() => {
        url =(url.replace(/size=\d+/, `size=${perPage}`))
        url =(url.replace(/page=\d+/, `page=0`))
        setUrl(url)
        setActive(0)
        getClaims(url)
    },[perPage])

    const handleTypeClaim = (e) => {
        let type = e
        if (e === null){
            type = ""
        }
        //console.log("TIPO DE RECLAMO", type)
        url =(url.replace(/type=\w?/, `type=${type}`))
        //console.log("URL", url)
        setUrl(url)
        getClaims(url)
    }


    let items=[]
    let oneElipsisA = false
    let oneElipsisD = false
    for (let number = 0; number <= totalPages; number++) {
        //add elipsis
        if (number > 2 && number < totalPages - 2) {
            if (number === active + 1 || number === active - 1) {
                items.push(
                    <Pagination.Item className={styles.pagina} key={number} active={number === active} onClick={() => goToPage(number)}>
                        {number+1}
                    </Pagination.Item>,
                );
            }else if(number ===active){
                items.push(
                    <Pagination.Item className={styles.pagina} onClick={()=>goToPage(number)} key={number} active={number === active}>
                        {number+1}
                    </Pagination.Item>,
                );
            }else{
                if (number < active && !oneElipsisA) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisA = true
                }
                if (number > active && !oneElipsisD) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisD = true
                }
            }
        }else{
            items.push(
                <Pagination.Item className={styles.pagina} onClick={()=>goToPage(number)} key={number} active={number === active}>
                    {number+1}
                </Pagination.Item>,
            );
        }
    }
    const editClaim = (id) => {
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${id}`, {
            headers: {
                Authorization: token.toString().replace("Bearer ", "")
            }
        }).then((res) => {

            console.log("EDITANDO RECLAMO...", res.data);

            let newData = {...startClaim, ...res.data}
            //console.log("NEW DATA", newData)
            if (newData.comment === null) {
                newData.comment = ""
            }
            if (newData.claimDate === null) {
                newData.claimDate = ""
            }
            if (newData.lossTypeId === null) {
                newData.lossTypeId = 1
            }

            if (newData.lossCalculation.length === 0) {
                //console.log("NO HAY CALCULO DE PERDIDAS")
                newData.lossCalculation = startClaim.lossCalculation
            }
            // setOneClaimAll(newData)
            navigate('/informe_inspeccion_all', {state: {id: res.data.id,oneClaimAll:newData,imRsk:true,lossCalculation:newData.lossCalculation,goToInspector:false}})
        }).catch((error) => {
            //console.log(error);
        })
    }
    const handleCheck = (e,id,survId) => {
        const listaSelected = selectedClaim
        if (e.target.checked){
            listaSelected.push(id)
            setSelectedClaim(listaSelected)
            setCobroObject({...cobroObject,claimList:listaSelected})
            setCobroIds([...cobroIds,survId])
        }else{
            let idx = listaSelected.indexOf(id)
            listaSelected.splice(idx,1)
            setSelectedClaim(listaSelected)
            setCobroObject({...cobroObject,claimList:listaSelected})
            setCobroIds([...cobroIds,survId])
        }
        //console.log(listaSelected)
    }
    //console.log(listaClaim)
    const handleCurr = (type,value) => {
        if (value === null || value === undefined || type === null || type === undefined){
            return "---"
        }
        const lang = localStorage.getItem("lang");
        const formatter = new Intl.NumberFormat(lang, {
            style: 'currency',
            currency: `${type}`,
        });
        return formatter.format(value)
    }

    const sortParams = (sort) => {
        if (sort === "na") {
            setNameOrder(!nameOrder);
        }
        if (sort === "cn") {
            setContainerNumberOrder(!containerNumberOrder);
        }
        if (sort === "cd") {
            setClaimDateOrder(!claimDateOrder);
        }
        if (sort === "ind") {
            setInspectionDateOrder(!inspectionDateOrder);
        }
        if (sort === "rf") {
            setRskFeeOrder(!rskFeeOrder);
        }
        if (sort === "sf") {
            setSurveyorFeeOrder(!surveyorFeeOrder);
        }
        if (sort === "st") {
            setStatusOrder(!statusOrder);
        }
    }

    return(
        <>
        <div className={styles.contiene_tabla} style={{borderTop:"transparent"}}>
            <div className={stylesfiltro.filtro_total} style={{margin: 0, width: '100%'}}>
                <div>
                    <div className={stylesfiltro.opciones_busqueda}>
                            <div className={styles.mostrar}>
                                <p>{traduccion.claimTable?.mostrar}</p>
                                <Form.Select onChange={handlePerPage} size="sm" id="show">
                                    <option value="4">4</option>
                                    <option value="8">8</option>
                                    <option value="20" selected={true}>20</option>
                                </Form.Select>
                            </div>
                            <div>
                                <button className="allbtn" onClick={(e)=>handleTypeClaim("")} >
                                    {traduccion?.factura?.all}
                                </button>
                            </div>
                            <div>
                                <button className="surveybtn" onClick={(e)=>handleTypeClaim(1)} >
                                    Online survey
                                </button>
                            </div>
                            <div>
                                <button className="recoverybtn"onClick={(e)=>handleTypeClaim(0)} >
                                    Recovery Process
                                </button>
                            </div>
                    </div>
                </div>
            </div>
            <div className={styles.contiene_tabla}>
                <Table className={styles.tabla} borderless hover>
                <thead>
                <tr>
                    <th></th>
                    <th>{traduccion?.clients?.name} <a onClick={() => sortParams("na")}> {nameOrder ? <FaSortUp/> :
                        <FaSortDown/>}</a></th>
                    <th>{traduccion?.factura?.container} <a onClick={() => sortParams("cn")}> {containerNumberOrder ? <FaSortUp/> :
                        <FaSortDown/>}</a></th>
                    <th>{traduccion?.factura?.claimDate} <a onClick={() => sortParams("cd")}> {claimDateOrder ? <FaSortUp/> :
                        <FaSortDown/>}</a></th>
                    <th>{traduccion?.factura?.inspectionDate} <a onClick={() => sortParams("ind")}> {inspectionDateOrder ? <FaSortUp/> :
                        <FaSortDown/>}</a></th>
                    <th>{traduccion?.factura?.RSKfee} <a onClick={() => sortParams("rf")}> {rskFeeOrder ? <FaSortUp/> :
                        <FaSortDown/>}</a></th>
                    <th>{traduccion?.factura?.surveyorFee} <a onClick={() => sortParams("sf")}> {surveyorFeeOrder ? <FaSortUp/> :
                        <FaSortDown/>}</a></th>
                    <th>{traduccion?.factura?.status} <a onClick={() => sortParams("st")}> {statusOrder ? <FaSortUp/> :
                        <FaSortDown/>}</a></th>
                    <th></th>

                </tr>
                </thead>
                <tbody>
                {
                    listaClaim?.claims?.length > 0?
                    listaClaim?.claims?.map((datos, idx) => {
                    return(
                        <tr key={idx} className={styles.tr_data}>
                        <td  style={{textAlign: "center",
                                    display: 'flex',
                                    gap: '3px' }}>
                            <input type="checkbox"
                                   disabled={datos.status !== "Pending" }
                                   id={datos.id} name="selectedClaim" value={datos.id}
                                   onClick={(e)=>{handleCheck(e,datos.id,datos.exporterId)}}
                                   className={styles.same_height} />

                                {
                                    (datos.status === 2 ||
                                        //datos.timeline.find((obj)=> obj.description === "NEW"))
                                        (datos.hasMap && datos.lastTimelineId !== 10)) &&
                                        <div style={{
                                            display: 'block',
                                            width: '4rem',
                                            // height: '40px',
                                            marginLeft: '1rem',
                                            background: 'darkgreen',
                                            fontSize: 10,
                                            lineHeight: 1.45,
                                            padding: '8px 0',
                                            fontWeight: "bolder",
                                            color: 'white',
                                            borderRadius: 5,

                                        }}>
                                            Survey Requested
                                        </div>
                                }

                                {!datos.hasMap &&
                                    <div style={{
                                            display: 'block',
                                            width: '4rem',
                                            // height: '40px',
                                            marginLeft: '1rem',
                                            background: 'rebeccapurple',
                                            padding: '8px 0',
                                            fontSize: 10,
                                            lineHeight: 1.45,
                                            fontWeight: "bolder",
                                            color: 'white',
                                            borderRadius: 5
                                        }}>
                                            Recovery Process
                                        </div>
                                }

                        </td>
                        <td>{datos.exporterName}</td>
                        <td>{datos.containerNumber}</td>
                        <td>{datos.claimDate? new Date(datos.claimDate).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }) :""}</td>
                        <td>{datos.inspectionDate}</td>
                        <td>{datos.rskFee}</td>
                        <td>{handleCurr(datos.surveyorFeeCurrency,datos.surveyorFee)}</td>
                        <td>{datos.status}</td>
                        <td>
                            <Button variant="light" onClick={()=>editClaim(datos.id)}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <AiOutlineEye fill="blue" />
                                </svg>
                            </Button>
                        </td>
                    </tr>
                    )}):
                    <tr>
                        <td colSpan="8" style={{textAlign: "center"}}>{traduccion?.factura?.noData}</td>
                    </tr>
                }
                </tbody>
            </Table>
            </div>
        </div>
    <Pagination style={{justifyContent:'center'}}>
        {items}
    </Pagination>

        </>
    );
};

export default TableBilling;
