import React, {useContext, useEffect, useRef, useState} from "react";
import DBContext from "../../../context/DBContext";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import styles from "../surveyors/mantenedor_surveyor.module.css";
import {Link} from "react-router-dom";
import {Button, Form, Pagination, Table} from "react-bootstrap";
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import {BsArrowCounterclockwise, BsPencil, BsSearch, BsTrash} from "react-icons/bs";
import {NewSpecie} from "../specie/NewSpecie";
import {NewCurrency} from "./NewCurrency";
import LangContext from "../../../context/LangContext";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import FiltroCurrency from "./FiltroCurrency";
import {toast} from "react-toastify";
import Pager from "../orden_cobro/Pager";

export const MantenedorMoneda = ({setRouteActive, newCurrency, setNewCurrency}) => {
    const {currency, cambioCurrency, setCambioCurrency} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);
    const [oneCurrency, setOneCurrency] = useState({});
    const [form, setForm] = useState({});
    //Paginacion
    const [perPage, setPerPage] = useState(10);
    const [active, setActive] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [items, setItems] = useState([]);

    const [currencyFullEjemplo, setCurrencyFullEjemplo] = useState(currency);
    const [currencySearch, setCurrencySearch] = useState(currency);

    const [loading, setLoading] = useState(true);
    const [currencyList, setCurrencyList] = useState([]);

    const initialDescription = useRef(true);
    const initialCurrencyStatus = useRef(true);
    const isSearchUrl = useRef(false);

    const [description, setDescription] = useState(true);
    const [currencyStatus, setCurrencyStatus] = useState(true);

    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/currency?page=0&size=${perPage}`);

    const [searchUrl, setSearchUrl] = useState(null);

    const searchCurrency = (url) => {
        setSearchUrl(url)
    };

    useEffect(() => {
        if (searchUrl === null) return;
        searchCurrencies(searchUrl);
    }, [searchUrl])

    const searchCurrencies = () => {
        setLoading(true);
        let searchUrlSize = searchUrl.toString().includes("size=") ? searchUrl : searchUrl + `&size=${perPage}`;
        return axios.get(searchUrlSize.toString(), {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            setCurrencyList(res.data.currencies)
            setTotalPages(res.data.totalPages - 1)
            isSearchUrl.current = true;
        }).catch((error) => {
            console.log({error})
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.get(url, header).then((response) => {
            setCurrencyList(response.data.currencies)
            setTotalPages(response.data.totalPages - 1)
        }).catch((error) => {
            console.log({error})
        }).finally(() => {
            setLoading(false);
        })
    }, [url]);

    const refreshCurrencies = () => {
        setLoading(true);
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.get( `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/currency?page=0&size=${perPage}`, header).then((response) => {
            setCurrencyList(response.data.currencies)
            setTotalPages(response.data.totalPages - 1)
        }).catch((error) => {
            console.log({error})
        }).finally(() => {
            setLoading(false);
        })
    }


    const handleNewSpecie = () => {
        setOneCurrency("");
        setNewCurrency(1)
    }
    const goToStart = () => {
        setRouteActive("/")
    }

    const editMoneda = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }

        axios.get(`${urlConfig.currencyUrl}${id}`, header)
            .then((response) => {
                let dataRes = response.data
                //console.log(dataRes)
                setOneCurrency(dataRes);
                setNewCurrency(1)
                // //console.log(dataRes)
            }).catch((error) => {
            //console.log(error);
        })
    }

    const deleteMoneda = (id) => {
        let currentCurrencyList = [...currencyList];
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.delete(`${urlConfig.currencyUrl}${id}`, header).then((response) => {
            const indiceEliminar = currentCurrencyList.findIndex(c => c.id === id);
            if (indiceEliminar !== -1) {
                currentCurrencyList.splice(indiceEliminar, 1);
            }
            setCurrencyList(currentCurrencyList);
            toast.success(`${traduccion?.mantendores?.elimSuccess}`)
        }).catch((error) => {
            console.log({error});
            toast.error(`${traduccion?.mantendores?.elimError}`)
        })
    }

    const goToPage = (page) => {
        if (isSearchUrl.current) {
            let paginatedUrl = searchUrl.toString().includes("page=") ? searchUrl.toString().replace(/page=\d+/, `page=${page}`) : searchUrl + `&page=${page}`
            paginatedUrl = paginatedUrl.includes("size=") ? paginatedUrl : paginatedUrl + `&size=${perPage}`;
            setUrl(paginatedUrl)
            setActive(page)
        } else {
            let paginatedUrl = url.replace(/page=\d+/, `page=${page}`);
            setUrl(paginatedUrl)
            setActive(page)
        }
    }

    const doPagination = (pag) => {
        setActive(pag)
        let active = pag;
        let items = []
        let oneElipsisA = false
        let oneElipsisD = false
        //console.log("TOTAL PAGES", totalPages)
        //console.log("ACTIVE", active)
        for (let number = 0; number < totalPages; number++) {
            //add elipsis
            if (number > 2 && number < totalPages - 2) {
                if (number === active + 1 || number === active - 1) {
                    items.push(
                        <Pagination.Item className={styles.pagina} key={number} active={number === active}
                                         onClick={() => goToPage(number)}>
                            {number + 1}
                        </Pagination.Item>,
                    );
                } else if (number === active) {
                    items.push(
                        <Pagination.Item className={styles.pagina} onClick={() => goToPage(number)} key={number}
                                         active={number === active}>
                            {number + 1}
                        </Pagination.Item>,
                    );
                } else {
                    if (number < active && !oneElipsisA) {
                        items.push(
                            <Pagination.Ellipsis key={number} disabled/>
                        );
                        oneElipsisA = true
                    }
                    if (number > active && !oneElipsisD) {
                        items.push(
                            <Pagination.Ellipsis key={number} disabled/>
                        );
                        oneElipsisD = true
                    }
                }
            } else {
                //console.log(number)
                //console.log(active)
                items.push(
                    <Pagination.Item className={styles.pagina} onClick={() => goToPage(number)} key={number}
                                     active={number === active}>
                        {number + 1}
                    </Pagination.Item>,
                );
            }
        }
        setItems(items)
    }

    useEffect(() => {
        if (initialCurrencyStatus.current) {
            initialCurrencyStatus.current = false;
            return;
        }
        if (!currencyStatus) {
            if (!url.includes("sort=currencyStatusEntity,desc") && url.includes("sort=currencyStatusEntity,asc")) {
                setUrl(url.replace("&sort=currencyStatusEntity,asc", "&sort=currencyStatusEntity,desc"));
            } else {
                setUrl(url + "&sort=currencyStatusEntity,desc");
            }
        } else {
            if (!url.includes("sort=currencyStatusEntity,asc") && url.includes("sort=currencyStatusEntity,desc")) {
                setUrl(url.replace("&sort=currencyStatusEntity,desc", "&sort=currencyStatusEntity,asc"))
            } else {
                setUrl(url + "&sort=currencyStatusEntity,asc");
            }
        }
    }, [currencyStatus]);

    useEffect(() => {
        if (initialDescription.current) {
            initialDescription.current = false;
            return;
        }
        if (!description) {
            if (!url.includes("sort=description,desc") && url.includes("sort=description,asc")) {
                setUrl(url.replace("&sort=description,asc", "&sort=description,desc"));
            } else {
                setUrl(url + "&sort=description,desc");
            }
        } else {
            if (!url.includes("sort=description,asc") && url.includes("sort=description,desc")) {
                setUrl(url.replace("&sort=description,desc", "&sort=description,asc"))
            } else {
                setUrl(url + "&sort=description,asc");
            }
        }
    }, [description]);

    const resetSort = () => {
        initialDescription.current = true;
        initialCurrencyStatus.current = true;
        setDescription(true);
        setCurrencyStatus(true);
        setUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/currency?page=0&size=${perPage}`)
    }

    const sortParams = (sort) => {
        if (sort === "description") {
            setDescription(!description);
        }
        if (sort === "currencyStatusEntity") {
            setCurrencyStatus(!currencyStatus);
        }
    }

    return (
        <>
            <div className={styles.all_surveyor}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>>
                    {newCurrency === 0 &&
                        <span className={styles.bold}>{traduccion?.mantenedoresII?.moneda}</span>
                    }
                    {(newCurrency === 1 && oneCurrency.id !== undefined) &&
                        <><span onClick={() => setNewCurrency(0)}
                                className={styles.like_a}>{traduccion?.mantenedoresII?.moneda}</span>>
                            <span className={styles.bold}>{traduccion?.mantenedoresII?.editMoneda}</span></>
                    }
                    {(newCurrency === 1 && oneCurrency.id === undefined) &&
                        <><span onClick={() => setNewCurrency(0)}
                                className={styles.like_a}>{traduccion?.mantenedoresII?.moneda}</span>>
                            <span className={styles.bold}>{traduccion?.mantenedoresII?.nuevaMoneda}</span></>
                    }
                </div>
                {newCurrency === 0
                    &&
                    <>
                        <div className={styles.title_surveyor}>
                            <p>{traduccion?.mantenedoresII?.nuevaMoneda}  </p>
                            <Button className={styles.title_button} variant="primary"
                                    onClick={handleNewSpecie}>{traduccion?.mantenedoresII?.nuevaMoneda}</Button>
                        </div>
                        <FiltroCurrency searchCurrency={searchCurrency} refreshCurrencies={refreshCurrencies}/>
                        <div className={stylesTabla.contiene_tabla}>
                            <Table borderless hover className={stylesTabla.tabla}>
                                <thead>
                                <tr>
                                    <th>{traduccion?.mantenedoresII?.moneda} <a
                                        onClick={() => sortParams("description")}> {description ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th>{traduccion?.clients?.status} <a
                                        onClick={() => sortParams("currencyStatusEntity")}> {currencyStatus ?
                                        <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th><a onClick={() => resetSort()}><BsArrowCounterclockwise/></a></th>
                                </tr>

                                </thead>
                                <tbody>

                                {currencyList.map((datos, idx) =>
                                    <tr key={idx} className={stylesTabla.tr_data}>
                                        <td>{datos.description}</td>
                                        <td>{datos.currencyStatusEntity.id === 1 ? `${traduccion?.mantendores?.activo}` : `${traduccion?.mantendores?.inactivo}`}</td>
                                        <td>
                                            <div className={stylesTabla.botones_botones_surveyor}
                                                 style={{display: 'flex'}}>
                                                <div onClick={() => editMoneda(datos.id)}
                                                     className={stylesTabla.boton_icono}>
                                                    <BsPencil/>
                                                </div>
                                                <div
                                                    onClick={() => deleteMoneda(datos.id)}
                                                    className={stylesTabla.boton_icono}>
                                                    <BsTrash style={{color: 'red'}}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                        <Pager totalPages={totalPages} active={active} goToPage={goToPage}/>
                    </>

                }

                {newCurrency === 1 && <NewCurrency oneCurrency={oneCurrency} setNewCurrency={setNewCurrency}/>}


            </div>
        </>
    )
}
