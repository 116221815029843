import {createContext, useEffect, useState} from "react";
import axios from "axios";
import {urlConfig} from "../config/urlConfig";
import useExpireToken from "../hooks/useExpireToken";

const DBContext = createContext();

const DBProvider = ({children}) => {

    const [allAccounts, setAllAccounts] = useState();
    const [cambioAccounts, setCambioAccounts] = useState();

    const [session, setSession] = useState([])

    const [stuffing, setStuffing] = useState([]);
    const [cambioStuffing, setCambioStuffing] = useState(false);
    const [stuffingPages, setStuffingPages] = useState(null);

    const [claim, setClaim] = useState([]);
    const [cambioClaims, setCambioClaims] = useState(false);

    const [claimPages, setClaimPages] = useState(null);

    const [inspector, setInspector] = useState([])
    const [cambioInspector, setCambioInspector] = useState(false);

    const [cambioRates, setCambioRates] = useState(false);
    const [paisesRates, setPaisesRates] = useState([]);

    const [company, setCompany] = useState([]);
    const [companyPages, setCompanyPages] = useState();
    const [cambioCompany, setCambioCompany] = useState(false);

    const [exporter, setExporter] = useState([]);
    const [cambioExporter, setCambioExporter] = useState(false);

    const [especie, setEspecie] = useState([]);
    const [cambioEspecie, setCambioEspecie] = useState(false);

    const [currency, setCurrency] = useState([]);
    const [cambioCurrency, setCambioCurrency] = useState(false);

    const [damage, setDamage] = useState([]);
    const [cambioDamage, setCambioDamage] = useState(false);

    const [timelineStatus, setTimelineStatus] = useState([]);

    const [listaPago, setListaPago] = useState([]);
    const [cambioListaPago, setCambioListaPago] = useState(false);

    const [listaCobro, setListaCobro] = useState([]);
    const [cambioListaCobro, setCambioListaCobro] = useState(false);

    const [claimsReady, setClaimsReady] = useState([]);
    const [cambioClaimsReady, setCambioClaimsReady] = useState(false);

    const [claimsFinalized, setClaimsFinalized] = useState([]);
    const [cambioClaimsFinalized, setCambioClaimsFinalized] = useState(false);

    const [statusClaim, setStatusClaim] = useState([]);


    const [profiles, setProfiles] = useState([
        {
          name: "Surveyor",
          status: true,
        },
        {
          name: "profile 2",
          status: false,
        },
      ]);
    const [cambioProfile, setCambioProfiles] = useState([])

    const [rsk, setRsk] = useState([]);
    const [cambioRsk, setCambioRsk] = useState([])

    const token = localStorage.getItem("token");
    let rol = ""
    if (token !== null) {

        const payload = JSON.parse(
            window.atob(token.split(".")[1])
            // base64 encoded atob => javascript ECMAScript
            // window son todas los metodos de DOM 
            );
        rol = payload.rol;
    }
    //console.log("ROL", rol)
    useEffect(() => {
        if (token) {
            axios.get(urlConfig.accountUrl, {
                    headers: {
                        Authorization: token
                    }
                }
            ).then((res) => {
                setSession(res.data.data)

            }).catch((error) => {
                //console.log(error);
            })
        }
    }, [setSession]);


    const claimMe = () => {

        if (rol === "ROLE_EXPORTER") {
            axios.get(urlConfig.claimUrl, {
                headers: {
                    Authorization: token?.toString().replace("Bearer ", "")
                }
            }).then((res) => {
                // invertir un objeto
                const claimData = res.data;
                setClaim(claimData)

            }).catch((error) => {
                //console.log(error);
                // localStorage.removeItem("token")
                // window.location.reload()
            })
        }
    }

    // ------CLAIMS ------
    useEffect(() => {
        if (token) {
           if (rol === "ROLE_EXPORTER") {
                axios.get(urlConfig.claimUrl, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    const claimData = res.data.claims;
                    setClaim(claimData)
                    let pages = {};
                    pages.currentPage = res.data.currentPage;
                    pages.totalItems = res.data.totalItems;
                    pages.totalPages = res.data.totalPages;
                    setClaimPages(pages)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
        if (token) {
            if (rol.includes("RSK")) {
                axios.get(urlConfig.claimUrlALL, {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {

                    const claimData = res.data;
                    setClaim(claimData)
                    // let pages = {};
                    // pages.currentPage = res.data.currentPage;
                    // pages.totalItems = res.data.totalItems;
                    // pages.totalPages = res.data.totalPages;
                    // setClaimPages(pages)
                }).catch((error) => {
                    //console.log(error);
                })

            }
        }
    }, [setClaim, cambioClaims, setCambioClaims]);

    // ------CLAIMS FINALIZED ------
    useEffect(() => {
        if (token) {
            if(rol.includes("RSK")){
               // axios.get(`${urlConfig.urlNgrok}/api/v1/order/surveyors/search?page=0&size=20`, {

                    axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/surveyors/search?page=0&size=20`, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", ""),
                    }
                }).then((res) => {
                    //console.log(res.data)
                    const claimData = res.data;
                    ////console.log("CARGA RECLAMOS //ESTADO CLAIM", {claimData})
                    setClaimsFinalized(claimData)
                    // let pages = {};
                    // pages.currentPage = res.data.currentPage;
                    // pages.totalItems = res.data.totalItems;
                    // pages.totalPages = res.data.totalPages;
                    // setClaimFPages(pages)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    }, [setClaimsFinalized, cambioClaimsFinalized, setCambioClaimsFinalized]);

//All accounts
    useEffect(() => {
        if (token) {
            if(rol.includes("RSK")){
                axios.get(urlConfig.accountAllUrl, {
                    headers: {
                        Authorization: token?.toString()
                    }
                }).then((res) => {
                    setAllAccounts(res.data.data)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    }, [cambioAccounts]);

// ------rates ------
    useEffect(() => {
        if (token) {
            if (rol.includes("RSK")) {
                axios.get(urlConfig.ratesUrl, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    setPaisesRates(res.data)
                    //console.log(res.data)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    }, [setPaisesRates, cambioRates, setCambioRates]);
// ------ Company Surveyor ------
    useEffect(() => {
        if (token) {
            //console.log("TOKEN", token)
            if (rol.includes("RSK")) {
                axios.get(urlConfig.searchCompanySurveyorUrl+"?page=0&size=10", {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    //console.log(res.data)
                    setCompany(res.data.companies)
                    let pages = {};
                    pages.currentPage = res.data.currentPage;
                    pages.totalItems = res.data.totalItems;
                    pages.totalPages = res.data.totalPages;
                    setCompanyPages(pages)
                    //console.log(res.data)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    }, [setCompany, cambioCompany, setCambioCompany]);

// ------- Inspectores -------

    useEffect(() => {
        if (token) {
            axios.get(urlConfig.searchSurveyorUrl+"?page=0&size=10", {
                headers: {
                    Authorization: token?.toString().replace("Bearer ", "")
                }
            }).then((res) => {
                setInspector(res.data.surveyors)
                //console.log(res.data.surveyors)
            }).catch((error) => {
                //console.log(error);
            })
        }
    }, [cambioInspector]);


    ////console.log({inspector,cambioInspector})

// ------- Exporters -------
    useEffect(() => {
        if (token) {
            if (rol.includes("RSK")) {
                axios.get(urlConfig.exporterUrl+"?page=0&size=10", {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                        setExporter(res.data.exporters)
                        //console.log(res.data)
                    }
                ).catch((error) => {
                    //console.log(error);
                })
            }
        }
    }, [setExporter, cambioExporter, setCambioExporter]);

// ------- Especies -------

    useEffect(() => {
        const noBearerToken = token?.toString().replace("Bearer ", "")
        //console.log({noBearerToken})
        if (token) {
            axios.get(urlConfig.especieUrl, {
                headers: {
                    Authorization: noBearerToken
                }
            }).then((res) => {
                setEspecie(res.data)
            }).catch((error) => {
                //console.log(error);
            })
        }
    }, [setEspecie, cambioEspecie, setCambioEspecie]);


    //console.log({especie})

    // ------- Currencies -------

    useEffect(() => {
        if (token) {
            axios.get(urlConfig.currencyUrl, {
                headers: {
                    Authorization: token?.toString().replace("Bearer ", "")
                }
            }).then((res) => {
                setCurrency(res.data.currencies)
            }).catch((error) => {
                //console.log(error);
            })
        }
    }, [setCurrency, cambioCurrency, setCambioCurrency]);

// ------- Stuffing -------
    useEffect(() => {
        if (token) {
            //verificar rol de usuario en el token
            if (rol === "ROLE_EXPORTER") {
                axios.get(`${urlConfig.allUrl}:9003/api/v1/stuffings/me?page=0&size=10`, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    //console.log(res.data.data)
                    setStuffing(res.data.data)
                    let pages = {};
                    pages.currentPage = res.data.data.currentPage;
                    pages.totalItems = res.data.data.totalItems;
                    pages.totalPages = res.data.data.totalPages;
                    setStuffingPages(pages)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    }, [setStuffing, cambioStuffing]);

    useEffect(() => {
        //console.log("aca")
        if (token) {
            //verificar rol de usuario en el token
            if (rol === "ROLE_EXPORTER" || rol.includes("RSK")) {
                axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/damage`, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    setDamage(res.data)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    }, [cambioDamage]);

    // ------- Timeline -------
    useEffect(() => {
        if (token){
            if (rol === "ROLE_EXPORTER" || rol.includes("RSK")) {
                axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/timeline`, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    //console.log("TIMELINE--->", res.data)
                    //const damage = res.data.map(item => ({"id": item.id, "description": item.description}));
                    setTimelineStatus(res.data)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    },[]);

    // ------- Lista Pago -------

    useEffect(() => {

        if (token) {
            if (rol === "ROLE_EXPORTER" || rol.includes("RSK")) {
                axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/pay`, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    //console.log("PAGOS--->", res.data)
                    //const damage = res.data.map(item => ({"id": item.id, "description": item.description}));
                    setListaPago(res.data)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    },[cambioListaPago]);

    // ------- Lista Pago -------

    useEffect(() => {

        if (token) {
            if (rol === "ROLE_EXPORTER" || rol.includes("RSK")) {
                axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/recovery?page=0&size=10`, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    //console.log("COBROS--->", res.data)
                    //const damage = res.data.map(item => ({"id": item.id, "description": item.description}));
                    setListaCobro(res.data)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    },[cambioListaCobro]);


    // ---Claims Ready ---
    useEffect(() => {
        if (token) {
            if (rol === "ROLE_EXPORTER" || rol.includes("RSK")) {
                axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/customers/search`, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    //console.log("COMPANIES--->", res.data)
                    //const damage = res.data.map(item => ({"id": item.id, "description": item.description}));
                    setClaimsReady(res.data)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    },[cambioClaimsReady]);


    // ------- Status Finalized -------

    useEffect(() => {
        if (token) {
            if (rol === "ROLE_EXPORTER" || rol.includes("RSK")) {
                axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/status`, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    //console.log("STATUS--->", res.data)
                    //const damage = res.data.map(item => ({"id": item.id, "description": item.description}));
                    setStatusClaim(res.data)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    },[]);


    useEffect(() => {
        if (token) {
            if (rol === "ROLE_EXPORTER" || rol.includes("RSK")) {
                axios.get(`${urlConfig.profileUrl}`, {
                    headers: {
                        Authorization: token?.toString()
                    }
                }).then((res) => {
                    setProfiles(res.data)
                }).catch((error) => {
                    console.log({error});
                })
            }
        }
    },[cambioProfile]);

    useEffect(() => {
        if (token) {
            if (rol === "ROLE_EXPORTER" || rol.includes("RSK")) {
                axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/rsk/?page=0&size=10`, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    //console.log("RSK--->", res.data.rskDtoList)
                    setRsk(res.data.rskDtoList)
                }).catch((error) => {
                    //console.log(error);
                })
            }
        }
    },[cambioRsk]);

    //Verifica si el Token es valido
    useExpireToken()

    const data = {
        session, claim,
        paisesRates, cambioRates, setCambioRates,
        company, cambioCompany, setCambioCompany, companyPages,
        inspector, cambioInspector, setCambioInspector,
        exporter, cambioExporter, setCambioExporter,
        especie, cambioEspecie, setCambioEspecie,
        currency, cambioCurrency, setCambioCurrency,
        setInspector, claimMe, setCompany, setClaim,
        cambioClaims, setCambioClaims,
        damage,setCambioDamage,cambioDamage,
        allAccounts, cambioAccounts, setCambioAccounts,

        stuffing, cambioStuffing,setCambioStuffing,setStuffing,
        listaPago, cambioListaPago, setCambioListaPago,
        listaCobro,cambioListaCobro, setCambioListaCobro,
        timelineStatus,
        claimPages, stuffingPages,
        profiles, setProfiles,
        cambioProfile, setCambioProfiles,
        rsk, cambioRsk, setCambioRsk,
        claimsFinalized, setCambioClaimsFinalized,cambioClaimsFinalized,
        claimsReady, setCambioClaimsReady,cambioClaimsReady,statusClaim
    }

    return (
        <DBContext.Provider value={data}>
            {children}
        </DBContext.Provider>
    );
}
export {DBProvider}
export default DBContext;
