import stylesSurv from "../surveyors/new_surveyor.module.css";
import {Button, Form} from "react-bootstrap";
import styles from "../company_surveyors/new_company.module.css";
import stylesCountry from "./countries.module.css";
import React, {useContext, useEffect} from "react";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import DBContext from "../../../context/DBContext";
import { ToastContainer, toast } from 'react-toastify';
import {Typeahead} from "react-bootstrap-typeahead";
import LangContext from "../../../context/LangContext";
import countrieslib from "countries-list";
import {onlyNumbers} from "../../../config/functions";

export const NewCountry = ({oneCountryRate}) => {
    const {traduccion,handleLanguage,initialLang} = useContext(LangContext);
    let {cambioRates,setCambioRates,currency} = React.useContext(DBContext);
    //console.log(oneCountryRate);

    const url = urlConfig.ratesUrl;
    const [country, setCountry] = React.useState({
        "country": "",
        "basicRatePerHour": "",
        "ratePerKm": "",
        "ratePerAddHour": "",
        "code": "",
        "currency": ""
    });
    const countryCodes = Object.keys(countrieslib.countries);

    let arrCountryCode1 = countryCodes.map((country) => countrieslib.countries[country].name).sort();
    // //console.log(oneCountryRate.currency.id);

    console.log({currency})

    useEffect(() => {
        setCountry({
            ...country,
            country: oneCountryRate.country,
            basicRatePerHour: oneCountryRate.basicRatePerHour,
            ratePerKm: oneCountryRate.ratePerKm,
            ratePerAddHour: oneCountryRate.ratePerAddHour,
            code: oneCountryRate.code,
            currency: oneCountryRate.currency?.id
        })
    },[oneCountryRate]);

    // if (oneCountryRate.country !== undefined) {
    //     country.country = oneCountryRate.country;
    //     country.basicRatePerHour = oneCountryRate.basicRatePerHour;
    //     country.ratePerKm = oneCountryRate.ratePerKm;
    //     country.ratePerAddHour = oneCountryRate.ratePerAddHour;
    //     country.code = oneCountryRate.code;
    // }

    const handleCountryInfo = (e) => {
        setCountry({
            ...country,
            [e.target.name]: e.target.value,
        })
    }

    const handlePost = () => {
        let header =  {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        if(country.country === "" || country.basicRatePerHour == ""
            || country.ratePerKm == "" || country.ratePerAddHour == ""
            || country.code == "" || country.currency == "" || country.currency === undefined) {
            toast.warning(`${traduccion?.warning?.fillAllFields}`);

        }else{
            //console.log(country)
            if(oneCountryRate.id !== undefined){
                //console.log(country)
                axios.put(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/country/${oneCountryRate.id}`,country,header)
                //axios.put(`https://testsiigroup.pagekite.me/api/v1/country/${oneCountryRate.id}`,country,header)
                    .then((response) => {
                        setCambioRates(!cambioRates);
                        toast.success(`${traduccion?.success?.successActCountry}`);
                    }).catch((error) => {
                    //console.log(error);
                    toast.error(`${traduccion?.errores?.paisErr}`);

                })
            }else{
                 axios.post(url, country, header).then((res) => {
                 // axios.post("https://testsiigroup.pagekite.me/api/v1/country", country, header).then((res) => {
                    //console.log(res)

                    setCambioRates(!cambioRates);
                    toast.success(`${traduccion?.success?.formSend}`,
                        {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                }).catch((err) => {
                    if (err.response.data.message?.search("Country already exist") !== -1) {
                        toast.error(`${traduccion?.errores?.existCountry}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        toast.error(`${traduccion?.errores?.formErr}`,
                            {
                                position: toast.POSITION.TOP_RIGHT,
                            });

                    }
                    //console.log(err)

                })
            }
        }
    }


    const handleChangeCountry = (e) => {
        setCountry({
            ...country,
            country: e[0]
        })
        //console.log(country)
        //console.log(e)

    }

    let codeByCountry=(pais) => {
        //console.log(pais)
        return countryCodes.filter((code) => (countrieslib.countries[code].name == pais));
    }

    const handleChangeCountryEnd = (e) => {
        let arr = codeByCountry(e[0])
        let strArr = arr.toString();
        //console.log(strArr)
        setCountry({
            ...country,
            country: e[0],
            code: strArr
        })
    }

    //console.log(country)

    return (
        <>
            <ToastContainer />
            <div className={stylesSurv.title_surveyor} style={{marginBottom:15}}>
                {/*<div>{oneCountryRate !== "" ? "New" : "Edit"} Country </div>*/}
                {<div>{traduccion?.mantenedoresII?.manteCountry}</div>}
            </div>
            <div style={{display:'flex', justifyContent:'left', width:'100%', marginTop:'2rem'}}>
                <div className={stylesSurv.all_surveyors}>
                    <div className={stylesSurv.title_surveyor} style={{justifySelf:'left'}}>
                        {oneCountryRate.country !== undefined ?
                            <p>{traduccion?.mantenedoresII?.editCountry}</p> :
                            <p>{traduccion?.mantenedoresII?.newCountry}</p>
                        }
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between',marginBottom:'1rem'}}>
                        <div>{traduccion?.mantenedoresII?.moneda}</div>
                        <Form.Group id="moneda">
                            <Form.Select className={styles.moneda} name="currency"
                                         onChange={handleCountryInfo}
                                         // value={country.currency?.id}
                            >
                                <option value="">---</option>
                                {
                                    currency.map(currency => (
                                            <option key={currency.id} value={currency.id} selected={currency.id === oneCountryRate?.currency?.id}>
                                                {currency.description}
                                            </option>
                                        )
                                    )
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                    {oneCountryRate.country !== undefined ?
                        <>

                            <div style={{marginBottom:'16px'}}>Country: <span style={{fontWeight:"bold"}}>{country.country}</span></div>
                        </>
                        :
                        <>


                        <div>{traduccion?.clients?.country}</div>
                        <Form.Group value={country.country} controlId="country"
                                    style={{width: '100%', marginTop: 0, marginBottom: 20}}>
                            <Typeahead
                                placeholder={country.country || `${traduccion.newSurveyor?.seleccionPais}`}
                                style={{margin: '0px !important'}}
                                className={stylesCountry.typeahead}
                                id="basic-typeahead"
                                labelKey={option => (option !== "CN" || option !== "US") ? `${option}` : null}
                                onChange={handleChangeCountryEnd}
                                onInputChange={e => handleChangeCountry(e)}
                                options={arrCountryCode1}
                            />


                        </Form.Group>
                        </>
                    }
                    <div>{traduccion?.mantenedoresII?.basicRatePerHour}</div>
                    <Form.Group controlId="basicRatePerHour">
                        <Form.Control
                            value={country.basicRatePerHour}
                            onChange={handleCountryInfo}
                            className={styles.selects_input_details}  type="text"
                            onInput={onlyNumbers}
                            placeholder={traduccion?.mantenedoresII?.basicRatePerHour} name="basicRatePerHour"/>
                    </Form.Group>
                    <div>{traduccion?.mantenedoresII?.ratePerKm}</div>
                    <Form.Group controlId="ratePerKm">
                        <Form.Control
                            value={country.ratePerKm}
                            onChange={handleCountryInfo}
                            className={styles.selects_input_details}  type="text"
                            onInput={onlyNumbers}
                            placeholder={traduccion?.mantenedoresII?.ratePerKm} name="ratePerKm"/>
                    </Form.Group>
                    <div>{traduccion?.mantenedoresII?.rateperadditionalhour}</div>
                    <Form.Group controlId="ratePerAddHour">
                        <Form.Control
                            value={country.ratePerAddHour}
                            onChange={handleCountryInfo}
                            className={styles.selects_input_details}  type="text"
                            onInput={onlyNumbers}
                            placeholder={traduccion?.mantenedoresII?.rateperadditionalhour} name="ratePerAddHour"/>
                    </Form.Group>
                    {(oneCountryRate.code !== undefined || country.code?.length > 0) &&
                    <div style={{margin:'16px 0'}}>Code: <span style={{fontWeight:'bold'}}>{`${country.code ||oneCountryRate.code}`}</span></div>
                    }
                    <Button onClick={handlePost}>
                        {traduccion?.mantenedoresII?.save}
                    </Button>
                </div>
            </div>
            </>
    )
}
