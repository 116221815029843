import React, {useContext, useEffect, useState} from "react";
import {saveAs} from "file-saver";
import JSZip from "jszip";
import {Button, Tab, Tabs} from "react-bootstrap";
import {CargaTab} from "./carga/CargaTab";
import styles from "./reclamo_tabs.module.css"
import {DocumentosTab} from "./documentos/DocumentosTab";
import {InspectorTab} from "./inspector/InspectorTab";
import LangContext from "../../../context/LangContext";
import {CalculoPerdidas} from "./perdidas/CalculoPerdidasTab";
import {LineaTiempo} from "./lineadetiempo/LineaTiempoTab";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import {toast, ToastContainer} from "react-toastify";
import {InspectorTabNoMap} from "./inspector/InspectorTabNoMap";
import {ModalTabInspector} from "./ModalTabInspector";
import {Recupero} from "./Recupero/Recupero";
import {ModalDescargaTodo} from "./ModalDescargaTodo";
import {Historico} from "./historico/Historico";
import {ChatSurveyor} from "./chat/ChatSurveyor";
import {ModalUpdateFinalized} from "./ModalUpdateFinalized";
import {startClaim} from "../../../config/initial";
import {useLocation} from "react-router-dom";


export const InformeReclamoTabs = ({
                                       yearRsk,
                                       claimForm,
                                       setClaimForm,
                                       arrFile,
                                       setArrFile,
                                       errors,
                                       setErrors,
                                       transporte,
                                       imRsk,
                                       goToInspector,
                                       setGoToInspector,
                                       forFinalChanges,
                                       editRsk,
                                       editExporter,
                                       initialRskClaim
                                   }) => {
    const {traduccion} = useContext(LangContext);

    const [key, setKey] = useState('carga');
    const [showModal, setShowModal] = useState(false);
    const [showModalDes, setShowModalDes] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [fechaHar, setFechaHar] = useState("Unknown");
    const [fechaPack, setFechaPack] = useState("Unknown");
    const [loadingFile, setLoadingFile] = useState(false);
    const [inInspector, setInInspector] = useState(false);
    const [theLogs, setTheLogs] = useState([]);
    const [inChat, setInChat] = useState(false);
    const [modalFinalizar, setModalFinalizar] = useState(false);
    const [cambios, setCambios] = useState([]);

    const [documents, setDocuments] = useState({
        "shippingDocuments": [
            //     {   "bl": 0,
            //         "fito": 0,
            //         "origin": 0,
            //         "invoice": 0,
            //         "packingList": 0,
            //         "booking": 0,
            //         "ct": 0,
            //         "other": 0
            //     }
        ]
    });
    //console.log(claimForm)
    const [filesClaim, setFilesClaim] = useState({
            "shippingDocuments": claimForm?.documents?.shipping || [],
            "consolidatedReport": claimForm?.documents?.consolidatedReport || "",
            "controlledAtmosphere": claimForm?.documents?.controlledAtmosphere || "",
            "thermograph": claimForm?.documents?.thermograph || "",
            "thermograph2": claimForm?.documents?.thermograph2 || "",
            "other": claimForm?.documents?.other || "",
            "assignmentLetter": claimForm?.documents?.assignmentLetter || "",
            "assignmentRights": claimForm?.documents?.assignmentRights || "",
            "survey": claimForm?.documents?.survey || "",
            "photograph": claimForm?.documents?.photograph || "",
            "notice": claimForm?.documents?.notice || "",
            "destruction": claimForm?.documents?.destruction || "",
            "receipt": claimForm?.documents?.receipt || "",
            "settlement": claimForm?.documents?.settlement || "",
            "invoice": claimForm?.documents?.invoice || "",
            "calculation": claimForm?.documents?.calculation || {
                "creditNote": [],
                "extraExpenses": [],
                "marketPrices": [],
                "salesResults": []
            },
        }
    )

    const isIterable = (obj) => {
        // checks for null and undefined
        if (obj == null) {
            return false;
        }
        //check if length is defined
        if (obj.length === undefined || obj.length === 0) {
            return false;
        }


        return typeof obj[Symbol.iterator] === 'function';
    }
    const [surveyDate, setSurveyDate] = useState("");
    useEffect(() => {
        if (claimForm?.hasMap) {
            if (isIterable(claimForm?.timeline)) {
                //El 4 es FINALIZED
                if (claimForm.timeline.find((e) => e.id === 4)) {
                    setSurveyDate(claimForm.timeline.find((e) => e.id === 4).time.split("T")[0])
                }
            }
        } else {
            setSurveyDate(claimForm?.surveyDate?.split("T")[0])
        }
    }, []);

    const handleDocuments = (e) => {

        if (e.target.files.length > 0) {
            if (e.target.name.match(/extraexpenses|salesresults|creditnotes|marketprice/)) {

                let file = e.target.files[0];
                if (file.size > 20000000) {
                    toast.error(`${traduccion?.errores?.errPerfil}`);
                    return;
                }
                let fileName = new File([file], e.target.name + "_" + file.name, {type: file.type});

                let arr = filesClaim.calculation;
                let name = e.target.files[0].name;
                let index = e.target.name.slice(-1);
                if (e.target.name.match(/creditnotes/)) {
                    arr.creditNote[index] = name;
                }
                if (e.target.name.match(/extraexpenses/)) {
                    arr.extraExpenses[index] = name;
                }
                if (e.target.name.match(/salesresults/)) {
                    arr.salesResults[index] = name;
                }
                if (e.target.name.match(/marketprices/)) {
                    arr.marketPrices[index] = name;
                }
                setFilesClaim({...filesClaim, calculation: arr});
                setArrFile([...arrFile, fileName]);
            } else {
                let file = e.target.files[0];
                if (file.size > 20000000) {
                    toast.error(`${traduccion?.errores?.errPerfil}`);
                    return;
                }
                let fileName = new File([file], e.target.name + "_" + file.name, {type: file.type});
                let arr = documents.shippingDocuments;

                for (let i = 0; i < arr.length; i++) {
                    if (e.target.name.slice(-1) == i) {
                        arr[i].filename = e.target.name + "_" + file.name;
                    }
                    ////console.log(file)
                }

                //borrar archivo antiguo si es que existe
                for (let i = 0; i < arrFile.length; i++) {
                    if (arrFile[i].name.split("_")[0] === e.target.name) {
                        arrFile.splice(i, 1);
                    }
                }
                setFilesClaim({...filesClaim, shippingDocuments: arr});
                setArrFile([...arrFile, fileName]);
            }
        } else {
            for (let i = 0; i < arrFile.length; i++) {
                if (arrFile[i].name.split("_")[0] === e.target.name) {
                    arrFile.splice(i, 1);
                }
            }
        }
        /*
        console.log(e.target.name)
        // let regexShipp = /shipping/
        console.log(e.target.name)
        if (e.target.files.length > 0) {
            //console.log(e.target.files[0].name)
            let file = e.target.files[0];
            if (file.size > 20000000) {
                toast.error(`${traduccion?.errores?.errPerfil}`);
                return;
            }
            let fileName = new File([file], e.target.name + "_" + file.name, {type: file.type});
            let arr = documents.shippingDocuments;

            for (let i = 0; i < arr.length; i++) {
                if (e.target.name.slice(-1) == i) {
                    arr[i].filename = e.target.name + "_" + file.name;
                }
                ////console.log(file)
            }

            //borrar archivo antiguo si es que existe
            for (let i = 0; i < arrFile.length; i++) {
                if (arrFile[i].name.split("_")[0] === e.target.name) {
                    //console.log("entra")
                    //console.log(arrFile[i].name.split("_")[0])
                    arrFile.splice(i, 1);
                }
            }
            //Dejar el archivo nuevo en el array de calculation
            if (e.target.name.match(/extraexpenses|salesresults|creditnotes|marketprice/)) {
                let arr = filesClaim.calculation;
                let name = e.target.files[0].name;
                let index = e.target.name.slice(-1);
                //console.log(name)
                if (e.target.name.match(/creditnotes/)) {
                    arr.creditNote[index] = name;
                }
                if (e.target.name.match(/extraexpenses/)) {
                    arr.extraExpenses[index] = name;
                }
                if (e.target.name.match(/salesresults/)) {
                    arr.salesResults[index] = name;
                }
                if (e.target.name.match(/marketprices/)) {
                    arr.marketPrices[index] = name;
                }
                //setFilesClaim({...filesClaim, calculation: arr});
            }

            setArrFile([...arrFile, fileName]);
        } else {
            for (let i = 0; i < arrFile.length; i++) {
                if (arrFile[i].name.split("_")[0] === e.target.name) {
                    arrFile.splice(i, 1);
                }
            }

        }*/

    }

    // get token
    const token = localStorage.getItem("token");
    //set header
    const header = {
        headers: {
            Authorization: token.toString().replace("Bearer ", "")
        }

    }
    const initialLang = localStorage.getItem("lang") || "es";

    const handleUpdateModalFinalized = (e) => {
        //console.log(forFinalChanges)
        // const tienenMismaInformacion = forFinalChanges.damages.every((valor, indice) => valor === claimForm.cargo.damages[indice]);
        const biggest = Math.max(forFinalChanges.damages.length, claimForm.cargo.damages.length);
        const firstDescription = forFinalChanges.damages.map(e => e.description).join(", ");
        const secondDescription = claimForm.cargo.damages.map((e) => e.description).join(", ");
        const changeFrom = initialLang === "es" ? "Cambió de " : "Changed from ";
        const changeTo = initialLang === "es" ? " a " : " to ";
        let tienenMismaInformacion = true;

        for (let i = 0; i < biggest; i++) {
            if (forFinalChanges.damages[i] === undefined || claimForm.cargo.damages[i] === undefined) {
                tienenMismaInformacion = false;
            } else {
                if (forFinalChanges.damages[i].id !== claimForm.cargo.damages[i].id) {
                    tienenMismaInformacion = false;
                }
            }
        }
        if (claimForm.stuffingDate === null) {
            claimForm.stuffingDate = "";
        }
        if (claimForm.cargo.eta === null) {
            claimForm.cargo.eta = "";
        }
        if (claimForm.cargo.etd === null) {
            claimForm.cargo.etd = "";
        }
        if (claimForm.gateOutDate === null) {
            claimForm.gateOutDate = "";
        }
        if (claimForm.ata === null) {
            claimForm.ata = "";
        }

        let fechaHarvest, fechaPacking;
        if (claimForm.cargo?.fruits[0]?.fruits?.harvesting.length > 0) {
            fechaHarvest = claimForm.cargo?.fruits[0].fruits.harvesting[0].harvestDate.split("T")[0]
        } else {
            fechaHarvest = ""
        }
        if (claimForm.cargo?.fruits[0]?.fruits?.packaging.length > 0) {
            fechaPacking = claimForm.cargo?.fruits[0].fruits.packaging[0].packageDate.split("T")[0]
        } else {
            fechaPacking = ""
        }

        let cambios = [];

        if (forFinalChanges.eta !== claimForm.cargo.eta) {
            //console.log(claimForm.cargo.eta)
            cambios.push(`ETA ${changeFrom}${forFinalChanges.eta === "" ? "---" : forFinalChanges.eta}` + `${changeTo} ` + claimForm.cargo.eta)
        }
        if (forFinalChanges.etd !== claimForm.cargo.etd) {
            //console.log(claimForm.cargo.etd)
            cambios.push(`ETD ${changeFrom}${forFinalChanges.etd === "" ? "---" : forFinalChanges.etd}` + `${changeTo} ` + claimForm.cargo.etd)
        }
        if (forFinalChanges.gateOut !== claimForm.gateOutDate) {
            //console.log(claimForm.gateOutDate)
            cambios.push(`Gate Out ${changeFrom}${forFinalChanges.gateOut === "" ? "---" : forFinalChanges.gateOut}` + `${changeTo} ` + claimForm.gateOutDate)
        }
        if (forFinalChanges.ata !== claimForm.ata) {
            //console.log(claimForm.ata)
            cambios.push(`ATA ${changeFrom}${forFinalChanges.ata === "" ? "---" : forFinalChanges.ata}` + `${changeTo} ` + claimForm.ata)
        }
        if (forFinalChanges.stuffingDate !== claimForm.stuffingDate) {
            cambios.push(`Stuffing Date ${changeFrom}${forFinalChanges.stuffingDate === "" ? "---" : forFinalChanges.stuffingDate}` + ` ${changeTo} ` + claimForm.stuffingDate)
        }
        if (!tienenMismaInformacion) {
            cambios.push(`${initialLang === 'es' ? 'Daños ' : 'Damages '}${changeFrom}"` + firstDescription + `"${changeTo}"` + secondDescription + '"')
        }
        if (forFinalChanges.fechaPacking !== fechaPacking) {
            //console.log(fechaPacking)
            cambios.push(`PackingDate ${changeFrom}${forFinalChanges.fechaPacking === "" ? "---" : forFinalChanges.fechaPacking}` + `${changeTo} ` + fechaPacking)
        }
        if (forFinalChanges.fechaHarvest !== fechaHarvest) {
            //console.log(fechaHarvest)
            cambios.push(`HarvestDate ${changeFrom}${forFinalChanges.fechaHarvest === "" ? "---" : forFinalChanges.fechaHarvest}` + `${changeTo} ` + fechaHarvest)
        }
        if (cambios.length > 0) {
            //console.log("CAMBIOS", cambios)
            setCambios(cambios)
            setModalFinalizar(true);
        } else {
            setCambios([]);
            handleUpdate(e);
        }
    }
    const location = useLocation();
    const handleUpdate = async (e, data) => {
        if (data) {
            claimForm = data;
            setClaimForm(data);
            location.state.oneClaimAll = data;
        }

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token.toString().replace("Bearer ", "")
            }
        }
        let formData = new FormData();

        arrFile.forEach((file) => {

            //reemplazar creditnotes,extraexpenses y salesresults con numero a sin numero
            let regex = /(creditnotes|extraexpenses|salesresults|marketprices)[0-9]/;
            if (regex.test(file.name.split("_")[0])) {
                //console.log(file)
                let name = file.name.split("_")[0].replace(/[0-9]/, '');
                let restName = file.name.replace(regex, "");
                let newFile = new File([file], name + restName, {type: file.type});
                formData.append("files", newFile);
                //console.log(newFile)
            } else {
                formData.append('files', file);
                //console.log(file)
            }
        });
        let url = `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me/${claimForm.id}`;
        if (imRsk) {
            url = `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${claimForm.id}`;
            //url = `http://127.0.0.1:9002/api/v1/claim/${claimForm.id}`;
        }
        claimForm.status = 1;
        let finalData = claimForm
        let newconsignee = finalData.consigneeName.replace(",", "")
        let newpol = finalData.pol.replace(",", "")
        let newpod = finalData.pod.replace(",", "")
        //console.log("POL", newpol)
        finalData.pol = newpol
        finalData.pod = newpod
        finalData.consigneeName = newconsignee
        if (finalData.surveyFee === null) {
            finalData.surveyFee = 0
        }

        const json = JSON.stringify(finalData);
        const blob = new Blob([json], {
            type: 'application/json'
        });
        formData.append('data', blob);

        if (finalData.timeline?.length > 0) {
            //console.log(finalData.timeline[finalData.timeline?.length-1])
            if (finalData.timeline[finalData.timeline?.length - 1].id !== 10 && (finalData.documents?.shippingDocuments?.length === 0 || finalData.documents?.shipping?.length === 0)) {
                toast.warn(`${traduccion.others.notEmptyDoc}`, {
                    autoClose: 3000,
                });
                throw new Error(`${traduccion.others.notEmptyDoc}`);
            }
        }

        await axios.put(url, formData, config) //PUT de claim
            // axios.put(`https://4355-2800-150-11e-2e87-597b-a529-6d26-ad58.sa.ngrok.io/api/v1/claim/${claimForm.id}`, formData, config)
            //CLAIM RSK (sin me)
            .then((response) => {
                if (e === "surveyReport") {

                }

                function renameKey(obj, oldKey, newKey) {
                    obj[newKey] = obj[oldKey];
                    delete obj[oldKey];
                }

                if (response.data.documentsEntity.documentsTypeEntityList.length > 0) {

                    let responseDocuments = response.data.documentsEntity.documentsTypeEntityList
                    const newArray = responseDocuments.map(({id, ...keepAttrs}) => keepAttrs)
                    newArray.forEach(item => renameKey(item, 'name', 'filename'))
                    let newDocuments = {"shippingDocuments": newArray}

                    setDocuments(newDocuments)
                    // let claimAddDoc = claimForm
                    // claimAddDoc.documents.shippingDocuments = newDocuments.shippingDocuments

                    //setFilesClaim(response.data.documentsEntity);
                    let documentsEntities = {
                        "shippingDocuments": newDocuments.shippingDocuments || [],
                        "consolidatedReport": response.data.documentsEntity.consolidatedReport || "",
                        "controlledAtmosphere": response.data.documentsEntity.controlledAtmosphere || "",
                        "thermograph": response.data.documentsEntity.thermograph || "",
                        "thermograph2": response.data.documentsEntity.thermograph2 || "",
                        "other": response.data.documentsEntity.other || "",
                        "assignmentLetter": response.data.documentsEntity.assignmentLetter || "",
                        "assignmentRights": response.data.documentsEntity.assignmentRights || "",
                        "survey": response.data.documentsEntity.survey || "",
                        "photograph": response.data.documentsEntity.photograph || "",
                        "notice": response.data.documentsEntity.notice || "",
                        "destruction": response.data.documentsEntity.destruction || "",
                        "receipt": response.data.documentsEntity.receipt || "",
                        "settlement": response.data.documentsEntity.settlement || "",
                        "invoice": response.data.documentsEntity.invoice || "",
                        "calculation": {
                            "creditNote": response.data.documentsEntity?.calculationDocumentEntityList?.filter(doc => doc.name.startsWith("CREDITNOTES")).map(doc => doc.name) || [],
                            "extraExpenses": response.data.documentsEntity?.calculationDocumentEntityList?.filter(doc => doc.name.startsWith("EXTRAEXPENSES")).map(doc => doc.name) || [],
                            "marketPrices": response.data.documentsEntity?.calculationDocumentEntityList?.filter(doc => doc.name.startsWith("MARKETPRICES")).map(doc => doc.name) || [],
                            "salesResults": response.data.documentsEntity?.calculationDocumentEntityList?.filter(doc => doc.name.startsWith("SALESRESULTS")).map(doc => doc.name) || []
                        },
                    };

                    setFilesClaim(documentsEntities);

                    setClaimForm({...claimForm, ...documentsEntities});
                }

                // setStuff(null)
                setModalFinalizar(false);
                if (e === "PRE_REQUEST" || e === "silence") {
                    //console.log("Reclamo actualizado correctamente")
                } else {
                    toast.success(`${traduccion?.success?.claimUpdated}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
                if (e === "inspector") {
                    setShowModal(false)
                    setKey("inspector")
                }
                formData.delete("files")
                setArrFile([])
                //setDocuments(response.data.documentsEntity.documentsTypeEntityList);
            }).catch((error) => {
                //console.log(error)
                if (error?.response?.data?.message !== undefined) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                } else {
                    toast.error(`${traduccion?.errores?.errActualizaReclamo}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }

            })
    }

    //console.log("CLAIMFORM", claimForm)

    const validate = () => {

        let errores = {};
        if (errors.cajasTotal) {
            errores.cajasTotal = errors.cajasTotal
        }
        if (errors.cajasTotalE) {
            errores.cajasTotalE = errors.cajasTotalE
        }
        if (errors.cajasTotalFin) {
            errores.cajasTotalFin = errors.cajasTotalFin
        }
        if (errors.cajasTotalFinE) {
            errores.cajasTotalFinE = errors.cajasTotalFinE
        }
        setErrors(errores)
        //console.log(errors)
        const blAwbCrt = claimForm.transportId === 1 ? "BL" : claimForm.transportId === 2 ? "AWB" : "CRT";


        if (claimForm.transportId === 2) {
            if (claimForm.blAwb.split("|||")[0] === "" || claimForm.blAwb.split("|||")[0] === null) {
                errores.blAwb1 = "AWB code is required"
            }
            if (claimForm.blAwb.split("|||")[1] === "" || claimForm.blAwb.split("|||")[1] === null) {
                errores.blAwb2 = "AWB number is required"
            }
        }
        if (claimForm.blAwb.split("-")[0] === "" || claimForm.blAwb.split("-")[0] === null) {
            errores.blAwb1 = "BL code is required"
        }
        if (claimForm.blAwb.split("-")[1] === "" || claimForm.blAwb.split("-")[1] === null) {
            errores.blAwb2 = "BL number is required"
        }
        if (claimForm?.transportId !== 3) {
            if (claimForm?.vessel === "" || claimForm?.vessel === null) {
                errores.vessel = claimForm.transportId === 2 ? "Airline is required" : "Vessel is required"
            }
        }
        if (claimForm?.blAwbDate === "" || claimForm?.blAwbDate === null) {
            errores.blAwbDate = `${blAwbCrt} date is required`
        }
        if (claimForm?.blAwb === "" || claimForm?.blAwb === null) {
            errores.blAwb = claimForm?.transportId !== 3 ? "BL/AWB is required" : "CRT is required"
        }
        if (claimForm?.pol === "" || claimForm?.pol === null) {
            errores.pol = "POL is required"
        }
        if (claimForm?.pod === "" || claimForm?.pod === null) {
            errores.pod = "POD is required"
        }
        if (claimForm?.exporterName === "" || claimForm?.exporterName === null) {
            errores.exporterName = "Exporter is required"
        }
        // if (claimForm?.shipperName === "" || claimForm?.shipperName === null) {
        //     errores.shipperName = "Shipper is required"
        // }
        if (claimForm?.consigneeName === "" || claimForm?.consigneeName === null) {
            errores.consigneeName = "Consignee is required"

        }
        if ((claimForm?.carrierName === "" || claimForm?.carrierName === null) && claimForm?.transportId !== 2) {
            errores.carrierName = "Carrier is required"
        }
        if (imRsk) {
            if (claimForm?.ata === "" || claimForm?.ata === null) {
                errores.ata = "ATA is required"
            }
            if (claimForm?.timeBar === "" || claimForm?.timeBar === null) {
                errores.timeBar = "TimeBar is required"
            }
        }
        if (claimForm?.transportId !== 2) {
            if (claimForm?.cargo?.containerNumber === "" || claimForm?.cargo?.containerNumber === null) {
                errores.containerNumber = claimForm.transportId === 3 ? "License Plate is required" : "Container number is required";
            }
            if (claimForm?.cargo?.temperature === "" || claimForm?.cargo?.temperature === null) {
                errores.temperature = "Temperature is required"
            }
            if (claimForm?.cargo?.ventilation === "" || claimForm?.cargo?.ventilation === null) {
                errores.ventilation = "Ventilation is required"
            }
        }

        if (claimForm?.cargo?.damages?.length === 0) {
            errores.damages = "A damage type is required"
        }
        if (claimForm?.cargo?.reclaimedAmount === "" || claimForm?.cargo?.reclaimedAmount === null) {
            errores.reclaimedAmount = "Reclaimed Amount is required"
        }
        if (claimForm?.cargo?.fruits?.length === 0) {
            errores.fruits = "Shippment Details are required"
        } else if (claimForm?.cargo?.fruits?.length > 0) {
            claimForm.cargo.fruits.forEach((fruit, index) => {
                if (fruit?.boxes === "" || fruit?.description === null) {
                    errores.boxes = "Number of boxes are required"
                }
                if (fruit?.id === "" || fruit?.id === null) {
                    errores.especie = "Species are required"
                }
            })
            claimForm.cargo.fruits.forEach(fruit => {
                if (fruit?.boxes === "" || fruit?.harvestDate === null) {
                    errores.boxes = "Boxes cannot be null"
                }
                if (fruit?.id === "" || fruit?.packageDate === null) {
                    errores.especie = "Specie cannot be null"
                }
            })

        }

        if (claimForm?.cargo?.damagesId?.includes(3)) {
            if (claimForm?.cargo?.etd === "" || claimForm?.cargo?.etd === null) {
                errores.etd = "ETD is required"
            }
            if (claimForm?.cargo?.eta === "" || claimForm?.cargo?.eta === null) {
                errores.eta = "ETA is required"
            }
        }
        if (claimForm.documents?.shipping) {
            if (claimForm?.documents?.shipping.length > 0) {
                claimForm.documents.shippingDocuments = claimForm.documents.shipping
            }
        }
        //console.log(claimForm?.documents?.shippingDocuments)
        if (claimForm?.documents?.shippingDocuments !== undefined) {

            if (claimForm.documents?.shippingDocuments?.length === 0) {
                errores.shippingDocuments = "Shipping Documents are required"
            } else if (claimForm.documents.shippingDocuments?.length > 0) {
                let mustHaveBL = false;

                let mustHaveFile = false;
                let mustHavePackingList = false;

                //Haciendolo de otra manera con .some
                let mustHaveInvoice = claimForm.documents.shippingDocuments.some((doc) => {
                    return doc.invoice === 1 || doc.invoice === true
                })
                for (const claimFormElement of claimForm.documents.shippingDocuments) {
                    //console.log(claimFormElement)
                    if (claimFormElement.filename !== "") {
                        mustHaveFile = true;

                        if (claimFormElement.bl === 1 || claimFormElement.bl === true) {
                            mustHaveBL = true;
                        }
                        // if (claimFormElement.invoice === 1 || claimFormElement.invoice === true) {
                        //     mustHaveInvoice = true;
                        // }
                        if (claimFormElement.packingList === 1 || claimFormElement.packingList === true) {
                            mustHavePackingList = true;
                        }
                    }
                }
                //console.log("mustHaveBL", mustHaveBL)
                //console.log("mustHaveInvoice", mustHaveInvoice)
                if (mustHaveFile === false) {
                    errores.shippingDocuments = "There is a empty file in Shipping Documents"
                } else if (!mustHaveBL || !mustHaveInvoice || !mustHavePackingList) {
                    errores.shippingDocuments = "Shipping Documents must have BL, Invoice and Packing List"
                }
            }
        } else {
            errores.shippingDocuments = "Shipping Documents are required"
        }
        setErrors(errores)
        //console.log(errors)
        return Object.keys(errores).length === 0;
    }
    useEffect(() => {
        validate()
        //console.log("VALIDANDO" + {claimForm})
    }, [claimForm]);

    const handleKey = (k) => {
        if (k === "chat") {
            setInChat(true)
        } else {
            //console.log("NO ESTA EN CHAT")
            setInChat(false)
        }
        if (k === "historico") {
            let header = {
                headers: {
                    Authorization: localStorage.getItem("token")?.toString().replace("Bearer ", "")
                }
            }
            axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me/logs/${claimForm.id}`, header)
                .then((res) => {
                    setTheLogs(res.data)
                }).catch((error) => {
                //console.log(error);
            })
        }
        if (key === k) {
            return;
        }
        if (k === "inspector") {

            if (claimForm.lastTimelineId !== 10 && claimForm.lastTimelineId !== 5 && claimForm.lastTimelineId !== undefined) {
                //console.log("NO ENTRO A MODAL")
                setKey(k);
            } else if (validate()) {
                //console.log("VALIDANDO INFORMERECLAMOTABS"+ {claimForm})
                setShowModal(true)
            } else {
                //console.log("ACA LOS ERRORES COMO VAN A SALIR")
                //console.log(errors)
                toast.warn(`${Object.keys(errors).map((k, id) => {

                    return (`${id === 0 ? errors[k] : "\n" + errors[k]}`)
                })}`, {
                    style: {
                        whiteSpace: 'pre-line'
                    },
                    position: toast.POSITION.TOP_RIGHT
                })
            }
            setInInspector(false)
        }

            // else if(key==="carga" || key==="documentos"){
        //     setKey(key);
        else {
            setInInspector(true)
            setKey(k);
        }


    }

    const handleModal = () => {
        setInInspector(true)
        handleUpdate("inspector")
    }

    const saveFinalizatedOrUpdateDocument = (e) => {
        if (claimForm?.lastTimelineId !== 4 && claimForm?.lastTimelineId !== 19) {
            //console.log(claimForm)
            handleUpdate()
        } else {
            handleUpdateModalFinalized()
        }
    }


    const handleDownloadMuchosFile = (e) => {
        // Create a new JSZip object
        const zip = new JSZip();
        setLoadingFile(true)
        // Create a list of promises to download the files
        const promises = e.map(file => {
            return axios({
                url: `${urlConfig.allUrl}:9004/api/v1/files/download?file=${file}`,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    'Authorization': localStorage.getItem("token")
                }
            }).then(response => {
                // Add the file to the ZIP file
                zip.file(file, response.data);
            }).catch(error => {
                //console.log(error);
                setLoadingFile(false)
            });
        });

        // Wait for all the promises to resolve

        Promise.all(promises).then(() => {
            // Generate the ZIP file and trigger the download
            zip.generateAsync({type: "blob"}).then(content => {
                // Trigger the download
                //fecha de hoy en string
                const date = new Date().toLocaleDateString().split("/").join("-")
                saveAs(content, `RSK${yearRsk}${claimForm?.id} ${claimForm?.cargo?.containerNumber}-${claimForm?.transportId !== 3 ? claimForm?.vessel : claimForm?.carrierName}.zip`);
            });
            setLoadingFile(false)
            setShowModalDes(false)
        });
    }


    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const handleModalDes = () => {
        const filenames = [];
        let index = 0;
        for (const key in claimForm?.documents) {
            if ((key.includes('shipping') && !key.includes('shippingDocuments')) || key === 'photograph' || key === 'survey' || key === 'noticeDate') continue;
            if (claimForm?.documents.hasOwnProperty(key)) {
                const value = claimForm?.documents[key];
                if (typeof value === 'object') {
                    if (Array.isArray(value)) {
                        value.map((e) => {
                            if (e.filename.includes('shipping')) return;
                            filenames.push(e.filename);
                        })
                    } else {
                        for (const key2 in value) {
                            if (value.hasOwnProperty(key2)) {
                                const value2 = value[key2];
                                if (typeof value2 === 'object' && value2.length > 0) {
                                    value2.map((e) => {
                                        filenames.push(e);
                                    })
                                }
                            }
                        }
                    }
                } else if (typeof value === 'string' && value) {
                    filenames.push(value);
                }
            }
            index++;
        }
        setModalData(filenames)
        if (filenames.length > 0) {
            setShowModalDes(true)
        } else {
            toast.error(`${traduccion?.errores?.noDocs}`)
        }

    }

    //console.log(claimForm?.documents)

    //para el billing
    useEffect(() => {
        if (goToInspector === true) {
            setKey("inspector")
            setGoToInspector(false)
        }
    }, [])

    useEffect(() => {
        if (!editRsk && !editExporter) {
            //console.log(claimForm)
            axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${claimForm.id}`, {
                headers: {
                    Authorization: token.toString().replace("Bearer ", "")
                }
            }).then((res) => {
                //console.log("EDITANDO RECLAMO...", res.data)
                let newData = {...startClaim, ...res.data}
                if (newData.comment === null) {
                    newData.comment = ""
                }
                if (newData.claimDate === null) {
                    newData.claimDate = ""
                }
                if (newData.lossTypeId === null) {
                    newData.lossTypeId = 1
                }
                if (newData.cargo?.fruits !== undefined) {
                    for (const fruits of newData.cargo.fruits) {
                        for (const harvest of fruits.fruits.harvesting) {
                            if (harvest.harvestDate === null) {
                                harvest.harvestDate = ""
                            }
                        }
                        for (const packa of fruits.fruits.packaging) {
                            if (packa.packageDate === null) {
                                packa.packageDate = ""
                            }
                        }
                    }
                }
                newData.documents = {
                    "shippingDocuments": res.data.documents?.shipping || [],
                    "shipping": res.data.documents?.shipping || [],
                    "consolidatedReport": res.data.documents?.consolidatedReport || "",
                    "controlledAtmosphere": res.data.documents?.controlledAtmosphere || "",
                    "thermograph": res.data.documents?.thermograph || "",
                    "thermograph2": res.data.documents?.thermograph2 || "",
                    "other": res.data?.documents.other || "",
                    "assignmentLetter": res.data.documents?.assignmentLetter || "",
                    "assignmentRights": res.data.documents?.assignmentRights || "",
                    "survey": res.data.documents?.surveyor?.survey || "",
                    "photograph": res.data.documents?.surveyor?.photograph || "",
                    "notice": res.data.documents?.surveyor?.notice || "",
                    "noticeDate": res.data.documents?.surveyor?.noticeDate || "",
                    "destruction": res.data.documents?.surveyor?.destruction || "",
                    "receipt": res.data.documents?.recovery?.receipt || "",
                    "settlement": res.data.documents?.recovery?.settlement || "",
                    "invoice": res.data.documents?.invoice || "",
                    calculation: res.data.documents?.calculation || {
                        creditNote: [],
                        extraExpenses: [],
                        marketPrices: [],
                        salesResults: [],
                    },

                };

                if (newData.lossCalculation.length === 0) {
                    //console.log("NO HAY CALCULO DE PERDIDA")
                    newData.lossCalculation = startClaim.lossCalculation
                } else {
                    //console.log("SI HAY CALCULO DE PERDIDA")
                    //console.log("CALCULO DE PERDIDA", res.data.lossCalculation)
                    //console.log("CALCULO DE PERDIDA", newData)
                    newData.lossCalculation = res.data.lossCalculation
                }
                //console.log("NEW DATA", newData)
                if (location.state?.lossCalculation !== undefined) {
                    //console.log(location.state?.lossCalculation)
                    newData.lossCalculation = location.state?.lossCalculation;
                }
                if (location.state?.id) {
                    newData.id = location.state?.id;
                }
                if (location.state?.oneExporter?.id) {
                    //console.log(location.state?.oneExporter)
                    newData.exporterName = location.state?.oneExporter?.companyName;
                    newData.companyName = location.state?.oneExporter?.companyName;
                    newData.companyId = location.state?.oneExporter?.id;
                }
                setClaimForm(newData);
                handleUpdate("silence", newData)
            }).catch((error) => {
                //console.log(error);
            })
        }


    }, []);


    return (
        <>
            <ToastContainer/>

            <div className={styles.all_tabs}>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={handleKey}
                    className={styles.tabs}
                >
                    <Tab eventKey="carga" title={`${traduccion.tabs?.carga}`}>
                        <CargaTab fechaHar={fechaHar} fechaPack={fechaPack}
                                  setFechaHar={setFechaHar} setFechaPack={setFechaPack}
                                  claimForm={claimForm} setClaimForm={setClaimForm}
                            // stuff={stuff} setStuff={setStuff}
                                  errors={errors} setErrors={setErrors}
                                  transporte={transporte} validate={validate}
                                  imRsk={imRsk}/>
                    </Tab>
                    <Tab eventKey="documentos" title={`${traduccion.tabs?.documentos}`}>
                        <DocumentosTab claimForm={claimForm} setClaimForm={setClaimForm}
                                       arrFile={arrFile} setArrFile={setArrFile}
                                       documents={documents} setDocuments={setDocuments}
                                       handleDocuments={handleDocuments}
                                       filesClaim={filesClaim} setFilesClaim={setFilesClaim}/>
                    </Tab>
                    <Tab eventKey="inspector" title={`${traduccion.tabs?.inspector}`}>
                        {
                            claimForm?.hasMap ?
                                <InspectorTab claimForm={claimForm} setClaimForm={setClaimForm}
                                              arrFile={arrFile} setArrFile={setArrFile}
                                              handleDocuments={handleDocuments}
                                              filesClaim={filesClaim}
                                              inInspector={inInspector}
                                              handleUpdate={handleUpdate}
                                              fechaHar={fechaHar} fechaPack={fechaPack}
                                              surveyDate={surveyDate} setSurveyDate={setSurveyDate}
                                              imRsk={imRsk}
                                />
                                :
                                <InspectorTabNoMap claimForm={claimForm} setClaimForm={setClaimForm}
                                                   arrFile={arrFile} setArrFile={setArrFile}
                                                   handleDocuments={handleDocuments}
                                                   filesClaim={filesClaim}

                                />
                        }
                    </Tab>
                    {(claimForm.lastTimelineId !== 10 && claimForm.lastTimelineId !== 19 && claimForm.lastTimelineId !== 4 && claimForm.lastTimelineId !== undefined && claimForm.hasMap) &&
                        <Tab eventKey={"chat"} title={"Chat"}>
                            <ChatSurveyor claimForm={claimForm} inChat={inChat} setInChat={setInChat}/>
                        </Tab>
                    }
                    <Tab eventKey="perdidas" title={`${traduccion.tabs?.perdidas}`}>
                        <CalculoPerdidas claimForm={claimForm} setClaimForm={setClaimForm}
                                         arrFile={arrFile} setArrFile={setArrFile}
                                         documents={documents} setDocuments={setDocuments}
                                         handleDocuments={handleDocuments}
                                         filesClaim={filesClaim} setFilesClaim={setFilesClaim}
                                         imRsk={imRsk}/>
                    </Tab>
                    <Tab eventKey="timeline" title="Timeline">
                        <LineaTiempo fechaHar={fechaHar} fechaPack={fechaPack}
                                     setFechaHar={setFechaHar} setFechaPack={setFechaPack}
                                     claimForm={claimForm} setClaimForm={setClaimForm} imRsk={imRsk}
                                     surveyDate={surveyDate}/>
                    </Tab>
                    <Tab eventKey="recupero" title={traduccion?.exporter?.recupero}>
                        <Recupero claimForm={claimForm} setClaimForm={setClaimForm}
                                  arrFile={arrFile} setArrFile={setArrFile}
                                  handleDocuments={handleDocuments}
                                  filesClaim={filesClaim} setFilesClaim={setFilesClaim}
                                  imRsk={imRsk}/>
                    </Tab>
                    <Tab eventKey="historico" title={traduccion?.exporter?.historico} imRsk={imRsk}>
                        <Historico claimForm={claimForm} setClaimForm={setClaimForm} theLogs={theLogs}/>
                    </Tab>


                </Tabs>
            </div>
            <div style={{marginTop: '2rem'}}>
                <Button onClick={saveFinalizatedOrUpdateDocument} style={{margin: "0 2rem 0 0", padding: ".5rem 1rem"}}
                        variant="primary">{traduccion.tabs?.guardar}</Button>

                <Button variant={"secondary"} style={{marginLeft: "2rem 0 2rem 30px", padding: ".5rem 1rem"}}
                        onClick={handleModalDes}>{traduccion?.inspector?.desscargadeDocumentos}</Button>
            </div>
            <ModalTabInspector
                claimForm={claimForm} setClaimForm={setClaimForm}
                show={showModal}
                handleModal={handleModal}
                onHide={() => setShowModal(false)}
            />
            <ModalDescargaTodo
                arr={modalData}
                showDes={showModalDes}
                descargaAll={handleDownloadMuchosFile}
                onHide={() => setShowModalDes(false)}
                loading={loadingFile}
            />
            <ModalUpdateFinalized
                cambios={cambios}
                show={modalFinalizar}
                onHide={() => setModalFinalizar(false)}
                handleUpdate={handleUpdate}
            />


        </>
    );
}
