import {Document, Page, Text, View,StyleSheet, Font} from '@react-pdf/renderer';
import fuenteTitulo from './fonts/PlayfairDisplaySC.ttf';
import fuenteTexto from './fonts/Montserrat-Light.ttf';
import fuenteTextoBold from './fonts/Montserrat-Medium.ttf';


// Register font
Font.register({ family: 'PlayfairDisplaySC', src: fuenteTitulo });
Font.register({ family: 'Montserrat-Light', src: fuenteTexto });
Font.register({ family: 'Montserrat-Medium', src: fuenteTextoBold });

// Reference font
const styles = StyleSheet.create({
    title: {
        marginTop:60,
        marginBottom:60,
        fontFamily: 'PlayfairDisplaySC',
        fontSize: 24,
    },
    claimData: {
        fontSize: 13,
        marginLeft: 58,
        textAlign:'left',
        fontFamily: 'Montserrat-Medium',
    },
    fullText: {
        textAlign:'justify',
        fontSize: 13,
        margin:58,
        fontFamily: 'Montserrat-Light',
    },
    footer:{
        fontFamily: 'Montserrat-Medium',
        marginTop:250,
        textAlign:'left',
        borderTop:'1px solid black',
        marginRight:150,
        paddingTop:10,
    }

})
//fecha de hoy para poner en el texto
const today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
const yyyy = today.getFullYear();
const fecha = dd + '/' + mm + '/' + yyyy;

export const LetterOfAuthority = ({claimForm,especie}) => {

    let arr = [];
    let str = "";
    //console.log(claimForm)
    if (claimForm?.cargo?.fruits.length > 0) {
        claimForm?.cargo?.fruits.forEach((fruit) => {
            if (fruit.id !== 0 && fruit.id !== "") {
                arr.push(fruit.id);
            }
        })
    }

    if (arr.length > 0 && especie.length > 0) {
        arr.forEach((id) => {
            especie.forEach((especie) => {
                if (parseInt(id) === especie.id) {
                    str += especie.description + ", ";
                }
            })
        })
        str = str.substring(0, str.length - 2);
    }
    return (
        <Document>
            <Page size="A4" style={{textAlign:'center'}}>
                <View>
                    <Text style={styles.title}>Letter of Authority</Text>
                </View>
                <View style={styles.claimData}>
                    <Text>
                        M/Vessel: {claimForm?.vessel}
                    </Text>
                    <Text>
                        POL: {claimForm?.pol}
                    </Text>
                    <Text>
                        POD: {claimForm?.pod}
                    </Text>
                    <Text>
                        BL - AWB - CRT: {claimForm?.blAwb}
                    </Text>
                    <Text>
                        Container Number: {claimForm?.cargo?.containerNumber}
                    </Text>
                    <Text>
                        Product: {str}
                    </Text>
                    <Text>
                        Date: {fecha}
                    </Text>
                </View>
                <View style={styles.fullText}>
                    <Text style={{marginBottom:20}}>
                        We do hereby appoint Messrs. <Text style={{display:'inline', fontFamily: 'Montserrat-Medium'}}>RSK Chile</Text> as our recovery agents and to do all acts that may be deemed necessary in the course of the recovery for the above claim.                    </Text>
                    <Text>
                        They are further authorized to negotiate, compromise, settle and receive payment on our behalf in respect of the above claim.                    </Text>
                    <Text style={styles.footer}>
                        {claimForm?.exporterName}
                    </Text>
                </View>
            </Page>
        </Document>
    )
}
