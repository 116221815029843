import React, {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import styles from "../surveyors/mantenedor_surveyor.module.css";
import {Link} from "react-router-dom";
import {Button, Form, Pagination, Spinner, Table} from "react-bootstrap";
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import {BsArrowCounterclockwise, BsPencil, BsSearch, BsTrash} from "react-icons/bs";
import DBContext from "../../../context/DBContext";
import {NewDamages} from "./NewDamages";
import LangContext from "../../../context/LangContext";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import Pager from "../orden_cobro/Pager";
import FiltroDamages from "./FiltroDamages";
import {toast} from "react-toastify";

export const Damages = ({setRouteActive, newDamages, setNewDamages, listaStatus}) => {
    const {damage, cambioDamage, setCambioDamage} = useContext(DBContext);
    const [oneDamage, setOneDamage] = useState({});
    const [form, setForm] = useState({});
    const {traduccion} = useContext(LangContext);
    //Paginacion
    const [perPage, setPerPage] = useState(10);
    const [active, setActive] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [items, setItems] = useState([]);

    const [damageFullEjemplo, setDamageFullEjemplo] = useState(damage);
    const [damageSearch, setDamageSearch] = useState(damage);


    const [loading, setLoading] = useState(true);

    const [damageList, setDamageList] = useState([]);

    const initialDescription = useRef(true);
    const initialDamageStatus = useRef(true);
    const isSearchUrl = useRef(false);

    const [description, setDescription] = useState(true);
    const [damageStatus, setDamageStatus] = useState(true);

    //const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/damage?page=0&size=${perPage}`);
    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/damage/search?page=0&size=${perPage}`);

    const [searchUrl, setSearchUrl] = useState(null);

    const searchDmg = (url) => {
        setSearchUrl(url)
    };

    useEffect(() => {
        if (searchUrl === null) return;
        searchDamages(searchUrl);
    }, [searchUrl])

    const handleNewDamage = () => {
        setNewDamages(1)
        setOneDamage({})
    }
    const goToStart = () => {
        setRouteActive("/")
    }

    const searchDamages = (url) => {
        setLoading(true);
        let searchUrlSize = searchUrl.toString().includes("size=") ? searchUrl : searchUrl + `&size=${perPage}`;
        return axios.get(searchUrlSize.toString(), {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            setDamageList(res.data.damages)
            setTotalPages(res.data.totalPages - 1)
            isSearchUrl.current = true;
        }).catch((error) => {
            console.log({error})
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        }).finally(() => {
            setLoading(false);
        });
    }

    const editDamage = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.get(`${urlConfig.damageUrl}${id}`, header)
            .then((response) => {
                let dataRes = response.data
                //console.log(dataRes)
                // setNewDamages(dataRes);
                setNewDamages(1)
                setOneDamage(dataRes)
                // //console.log(dataRes)
            }).catch((error) => {
            //console.log(error);
        })
    }

    const deleteDamage = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.delete(`${urlConfig.damageUrl}${id}`, header).then((response) => {
            setCambioDamage(!cambioDamage);
            toast.success(`${traduccion?.mantendores?.elimSuccess}`)
        }).catch((error) => {
            console.log({error});
            toast.error(`${traduccion?.mantendores?.elimError}`)
        })
    }

    const goToPage = (page) => {
        if (isSearchUrl.current) {
            let paginatedUrl = searchUrl.toString().includes("page=") ? searchUrl.toString().replace(/page=\d+/, `page=${page}`) : searchUrl + `&page=${page}`
            paginatedUrl = paginatedUrl.includes("size=") ? paginatedUrl : paginatedUrl + `&size=${perPage}`;
            setUrl(paginatedUrl)
            setActive(page)
        } else {
            let paginatedUrl = url.replace(/page=\d+/, `page=${page}`);
            setUrl(paginatedUrl)
            setActive(page)
        }
    }

    useEffect(() => {
        if (initialDamageStatus.current) {
            initialDamageStatus.current = false;
            return;
        }
        if (!damageStatus) {
            if (!url.includes("sort=damageStatus,desc") && url.includes("sort=damageStatus,asc")) {
                setUrl(url.replace("&sort=damageStatus,asc", "&sort=damageStatus,desc"));
            } else {
                setUrl(url + "&sort=damageStatus,desc");
            }
        } else {
            if (!url.includes("sort=damageStatus,asc") && url.includes("sort=damageStatus,desc")) {
                setUrl(url.replace("&sort=damageStatus,desc", "&sort=damageStatus,asc"))
            } else {
                setUrl(url + "&sort=damageStatus,asc");
            }
        }
    }, [damageStatus]);

    useEffect(() => {
        if (initialDescription.current) {
            initialDescription.current = false;
            return;
        }
        if (!description) {
            if (!url.includes("sort=description,desc") && url.includes("sort=description,asc")) {
                setUrl(url.replace("&sort=description,asc", "&sort=description,desc"));
            } else {
                setUrl(url + "&sort=description,desc");
            }
        } else {
            if (!url.includes("sort=description,asc") && url.includes("sort=description,desc")) {
                setUrl(url.replace("&sort=description,desc", "&sort=description,asc"))
            } else {
                setUrl(url + "&sort=description,asc");
            }
        }
    }, [description]);

    useEffect(() => {
        setLoading(true);
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.get(url, header).then((response) => {
            setDamageList(response.data.damages)
            setTotalPages(response.data.totalPages - 1)
        }).catch((error) => {
            console.log({error})
        }).finally(() => {
            setLoading(false);
        })
    }, [url]);

    const resetSort = () => {
        //setUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/damage?page=0&size=${perPage}`)
        initialDescription.current = true;
        initialDamageStatus.current = true;
        setDescription(true);
        setDamageStatus(true);
        setUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/damage/search?page=0&size=${perPage}`)
    }

    const sortParams = (sort) => {
        if (sort === "description") {
            setDescription(!description);
        }
        if (sort === "damageStatus") {
            setDamageStatus(!damageStatus);
        }
    }

    return (
        <>
            <div className={styles.all_surveyor}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>>
                    {newDamages === 0 &&
                        <span className={styles.bold}>{traduccion?.mantendores?.danios}</span>
                    }
                    {(newDamages === 1 && oneDamage.id !== undefined) &&
                        <><span onClick={() => setNewDamages(0)}
                                className={styles.like_a}>{traduccion?.mantendores?.danios}</span>>
                            <span className={styles.bold}>{traduccion?.mantendores?.editDanio}</span></>
                    }
                    {(newDamages === 1 && oneDamage.id === undefined) &&
                        <><span onClick={() => setNewDamages(0)}
                                className={styles.like_a}>{traduccion?.mantendores?.danios}</span>>
                            <span className={styles.bold}>{traduccion?.mantendores?.nuevoDanio}</span></>
                    }
                </div>
                {newDamages === 0
                    &&
                    <>
                        <div className={styles.title_surveyor}>
                            <p>{traduccion?.mantendores?.mantenedorDeDanios}</p>
                            <Button className={styles.title_button} variant="primary"
                                    onClick={handleNewDamage}>{traduccion?.mantendores?.nuevoDanio}</Button>
                        </div>
                        {/*------------ Filtro ------------*/}
                        <FiltroDamages searchDmg={searchDmg}/>
                        <div className={stylesTabla.contiene_tabla}>
                            <Table borderless hover className={stylesTabla.tabla}>
                                <thead>
                                <tr>
                                    <th>{traduccion?.mantendores?.danios} <a
                                        onClick={() => sortParams("description")}> {description ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th>{traduccion?.clients?.status} <a
                                        onClick={() => sortParams("damageStatus")}> {damageStatus ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th><a onClick={() => resetSort()}><BsArrowCounterclockwise/></a></th>
                                </tr>
                                </thead>
                                <tbody style={{position: "relative"}}>
                                {
                                    loading && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor: "rgb(255,255,255)",
                                                opacity: 0.5,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                zIndex: 9999,
                                            }}
                                        >
                                            <Spinner animation="border" size="sm"/>
                                        </div>
                                    )}
                                {
                                    damageList?.map((datos, idx) =>
                                        <tr key={idx} className={stylesTabla.tr_data}>
                                            <td>{datos.description}</td>
                                            <td>{datos.damageStatus ? `${traduccion?.mantendores?.activo}` : `${traduccion?.mantendores?.inactivo}`}</td>
                                            <td>
                                                <div className={stylesTabla.botones_botones_surveyor}
                                                     style={{display: 'flex'}}>
                                                    <div onClick={() => editDamage(datos.id)}
                                                         className={stylesTabla.boton_icono}>
                                                        <BsPencil/>
                                                    </div>
                                                    <div
                                                        onClick={() => deleteDamage(datos.id)}
                                                        className={stylesTabla.boton_icono}>
                                                        <BsTrash style={{color: 'red'}}/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                        <Pager totalPages={totalPages} active={active} goToPage={goToPage}/>
                    </>

                }

                {newDamages === 1 &&
                    <NewDamages oneDamage={oneDamage} setCambioDamage={setCambioDamage} cambioDamage={cambioDamage}/>}
            </div>
        </>
    );
}
