import React, {useContext, useEffect, useState} from "react";
import imagen from "../default_logo.png";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import {toast, ToastContainer} from "react-toastify";
import stylesSurv from "../surveyors/new_surveyor.module.css";
import {Button, Col, Form, Nav, Row} from "react-bootstrap";
import styles from "../company_surveyors/new_company.module.css";
import DBContext from "../../../context/DBContext";
import {Typeahead} from "react-bootstrap-typeahead";
import {forEach} from "react-bootstrap/ElementChildren";
import LangContext from "../../../context/LangContext";
import {onlyNumbers} from "../../../config/functions";

export const NewExporter = ({setNewExporter,oneExporter}) => {
    const {exporter,setCambioExporter,cambioExporter,paisesRates} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);

    const [profileImage, setProfileImage] = useState(imagen);
    const [imageFile, setImageFile] = useState(null);
    const [exporterList, setExporterList] = useState([{id: 0, companyName: "Loading..."}]);
    const [errors, setErrors] = useState({});

console.log("JSA: oneExporter",oneExporter)

    const [exporterObj, setExporterObj] = useState({
        id: "",
        companyName: "",
        logo: "",
        address: "",
        vatId: "",
        phone: "",
        legalRepresentative: "",
        email: "",
        optionalEmail: "",
        secondaryEmail: "",
        contract: "",
        startPeriod: "",
        endPeriod: "",
        season: "",
        containerFee: 0,
        status: 1,
        // accountId: 0,
        hasContract:true,
        country:"",
        exporters: [],
        //stuffing: [{id: 2}, {id: 1}]
    });

    useEffect(() => {
        if(oneExporter !== "") {
            setExporterObj({
                ...exporterObj,
                id: oneExporter.id,
                companyName: oneExporter.companyName,
                logo: oneExporter.logo,
                address: oneExporter.address,
                vatId: oneExporter.vatId,
                phone: oneExporter.phone,
                country: oneExporter.countryEntity.id,
                legalRepresentative: oneExporter.legalRepresentative,
                email: oneExporter.email,
                optionalEmail: oneExporter.optionalEmail,
                secondaryEmail: oneExporter.secondaryEmail,
                contract: oneExporter.contract,
                startPeriod:oneExporter.startPeriod?.slice(0, 10)?? null,
                endPeriod:oneExporter.endPeriod?.slice(0, 10) ?? null,
                season: oneExporter.season,
                containerFee: oneExporter.containerFee,
                status: oneExporter.status,
                // accountId: oneExporter.id,
                stuffing: oneExporter.stuffing,
                exporters: oneExporter.exporters ?? [],
                hasContract:oneExporter.hasContract ?? true


            })
            if (oneExporter !== "") {
                setProfileImage(oneExporter.logo);
            }
        }
    },[oneExporter]);

    const handlePreviewImg = (e) => {
        if (e.target?.files.length > 0) {
            const file = e.target.files[0];
            const fileUrl = URL.createObjectURL(file);
            setProfileImage(fileUrl);
            setImageFile(file);
            // setIsImage(true);
            setExporterObj({...exporterObj, photo: file})
        }
    }

    const uploadImage = () => {
        //console.log("uploadImage")
        const file = imageFile;

        if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/jpg") {
            throw new Error('Formato de imagen inválido.');
        }
        if (file.size > 20000000) {
            throw new Error('Imagen excede el tamaño permitido (20mb).');
        }

        const image = new File([file], `LOGO_${file.name}`, {type: file.type});
        let formData = new FormData();
        formData.append('file', image);
        //console.log("formData", formData)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                //'Authorization': process..env.REACT_APP_TOKEN_RSK
                'Authorization': "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIzY2E3YWI2ZC0wOGZiLTQ3MDMtYTI0Yi1hZjNiNzYwYTFiOWMiLCJzdWIiOiJ2Y2VsaXNAcnNrY2hpbGUuY29tIiwicm9sIjoiUk9MRV9SU0siLCJpYXQiOjE2Njg0MzAxNTAsImV4cCI6MjA2ODQ2NjE1MH0.r7MyaqiC-2wpKv6Ai6gmJnDFaFL8_lPEZvl44n2aed0"
            }
        }
        return axios.post(`${urlConfig.filesUrl}/img`, formData, config);
    }

    const verifyErrors = (e) => {

        // const vatIdRegex = new RegExp("^[A-Z]{1}[0-9]{8}$");
        let errors = {};
        if (!exporterObj.companyName) {
            errors.companyName = "Debe ingresar el nombre de la empresa";
        }
        if (exporterObj.hasContract) {
            if (!exporterObj.containerFee) {
                errors.containerFee = "Debe ingresar el valor del tarifa por contenedor";
            }
            if (!exporterObj.email) {
                errors.email = "Debe ingresar el correo electrónico de la empresa";
            }
            if (!exporterObj.startPeriod) {
                errors.startPeriod = "Debe ingresar la fecha de inicio de la temporada de la empresa";
            }
            if (!exporterObj.endPeriod) {
                errors.endPeriod = "Debe ingresar la fecha de fin de la temporada de la empresa";
            }
            if (!exporterObj.address) {
                errors.address = "Debe ingresar la dirección de la empresa";
            }
            if (!exporterObj.vatId) {
                errors.vatId = "Debe ingresar el Vat id de la empresa";
            }
            if (!exporterObj.phone) {
                errors.phone = "Debe ingresar el teléfono de la empresa";
            }
            if (!exporterObj.legalRepresentative) {
                errors.legalRepresentative = "Debe ingresar el representante legal de la empresa";
            }

            if (!exporterObj.country) {
                errors.country = "Debe ingresar el país de la empresa";
            }

        }
        setErrors(errors);
        return errors;
    }

    const handleChanges = (e) => {
        verifyErrors()
        setExporterObj({
            ...exporterObj,
            [e.target.name]: e.target.value
        })
    }
    const uploadImageAndForm = () => {

        let arrErrors = verifyErrors();
        if (Object.keys(arrErrors).length !== 0) {
            //console.log(arrErrors)
            toast.warn(`${Object.keys(arrErrors).map((key, id) => {

                return (`${id === 0 ? arrErrors[key] : "\n" + arrErrors[key]}`)
            })}`, {
                style: {
                    whiteSpace: 'pre-line'
                },
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }
        let finalMix;
        let header =  {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", "")
            }
        }
        //Nuevo sin foto y si se está editando ya venía sin foto
        if (imageFile === null && oneExporter.logo === "") {
            //console.log("Adjunte imagen")
            return false;
        }
        // //console.log(exporterObj)
        // Editando sin agregar foto
        if (oneExporter !== "" && imageFile === null) {
            console.log("Editando sin foto")
            console.log("exporterObj", exporterObj)
            return axios.put(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/${oneExporter.id}`, exporterObj, header)
            //return axios.put(`${urlConfig.urlKite}/api/v1/exporter/${oneExporter.id}`, exporterObj, header)
                .then((res) => {
                    setCambioExporter(!cambioExporter);

                    //console.log("res", res)
                    toast.success(`${traduccion?.success?.successActComp}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }).catch((error) => {
                    //console.log(error.response.data.message);
                    if (error.response.data.message.includes("Username cannot be changed")) {
                        toast.error(`${traduccion?.errores?.userChange}`, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    } else if(error.response.data.message.includes("Username already exists")){
                        toast.error(`${traduccion?.errores?.userExist}`, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }else if(error.response.data.message){
                        toast.error(error.response.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }else{
                        toast.error(`${traduccion?.errores?.editComp}`, {
                            position: toast.POSITION.TOP_RIGHT
                        })

                    }
                })
        }else{
            if(imageFile === null){
                toast.warn(`${traduccion?.warning?.mustAddImage}`);
                return ;
            }
            //Nuevo con foto o editando con foto
            uploadImage().then((res) => {
                const {data} = res.data;
                //console.log(res)
                finalMix = {...exporterObj, logo: data[0]};
            }).catch((error) => {
                //console.log(error);
            }).then(() => {
                //console.log(oneExporter.id !== undefined )
                //console.log(arrErrors.length)
                if (oneExporter.id !== undefined) {
                    //console.log("Editando con foto")
                    //console.log(finalMix)
                    return axios.put(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/${oneExporter.id}`, finalMix, header)
                    //return axios.put(`${urlConfig.urlKite}/api/v1/exporter/${oneExporter.id}`, finalMix, header)
                        .then((res) => {
                            setCambioExporter(!cambioExporter);
                            //console.log("res", res)
                            toast.success(`${traduccion?.success?.successActComp}`, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }).catch((error) => {
                            //console.log(error);
                            toast.error(`${traduccion?.errores?.editComp}`, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        })
                }else if(arrErrors.length > 0){
                    toast.warning(`${traduccion?.warning?.fillAllFields}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }else{
                    return axios.post(`${urlConfig.local_url}${urlConfig.portClaim}/api/v1/exporter/`, finalMix, header)
                    //return axios.post(`${urlConfig.urlKite}/api/v1/exporter/`, finalMix, header)
                    .then((res) => {
                            //console.log("res", res)
                            setCambioExporter(!cambioExporter);

                            toast.success(`${traduccion?.success?.successNewComp}`, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }).catch((error) => {
                            //console.log(error.response.data.message);
                            if (error.response.data.message.includes("Email already exist")) {
                                toast.error(`${traduccion?.errores?.mailExist}`)
                            }else if (error.response.data.message.includes("Account already")) {
                                toast.error(`${traduccion?.errores?.accExist}`)
                            }
                            else if(error.response.data.message) {
                                toast.error(error.response.data.message, {
                                    position: toast.POSITION.TOP_RIGHT
                                })
                            }
                            else{
                                toast.error(`${traduccion?.errores?.errorCreacomp}`, {
                                    position: toast.POSITION.TOP_RIGHT
                                })
                            }
                        })
                }
            })
        }
    }

    const handleCheckContract = (e) => {
        setExporterObj({
            ...exporterObj,
            containerFee: "",
            startPeriod: null,
            endPeriod: null,
            hasContract: e.target.checked
        })
    }
    const handleGetCompanyList = (e) => {
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/company`, {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        })
            .then((res) => {
                setExporterList(res.data)
            }).catch((error) => {
                //console.log(error)
            })
    }


    const handlePushCompany = (e) => {
        if (!isNaN(parseInt(e.target.value))) {
            if (exporterObj.exporters.some((item) => item.id === parseInt(e.target.value))) {
                //console.log("Compania ya existe")
            } else {
                setExporterObj({
                    ...exporterObj,
                    exporters: [...exporterObj.exporters, {id: parseInt(e.target.value)}]
                })
            }
        }
        e.target.value = "";
    }
    const handleDeleteCompany = (numeroId) => {
        //console.log("numeroId", numeroId)
        //console.log("exporterObj", exporterObj.exporters)
        setExporterObj({
            ...exporterObj,
            exporters: exporterObj.exporters.filter((item) => item.id !== parseInt(numeroId))
        })
    }
    //console.log("exporterObj", exporterObj)
    const companyName = (id) => {
        const comp = exporterList.find((item) => item.id === id);

        //const comp = exporter.find((item) => item.id === id);
        //console.log(exporter)
        //console.log("comp", comp)
        //console.log("id", id)
        if (comp !== undefined ){
            return comp.companyName
        }else{
            return ""
        }
    }

    return (
        <>
            <ToastContainer/>
            <div style={{width:'100%',margin:0,padding:0}}>
                <div className={stylesSurv.title_surveyor} style={{marginBottom:15}}>
                    <div>{oneExporter !== "" ? `${traduccion?.clients?.editEmp}` : `${traduccion?.clients?.creaEmp}`}</div>
                </div>

                <Row className={stylesSurv.contain_all_surveyors}>

                    <Col sm={11} md={5} xl={3}>
                        <div style={{display:'flex', flexDirection:'column', gap:'1.8rem'}}>

                            <Form.Group>
                                <Form.Select  className={styles.selects_input_details} style={{marginBottom:0}} onChange={handleChanges} value={exporterObj.status} name={"status"}>
                                    <option value={1}>{traduccion?.clients?.active}</option>
                                    <option value={2}>{traduccion?.clients?.inactive}</option>
                                </Form.Select>
                            </Form.Group>

                            <div className={stylesSurv.all_surveyors}  >
                                <div className={stylesSurv.title}>{traduccion?.clients?.compLogo}</div>
                                <div>
                                    <div className={styles.photo}>
                                        <label className={styles.photocon} htmlFor="file-input">
                                            <div>
                                                {/*<BiUpload style={{fontSize:44}}/>*/}
                                                <img style={{backgroundColor:'transparent'}} src={profileImage} alt="logo"/>
                                            </div>
                                        </label>
                                        <input style={{display: 'none'}} onChange={handlePreviewImg} id="file-input" type="file" accept={"image/*"} name="photo"/>

                                    </div>
                                </div>
                            </div>

                            <div className={stylesSurv.all_surveyors}>
                                <div className={stylesSurv.title}>{traduccion?.clients?.contractDetails}</div>
                                <Form.Check
                                    type="switch"
                                    checked={exporterObj.hasContract}
                                    label={exporterObj.hasContract ? `${traduccion?.clients.expoCC}` : `${traduccion?.clients.expoSC}`}
                                    onClick={handleCheckContract}
                                    style={{margin: '1rem 0'}}
                                >
                                </Form.Check>
                                <div>{traduccion?.clients?.feeConta}</div>
                                <Form.Group controlId="fee">
                                    <Form.Control
                                        value={exporterObj.containerFee}
                                        onChange={handleChanges}
                                        className={exporterObj.hasContract? styles.selects_input_details:styles.selects_input_details_disabled}
                                        type="text"
                                        onInput={onlyNumbers}
                                        placeholder="Container Fee" name="containerFee"
                                        disabled={!exporterObj.hasContract}
                                    />
                                </Form.Group>
                                <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                                    <div>
                                        <label htmlFor="startPeriod">{traduccion?.clients.startPeriod}</label>
                                        <Form.Control onChange={handleChanges}
                                                      name="startPeriod"
                                                      value={exporterObj.startPeriod}
                                                      className={exporterObj.hasContract? styles.selects_input_details:styles.selects_input_details_disabled}                                                      max={exporterObj.endPeriod}
                                                      type="date"/>
                                    </div>
                                    <div>
                                        <label htmlFor="endPeriod">{traduccion?.clients.endPeriod}</label>
                                        <Form.Control onChange={handleChanges}
                                                      name="endPeriod"
                                                      value={exporterObj.endPeriod}
                                                      className={exporterObj.hasContract? styles.selects_input_details:styles.selects_input_details_disabled}                                                      min={exporterObj.startPeriod}
                                                      type="date"/>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/*<div className={stylesSurv.all_surveyors} style={{marginTop:20}}>*/}
                        {/*<div className={stylesSurv.title}>{traduccion?.clients?.contractDetails}</div>*/}

                        {/*</div>*/}

                    </Col>

                    <Col sm={11} md={7} xl={9}>
                        <div className={stylesSurv.all_surveyors}>
                            <Col sm={12} lg={9}>
                                <div className={stylesSurv.title}>{traduccion?.clients?.contactDetails}</div>
                                <div>{traduccion?.clients?.compName}</div>
                                <Form.Group controlId="companyName">
                                    <Form.Control
                                        value={exporterObj.companyName}
                                        onChange={handleChanges}
                                        className={styles.selects_input_details} type="text"
                                        placeholder={traduccion?.clients?.compName} name="companyName"/>
                                </Form.Group>
                                <div>{traduccion?.clients?.address}</div>
                                <Form.Group controlId="address">
                                    <Form.Control
                                        value={exporterObj.address}
                                        onChange={handleChanges}
                                        className={styles.selects_input_details}  type="text"
                                        placeholder={traduccion?.clients?.address} name="address"/>
                                </Form.Group>
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <Form.Group controlId="vatId" style={{marginRight:40}}>
                                        <div>VAT ID</div>
                                        <Form.Control
                                            value={exporterObj.vatId}
                                            onChange={handleChanges}
                                            className={styles.selects_input_details} type="text"
                                            placeholder="VAT ID" name="vatId"/>
                                    </Form.Group>
                                    <Form.Group controlId="phone">
                                        <div>{traduccion?.clients?.phone}</div>
                                        <Form.Control
                                            value={exporterObj.phone}
                                            onChange={handleChanges}
                                            className={styles.selects_input_details}  type="text"
                                            placeholder={traduccion?.clients?.phone} name="phone"/>
                                    </Form.Group>
                                </div>
                                <div>
                                    <label htmlFor="container_select">{traduccion?.clients?.country}</label>
                                    <Form.Select onChange={handleChanges} value={exporterObj.country} className={styles.selects_input_details} name="country">
                                        <option selected={exporterObj.country===""}>{traduccion?.clients?.selectCountry}</option>
                                        {paisesRates && paisesRates.map((pais, index) => (
                                                <option key={index} value={pais.id}>{pais.country}</option>
                                            ))}
                                        </Form.Select>
                                </div>
                                <div>{traduccion?.clients?.legalRep}</div>
                                <Form.Group controlId="legalRepresentative">
                                    <Form.Control
                                        value={exporterObj.legalRepresentative}
                                        onChange={handleChanges}
                                        className={styles.selects_input_details}  type="text"
                                        placeholder={traduccion?.clients?.legalRep} name="legalRepresentative"/>
                                </Form.Group>
                                <div>{traduccion?.clients?.pMail}</div>
                                <Form.Group controlId="email">
                                    <Form.Control
                                        value={exporterObj.email}
                                        onChange={handleChanges}
                                        className={styles.selects_input_details} type="email"
                                        style={{maxWidth:700}}
                                        placeholder={traduccion?.clients?.pMail} name="email"/>
                                </Form.Group>
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <Form.Group controlId="vatId" style={{marginRight:40}}>
                                        <div>{traduccion?.clients?.optionalMail}</div>
                                        <Form.Control
                                            value={exporterObj.optionalEmail}
                                            onChange={handleChanges}
                                            className={styles.selects_input_details} type="text"
                                            placeholder={traduccion?.clients?.optionalMail} name="optionalEmail"/>
                                    </Form.Group>
                                    <Form.Group controlId="phone">
                                        <div>{traduccion?.clients?.secondaryMail}</div>
                                        <Form.Control
                                            value={exporterObj.secondaryEmail}
                                            onChange={handleChanges}
                                            className={styles.selects_input_details} type="text"
                                            placeholder={traduccion?.clients?.secondaryMail} name="secondaryEmail"/>
                                    </Form.Group>
                                </div>
                            </Col>
                        </div>
                    </Col>
                    {
                    exporterObj.hasContract &&
                    <Row style={{marginTop:'2rem'}}>
                        <div className={stylesSurv.all_surveyors} style={{padding:'2rem'}}>
                            <Row sm={12} lg={9}>
                                <div className={stylesSurv.title}>{traduccion?.clients?.affCompany}</div>
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <Form.Group controlId="vatId" style={{marginRight:40}}>
                                        <div>{traduccion?.clients?.compName}</div>
                                        <Form.Select
                                            onClick={handleGetCompanyList}
                                            onChange={handlePushCompany}
                                            className={styles.selects_input_details} type="text"
                                            placeholder={traduccion?.clients?.compName} name="companyName">

                                            <option selected={true} value="">{traduccion?.clients?.selectcompany}</option>
                                            {exporterList?.length>0 && exporterList.map((company) => (
                                                <option key={company.id}
                                                        value={parseInt(company.id)}>{company.companyName}</option>
                                            ))}
                                        </Form.Select>

                                    </Form.Group>
                                </div>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <div style={{display:"flex", justifyContent:"start",flexWrap:'wrap'}}>
                                        {
                                            exporterObj.exporters.map((company, index) => (
                                                <div key={index} className={styles.buttons_companies}>
                                                    <div style={{width:"100%"}}>{company.companyName?? companyName(company.id)}</div>
                                                    <Button variant={"outline-primary"} size={"sm"} onClick={()=>handleDeleteCompany(company.id)}>x</Button>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    }
                    {oneExporter === "" ?
                        <div style={{display:"flex",justifyContent:"end"}}>
                            <Button onClick={uploadImageAndForm}
                                    style={{width: 200, margin: '1.5rem 2rem 3rem 2rem', padding: '1rem 2rem'}}>
                                {traduccion?.clients?.save}
                            </Button>
                        </div>
                        :
                        <div style={{display:"flex",justifyContent:"end"}}>
                            <Button onClick={uploadImageAndForm}
                                    style={{width: 200, margin: '1.5rem 2rem 3rem 2rem', padding: '1rem 2rem'}}>
                                {traduccion?.clients?.edit}

                            </Button>
                        </div>
                    }

                </Row>

            </div>
        </>
    )
}
