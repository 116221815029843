import React, {useContext, useEffect, useRef, useState} from "react";
import DBContext from "../../../context/DBContext";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import styles from "../../home/table_stuffing/tabla_stuff.module.css";
import {Table} from "react-bootstrap";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import {BsPencil, BsTrash} from "react-icons/bs";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import LangContext from "../../../context/LangContext";

export const TablaExporters = ({setNewExporter, setOneExporter, exporter, setCambioExporter, cambioExporter, getExportersSort}) => {
    // const {exporter,setCambioExporter,cambioExporter} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);

    const initialNameOrder = useRef(true);
    const initialPeriodOrder = useRef(true);
    const initialEmailOrder = useRef(true);
    const initialFeeOrder = useRef(true);
    const initialStatusOrder = useRef(true);

    const [nameOrder, setNameOrder] = useState(true);
    const [periodOrder, setPeriodOrder] = useState(true);
    const [emailOrder, setEmailOrder] = useState(true);
    const [feeOrder, setFeeOrder] = useState(true);
    const [statusOrder, setStatusOrder] = useState(true);

    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/search?page=0&size=${20}`);

    useEffect(() => {
        if (initialNameOrder.current) {
            initialNameOrder.current = false;
            return;
        }
        if (!nameOrder) {
            if (!url.includes("sort=cn,desc") && url.includes("sort=cn,asc")) {
                setUrl(url.replace("&sort=cn,asc", "&sort=cn,desc"));
            } else {
                setUrl(url + "&sort=cn,desc");
            }
        } else {
            if (!url.includes("sort=cn,asc") && url.includes("sort=cn,desc")) {
                setUrl(url.replace("&sort=cn,desc", "&sort=cn,asc"))
            } else {
                setUrl(url + "&sort=cn,asc");
            }
        }
    }, [nameOrder]);

    useEffect(() => {
        if (initialPeriodOrder.current) {
            initialPeriodOrder.current = false;
            return;
        }
        if (!periodOrder) {
            if (!url.includes("sort=sp,desc") && url.includes("sort=sp,asc")) {
                setUrl(url.replace("&sort=sp,asc", "&sort=sp,desc"));
            } else {
                setUrl(url + "&sort=sp,desc");
            }
        } else {
            if (!url.includes("sort=sp,asc") && url.includes("sort=sp,desc")) {
                setUrl(url.replace("&sort=sp,desc", "&sort=sp,asc"))
            } else {
                setUrl(url + "&sort=sp,asc");
            }
        }
    }, [periodOrder]);

    useEffect(() => {
        if (initialFeeOrder.current) {
            initialFeeOrder.current = false;
            return;
        }
        if (!feeOrder) {
            if (!url.includes("sort=cf,desc") && url.includes("sort=cf,asc")) {
                setUrl(url.replace("&sort=cf,asc", "&sort=cf,desc"));
            } else {
                setUrl(url + "&sort=cf,desc");
            }
        } else {
            if (!url.includes("sort=cf,asc") && url.includes("sort=cf,desc")) {
                setUrl(url.replace("&sort=cf,desc", "&sort=cf,asc"))
            } else {
                setUrl(url + "&sort=cf,asc");
            }
        }
    }, [feeOrder]);

    useEffect(() => {
        if (initialEmailOrder.current) {
            initialEmailOrder.current = false;
            return;
        }
        if (!emailOrder) {
            if (!url.includes("sort=em,desc") && url.includes("sort=em,asc")) {
                setUrl(url.replace("&sort=em,asc", "&sort=em,desc"));
            } else {
                setUrl(url + "&sort=em,desc");
            }
        } else {
            if (!url.includes("sort=em,asc") && url.includes("sort=em,desc")) {
                setUrl(url.replace("&sort=em,desc", "&sort=em,asc"))
            } else {
                setUrl(url + "&sort=em,asc");
            }
        }
    }, [emailOrder]);

    useEffect(() => {
        if (initialStatusOrder.current) {
            initialStatusOrder.current = false;
            return;
        }
        if (!statusOrder) {
            if (!url.includes("sort=st,desc") && url.includes("sort=st,asc")) {
                setUrl(url.replace("&sort=st,asc", "&sort=st,desc"));
            } else {
                setUrl(url + "&sort=st,desc");
            }
        } else {
            if (!url.includes("sort=st,asc") && url.includes("sort=st,desc")) {
                setUrl(url.replace("&sort=st,desc", "&sort=st,asc"))
            } else {
                setUrl(url + "&sort=st,asc");
            }
        }
    }, [statusOrder]);

    useEffect(() => {
        getExportersSort(url);
    }, [url])

    const editCompanySurveyor = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token")?.toString().replace("Bearer ", "")
                // Authorization: localStorage.getItem("token"),
            }
        }

        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/${id}`, header)
            .then((response) => {
                let dataRes = response.data
                //console.log(dataRes)
                setOneExporter(dataRes);
                setNewExporter(1)
            }).catch((error) => {
            //console.log(error);
        })
    }
    const deleteCompanySurveyor = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token")?.toString().replace("Bearer ", "")
            }
        }

        axios.delete(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/${id}`, header)
            //axios.delete(`${urlConfig.urlKite}/api/v1/exporter/${id}`,header)

            .then(() => {
                setCambioExporter(!cambioExporter);
            }).catch((error) => {
            //console.log(error);
        })
    }

    const sortParams = (sort) => {
        if (sort === "cn") {
            setNameOrder(!nameOrder);
        }
        if (sort === "sp") {
            setPeriodOrder(!periodOrder);
        }
        if (sort === "cf") {
            setFeeOrder(!feeOrder);
        }
        if (sort === "em") {
            setEmailOrder(!emailOrder);
        }
        if (sort === "st") {
            setStatusOrder(!statusOrder);
        }
    }

    return (
        <>
            <div className={styles.contiene_tabla}>
                <Table borderless hover className={styles.tabla}>
                    <thead>
                    <tr>
                        <th>{traduccion?.clients?.name} <a onClick={() => sortParams("cn")}> {nameOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th className={styles.th_container_nr}>{traduccion?.clients?.period} <a
                            onClick={() => sortParams("sp")}> {periodOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.clients?.pMail} <a onClick={() => sortParams("em")}> {emailOrder ?
                            <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.clients?.feeConta} <a onClick={() => sortParams("cf")}> {feeOrder ?
                            <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.clients?.status} <a onClick={() => sortParams("st")}> {statusOrder ?
                            <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                    </tr>

                    </thead>
                    <tbody>

                    {exporter?.map((datos, idx) =>

                        <tr key={idx} className={stylesTabla.tr_data}>
                            <td>{datos.companyName}</td>
                            <td>{datos.startPeriod?.slice(0, 10)} / {datos.endPeriod?.slice(0, 10)}</td>
                            <td>{datos.email}</td>
                            <td>{datos.containerFee}</td>
                            <td>{datos.status === 1 ? `${traduccion?.mantendores?.activo}` : `${traduccion?.mantendores?.inactivo}`}</td>
                            <td>
                                <div className={stylesTabla.botones_botones_surveyor} style={{display: 'flex'}}>
                                    <div
                                        onClick={() => editCompanySurveyor(datos.id)}
                                        className={stylesTabla.boton_icono}>
                                        <BsPencil/>
                                    </div>
                                    <div
                                        onClick={() => deleteCompanySurveyor(datos.id)}
                                        className={stylesTabla.boton_icono}>
                                        <BsTrash style={{color: 'red'}}/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}
