import { Timeline, TimelineEvent } from "react-event-timeline";
import { RiCalendarLine } from "react-icons/ri";
import styles from "../documentos/documentos.module.css";

export const Historico = ({ theLogs }) => {
    let array = [];
    let orderLogs = [...theLogs];


    orderLogs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    // Función recursiva para filtrar nulos y arreglos vacíos
    const filterValues = (value) => {
        if (value === null) {
            // Excluir valores nulos
            return undefined;
        } else if (Array.isArray(value)) {
            // Filtrar recursivamente cada elemento del arreglo, excluyendo arreglos vacíos
            const filteredArray = value.map(filterValues).filter(item => item !== undefined);
            return filteredArray.length > 0 ? filteredArray : undefined;
        } else if (typeof value === 'object') {
            // Filtrar recursivamente cada propiedad del objeto, excluyendo objetos vacíos
            const filteredObject = Object.entries(value).reduce((acc, [key, subValue]) => {
                const filtered = filterValues(subValue);
                if (filtered !== undefined) {
                    acc[key] = filtered;
                }
                return acc;
            }, {});
            return Object.keys(filteredObject).length > 0 ? filteredObject : undefined;
        } else {
            // Devolver el valor si no es un objeto o arreglo (y no es nulo)
            return value;
        }
    };

    orderLogs.forEach((datos) => {
        try {
            let toJson = JSON.parse(datos.changes || '{}');
            let filteredChanges = {};

            Object.keys(toJson).forEach((key) => {
                const filteredValue = filterValues(toJson[key]);
                if (filteredValue !== undefined) {
                    filteredChanges[key] = filteredValue;
                }
            });

            if (Object.keys(filteredChanges).length > 0) {
                filteredChanges['user'] = datos.user;
                filteredChanges['created_at'] = new Date(datos.created_at).toLocaleString();
                array.push(filteredChanges);
            }
        } catch (e) {
            console.log('Error:', e);
        }
    });
//console.log(array)
    const renderData = (data) => {
        const renderEntries = (entries) => {
            return entries.map(([key, value], index) => {
                // Nueva lógica para manejar todos los tipos de datos
                let displayValue;
                if (typeof value === 'boolean' || typeof value === 'number') {
                    displayValue = value.toString();
                } else if (typeof value === 'string') {
                    displayValue = value;
                }

                if (['user', 'created_at'].includes(key)) {
                    return null; // No renderizar estos campos como parte de los cambios
                }

                if (displayValue) {
                    // Renderiza directamente el valor si es un string, número o booleano
                    return <li key={index}>{displayValue}</li>;
                } else if (Array.isArray(value)) {
                    // Si el valor es un arreglo, verifica si sus elementos son añadidos o eliminados
                    return (
                        <li key={`${key}-${index}`}>
                            {value.map((item, idx) => {
                                const prefix = item.added ? "[+] " : item.removed ? "[-] " : "";
                                const itemValue = typeof item === 'object' && (item.added || item.removed) ? item.value : item;

                                if (typeof itemValue === 'string') {
                                    return <div key={`${key}-${idx}`}>{prefix + itemValue}</div>;
                                } else {
                                    // Manejar recursivamente si el elemento es un objeto
                                    return Object.entries(itemValue).map(([itemKey, msg], msgIdx) => {
                                        if (!['user', 'created_at'].includes(itemKey)) {
                                            const itemPrefix = item.added ? "[+] " : item.removed ? "[-] " : "";
                                            return <div key={`${key}-${idx}-msg-${msgIdx}`}>{itemPrefix + msg}</div>;
                                        }
                                        return null;
                                    });
                                }
                            })}
                        </li>
                    );
                } else if (typeof value === 'object' && value !== null) {
                    // Si es un objeto, llama recursivamente para renderizar sus propiedades
                    return <li key={index}>{renderEntries(Object.entries(value))}</li>;
                }
                return null;
            }).filter(component => component !== null); // Filtramos los nulos resultantes de la exclusión
        };

        return (
            <ul>
                {renderEntries(Object.entries(data))}
            </ul>
        );
    };

    return (
        <>
            <div style={{ margin: '2rem', paddingTop: '2rem', maxHeight: '80vh', overflowY: 'scroll' }} className={styles.gradient_historico}>
                <div className={styles.title} style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>Logs</div>
                {
                    array.length > 0 ? (
                        array.map((data, idx) => {
                            return (
                                <Timeline lineColor={'#aaf'} key={idx}>
                                    <TimelineEvent style={{ fontWeight: '400' }}
                                                   createdAt={data.created_at}
                                                   icon={<RiCalendarLine style={{ color: '#88e' }} />}
                                                   contentStyle={{
                                                       background: '#eef',
                                                       border: '1px solid #bbf',
                                                       boxShadow: '3px 3px 6px #22226666'
                                                   }} bubbleStyle={{ background: '#eef', borderColor: '#aaf' }}
                                                   title={""}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ fontWeight: 'bold', marginRight: 5 }}>User:</div>
                                                <div><span>{data.user}</span></div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                            }}>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ fontWeight: 'bold' }}>Changes:</div>
                                                <ul>{renderData(data)}</ul>
                                            </div>
                                        </div>
                                    </TimelineEvent>
                                </Timeline>
                            )
                        })
                    ) : (
                        <></>
                    )
                }
            </div>
        </>
    );
}