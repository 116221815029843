import React, {useContext, useEffect, useState} from "react";
import DBContext from "../../../context/DBContext";
import {urlConfig} from "../../../config/urlConfig";
import {toast, ToastContainer} from "react-toastify";
import stylesSurv from "../surveyors/new_surveyor.module.css";
import {Button, Form, Table} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import stylesCountry from "../country/countries.module.css";
import styles from "../company_surveyors/new_company.module.css";
import axios from "axios";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import {BsPencil, BsTrash} from "react-icons/bs";
import LangContext from "../../../context/LangContext";

export const NewSpecie = ({oneSpecie}) => {
    let {cambioEspecie, setCambioEspecie, especie, damage} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);

    const [specieObj, setSpecieObj] = useState({
        description: "",
        statusId: 1,
        damagesId: [],
        damages: [],
        newVariety: [],
        currentVariety: []
    });
    const listaStatus = [`${traduccion?.mantendores?.activo}`, `${traduccion?.mantendores?.inactivo}`];
    const [nuevaVariedad, setNuevaVariedad] = useState("");
    const [nuevaVariedadList, setNuevaVariedadList] = useState([]);
    const [damages, setDamages] = useState([])
    const [objVariety, setObjVariety] = useState([]);

    const [damageList, setDamageList] = useState([]);

    // const damageList = [1,2]
    // //console.log(oneSpecie.damages)
    const fakeEntityList = [{id: 1, description: "Fake1", status: 1}, {id: 2, description: "Fake2", status: 2}]

    useEffect(() => {
        setSpecieObj({
            ...specieObj,
            id: oneSpecie.id,
            description: oneSpecie.description,
            statusId: oneSpecie.statusId,
            damagesId: oneSpecie.damagesId,
            damages: oneSpecie.damagesId,
            newVariety: [],
            currentVariety: oneSpecie.variety,
        })
    }, [oneSpecie]);

    useEffect(() => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/damage`, header).then((response) => {
            setDamageList(response.data)
        }).catch((error) => {
            console.log({error})
        })
    }, []);

    const damageArrToObj = (arr) => {
        if (damages.length === 0) {
            let obj = []
            arr.forEach((item) => {
                //console.log(item)
                for (let i = 0; i < damage.length; i++) {
                    if (item === damage[i].id) {
                        obj.push({id: damage[i].id, description: damage[i].description})
                    }
                }
            })
            return obj;
        }
        return damages;
    }

    useEffect(() => {
        if (specieObj?.damagesId?.length > 0) {
            setDamages(damageArrToObj(specieObj.damagesId))
            //console.log(damageArrToObj(specieObj.damagesId))
        }
    }, [specieObj])

    const handleSpecie = (e) => {
        let value = e.target.value;
        if (e.target.name === "statusId") {
            value = parseInt(value);
        }
        setSpecieObj({
            ...specieObj,
            [e.target.name]: value,
        })
    }
    const editable = (idx) => {
        let listaNueva = nuevaVariedadList
        listaNueva[idx].editable = true;

        // setNuevaVariedadList(listaNueva)
        setSpecieObj({
            ...specieObj
        })
    }

    const handleNuevaVariedad = (e) => {
        setNuevaVariedad(e.target.value);
    }

    const addNewVariety = () => {
        //console.log(specieObj.newVariety)

        //Array con objetos
        let listaNueva = nuevaVariedadList
        listaNueva.push({description: nuevaVariedad, editable: false})

        // Array con textos
        let listaNueva2 = specieObj.newVariety
        listaNueva2.push(nuevaVariedad)
        //console.log(listaNueva2)

        setNuevaVariedadList(listaNueva)
        setSpecieObj({
            ...specieObj,
            newVariety: listaNueva2,
        })
    }

    const handlePost = () => {
        //console.log(specieObj)
        let finalObj = specieObj;
        finalObj.damagesId = arrayDamageIds();
        //console.log("finalObj",finalObj)
        //get the token from the local storage
        let token = localStorage.getItem("token");
        const noBearerToken = token.toString().replace("Bearer ", "")
        let header = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': noBearerToken
            }
        }
        if (oneSpecie?.id !== undefined) {
            axios.put(urlConfig.especieUrl + oneSpecie.id, finalObj, header)
                // axios.put(urlConfig.urlNgrok + oneSpecie.id, finalObj, header)

                .then((response) => {
                    setCambioEspecie(!cambioEspecie);
                    toast.success(`${traduccion?.success?.successActSpec}`);
                })
                .catch((error) => {
                    toast.error(`${traduccion?.errores?.acrSpec}`);
                })
        } else if (specieObj.name !== "" && specieObj.status !== "") {
            axios.get(urlConfig.especieUrl, header)
                //            axios.get(urlConfig.urlNgrok, header)

                .then((response) => {
                    let dataRes = response.data;
                    //console.log(dataRes);
                    let especieList = dataRes.map((especie) => especie.description);
                    if (especieList.includes(specieObj.description)) {
                        toast.error(`${traduccion?.errores?.specExist}`);
                    } else {
                        axios.post(urlConfig.especieUrl, specieObj, header)
                            .then((response) => {
                                setCambioEspecie(!cambioEspecie);
                                toast.success(`${traduccion?.success?.successNewSpec}`);
                            })
                            .catch((error) => {
                                toast.error(`${traduccion?.errores?.newSpec}`);

                            })
                    }
                }).catch((error) => {
                //console.log(error);
            })

        }
    }


    const editSpecieNew = (e) => {
        let id = e.target.id;
        //console.log(id)
        let value = e.target.value;
        //console.log(value)

        let listaNueva = specieObj.newVariety;
        listaNueva[id] = value;
        //console.log(listaNueva)
        setSpecieObj({
            ...specieObj,
            newVariety: listaNueva,
        })
    }
    const deleteSpecieNew = (e) => {
        let id = e
        let listaNueva = specieObj.newVariety;
        listaNueva.splice(id, 1);
        //console.log(listaNueva)
        setSpecieObj({
            ...specieObj,
            newVariety: listaNueva,
        })
    }

    const editableCurrent = (idx) => {
        let listaNueva = specieObj.currentVariety;
        listaNueva[idx].editable = true;
        setSpecieObj({
            ...specieObj
        })
    }
    const editSpecieCurrent = (e) => {
        let id = e.target.id;
        //console.log(id)
        let value = e.target.value;
        //console.log(value)

        let listaNueva = specieObj.currentVariety;
        listaNueva[id].description = value;
        setSpecieObj({
            ...specieObj,
            currentVariety: listaNueva,
        })
    }

    const deleteSpecieCurrent = (e) => {
        let id = e
        let listaNueva = specieObj.currentVariety;
        listaNueva.splice(id, 1);
        setSpecieObj({
            ...specieObj,
            currentVariety: listaNueva,
        })
    }
    const arrayDamageIds = (e) => {
        let ids = [];
        //console.log(damages)

        damages?.map(item => {
            ids.push(item.id)
        })
        return ids;
    }

    const handleDamage = (lista) => {
        setDamages(lista);
        //console.log("Lista de danio",lista)
        let ids = [];
        lista?.map(item => {
            ids.push(item.id)
        })
        setSpecieObj({
            ...specieObj,
            damages: ids,
        })
    }

    const handleSearch = (e) => {
        let value = e.target.value;
        let listaVariedades = specieObj.currentVariety;
        let listaNueva = listaVariedades.filter(item => item.description.toLowerCase().includes(value.toLowerCase()))
        setObjVariety(listaNueva)
    }

    useEffect(() => {
        setObjVariety(specieObj.currentVariety)
    }, [specieObj.currentVariety]);


    //
    //console.log("objeto",specieObj);
    // //console.log("palabra",nuevaVariedad)
    //console.log("lista",nuevaVariedadList)
    //
    //console.log("listDamage",damage)
    //console.log("damages",damages)
    //console.log("objVariety",objVariety)

    return (
        <>
            <ToastContainer/>
            <div className={stylesSurv.title_surveyor} style={{marginBottom: 15}}>
                {/*<div>{oneSpecie !== "" ? "Editar" : "Crear"} Empresa</div>*/}
                {<div>{traduccion?.mantendores?.mantenedorDeEspecies}</div>}

            </div>
            <div className={stylesSurv.all_surveyors} style={{
                display: 'flex',
                height: 'auto',
                justifyContent: 'left',
                columnGap: '10%',
                width: '100%',
                marginTop: '2rem'
            }}>
                <div>
                    <div className={stylesSurv.title_surveyor} style={{justifySelf: 'left'}}>
                        {oneSpecie.description !== undefined ?
                            <p>{traduccion?.mantendores?.editEspecie}</p> :
                            <p>{traduccion?.mantendores?.nuevaEspecie}</p>
                        }
                    </div>
                    <div>{traduccion?.mantendores?.nombreEsp}</div>
                    <Form.Group controlId="nombre">
                        <Form.Control
                            value={specieObj.description}
                            onChange={handleSpecie}
                            className={styles.selects_input_details} type="text"
                            placeholder={traduccion?.mantendores?.nombreEsp} name="description"/>
                    </Form.Group>
                    <div>{traduccion?.mantendores?.estado}</div>

                    <Form.Select onChange={handleSpecie} className={styles.selects_input_details} id="estado"
                                 name="statusId">
                        <option value="" defaultValue={true}>{traduccion?.clients?.selectOption}</option>

                        {listaStatus.map((item, idx) => {
                            return (
                                <option key={idx} value={idx + 1}
                                        selected={idx + 1 === specieObj.statusId}>{item}</option>
                            )
                        })}
                    </Form.Select>

                    <Button onClick={handlePost}>
                        {traduccion?.mantendores?.saveChanges}
                    </Button>
                </div>
                <div>
                    <div className={stylesSurv.title_surveyor} style={{justifySelf: 'left'}}>
                        {oneSpecie.name !== undefined ?
                            <p>{traduccion?.mantendores?.agregarVari}</p> :
                            <p>{traduccion?.mantendores?.agregarVari}</p>
                        }
                    </div>

                    <div>{traduccion?.mantendores?.nombreVar}</div>
                    <div style={{display: 'flex'}}>
                        <Form.Group controlId="nombre">
                            <Form.Control
                                value={nuevaVariedad}
                                onChange={handleNuevaVariedad}
                                className={styles.selects_input_details} type="text"
                                placeholder={traduccion?.mantendores?.nombreVar} name="description"/>
                        </Form.Group>
                        <Button className="btn-success"
                                style={{margin: '.3rem .5rem', height: 'fit-content', alignSelf: "self-start"}}
                                onClick={addNewVariety}>
                            {traduccion?.mantendores?.agregar}
                        </Button>
                    </div>
                </div>
                <div>
                    <div className={stylesSurv.title_surveyor} style={{justifySelf: 'left'}}>
                        {oneSpecie.name !== undefined ?
                            <p>{traduccion?.mantendores?.editarAgregArr}</p> :
                            <p>{traduccion?.mantendores?.editarAgregArr}</p>
                        }
                    </div>
                    <Typeahead
                        id="basic-typeahead-multiple"
                        multiple
                        labelKey="description"
                        onChange={handleDamage}
                        options={damageList}
                        selected={damages}
                    />
                </div>
            </div>
            <div className={stylesSurv.title_surveyor} style={{justifySelf: 'left'}}>

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '1rem',
                    margin: '3rem 0 1.5rem 0',
                    padding: '1rem',
                    boxShadow: '0 0 3px #666',
                    borderRadius: '5px'
                }}>
                    <Form.Label htmlFor={"varietyS"}
                                style={{width: '23ch', margin: 0}}>{traduccion?.mantendores?.buscarVari}</Form.Label>
                    <Form.Control
                        style={{margin: '0'}}
                        onChange={handleSearch}
                        id={"varietyS"}
                        className={styles.selects_input_details} type="text"
                        placeholder={traduccion?.mantendores?.variedad} name="description"/>
                </div>

            </div>

            <Table style={{width: '100%'}}>
                <thead>
                <tr>
                    <th>{traduccion?.mantendores?.variedadDe} {oneSpecie?.description}</th>
                    {/*<th>{traduccion?.clients?.status}</th>*/}
                    <th>{traduccion?.mantendores?.editBorrar}</th>

                </tr>

                </thead>
                <tbody>

                {(specieObj.newVariety?.length > 0) && specieObj.newVariety.map((datos, idx) =>
                    <tr key={idx}>
                        {nuevaVariedadList[idx].editable ?
                            <td>
                                <Form.Control
                                    id={`${idx}`}
                                    value={specieObj.newVariety[idx]}
                                    onChange={editSpecieNew}
                                    className={styles.selects_input_details} type="text"
                                    placeholder={traduccion?.mantendores?.variedad} name="description"/>
                            </td> :
                            <td>{datos}</td>
                        }
                        <td>
                            <div className={stylesTabla.botones_botones_surveyor} style={{display: 'flex'}}>
                                <div
                                    onClick={() => editable(idx)}
                                    className={stylesTabla.boton_icono}>
                                    <BsPencil/>
                                </div>
                                <div
                                    onClick={() => deleteSpecieNew(idx)}
                                    className={stylesTabla.boton_icono}>
                                    <BsTrash style={{color: 'red'}}/>
                                </div>
                            </div>
                        </td>
                    </tr>
                )}
                {objVariety !== undefined && objVariety.map((datos, idx) =>
                    <tr key={idx}>
                        {specieObj.currentVariety[idx].editable ?
                            <td>
                                <Form.Control
                                    id={`${idx}`}
                                    value={specieObj.currentVariety[idx].description}
                                    onChange={editSpecieCurrent}
                                    className={styles.selects_input_details} type="text"
                                    placeholder={traduccion?.mantendores?.variedad} name="description"/>
                            </td> :

                            <td>{datos.description}</td>
                        }
                        {/*<td>{datos.status}</td>*/}
                        <td>
                            <div className={stylesTabla.botones_botones_surveyor} style={{display: 'flex'}}>
                                <div
                                    onClick={() => editableCurrent(idx)}
                                    className={stylesTabla.boton_icono}>
                                    <BsPencil/>
                                </div>
                                <div
                                    onClick={() => deleteSpecieCurrent(idx)}
                                    className={stylesTabla.boton_icono}>
                                    <BsTrash style={{color: 'red'}}/>
                                </div>
                            </div>
                        </td>
                    </tr>
                )}


                </tbody>
            </Table>
        </>
    )
}
