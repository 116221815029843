import styles from './inspector.module.css'
import stylesInput from '../documentos/documentos.module.css'
import {Button, Col, FloatingLabel, Form, Row, Table} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Rating} from "react-simple-star-rating";
import {AiOutlineMail, AiOutlinePhone, AiOutlineUser} from "react-icons/ai";
import DBContext from "../../../../context/DBContext";
import {Timeline} from "./timeline/Timeline";
import {Mapa} from "./map/Mapa";
//import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import LangContext from "../../../../context/LangContext";
import {urlConfig} from "../../../../config/urlConfig";
import {toast, ToastContainer} from "react-toastify";
import {FiDownload} from "react-icons/fi";
import {SurveyReport} from "./survey_report/SurveyReport";
import {surveyHead} from "../../../../pdf/surveyHead";

import ReactDOMServer from 'react-dom/server';
import {ImageReport} from "./image_report/ImageReport";
import {BiCopy} from "react-icons/bi";

const token = localStorage.getItem("token");
const InspectorFirst = (props) => {
    //console.log(props.claimForm)
    const {traduccion} = useContext(LangContext)
    if (props.claimForm.lastTimelineId === undefined || props.claimForm.lastTimelineId === 16) {
        props.setPag(1)
    } else if (props.claimForm.lastTimelineId !== 10 && props.claimForm.lastTimelineId !== 5 && props.claimForm.lastTimelineId !== 17
    ) {
        props.setPag(3)
    }
    const setToFalse = () => {
        props.setPag(2)
        props.setClaimForm({...props.claimForm, surveyorId: props.oneSurveyor.id, surveyFee: props.oneSurveyor.price})
    }
    const setASurveyor = (id) => {
        props.setOneSurveyor(props.surveyorList[id]);
    }

    const sumaRatePerHour = (rate, total) => {
        let suma = (rate + total).toFixed(2);
        if (suma > 0) {
            return suma
        } else {
            return "Price error"
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.mapa}>
                <Mapa mapCode={props.mapCode} setMapCode={props.setMapCode} pag={props.pag}
                      setClaimForm={props.setClaimForm} claimForm={props.claimForm}
                      setOneSurveyor={props.setOneSurveyor} oneSurveyor={props.oneSurveyor}
                      setSurveyorList={props.setSurveyorList} surveyorList={props.surveyorList}
                      setDireccionEscrita={props.setDireccionEscrita} direccionEscrita={props.direccionEscrita}
                />
            </div>


            <div className={styles.titulo}>{traduccion.inspector?.listaInspectores}</div>
            <Table className={styles.tabla}>
                <thead>
                <tr>
                    <th>{traduccion.inspector?.nombre}</th>
                    <th>{traduccion.inspector?.especialidad}</th>
                    <th>{traduccion.inspector?.rangoOpera}</th>
                    <th>{traduccion.inspector?.tiempoRespuesta}</th>
                    <th>{traduccion.inspector?.tarifaEstimada}</th>
                </tr>

                </thead>
                <tbody>

                {
                    props.surveyorList.map((datos, idx) =>
                        <tr key={idx}
                            className={(datos.id === props.oneSurveyor.id) ? styles.tr_data_select : styles.tr_data}
                            onClick={() => setASurveyor(idx)}>

                            <td>
                                <span><img className={styles.foto_perfil} src={datos.photo} alt="foto"/></span>
                                <span style={{marginLeft: "1rem"}}>{datos.firstName} {datos.lastName}</span>
                                <span className={styles.rating}><Rating size={18} className={styles.rating}
                                                                        fillColor={"#5B69EA"} readonly={true}
                                                                        initialValue={datos.rating}/></span>
                            </td>
                            <td>{datos.profession}</td>
                            <td>{datos.operationRange * 0.001} Km</td>
                            <td>{datos.responseTime}</td>
                            <td>{datos.rates?.currency === "CLP" ? ~~datos.rates?.totalPrice : datos.rates?.totalPrice} {datos.rates.currency}</td>
                            {/* Los ~~ son para quitar los decimales asi como un floor */}
                        </tr>
                    )}
                </tbody>
            </Table>
            <div className={styles.button_div}>
                {(props.oneSurveyor.id === undefined) ?
                    <Button className={styles.button_next} disabled
                            variant="secondary">{traduccion.inspector?.seleccioneInspector}</Button>
                    : <Button onClick={setToFalse} className={styles.button_next}
                              variant="primary">{traduccion.inspector?.next}</Button>
                }
            </div>
            <div>
                <ul>
                    <li style={{fontSize: '.9rem'}}>
                        {traduccion?.disclaimer?.one}
                    </li>
                    <li style={{fontSize: '.9rem'}}>
                        {traduccion?.disclaimer?.two}
                    </li>
                    <li style={{fontSize: '.9rem'}}>
                        {traduccion?.disclaimer?.three}
                    </li>
                    <li style={{fontSize: '.9rem'}}>
                        {traduccion?.disclaimer?.four}
                    </li>
                    <li style={{fontSize: '.9rem'}}>
                        {traduccion?.disclaimer?.five}
                    </li>
                </ul>
            </div>
        </div>
    )
}

const InspectorSecond = (props) => {
    const {traduccion} = useContext(LangContext)

    const [distance, setDistance] = useState(null);

    const [receiverForm, setReceiverForm] = useState({
        receiverName: "",
        receiverPhone: "",
        receiverEmail: "",
        receiverComments: "",
        latitude: "",
        longitude: "",
    });
    const [errores, setErrores] = useState({})
    const [priceResult, setPriceResult] = useState(null);
    const token = localStorage.getItem("token");

    const setToTrue = () => {
        // axios.post(urlConfig.inspectorUrl+"/location/price",{
        //     "surveyor": props.oneSurveyor.id,
        //     "distance": props.oneSurveyor.distance_m,
        //     "duration": props.oneSurveyor.duration_m,
        // }).then((response)=>{
        //     ////console.log(response)
        //     //TODO: Hacer que la pagina vaya a la 3
        //     props.setPag(3)
        // }).catch((error)=>{
        //     ////console.log(error)
        // })
        props.setPag(1)
    }

    const setToThree = (e) => {
        e.preventDefault()
        props.claimForm.status = 1;
        props.claimForm.paymentId = 1;

        const save = props.handleUpdate();
        console.table(save)

        if (receiverForm.receiverName !== "" && receiverForm.receiverPhone !== "" && receiverForm.receiverEmail !== "") {
            axios.post(`${urlConfig.local_url}${urlConfig.portClaim}/api/v1/claim/me/inspection_request/${props.claimForm.id}`, {
                "surveyor": props.oneSurveyor.id,
                "receiverEmail": receiverForm.receiverEmail,
                "receiverName": receiverForm.receiverName,
                "receiverPhone": receiverForm.receiverPhone,
                "receiverComments": "",
                "inspectionAddress": props.direccionEscrita,
                "latitude": props.claimForm.latitude,
                "longitude": props.claimForm.longitude,
                "duration": distance.duration.value,
                "distance": distance.distance.value,
                "country": props.mapCode,
                "totalPerKm": props.precio.precioRecorrido,
                "totalPrice": props.precio.precioTotal,
            }, {
                headers: {
                    Authorization: token
                }
            })
                .then((res) => {
                    const date = Date.now()

                    props.setTimelineDate(res.data)
                    res.data.push({description: "NEW", time: ""})
                    let last = res.data.length - 1
                    if (res.data[last].description === "NEW") {
                        props.setClaimForm({...props.claimForm, lastTimelineId: 1})
                    }
                    props.setPag(3)
                }).catch((error) => {
                if (error?.response?.data?.message !== undefined) {
                    if (error.response.data.message === null) {
                        toast.error(`${traduccion?.errores?.dataNull}`, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                } else {
                    toast.error(`${traduccion?.errores?.errSendData}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
        } else {

            toast.warning(`${traduccion?.warning?.mustFillAllAndMail}`,
                {
                    position: toast.POSITION.TOP_RIGHT
                })
        }
    }


    useEffect(() => {
        if (distance) {
            calculaPrecio(props.oneSurveyor.rates)
        }
    }, [distance])


    const calculaPrecio = (res) => {
        // precio del tiemporecorrido distance.duration.value/3600 *120).toFixed(2)
        // precio de la distancia distance.distance.value * 0.000621371* 0.95 * 2).toFixed(2)
        Number.prototype.toFixedNumber = function (digits, base) {
            var pow = Math.pow(base || 10, digits);
            return Math.round(this * pow) / pow;
        }

        const numDist = res.distance
        const kmORmi = res.totalMiles === null ? " km" : " mi";
        const idaVuelta = res.kilometers.toFixed(1) + kmORmi;
        ////console.log(idaVuelta)


        //const precioDist = (idaVuelta.replace("km","").replace("mi","")*0.95*2).toFixedNumber(2,10);
        let precioHora = res.ratePerHour;
        //let precioRecorrido = (distance.distance.value/1000  * 0.95 * 2).toFixedNumber(2);
        let precioHorasRecorrido = (distance.duration.value / 3600 * precioHora * 2).toFixedNumber(2);
        //let precioTotal = (2 * precioHora) + precioRecorrido + precioHorasRecorrido;

        //segundos a horas
        // let horas = (((distance.duration.value)*2)/3600).toFixedNumber(2);
        // let minutos = ((horas - Math.floor(horas))*60).toFixedNumber(0);
        // let tiempoIdayVuelta = Math.floor(horas) + "h " + minutos + "m";

        //Desde el back
        let tiempoIdayVuelta = res.hours + "h " + res.minutes + "m";
        let precioTotal = res.totalPrice;
        let precioRecorrido = res.totalPerKm;
        let kilometers = res.kilometers;
        let currency = res.currency;

        const precios = {
            precioRecorrido,
            precioHorasRecorrido,
            idaVuelta,
            precioTotal,
            tiempoIdayVuelta,
            currency,
            precioHora,
            kilometers
        }
        ////console.log(precios)
        props.setPrecio(precios)
    }

    useEffect(() => {
        props.setClaimForm({
            ...props.claimForm,
            totalPrice: props.precio?.precioTotal
        })
        //update sessionstorage claim
        //sessionStorage.setItem("claim", JSON.stringify(props.claimForm));
    }, [props.precio]);

    const handleInputChange = (e) => {
        if (e.target.name === "email") {
            //validar mail
            if (e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                setReceiverForm({
                    ...receiverForm,
                    [e.target.name]: e.target.value
                })
            } else {
                setReceiverForm({
                    ...receiverForm,
                    [e.target.name]: ""
                })
            }
        } else {
            setReceiverForm({
                ...receiverForm,
                [e.target.name]: e.target.value
            })
        }
    }
    ////console.log(receiverForm)
    return (
        <>
            <ToastContainer/>
            <Table className={styles.tabla}>
                <thead>
                <tr>
                    <th>{traduccion.inspector?.nombre}</th>
                    <th>{traduccion.inspector?.especialidad}</th>
                    <th>{traduccion.inspector?.rangoOpera}</th>
                    <th>{traduccion.inspector?.tiempoRespuesta}</th>
                    <th>{traduccion.inspector?.tarifaEstimada}</th>
                </tr>

                </thead>
                <tbody>
                <tr className={styles.tr_data}>

                    <td>
                        <span><img className={styles.foto_perfil} src={props.oneSurveyor.photo}
                                   alt="foto_perfil"/></span>
                        <span
                            style={{marginLeft: "1rem"}}>{props.oneSurveyor.firstName} {props.oneSurveyor.lastName}</span>
                        <span className={styles.rating}><Rating size={18} className={styles.rating}
                                                                fillColor={"#5B69EA"} readonly={true}
                                                                initialValue={props.oneSurveyor.rating}/></span>
                    </td>
                    <td>{props.oneSurveyor.profession}</td>
                    <td>{props.oneSurveyor.operationRange / 1000} km</td>
                    <td>{props.oneSurveyor.responseTime} horas</td>
                    <td>{props.precio?.precioTotal} {props.precio?.currency}</td>
                </tr>

                </tbody>
            </Table>

            <Row>
                <Col xs={12} xl={8}>
                    <div style={{
                        marginLeft: '1rem',
                        fontWeight: '500'
                    }}>{traduccion?.inspector?.inspectorSeleccionadoEn} {props.claimForm?.mainAddress || props.direccionEscrita}</div>
                    <div className={styles.mapa_bajo_inspector} style={{marginRight: 0, marginTop: 0}}>
                        <div className={styles.mapa}>
                            <div className={styles.mapa}>
                                <Mapa pag={props.pag} distance={distance} setDistance={setDistance}
                                      setClaimForm={props.setClaimForm}
                                      setOneSurveyor={props.setOneSurveyor} oneSurveyor={props.oneSurveyor}
                                      claimForm={props.claimForm} setSurveyorList={props.setSurveyorList}
                                      surveyorList={props.surveyorList}/>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} xl={4}>
                    <div className={styles.mapa_bajo_inspector_dos} style={{marginLeft: 0}}>
                        <Row>
                            <Col>
                                <div className={styles.titulo2}>{traduccion.inspector?.datosRecibidor}</div>
                                <div className={styles.datos_recibidor}>
                                    <div className={styles.icono_recibidor}>
                                        <AiOutlineUser/>
                                    </div>
                                    <div>
                                        <div>{traduccion.inspector?.nombre}</div>
                                        <div className={styles.datos_recibidor_bold}>
                                            <Form.Control className={styles.datos_recibidor_input} type="text"
                                                          onChange={handleInputChange} name="receiverName"
                                                          id="nombreRecibidor"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.datos_recibidor}>
                                    <div className={styles.icono_recibidor}>
                                        <AiOutlinePhone/>
                                    </div>
                                    <div>
                                        <div>{traduccion.inspector?.telefono}</div>
                                        <div className={styles.datos_recibidor_bold}>
                                            <Form.Control className={styles.datos_recibidor_input} type="text"
                                                          onChange={handleInputChange} name="receiverPhone"
                                                          id="telefonoRecibidor"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.datos_recibidor}>
                                    <div className={styles.icono_recibidor}>
                                        <AiOutlineMail/>
                                    </div>
                                    <div>
                                        <div>{traduccion.inspector?.email}</div>
                                        <div className={styles.datos_recibidor_bold}>
                                            <Form.Control type="email" onChange={handleInputChange} name="receiverEmail"
                                                          id="emailRecibidor"/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </Col>
            </Row>
            <div className={styles.mapa_bajo_inspector_dos}>
                <div className={styles.titulo2}>{traduccion.inspector?.costoAproximado}</div>
                <div className={styles.costo}>
                    <div className={styles.costo_items}>
                        <div>{traduccion.inspector?.tiempoBaseInspeccion}:</div>
                        <div className={styles.costo_items_bold}>5 horas base
                            ({props.precio && props.precio?.precioHora} {props.precio && props.precio?.currency})
                        </div>
                    </div>
                    <div className={styles.costo_items}>
                        <div>{traduccion.inspector?.tiempoRecorrido} ({traduccion.inspector?.idaVuelta}):</div>
                        <div className={styles.costo_items_bold}>{props.precio && props.precio?.tiempoIdayVuelta}</div>
                    </div>
                    <div className={styles.costo_items}>
                        <div>{props.precio?.idaVuelta.slice(-2) === 'km' ? `${traduccion.inspector?.km}` : `${traduccion.inspector?.ml}`} ({traduccion.inspector?.idaVuelta})
                            :
                        </div>
                        <div
                            className={styles.costo_items_bold}>{props.precio && props.precio?.idaVuelta} ({props.precio && props.precio?.precioRecorrido.toFixed(2)} {props.precio?.currency})
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.mapa_bajo_inspector_dos}>
                <div className={styles.total}>
                    <div>
                        <div>Total:<span
                            className={styles.costo_items_bold}> {props.precio && props.precio.precioTotal.toFixed(2)} {props.precio && props.precio?.currency}</span>
                        </div>
                    </div>
                    <div className={styles.total_buttons}>
                        <Button className={styles.button_comment} onClick={setToTrue} variant="danger">Back</Button>
                        <Button onClick={setToThree} className={styles.button_comment}
                                variant="primary">{traduccion.inspector?.solicitarInspeccion}</Button>
                    </div>
                </div>
            </div>

            <div className={styles.button_div2}>
                <Button onClick={setToTrue} className={styles.button_next}
                        variant="primary">{traduccion.inspector?.back}</Button>
            </div>
        </>
    )
}

const InspectorThird = (props) => {

    const {traduccion} = useContext(LangContext)
    const setToTrue = () => {
        props.setPag(1)
    }
    const setToThree = () => {
        props.setPag(3)
    }
    ////console.log(props.claimForm.lastTimelineId)
    ////console.log(props.claimForm.surveyor?.id)
    if (props.claimForm.lastTimelineId === 10 || props.claimForm.lastTimelineId === 17
    ) {
        setToTrue()
    }

    let inspectionFruitPacketList = [];

    /*
    if(props.claimForm.inspection.inspectionReport.inspectionFruit.length > 0){
        props.claimForm.inspection.inspectionReport.inspectionFruit.map((fruit, index) => {
            fruit.inspectionPacket.map((material,index) => {
                if (material.packetMaterial !== '') {
                    material.packetMaterial.split(',').forEach(m => {
                        let packetMaterial = '';
                        switch (m.trim()) {
                            case 'Cartón':
                                packetMaterial = 'Cartons';
                                break;
                            case 'Madera':
                                packetMaterial = 'Woods';
                                break;
                            case 'Cartón plástico':
                                packetMaterial = 'Cardboard plastic';
                                break;
                            case 'Panel de fibra de doble pared':
                                packetMaterial = 'Double wall fiberboard';
                                break;
                            case 'Map':
                                packetMaterial = 'Map';
                                break;
                            case 'Bolsa perforada':
                                packetMaterial = 'Perforated Bag';
                                break;
                            case 'Revestimientos':
                                packetMaterial = 'Liners';
                                break;
                            case 'Plastic':
                                packetMaterial = 'Plastic';
                                break;
                            case 'Single wall fiberboard':
                                packetMaterial = 'Single wall fiberboard';
                                break;
                            case 'Wood':
                                packetMaterial = 'Wood';
                                break;
                            case 'Open top':
                                packetMaterial = 'Open top';
                                break;
                            case 'Telescoping lid':
                                packetMaterial = 'Telescoping lid';
                                break;
                            case 'Removable top lid':
                                packetMaterial = 'Removable top lid';
                                break;
                            case 'Folding lids':
                                packetMaterial = 'Folding lids';
                                break;
                            case 'Column stacked':
                                packetMaterial = 'Column stacked';
                                break;
                            case 'Cross stacked':
                                packetMaterial = 'Cross stacked';
                                break;
                            case 'Volume filled':
                                packetMaterial = 'Volume filled';
                                break;
                            case 'Tray packed':
                                packetMaterial = 'Tray packed';
                                break;
                            case 'Layer packed':
                                packetMaterial = 'Layer packed';
                                break;
                            case 'Polyethene liner':
                                packetMaterial = 'Polyethene liner';
                                break;
                            case 'So2 pad':
                                packetMaterial = 'So2 pad';
                                break;
                            case 'Slider bags':
                                packetMaterial = 'Slider bags';
                                break;
                            case 'Clamshells':
                                packetMaterial = 'Clamshells';
                                break;
                            default:
                                packetMaterial = m.trim();
                        }
                        if (!inspectionFruitPacketList.includes(packetMaterial)) {
                            inspectionFruitPacketList.push(packetMaterial);
                        }
                    })
                }
            })
        })
    }

     */

    const [linkSurveyor, setLinkSurveyor] = useState(props.claimForm?.documents?.survey || props.claimForm?.documents?.surveyor?.survey || '')
    const [linkPhotograph, setLinkPhotograph] = useState(props.claimForm?.documents?.photograph || props.claimForm?.documents?.surveyor?.photograph || '')
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    let datos = []
    ////console.log(props)
    if (props.timelineDate !== null && props.timelineDate !== undefined) {
        datos = props.timelineDate
    } else if (props.claimForm?.timeline) {
        datos = props.claimForm.timeline
    } else {
        datos = [{
            "id": 1,
            "time": "2222-12-22",
            "description": "TEST",
        },
            {
                "id": 2,
                "time": "2222-12-22",
                "description": "TEST",
            },]
    }

    if (props.oneSurveyor?.id === undefined) {
        props.setOneSurveyor(props.claimForm.surveyor)
    }

    const downloadFile = (bytes, fileName, eml) => {
        const blob = new Blob([bytes], eml ? {type: 'message/rfc822'} : {type: "application/pdf"});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click()
    }

    const descargaUno = (filename, eml) => {
        axios({
            url: `${urlConfig.allUrl}:9004/api/v1/files/download?file=${filename}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        }).then((response) => {
            downloadFile(response.data, filename.split("_")[0], eml)
        }).catch((error) => {
            ////console.log(error);
        });
    }
    if (props.claimForm.lastTimelineId === 10) {
        setToTrue()
    }

    const copyToClipboard = str => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText)
            return navigator.clipboard.writeText(str);
        return Promise.reject("The Clipboard API is not available.");
    };
    const doHtml = (e, f) => {
        if (props.claimForm?.inspection === null) {
            toast.error("No hay datos para generar el reporte")
            return null;
        }
        let elHtml = surveyHead.head + ReactDOMServer.renderToString(<SurveyReport claimForm={props.claimForm}
                                                                                   fechaHar={props.fechaHar}
                                                                                   fechaPack={props.fechaPack}
                                                                                   surveyDate={props.surveyDate}/>) + surveyHead.end;
        if (f === 'img') {
            setLoading2(true)
            elHtml = surveyHead.head + ReactDOMServer.renderToString(<ImageReport
                claimForm={props.claimForm}/>) + surveyHead.end;
        } else {
            setLoading(true)
        }

        const blob = new Blob([elHtml], {type: 'text/html'});
        if (e === "pdf") {


        } else if (e === "html") {
            const file = new File([blob], 'archivo.html', {type: 'text/html'});
            const url = URL.createObjectURL(file);
            window.open(url)

        } else {
            // //ENVIAR A BACKEND
            let name = "Survey"
            let endpoint = "survey_report"
            let pre = ""
            if (f === 'img') {
                name = "Photograph"
                endpoint = "photograph_report"
            }
            if (!props.imRsk) {
                pre = "me/"
            }
            const file = new File([blob], `${name}_Report-RSK${props.claimForm.createdTime.slice(0, 4)}/${props.claimForm.id}.html`, {type: 'text/html'});
            const formData = new FormData();
            formData.append('files', file);


            ////console.log("formData", formData)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIzY2E3YWI2ZC0wOGZiLTQ3MDMtYTI0Yi1hZjNiNzYwYTFiOWMiLCJzdWIiOiJ2Y2VsaXNAcnNrY2hpbGUuY29tIiwicm9sIjoiUk9MRV9SU0siLCJpYXQiOjE2Njg0MzAxNTAsImV4cCI6MjA2ODQ2NjE1MH0.r7MyaqiC-2wpKv6Ai6gmJnDFaFL8_lPEZvl44n2aed0"
                }
            }
            axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${pre}${endpoint}/${props.claimForm.id}`, formData, config)
                // axios.post(`https://9939-2800-150-11e-2e87-6ccd-c541-77ff-d379.sa.ngrok.io/api/v1/claim/${pre}${endpoint}/${props.claimForm.id}`, formData, config)
                .then((response) => {
                    copyToClipboard(response.data.url)
                    if (f === 'img') {
                        setLinkPhotograph(response.data.url)
                        setLoading2(false)
                    } else {
                        setLinkSurveyor(response.data.url)
                        setLoading(false)
                    }
                }).catch((error) => {
                ////console.log(error);
            })
        }
    }

    const doHtmlNew = (e, f, claim) => {
        if (claim.inspection === null) {
            toast.error("No hay datos para generar el reporte")
            return null;
        }
        let elHtml = surveyHead.head + ReactDOMServer.renderToString(<SurveyReport claimForm={claim}
                                                                                   fechaHar={claim.fechaHar}
                                                                                   fechaPack={claim.fechaPack}
                                                                                   surveyDate={claim.surveyDate}/>) + surveyHead.end;
        if (f === 'img') {
            setLoading2(true)
            elHtml = surveyHead.head + ReactDOMServer.renderToString(<ImageReport
                claimForm={claim}/>) + surveyHead.end;
        } else {
            setLoading(true)
        }

        const blob = new Blob([elHtml], {type: 'text/html'});
        if (e === "pdf") {


        } else if (e === "html") {
            const file = new File([blob], 'archivo.html', {type: 'text/html'});
            const url = URL.createObjectURL(file);
            window.open(url)

        } else {
            // //ENVIAR A BACKEND
            let name = "Survey"
            let endpoint = "survey_report"
            let pre = ""
            if (f === 'img') {
                name = "Photograph"
                endpoint = "photograph_report"
            }
            if (!claim.imRsk) {
                pre = "me/"
            }
            const file = new File([blob], `${name}_Report-RSK${claim.createdTime.slice(0, 4)}/${claim.id}.html`, {type: 'text/html'});
            const formData = new FormData();
            formData.append('files', file);


            ////console.log("formData", formData)
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIzY2E3YWI2ZC0wOGZiLTQ3MDMtYTI0Yi1hZjNiNzYwYTFiOWMiLCJzdWIiOiJ2Y2VsaXNAcnNrY2hpbGUuY29tIiwicm9sIjoiUk9MRV9SU0siLCJpYXQiOjE2Njg0MzAxNTAsImV4cCI6MjA2ODQ2NjE1MH0.r7MyaqiC-2wpKv6Ai6gmJnDFaFL8_lPEZvl44n2aed0"
                }
            }
            axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${pre}${endpoint}/${claim.id}`, formData, config)
                // axios.post(`https://9939-2800-150-11e-2e87-6ccd-c541-77ff-d379.sa.ngrok.io/api/v1/claim/${pre}${endpoint}/${props.claimForm.id}`, formData, config)
                .then((response) => {
                    copyToClipboard(response.data.url)
                    if (f === 'img') {
                        setLinkPhotograph(response.data.url)
                        setLoading2(false)
                    } else {
                        setLinkSurveyor(response.data.url)
                        setLoading(false)
                    }
                }).catch((error) => {
                ////console.log(error);
            })
        }
    }

    const resBD = (tipo) => {
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${props.claimForm.id}`, {
            headers: {
                Authorization: token.toString().replace("Bearer ", "")
            }
        }).then((res) => {
            console.log(res.data);
            doHtmlNew(tipo, null, res.data);
        })
    }


    return (
        <>
            <div
                style={{marginTop: "1.5rem"}}>{traduccion?.inspector?.inspectorSeleccionadoEn} {props.claimForm?.mainAddress || props.direccionEscrita}</div>
            <Table className={styles.tabla}>
                <thead>
                <tr>
                    <th>{traduccion.inspector?.nombre}</th>
                    <th>{traduccion.inspector?.especialidad}</th>
                    <th>{traduccion.inspector?.rangoOpera}</th>
                    {/*<th>{traduccion.inspector?.tiepmoRespuesta}</th>*/}
                    {/*<th>{traduccion.inspector?.tarifaEstimada}</th>*/}
                </tr>

                </thead>
                <tbody>
                {props.oneSurveyor &&
                    <tr className={styles.tr_data}>

                        <td>
                            <span><img className={styles.foto_perfil} src={props.oneSurveyor.photo} alt="foto_perfil"/></span>
                            <span
                                style={{marginLeft: "1rem"}}>{props.oneSurveyor.firstName} {props.oneSurveyor.lastName}</span>
                            <span className={styles.rating}><Rating size={18} className={styles.rating}
                                                                    fillColor={"#5B69EA"} readonly={true}
                                                                    initialValue={props.oneSurveyor.rating}/></span>
                        </td>
                        <td>{props.oneSurveyor.profession}</td>
                        <td>{props.oneSurveyor.operationRange / 1000} km</td>
                        {/*<td>{props.oneSurveyor.responseTime} horas</td>*/}
                        {/*<td>${props.precio?.precioTotal} USD</td>*/}
                    </tr>
                }
                </tbody>
            </Table>
            <Row>
                <div className={styles.titulo3}>TimeLine</div>
                <Timeline items={datos} datos={datos} idClaim={props.claimForm.id}/>
            </Row>
            <Row>

                <Col>
                    <div className={styles.documentos}>
                        <div className={styles.documentos_title}>{traduccion?.inspector?.informeDeInspeccion}</div>
                        {/*{props.filesClaim.survey && <div style={{fontSize:13, marginTop:-10}}>{props.filesClaim.survey}</div>}*/}
                        {/*<Form.Group id="formFile1" style={{display:'flex'}}>*/}
                        {/*    <Form.Control name={"survey"} className={props.filesClaim.survey ? stylesInput.file_input2:stylesInput.file_input } onChange={props.handleDocuments} type="file" />*/}
                        {/*        {/^SURVEY_/.test(props.filesClaim.survey) && <div className={stylesInput.button_des} onClick={()=>descargaUno(props.filesClaim.survey)}><FiDownload/></div>}*/}
                        {/*</Form.Group>*/}
                        <div className={styles.documentos_link} onClick={() => copyToClipboard(linkSurveyor)}>
                            <p className={styles.solo_p} style={{position: "absolute", right: 8}}><BiCopy
                                style={{verticalAlign: 'initial'}}/></p>
                            <div>{linkSurveyor !== "" ? linkSurveyor : " No link available"}</div>
                        </div>
                        {
                            props.imRsk &&
                            <div style={{display: 'flex', gap: '10px', justifyContent: 'center'}}>
                                {
                                    datos[datos.length - 1].description.includes('CANCELED') ? (
                                        <>
                                            <Button style={{marginBottom: '1rem'}} disabled={true}>Create Link</Button>
                                            <Button style={{marginBottom: '1rem'}} disabled={true}>Preview html</Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button style={{marginBottom: '1rem'}} disabled={props.claimForm?.inspection?.inspectionReport === undefined || props.claimForm?.inspection?.inspectionReport === null} onClick={() => {
                                                resBD("back")
                                            }}>Create Link</Button>
                                            <Button onClick={() => {resBD("html") }} style={{marginBottom: '1rem'}}
                                                    disabled={props.claimForm?.inspection?.inspectionReport === undefined || props.claimForm?.inspection?.inspectionReport === null}
                                            >Preview html</Button>
                                        </>
                                    )
                                }
                            </div>
                        }
                    </div>
                </Col>
                <Col>
                    <div className={styles.documentos}>
                        <div className={styles.documentos_title}>{traduccion?.inspector?.informeFotografico}</div>
                        {/*{props.filesClaim.photograph && <div style={{fontSize:13, marginTop:-10}}>{props.filesClaim.photograph}</div>}*/}
                        {/*<Form.Group id="formFile2" style={{display:'flex'}} >*/}
                        {/*    <Form.Control name="photograph" className={props.filesClaim.photograph ? stylesInput.file_input2:stylesInput.file_input } onChange={props.handleDocuments} type="file" />*/}
                        {/*    {/^PHOTOGRAPH_/.test(props.filesClaim.photograph) && <div className={stylesInput.button_des} onClick={()=>descargaUno(props.filesClaim.photograph)}><FiDownload/></div>}*/}

                        {/*</Form.Group>*/}
                        <div className={styles.documentos_link} onClick={() => copyToClipboard(linkPhotograph)}>
                            <p className={styles.solo_p} style={{position: "absolute", right: 8}}><BiCopy
                                style={{verticalAlign: 'initial'}}/></p>
                            <div>{linkPhotograph !== "" ? linkPhotograph : " No link available"}</div>
                        </div>
                        {
                            props.imRsk &&
                            <div style={{display: 'flex', gap: '10px', justifyContent: 'center'}}>
                                {
                                    datos[datos.length - 1].description.includes('CANCELED') ? (
                                        <>
                                            <Button style={{marginBottom: '1rem'}} disabled={true}>Create Link</Button>
                                            <Button style={{marginBottom: '1rem'}} disabled={true}>Preview html</Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button style={{marginBottom: '1rem'}} onClick={() => doHtml('back', 'img')}
                                                // TODO: Si existe el informe, que se muestre
                                                    disabled={props.claimForm?.inspection?.inspectionReport === undefined || props.claimForm?.inspection?.inspectionReport === null}
                                            >Create Link</Button>
                                            <Button onClick={() => doHtml('html', 'img')} style={{marginBottom: '1rem'}}
                                                    disabled={props.claimForm?.inspection?.inspectionReport === undefined || props.claimForm?.inspection?.inspectionReport === null}
                                                // disabled={props.claimForm?.claimStatus?.id === 1}
                                            >Preview html</Button>
                                        </>
                                    )
                                }
                            </div>
                        }
                    </div>
                </Col>
            </Row>
            <Row style={{height: '190px'}}>
                <Col style={{height: '100%'}}>
                    <div className={styles.documentos}>
                        <div className={styles.documentos_title}>{traduccion?.inspector?.notaDeProtesta}</div>
                        {props.filesClaim.notice &&
                            <div style={{fontSize: 13, marginTop: -10}}>{props.filesClaim.notice}</div>}

                        <Form.Group id="formFile3" style={{display: 'flex'}}>
                            <Form.Control name="notice"
                                          className={props.filesClaim.notice ? stylesInput.file_input2 : stylesInput.file_input}
                                          onChange={props.handleDocuments} type="file"/>
                            {/^NOTICE_/.test(props.filesClaim.notice) && <div className={stylesInput.button_des}
                                                                              onClick={() => descargaUno(props.filesClaim.notice, true)}>
                                <FiDownload/></div>}

                        </Form.Group>
                        <div className="mb-3">{traduccion?.documentos?.soloArchivos}</div>
                    </div>
                </Col>
                <Col style={{height: '100%'}}>
                    <div className={styles.documentos}>
                        <div className={styles.documentos_title}>{traduccion?.inspector?.certificadoDeDestruccion}</div>
                        <div style={{
                            fontSize: 13,
                            marginTop: -10
                        }}>{props.filesClaim.destruction ? props.filesClaim.destruction : ' '}</div>

                        <Form.Group id="formFile4" style={{display: 'flex'}}>
                            <Form.Control name="destruction"
                                          className={props.filesClaim.destruction ? stylesInput.file_input2 : stylesInput.file_input}
                                          onChange={props.handleDocuments} type="file"/>
                            {/^DESTRUCTION_/.test(props.filesClaim.destruction) &&
                                <div className={stylesInput.button_des}
                                     onClick={() => descargaUno(props.filesClaim.destruction)}><FiDownload/></div>}

                        </Form.Group>
                        <div className="mb-3">{traduccion?.documentos?.soloArchivos}</div>
                    </div>
                </Col>
            </Row>

            {/*<Button onClick={()=>doHtml('pdf')} style={{marginBottom:'1rem'}}>Do pdf</Button>*/}

            <div style={{padding: '2rem'}}>
                {/*<SurveyReport claimForm={props.claimForm} fechaHar={props.fechaHar} fechaPack={props.fechaPack} surveyDate={props.surveyDate}/>*/}
                {/*<ImageReport claimForm={props.claimForm} />*/}
            </div>

        </>
    )
}

export const InspectorTab = ({
                                 claimForm,
                                 setClaimForm,
                                 filesClaim,
                                 handleDocuments,
                                 inInspector,
                                 handleUpdate,
                                 fechaHar,
                                 fechaPack,
                                 surveyDate,
                                 imRsk
                             }) => {
    const {traduccion} = useContext(LangContext)

    const {inspector} = useContext(DBContext);
    const [surveyorList, setSurveyorList] = useState([]);
    const [pag, setPag] = useState(4);
    const [oneSurveyor, setOneSurveyor] = useState({});
    const [precio, setPrecio] = useState(null);
    const [timelineDate, setTimelineDate] = useState(null);
    const [mapCode, setMapCode] = useState("")
    const [direccionEscrita, setDireccionEscrita] = useState("")


    // useEffect(() => {
    //     const getResponse = async () => {
    //         await inspector;
    //     };
    //     getResponse();
    //     setSurveyorList(inspector)
    // }, []);
    // ////console.log("INSPECTOR", inspector)
    useEffect(() => {
        if (inInspector || (claimForm.lastTimelineId === 10 || claimForm.lastTimelineId !== 5)) {
            setPag(1)
        }
    }, [inInspector])


    const setToTwo = () => {
        setPag(2)
    }


    const token = localStorage.getItem("token");

    const cancelaInspector = () => {
        //axios.post(`${urlConfig.urlNgrok}/api/v1/claim/me/inspection_request/${claimForm.id}`, {}, {
        axios.post(`${urlConfig.local_url}${urlConfig.portClaim}/api/v1/claim/me/inspection_request/${claimForm.id}`, {}, {
            headers: {
                Authorization: token
            }
        })
            .then((res) => {
                ////console.log(res.data)
                setClaimForm({...claimForm, lastTimelineId: 10})
                setPag(1)
            }).catch((error) => {
            if (error?.response?.data?.message !== undefined) {
                setPag(1)
                if (error.response.data.message === null) {
                    toast.error(`${traduccion?.errores?.dataNull}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            } else {
                setPag(1)
                toast.error(`${traduccion?.errores?.errSendData}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
            ////console.log(error);
            ////console.log(error);
        })
    }
    return (
        <>
            {
                (pag === 1) && <InspectorFirst claimForm={claimForm} setClaimForm={setClaimForm}
                                               mapCode={mapCode} setMapCode={setMapCode}
                                               surveyorList={surveyorList} setSurveyorList={setSurveyorList}
                                               oneSurveyor={oneSurveyor} setOneSurveyor={setOneSurveyor} pag={pag}
                                               setPag={setPag}
                                               direccionEscrita={direccionEscrita}
                                               setDireccionEscrita={setDireccionEscrita}
                />
            }
            {
                (pag === 2) && <InspectorSecond precio={precio} setPrecio={setPrecio} claimForm={claimForm}
                                                mapCode={mapCode} setMapCode={setMapCode}
                                                setClaimForm={setClaimForm} surveyorList={surveyorList}
                                                setSurveyorList={setSurveyorList}
                                                oneSurveyor={oneSurveyor} setOneSurveyor={setOneSurveyor} pag={pag}
                                                setPag={setPag}
                                                handleUpdate={handleUpdate}
                                                timelineDate={timelineDate} setTimelineDate={setTimelineDate}
                                                direccionEscrita={direccionEscrita}
                                                setDireccionEscrita={setDireccionEscrita}

                />
            }
            {
                (pag === 3) && <div><InspectorThird precio={precio} setPrecio={setPrecio}
                                                    setOneSurveyor={setOneSurveyor} oneSurveyor={oneSurveyor}
                                                    setPag={setPag} claimForm={claimForm}
                                                    timelineDate={timelineDate} setTimelineDate={setTimelineDate}
                                                    filesClaim={filesClaim} handleDocuments={handleDocuments}
                                                    fechaHar={fechaHar} fechaPack={fechaPack}
                                                    surveyDate={surveyDate} imRsk={imRsk}
                                                    direccionEscrita={direccionEscrita}
                                                    setDireccionEscrita={setDireccionEscrita}
                />

                    {(!(claimForm.lastTimelineId !== 10 && claimForm.lastTimelineId !== 5 && claimForm.timeline === undefined) && pag !== 3) &&
                        <Button onClick={setToTwo} className={styles.button_prev} variant="primary">Anterior</Button>
                    }
                    {(claimForm.lastTimelineId === 1) &&
                        <Button className={styles.button_prev} onClick={cancelaInspector}
                                variant="danger">{traduccion?.inspector?.cancelInspection}</Button>
                    }
                </div>
            }
            {
                (pag === 4) && <></>
            }

        </>
    )

}
