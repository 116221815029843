import React, {useContext, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import DBContext from "../../../context/DBContext";
import thisStyles from "./profile.module.css";
import stylesFiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {Button, Form} from "react-bootstrap";
import styles from "./../surveyors/mantenedor_surveyor.module.css";
import {Link} from "react-router-dom";
import {FilterProfile} from "./filter_profile/FilterProfile";
import {TablaProfiles} from "./tabla_profiles/TablaProfiles";
import {NewProfile} from "./NewProfile";
import LangContext from "../../../context/LangContext";

export const Profiles = ({newProfiles, setNewProfiles, setRouteActive, listaStatus}) => {
    const [oneProfile, setOneProfile] = useState({});
    const [accountSurveyor, setAccountSurveyor] = useState({});
    const [profileList, setProfileList] = useState([]);
    const [profileId, setProfileId] = useState(null);
    const [editProfile, setEditProfile] = useState(false);
    const {traduccion} = useContext(LangContext);
    let {profiles, cambioProfiles, setCambioProfiles} = React.useContext(DBContext);

    const [searchUrl, setSearchUrl] = useState(null);

    const searchClaims = (url) => {
        setSearchUrl(url)
    };

    const goToStart = () => {
        setRouteActive("/");
    };

    const handleNewProfile = () => {
        setOneProfile("");
        setNewProfiles(1)
    }
    useEffect(() => {
        setCambioProfiles(!cambioProfiles);
    }, []);
    return (
        <>
            <div className={styles.all_surveyor}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>
                    >{newProfiles === 0 && <span className={styles.bold}>{traduccion?.mantenedoresII?.perfil}</span>}
                    {newProfiles === 1 && (
                        <>
              <span onClick={() => setNewProfiles(0)} className={styles.like_a}>
                {traduccion?.mantenedoresII?.perfil}
              </span>
                            ><span className={styles.bold}>{traduccion?.mantenedoresII?.nuevoPerfil}</span>
                        </>
                    )}
                    {newProfiles === 2 && (
                        <>
              <span onClick={() => setNewProfiles(0)} className={styles.like_a}>
                {traduccion?.mantenedoresII?.perfil}
              </span>
                            ><span className={styles.bold}>{traduccion?.mantenedoresII?.profileDetails}</span>
                        </>
                    )}
                </div>
                {newProfiles === 0 && (
                    <>
                        <div className={styles.title_surveyor}>
                            <p>{traduccion?.mantenedoresII?.perfil}</p>
                            <Button
                                className={styles.title_button}
                                variant="primary"
                                onClick={handleNewProfile}
                            >{traduccion?.mantenedoresII?.nuevoPerfil}</Button>
                        </div>
                        <FilterProfile
                            searchClaims={searchClaims}
                            profileList={profileList}
                            setProfileList={setProfileList}
                        />
                        <TablaProfiles
                            searchUrl={searchUrl}
                            setProfileId={setProfileId}
                            listaStatus={listaStatus}
                            profileList={profileList}
                            oneProfile={oneProfile}
                            setNewProfiles={setNewProfiles}
                            editProfile={editProfile}
                            setEditProfile={setEditProfile}
                            setOneProfile={setOneProfile}
                            profiles={profiles}
                        />
                    </>
                )}

                {newProfiles === 1 &&
                    <NewProfile setNewProfiles={setNewProfiles} title={"New profile"} editProfile={editProfile}
                                oneProfile={oneProfile}/>}
                {/*{newProfiles === 2 && <SurveyorDetail profileId={profileId} accountSurveyor={accountSurveyor} oneSurveyor={oneSurveyor} />} }*/}
            </div>
        </>
    );
};
