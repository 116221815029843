import {Form, Tab, Tabs} from "react-bootstrap";
import styles from "./transporte.module.css"
import {useContext, useEffect, useRef, useState} from "react";
import LangContext from "../../../context/LangContext";
import {TransporteMaritimo} from "./TransporteMaritimo";
import {IoMdBoat, IoIosAirplane, IoMdBus} from "react-icons/io";
import {Fragment} from 'react'; // import Fragment from React
import {ImMinus, ImPlus} from "react-icons/im";
import {TransporteAereo} from "./TransporteAereo";
import {TransporteTerrestre} from "./TransporteTerrestre";

export const Transporte = ({isOn, setIsOn, claimForm, setClaimForm, stuff, setStuff, errors,setErrors, transporte,setTransporte,imRsk, editRsk, setExportersName, exportersName, handleChangeExporter}) => {
    const [iconoT, setIconoT] = useState(<IoMdBoat/>);
    const [showTransporte, setShowTransporte] = useState();
    const [roleClaim, setRoleClaim] = useState(false);
    const [roleRecover, setRoleRecover] = useState(false);
    const [roleAdmin, setRoleAdmin]= useState(false)

    const ref = useRef(null);
    const {traduccion} = useContext(LangContext);

    //console.log({stuff})

    //update claimForm sessionStorage
    useEffect(() => {
       // sessionStorage.setItem("claim", JSON.stringify(claimForm));
    }, [claimForm])
    const claimSinTransporte = {
        ...claimForm,
        ata: "",
        awb: "",
        blAwb: "",
        blAwbDate: "",
        pol: "",
        pod: "",
        vessel: "",
        consigneeName: "",
        timeBar: "",
        carrierName: "",
        shipperName: "",
    }
    const handleMaritimo = () => {
        setTransporte(1);
        setIconoT(<IoMdBoat/>);
        setClaimForm({...claimSinTransporte,transportId:1})
        //update sessionStorage of claim

    }
    const handleAereo = () => {
        setTransporte(2);
        setIconoT(<IoIosAirplane/>);
        setClaimForm({...claimSinTransporte,transportId:2})
    }
    const handleTerrestre = () => {
        setTransporte(3);
        setIconoT(<IoMdBus/>);
        setClaimForm({...claimSinTransporte,transportId:3})
    }

    //Transition from fixed to auto
    const onOffColl=()=>{
        if(isOn){
            let sectionHeight = ref.current.scrollHeight;
            let elementTransition = ref.current.style.transition;
            ref.current.style.transition = '';
            ref.current.style.transitionend = '';

            requestAnimationFrame(function() {
                ref.current.style.height = sectionHeight + 'px';
                // ref.current.style.transition = elementTransition;

                requestAnimationFrame(function() {
                    ref.current.style.height = 70 + 'px';
                    ref.current.style.overflow = "hidden";
                    ontransitionend = (event) => {
                        ref.current.style.height = '70px'
                    };
                });
            });
        }else{
            let sectionHeight = ref.current.scrollHeight;
            let elementTransition = ref.current.style.transition;
            ref.current.style.height = sectionHeight + 'px';

            requestAnimationFrame(function() {
                ref.current.style.height = sectionHeight + 'px';
                ref.current.style.transition = elementTransition;

                ontransitionend = (event) => {
                    ref.current.style.height = 'auto'
                    ref.current.style.overflow = "visible";
                };

            });

        }
        setIsOn(!isOn);

    }
    useEffect(() => {
        if (claimForm?.transportId === 1) {
            handleMaritimo();
        }else if (claimForm?.transportId === 2) {
            handleAereo();
        }else if (claimForm?.transportId === 3) {
            handleTerrestre();
        }
    },[]);


    //console.log(exportersName)
    //Get token
    let tokenAtob = atob(localStorage.getItem("token").split(".")[1])
    let tokenA = JSON.parse(tokenAtob)
    useEffect(() => {
        if(tokenA.rol === "ROLE_RSK" || btoa(tokenA.rol) === 'Uk9MRV9SU0tfQURNSU5JU1RSQUNJw5NO'){
            setRoleAdmin(true)
        }else{
            setRoleAdmin(false)
        }
        if(tokenA.rol === "ROLE_RSK_CLAIMS"){
            setRoleClaim(true)
        }else{
            setRoleClaim(false)
        }
        if(tokenA.rol === "ROLE_RSK_RECOVERY"){
            setRoleRecover(true)
        }else{
            setRoleRecover(false)
        }
    },[])

    return (
        <>
            <div ref={ref} className={styles.all_div}
                 style={{overflow:"visible"}}
            >
            <div className={styles.collapse_icon} onClick={onOffColl}>{isOn?<ImMinus/>:<ImPlus/>}</div>
                <div className={styles.div_radio}>

                    <Form>
                        <div className={styles.radio_buttons}>
                            <div className={styles.radio_buttons_title}>{traduccion.transport?.tipoTransporte}</div>
                            <Form.Check
                                style={{margin:0, marginLeft:10}}
                                name="transporte"
                                type="radio"
                                disabled={!(claimForm?.lastTimelineId === 10 ||  claimForm?.lastTimelineId === 5 || claimForm?.lastTimelineId === undefined)}
                                label={traduccion.transport?.maritimo}
                                onClick={handleMaritimo}
                                value={1}
                                checked={transporte === 1}

                            />

                            <Form.Check
                                style={{margin:0, marginLeft:10}}
                                name="transporte"
                                type="radio"
                                disabled={!(claimForm?.lastTimelineId === 10 ||  claimForm?.lastTimelineId === 5 || claimForm?.lastTimelineId === undefined)}
                                label={traduccion.transport?.aereo}
                                onClick={handleAereo}
                                value={2}
                                checked={transporte === 2}

                            />
                            <Form.Check
                                style={{margin:0, marginLeft:10}}
                                name="transporte"
                                type="radio"
                                disabled={!(claimForm?.lastTimelineId === 10 ||  claimForm?.lastTimelineId === 5 || claimForm?.lastTimelineId === undefined)}
                                label={traduccion.transport?.terrestre}
                                onClick={handleTerrestre}
                                value={3}
                                checked={transporte === 3}

                            />
                        </div>

                    </Form>
                </div>
                <div className={styles.tabs}>
                    <Tabs
                        // id="controlled-tab-example"
                        activeKey={transporte}
                        // onSelect={(k) => setTransporte(k)}

                        className="mb-3"
                    >{
                        transporte &&
                        <Tab eventKey={transporte} title={

                            <Fragment>
                                <span style={{marginRight: "1rem", fontSize:'1.2rem'}}>{iconoT}</span>
                                <span>{transporte === 1 ? `${traduccion.transport?.maritimo}`
                                    : transporte === 2 ? `${traduccion.transport?.aereo}`
                                    : `${traduccion.transport?.terrestre}`}</span>
                            </Fragment>


                        }>
                            {transporte === 1 &&
                                <TransporteMaritimo claimForm={claimForm} setClaimForm={setClaimForm}
                                                    stuff={stuff} setStuff={setStuff}
                                                    errors={errors} setErrors={setErrors}
                                                    imRsk={imRsk} editRsk={editRsk}
                                                    exportersName={exportersName} setExportersName={setExportersName}
                                                    roleClaim={roleClaim} roleRecover={roleRecover} roleAdmin={roleAdmin}
                                                    handleChangeExporter={handleChangeExporter}
                                />

                            }
                            {transporte === 2 &&
                                <TransporteAereo claimForm={claimForm} setClaimForm={setClaimForm}
                                                 stuff={stuff} setStuff={setStuff}
                                                 errors={errors} setErrors={setErrors}
                                                 imRsk={imRsk} editRsk={editRsk}
                                                 exportersName={exportersName} setExportersName={setExportersName}
                                                 roleClaim={roleClaim} roleRecover={roleRecover} roleAdmin={roleAdmin}
                                                 handleChangeExporter={handleChangeExporter}
                                />
                            }
                            {transporte === 3 &&
                                <TransporteTerrestre claimForm={claimForm} setClaimForm={setClaimForm}
                                                     stuff={stuff} setStuff={setStuff}
                                                     errors={errors} setErrors={setErrors}
                                                     imRsk={imRsk} editRsk={editRsk}
                                                     exportersName={exportersName} setExportersName={setExportersName}
                                                     roleClaim={roleClaim} roleRecover={roleRecover} roleAdmin={roleAdmin}
                                                     handleChangeExporter={handleChangeExporter}
                                />
                            }
                        </Tab>
                    }
                    </Tabs>
                </div>
            </div>
        </>
    )
}
