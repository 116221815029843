import styles from "./tabla_stuff.module.css";
import {Table} from 'react-bootstrap/'

import {Button, Form, Pagination} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {ModalReclamo} from "../modal_reclamo/ModalReclamo";
import DBContext from "../../../context/DBContext";
import LangContext from "../../../context/LangContext";
import axios from "axios";
import {toast} from "react-toastify";

export const TablaStuff = ({stuff,setStuff,initStuffing,setInitStuffing,
                               listaStuffing,setListaStuffing,
                               perPage,setPerPage, url,setUrl,active,setActive,setTotalPages,totalPages}) => {
    const {traduccion} = useContext(LangContext);
    let {stuffing} = useContext(DBContext);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [hasMap, setHasMap] = useState(false);
    // stuffing = stuffing.data

    //console.log("stuffing", stuffing)
    //console.log("totalPages", totalPages)

    const token = localStorage.getItem("token").replace("Bearer ", "");;

    //Pagination
    const getClaims = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            //console.log("RES", res.data)
            setListaStuffing(res.data.data)
            setTotalPages(res.data.data.totalPages)
            // setCambioClaims(!cambioClaims)

        }).catch((error) => {
            //console.log(error);
            // toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
            //     position: toast.POSITION.TOP_RIGHT
            // })
        })
    }

    const goToPage = (page) => {
        //console.log("GO TO PAGE", page)
        url =(url.replace(/page=\d+/, `page=${page}`))
        //console.log("URL", url)
        setUrl(url)
        setActive(page)
    }

    const handlePerPage = (e) => {
        let page = e.target.value
        //console.log("PAGINANDO...", page)
        setPerPage(page)
    }
    useEffect(() => {
        let newUrl =(url.replace(/size=\d+/, `size=${perPage}`))
        newUrl =(newUrl.replace(/page=\d+/, `page=0`))
        setUrl(newUrl)
        setActive(1)
    },[perPage])


    useEffect(() => {
        //console.log("GET...", url)
        getClaims(url)
    },[url])

    // let numPage = Math.floor(listaClaim.totalItems / perPage);
    let items=[]
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item onClick={()=>goToPage(number)} key={number} active={number === active}>
                {number}
            </Pagination.Item>,
        );
    }

    return (
        <>
        <div className={styles.mostrar_nuevo_reclamo} >
            <div className={styles.mostrar}>
                <p>{traduccion.claimTable?.mostrar}</p>
                <Form.Select onChange={handlePerPage} size="sm" id="show">
                    {/*<option value="1">1</option>*/}
                    <option value="2">2</option>
                    <option value="4">4</option>
                    <option value="8">8</option>
                    <option value="20" selected={true}>20</option>
                </Form.Select>
            </div>

        </div>
            <div className={styles.contiene_tabla}>
                <Table borderless hover className={styles.tabla}>
                    <thead>
                    <tr >
                        <th>{traduccion.stuffingTable?.shippingOrder}</th>
                        <th className={styles.th_container_nr}>{traduccion.stuffingTable?.nContainer}</th>
                        <th>{traduccion.stuffingTable?.nave}</th>
                        <th>{traduccion.stuffingTable?.consignee}</th>
                        <th>{traduccion.stuffingTable?.pol}</th>
                        <th>{traduccion.stuffingTable?.pod}</th>
                        <th className={styles.th_buttons}></th>


                    </tr>

                    </thead>
                    <tbody>
                    {listaStuffing?.data?.map((datos,idx) =>
                            <tr key={idx} className={styles.tr_data}>
                                <td>{datos.boardingOrder}</td>
                                <td>{datos.containerNumber}</td>
                                <td>{datos.vessel}</td>
                                <td>{datos.consignee}</td>
                                <td>{datos.pol}</td>
                                <td>{datos.pod}</td>
                                <td className={styles.th_buttons}>
                                    {/*<Button variant="primary">Inspeccion</Button>{' '}*/}
                                    <Button variant="primary" onClick={()=> {
                                        setModalData(datos);
                                        setHasMap(true)
                                        setShowModal(true);
                                    }}>
                                        {traduccion.stuffingTable?.inspeccion}
                                    </Button>
                                    <Button variant="primary" onClick={()=> {
                                        setModalData(datos);
                                        setHasMap(false)
                                        setShowModal(true);
                                    }}>{traduccion.stuffingTable?.recupero}</Button>{' '}
                                </td>
                            </tr>
                        )}
                    <ModalReclamo
                        setInitStuffing={setInitStuffing}
                        stuff={stuff}
                        setStuff={setStuff}
                        stuffing={modalData}
                        show={showModal}
                        hasMap={hasMap}
                        onHide={() => setShowModal(false)}
                    />
                    {/*<tr className={styles.tr_data}>*/}


                    {/*    <td>Exportador</td>*/}
                    {/*    <td>HLDF 234567-5</td>*/}
                    {/*    <td>MSC elliot</td>*/}
                    {/*    <td>MSC elliot</td>*/}
                    {/*    <td>MSC elliot</td>*/}
                    {/*    <td>Estado</td>*/}
                    {/*    <td className={styles.th_buttons}>*/}
                    {/*        /!*<Button variant="primary">Inspeccion</Button>{' '}*!/*/}
                    {/*        <Button variant="primary" onClick={() => setShowModal(true)}>*/}
                    {/*            Inspeccion*/}
                    {/*        </Button>*/}
                    {/*        <Button variant="primary">Recupero</Button>{' '}*/}
                    {/*    </td>*/}
                    {/*    <ModalReclamo*/}
                    {/*        show={showModal}*/}
                    {/*        onHide={() => setShowModal(false)}*/}
                    {/*    />*/}

                    {/*</tr>*/}
                    </tbody>
                </Table>
            </div>
                <Pagination>{items}</Pagination>

        </>
    )
}
