import React, {useContext, useEffect, useState} from 'react';
import {Navbar,Container} from 'react-bootstrap/';
import {Button, Form, Modal, Overlay, Popover, Row} from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import styles from "./styleNavbar.module.css"
import {Sling as Hamburger} from "hamburger-react";
import LangContext from "../../context/LangContext";
import logo from '../../pages/Login/BOXClaims.png';
import spain from './spain.png';
import england from './united-kingdom.png';
import AccountContext from "../../context/DBContext";
import axios from "axios";
import {urlConfig} from "../../config/urlConfig";
import {toast, ToastContainer} from "react-toastify";
import {startClaim} from "../../config/initial";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {BsFillArrowLeftCircleFill} from "react-icons/bs";
import {GoPrimitiveDot} from "react-icons/go";

const HeadNavbar = (prop) => {

    const {session} = useContext(AccountContext);
    const {traduccion,handleLanguage} = useContext(LangContext);
    const [passModal, setPassModal] = useState(false);
    const [reset, setReset] = useState(false);
    const [notEqual, setNotEqual] = useState(0);
    const [cumple, setCumple] = useState(false);
    const [notEmpty, setNotEmpty] = useState(false);
    const [showNotif, setShowNotif] = useState(false);
    const [notif, setNotif] = useState([{
        type: "",
        nClaim: "",
        isRead: false,
    }]);
    const [notiAlert, setNotiAlert] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const [pass, setPass] = useState({
        oldPass: "",
        newPass: "",
        confirmPass: ""
    });

    // let regexPass = /^(?=.*[A-Za-z])(?=.*[@$!()%*#?&^<>+/|=:.,\-_~])[A-Za-z\d@$!()%*#?&^<>+/|=:.,\-_~]{8,15}$/;
    let regexPass = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,20}$/;

    const handleChangePass = (e) => {
        setPass({
            ...pass,
            [e.target.name]: e.target.value
        })

        if (e.target.name === "confirmPass") {
            if(pass.newPass !== e.target.value) {
                setNotEqual(1)
            }else{
                setNotEqual(2)
            }
        }
        if (e.target.name === "newPass") {
            if(pass.confirmPass !== e.target.value){
                setNotEqual(1)
            }else{
                setNotEqual(2)
            }

            if(e.target.value.match(regexPass) !== null) {
                setCumple(true)
            }else{
                setCumple(false)
            }
        }

        if (e.target.name === "oldPass") {
            if(e.target.value !== ""){
                setNotEmpty(true)
            }else{
                setNotEmpty(false)
            }
        }

        if (e.target.name === "confirmPass" && e.target.value === "") {
            setNotEqual(0)
        }
        if (e.target.name === "newPass" && e.target.value === "") {
            setNotEqual(0)
        }

    }

    const handleSession = (e) => {
        setReset(true)
        if (e.target.value === "cerrar") {
            localStorage.removeItem("token")
            window.location.reload()
        }
    };
    const showModal = (e) => {
        if (e.target.value === "pass") {
            setPassModal(true)
        }
    }

    const postChangePass = () => {
        if ((cumple === true || notEqual === 2 || notEmpty !== false)){
            const finalPass = {
                oldPassword: pass.oldPass,
                newPassword: pass.newPass
            }
            //console.log(finalPass)
            axios.post(`${urlConfig.allUrl}${urlConfig.portAccount}/api/v1/accounts/password`, finalPass, {
                headers: {
                    Authorization: `${localStorage.getItem("token")}`,
                    "X-MAILTYPE":'claims'

                }
            }).then(() => {
                toast.success(`${traduccion.topbar.passChanged}`)
                setPassModal(false)
                setReset(false)
            }).catch(err => {
                //console.log(err.response.data.message)
                // check if the error includes "password"
                if (err.response.data.message.includes("Invalid password")) {
                    //console.log("error")
                    toast.warning(`${traduccion.topbar.errorPass}`)
                    setReset(true)
                }
            })
        }
    }

    const token = localStorage.getItem("token");

    const editClaim = (elem) => {
        let tokenAtob = atob(localStorage.getItem("token").split(".")[1])
        let tokenA = JSON.parse(tokenAtob)
        //console.log(prop)
        //console.log(elem)
        let pre = ""
        if (tokenA.rol === "ROLE_EXPORTER"){
            pre = "/me"
        }
        const imExporter = tokenA.rol === "ROLE_EXPORTER";
        axios.put(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim${pre}/notification/${elem.claim}`, elem, {
            headers: {
                Authorization: token.toString().replace("Bearer ", "")
            }
        }).then(() => {
            let newNotificaciones = notif
            notif.map((noti, index) => {
                if (noti.nClaim === elem.claim) {
                    newNotificaciones[index].isRead = true
                }
            })
            setNotif(newNotificaciones)

            axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim${pre}/${elem.claim}`, {
                headers: {
                    Authorization: token.toString().replace("Bearer ", "")
                }
            }).then((res) => {
                //console.log("EDITANDO RECLAMO...", res.data)
                let newData = {...startClaim, ...res.data}
                //console.log("NEW DATAaaaaa", newData)
                //console.log(res.data)
                if (newData.comment === null) {
                    newData.comment = ""
                }
                if (newData.claimDate === null) {
                    newData.claimDate = ""
                }
                if (newData.lossTypeId === null) {
                    newData.lossTypeId = 1
                }
                if (newData.cargo?.fruits !== undefined) {
                    for (const fruits of newData.cargo.fruits) {
                        for (const harvest of fruits.fruits.harvesting) {
                            if (harvest.harvestDate === null) {
                                harvest.harvestDate = ""
                            }
                        }
                        for (const packa of fruits.fruits.packaging) {
                            if (packa.packageDate === null) {
                                packa.packageDate = ""
                            }
                        }
                    }
                }
                newData.documents = {
                    "shippingDocuments": res.data.documents?.shipping || [],
                    "shipping": res.data.documents?.shipping || [],
                    "consolidatedReport": res.data.documents?.consolidatedReport || "",
                    "controlledAtmosphere": res.data.documents?.controlledAtmosphere || "",
                    "thermograph": res.data.documents?.thermograph || "",
                    "thermograph2": res.data.documents?.thermograph2 || "",
                    "other": res.data?.documents.other || "",
                    "assignmentLetter": res.data.documents?.assignmentLetter || "",
                    "assignmentRights": res.data.documents?.assignmentRights || "",
                    "survey": res.data.documents?.surveyor?.survey || "",
                    "photograph": res.data.documents?.surveyor?.photograph || "",
                    "notice": res.data.documents?.surveyor?.notice || "",
                    "noticeDate": res.data.documents?.surveyor?.noticeDate || "",
                    "destruction": res.data.documents?.surveyor?.destruction || "",
                    "receipt": res.data.documents?.recovery?.receipt || "",
                    "settlement": res.data.documents?.recovery?.settlement || "",
                    "calculation": res.data.documents?.calculation || {
                        "creditNote": [],
                        "extraExpenses": [],
                        "marketPrices": [],
                        "salesResults": []
                    }
                };


                if (newData.lossCalculation.length === 0) {
                    //console.log("NO HAY CALCULO DE PERDIDA")
                    newData.lossCalculation = startClaim.lossCalculation
                } else {
                    //console.log("SI HAY CALCULO DE PERDIDA")
                    //console.log("CALCULO DE PERDIDA", res.data.lossCalculation)
                    //console.log("CALCULO DE PERDIDA", newData)
                    newData.lossCalculation = res.data.lossCalculation
                }
                // setOneClaimAll(newData)
                //console.log("LOCATION", location)
                const obj = {
                    state: {
                        id: res.data.id,
                        oneClaimAll: newData,
                        imRsk: !imExporter,
                        lossCalculation: newData.lossCalculation,
                        editRsk: true
                    }
                }

                //
                // //console.log("NEW LOCATION", newLocation)
                if (tokenA.rol === "ROLE_EXPORTER"){
                    if (location.pathname === "/informe_inspeccion") {
                        navigate('/')
                        setTimeout(() => navigate('/informe_inspeccion', obj)
                            , 0)
                    } else {
                        navigate('/informe_inspeccion', obj)
                    }
                }else{
                    if (location.pathname === "/informe_inspeccion_all") {
                        navigate('/')
                        setTimeout(() => navigate('/informe_inspeccion_all', obj)
                            , 0)
                    } else {
                        navigate('/informe_inspeccion_all', obj)
                    }
                }

            }).catch((error) => {
                //console.log(error);
            })
        }).catch((error) => {
            //console.log(error);
        })
    }

    const closeModal = () => {
        setPass({
            oldPass: "",
            newPass: "",
            confirmPass: ""
        })
        setNotEqual(0)
        setPassModal(false)
        setReset(false)
    }

    //console.log(session)
    const nombre = session.legalRepresentative===null || session.legalRepresentative===undefined
        ? session.firstName + " " + session.lastName
        : session.legalRepresentative;

    const persona = `${traduccion.topbar?.perfil}: ${nombre}`;

    //console.log({session})


    const clickCampana = () => {
        let tokenAtob = atob(localStorage.getItem("token").split(".")[1])
        let tokenA = JSON.parse(tokenAtob)
        //console.log(prop)
        let pre = ""
        if (tokenA.rol === "ROLE_EXPORTER"){
            pre = "/me"
        }
        setShowNotif(!showNotif)
        // if(showNotif !== true){
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim${pre}/notification`, {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            }
        })
            .then(res => {
                let notificaciones = []
                for (let i = 0; i < res.data.length; i++) {
                    //console.log(res.data[i])
                    let idClaim = res.data[i].claim
                    let message = res.data[i].message
                    let rskNumber = ""
                    let days = ""
                    if (message !== null && message.match(/ \d+/)) {
                        days = message.match(/ \d+/)[0]
                    }
                    if (message !== null && message.match(/(RSK)\w+\/?\d+/ )) {
                        rskNumber = message.match(/(RSK)\w+\/?\d+/)[0]
                        let regex = new RegExp(`${idClaim}`);
                        if (rskNumber.match(regex)){
                            //console.log("match");
                            let lastIndex = rskNumber.lastIndexOf(idClaim);
                            rskNumber = rskNumber.replace("/", "")
                            rskNumber = rskNumber.substring(0, lastIndex) + `/${idClaim}`;
                        }

                    }
                    //console.log(rskNumber)
                    notificaciones[i] = {
                        type: res.data[i].type,
                        nClaim: res.data[i].claim,
                        isRead: res.data[i].isRead,
                        message: res.data[i].message,
                        rskNumber: rskNumber,
                        days: days
                    }
                }
                setNotif(notificaciones)
                if (notificaciones.length > 0){
                    prop.setNotification(true)
                    setNotiAlert(true)
                }else{
                    prop.setNotification(false)
                    setNotiAlert(false)
                }
                //console.log(res)
            }).catch(err => {
            //console.log(err)
        })
        // }else{
        //     //console.log("desclick campana")
        // }
        // //console.log("click campana")
    }

    const manipulaNotificaciones = (type,nClaim,isRead,rskNum, days) => {
        switch (type) {
            case "NEW_CLAIM":
                return (
                    <>
                        <span>{`${traduccion?.others?.newClaim1} ${rskNum} ${traduccion?.others?.newClaim2}`}</span>
                        {!isRead &&
                            <span style={{color:'#e91c1c',marginLeft:'1rem'}}><GoPrimitiveDot/></span>
                        }
                    </>
                )
            case "CLAIM_INACTIVITY":
                return (
                    <>
                        <span>{`${traduccion?.others?.claimInactivity1} ${rskNum} ${traduccion?.others?.claimInactivity2}`}</span>
                        {!isRead &&
                            <span style={{color:'#e91c1c',marginLeft:'1rem'}}><GoPrimitiveDot/></span>
                        }
                        {/*<span className={styles.notif}><BsFillArrowLeftCircleFill/></span>*/}
                    </>
                )
            case "INSPECTION_CANCELED":
                return (
                    <>
                        <span>{`${traduccion?.others?.inspectionCancel1} ${rskNum} ${traduccion?.others?.inspectionCancel2} `}</span>
                        {!isRead &&
                            <span style={{color:'#e91c1c',marginLeft:'1rem'}}><GoPrimitiveDot/></span>
                        }
                    </>
                )
            case "INSPECTION_FINISHED":
                return (
                    <>
                        <span>{`${traduccion?.others?.inspectionfinish1} ${rskNum} ${traduccion?.others?.inspectionfinish2}`}</span>
                        {!isRead &&
                            <span style={{color:'#e91c1c',marginLeft:'1rem'}}><GoPrimitiveDot/></span>
                        }
                    </>
                )
            case "SIGNED_SETTLEMENT":
                return (
                    <>
                        <span>{`${traduccion?.others?.signedSettlement}`}</span>
                        {!isRead &&
                            <span style={{color:'#e91c1c',marginLeft:'1rem'}}><GoPrimitiveDot/></span>
                        }
                    </>
                )
            case "NEW_SURVEYOR":
                return (
                    <>
                        <span>{`${traduccion?.others?.newSurveyor}`}</span>
                        {!isRead &&
                            <span style={{color:'#e91c1c',marginLeft:'1rem'}}><GoPrimitiveDot/></span>
                        }
                    </>
                )
            case "TIME_BAR":
                return (
                    <>
                        <span>{`${traduccion?.others?.newClaim1} ${rskNum} ${traduccion?.others?.estaA} ${days} ${traduccion?.others?.timeBar}`}</span>
                        {!isRead &&
                            <span style={{color:'#e91c1c',marginLeft:'1rem'}}><GoPrimitiveDot/></span>
                        }
                    </>
                )
            default:
                return (
                    <>
                        <span>{`${traduccion?.others?.unknownNotif}`}</span>
                    </>
                )
        }

    }
    useEffect(() => {
        let tokenAtob = atob(localStorage.getItem("token").split(".")[1])
        let tokenA = JSON.parse(tokenAtob)
        //console.log(prop)
        let pre = ""
        if (tokenA.rol === "ROLE_EXPORTER"){
            pre = "/me"
        }
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim${pre}/notification`, {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            }
        })
            .then(res => {
                let notificaciones = []
                for (let i = 0; i < res.data.length; i++) {
                    //console.log(res.data[i])
                    let idClaim = res.data[i].claim
                    let message = res.data[i].message
                    let rskNumber = ""
                    let days = ""
                    if (message !== null && message.match(/ \d+/)) {
                        days = message.match(/ \d+/)[0]
                    }
                    if (message !== null && message.match(/(RSK)\w+\/?\d+/ )) {
                        rskNumber = message.match(/(RSK)\w+\/?\d+/)[0]
                        let regex = new RegExp(`${idClaim}`);
                        if (rskNumber.match(regex)){
                            //console.log("match");
                            let lastIndex = rskNumber.lastIndexOf(idClaim);
                            rskNumber = rskNumber.replace("/", "")
                            rskNumber = rskNumber.substring(0, lastIndex) + `/${idClaim}`;
                        }

                    }

                    notificaciones[i] = {
                        type: res.data[i].type,
                        nClaim: res.data[i].claim,
                        isRead: res.data[i].isRead,
                        message: res.data[i].message,
                        rskNumber: rskNumber,
                        days: days
                    }
                }
                setNotif(notificaciones)
                if (notificaciones.length > 0){
                    prop.setNotification(true)
                    setNotiAlert(true)
                }else{
                    prop.setNotification(false)
                    setNotiAlert(false)
                }
                //console.log(res)
            }).catch(err => {
            //console.log(err)
        })
    }, [])
    return (
        <>
            <ToastContainer/>
            <Navbar className={styles.topnav}>
                {/* Propiedad para saber si esta o no colapsada la barra toggled={prop.isColapsed}*/}
                <div className={styles.hamburger}>
                    <Hamburger toggle={prop.collap}/>
                </div>
                <Container fluid>
                    <div className={styles.icon_title}>
                        <div className={styles.rsk_icon}>
                            <img src={logo} alt="logo Box claims"/>
                        </div>
                        <p className={styles.titulo}>{traduccion.header?.titulo}</p>
                    </div>

                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">

                        <div style={{marginRight:"20px"}}>
                            <OverlayTrigger
                                show={showNotif}
                                onToggle={clickCampana}
                                trigger="click"
                                placement="bottom"
                                overlay={
                                    <Popover id="popover-positioned-bottom" style={{minWidth:'fit-content'}}>
                                        <Popover.Header as="h3" style={{background:'#e3e3ff', borderBottom:'2px solid #a3a3ff'}}>{traduccion?.others?.noti}</Popover.Header>
                                        <Popover.Body style={{padding:'0'}}>
                                            {notif.length >0 ?notif.map((item, index) => {
                                                    //console.log(item)
                                                    let notification = {
                                                        "type": item.type,
                                                        "claim": item.nClaim,
                                                        "isRead": item.isRead,
                                                        "message": item.message
                                                    }

                                                    return (
                                                        <div className={ index !== notif.length-1 ? styles.notihover: styles.notihoverlast} key={index}  onClick={()=>editClaim(notification)}>
                                                            <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                                                                {manipulaNotificaciones(item.type,item.nClaim,item.isRead, item.rskNumber,item.days)}
                                                            </div>
                                                        </div>
                                                    )}):
                                                <p style={{padding:'.8rem', marginBottom:0}}>No hay notificaciones</p>
                                            }
                                        </Popover.Body>
                                    </Popover>
                                }>
                                <div style={{position:'relative', display:'inline-flex'}}>
                                    <div style={{border:'1px solid blue'}} className={!prop.noti ? styles.bell : styles.bell_pulse} >
                                        <svg width="25" height="25" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22 21H2V19H3V12.031C3 7.043 7.03 3 12 3C16.97 3 21 7.043 21 12.031V19H22V21ZM5 19H19V12.031C19 8.148 15.866 5 12 5C8.134 5 5 8.148 5 12.031V19ZM9.5 22H14.5C14.5 22.663 14.2366 23.2989 13.7678 23.7678C13.2989 24.2366 12.663 24.5 12 24.5C11.337 24.5 10.7011 24.2366 10.2322 23.7678C9.76339 23.2989 9.5 22.663 9.5 22Z" fill="black"/>
                                        </svg>
                                    </div>
                                    {prop.noti &&
                                        <div style={{position:'absolute', left:'32px', top:'-10px'}}>
                                            <div style={{fontSize:'13px',textAlign:'center',position:'absolute',boxShadow:'1px 1px 3px ', color:'white', backgroundColor:'#f10707', border:'1px solid #ad0000', padding:'.1rem', borderRadius:'50%', minWidth:'1.7rem',minHeight:'1.7rem', fontWeight:'600', marginLeft:'2px' }}>{notif.length}</div>
                                        </div>
                                    }
                                </div>
                            </OverlayTrigger>

                            <div className={styles.lang}>
                                {(localStorage.getItem("lang")==='es')? <img src={spain} alt="es" style={{marginRight:5, marginBottom:4}} /> :
                                    <img src={england} alt="en" style={{marginRight:5, marginBottom:4}} />  }
                                <select defaultValue={localStorage.getItem("lang")} name="language" onChange={handleLanguage}>
                                    <option value="es">ES</option>
                                    <option value="en">EN</option>
                                </select>
                            </div>
                        </div>
                        <Form.Select  className={styles.form_selection} name="session" onChange={handleSession} onClick={showModal}>
                            <option selected={!reset}>{persona}</option>
                            <option value="pass">{traduccion.topbar?.pass}</option>
                            <option value="cerrar">{traduccion.topbar?.salir}</option>
                        </Form.Select>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Modal
                show={passModal}
                onHide={closeModal}
                // size={width > 768 ? "md" : "sm"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {traduccion?.modal?.cambiaPass}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>

                        <Row className="mt-2">
                            <div>{traduccion?.modal?.passActual}</div>
                            <Form.Control className="text-muted" name="oldPass" type="password" onChange={handleChangePass} placeholder={traduccion?.modal?.passActual}>

                            </Form.Control>
                        </Row>
                        <Row className="mt-4">
                            <div>{traduccion?.modal?.passNueva}</div>
                            {(cumple===false && pass.newPass.length>0) &&
                                <div style={{fontSize:13, marginBottom:4}}>- Debe tener entre 8 y 15 caracteres<br/>- Debe incluir al menos una mayuscula, minuscula y numero.</div>
                            }
                            <Form.Control type="password" name="newPass" onChange={handleChangePass} placeholder={traduccion?.modal?.passNueva} />
                        </Row>
                        <Row className="my-4">
                            <div>{traduccion?.modal?.passConfirm}</div>
                            <Form.Control type="password" name="confirmPass" onChange={handleChangePass} placeholder={traduccion?.modal?.passConfirm} />
                            {(notEqual===1 && pass.confirmPass.length>0)?
                                <div className="text-danger" style={{fontSize:14}}>{(localStorage.getItem("lang")==='es')? "Contraseñas no coinciden": "Passwords do not match" }</div>
                                :<></>
                            }
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>

                    <Button disabled={(cumple !== true || notEqual !== 2 || notEmpty === false)}
                            onClick={postChangePass}
                    >Confirmar</Button>

                    <Button
                        onClick={closeModal}
                    >Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default HeadNavbar;
