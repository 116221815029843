import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import stylesSurv from "../surveyors/new_surveyor.module.css";
import estilo from "../surveyors/surveyor_details.module.css";
import styles from "./new_company.module.css";

import {BsEye, BsPencil, BsPlusSquareFill, BsTrash} from "react-icons/bs";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import imagen from "../default_logo.png";
import {toast, ToastContainer} from "react-toastify";
import DBContext from "../../../context/DBContext";
import {AiFillMinusSquare} from "react-icons/ai";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import {useParamsUrl} from "../useParamsUrl";
import LangContext from "../../../context/LangContext";
import Bank from "./Bank";

export const NewCompanySurveyor = ({oneCompany, editCompanySurveyor}) => {

    //console.log(3, editCompanySurveyor)

    const baseCompanySurveyor = {
        id: "",
        logo: "",
        companyName: "",
        address: "",
        vat_id: "",
        phone: "",
        legalRepresentative: "",
        email: "",
        optionalEmail: "",
        secondaryEmail: "",
        status: 0,
        countries: [],
        bankInformation: [{
            accountNumber: "",
            bankName: "",
            bankAddress: "",
            swiftCode: "",
            ibanCode: "",
            bankHolderName: "",
            bankHolderAddress: "",
            bicCode: "",
            currency: 0,
            area_id: 0
        }]
    }

    const currentCompanySurveyor = {
        id: oneCompany.id,
        logo: oneCompany.logo,
        companyName: oneCompany.companyName,
        address: oneCompany.address,
        vat_id: oneCompany.vat_id,
        phone: oneCompany.phone,
        legalRepresentative: oneCompany.legalRepresentative,
        email: oneCompany.email,
        optionalEmail: oneCompany.optionalEmail,
        secondaryEmail: oneCompany.secondaryEmail,
        status: oneCompany.status,
        countries: oneCompany.countries,
        surveyors: oneCompany.surveyors,
        bankInformation: oneCompany.bankInformation
    }

    //console.log({currentCompanySurveyor})


    const {traduccion} = useContext(LangContext);

    const {
        paisesRates,
        setCambioCompany,
        cambioCompany,
        cambioInspector,
        setCambioInspector
    } = useContext(DBContext);

    const [companySurveyor, setCompanySurveyor] = useState(editCompanySurveyor ? currentCompanySurveyor : baseCompanySurveyor);

    const {searchUrl, handleSearch} = useParamsUrl(`${urlConfig.searchSurveyorCompanyUrl}/${oneCompany.id}`);
    const [profileImage, setProfileImage] = useState(imagen);
    const [previewProfileImage, setPreviewProfileImage] = useState(null);

    const [operationCountries, setOperationCountries] = useState([])
    const [countries, setCountries] = useState([]);
    const [status, setStatus] = useState([]);

    useEffect(() => {
        const filteredCountryList = paisesRates.filter(country => companySurveyor.countries.includes(country.id));
        setOperationCountries(filteredCountryList);
    }, [companySurveyor])

    useEffect(() => {
        //console.log(previewProfileImage, editCompanySurveyor)
        if (editCompanySurveyor) {
            if (previewProfileImage == null) {
                setProfileImage(companySurveyor.logo)
            }
        }
    }, [companySurveyor])


    useEffect(() => {
        const countryArray = [];
        if (editCompanySurveyor) {
            const surveyorsCopy = companySurveyor.surveyors.slice();
            surveyorsCopy.forEach(surveyor => {
                const {country_id, country} = surveyor;
                const existingCountry = countryArray.find(c => c.country_id === country_id);
                if (!existingCountry) {
                    countryArray.push({country_id, country});
                }
            });
            setCountries(countryArray);
        }
    }, [])

    useEffect(() => {
        const statusArray = [];
        if (editCompanySurveyor) {
            const surveyorsCopy = companySurveyor.surveyors.slice();
            surveyorsCopy.forEach(surveyor => {
                const {status} = surveyor;
                const existingStatus = statusArray.find(s => s.id === status.id);
                if (!existingStatus) {
                    statusArray.push(status);
                }
            });
            setStatus(statusArray);
        }
    }, [])

    const handlePreviewImg = (e) => {
        //console.log(e.target.files.length);
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const fileUrl = URL.createObjectURL(file);
            setProfileImage(fileUrl);
            setPreviewProfileImage(file);
        }
    }

    const handleChanges = (e) => {
        setCompanySurveyor({
            ...companySurveyor,
            [e.target.name]: e.target.value
        })
    }

    const searchSurveyors = () => {
        return axios.get(searchUrl.toString(), {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            const {surveyors} = res.data;
            setCompanySurveyor({...companySurveyor, surveyors});
        }).catch((error) => {
            //console.log(error);
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        });
    }

    const handleDeleteOperationCountry = (index) => {
        const newOperationCountries = operationCountries.filter((_, i) => i !== index);
        setOperationCountries(newOperationCountries)
    }

    const handleAddOperationCountry = () => {
        setOperationCountries([...operationCountries, ""])
    }

    const handleOperationCountries = (e) => {
        const {value} = e.target;
        setCompanySurveyor({
            ...companySurveyor,
            countries: [...companySurveyor.countries, parseInt(value)]
        })
    }

    //console.log(companySurveyor)

    const uploadImage = () => {
        try {
            if (!editCompanySurveyor) {
                if (previewProfileImage === null) {
                    toast.error(`${traduccion?.errores?.imgNeed}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    return false;
                }
                const {type, name, size} = previewProfileImage;
                if (type !== "image/png" && type !== "image/jpeg" && type !== "image/jpg") {
                    toast.error(`${traduccion?.errores?.invalidImg}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    return false;
                }
                if (size > 20000000) {
                    toast.error(`${traduccion?.errores?.invalidImg}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    return false;
                }
                const image = new File([previewProfileImage], `PROFILE_${name}`, {type});
                let formData = new FormData();
                formData.append('file', image);

                const header = {
                    headers: {
                        'content-type': 'multipart/form-data', Authorization: localStorage.getItem("token")
                    }
                }
                return axios.post(`${urlConfig.filesUrl}/img`, formData, header);
            } else {
                if (previewProfileImage === null) {
                    return true;
                } else {
                    const {type, name, size} = previewProfileImage;
                    if (type !== "image/png" && type !== "image/jpeg" && type !== "image/jpg") {
                        toast.error(`${traduccion?.errores?.invalidImg}`, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        return false;
                    }
                    if (size > 20000000) {
                        toast.error(`${traduccion?.errores?.invalidImg}`, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        return false;
                    }
                    const image = new File([previewProfileImage], `PROFILE_${name}`, {type});
                    let formData = new FormData();
                    formData.append('file', image);

                    const header = {
                        headers: {
                            'content-type': 'multipart/form-data', Authorization: localStorage.getItem("token")
                        }
                    }
                    return axios.post(`${urlConfig.filesUrl}/img`, formData, header);
                }
            }
        } catch (err) {
            console.error(err)
            toast.error(`${traduccion?.errores?.errProfileImg}`, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
    }

    const saveCompanySurveyor = (logo) => {
        const header = {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }
        companySurveyor.logo = logo;
        axios.post(urlConfig.companyUrl, companySurveyor, header)
            .then((res) => {
                //console.log("res", res)
                setCambioCompany(!cambioCompany);
                toast.success(`${traduccion?.success?.successNewComp}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }).catch((err) => {
            if (err.code === "ERR_NETWORK") {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                if (err.response.data.message === "Error in fields") {
                    const {message, errors} = err.response.data;
                    toast.error(<> {message} <br/> {errors.join(", ")} </>);
                } else if (err.response.data.message) {
                    toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    toast.error(`${traduccion?.errores?.errNewComp}`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        })
    }

    const updateCompanySurveyor = (logo) => {
        const header = {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }
        companySurveyor.logo = logo;
        axios.put(`${urlConfig.companyUrl}${oneCompany.id}`, companySurveyor, header).then(res => {
            toast.success(`${traduccion?.success?.successActCompSurv}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch(err => {
            console.error(err);
            if (err.code === "ERR_NETWORK") {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                if (err.response.data.message === "Error in fields") {
                    const {message, errors} = err.response.data;
                    toast.error(<> {message} <br/> {errors.join(", ")} </>);
                } else {
                    toast.error(`${traduccion?.errores?.errEditComp}`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        })
    }

    const uploadImageAndForm = async () => {
        try {
            const result = await uploadImage();
            if (result === true) {
                if (editCompanySurveyor) {
                    updateCompanySurveyor(profileImage);
                } else {
                    saveCompanySurveyor(profileImage);
                }
            } else if (result === false) {
                return false;
            } else {
                if (editCompanySurveyor) {
                    updateCompanySurveyor(result.data.data[0]);
                } else {
                    saveCompanySurveyor(result.data.data[0]);
                }
            }
        } catch (err) {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT
            })
        } finally {

        }
    }


    const deleteSurveyor = (index, id) => {
        let token = localStorage.getItem("token");
        const noBearerToken = token.toString().replace("Bearer ", "")
        let header = {
            headers: {
                Authorization: noBearerToken,
            }
        }
        axios.delete(`${urlConfig.inspectorUrl}/${id}`, header).then((response) => {
            setCambioInspector(!cambioInspector);
            setCompanySurveyor({
                ...companySurveyor,
                surveyors: companySurveyor.surveyors.filter((item, i) => i !== index)
            })
        }).catch((error) => {
            //console.log(error)
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }

    const addBank = () => {
        const newBank = {
            accountNumber: "",
            bankName: "",
            bankAddress: "",
            swiftCode: "",
            ibanCode: "",
            bankHolderName: "",
            bankHolderAddress: "",
            bicCode: "",
            currency: 0,
            area_id: 0,
        }

        const updatedData = {
            ...companySurveyor,
            bankInformation: [...companySurveyor.bankInformation, newBank],
        }
        setCompanySurveyor(updatedData);
    }


    const deleteBank = () => {
        const updatedData = {
            ...companySurveyor,
            bankInformation: companySurveyor.bankInformation.slice(0, -1)
        }
        setCompanySurveyor(updatedData);
    }

    const handleBankInfo = (info, index) => {
        let arrayAccounts = [...companySurveyor.bankInformation]
        arrayAccounts[index] = info;
        setCompanySurveyor({
            ...companySurveyor,
            bankInformation: arrayAccounts
        });
    }

    return (
        <>

            <ToastContainer/>

            <div style={{width: '100%', margin: 0, padding: 0}}>
                <div className={stylesSurv.title_surveyor} style={{marginBottom: 15}}>
                    <div>{oneCompany !== "" ? "Editar" : "Crear"} Empresa</div>
                </div>

                <Row className={stylesSurv.contain_all_surveyors}>

                    <Col sm={11} md={5} xl={3}>

                        <div style={{display: 'flex', flexDirection: 'column', gap: '2.4rem'}}>

                            <Form.Group>
                                <Form.Select className={styles.selects_input_details} onChange={handleChanges}
                                             value={companySurveyor.status} name={"status"}>
                                    <option value={true}>{traduccion?.clients?.active}</option>
                                    <option value={false}>{traduccion?.clients?.inactive}</option>
                                </Form.Select>
                            </Form.Group>

                            <div className={stylesSurv.all_surveyors}>
                                <div className={stylesSurv.title}>{traduccion?.clients?.compLogo}</div>
                                <div>
                                    <div className={styles.photo}>
                                        <label className={styles.photocon} htmlFor="file-input">
                                            <div>
                                                {/*<BiUpload style={{fontSize:44}}/>*/}
                                                <img style={{backgroundColor: 'transparent'}} src={profileImage}
                                                     alt="logo"/>
                                            </div>
                                        </label>
                                        <input style={{display: 'none'}} onChange={handlePreviewImg} id="file-input"
                                               type="file" accept={"image/*"} name="logo"/>

                                    </div>
                                </div>
                            </div>

                        </div>
                        {/*---------Area geográfica y el rate---------------*/}
                        <Row style={{marginTop:'1rem'}}>
                            <Col sm={12}>

                                <div className={stylesSurv.all_surveyors}>
                                    <div style={{display: 'flex', justifyContent: 'initial', marginLeft: '1rem'}}>
                                        <div>
                                            <div
                                                className={stylesSurv.title}>{traduccion.newSurveyor?.pais} / {traduccion?.carga?.moneda}</div>
                                            {
                                                operationCountries.length > 0 ?
                                                    operationCountries.map((operationCountry, index) => (
                                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                            <Form.Select style={{margin: '.3rem 0'}}
                                                                         onChange={handleOperationCountries}
                                                                         value={operationCountry.id}>
                                                                <option value="">-</option>
                                                                {
                                                                    paisesRates.length > 0 ?
                                                                        paisesRates.map(country => (
                                                                            <option key={country.id}
                                                                                    value={country.id}>{country.country}</option>
                                                                        )) : null
                                                                }
                                                            </Form.Select>
                                                            <div style={{alignSelf: 'center', margin: '.4rem'}}>
                                                                <AiFillMinusSquare
                                                                    onClick={() => handleDeleteOperationCountry(index)}
                                                                    className={styles.boton_mas}/>
                                                            </div>

                                                        </div>
                                                    )) : null
                                            }
                                            <BsPlusSquareFill
                                                onClick={handleAddOperationCountry}
                                                className={styles.boton_mas}/>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={11} sm={6} md={7} xl={9}>
                        <div className={stylesSurv.all_surveyors}>
                            <Col sm={12} lg={9}>
                                <div className={stylesSurv.title}>{traduccion?.clients?.contactDetails}</div>
                                <div>{traduccion?.clients?.compName}</div>
                                <Form.Group controlId="companyName">
                                    <Form.Control
                                        value={companySurveyor.companyName}
                                        onChange={handleChanges}
                                        className={styles.selects_input_details} type="text"
                                        placeholder={traduccion?.clients?.compName} name="companyName"/>
                                </Form.Group>
                                <div></div>
                                <Form.Group controlId="address">
                                    <div>{traduccion?.clients?.address}
                                    </div>

                                    <Form.Control
                                        value={companySurveyor.address}
                                        onChange={handleChanges}
                                        className={styles.selects_input_details} type="text"
                                        placeholder={traduccion?.clients?.address} name="address"/>
                                </Form.Group>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <Form.Group controlId="vat_id" style={{marginRight: 40}}>
                                        <div>VAT ID</div>
                                        <Form.Control
                                            value={companySurveyor.vat_id}
                                            onChange={handleChanges}
                                            className={styles.selects_input_details} type="text"
                                            placeholder="VAT ID" name="vat_id"/>
                                    </Form.Group>
                                    <Form.Group controlId="phone">
                                        <div>{traduccion?.clients?.phone}</div>
                                        <Form.Control
                                            value={companySurveyor.phone}
                                            onChange={handleChanges}
                                            className={styles.selects_input_details} type="text"
                                            placeholder={traduccion?.clients?.phone} name="phone"/>
                                    </Form.Group>
                                </div>
                                <div>{traduccion?.clients?.legalRep}</div>
                                <Form.Group controlId="legalRepresentative">
                                    <Form.Control
                                        value={companySurveyor.legalRepresentative}
                                        onChange={handleChanges}
                                        className={styles.selects_input_details} type="text"
                                        placeholder={traduccion?.clients?.legalRep} name="legalRepresentative"/>
                                </Form.Group>
                                <div>{traduccion?.clients?.pMail}</div>
                                <Form.Group controlId="email">
                                    <Form.Control
                                        value={companySurveyor.email}
                                        onChange={handleChanges}
                                        className={styles.selects_input_details} type="email"
                                        style={{maxWidth: 700}}
                                        placeholder={traduccion?.clients?.pMail} name="email"/>
                                </Form.Group>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <Form.Group controlId="vatId" style={{marginRight: 40}}>
                                        <div>Optional mail</div>
                                        <Form.Control
                                            value={companySurveyor.optionalEmail}
                                            onChange={handleChanges}
                                            className={styles.selects_input_details} type="text"
                                            placeholder="Optional email" name="optionalEmail"/>
                                    </Form.Group>
                                    <Form.Group controlId="phone">
                                        <div>Secondary mail</div>
                                        <Form.Control
                                            value={companySurveyor.secondaryEmail}
                                            onChange={handleChanges}
                                            className={styles.selects_input_details} type="text"
                                            placeholder="Secondary email" name="secondaryEmail"/>
                                    </Form.Group>
                                </div>
                            </Col>
                        </div>
                    </Col>
                </Row>

                {
                    companySurveyor.bankInformation.map((bank, index) => (
                        <Bank
                            key={index}
                            handleBankInfo={info => handleBankInfo(info, index)}
                            bankInformation={bank}
                        />
                    ))
                }
                <div style={{display: "flex", justifyContent: "start", position: "absolute"}}>
                    {
                        companySurveyor.bankInformation.length < 2 ? (
                            <Button onClick={addBank}
                                    style={{width: 200, margin: '1.5rem 2rem 3rem 2rem', padding: '1rem 2rem'}}>Agregar
                                cuenta</Button>
                        ) : <Button onClick={deleteBank}
                                    style={{width: 200, margin: '1.5rem 2rem 3rem 2rem', padding: '1rem 2rem'}}>Eliminar
                            cuenta</Button>
                    }
                </div>


                {oneCompany === "" ?
                    <div style={{display: "flex", justifyContent: "end"}}>
                        <Button onClick={uploadImageAndForm}
                                style={{width: 200, margin: '1.5rem 2rem 3rem 2rem', padding: '1rem 2rem'}}>
                            Guardar
                        </Button>
                    </div>
                    :
                    <div style={{display: "flex", justifyContent: "end"}}>
                        <Button onClick={uploadImageAndForm}
                                style={{width: 200, margin: '1.5rem 2rem 3rem 2rem', padding: '1rem 2rem'}}>
                            Editar
                        </Button>
                    </div>
                }

                <Row className={stylesSurv.contain_all_surveyors} style={{marginTop: 30}}>
                    <Col sm={12}>
                        <div className={stylesSurv.all_surveyors}>
                            {/*-------- Filtro de companias de surveyor -----------*/}

                            <div className={estilo.surveyor_details_title}
                                 style={{marginTop: '.5rem', marginBottom: '-.5rem'}}>Affiliates surveyors
                            </div>
                            <div className={estilo.surveyor_details_all} style={{border: 'none', boxShadow: 'none'}}>

                                <div className={estilo.personal_details_container2}>
                                    <Form className={estilo.form_group}>

                                        <div>
                                            <label htmlFor="nameF"
                                                   style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{traduccion?.clients?.name}</label>
                                            <Form.Control id="nameF" className={estilo.input_style} type="text"
                                                          placeholder={traduccion?.mantendores?.nombre} name="name"
                                                          onChange={handleSearch}/>

                                        </div>
                                        <div>
                                            <label htmlFor="countryF">{traduccion?.clients?.country}</label>
                                            <Form.Select className={estilo.input_style} id="countryF" name="country"
                                                         onChange={handleSearch}>
                                                <option value="">-</option>
                                                {
                                                    countries.length > 0 ?
                                                        countries.map(item =>
                                                            <option key={item.country_id}
                                                                    value={item.country_id}>{item.country}</option>
                                                        ) : null
                                                }
                                            </Form.Select>
                                        </div>

                                        <div>
                                            <label htmlFor="estadoF">{traduccion?.clients?.status}</label>

                                            <div style={{display: 'flex'}}>
                                                <div>
                                                    <Form.Select className={estilo.input_style} id="estadoF"
                                                                 name="status" onChange={handleSearch}>
                                                        <option value="">-</option>
                                                        {
                                                            status.length > 0 ?
                                                                status.map(item =>
                                                                    <option key={item.id}
                                                                            value={item.id}>{item.status}</option>
                                                                ) : null
                                                        }
                                                    </Form.Select>
                                                </div>
                                                <div className={estilo.boton_filtro}>
                                                    <Button variant="primary" onClick={searchSurveyors}>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                                                fill="white"/>
                                                        </svg>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                    </Form>
                                </div>
                            </div>
                            <div className={stylesTabla.contiene_tabla}>
                                <Table borderless hover className={stylesTabla.tabla}>
                                    <thead>
                                    <tr>
                                        <th>{traduccion?.clients?.name}</th>
                                        <th className={styles.th_container_nr}>{traduccion?.clients?.phone}</th>
                                        <th>{traduccion?.login?.correo}</th>
                                        <th>Main Address</th>
                                        <th>{traduccion?.clients?.country}</th>
                                        <th>{traduccion?.clients?.status}</th>
                                        <th></th>
                                    </tr>

                                    </thead>
                                    <tbody>

                                    {
                                        companySurveyor.surveyors?.length > 0 ?
                                            companySurveyor.surveyors.map((datos, index) =>
                                                <tr key={index} className={stylesTabla.tr_data}>
                                                    <td>{datos.lastName ? `${datos.firstName} ${datos.lastName}` : datos.firstName}</td>
                                                    <td>{datos.phone}</td>
                                                    <td>{datos.email}</td>
                                                    <td>{datos.mainAddress}</td>
                                                    <td>{datos.country}</td>
                                                    <td>{datos.status.status}</td>
                                                    <td>
                                                        <div className={stylesTabla.botones_botones_surveyor}
                                                             style={{display: 'flex'}}>
                                                            <div
                                                                onClick={() => deleteSurveyor(index, datos.id)}
                                                                className={stylesTabla.boton_icono}>
                                                                <BsTrash style={{color: 'red'}}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) :
                                            <tr>
                                                <td colSpan="8" style={{textAlign: "center"}}>No hay datos disponibles
                                                </td>
                                            </tr>
                                    }

                                    </tbody>
                                </Table>
                            </div>
                            {/*-------- Fin Filtro de compañías de surveyor -----------*/}
                        </div>

                    </Col>
                </Row>
            </div>
        </>
    )
}
