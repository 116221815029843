import styles from "../billing/billingtabla.module.css";
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {Button, Form, Pagination, Table, Modal, Spinner} from "react-bootstrap";
import {AiOutlineEye} from "react-icons/ai";
import React, {useContext, useEffect, useRef, useState} from "react";
import LangContext from "../../../context/LangContext";
import {toast} from "react-toastify";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";

import {MdPictureAsPdf} from "react-icons/md";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import Pager from "./Pager";

export const TableCobro = ({searchUrl}) => {

    const {traduccion} = useContext(LangContext);
    const [show, setShow] = React.useState(false);
    const [objModal, setObjModal] = React.useState(0);
    const [show2, setShow2] = React.useState(false);
    const [objModal2, setObjModal2] = React.useState(0);

    console.log({objModal2})

    const initialRecoveryNumberOrder = useRef(true);
    const initialDateOfIssueOrder = useRef(true);
    const initialExporterNameOrder = useRef(true);
    const initialDateOfPayOrder = useRef(true);
    const initialStatusOrder = useRef(true);

    const [recoveryNumberOrder, setRecoveryNumberOrder] = useState(true);
    const [dateOfIssueOrder, setDateOfIssue] = useState(true);
    const [exporterNameOrder, setExporterNameOrder] = useState(true);
    const [dateOfPayOrder, setDateOfPayOrder] = useState(true);
    const [statusOrder, setStatusOrder] = useState(true);

    const [listaCobro, setListaCobro] = useState([]);

    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [active, setActive] = useState(0);

    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/recovery/search?page=0&size=${perPage}`);

    const [loading, setLoading] = useState(true);

    const searchClaims = () => {
        setLoading(true);
        return axios.get(searchUrl, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            setListaCobro(res.data.recoveryOrders)
            setTotalPages(res.data.totalPages - 1)
        }).catch((error) => {
            console.log({error})
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if (searchUrl === null) return;
        searchClaims();
    }, [searchUrl]);

    useEffect(() => {
        if (initialRecoveryNumberOrder.current) {
            initialRecoveryNumberOrder.current = false;
            return;
        }
        if (!recoveryNumberOrder) {
            if (!url.includes("sort=rn,desc") && url.includes("sort=rn,asc")) {
                setUrl(url.replace("&sort=rn,asc", "&sort=rn,desc"));
            } else {
                setUrl(url + "&sort=rn,desc");
            }
        } else {
            if (!url.includes("sort=rn,asc") && url.includes("sort=rn,desc")) {
                setUrl(url.replace("&sort=rn,desc", "&sort=rn,asc"))
            } else {
                setUrl(url + "&sort=rn,asc");
            }
        }
    }, [recoveryNumberOrder]);

    useEffect(() => {
        if (initialDateOfIssueOrder.current) {
            initialDateOfIssueOrder.current = false;
            return;
        }
        if (!dateOfIssueOrder) {
            if (!url.includes("sort=doi,desc") && url.includes("sort=doi,asc")) {
                setUrl(url.replace("&sort=doi,asc", "&sort=doi,desc"));
            } else {
                setUrl(url + "&sort=doi,desc");
            }
        } else {
            if (!url.includes("sort=doi,asc") && url.includes("sort=doi,desc")) {
                setUrl(url.replace("&sort=doi,desc", "&sort=doi,asc"))
            } else {
                setUrl(url + "&sort=doi,asc");
            }
        }
    }, [dateOfIssueOrder]);

    useEffect(() => {
        if (initialExporterNameOrder.current) {
            initialExporterNameOrder.current = false;
            return;
        }
        if (!exporterNameOrder) {
            if (!url.includes("sort=exp,desc") && url.includes("sort=exp,asc")) {
                setUrl(url.replace("&sort=exp,asc", "&sort=exp,desc"));
            } else {
                setUrl(url + "&sort=exp,desc");
            }
        } else {
            if (!url.includes("sort=exp,asc") && url.includes("sort=exp,desc")) {
                setUrl(url.replace("&sort=exp,desc", "&sort=exp,asc"))
            } else {
                setUrl(url + "&sort=exp,asc");
            }
        }
    }, [exporterNameOrder]);

    useEffect(() => {
        if (initialDateOfPayOrder.current) {
            initialDateOfPayOrder.current = false;
            return;
        }
        if (!dateOfPayOrder) {
            if (!url.includes("sort=dop,desc") && url.includes("sort=dop,asc")) {
                setUrl(url.replace("&sort=dop,asc", "&sort=dop,desc"));
            } else {
                setUrl(url + "&sort=dop,desc");
            }
        } else {
            if (!url.includes("sort=dop,asc") && url.includes("sort=dop,desc")) {
                setUrl(url.replace("&sort=dop,desc", "&sort=dop,asc"))
            } else {
                setUrl(url + "&sort=dop,asc");
            }
        }
    }, [dateOfPayOrder]);

    useEffect(() => {
        if (initialStatusOrder.current) {
            initialStatusOrder.current = false;
            return;
        }
        if (!statusOrder) {
            if (!url.includes("sort=st,desc") && url.includes("sort=st,asc")) {
                setUrl(url.replace("&sort=st,asc", "&sort=st,desc"));
            } else {
                setUrl(url + "&sort=st,desc");
            }
        } else {
            if (!url.includes("sort=st,asc") && url.includes("sort=st,desc")) {
                setUrl(url.replace("&sort=st,desc", "&sort=st,asc"))
            } else {
                setUrl(url + "&sort=st,asc");
            }
        }
    }, [statusOrder]);


    const token = localStorage.getItem("token");
    let rol = ""
    if (token !== null) {
        const payload = JSON.parse(atob(token.split(".")[1]));
        rol = payload.rol;
    }
    const searchSortRecoveryOrder = (url) => {
        setLoading(true);
        if (token) {
            if (rol === "ROLE_EXPORTER" || rol.includes("RSK")) {
                axios.get(url, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    setListaCobro(res.data.recoveryOrders)
                    setTotalPages(res.data.totalPages - 1)
                }).catch((error) => {
                    console.log(error);
                    toast.error(`${traduccion?.errores?.errorSorting}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }

    useEffect(() => {
        searchSortRecoveryOrder(url)
    }, [url]);

    const goToPage = (page) => {
        let paginatedUrl = url.replace(/page=\d+/, `page=${page}`);
        setUrl(paginatedUrl)
        setActive(page)
    }

    const abrirModal = (obj) => {
        setObjModal(obj)
        setShow(true)
    }

    const handleFechaPago = (e, idx) => {
        let newLista = listaCobro.map((item, index) => {
            if (index === idx) {
                return {
                    ...item,
                    dateOfPay: e.target.value
                }
            }
            return item
        })
        setListaCobro(newLista);
    }

    const handleEstado = (e, idx) => {
        let newLista = listaCobro.map((item, index) => {
            if (index === idx) {
                return {
                    ...item,
                    statusOrder: e.target.value
                }
            }
            return item
        })
        if (e.target.value !== "2") {
            if (listaCobro[idx].dateOfPay !== "" && listaCobro[idx].dateOfPay !== null) {
                setObjModal2(newLista[idx])
                setShow2(true)
            } else {
                toast.warning(`${traduccion?.warning?.debeIngresarFechaPago}`)
            }
        } else {
            setObjModal2(newLista[idx])
            setShow2(true)
        }
    }

    const updateStatus = () => {
        let token = localStorage.getItem('token').replace("Bearer", '')
        let objParaEnviar = {
            dateOfPay: objModal2.dateOfPay,
            status: objModal2.statusOrder
        }
        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/recovery/status/${objModal2.id}`, objParaEnviar, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            toast.success(`${traduccion?.success?.successAct}`)
            setShow2(false)
            axios.get(url, {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                setListaCobro(res.data.recoveryOrders)
            }).catch((err) => {

            })
        }).catch((err) => {
            toast.error(`${traduccion?.errores?.errAct}`)
        })
        setShow2(false)
    }

    /*
    const updatePayment = () => {
        //console.log("UPDATE PAYMENT")
        //console.log(objModal2)
        let token = localStorage.getItem('token').replace("Bearer", '')
        let objParaEnviar = {
            dateOfPay: objModal2.dateOfPay,
            status: objModal2.statusOrder
        }
        let urlStatus = "";
        if (isPago) {
            // urlStatus = `${urlConfig.urlNgrok}/api/v1/order/pay/status/${objModal2.id}`
            urlStatus = `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/pay/status/${objModal2.id}`

        } else {
            // urlStatus = `${urlConfig.urlNgrok}/api/v1/order/recovery/status/${objModal2.id}`
            urlStatus = `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/recovery/status/${objModal2.id}`

        }

        axios.post(urlStatus, objParaEnviar, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            //console.log(res)
            toast.success(`${traduccion?.success?.successAct}`)
            setShow2(false)
            // if (isPago){
            //     setCambioListaPago(!cambioListaPago)
            //     setListaCobroPago(listaPago)
            //
            // }else{
            //     setCambioListaCobro(!cambioListaCobro)
            //     setListaCobroPago(listaCobro)
            // }
            //console.log("URL", url)
            axios.get(url, {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                //console.log(res)
                if (isPago) {
                    //console.log(res.data.payOrders)
                    setListaCobroPago(res.data.payOrders)
                } else {
                    setListaCobroPago(res.data.recoveryOrders)
                }
            }).catch((err) => {
                //console.log(err)
            })
        }).catch((err) => {
            //console.log(err)
            toast.error(`${traduccion?.errores?.errAct}`)
        })

        //console.log("OBJ PARA ENVIAR", objParaEnviar)
        setShow2(false)
    }

     */

    const downloadPdf = (id) => {
        //console.log("DOWNLOAD PDF", id)
        let token = localStorage.getItem('token').replace("Bearer", '')

        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/recovery/${id}`, {}, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Disposition': 'attachment',
                Authorization: token,

            }
        }).then((res) => {
            const url = URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "Recovery Order" + `-${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            toast.success(`${traduccion?.success?.downPdf}`)
        }).catch((err) => {
            //console.log(err)
            toast.error(`${traduccion?.errores?.errDown}`)
        })
    }

    const handleCurr = (type, value) => {
        if (value === null || value === undefined || type === null || type === undefined) {
            return "---"
        }
        const lang = localStorage.getItem("lang");
        const formatter = new Intl.NumberFormat(lang, {
            style: 'currency',
            currency: `${type}`,
        });
        return formatter.format(value)
    }

    const sortParams = (sort) => {
        if (sort === "rn") {
            setRecoveryNumberOrder(!recoveryNumberOrder);
        }
        if (sort === "doi") {
            setDateOfIssue(!dateOfIssueOrder);
        }
        if (sort === "exp") {
            setExporterNameOrder(!exporterNameOrder);
        }
        if (sort === "dop") {
            setDateOfPayOrder(!dateOfPayOrder);
        }
        if (sort === "st") {
            setStatusOrder(!statusOrder);
        }
    }

    return (
        <>
            <div className={styles.contiene_tabla} style={{borderTop: "transparent"}}>
                <div className={stylesfiltro.filtro_total} style={{margin: 0, width: '100%'}}>

                </div>
                <div className={styles.contiene_tabla}>
                    <Table className={styles.tabla} borderless hover>
                        <thead>
                        <tr>
                            <th>{traduccion?.ordenCobro?.ordCobro}
                                <a onClick={() => sortParams("rn")}> {recoveryNumberOrder ? <FaSortUp/> :
                                    <FaSortDown/>}</a></th>
                            <th>{traduccion?.ordenCobro?.fechaEmision} <a
                                onClick={() => sortParams("doi")}> {dateOfIssueOrder ? <FaSortUp/> :
                                <FaSortDown/>}</a></th>
                            <th>{traduccion?.ordenCobro?.exportador}
                                <a onClick={() => sortParams("exp")}> {exporterNameOrder ? <FaSortUp/> :
                                    <FaSortDown/>}</a></th>
                            <th>{traduccion?.ordenCobro?.fechaPago} <a
                                onClick={() => sortParams("dop")}> {dateOfPayOrder ? <FaSortUp/> :
                                <FaSortDown/>}</a></th>
                            <th>{traduccion?.ordenCobro?.estado} <a onClick={() => sortParams("st")}> {statusOrder ?
                                <FaSortUp/> :
                                <FaSortDown/>}</a></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" style={{textAlign: "center"}}><Spinner animation="border" size="sm"/>
                                </td>
                            </tr>
                        ) : (
                            listaCobro?.length > 0 ?
                                listaCobro?.map((datos, idx) => {
                                    return (
                                        <tr key={idx} className={styles.tr_data}>

                                            <td>{datos.id}</td>
                                            <td>{datos.dateOfIssue}</td>
                                            {/*<td>{new Date(datos.ata).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>*/}
                                            <td>{datos.exporterName}</td>
                                            <td style={{width: '14ch', fontSize: '1rem'}}>
                                                <Form.Control type={"date"}
                                                              value={datos.dateOfPay ? datos.dateOfPay : ""}
                                                              onChange={(e) => handleFechaPago(e, idx)}>
                                                </Form.Control>
                                            </td>
                                            <td style={{width: '14ch', fontSize: '1rem'}}>
                                                <Form.Select value={datos.status}
                                                             onChange={(e) => e.target.value !== '---' ? handleEstado(e, idx) : null}>
                                                    <option>---</option>
                                                    <option value="2">{traduccion?.factura?.pendiente}</option>
                                                    <option value="4">{traduccion?.factura?.invoiced}</option>
                                                    <option value="1">{traduccion?.factura?.paid}</option>
                                                </Form.Select>
                                            </td>
                                            <td>
                                                <Button variant="light" onClick={() => abrirModal(datos)}>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <AiOutlineEye fill="blue"/>
                                                    </svg>
                                                </Button>
                                                <Button style={{marginLeft: '1rem'}} variant="light"
                                                        onClick={(e) => downloadPdf(datos.id)}>
                                                    <svg width="25" height="25" viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <MdPictureAsPdf fill="#3333CC" color={"blue"}/>
                                                    </svg>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }) :

                                <tr>
                                    <td colSpan="8" style={{textAlign: "center"}}>{traduccion?.factura?.noData}</td>
                                </tr>
                        )}
                        </tbody>

                    </Table>
                </div>
            </div>
            <Pager totalPages={totalPages} active={active} goToPage={goToPage}/>

            <Modal show={show} onHide={() => setShow(false)} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Listado de servicios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.contiene_tabla} style={{borderTop: "transparent", width: '100%'}}>

                        <Table className={styles.tabla} borderless hover>
                            <thead>
                            <tr>
                                <>
                                    <th>{traduccion?.clients?.name}</th>
                                    <th>Container</th>
                                    <th>Claim Date</th>
                                    <th>Inspection Date</th>
                                    <th>RSK's fee</th>
                                    <th>Surveyor's fee</th>
                                </>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                objModal?.claims?.length > 0 ?

                                    objModal?.claims.map((datos, idx) => {

                                        return (
                                            <tr key={idx} className={styles.tr_data}>
                                                <td>{datos.surveyorName}</td>
                                                <td>{datos.containerNumber}</td>
                                                <td>{new Date(datos.claimDate).toLocaleDateString('es-ES', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric'
                                                })}</td>
                                                <td style={{minWidth: '11ch'}}>{datos.inspectionDate}</td>
                                                <td>{datos.rskFee}</td>
                                                <td>{handleCurr(datos.surveyorFeeCurrency, datos.surveyorFee)}</td>
                                            </tr>
                                        )
                                    }) : (
                                        <tr>
                                            <td colSpan="8" style={{textAlign: "center"}}>{traduccion?.factura?.noData}</td>
                                        </tr>
                                    )}
                            </tbody>
                        </Table>
                        <Button variant="primary" onClick={() => setShow(false)}>
                            Close
                        </Button>

                    </div>
                </Modal.Body>

            </Modal>
            <Modal show={show2} onHide={() => setShow2(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{fontWeight: 'bolder'}}>{traduccion?.others?.deseaCambiarEstado}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{borderTop: "transparent", marginBottom: '2rem', minHeight: '4rem'}}>
                        <div style={{margin: '.5rem'}}><span
                            style={{fontWeight: 'bolder'}}>Order Number:</span> {objModal2.id}</div>
                        <div style={{margin: '.5rem'}}><span
                            style={{fontWeight: 'bolder'}}>Date of issue:</span> {objModal2.dateOfIssue}</div>
                        <div style={{margin: '.5rem'}}><span
                            style={{fontWeight: 'bolder'}}>Date of pay:</span> {objModal2.dateOfPay}</div>
                        <div style={{margin: '.5rem'}}><span style={{fontWeight: 'bolder'}}>Status Order:</span>
                            {
                                objModal2.id == 1 ? ` ${traduccion?.ordenCobro.paid}` : objModal2.id == 2 ? ` ${traduccion?.ordenCobro.pending}` : ` ${traduccion?.ordenCobro.invoiced}`
                            }
                        </div>
                        <div style={{fontWeight: 'bolder', margin: '1.5rem .5rem 1rem .5rem', fontSize: '1.2rem'}}>
                            {traduccion?.others?.rUsure} {objModal2.id} {traduccion?.others?.to}
                            {
                                objModal2.statusOrder == "1" ? ` ${traduccion?.ordenCobro.paid}` : objModal2.statusOrder == "2" ? ` ${traduccion?.ordenCobro.pending}` : ` ${traduccion?.ordenCobro.invoiced}`
                            }
                        </div>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        padding: '0 1rem 2rem 1rem',
                        color: 'red'
                    }}>{objModal2.statusOrder == "2" && `${traduccion?.others?.willDelete}`}</div>
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <Button variant="warning" onClick={() => setShow2(false)}>
                            {traduccion?.others?.close}
                        </Button>
                        <Button variant={objModal2.statusOrder !== "2" ? "primary" : "danger"} onClick={updateStatus}>
                            {traduccion?.others?.Accept}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    );
}
