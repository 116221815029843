import {Menu, MenuItem, ProSidebar, SubMenu} from "react-pro-sidebar";
import style from "./styleSidebar.module.css";
import {BsBook, BsBoxSeam, BsClipboardCheck, BsCoin, BsFileBarGraph, BsFileEarmarkText} from "react-icons/bs";
import {HiOutlineClipboardDocumentList} from "react-icons/hi2";
import {IoDocumentsOutline} from "react-icons/io5";
import {TfiDropbox} from "react-icons/tfi";
import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";
import {BiWorld} from "react-icons/bi";
import {RiBankFill, RiBillLine, RiFileDamageLine} from "react-icons/ri";
import {FiUsers} from "react-icons/fi";
import {RiSwapBoxLine} from "react-icons/ri";
import {MdOutlineFolderShared} from "react-icons/md";
import LangContext from "../../context/LangContext";


export const RskSidebar = (props) => {
    const {traduccion} = useContext(LangContext);

    //console.log(props.routeActive);
    //get info from token
    const token = localStorage.getItem("token");
    const tokenInfo = JSON.parse(atob(token.split(".")[1]));
    const {modules} = tokenInfo;
    const arrRutas = (modules || '').split(",");
    arrRutas.push("")
    const updateRuta = arrRutas.map((ruta) => "/" + ruta);
    updateRuta.push("/dashboard")
    // const allRutas = ["/", "/invoiced","/payment","/surveyors", "/companies", "/countries", "/exporters", "/fruits", "/currencies", "/billing", "/all_claims", "/damages", "/profiles", "/users","/all_stuffing"]

    const [rutas, setRutas] = useState(updateRuta);

    const listItems = [
        {
            onClick: () => {
                //props.setNewDamages(0);
                props.setRouteActive("/");
                props.setSubMenuA(false);

            },
            to: "/",
            text: "Dashboard",
            logo: () => (
                <BsFileBarGraph
                    style={{fontSize: "2rem", marginRight: "1.2rem"}}
                />
            ),
        },
        {
            onClick: () => {
                // props.setNewCurrency(0)
                props.setRouteActive("/all_claims");
                props.setSubMenuA(false);

            },
            to: "/all_claims",
            text: `${traduccion?.sidebar?.reclamos}`,
            logo: () => (
                <IoDocumentsOutline
                    style={{fontSize: "2rem", marginRight: "1.2rem"}}
                />
            ),
        },
        {
            onClick: () => {
                // props.setNewCurrency(0)
                props.setRouteActive("/all_stuffing");
            },
            to: "/all_stuffing",
            text: `${traduccion?.sidebar?.stuffing}`,
            state: {id: "stuffing"},
            logo: () => (
                <BsBoxSeam
                    style={{fontSize: "2rem", marginRight: "1.2rem"}}
                />
            ),
        },
        {
            onClick: () => {
                props.setNewExporter(0);
                props.setRouteActive("/exporters");
                props.setSubMenuA(false);

            },
            to: "/exporters",
            text: () => (
                <span style={{display: "inline-flex", flexDirection: "column"}}>
            {" "}
                    <span>{traduccion?.sidebar?.exportadores1} </span> <span> {traduccion?.sidebar?.exportadores2}</span>
          </span>
            ),
            logo: () => (
                <BsFileEarmarkText
                    style={{fontSize: "2rem", marginRight: "1.5rem"}}
                />
            ),
        },
        {
            onClick: () => {
                props.setNewSurveyor(0);
                props.setRouteActive("/surveyors");
                props.setSubMenuA(false);
            },
            to: "/surveyors",
            text: `${traduccion?.sidebar?.surveyors}`,
            logo: () => (
                <BsClipboardCheck
                    style={{fontSize: "2rem", marginRight: "1.5rem"}}
                />
            ),
        },
        {
            onClick: () => {
                props.setNewCompany(0);
                props.setRouteActive("/companies");
                props.setSubMenuA(false);
            },
            to: "/companies",
            text: () => (
                <span style={{display: "inline-flex", flexDirection: "column"}}>
            {" "}
                    <span>{traduccion?.sidebar?.company1}</span> <span>{traduccion?.sidebar?.company2}</span>
          </span>
            ),
            logo: () => (
                <HiOutlineClipboardDocumentList
                    style={{fontSize: "2rem", marginRight: "1.2rem"}}
                />
            ),
        },
        // {
        //   //hasSubMenu: true,
        //   // openSubMenu: true,
        //   //   to: "/invoiced/customers",
        //   keepActive: true,
        //   onClick: () => {
        //     props.setNewBilling(0);
        //     // props.setRouteActive("/mantenedor_facturas");
        //   },
        //   onClickA: () => {
        //     props.setSubMenuA(!props.subMenuA);
        //   },
        //   text: `${traduccion?.sidebar?.invoice}`,
        //   logo: () => (
        //       <RiBillLine style={{fontSize: "2rem", marginRight: "1.2rem"}}/>
        //
        //   ),
        // },
        // {
        //   customer: true,
        //   onClick: () => {
        //     props.setNewBilling(0);
        //     props.setRouteActive("/invoiced/customers");
        //   },
        //   to: "/invoiced/customers",
        //     text: `${traduccion?.sidebar?.clientes}`,
        //   logo: () => (
        //       <div style={{width:'1rem',margin:'0px .5rem 0px .8rem', display:'inline'}} />
        //
        //   ),
        // },
        // {
        //   customer: true,
        //   onClick: () => {
        //     props.setNewCobro(0);
        //     props.setIsPago(false);
        //     props.setRouteActive("/invoiced/recovery");
        //   },
        //   to: "/invoiced/recovery",
        //     text: `${traduccion?.sidebar?.recovery}`,
        //   logo: () => (
        //       <div style={{width:'1rem',margin:'0px .5rem 0px .8rem', display:'inline'}} />
        //   ),
        //
        // },
        // {
        //   surveyor: true,
        //   onClick: () => {
        //     props.setNewBillingSurveyor(0);
        //     props.setRouteActive("/invoiced/surveyor");
        //   },
        //   to: "/invoiced/surveyor",
        //     text: `${traduccion?.sidebar?.surveyorsPay}`,
        //   logo: () => (
        //       <div style={{width:'1rem',margin:'0px .5rem 0px .8rem', display:'inline'}} />
        //   ),
        //
        // },
        // {
        //     surveyor: true,
        //     onClick: () => {
        //         props.setNewPago(0);
        //         props.setIsPago(true);
        //         // props.setRouteActive("/invoiced/payment");
        //     },
        //     to: "/invoiced/payment",
        //     text: `${traduccion?.sidebar?.payOrder}`,
        //     logo: () => (
        //         <div style={{width: '1rem', margin: '0px .5rem 0px .8rem', display: 'inline'}}/>
        //     ),
        // },
        {
            hasSubMenu: true,
            openSubMenu: true,
            //   to: "/invoiced/customers",
            keepActive: true,
            onClick: () => {
                props.setNewBilling(0);
                // props.setRouteActive("/mantenedor_facturas");
            },
            onClickA: () => {
                props.setSubMenuA(() => {
                    if (props.setSubMenuA && !props.routeActive.includes("/invoiced")) {
                        return true
                    } else if (props.setSubMenuA && props.routeActive.includes("/invoiced")) {
                        return true
                    } else {
                        return false
                    }
                })

                // return props.setSubMenuA(!props.subMenuA);

            },
            text: `${traduccion?.sidebar?.invoice}`,
            logo: () => (
                <RiBillLine style={{fontSize: "2rem", marginRight: "1.2rem"}}/>

            ),

            subMenus: [{
                onClick: () => {
                    props.setNewBilling(0);
                    props.setRouteActive("/invoiced/customers");

                },

                to: "/invoiced/customers",
                text: `${traduccion?.sidebar?.clientes}`,
                logo: () => (
                    <div style={{width: '1rem', margin: '0px .5rem', display: 'inline'}}/>

                ),
            },
                {
                    onClick: () => {
                        props.setNewCobro(0);
                        props.setIsPago(false);

                        props.setRouteActive("/invoiced/recovery");
                    },
                    to: "/invoiced/recovery",
                    text: `${traduccion?.sidebar?.recovery}`,
                    logo: () => (
                        <div style={{width: '1rem', margin: '0px .5rem', display: 'inline'}}/>
                    ),

                },
                {
                    onClick: () => {
                        props.setNewBillingSurveyor(0);
                        props.setRouteActive("/invoiced/surveyor");
                    },
                    to: "/invoiced/surveyor",
                    text: `${traduccion?.sidebar?.surveyorsPay}`,
                    logo: () => (
                        <div style={{width: '1rem', margin: '0px .5rem', display: 'inline'}}/>
                    ),

                },
                {
                    onClick: () => {
                        props.setNewPago(0);
                        props.setIsPago(true);
                        props.setRouteActive("/invoiced/payment");
                    },
                    to: "/invoiced/payment",
                    text: `${traduccion?.sidebar?.payOrder}`,
                    logo: () => (
                        <div style={{width: '1rem', margin: '0px .5rem', display: 'inline'}}/>
                    ),
                }
            ]
        },
        {
            onClick: () => {
                props.setNewDamages(0);
                props.setRouteActive("/damages");
                props.setSubMenuA(false);

            },
            to: "/damages",
            text: `${traduccion?.sidebar?.danios}`,
            logo: () => (
                <RiFileDamageLine
                    style={{fontSize: "2rem", marginRight: "1.2rem"}}
                />
            ),
        },
        {
            onClick: () => {
                props.setNewSpecie(0);
                props.setRouteActive("/fruits");
                props.setSubMenuA(false);

            },
            to: "/fruits",
            text: () => (
                <span style={{display: "inline-flex", flexDirection: "column"}}>
            {" "}
                    <span>{traduccion?.sidebar?.specie1} </span> <span> {traduccion?.sidebar?.specie2}</span>
          </span>
            ),
            logo: () => (
                <TfiDropbox style={{fontSize: "2rem", marginRight: "1.2rem"}}/>
            ),
        },
        {
            onClick: () => {
                props.setNewCurrency(0);
                props.setRouteActive("/currencies");
                props.setSubMenuA(false);

            },
            to: "/currencies",
            text: `${traduccion?.sidebar?.currency}`,
            logo: () => (
                <BsCoin style={{fontSize: "2rem", marginRight: "1.2rem"}}/>
            ),
        },
        {
            onClick: () => {
                props.setNewUser(0)
                props.setRouteActive("/users");
                props.setSubMenuA(false);

            },
            to: "/users",
            text: `${traduccion?.sidebar?.RSKUsers}`,
            logo: () => (
                <FiUsers
                    style={{fontSize: "2rem", marginRight: "1.2rem"}}
                />
            ),
        },
        {
            onClick: () => {
                props.setNewProfiles(0);
                props.setRouteActive("/profiles");
                props.setSubMenuA(false);

            },
            to: "/profiles",
            text: `${traduccion?.sidebar?.perfiles}`,
            logo: () => (
                <MdOutlineFolderShared
                    style={{fontSize: "2rem", marginRight: "1.2rem"}}
                />
            ),
        },
        // {
        //   onClick: () => {
        //     props.setNewPrivileges(0);
        //     props.setRouteActive("/privileges");
        //   },
        //   to: "/privileges",
        //   text: "Privileges",
        //   logo: () => (
        //       <RiSwapBoxLine
        //           style={{ fontSize: "2rem", marginRight: "1.2rem" }}
        //       />
        //   ),
        // },
        {
            onClick: () => {
                props.setNewCountryRate(0);
                props.setRouteActive("/countries");
                props.setSubMenuA(false);

            },
            to: "/countries",
            text: `${traduccion?.sidebar?.paises}`,
            logo: () => (
                <BiWorld style={{fontSize: "2rem", marginRight: "1.2rem"}}/>
            ),
        },


    ];

    const menuItem = (item, index) => (
        <Link key={index} onClick={item.onClick} to={item.to} state={item.state}>

            <MenuItem key={index} onClick={item.hasSubMenu ? item.onClickA : null}
                      className={
                          //Condiciones para verse activo
                          props.routeActive === item.to ?
                              style.custom_menu_item_active2 :

                              (item.keepActive && props.routeActive.includes("/invoiced")) ?
                                  item.hasSubMenu ?
                                      style.custom_menu_item_active3 :
                                      style.custom_menu_item_active :
                                  style.custom_menu_item
                      }
            >
                {item.logo()}
                <span className={style.texto_menu}>
            {typeof item.text === "function" ? item.text() : item.text}
          </span>
                {props.routeActive === item.to && (
                    <div style={{display: "inline"}}>
                        <svg
                            className="float-end"
                            style={{marginTop: "5px"}}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.1717 12L8.22168 7.04999L9.63568 5.63599L15.9997 12L9.63568 18.364L8.22168 16.95L13.1717 12Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                )}
                {item.hasSubMenu &&
                    <SubMenu open={props.subMenuA}>
                        {
                            item.subMenus.map((subMenu, index) => (
                                <Link key={index} onClick={subMenu.onClick} to={subMenu.to}>
                                    <MenuItem key={index}
                                        className={
                                            props.routeActive === subMenu.to ?
                                                style.custom_submenu_item_active2
                                                : props.routeActive.includes("/invoiced") ?
                                                    style.new_custom_sub_invoiced
                                                    : style.new_custom_sub
                                        }
                                    >
                                        {subMenu.logo()}
                                        <span className={style.texto_menu}>
            {typeof subMenu.text === "function" ? subMenu.text() : subMenu.text}
          </span>
                                        {props.routeActive === subMenu.to && (
                                            <div style={{display: "inline"}}>
                                                <svg
                                                    className="float-end"
                                                    style={{marginTop: "5px"}}
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M13.1717 12L8.22168 7.04999L9.63568 5.63599L15.9997 12L9.63568 18.364L8.22168 16.95L13.1717 12Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                            </div>
                                        )}

                                    </MenuItem>
                                </Link>
                            ))
                        }
                    </SubMenu>
                }
            </MenuItem>

        </Link>
    );

    return (
        <>
            <div style={{height: "100%"}}>
                <ProSidebar
                    className={style.custom_sidebar}
                    collapsed={props.collapse}
                >
                    {/*<Menu className={style.custom_menu} >*/}
                    {/*    {listItems.map((item) => (*/}
                    {/*        <>{menuItem(item)}</>*/}
                    {/*    ))}*/}
                    {/*</Menu>*/}


                    <Menu className={style.custom_menu}>
                        {listItems.map((item, index) => {
                            if (rutas.includes(item.to)) {
                                return <>{menuItem(item, index)}</>
                            } else if (rutas.includes('/invoiced')) {
                                if (item.to === undefined || item.to === '/invoiced/payment' || item.to === '/invoiced/surveyor' || item.to === '/invoiced/customers' || item.to === '/invoiced/recovery') {
                                    return <>{menuItem(item, index)}</>
                                } else {
                                    return null
                                }
                            }
                        })}
                    </Menu>
                </ProSidebar>
            </div>
        </>
    );
}
