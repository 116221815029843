import React, {useContext, useEffect, useRef, useState} from "react";
import LangContext from "../../../context/LangContext";
import {useNavigate} from "react-router";
import axios from "axios";
import {toast} from "react-toastify";
import {Button, Form, Pagination, Spinner, Table} from "react-bootstrap";
import {urlConfig} from "../../../config/urlConfig";
import {startClaim} from "../../../config/initial";
import styles from "../billing/billingtabla.module.css";
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {AiOutlineEye} from "react-icons/ai";
import Pager from "../orden_cobro/Pager";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import {BsArrowCounterclockwise} from "react-icons/bs";

export const TablaBillingSurveyor = ({searchUrl}) => {

    const {traduccion} = useContext(LangContext);
    const navigate = useNavigate();

    const initialReferenceNumberOrder = useRef(true);
    const initialNameOrder = useRef(true);
    const initialCountryOrder = useRef(true);
    const initialContainerNumberOrder = useRef(true);
    const initialFeeOrder = useRef(true);
    const initialStatusOrder = useRef(true);

    const isSearchUrl = useRef(false);

    const [referenceNumberOrder, setReferenceNumberOrder] = useState(true);
    const [nameOrder, setNameOrder] = useState(true);
    const [countryOrder, setCountryOrder] = useState(true);
    const [containerNumberOrder, setContainerNumberOrder] = useState(true);
    const [feeOrder, setFeeOrder] = useState(true);
    const [statusOrder, setStatusOrder] = useState(true);

    const [listaBilling, setListaBilling] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const [perPage, setPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [active, setActive] = useState(0);

    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/surveyors/search?page=0&size=${perPage}`);

    const [loading, setLoading] = useState(true);
    const [loadingPdf, setLoadingPdf] = useState(false);

    const token = localStorage.getItem("token");


    useEffect(() => {
        if (initialReferenceNumberOrder.current) {
            initialReferenceNumberOrder.current = false;
            return;
        }
        if (!referenceNumberOrder) {
            if (!url.includes("sort=rn,desc") && url.includes("sort=rn,asc")) {
                setUrl(url.replace("&sort=rn,asc", "&sort=rn,desc"));
            } else {
                setUrl(url + "&sort=rn,desc");
            }
        } else {
            if (!url.includes("sort=rn,asc") && url.includes("sort=rn,desc")) {
                setUrl(url.replace("&sort=rn,desc", "&sort=rn,asc"))
            } else {
                setUrl(url + "&sort=rn,asc");
            }
        }
    }, [referenceNumberOrder]);

    useEffect(() => {
        if (initialNameOrder.current) {
            initialNameOrder.current = false;
            return;
        }
        if (!nameOrder) {
            if (!url.includes("sort=na,desc") && url.includes("sort=na,asc")) {
                setUrl(url.replace("&sort=na,asc", "&sort=na,desc"));
            } else {
                setUrl(url + "&sort=na,desc");
            }
        } else {
            if (!url.includes("sort=na,asc") && url.includes("sort=na,desc")) {
                setUrl(url.replace("&sort=na,desc", "&sort=na,asc"))
            } else {
                setUrl(url + "&sort=na,asc");
            }
        }
    }, [nameOrder]);

    useEffect(() => {
        if (initialCountryOrder.current) {
            initialCountryOrder.current = false;
            return;
        }
        if (!countryOrder) {
            if (!url.includes("sort=co,desc") && url.includes("sort=co,asc")) {
                setUrl(url.replace("&sort=co,asc", "&sort=co,desc"));
            } else {
                setUrl(url + "&sort=co,desc");
            }
        } else {
            if (!url.includes("sort=co,asc") && url.includes("sort=co,desc")) {
                setUrl(url.replace("&sort=co,desc", "&sort=co,asc"))
            } else {
                setUrl(url + "&sort=co,asc");
            }
        }
    }, [countryOrder]);

    useEffect(() => {
        if (initialContainerNumberOrder.current) {
            initialContainerNumberOrder.current = false;
            return;
        }
        if (!containerNumberOrder) {
            if (!url.includes("sort=cn,desc") && url.includes("sort=cn,asc")) {
                setUrl(url.replace("&sort=cn,asc", "&sort=cn,desc"));
            } else {
                setUrl(url + "&sort=cn,desc");
            }
        } else {
            if (!url.includes("sort=cn,asc") && url.includes("sort=cn,desc")) {
                setUrl(url.replace("&sort=cn,desc", "&sort=cn,asc"))
            } else {
                setUrl(url + "&sort=cn,asc");
            }
        }
    }, [containerNumberOrder]);

    useEffect(() => {
        if (initialFeeOrder.current) {
            initialFeeOrder.current = false;
            return;
        }
        if (!feeOrder) {
            if (!url.includes("sort=rf,desc") && url.includes("sort=rf,asc")) {
                setUrl(url.replace("&sort=rf,asc", "&sort=rf,desc"));
            } else {
                setUrl(url + "&sort=rf,desc");
            }
        } else {
            if (!url.includes("sort=rf,asc") && url.includes("sort=rf,desc")) {
                setUrl(url.replace("&sort=rf,desc", "&sort=rf,asc"))
            } else {
                setUrl(url + "&sort=rf,asc");
            }
        }
    }, [feeOrder]);

    useEffect(() => {
        if (initialStatusOrder.current) {
            initialStatusOrder.current = false;
            return;
        }
        if (!statusOrder) {
            if (!url.includes("sort=st,desc") && url.includes("sort=st,asc")) {
                setUrl(url.replace("&sort=st,asc", "&sort=st,desc"));
            } else {
                setUrl(url + "&sort=st,desc");
            }
        } else {
            if (!url.includes("sort=st,asc") && url.includes("sort=st,desc")) {
                setUrl(url.replace("&sort=st,desc", "&sort=st,asc"))
            } else {
                setUrl(url + "&sort=st,asc");
            }
        }
    }, [statusOrder]);

    const searchClaims = () => {
        setLoading(true);
        let searchUrlSize = searchUrl.toString().includes("size=") ? searchUrl : searchUrl + `&size=${perPage}`;
        return axios.get(searchUrlSize, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            setListaBilling(res.data.claims)
            setTotalPages(res.data.totalPages - 1)
            isSearchUrl.current = true;
        }).catch((error) => {
            console.log({error})
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        }).finally(() => {
            setLoading(false);
        });
    }


    useEffect(() => {
        if (searchUrl === null) return;
        searchClaims();
    }, [searchUrl])


    let rol = ""
    if (token !== null) {
        const payload = JSON.parse(atob(token.split(".")[1]));
        rol = payload.rol;
    }
    const searchSortBillingOrder = (url) => {
        setLoading(true);
        if (token) {
            if (rol.includes("RSK")) {
                axios.get(url, {
                    headers: {
                        Authorization: token?.toString().replace("Bearer ", "")
                    }
                }).then((res) => {
                    setListaBilling(res.data.claims)
                    setTotalPages(res.data.totalPages - 1)
                    sortRefTo(false);
                }).catch((error) => {
                    console.log(error);
                    toast.error(`${traduccion?.errores?.errorSorting}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }).finally(() => {
                    setLoading(false);
                });
            }
        }
    }

    useEffect(() => {
        searchSortBillingOrder(url)
    }, [url]);

    const goToPage = (page) => {
        if(isSearchUrl.current){
            let paginatedUrl = searchUrl.toString().includes("page=") ? searchUrl.toString().replace(/page=\d+/, `page=${page}`) : searchUrl + `&page=${page}`
            paginatedUrl = paginatedUrl.includes("size=") ? paginatedUrl : paginatedUrl + `&size=${perPage}`;
            setUrl(paginatedUrl)
            setActive(page)
        }else{
            let paginatedUrl = url.replace(/page=\d+/, `page=${page}`);
            setUrl(paginatedUrl)
            setActive(page)
        }
    }

    const handlePerPage = (e) => {
        let page = e.target.value;
        setPerPage(page)
        setUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/surveyors/search?page=0&size=${page}`);
    }

    const editClaim = (id) => {

        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${id}`, {
            headers: {
                Authorization: token.toString().replace("Bearer ", "")
            }
        }).then((res) => {
            //console.log("EDITANDO RECLAMO...", res.data)
            let newData = {...startClaim, ...res.data}
            //console.log("NEW DATA", newData)
            if (newData.comment === null) {
                newData.comment = ""
            }
            if (newData.claimDate === null) {
                newData.claimDate = ""
            }
            if (newData.lossTypeId === null) {
                newData.lossTypeId = 1
            }

            if (newData.lossCalculation.length === 0) {
                //console.log("NO HAY CALCULO DE PERDIDAS")
                newData.lossCalculation = startClaim.lossCalculation
            }
            //console.log("NEW DATA", newData)
            //console.log("NEW DATA LOSS CALCULATION", newData.lossCalculation)
            // setOneClaimAll(newData)
            navigate('/informe_inspeccion_all', {
                state: {
                    id: res.data.id,
                    oneClaimAll: newData,
                    imRsk: true,
                    lossCalculation: newData.lossCalculation,
                    goToInspector: true
                }
            })
        }).catch((error) => {
            //console.log(error);
        })
    }

    const handleCheck = (id) => {
        setSelectedIds([...selectedIds, id]);
    }

    const handleCurr = (type, value) => {
        if (value === null || value === undefined || type === null || type === undefined) {
            return "---"
        }
        const lang = localStorage.getItem("lang");
        const formatter = new Intl.NumberFormat(lang, {
            style: 'currency', currency: `${type}`,
        });
        return formatter.format(value)
    }

    const sortParams = (sort) => {
        if (sort === "na") {
            setNameOrder(!nameOrder);
        }
        if (sort === "co") {
            setCountryOrder(!countryOrder);
        }
        if (sort === "cn") {
            setContainerNumberOrder(!containerNumberOrder);
        }
        if (sort === "rn") {
            setReferenceNumberOrder(!referenceNumberOrder);
        }
        if (sort === "rf") {
            setFeeOrder(!feeOrder);
        }
        if (sort === "st") {
            setStatusOrder(!statusOrder);
        }
    }

    const sortRefTo = (bool) => {
        initialReferenceNumberOrder.current = bool;
        initialContainerNumberOrder.current = bool;
        initialCountryOrder.current = bool;
        initialFeeOrder.current = bool;
        initialNameOrder.current = bool;
        initialStatusOrder.current = bool;
    }
    const resetRefAndSortStates = () => {
        initialReferenceNumberOrder.current = true;
        initialContainerNumberOrder.current = true;
        initialCountryOrder.current = true;
        initialFeeOrder.current = true;
        initialNameOrder.current = true;
        initialStatusOrder.current = true;
        setNameOrder(true);
        setCountryOrder(true);
        setContainerNumberOrder(true);
        setReferenceNumberOrder(true);
        setFeeOrder(true);
        setStatusOrder(true);
        setUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/surveyors/search?page=0&size=${20}`);
    }

    const generateBillingOrder = () => {
        if (selectedIds.length === 0) {
            toast.warning(`${traduccion?.warning?.noClaimsSelected}`)
            return;
        }
        axios.post(`${urlConfig.local_url}${urlConfig.portClaim}/api/v1/order/pay`, {claimList:selectedIds}, {
            headers: {
                'Authorization': localStorage.getItem("token").replace("Bearer ", ""),
            }
        }).then(res => {
            downloadPdf(res.data.id)
            toast.success(`${traduccion?.success?.newclaim}`)
            searchSortBillingOrder(url);
        }).catch(err => {
            console.log({err})
            toast.error(`${err.response.data.message}`)
        })
    }

    const downloadPdf = (id) => {
        setLoadingPdf(true);
        let token = localStorage.getItem("token");
        const headers = {
            responseType: 'arraybuffer',
            headers: {
                'Content-Disposition': 'attachment', Authorization: token
            }
        }
        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/payment/${id}`, {}, headers).then(res => {
            const url = URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Pay order -${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            toast.success(`${traduccion?.success?.downPdf}`)
        }).catch(err => {
            console.log({err})
            toast.error(`${err.response.data.message}`)
        }).finally(() => {
            setLoadingPdf(false);
        })
    }

    return (<>
        <div className={styles.contiene_tabla} style={{borderTop: "transparent"}}>
            <div className={stylesfiltro.filtro_total} style={{margin: 0, width: '100%'}}>
                <div>
                    <div className={stylesfiltro.opciones_busqueda}>
                        <Form>
                            <div className={styles.mostrar}>
                                <p>{traduccion.claimTable?.mostrar}</p>
                                <Form.Select onChange={handlePerPage} size="sm" id="show">
                                    <option value="4">4</option>
                                    <option value="8">8</option>
                                    <option value="20" selected={true}>20</option>
                                </Form.Select>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div className={styles.contiene_tabla}>
                <Table className={styles.tabla} borderless hover>
                    <thead>
                    <tr>
                        <th></th>
                        <th>{traduccion?.clients?.name} <a
                            onClick={() => sortParams("na")}> {nameOrder ? <FaSortUp/> : <FaSortDown/>}</a></th>
                        <th>{traduccion?.clients?.country} <a
                            onClick={() => sortParams("co")}> {countryOrder ? <FaSortUp/> : <FaSortDown/>}</a></th>
                        <th>{traduccion?.ordenCobro?.container} <a
                            onClick={() => sortParams("cn")}> {containerNumberOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.ordenCobro?.nroOrden} <a
                            onClick={() => sortParams("rn")}> {referenceNumberOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.ordenCobro?.tarifa} <a
                            onClick={() => sortParams("rf")}> {feeOrder ? <FaSortUp/> : <FaSortDown/>}</a></th>
                        <th>{traduccion?.ordenCobro?.estado} <a
                            onClick={() => sortParams("st")}> {statusOrder ? <FaSortUp/> : <FaSortDown/>}</a></th>
                        <th><a onClick={() => resetRefAndSortStates()}><BsArrowCounterclockwise/></a></th>
                    </tr>
                    </thead>
                    <tbody>
                    {loading ? (<tr>
                        <td colSpan="8" style={{textAlign: "center"}}><Spinner animation="border"
                                                                               size="sm"/>
                        </td>
                    </tr>) : (listaBilling?.length > 0 ? listaBilling?.map((datos, idx) => {
                        return (<tr key={idx} className={styles.tr_data}>
                            <td style={{
                                textAlign: "center", display: 'flex', gap: '3px'
                            }}>
                                <input type="checkbox"
                                       disabled={datos.status !== "Pending"}
                                       id={datos.id} name="selectedClaim" value={datos.id}
                                       onClick={(e) => {
                                           handleCheck(datos.id)
                                       }}
                                       className={styles.same_height}/>
                            </td>
                            <td>{datos.surveyorName}</td>
                            <td>{datos.country}</td>
                            <td>{datos.containerNumber}</td>
                            <td>{datos.referenceNumber || "--"}</td>
                            <td>{handleCurr(datos.feeCurrency, datos.fee)}</td>
                            <td>{datos.status}</td>
                            <td>
                                <Button variant="light" onClick={() => editClaim(datos.id)}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <AiOutlineEye fill="blue"/>
                                    </svg>
                                </Button>
                            </td>
                        </tr>)
                    }) : <tr>
                        <td colSpan="8" style={{textAlign: "center"}}>{traduccion?.factura?.noData}</td>
                    </tr>)}
                    </tbody>
                </Table>
            </div>
        </div>
        <Pager totalPages={totalPages} active={active} goToPage={goToPage}/>

        <div className="w-100 div-btn">
            <div style={{margin: '2rem'}}>
                <Button variant="primary btn-secun mb-4"
                        onClick={generateBillingOrder}
                >{loadingPdf ? <Spinner animation="border" role="status">
                </Spinner> : <span>{traduccion?.ordenCobro?.generar}</span>}
                </Button>
            </div>
        </div>
    </>);
}
