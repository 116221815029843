import style from './detail_account.module.css'
import {Fragment, useContext, useEffect, useState} from "react";
import AccountContext from "../../../context/DBContext";
import LangContext from "../../../context/LangContext";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import styles from "../modal_reclamo/modal.module.css";
import {urlConfig} from "../../../config/urlConfig";
import axios from "axios";

export const DetailAccount = () => {
    const {session} = useContext(AccountContext);
    const {traduccion} = useContext(LangContext);
    const [fecha, setFecha] = useState("Sin fecha");
    const [showModal,setShowModal] = useState(false)
    const [afiliados,setAfiliados] = useState([])

    // //console.log(session)

    ////console.log(session)
    // const fechaStartFinish = async ()=>{
    //     await session.endPeriod;
    //     if (session.endPeriod && session.startPeriod )
    //     setFecha(<Fragment>{session.endPeriod.slice(0,10)} - <span className={style.avoidwrap}>{session.endPeriod.slice(0,10)}</span></Fragment>)
    // }
    // //        setFecha(`${session.endPeriod.slice(0,10)} - <span className={style.avoidwrap}> ${session.endPeriod}</span>`)
    // useEffect(() => {
    //     fechaStartFinish()
    // }, [setFecha]);

    const showModalAff = ()=>{
        let token = localStorage.getItem("token").replace("Bearer ","")
        let headers = {headers: {Authorization: token}}
        axios.get(urlConfig.allUrl+`${urlConfig.portClaim}/api/v1/exporter/company_contract`,headers).then((res)=>{
            //console.log(res.data[0])
            setAfiliados(res.data[0].affiliatedCompanies)
            setShowModal(showModal=>!showModal)
        }).catch((err)=>{
            //console.log(err)
        })


    }
    return (
        <>
            <div className={style.tarjeta}>
                <div className={style.photo}>
                    <img src={session?.logo} alt="logo" />
                    <p style={{textAlign:'center'}}>{session?.companyName}</p>
                </div>
                <div className={style.detail}>
                        <p className={style.titulo}>{traduccion.detailAccount?.titulo}</p>
                    <div className={style.detail_up}>
                        <div>
                            <p className={style.detail_up_title}>{traduccion.detailAccount?.contract}</p>
                            <p>{session?.contractName}</p>
                        </div>
                        <div>
                            <p className={style.detail_up_title}>{traduccion.detailAccount?.valido}</p>
                            <p>{session.endPeriod?.slice(0,10)} - <span className={style.avoidwrap}>{session.endPeriod?.slice(0,10)}</span></p>
                        </div>
                        <div>
                            <p className={style.detail_up_title}>{traduccion.detailAccount?.porContenedor}</p>
                            <p>(USD$) {session?.containerFee}</p>
                        </div>
                        {/*<div>*/}
                        {/*    <p className={style.detail_up_title}>{traduccion.detailAccount?.season}</p>*/}
                        {/*    <p>{session?.season}</p>*/}
                        {/*</div>*/}
                    </div>
                    <div className={style.detail_down}>
                        <Button variant={"outline-primary"} onClick={showModalAff}>{traduccion.detailAccount?.afiliados}</Button>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={()=>setShowModal(false)}
                // size={width > 768 ? "md" : "sm"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Lista de afiliados
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>

                        <Row>
                        {afiliados.length>0 && afiliados.map((item,index)=>{
                            //console.log(item)
                            return(
                                    <Col xs={6} key={index}>
                                        <p>- {item.companyName}</p>
                                    </Col>
                            )
                        })}
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}
