import {Col, Form, Row} from "react-bootstrap";
import styles from "./inspector.module.css";
import React, {useContext} from "react";
import stylesInput from "../documentos/documentos.module.css";
import {FiDownload} from "react-icons/fi";
import axios from "axios";
import {urlConfig} from "../../../../config/urlConfig";
import LangContext from "../../../../context/LangContext";

export const InspectorTabNoMap = ({claimForm,setClaimForm,setArrFile,arrFile, filesClaim, handleDocuments}) => {

    const {traduccion} = useContext(LangContext)

    const downloadFile = (bytes, fileName) => {
        const blob = new Blob ([bytes], {type:"application/pdf"})   ;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click()
    }

    const descargaUno = (filename) => {
        axios({
            url: `${urlConfig.allUrl}:9004/api/v1/files/download?file=${filename}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        }).then((response) => {
            downloadFile(response.data, filename.split("_")[0])
        }).catch((error) => {
            //console.log(error);
        });
    }

    console.log({claimForm})

    return (
        <>
            <Row>

                <Col>
                    <div className={styles.documentos}>
                        <div className={styles.documentos_title}>{traduccion?.inspector?.informeDeInspeccion}</div>
                        {filesClaim.survey && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.survey}</div>}
                        <Form.Group id="formFile1" style={{display:'flex'}}>
                            <Form.Control name={"survey"} className={filesClaim.survey ? stylesInput.file_input2:stylesInput.file_input } onChange={handleDocuments} type="file" />
                            {/^SURVEY_/.test(filesClaim.survey) && <div className={stylesInput.button_des} onClick={()=>descargaUno(filesClaim.survey)}><FiDownload/></div>}
                        </Form.Group>
                        <div className="mb-3">{traduccion?.documentos?.soloArchivos}</div>
                    </div>
                </Col>
                <Col>
                    <div className={styles.documentos}>
                        <div className={styles.documentos_title}>{traduccion?.inspector?.informeFotografico}</div>
                        {filesClaim.photograph && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.photograph}</div>}
                        <Form.Group id="formFile2" style={{display:'flex'}} >
                            <Form.Control name="photograph" className={filesClaim.photograph ? stylesInput.file_input2:stylesInput.file_input } onChange={handleDocuments} type="file" />
                            {/^PHOTOGRAPH_/.test(filesClaim.photograph) && <div className={stylesInput.button_des} onClick={()=>descargaUno(filesClaim.photograph)}><FiDownload/></div>}

                        </Form.Group>
                        <div className="mb-3">{traduccion?.documentos?.soloArchivos}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={styles.documentos}>
                        <div className={styles.documentos_title}>{traduccion?.inspector?.notaDeProtesta}</div>
                        {filesClaim.notice && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.notice}</div>}

                        <Form.Group id="formFile3" style={{display:'flex'}}>
                            <Form.Control name="notice"  className={filesClaim.notice ? stylesInput.file_input2:stylesInput.file_input } onChange={handleDocuments} type="file" />
                            {/^NOTICE_/.test(filesClaim.notice) && <div className={stylesInput.button_des} onClick={()=>descargaUno(filesClaim.notice)}><FiDownload/></div>}

                        </Form.Group>
                        <div className="mb-3">{traduccion?.documentos?.soloArchivos}</div>
                    </div>
                </Col>
                <Col>
                    <div className={styles.documentos}>
                        <div className={styles.documentos_title}>{traduccion?.inspector?.certificadoDeDestruccion}</div>
                        {filesClaim.destruction && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.destruction}</div>}

                        <Form.Group id="formFile4" style={{display:'flex'}}>
                            <Form.Control name="destruction"  className={filesClaim.destruction ? stylesInput.file_input2:stylesInput.file_input } onChange={handleDocuments} type="file" />
                            {/^DESTRUCTION_/.test(filesClaim.destruction) && <div className={stylesInput.button_des} onClick={()=>descargaUno(filesClaim.destruction)}><FiDownload/></div>}

                        </Form.Group>
                        <div className="mb-3">{traduccion?.documentos?.soloArchivos}</div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
