import {Transporte} from "../components/informe_reclamo/transporte/Transporte";
import {useContext, useEffect, useState} from "react";
import styles from "./claim.module.css"
import {RefNumbersAndStatus} from "../components/informe_reclamo/RefNumbersAndStatus";
import {InformeReclamoTabs} from "../components/informe_reclamo/informe_reclamo_tabs/InformeReclamoTabs";
import AccountContext from "../context/DBContext";
import {useLocation} from "react-router-dom";
import {Button} from "react-bootstrap";
import {urlConfig} from "../config/urlConfig";
import axios from "axios";

export const Claim = ({stuff, setStuff, oneClaim,setRouteActive}) => {

    //ONECLAIM VIENE SE CARGA EN TABLA.JS USANDO EL OBJETO BASE
    //DE AQUI EL INITIALCLAIM SE LLAMA CLAIMFORM
    const [errors, setErrors] = useState({});
    const [arrFile, setArrFile] = useState([]);
    const [isOn, setIsOn] = useState(true);
    const [transporte, setTransporte] = useState(1);
    const [blDate, setBlDate] = useState("Unknown");
    const location = useLocation();
    const {session} = useContext(AccountContext);
    const [claimForm, setClaimForm] = useState( location?.state?.oneClaimAll || location?.state?.initialStuffing || oneClaim);
    const [yearRsk, setYearRsk] = useState(claimForm?.createdTime?.slice(0, 4) || new Date().getFullYear() );
    const [goToInspector, setGoToInspector] = useState(location?.state?.goToInspector || false);
    const [exportersName, setExportersName] = useState(location?.state?.oneExporter || []);
    const [forFinalChanges, setForFinalChanges] = useState({eta: "", etd: "", gateOut: "", ata: "", damages:"", stuffingDate:""});
    let imRsk = location.state?.imRsk
    let editRsk = location.state?.editRsk
    let editExporter = location.state?.editExporter
    //console.log(claimForm)
    // //console.log(claimForm)
    // useEffect(()=>{
    //     if(location?.state?.oneClaimAll){
    //         setClaimForm(location?.state?.oneClaimAll)
    //     }
    //
    // },[location])
    //console.log({claimForm})
    useEffect(() => {
        let mixClaimForm = claimForm;
        //console.log('DESDE LA VISTA CLAIM:    _____   : ', claimForm)
        if (location.state?.lossCalculation !== undefined) {
            //console.log(location.state?.lossCalculation)
            mixClaimForm.lossCalculation = location.state?.lossCalculation;
        }
        if(location.state?.id){
            mixClaimForm.id = location.state?.id;
        }
        if(location.state?.oneExporter?.id){
            //console.log(location.state?.oneExporter)
            mixClaimForm.exporterName = location.state?.oneExporter?.companyName;
            mixClaimForm.companyName = location.state?.oneExporter?.companyName;
            mixClaimForm.companyId = location.state?.oneExporter?.id;
        }

        if (location.state?.initialStuffing !== undefined) {
            setClaimForm(location.state?.initialStuffing);
        }else{
            setClaimForm(mixClaimForm);
        }
        if(location?.state?.oneClaimAll){
            setClaimForm(location?.state?.oneClaimAll)
        }

    }, [])

    let final = location?.state?.oneClaimAll || location?.state?.initialStuffing;
    //console.log(final)
    //console.log(claimForm)
    useEffect(() => {
        if (final?.lastTimelineId === 4 || final?.lastTimelineId === 19){
            let arr = final.cargo.damages;
            //console.log(arr)

            let fechaHarvest, fechaPacking;
            if (claimForm.cargo?.fruits[0]?.fruits?.harvesting.length > 0) {
                fechaHarvest = claimForm.cargo?.fruits[0].fruits.harvesting[0].harvestDate.split("T")[0]
            }else{
                fechaHarvest = ""
            }
            if (claimForm.cargo?.fruits[0]?.fruits?.packaging.length > 0) {
                fechaPacking = claimForm.cargo?.fruits[0].fruits.packaging[0].packageDate.split("T")[0]
            }else{
                fechaPacking = ""
            }

                setForFinalChanges(
                { eta: claimForm.cargo.eta === null ? "" : claimForm.cargo.eta,
                    etd: claimForm.cargo.etd === null ? "" : claimForm.cargo.etd,
                    gateOut: claimForm.gateOutDate === null ? "" : claimForm.gateOutDate,
                    ata: claimForm.ata === null ? "" : claimForm.ata,
                    damages:arr,
                    stuffingDate:claimForm.stuffingDate === null ? "" : claimForm.stuffingDate,
                    fechaHarvest: fechaHarvest,
                    fechaPacking: fechaPacking
                }
            )
        }
    }, [final])
    // ACA YA SE TIENE TODA LA INFORMACION DE STUFFING,
    // PQ NO LLENAR INMEDIATAMENTE CON LOS CAMPOS EN EL OBJETO INICIAL DE CLAIM Y MAS ADELANTE SOLO SE MODIFICAN EN ONCHANGE
    //console.log(yearRsk)
    //console.log(location.state?.initialStuffing)
    //console.log(claimForm)


    //console.log(oneClaim)
    let refNumber = `RSK${yearRsk}/${location.state?.id||claimForm?.id}`;

    if (claimForm?.documents?.shipping?.length === 0) {
        claimForm.documents.shippingDocuments = [];
    }

    // setClaimForm(location.state.oneClaimAll);
    // //console.log(location.state)
    // if (location.state.oneClaimAll !== undefined){
    //     setClaimForm(location.state.oneClaimAll)
    //     //console.log(claimForm)
    // }
    let initialClaim = {};

    //VALIDACION INNECESARIA PQ SIEMPRE EXISTE EL ID EN EL OBJETO
    if (oneClaim?.id !== undefined) {
        /*
            initialClaim = {...oneClaim};
            initialClaim.surveyorRefNumber = refNumber;
            initialClaim.companyName = session.companyName
            initialClaim.currencyId = 1;
            initialClaim.transportId = 1;
            initialClaim.cargo = {};
            initialClaim.cargo.cargoId = 1;
            initialClaim.distance = 0;
            initialClaim.inspectorStatus = 0;
            initialClaim.packingTimeline = 0;
            initialClaim.reclaimedAmount = 0;
            initialClaim.scheduleDate = "";
            initialClaim.status = 1;
            initialClaim.claimDate = 2021 - 10 - 10;
            initialClaim.comment = "";
            initialClaim.commentDate = "";
            initialClaim.timeLine = 10;
         */

    } else {
        //ACÁ NUNCA LLEGA PORQUE EL OBJETO SIEMPRE VA A TENER EL ID
        /*
            initialClaim = startClaim;
            initialClaim.surveyorRefNumber = refNumber;
            initialClaim.companyName = session.companyName
            initialClaim.id = location.state.id;
         */
    }
    //console.log({claimForm})
    // //console.log(oneClaim)
    //console.log({session})
    //save a sessionstorage with the claim
    //sessionStorage.setItem("claim", JSON.stringify(initialClaim));

    //SE TRAE LO DEL STUFFING Y SE LLENA EL CLAIMFORM


    //agregar elemento a claimForm
    /*
    useEffect(() => {
        setClaimForm({
            ...claimForm,
            companyName: session.companyName
        })
    }, [session]);

     */

    //console.log({claimForm})
    if (claimForm === null) {
        setRouteActive("/");
    }
    //console.log(errors)

    const handleChangeExporter = (e) => {
        let direccionUrl;
        if (!imRsk)  {
            direccionUrl = `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/company_contract`
        }else{
            direccionUrl = `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/company_contract/${claimForm.id}`
        }

        axios.get(direccionUrl, {
            headers: {
                Authorization: `${localStorage.getItem("token").replace("Bearer ", "")}`,
            }
        }).then((res) => {
            let respuesta;
            if (imRsk) {
                respuesta =res.data
            }else{
                respuesta = res.data[0]
            }
            setExportersName(respuesta)
            //console.log("EXPORTER NAME-----------------------------" + respuesta.companyName)
            //console.log(respuesta)
            //console.log(e === "start", claimForm.companyName === "", claimForm.exporterName === "")

            if (e === "start" && ((claimForm.companyName === "" && claimForm.exporterName === "") || (claimForm.companyName === null && claimForm.exporterName === null))) {
                //console.log("ENTRO EN EL IF")
                setClaimForm({
                    ...claimForm,
                    exporterName: respuesta.companyName,
                    companyName: respuesta.companyName,
                    companyId: respuesta.id
                })
            }

        }).catch((error) => {
            console.log(error);
        })
    }
    //console.log(claimForm)

    useEffect(() => {
        handleChangeExporter("start")
    }, [])
    return (
        <>
            <div className={styles.claim_all}>
                <RefNumbersAndStatus refNumber={refNumber} claimForm={claimForm} setClaimForm={setClaimForm} imRsk={imRsk}/>
                <div className={styles.transporte}>
                    <Transporte
                        // stuff={stuff} setStuff={setStuff}
                                isOn={isOn} setIsOn={setIsOn}
                                claimForm={claimForm} setClaimForm={setClaimForm}
                                errors={errors} setErrors={setErrors}
                                transporte={transporte} setTransporte={setTransporte}
                                imRsk={imRsk}
                                editRsk={editRsk}
                                exportersName={exportersName} setExportersName={setExportersName}
                                handleChangeExporter={handleChangeExporter}
                    />
                </div>
                <InformeReclamoTabs editRsk={editRsk} editExporter={editExporter} claimForm={claimForm} setClaimForm={setClaimForm}
                                   // stuff={stuff} setStuff={setStuff}
                                    yearRsk={yearRsk}
                                    arrFile={arrFile} setArrFile={setArrFile}
                                    errors={errors} setErrors={setErrors}
                                    transporte={transporte} imRsk={imRsk} goToInspector={goToInspector} setGoToInspector={setGoToInspector} forFinalChanges={forFinalChanges}/>
            </div>
        </>
    )
}
