import {Button, Col, Container, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import styles from "./new_surveyor.module.css";
import {Mapa} from "../../informe_reclamo/informe_reclamo_tabs/inspector/map/Mapa";
import imagen from "./abstract-user.png";
//import {Link} from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import {MdOutlineAddLocationAlt} from "react-icons/md";
import axios from "axios";
import countries from "countries-list";
import 'react-phone-number-input/style.css'
//import PhoneInput from 'react-phone-number-input'
import {FlagIcon} from "react-flag-kit";
import {Typeahead} from "react-bootstrap-typeahead";
import LangContext from "../../../context/LangContext";

import {useForm} from "../../../hooks/useForm";
import {FaFileDownload} from "react-icons/fa";
import {ToastContainer, toast} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import {urlConfig} from "../../../config/urlConfig";
import DBContext from "../../../context/DBContext";

import NewSurveyorCompany from "./NewSurveyorCompany";
import {onlyNumbers} from "../../../config/functions";

export const NewSurveyor = ({title, setNewSurveyor, paginaSurveyors, editSurveyor, oneSurveyor}) => {
    const {traduccion, handleLanguage, initialLang} = useContext(LangContext);
    const {company, currency} = useContext(DBContext);

    const lang = localStorage.getItem("lang");

    const [showModal, setShowModal] = useState(false);
    const [direccion, setDireccion] = useState("");
    const [addressForm, setAddressForm] = useState({})
    const [countryCode, setCountryCode] = useState("")
    const [files, setFiles] = useState(new FormData());
    const [arrFile, setArrFile] = useState([]);
    const {height, width} = useWindowSize();
    const [toolArr, setToolArr] = useState([])
    const [tool, setTool] = useState([])
    const [surveyorData, setSurveyorData] = useState(new FormData());
    // const [valueP, setValueP] = useState()
    const [showInputCodeType, setShowInputCodeType] = useState([false, false])
    const [mapCode, setMapCode] = useState("")
    const [range, setRange] = useState(0);
    const [typeCode, setTypeCode] = useState(0)
    const [typeCodeB, setTypeCodeB] = useState(0)
    const [countryRate, setCountryRate] = useState({});

    const [toggleBank, setToggleBank] = useState(false);
    const [european1, setEuropean1] = useState(false);
    const [european2, setEuropean2] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [isPassport, setIsPassport] = useState(false);
    const [isAddress, setIsAddress] = useState(false);
    const [profileImage, setProfileImage] = useState(imagen);
    const [imageFile, setImageFile] = useState(null);
    const [errores, setErrores] = useState({});
    const [survVerif, setSurvVerif] = useState({});
    const [loading, setLoading] = useState(false);
    const [sendForm, setSendForm] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [fromCompany, setFromCompany] = useState(false);
    const [surveyor, setSurveyor] = useState({
        "id": "",
        "hasCompany": false,
        "company_id": null,
        "area_id": 0,
        "firstName": "",
        "lastName": "",
        "surveyorName": "",
        "address": "",
        "personalId": "",
        "mainAddress": "",
        "photo": "",
        "passportDocument": false,
        "degreeCertificate": false,
        "addressCertificate": false,
        "curriculum": false,
        "profession": "",
        "contract": "",
        "rating": 0,
        "price": 0,
        "latitude": 0,
        "longitude": 0,
        "operationRange": 0,
        "responseTime": 0,
        "phone": "",
        "email": "",
        "country": "",
        "username": "",
        "tools": [],
        "status_id": 0,
        "country_id": null,
        "bankInformation": [{
            "accountNumber": "",
            "bankName": "",
            "bankAddress": "",
            "swiftCode": "",
            "ibanCode": "",
            "bankHolderName": "",
            "bankHolderAddress": "",
            "bicCode": "",
            "currency": 0,
            "area_id": 0
        }],

    });
    const [isValid, setIsValid] = useState();

    const handleTypeCode = (e) => {
        if (typeCode === 0) {
            surveyor.bankInformation[0].bicCode = ""
            surveyor.bankInformation[0].swiftCode = ""
        }
        if (typeCode === 1) {
            surveyor.bankInformation[0].swiftCode = ""
        }
        if (typeCode === 2) {
            surveyor.bankInformation[0].bicCode = ""
        }
        let value = parseInt(e.target.value)
        setTypeCode(value)
        handleChange(e)
    }
    const handleTypeCodeB = (e) => {
        if (typeCodeB === 0) {
            surveyor.bankInformation[1].bicCode = ""
            surveyor.bankInformation[1].swiftCode = ""
        }
        if (typeCodeB === 1) {
            surveyor.bankInformation[1].swiftCode = ""
        }
        if (typeCodeB === 2) {
            surveyor.bankInformation[1].bicCode = ""
        }
        let value = parseInt(e.target.value)
        setTypeCodeB(value)
        handleChange(e)

    }

    const getCountryById = (idCountry) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        if (typeof idCountry === "number") {
            axios.get(urlConfig.allUrl + `${urlConfig.portClaim}/api/v1/country/${idCountry}`, header).then(res => {
                setCountryRate(res.data)
            }).catch(err => {
                setSurveyor({...surveyor, country_id: null})
                if (err.response.data.message.search("not found") !== -1) {
                    setErrores({...errores, countryNOT: "countryNotFound"})
                    setDireccion("")
                    toast.warning(`${traduccion?.warning?.noCountryWithCode}` + `${idCountry}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
        } else {
            //console.log("el country debe ser un id")
        }
    }

    useEffect(() => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        if (typeof mapCode === "string" && mapCode.length === 2) {
            axios.get(urlConfig.allUrl + `${urlConfig.portClaim}/api/v1/country/code/${mapCode}`, header).then(res => {
                setCountryRate(res.data)
                setIsValid(true)
            }).catch(err => {
                setSurveyor({...surveyor, country_id: null, mainAddress: "", latitude: 0, longitude: 0})
                if (err.response.data.message.search("not found") !== -1) {
                    toast.warning(`${traduccion?.warning?.noCountryWithCode}` + `${mapCode}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
        }
    }, [mapCode])

    //console.log({mapCode}, {isValid})

    useEffect(() => {
        // //console.log(showInputCodeType)
        if (oneSurveyor.bankInformation?.length > 0) {
            if (oneSurveyor.bankInformation.length === 1) {
                setShowInputCodeType([true, false])
                if (oneSurveyor.bankInformation[0].ibanCode) {
                    setEuropean1(true);
                }
            }
            if (oneSurveyor.bankInformation.length > 1) {
                setToggleBank(true);
                setShowInputCodeType([true, true])
                // //console.log("true x2")
                if (oneSurveyor.bankInformation[0].ibanCode) {
                    setEuropean1(true);
                }
                if (oneSurveyor.bankInformation[1].ibanCode) {
                    setEuropean2(true);
                }
            }


        } else {
            setShowInputCodeType([false, false])
        }

    }, []);
    // //console.log(showInputCodeType)

    const filesServer = {
        "passportDocument": oneSurveyor.passportDocument || false,
        "degreeCertificate": oneSurveyor.degreeCertificate || false,
        "addressCertificate": oneSurveyor.addressCertificate || false,
        "curriculum": oneSurveyor.curriculum || false,
    }

    let newSurveyorExample = {
        "surveyorName": "Angelo Torres",
        "address": "London Av 355",
        "personalId": "10299877-5",
        "mainAddress": "London Av 355",
        "photo": "https://us.123rf.com/450wm/mimagephotography/mimagephotography1812/mimagephotography181200205/113230400-primer-plano-retrato-de-hombre-latino-guapo-contra-el-fondo-blanco-aislado.jpg?ver=6",
        "passportDocument": "E-11223344556677",
        "degreeCertificate": "Certificate Agronomist",
        "addressCertificate": "London",
        "profession": "Agronomist",
        "contract": "Surveyor",
        "rating": 1,
        "price": 500,
        "latitude": 40.067167505130016,
        "longitude": -74.94468991509572,
        "operationRange": 120000,
        "responseTime": 3,
        "phone": "12233444",
        "email": "at@gmail.com",
        "country": "Chile",
        "name": "Angela Torres",
        "username": "at@gmail.com",
        "bankInformation": [{
            "bankAddress": "London 121, office 50",
            "bankName": "Bank of London",
            "accountName": "Angela Torres",
            "accountNumber": "33885577",
            "swiftCode": 12,
            "ibanCode": 4015
        }, {
            "bankAddress": "Santiago 111, office 70",
            "bankName": "Scotiabank",
            "accountName": "Angela Torres",
            "accountNumber": "11223344",
            "swiftCode": 1,
            "ibanCode": 1010
        }]
    }

    //if not lang token create one
    if (lang === null) {
        localStorage.setItem("lang", initialLang());
    }

    const validationsForm = (form, targetName) => {
        //console.log("validations")

        form = survVerif;
        let errors = {};
        //regex tamaño maximo 50 caracteres, letras mayusculas y minusculas
        let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]{2,50}$/;
        let regexAddress = /^.{5,250}$/;


        //regex email
        let regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/;


        let regexPhone = /^[0-9+]{7,18}$/;
        let regexCantidadID = /^.{5,20}$/;
        // regex biccode/SwiftCode
        let regexSwiftCode = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
        //regex numero positivo mayor a 0
        let regexRate = /^[1-9]\d*$/;
        //regex ibanCode
        let regexIbanCode = /^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/;


        const mensajeObligatorioEs = n => `El campo ${n} es obligatorio`;
        const mensajeObligatorioEn = n => `The ${n} field is required`;

        ////console.log("targetName",targetName)
        if (!form.firstName?.trim()) {
            errors.firstName = lang === 'es' ? mensajeObligatorioEs('nombre') : mensajeObligatorioEn('First name');
        } else if (!regexName.test(form.firstName)) {
            errors.firstName = lang === 'es' ? 'El nombre no es válido (Deben ser letras)' : 'The first name is not valid (Must be letters only)';
        }

        if (!form.lastName?.trim()) {
            errors.lastName = lang === 'es' ? mensajeObligatorioEs('apellido') : mensajeObligatorioEn('Last name');
        } else if (!regexName.test(form.lastName)) {
            errors.lastName = lang === 'es' ? 'El apellido no es válido (Deben ser letras)' : 'The last name is not valid (Must be letters only)';
        }


        if (countryCode == "") {
            errors.country = lang === 'es' ? 'El campo país debe ser válido' : 'The country must be valid';
        }

        if (!form.profession?.trim()) {
            errors.profession = lang === 'es' ? mensajeObligatorioEs('profesión') : mensajeObligatorioEn('profession');
        } else if (!regexName.test(form.profession)) {
            errors.profession = lang === 'es' ? 'La profesión no es válida (Deben ser letras)' : 'The profession is not valid (Must be letters only)';
        }
        // //console.log(form)
        if (!regexPhone.test(form.phone?.trim())) {
            errors.phone = lang === 'es' ? "El campo teléfono es incorrecto (debe ser entre 6 y 15 números)" : 'The field phone is incorrect (must be between 6 and 15 numbers)';

        }

        if (surveyor.mainAddress == "") {
            errors.mainAddress = lang === 'es' ? "El campo direccion de operación es requerido" : 'The field operation address is required';
        }
        if ((surveyor.operationRange == "") || (surveyor.operationRange == 0)) {
            errors.operationRange = lang === 'es' ? "El rango de operación debe ser válido" : 'The operation range must be valid';
        }

        if (!form.email?.trim()) {
            errors.email = lang === 'es' ? "El campo email es requerido" : "The email field is required";
        } else if (!regexEmail.test(form.email.trim())) {
            errors.email = lang === 'es' ? "El campo email debe ser válido" : "The email field must be valid";
        }

        if (!form.personalId?.trim()) {
            errors.personalId = lang === 'es' ? "El campo personalId es requerido" : "The personalId field is required";
        } else if (!regexCantidadID.test(form.personalId.trim())) {
            errors.personalId = lang === 'es' ? "El campo personalId es incorrecto (debe ser entre 5 y 20 caracteres)" : 'The field personalId is incorrect (must be between 5 and 20 characters)';
        }


        if (!form.photo) {
            if (isImage === false) {
                errors.imageFile = lang === 'es' ? "La imagen de perfil es requerida" : "The profile image is required";
            }

        }
        if (surveyor.status_id === 0) {
            errors.status_id = lang === 'es' ? "El campo status es requerido" : "The status field is required";
        }
        if (surveyor.hasCompany === false) {
            if (surveyor.passportDocument === false) {
                errors.passportFile = lang === 'es' ? "La archivo de pasaporte es requerido" : "The file is required";
            }

            if (surveyor.addressCertificate === false) {
                errors.addressFile = lang === 'es' ? "La archivo es requerido" : "The file is required";
            }


            if (surveyor.bankInformation[0]?.currency === 0) {
                errors.currency = lang === 'es' ? "El campo moneda es requerido" : "The currency field is required";
            }

            if (surveyor.country_id === null) {
                errors.countryId = lang === 'es' ? `${countryByCode(mapCode)} no esta disponible para operar` : `${countryByCode(mapCode)} is not available to operate`;
            }
            //BankHolder Name
            // for (let i = 0; i < surveyor.bankInformation.length; i++) {
            if (!surveyor.bankInformation[0]) {
                errors.bankInformation = lang === 'es' ? "El campo información bancaria es requerido" : "The bank information field is required";
            }

            if (surveyor.bankInformation[0]) {

                if (european1) {
                    if (!surveyor.bankInformation[0]?.ibanCode?.trim()) {
                        errors.iban = lang === 'es' ? "El campo iban code es requerido" : "The iban code field is required";
                    } else if (!regexIbanCode.test(surveyor.bankInformation[0].ibanCode)) {
                        errors.iban = lang === 'es' ? 'El campo iban code debe ser válido' : 'El campo iban code debe ser válido';
                    }
                }
                if (!surveyor?.bankInformation[0]?.bankHolderName) {
                    errors.accountName = lang === 'es' ? mensajeObligatorioEs('nombre de la cuenta') : mensajeObligatorioEn('account name');
                } else if (!surveyor.bankInformation[0]?.bankHolderName.trim()) {
                    errors.accountName = lang === 'es' ? mensajeObligatorioEs('nombre de la cuenta') : mensajeObligatorioEn('account name');
                }

                //BankHolder Address
                if (!surveyor.bankInformation[0]?.bankHolderAddress) {
                    errors.accountAddress = lang === 'es' ? mensajeObligatorioEs('dirección de la cuenta') : mensajeObligatorioEn('account address');
                } else if (!surveyor.bankInformation[0].bankHolderAddress.trim()) {
                    errors.accountAddress = lang === 'es' ? mensajeObligatorioEs('dirección de la cuenta') : mensajeObligatorioEn('account address');
                }

                //Bank Name
                if (!surveyor.bankInformation[0]?.bankName) {
                    errors.bankName = lang === 'es' ? mensajeObligatorioEs('nombre del banco') : mensajeObligatorioEn('bank name');
                } else if (!surveyor.bankInformation[0].bankName.trim()) {
                    errors.bankName = lang === 'es' ? mensajeObligatorioEs('nombre del banco') : mensajeObligatorioEn('bank name');
                }

                if (surveyor.bankInformation[0].area_id === 0) {
                    errors.area_id = lang === 'es' ? "El campo ubicacion es requerido" : "The region account field is required";
                }

                //Bank Address
                if (!surveyor.bankInformation[0]?.bankAddress) {
                    errors.bankAddress = lang === 'es' ? mensajeObligatorioEs('dirección del banco') : mensajeObligatorioEn('bank address');
                } else if (!surveyor.bankInformation[0].bankAddress.trim()) {
                    errors.bankAddress = lang === 'es' ? mensajeObligatorioEs('dirección del banco') : mensajeObligatorioEn('bank address');
                }

                //Bank Account Number
                if (!surveyor.bankInformation[0]?.accountNumber) {
                    errors.accountNumber = lang === 'es' ? mensajeObligatorioEs('número de cuenta') : mensajeObligatorioEn('account number');
                } else if (!surveyor.bankInformation[0].accountNumber.trim()) {
                    errors.accountNumber = lang === 'es' ? mensajeObligatorioEs('número de cuenta') : mensajeObligatorioEn('account number');
                }

                //swift code sin bicCode
                if (typeCode === 0) {
                    surveyor.bankInformation[0].bicCode = '';
                    surveyor.bankInformation[0].swiftCode = '';

                    errors.codes = lang === 'es' ? mensajeObligatorioEs('código swift o bic') : mensajeObligatorioEn('swift code or bic');
                } else if (typeCode === 1) {
                    surveyor.bankInformation[0].swiftCode = '';
                    if (surveyor.bankInformation[0]?.bicCode === "") {
                        errors.codes = lang === 'es' ? mensajeObligatorioEs('código bic') : mensajeObligatorioEn('bic code');
                    } else if (!regexSwiftCode.test(surveyor.bankInformation[0]?.bicCode.trim())) {
                        errors.codes = lang === 'es' ? 'El código bic no es válido (letras deben ser mayúsculas)' : 'The bic code is not valid (letters must be uppercase)';
                    }
                } else if (typeCode === 2) {
                    surveyor.bankInformation[0].bicCode = '';
                    if (surveyor.bankInformation[0]?.swiftCode === "") {
                        errors.codes = lang === 'es' ? mensajeObligatorioEs('código swift') : mensajeObligatorioEn('swift code');
                    } else if (!regexSwiftCode.test(surveyor.bankInformation[0]?.swiftCode.trim())) {
                        errors.codes = lang === 'es' ? 'El código swift no es válido (letras deben ser mayúsculas)' : 'The swift code is not valid (letters must be uppercase)';
                    }
                }

                if (surveyor.bankInformation.length > 1) {
                    if (european2) {
                        if (!surveyor.bankInformation[1].ibanCode?.trim()) {
                            errors.iban1 = lang === 'es' ? "El campo iban code es requerido" : "The iban code field is required";
                        } else if (!regexIbanCode.test(surveyor.bankInformation[1].ibanCode)) {
                            errors.iban1 = lang === 'es' ? 'El campo iban code debe ser válido' : 'El campo iban code debe ser válido';
                        }
                    }
                    if (!surveyor?.bankInformation[1]?.bankHolderName) {
                        errors.accountName1 = lang === 'es' ? mensajeObligatorioEs('nombre de la cuenta') : mensajeObligatorioEn('account name');
                    } else if (!surveyor.bankInformation[1]?.bankHolderName.trim()) {
                        errors.accountName1 = lang === 'es' ? mensajeObligatorioEs('nombre de la cuenta') : mensajeObligatorioEn('account name');
                    }

                    //BankHolder Address
                    if (!surveyor.bankInformation[1]?.bankHolderAddress) {
                        errors.accountAddress1 = lang === 'es' ? mensajeObligatorioEs('dirección de la cuenta') : mensajeObligatorioEn('account address');
                    } else if (!surveyor.bankInformation[1].bankHolderAddress.trim()) {
                        errors.accountAddress1 = lang === 'es' ? mensajeObligatorioEs('dirección de la cuenta') : mensajeObligatorioEn('account address');
                    }

                    //Bank Name
                    if (!surveyor.bankInformation[1]?.bankName) {
                        errors.bankName1 = lang === 'es' ? mensajeObligatorioEs('nombre del banco') : mensajeObligatorioEn('bank name');
                    } else if (!surveyor.bankInformation[1].bankName.trim()) {
                        errors.bankName1 = lang === 'es' ? mensajeObligatorioEs('nombre del banco') : mensajeObligatorioEn('bank name');
                    }

                    if (surveyor.bankInformation[1].area_id === 0) {
                        errors.area_id1 = lang === 'es' ? "El campo ubicacion es requerido" : "The region account field is required";
                    }

                    //Bank Address
                    if (!surveyor.bankInformation[1]?.bankAddress) {
                        errors.bankAddress1 = lang === 'es' ? mensajeObligatorioEs('dirección del banco') : mensajeObligatorioEn('bank address');
                    } else if (!surveyor.bankInformation[1].bankAddress.trim()) {
                        errors.bankAddress1 = lang === 'es' ? mensajeObligatorioEs('dirección del banco') : mensajeObligatorioEn('bank address');
                    }

                    //Bank Account Number
                    if (!surveyor.bankInformation[1]?.accountNumber) {
                        errors.accountNumber1 = lang === 'es' ? mensajeObligatorioEs('número de cuenta') : mensajeObligatorioEn('account number');
                    } else if (!surveyor.bankInformation[1].accountNumber.trim()) {
                        errors.accountNumber1 = lang === 'es' ? mensajeObligatorioEs('número de cuenta') : mensajeObligatorioEn('account number');
                    }


                    if (typeCodeB === 0) {
                        surveyor.bankInformation[1].bicCode = '';
                        surveyor.bankInformation[1].swiftCode = '';
                        errors.codesB = lang === 'es' ? mensajeObligatorioEs('código swift o bic') : mensajeObligatorioEn('swift code or bic');
                    } else if (typeCodeB === 1) {
                        if (surveyor.bankInformation[1]?.bicCode === "") {
                            surveyor.bankInformation[1].swiftCode = '';
                            errors.codesB = lang === 'es' ? mensajeObligatorioEs('código bic') : mensajeObligatorioEn('bic code');
                        } else if (!regexSwiftCode.test(surveyor.bankInformation[1]?.bicCode.trim())) {
                            errors.codesB = lang === 'es' ? 'El código bic no es válido (letras deben ser mayúsculas)' : 'The bic code is not valid (letters must be uppercase)';
                        }
                    } else if (typeCodeB === 2) {
                        if (surveyor.bankInformation[1]?.swiftCode === "") {
                            surveyor.bankInformation[1].bicCode = '';
                            errors.codesB = lang === 'es' ? mensajeObligatorioEs('código swift') : mensajeObligatorioEn('swift code');
                        } else if (!regexSwiftCode.test(surveyor.bankInformation[1]?.swiftCode.trim())) {
                            errors.codesB = lang === 'es' ? 'El código swift no es válido (letras deben ser mayúsculas)' : 'The swift code is not valid (letters must be uppercase)';
                        }
                    }
                }
            }
            //     if (surveyor.bankInformation[0].swiftCode === "" && surveyor.bankInformation[0].bicCode === "") {
            //         errors.codes = lang === 'es' ? mensajeObligatorioEs('código swift o bic') : mensajeObligatorioEn('swift code or bic');
            //     } else if (surveyor.bankInformation[0].swiftCode !== "") {
            //         // //console.log("swiftCode-0")
            //         if (!regexSwiftCode.test(surveyor.bankInformation[0].swiftCode.trim())) {
            //             errors.codes = lang === 'es' ? 'El código swift no es válido (letras deben ser mayúsculas)' : 'The swift code is not valid (letters must be uppercase)';
            //         }
            //     } else if (surveyor.bankInformation[0].bicCode !== "") {
            //         // //console.log("bicCode-0")
            //
            //         if (!regexSwiftCode.test(surveyor.bankInformation[0].bicCode.trim())) {
            //             errors.codes = lang === 'es' ? 'El código bic no es válido (letras deben ser mayúsculas)' : 'The bic code is not valid (letters must be uppercase)';
            //         }
            //     }
            // } else {
            //     errors.codes = lang === 'es' ? mensajeObligatorioEs('código swift o bic') : mensajeObligatorioEn('swift code or bic');
            // }
            // if (surveyor.bankInformation?.length > 0) {
            //     if (surveyor.bankInformation[0].swiftCode === null && surveyor.bankInformation[0].bicCode === null) {
            //         errors.codes = lang === 'es' ? mensajeObligatorioEs('código swift o bic') : mensajeObligatorioEn('swift code or bic');
            //     } else if (surveyor.bankInformation[0].swiftCode !== null) {
            //         //console.log("swiftCode-0")
            //         if (!regexSwiftCode.test(surveyor.bankInformation[0].swiftCode.trim())) {
            //             errors.codes = lang === 'es' ? 'El código swift no es válido (letras deben ser mayúsculas)' : 'The swift code is not valid (letters must be uppercase)';
            //         }
            //     } else if (surveyor.bankInformation[0].bicCode !== null) {
            //         //console.log("bicCode-0")
            //
            //         if (!regexSwiftCode.test(surveyor.bankInformation[0].bicCode.trim())) {
            //             errors.codes = lang === 'es' ? 'El código bic no es válido (letras deben ser mayúsculas)' : 'The bic code is not valid (letters must be uppercase)';
            //         }
            //     }
            // } else {
            //     errors.codes = lang === 'es' ? mensajeObligatorioEs('código swift o bic') : mensajeObligatorioEn('swift code or bic');
            // }
        }
        // }
        // if(editSurveyor){
        //
        //     if (surveyor.basicRatePerHour === null) {
        //         errors.basicRatePerHour = lang === 'es' ? mensajeObligatorioEs('tarifa por hora') : mensajeObligatorioEn('rate per hour');
        //     } else if (surveyor.basicRatePerHour !== null) {
        //         if (!regexRate.test(surveyor.basicRatePerHour)) {
        //             errors.basicRatePerHour = lang === 'es' ? 'La tarifa por hora debe ser un número positivo' : 'The rate per hour must be a positive number';
        //         }
        //
        //     }
        //     if (surveyor.ratePerKm === null) {
        //         errors.ratePerKm = lang === 'es' ? mensajeObligatorioEs('tarifa por km') : mensajeObligatorioEn('rate per km');
        //     }
        //     else if (surveyor.ratePerKm !== null) {
        //         if (!regexRate.test(surveyor.ratePerKm)) {
        //             errors.ratePerKm = lang === 'es' ? 'La tarifa por km debe ser un número positivo' : 'The rate per km must be a positive number';
        //         }
        //     }
        //     if(surveyor.ratePerAddHour === null){
        //         errors.ratePerAddHour = lang === 'es' ? mensajeObligatorioEs('tarifa por hora adicional') : mensajeObligatorioEn('rate per additional hour');
        //     }
        //     else if(surveyor.ratePerAddHour !== null){
        //         if (!regexRate.test(surveyor.ratePerAddHour)) {
        //             errors.ratePerAddHour = lang === 'es' ? 'La tarifa por hora adicional debe ser un número positivo' : 'The rate per additional hour must be a positive number';
        //         }
        //     }
        // }


        setErrores(errors);
        return errors;
    };

    const {handleChangeForm, handleBlurForm, handleSubmitForm, errors} = useForm(surveyor, validationsForm)

    // useForm(surveyor)


    const scrollToTargetAdjusted = (target) => {
        let element = document.getElementById(target);
        let offset = 50;
        const bodyRect = document.body.getBoundingClientRect().top;
        if (element) {
            let elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({
                top: offsetPosition, behavior: "smooth"
            });
            setTimeout(() => {
                setShowMessage(true)
            }, 1000);
        } else {
            window.scrollTo({
                top: 0, behavior: "smooth"
            });
        }
    }

    //Reference to the input phone
    const refPhone = useRef("");
    const refPhoneNum = useRef("");


    //handle change surveyor
    const handleChange = (e) => {
        //console.log("handle change")
        //console.log("e.target.name", e.target.value)

        handleChangeForm(e);


        if (e.target?.files) {
            let file = e.target.files[0];
            //console.log(file);
            // let fileUpload = {'file':e.target.files[0]}
            let formData = new FormData();

            if ((file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "application/pdf") && file.size < 20000000) {
                formData.append('file', file)
                formData.append('data', JSON.stringify(newSurveyorExample))
                //console.log(formData.entries())
                // formData.append('data', JSON.stringify(surveyor))
                setSurveyorData(formData)

                setSurveyor({
                    ...surveyor, [e.target.name]: formData
                })
            }

        } else if (e.target?.name === "country") {
            //reference to the input phone
            if (e.target?.value === "") {
                setSurveyor({
                    ...surveyor, phone: "",
                })
            }
            setSurveyor({
                ...surveyor, country: countries.countries[e.target.value].name,
            })

            setCountryCode(e.target.value)
        } else if (e.target?.name === "phone") {
            /*
            setSurveyor({
                ...surveyor, phone: `+${countries.countries[countryCode].phone}${refPhone.current.children[1].value}`,
            })*/
        } else if (e.target?.name === "firstName") {
            setSurveyor({
                ...surveyor, surveyorName: e.target.value, firstName: e.target.value,
            })
        } else if (e.target?.name === "email") {
            setSurveyor({
                ...surveyor, email: e.target.value, username: e.target.value,
            })
        } else if (e.target?.name === "status_id") {
            setSurveyor({
                ...surveyor, status_id: +e.target.value,
            })
        } else {
            setSurveyor({
                ...surveyor, [e.target.name]: e.target.value
            })
        }
        // //console.log("surveyorData", surveyorData)
        handleBlurForm(e)
    }
    const handleBankInformation = (e) => {
        handleChangeForm(e);
        let index = e.target.name.split("-")[1];
        let nameKey = e.target.name.split("-")[0];
        let value = e.target.value;

        if (surveyor.bankInformation === undefined || surveyor.bankInformation.length == 0) {

            let bankInfo = {
                "bankAddress": "",
                "bankName": "",
                "bankHolderName": "",
                "accountNumber": "",
                "ibanCode": "",
                "bankHolderAddress": "",
                "swiftCode": "",
                "bicCode": "",
                "currency": 0,
                "area_id": 0,
            };

            let bankInit = [];
            bankInit.push({...bankInfo, [nameKey]: value})
            setSurveyor({
                ...surveyor, bankInformation: bankInit
            })
        } else if (surveyor.bankInformation.length == 1 && index == 1) {
            let obj = {}
            obj[nameKey] = value
            surveyor.bankInformation.push(obj)
            setSurveyor({
                ...surveyor, bankInformation: surveyor.bankInformation
            })
        } else {
            //console.log(nameKey)
            if (nameKey === "area_id") {
                handleBankArea(e)
                surveyor.bankInformation[index][nameKey] = +value
                if (index == 0) {
                    surveyor.area_id = +value
                }
            } else {
                surveyor.bankInformation[index][nameKey] = value

            }
            setSurveyor({
                ...surveyor, bankInformation: surveyor.bankInformation
            })
        }
        //console.log({bankInformation: surveyor.bankInformation})
    }
    const handleToggleBank = () => {
        if (toggleBank && surveyor.bankInformation?.length > 1) {
            validationsForm(survVerif)
            surveyor.bankInformation.pop();
            setSurveyor({
                ...surveyor, bankInformation: surveyor.bankInformation
            })
        } else {
            let bankInfo = {
                "bankAddress": "",
                "bankName": "",
                "bankHolderName": "",
                "accountNumber": "",
                "ibanCode": "",
                "bankHolderAddress": "",
                "swiftCode": "",
                "bicCode": "",
                "currency": 0,
                "area_id": 0,
            };
            surveyor.bankInformation.push(bankInfo)
            setSurveyor({
                ...surveyor, bankInformation: surveyor.bankInformation
            })
        }
        setToggleBank(!toggleBank)
    }

    //list of countries
    const countryCodes = Object.keys(countries.countries);

    //update phone after change country
    useEffect(() => {
        // //console.log(refPhone.current)
        if (countryCode !== "") {

            setSurveyor({
                ...surveyor, phone: `+${countries.countries[countryCode].phone}${refPhoneNum.current.value}`,
            })
        }
    }, [countryCode, refPhoneNum.current.value])

    const handleChange2 = (e) => {
        if (e.target?.files) {
            let file = e.target.files[0];
            // let fileUpload = {'file':e.target.files[0]}
            let formData = new FormData();
            if ((file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "application/pdf") && file.size < 20000000) {
                formData.append('file', file)
                setSurveyor({
                    ...surveyor, [e.target.name]: formData
                })
            }
        } else {
            setSurveyor({
                ...surveyor, [e.target.name]: e.target.value
            })
        }
        //console.log("surveyor", surveyor)
    }

    useEffect(() => {
        setSurveyor({
            ...surveyor, mainAddress: direccion
        })
    }, [direccion]);
    useEffect(() => {
        setSurveyor({
            ...surveyor, latitude: addressForm.lat, longitude: addressForm.lng
        })

    }, [addressForm]);

    const handleBankArea = (e) => {
        //console.log(e.target.value)
        let index = e.target.name.split("-")[1];
        //console.log(e.target.value)
        //console.log(index)
        if (e.target.value == "3") {
            if (index == "0") {
                //console.log("is true europa1")
                setEuropean1(true)
            }
            if (index === "1") {
                setEuropean2(true)
            }
        } else {
            if (index === "0") {
                setEuropean1(false)
            }
            if (index === "1") {
                setEuropean2(false)
            }
        }
    }
    //
    // useEffect(() => {
    //     if (toggleBank === false) {
    //         setEuropean2(false)
    //     }
    // }, [toggleBank]);

    //Go back
    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);
    const onBackButtonEvent = () => {
        setNewSurveyor(0);
    }
    const handleCancel = () => {

        //setDireccion("");
        setShowModal(false)
    }

    //download file from bytes
    const downloadFile = (bytes, fileName) => {
        const blob = new Blob([bytes], {type: "application/pdf"});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click()
    }

    const handleDownload = (e) => {
        //console.log("handleDownload" + e)
        axios({
            url: `${urlConfig.allUrl}:9004/api/v1/files/download?file=${e}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        }).then((response) => {
            downloadFile(response.data, e)
        }).catch((error) => {
            //console.log(error);
        });
    }

    const handlePreviewImg = (e) => {
        if (e.target?.files.length > 0) {
            const file = e.target.files[0];
            const fileUrl = URL.createObjectURL(file);
            setProfileImage(fileUrl);
            setImageFile(file);
            setIsImage(true);
            setSurveyor({...surveyor, photo: file})
        }
    }

    const uploadImage = () => {
        //console.log("uploadImage")
        const file = imageFile;

        if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/jpg") {
            throw new Error('Formato de imagen inválido.');
        }
        if (file.size > 20000000) {
            throw new Error('Imagen excede el tamaño permitido (20mb).');
        }

        const image = new File([file], `PROFILE_${file.name}`, {type: file.type});
        let formData = new FormData();
        formData.append('file', image);
        //console.log("formData", formData)
        const config = {
            headers: {
                'content-type': 'multipart/form-data', //'Authorization': process..env.REACT_APP_TOKEN_RSK
                'Authorization': "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIzY2E3YWI2ZC0wOGZiLTQ3MDMtYTI0Yi1hZjNiNzYwYTFiOWMiLCJzdWIiOiJ2Y2VsaXNAcnNrY2hpbGUuY29tIiwicm9sIjoiUk9MRV9SU0siLCJpYXQiOjE2Njg0MzAxNTAsImV4cCI6MjA2ODQ2NjE1MH0.r7MyaqiC-2wpKv6Ai6gmJnDFaFL8_lPEZvl44n2aed0"
            }
        }

        return axios.post(`${urlConfig.allUrl}:9004/api/v1/files/img`, formData, config);

        /*
		axios.post(`${urlConfig.allUrl}:9004/api/v1/files/img`, formData, config).then((res) => {
			const {data} = res.data;
			setProfileImage(data[0]);
		}).catch((error) => {
			//console.log(error);
		})*/
    }

    const uploadFile = (surveyorData) => {
        let formData = new FormData();
        let formDataRegister = new FormData();
        arrFile.forEach((file) => {
            //console.log(file)
            formData.append('file', file);
            formDataRegister.append('file', file);
        });
        let dataFinal = surveyorData

        //console.log(surveyor)
        //console.log(!editSurveyor)

        if (editSurveyor) {
            //console.log(typeof surveyor.passportDocument)
            dataFinal = {
                ...surveyorData,
                "passportDocument": (surveyor.passportDocument === false) ? false : !(typeof surveyor.passportDocument === 'string'),
                "degreeCertificate": (surveyor.degreeCertificate === false) ? false : !(typeof surveyor.degreeCertificate === 'string'),
                "addressCertificate": (surveyor.addressCertificate === false) ? false : !(typeof surveyor.addressCertificate === 'string'),
                "curriculum": (surveyor.curriculum === false) ? false : !(typeof surveyor.curriculum === 'string'),
            }
        }

        dataFinal.company_id = ""
        //console.log(dataFinal)
        const json = JSON.stringify(dataFinal);
        const blob = new Blob([json], {
            type: "application/json"
        });
        formData.append('data', blob)
        const token = localStorage.getItem("token");
        const noBearerToken = token.toString().replace("Bearer ", "")

        //no bearer

        let config = {
            headers: {
                'content-type': 'multipart/form-data',

            }
        }
        if (editSurveyor) {
            config = {
                headers: {
                    'content-type': 'multipart/form-data', 'Authorization': noBearerToken

                }
            }

            return axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/inspector/${surveyorData.id}`, formData, config).then((res) => {
                //return axios.post(`${urlConfig.urlKite}/api/v1/inspector/${surveyorData.id}`, formData, config).then((res) => {

                // //console.log(res)
                toast.success(`${traduccion?.success?.successActSurv}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });

                const goToOneSurveyor = (id, edita) => {
                    //get the token from the local storage
                    let token = localStorage.getItem("token");
                    const noBearerToken = token.toString().replace("Bearer ", "")
                    let header = {
                        headers: {
                            Authorization: noBearerToken,
                        }
                    }
                    //console.log(header)
                    axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/inspector/${id}`, header)
                        .then((response) => {
                            //console.log(response)
                            let dataMix = {
                                ...response.data, id: id
                            }
                            setSurveyor(dataMix);
                            //console.log(editSurveyor)
                            if (edita) {
                                //console.log("editando")
                                setNewSurveyor(1);
                            } else {
                                //console.log("viendo")
                                setNewSurveyor(2);
                            }
                            //console.log(response.data.data)
                        }).catch((error) => {
                        //console.log(error);
                    })
                };
            }).catch((error) => {
                //console.log(error);
                toast.error(`${traduccion?.errores?.formErr}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
        } else {

            const json = JSON.stringify(dataFinal);
            const blob = new Blob([json], {
                type: "application/json"
            });
            formDataRegister.append('data', blob);


            return axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/inspector/register`, formDataRegister, config)
                //return axios.post(`${urlConfig.urlKite}/api/v1/inspector/register`, formDataRegister, config)

                .then((response) => {
                    toast.success(`${traduccion?.success?.formSend}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                })
                .catch((error) => {
                    //console.log(error);
                    if (error.response.data.message.search("Email already") !== -1) {
                        toast.error(`${traduccion?.errores?.mailExist}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        if (error.response.data.message) {
                            if (error.response.data.message.search("Account already exists") !== -1) {
                                toast.error(`${traduccion?.errores?.accExist}`, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            } else {
                                toast.error(error.response.data.message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            }
                        } else {
                            toast.error(`${traduccion?.errores?.formErr}`, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }

                    }
                });

        }
    }


    const checkFile = (e) => {
        if (e.target.value.length) {
            //console.log("nuevos archivos")
            return true;
        } else {
            //console.log("no hay archivos")
            return false;
        }
    }

    const docsChange = (e) => {
        let myFile = e.target.files[0];
        if (checkFile(e)) {
            if (e.target?.files.length > 0) {
                //change file name
                const myRenamedFile = new File([myFile], e.target.name + "_" + myFile.name, {type: myFile.type});
                // //console.log("myFile", myRenamedFile+`.name`)

                for (let i = 0; i < arrFile.length; i++) {
                    if (arrFile[i].name.split('_')[0] === myRenamedFile.name.split('_')[0]) {
                        arrFile.splice(i, 1);
                    }
                }

                if (myRenamedFile.name.split('_')[0] === "address") {
                    setSurveyor({...surveyor, addressCertificate: true})
                } else if (myRenamedFile.name.split('_')[0] === "passport") {
                    setSurveyor({...surveyor, passportDocument: true})
                } else if (myRenamedFile.name.split('_')[0] === "degree") {
                    setSurveyor({...surveyor, degreeCertificate: true})
                } else if (myRenamedFile.name.split('_')[0] === "curriculum") {
                    setSurveyor({...surveyor, curriculum: true})

                }
                // myFile.name = e.target.name+myFile.name;
                console.log({myRenamedFile})
                setArrFile([...arrFile, myRenamedFile]);

                for (let pair of files.entries()) {
                    //console.log(pair[0] + ', ' + pair[1]);
                }
            }
        } else {
            //console.log(e.target.name)
            for (let i = 0; i < arrFile.length; i++) {
                if (arrFile[i].name.split('_')[0] === e.target.name) {
                    //console.log(arrFile[i].name.split('_')[0])
                    arrFile.splice(i, 1);
                    if (e.target.name === "address") {
                        setIsAddress(false);
                        setSurveyor({...surveyor, addressCertificate: filesServer.addressCertificate})
                    } else if (e.target.name === "passport") {
                        setIsPassport(false);
                        setSurveyor({...surveyor, passportDocument: filesServer.passportDocument})
                    } else if (e.target.name === "degree") {
                        setSurveyor({...surveyor, degreeCertificate: filesServer.degreeCertificate})
                    } else if (e.target.name === "curriculum") {
                        setSurveyor({...surveyor, curriculum: filesServer.curriculum})
                    }

                }
            }

        }
    }

    //use Effect para comprobar si passport y address estan cargados
    useEffect(() => {
        for (let i = 0; i < arrFile.length; i++) {
            //console.log(arrFile[i].name.split('_')[0])
            if (arrFile[i].name.split('_')[0] === "passport") {
                setSurveyor({...surveyor, passportDocument: true})
                setIsPassport(true);
            }
            if (arrFile[i].name.split('_')[0] === "address") {
                setSurveyor({...surveyor, addressCertificate: true})
                setIsAddress(true);
            }
        }

    }, [arrFile]);

    //subir foto
    //onChange={(e)=>handleUpload(e,"img")}

    const handleAllForm = (e) => {
        //console.log("handleAllForm")
        setLoading(true)
    }


    useEffect(() => {
        // //console.log("aca en el useEffect de loading")
        if (editSurveyor && loading) {
            if (handleSubmitForm()) {
                //console.log(loading)

                uploadFile(surveyor)
                setLoading(false)
            } else {
                if (Object.keys(errores).length > 10) {
                    toast.warning(`${traduccion?.warning?.tooMantmepty}`, {
                        style: {
                            whiteSpace: 'pre-line'
                        }, position: toast.POSITION.TOP_RIGHT
                    })
                } else {
                    toast.warning(`${Object.keys(errores).map((key, id) => {

                        return (`${id === 0 ? errores[key] : "\n" + errores[key]}`)
                    })}`, {
                        style: {
                            whiteSpace: 'pre-line'
                        }, position: toast.POSITION.TOP_RIGHT
                    })
                }
                if (Object.keys(errores).length > 0) {
                    scrollToTargetAdjusted(Object.keys(errores)[0]);
                }
                setLoading(false)
            }
        } else if (!editSurveyor && loading) {
            handleConfirm()
        }
    }, [loading])

    const handleConfirm = (e) => {
        //console.log("handleConfirm")
        if (handleSubmitForm()) {

            if (imageFile === null) {
                //console.log("Adjunte imagen")
                return false;
            }
            uploadImage().then((res) => {

                //console.log(res.data.data[0])
                // const surveyorData = {
                //     ...surveyor,
                //     photo: res.data.data[0]
                // }

                const surveyorData = surveyor;

                surveyorData.photo = res.data.data[0];

                uploadFile(surveyorData).then((res) => {
                    //console.log(res)
                    setLoading(false);

                }).catch((error) => {
                    //console.log(error);
                    setLoading(false);

                })
            }).catch((error) => {
                //console.log(error);
                setLoading(false);

            });

        } else {
            setLoading(false);
            if (Object.keys(errores).length > 0) {
                scrollToTargetAdjusted(Object.keys(errores)[0]);
            }
            let arrKeys = Object.keys(errors)
            let idx = arrKeys.findIndex((e => e === "countryId"))
            ////console.log(idx)
            if (idx !== -1) {
                toast.error(`${Object.values(errores)[idx]})`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                if (Object.keys(errores).length > 10) {
                    toast.warning(`${traduccion?.warning?.tooMantmepty}`, {
                        style: {
                            whiteSpace: 'pre-line'
                        }, position: toast.POSITION.TOP_RIGHT
                    })
                } else {
                    toast.warning(`${Object.keys(errores).map((key, id) => {

                        return (`${id === 0 ? errores[key] : "\n" + errores[key]}`)
                    })}`, {
                        style: {
                            whiteSpace: 'pre-line'
                        }, position: toast.POSITION.TOP_RIGHT
                    })
                }
            }

        }

        // Promise.allSettled([documentPromise]).then(res => {
        //     res.fotrEach((data, index) => {
        //         if (data.status !== 'fulfilled') {
        //             //console.log('Error al subir documentos');
        //         }
        //     })
        // }).catch(err => //console.log(err));
    }

    const keyDown = (e) => {
        // handleBlurForm(e)
        if (/[+()-]/.test(e.key)) {
            e.preventDefault();
        }
        //console.log(e.key)
    }

    const getIndexOfTool = (arrTool, toolId) => {
        let index = -1;
        arrTool.forEach((item, i) => {
            if (item.id === toolId) {
                index = i;
            }
        })
        return index;
    }

    const toolsBackToFront = (arrTools) => {
        let arr = []
        let arr5 = []
        let arr6 = []

        // poner las frutas en el array
        for (let i = 0; i < arrTools.length; i++) {
            if (arrTools[i].id !== 5 && arrTools[i].id !== 6) {
                arr.push({"id": `${arrTools[i].id}`})
            }
            // //console.log(arrTools[i].id)
            if (arrTools[i].id === 5) {
                arr5.push(arrTools[i].fruit.toString())
            }
            if (arrTools[i].id === 6) {
                arr6.push(arrTools[i].fruit.toString())
            }
        }
        let totalFruta = traduccion.newSurveyor?.frutas;
        if (arr5.length > 0) {
            let arrDef = [];
            for (let i = 0; i < totalFruta.length; i++) {
                if (arr5.includes(totalFruta[i].id.toString())) {
                    arrDef.push(totalFruta[i])
                }
            }
            arr.push({"id": '5', "fruits": arr5, "arrDefault": arrDef})
        } else if (Object.values(arrTools).indexOf('5') !== -1) {
            arr.push({"id": '5'})
        }
        if (arr6.length > 0) {
            let arrDef = [];
            for (let i = 0; i < totalFruta.length; i++) {
                if (arr6.includes(totalFruta[i].id.toString())) {
                    arrDef.push(totalFruta[i])
                }
            }
            arr.push({"id": '6', "fruits": arr6, "arrDefault": arrDef})
        } else if (Object.values(arrTools).indexOf('6') !== -1) {
            arr.push({"id": '6'})
        }

        let array = []
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === '5' || arr[i].id === '6') {
                if (!array.includes(arrTools[i].id)) {
                    // //console.log(arrTools[i].id)
                    array.push(arr[i].id.toString())
                } else {
                    array.splice(array.indexOf(arr[i].id), 1)
                }
            }
        }
        return {tools: arr, toolsArr: array}
    }

    const handleTools = (e, idNumber) => {
        let array = Array.from(toolArr)
        let toolsArr = Array.from(tool)

        if (idNumber === "5" || idNumber === "6") {
            if (!array.includes(idNumber)) {
                array.push(idNumber)
            } else {
                array.splice(array.indexOf(idNumber), 1)
            }
        }
        setToolArr(array)
        if (e.target.checked) {
            if (idNumber === "5" || idNumber === "6") {
                toolsArr.push({id: idNumber, fruits: []})
            } else {
                toolsArr.push({id: idNumber})
            }
            setTool(toolsArr)
        } else {
            toolsArr.splice(getIndexOfTool(toolsArr, idNumber), 1)
            setTool(toolsArr)
        }
        //console.log(getIndexOfTool(toolsArr, idNumber))

    }


    const agregaEspecieTool = (arrEsp, toolId) => {
        let arrayTools = Array.from(tool)
        let arraySpecies = []
        for (let i = 0; i < arrEsp.length; i++) {
            arraySpecies.push(arrEsp[i].id)
        }
        arrayTools[getIndexOfTool(tool, toolId)].fruits = arraySpecies
        setTool(arrayTools)
        setSurveyor({...surveyor, tools: arrayTools})
    }

    //busca el codigo teniendo el pais
    let codeByCountry = (pais) => {
        if (pais == "United StatesNO" || pais == "ChinaNO") {
            setCountryCode("")
            return [];
        } else {
            return countryCodes.filter((code) => (countries.countries[code].name == pais));
        }
    }

    let countryByCode = (code) => {
        return countries.countries[code]?.name;
    }

    //delete item from array
    const deleteItem = () => {
        let arrCountryCode1 = countryCodes.map((country) => countries.countries[country].name).sort();
        let index = arrCountryCode1.indexOf("China");
        arrCountryCode1.splice(index, 1);
        let index2 = arrCountryCode1.indexOf("United States");
        arrCountryCode1.splice(index2, 1);
        return (arrCountryCode1);
    }
    let arrCountryCode1 = countryCodes.map((country) => countries.countries[country].name).sort();

    // ACA SE ELIMINA CHINA y EEUU
    //let arrCountryCode1 = deleteItem();

    //Agrega el codigo en el phone con el onchange
    const handleChangeCountry = (e) => {
        setSurveyor({
            ...surveyor, country: e
        })

        let code = codeByCountry(e);
        if (code !== undefined) {
            setCountryCode("")
            if (code.length > 0) {
                // //console.log(code)
                setCountryCode(code[0]);
            } else {
                //console.log("No se encuentra codigo")
            }
        }
    }

    const handleChangeCountryEnd = (e) => {
        setSurveyor({
            ...surveyor, country: e[0]
        })
        //console.log(codeByCountry(e))
        if (codeByCountry(e).length > 0) {
            setCountryCode(codeByCountry(e[0]))
        }
    }


    //update surveyor when range and tools change
    useEffect(() => {
        setSurveyor({
            ...surveyor, operationRange: range
        })
    }, [range])


    useEffect((e) => {
        setSurveyor({
            ...surveyor, tools: tool
        })
        // //console.log(tool)
    }, [tool])

    useEffect(() => {
        setShowMessage(true);
    }, [sendForm]);

    useEffect(() => {
        setTimeout(function () {
            setShowMessage(false);
        }, 3000);
        setTimeout(function () {
            setSendForm(null)
        }, 3000);
    }, [showMessage]);

    //revisando datos
    // useEffect(() => {
    //     //console.log(countries);
    // }, []);
    useEffect(() => {
        //console.log(arrFile)
    }, [arrFile]);
    // useEffect(() => {
    //     //console.log(toolArr)
    // }, [toolArr]);
    // useEffect(() => {
    //     //console.log(tool)
    // }, [tool]);
    // useEffect(() => {
    //     //console.log(surveyorData)
    // }, [surveyorData]);
    useEffect(() => {
        setSurvVerif(surveyor)

    }, [surveyor, typeCode])


    useEffect(() => {
        setDireccion(surveyor.mainAddress)
    }, [surveyor.mainAddress]);

    useEffect(() => {
        //console.log(countryRate)
        //console.log(surveyor)
        //console.log(survVerif)
        //console.log(surveyorData)
        if (countryRate) {

            setSurveyor({
                ...surveyor, country_id: countryRate.id
            })

        }
    }, [countryRate])

    const handleCheckCompany = (e) => {
        //console.log({e})
        setFromCompany(e.target.checked)
        setSurveyor({
            ...surveyor, hasCompany: e.target.checked
        })
        setNewSurveyor(3)
        if (e.target.checked === false) {
            setSurveyor({
                ...surveyor, company_id: null,
            })
        }
    }


    //--------------Tiene que estar al final
    useEffect(() => {
        //console.log("oneSurveyor", oneSurveyor)
        if (editSurveyor) {
            const dataSurv = {
                ...surveyor,

                "firstName": oneSurveyor.firstName,
                "lastName": oneSurveyor.lastName,
                "surveyorName": oneSurveyor.surveyorName,
                "address": oneSurveyor.address,
                "personalId": oneSurveyor.personalId,
                "mainAddress": oneSurveyor.mainAddress,
                "photo": oneSurveyor.photo,
                "passportDocument": oneSurveyor.passportDocument === null ? false : oneSurveyor.passportDocument,
                "degreeCertificate": oneSurveyor.degreeCertificate === null ? false : oneSurveyor.degreeCertificate,
                "addressCertificate": oneSurveyor.addressCertificate === null ? false : oneSurveyor.addressCertificate,
                "curriculum": oneSurveyor.curriculum === null ? false : oneSurveyor.curriculum,
                "profession": oneSurveyor.profession,
                "contract": oneSurveyor.contract,
                "rating": oneSurveyor.rating,
                "price": oneSurveyor.price,
                "latitude": oneSurveyor.latitude,
                "longitude": oneSurveyor.longitude,
                "operationRange": oneSurveyor.operationRange,
                "responseTime": oneSurveyor.responseTime,
                "phone": oneSurveyor.phone,
                "email": oneSurveyor.email,
                "country": oneSurveyor.country,
                "username": oneSurveyor.username,
                "tools": [],
                "status_id": oneSurveyor.status_id,
                "id": oneSurveyor.id,
                "area_id": oneSurveyor.area_id,
                "basicRatePerHour": oneSurveyor.basicRatePerHour,
                "ratePerKm": oneSurveyor.ratePerKm,
                "ratePerAddHour": oneSurveyor.ratePerAddHour,
                "bankInformation": oneSurveyor.bankInformation,
                "country_id": oneSurveyor.country_id,
                "company_id": oneSurveyor.company_id,
            }
            if (oneSurveyor.bankInformation[0]?.bicCode.length > 0) {
                setTypeCode(1)
            }
            if (oneSurveyor.bankInformation[0]?.swiftCode.length > 0) {
                setTypeCode(2)
            }

            if (oneSurveyor.bankInformation[1]?.bicCode.length > 0) {
                setTypeCodeB(1)
            }
            if (oneSurveyor.bankInformation[1]?.swiftCode.length > 0) {
                setTypeCodeB(2)
            }


            // //console.log(showInputCodeType)
            if (oneSurveyor.company_id !== null) {
                dataSurv.hasCompany = true
                setFromCompany(true)
            } else {
                dataSurv.hasCompany = false
                setFromCompany(false)
            }
            handleChangeCountry(dataSurv.country)
            //console.log(oneSurveyor.country_id)
            getCountryById(oneSurveyor.country_id)
            // //console.log("onesurveyor",oneSurveyor)
            setProfileImage(oneSurveyor.photo)
            setRange(oneSurveyor.operationRange)

            dataSurv.tools = toolsBackToFront(oneSurveyor.tools).tools
            setTool(dataSurv.tools)
            setToolArr(toolsBackToFront(oneSurveyor.tools).toolsArr)
            //console.log("dataSurv", dataSurv)
            setSurveyor(dataSurv)
        }

    }, []);

    // const handleChangeCompany = (e) => {
    //     //console.log(e)
    //     setSurveyor({
    //         ...surveyor,
    //         company_id: e
    //     })
    // }
    const handleChangeCompanyEnd = (e) => {
        //console.log(e)
        setSurveyor({
            ...surveyor, company_id: e[0].id
        })
    }

    const acceptModal = () => {
        if (surveyor.address !== "" && surveyor.operationRange > 0) {
            setShowModal(false)
        } else {
            toast.warning(`${traduccion?.warning?.mutcompleteRangeAndaddress}`)
        }
    }

    useEffect(() => {
        setAddressForm({lat: surveyor.latitude, lng: surveyor.longitude})
    }, [surveyor.latitude, surveyor.longitude])

    useEffect(() => {
        validationsForm(survVerif)
    }, [survVerif])

    return (<>
        <div className={styles.title_surveyor} style={{justifyContent: "start", alignItems: 'center'}}>
            {editSurveyor ? <div>Editar Perfil</div> : <div>{title}</div>}
            {!editSurveyor && <Form.Check
                type="switch"
                checked={fromCompany}
                label={fromCompany ? "Compañia Surveyors" : "Persona Natural"}
                style={{margin: '0 20px', fontSize: 18, alignSelf: "center"}}
                onClick={handleCheckCompany}>
            </Form.Check>}
        </div>
        {fromCompany ? <NewSurveyorCompany handleCheckCompany={{handleCheckCompany}} fromCompany={{fromCompany}}/> : <>
            <Row className={styles.contain_all_surveyors}
                 style={{margin: '20px 0', justifyContent: 'start', alignSelf: 'start'}}>
                <Col className="mt-4" sm={12} lg={6}>
                    <div className={styles.all_surveyors}>
                        <div className={styles.profile_and_title}>
                            <div className={styles.title}>{traduccion.newSurveyor?.detallesPersonales}</div>
                            <div style={{textAlign: 'right'}}>
                                {errores.imageFile && <p style={{marginBottom: 5}}
                                                         className={styles.errors}>{errores.imageFile}</p>}
                                <label
                                    className={(!editSurveyor && !errores.imageFile) ? styles.image : (!editSurveyor && errores.imageFile) ? styles.imageB : styles.image2}
                                    htmlFor="file-input">
                                    <img src={profileImage} alt="imagen"/>
                                </label>
                            </div>
                            {(!editSurveyor) &&
                                <input style={{display: 'none'}} onChange={handlePreviewImg} id="file-input"
                                       type="file" accept={"image/*"} name="photo"/>}

                        </div>
                        <Row>
                            <Col>
                                <div>{traduccion.newSurveyor?.nombre}</div>
                                {errores.firstName && <p className={styles.errors}>{errores.firstName}</p>}
                                <Form.Group controlId="surveyorName" style={{width: '90%'}}>
                                    <Form.Control value={surveyor.firstName} onChange={handleChange}
                                                  onKeyDown={e => {
                                                      if (e.key === "Tab") handleBlurForm(e)
                                                  }} onBlur={handleBlurForm}
                                                  className={styles.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.nombrePlaceHolder}
                                                  name="firstName"
                                                  isValid={!errores.firstName}
                                    />
                                </Form.Group>
                                <div>{traduccion.newSurveyor?.apellido}</div>
                                {errores.lastName && <p className={styles.errors}>{errores.lastName}</p>}

                                <Form.Group controlId="lastName" style={{width: '90%'}}>
                                    <Form.Control value={surveyor.lastName} onChange={handleChange}
                                                  onKeyDown={e => {
                                                      if (e.key === "Tab") handleBlurForm(e)
                                                  }} onBlur={handleBlurForm}
                                                  className={styles.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.apellidoPlaceHolder}
                                                  name="lastName"
                                                  isValid={!errores.lastName}
                                    />
                                </Form.Group>
                                <div>{traduccion.newSurveyor?.pais}</div>
                                {errores.country && <p className={styles.errors}>{errores.country}</p>}
                                <Form.Group value={surveyor.country} controlId="country"
                                            style={{width: '90%', marginTop: 0}}>
                                    <Typeahead
                                        placeholder={surveyor.country || `${traduccion.newSurveyor?.seleccionPais}`}
                                        style={{margin: '0px !important'}}
                                        className={styles.typeahead}
                                        id="basic-typeahead"
                                        labelKey={option => (option !== "CN" || option !== "US") ? `${option}` : null}
                                        onChange={handleChangeCountryEnd}
                                        onInputChange={e => handleChangeCountry(e)}
                                        options={arrCountryCode1}
                                        onBlur={handleBlurForm}
                                        isValid={!errores.country}
                                    />
                                </Form.Group>
                                <div style={{marginTop: 23}}>{traduccion.newSurveyor?.profesion}</div>
                                {errores.profession && <p className={styles.errors}>{errores.profession}</p>}

                                <Form.Group controlId="profession" style={{width: '90%'}}>
                                    <Form.Control value={surveyor.profession} onChange={handleChange}
                                                  onKeyDown={e => {
                                                      if (e.key === "Tab") handleBlurForm(e)
                                                  }} onBlur={handleBlurForm}
                                                  className={styles.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.seleccioneProfesion}
                                                  name="profession"
                                                  isValid={!errores.profession}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>

                                <div>{traduccion.newSurveyor?.celular}</div>
                                <InputGroup controlId="phone" ref={refPhone} style={{width: '90%'}}>
                                    {errores.phone && <p className={styles.errors}>{errores.phone}</p>}

                                    {countryCode !== "" && <InputGroup.Text
                                        className={errores.country ? styles.selects_input : styles.selects_input_countryT}

                                    >
                                        <FlagIcon code={countryCode} style={{
                                            height: 18,
                                            width: 22,
                                            border: '1px solid #999',
                                            borderRadius: '2px',
                                            marginRight: 6
                                        }}/>
                                        +{countries.countries[countryCode].phone}
                                    </InputGroup.Text>}
                                    <Form.Control ref={refPhoneNum} onChange={handleChange}
                                        //onKeyDown={keyDown}
                                                  className={styles.selects_input}
                                                  type="text"
                                                  onInput={onlyNumbers}
                                                  placeholder={countryCode !== "" ? surveyor.phone.replace(`+${countries.countries[countryCode]?.phone}`, "") || traduccion.newSurveyor?.celularPlaceHolder1 : surveyor.phone.replace(`+${countries.countries[countryCode]?.phone}`, "") || traduccion.newSurveyor?.celularPlaceHolder2}
                                                  disabled={countryCode === ""} name="phone"
                                                  isValid={!errores.phone && !errores.country}
                                    />
                                </InputGroup>
                                <div>{traduccion.newSurveyor?.correoElectronico}</div>
                                {!editSurveyor ? <Form.Group controlId="email" style={{width: '90%'}}>
                                    {errores.email && <p className={styles.errors}>{errores.email}</p>}

                                    <Form.Control value={surveyor.email} onChange={handleChange}
                                                  onBlur={handleBlurForm}
                                                  className={styles.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.emailPlaceHolder}
                                                  name="email"
                                                  isValid={!errores.email}
                                    />
                                </Form.Group> : <p style={{
                                    color: '#888', fontWeight: 500, marginTop: 5
                                }}>{surveyor.email}</p>

                                }
                                <div>{traduccion.newSurveyor?.idPersonal}</div>
                                {errores.personalId && <p className={styles.errors}>{errores.personalId}</p>}

                                <Form.Group controlId="personalId" style={{width: '90%'}}>
                                    <Form.Control value={surveyor.personalId} onChange={handleChange}
                                                  onKeyDown={e => {
                                                      if (e.key === "Tab") handleBlurForm(e)
                                                  }}
                                                  onBlur={handleBlurForm} className={styles.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.idPlaceHolder}
                                                  name="personalId"
                                                  isValid={!errores.personalId}
                                    />
                                </Form.Group>
                                {(fromCompany && editSurveyor) && <>
                                    <div>Company</div>
                                    <div style={{
                                        color: '#888', fontWeight: 500, marginTop: 5
                                    }}>{company.filter((e) => e.id === surveyor.company_id)[0].companyName}</div>
                                </>}

                            </Col>
                            <div>{traduccion.newSurveyor?.rangoOperacion} (Km)</div>
                            {errores.mainAddress && <p className={styles.errors}
                                                       style={{marginLeft: '1rem'}}>{errores.mainAddress}</p>}
                            {errores.operationRange && <p className={styles.errors}
                                                          style={{marginLeft: '1rem'}}>{errores.operationRange}</p>}

                            <InputGroup controlId="mainAddress" style={{width: '90%'}}>
                                <Button onClick={() => setShowModal(true)} className={styles.icon_container}
                                        id="button-addon1">
                                    <MdOutlineAddLocationAlt className={styles.icon_new_location}/>
                                </Button>

                                <Form.Control className={styles.selects_input3} type="text" disabled={true}
                                              value={direccion}
                                              placeholder={traduccion.newSurveyor?.direccionPlaceHolder}
                                              name="mainAddress"
                                              isValid={!errores.mainAddress}
                                />
                                <Form.Control className={styles.selects_input2} type="text" disabled={true}
                                              value={`${range / 1000} km`}
                                              placeholder="Range" name="range"
                                              isValid={!errores.operationRange}

                                />

                            </InputGroup>
                            {(surveyor.country_id != null) && <div style={{marginTop: 20}}>
                                <div style={{fontWeight: 'bold'}}>Country Rate</div>
                                <div>Basic rate per hour: {countryRate.basicRatePerHour}</div>
                                <div>Rate per Km: {countryRate.ratePerKm}</div>
                                <div>Rate per hour: {countryRate.ratePerAddHour}</div>

                            </div>}


                        </Row>
                    </div>
                </Col>
                <Col className="mt-4">
                    <div className={styles.all_surveyors}>
                        <div className={styles.title}>{traduccion.newSurveyor?.documentosRespaldo}</div>

                        <div className={styles.file_title}>{traduccion.newSurveyor?.pasaporte}</div>
                        {errores.passportFile && <p className={styles.errors}>{errores.passportFile}</p>}

                        <Form.Group controlId="passportFile">
                            <InputGroup>
                                <Form.Control onChange={docsChange}
                                              className={typeof surveyor.passportDocument === "string" ? styles.file_input2 : styles.file_input}
                                              type="file"
                                              placeholder="Passport" onKeyDown={e => {
                                    if (e.key === "Tab") handleBlurForm(e)
                                }}
                                              onBlur={handleBlurForm} name="passport"
                                              isValid={!errores.passportFile}
                                              accept="application/pdf"

                                />

                                {typeof surveyor.passportDocument === "string" &&

                                    <Button onClick={() => handleDownload(surveyor.passportDocument)}
                                            variant="outline-secondary" id="button-addon2"
                                            style={{width: '50px'}} className={styles.button_input}>
                                        <FaFileDownload/>
                                    </Button>}
                            </InputGroup>
                            {typeof surveyor.passportDocument === "string" && <div style={{
                                fontSize: 12, marginTop: 0
                            }}>{surveyor.passportDocument.length > 1 && surveyor.passportDocument.split('_')[1]} </div>}
                        </Form.Group>

                        <div className={styles.file_title}>{traduccion.newSurveyor?.certificadoTitulo}</div>
                        <Form.Group controlId="degree">
                            <InputGroup style={{display: 'flex', flexWrap: 'nowrap', flexDirection: 'row'}}>

                                <Form.Control onChange={docsChange} onKeyDown={e => {
                                    if (e.key === "Tab") handleBlurForm(e)
                                }}
                                              className={(surveyor.degreeCertificate.length > 1) ? styles.file_input2 : styles.file_input}
                                              type="file"
                                              title=" " name="degree" onBlur={handleBlurForm}
                                              accept="application/pdf"
                                />
                                {typeof surveyor.degreeCertificate === "string" &&
                                    <Button onClick={() => handleDownload(surveyor.degreeCertificate)}
                                            variant="outline-secondary" id="button-addon2"
                                            style={{width: '50px'}} className={styles.button_input}>
                                        <FaFileDownload/>
                                    </Button>}
                            </InputGroup>
                            {typeof surveyor.degreeCertificate === "string" && <div style={{
                                fontSize: 12, marginTop: 0
                            }}>{surveyor.degreeCertificate.length > 1 && surveyor.degreeCertificate.split('_')[1]} </div>}

                        </Form.Group>

                        <div className={styles.file_title}>{traduccion.newSurveyor?.direccionDocumento}<span
                            style={{fontSize: 14}}> {traduccion.newSurveyor?.direccionDocumentoEjemplo}</span>
                        </div>
                        {errores.addressFile && <p className={styles.errors}>{errores.addressFile}</p>}

                        <Form.Group controlId="addressFile">
                            <InputGroup style={{display: 'flex', flexWrap: 'nowrap', flexDirection: 'row'}}>

                                <Form.Control onChange={docsChange}
                                              className={(surveyor.addressCertificate.length > 1) ? styles.file_input2 : styles.file_input}
                                              type="file"
                                              placeholder="Document" onKeyDown={e => {
                                    if (e.key === "Tab") handleBlurForm(e)
                                }}
                                              onBlur={handleBlurForm}
                                              name="address"
                                              isValid={!errores.addressFile}
                                              accept="application/pdf"

                                />
                                {typeof surveyor.addressCertificate === "string" &&
                                    <Button onClick={() => handleDownload(surveyor.addressCertificate)}
                                            variant="outline-secondary" id="button-addon2"
                                            style={{width: '50px'}} className={styles.button_input}>
                                        <FaFileDownload/>
                                    </Button>}
                            </InputGroup>
                            {typeof surveyor.addressCertificate === "string" && <div style={{
                                fontSize: 12, marginTop: 0
                            }}>{surveyor.addressCertificate.length > 1 && surveyor.addressCertificate.split('_')[1]} </div>}

                        </Form.Group>
                        <div className={styles.file_title}>{traduccion.newSurveyor?.curriculum}</div>
                        <Form.Group controlId="curriculum">
                            <InputGroup style={{
                                display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', margin: 0
                            }}>

                                <Form.Control onChange={docsChange} onKeyDown={e => {
                                    if (e.key === "Tab") handleBlurForm(e)
                                }}
                                              className={(surveyor.curriculum.length > 1) ? styles.file_input2 : styles.file_input}
                                              type="file" onBlur={handleBlurForm}
                                              placeholder="Curriculum" name="curriculum"
                                              accept="application/pdf"
                                              onFocus={checkFile}
                                />
                                {typeof surveyor.curriculum === "string" &&
                                    <Button onClick={() => handleDownload(surveyor.curriculum)}
                                            variant="outline-secondary" id="button-addon2"
                                            style={{width: '50px'}} className={styles.button_input}>
                                        <FaFileDownload/>
                                    </Button>}
                            </InputGroup>
                        </Form.Group>
                        {typeof surveyor.curriculum === "string" && <div style={{
                            fontSize: 12, marginTop: 0, marginBottom: 10
                        }}>{surveyor.curriculum.length > 1 && surveyor.curriculum.split('_')[1]} </div>}

                        {/*{!paginaSurveyors &&*/}
                        {/*    <div style={{margin:'16px 0'}}>*/}
                        {/*    <a href={"#curriculum"}>Send reminder</a>*/}
                        {/*    </div>*/}
                        {/*}*/}

                        <div className={styles.title}>{traduccion.newSurveyor?.herramientas}</div>

                        <Form.Group controlId="formBasicCheckbox">
                            {traduccion.newSurveyor?.herramientasOpciones.map((tool, idx) => (<div key={idx}>
                                <Form.Check
                                    id={`tool-${tool.id}`}
                                    key={tool.id}
                                    type="checkbox"
                                    label={tool.description}
                                    name={tool.name}
                                    checked={surveyor.tools.some(e => e.id == tool.id)}
                                    // onChange={(e)=>//console.log(e.target.checked)}
                                    onChange={(e) => handleTools(e, tool.id)}
                                />
                                {toolArr.includes(tool.id) && <div>
                                    <p style={{margin: 0}}>Especies</p>
                                    <Typeahead
                                        id="basic-typeahead-multiple"
                                        multiple
                                        defaultSelected={surveyor.tools.find(e => e.id == tool.id)?.arrDefault}
                                        labelKey={option => `${option.description}`}
                                        onChange={(e) => agregaEspecieTool(e, tool.id)}
                                        options={traduccion.newSurveyor?.frutas}
                                        style={{marginBottom: 20}}
                                    />
                                </div>}
                            </div>))}
                        </Form.Group>
                    </div>
                </Col>
            </Row>
            <Row className={styles.contain_all_surveyors} style={{margin: '1.5rem 0'}}>

                <Col sm={12}>
                    <div className={styles.all_surveyors}>
                        <div className={styles.title}>{traduccion.newSurveyor?.detallesBancarios}</div>
                        <Row>
                            <Col>
                                <div>{traduccion.newSurveyor?.nombreBanco}</div>

                                <Form.Group controlId="bankName">
                                    {errores.bankName && <p className={styles.errors}>{errores.bankName}</p>}

                                    <Form.Control value={surveyor.bankInformation[0]?.bankName}
                                                  onChange={handleBankInformation}
                                                  className={styles.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.bancoPlaceHolder}
                                                  name="bankName-0"
                                                  onKeyDown={e => {
                                                      if (e.key === "Tab") handleBlurForm(e)
                                                  }}
                                                  onBlur={handleBlurForm}
                                                  isValid={!errores.bankInformation && !errores.bankName}
                                    />
                                </Form.Group>

                                <div>{traduccion.newSurveyor?.cuentaBancaria}</div>
                                <Form.Group controlId="accountNumber">
                                    {errores.accountNumber && <p className={styles.errors}>{errores.accountNumber}</p>}

                                    <Form.Control value={surveyor.bankInformation[0]?.accountNumber}
                                                  onChange={handleBankInformation}
                                                  className={styles.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.cuentaBancariaPlaceHolder}
                                                  name="accountNumber-0"
                                                  onKeyDown={e => {
                                                      if (e.key === "Tab") handleBlurForm(e)
                                                  }}
                                                  onBlur={handleBlurForm}
                                                  isValid={!errores.bankInformation && !errores.accountNumber}
                                    />
                                </Form.Group>

                                <div>
                                    <div>{traduccion.newSurveyor?.tipoCodigo}</div>
                                    {errores.codes && <p className={styles.errors}>{errores.codes}</p>}

                                    <Form.Group controlId="typeCode-0">
                                        <Form.Select onChange={handleTypeCode}
                                                     className={styles.selects_input}
                                                     name="typeCode-0" style={{width: '20ch'}}
                                                     isValid={!errores.bankInformation && !errores.codes}
                                        >
                                            <option value={0} selected={typeCode === 0}>Select Type Code
                                            </option>
                                            <option value={1}
                                                    selected={typeCode === 1}>{traduccion.newSurveyor?.codigoBic}</option>
                                            <option value={2}
                                                    selected={typeCode === 2}>{traduccion.newSurveyor?.codigoSwift}</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                {typeCode === 1 && <div>
                                    <Form.Group controlId="bicCode-0">
                                        <Form.Control value={surveyor.bankInformation[0]?.bicCode}
                                                      style={{width: '22ch'}}
                                                      onChange={handleBankInformation}
                                                      placeholder={traduccion.newSurveyor?.codigoBic}
                                                      className={styles.selects_input} type="text"
                                                      name="bicCode-0"
                                                      onKeyDown={e => {
                                                          if (e.key === "Tab") handleBlurForm(e)
                                                      }}
                                                      onBlur={handleBlurForm}
                                                      isValid={!errores.bankInformation && !errores.codes}
                                        />

                                    </Form.Group>
                                </div>}
                                {typeCode === 2 && <div>
                                    <Form.Group controlId="swiftCode-0">
                                        <Form.Control value={surveyor.bankInformation[0]?.swiftCode}
                                                      style={{width: '22ch'}}

                                                      onChange={handleBankInformation}
                                                      placeholder={traduccion.newSurveyor?.codigoSwift}

                                                      className={styles.selects_input} type="text"
                                                      name="swiftCode-0"
                                                      onKeyDown={e => {
                                                          if (e.key === "Tab") handleBlurForm(e)
                                                      }}
                                                      onBlur={handleBlurForm}
                                                      isValid={!errores.bankInformation && !errores.codes}
                                        />
                                    </Form.Group>
                                </div>}

                            </Col>
                            <Col>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div>
                                        <div>Currency</div>
                                        {errores.currency && <p className={styles.errors}>{errores.currency}</p>}

                                        <Form.Group controlId="currency">
                                            <Form.Select onChange={handleBankInformation}
                                                         className={styles.selects_input}
                                                         style={{width: '13ch'}}
                                                         name="currency-0"
                                                         isValid={!errores.bankInformation && !errores.currency}
                                            >
                                                <option value="0"
                                                        selected={surveyor.bankInformation[0]?.currency === 0}>---
                                                </option>
                                                {currency.map((currency, index) => (
                                                    <option key={index} value={currency.id}
                                                            selected={surveyor.bankInformation[0]?.currency === currency.id}>
                                                        {currency.description}
                                                    </option>))}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div>{traduccion.newSurveyor?.direccionCuenta}</div>
                                {errores.bankAddress && <p className={styles.errors}>{errores.bankAddress}</p>}

                                <Form.Group controlId="bankAddress">
                                    <Form.Control value={surveyor.bankInformation[0]?.bankAddress}
                                                  onChange={handleBankInformation}
                                                  className={styles.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.direccionCuentPlaceHolder}
                                                  onKeyDown={e => {
                                                      if (e.key === "Tab") handleBlurForm(e)
                                                  }}
                                                  onBlur={handleBlurForm} name="bankAddress-0"
                                                  isValid={!errores.bankInformation && !errores.bankAddress}
                                    />
                                </Form.Group>
                                <div>{traduccion.newSurveyor?.ubicacion}</div>
                                {errores.area_id && <p className={styles.errors}>{errores.area_id}</p>}

                                <Form.Group controlId="area_id">
                                    <Form.Select onChange={handleBankInformation}
                                                 className={styles.selects_input}
                                                 name="area_id-0"
                                                 isValid={!errores.bankInformation && !errores.area_id}
                                    >
                                        <option value="0"
                                                selected={surveyor.bankInformation[0].area_id === 0}>{traduccion?.newSurveyor?.selectArea}
                                        </option>
                                        <option value="1"
                                                selected={surveyor.bankInformation[0].area_id === 1}>{traduccion?.newSurveyor?.selectAreaOtra}
                                        </option>

                                        <option value="3"
                                                selected={surveyor.bankInformation[0].area_id === 3}>{traduccion?.newSurveyor?.selectAreaEuropa}
                                        </option>
                                        {/*<option value="3" selected={european1}>{traduccion.newSurveyor?.europa}</option>*/}
                                    </Form.Select>
                                </Form.Group>
                                {european1 && <>
                                    <div>{traduccion.newSurveyor?.codigoIBAN}</div>
                                    {errores.iban && <p className={styles.errors}>{errores.iban}</p>}

                                    <Form.Group controlId="iban" style={{width: '25ch'}}>
                                        <Form.Control value={surveyor.bankInformation[0].ibanCode}
                                                      onChange={handleBankInformation}
                                                      className={styles.selects_input}
                                                      type="text"
                                                      placeholder="AB1234567890123" name="ibanCode-0"
                                                      isValid={!errores.bankInformation && !errores.iban}
                                        />
                                    </Form.Group>
                                </>}

                            </Col>
                            <hr></hr>
                            <div className={styles.title}>{traduccion.newSurveyor?.titularCuenta}</div>
                            {errores.accountName && <p className={styles.errors}>{errores.accountName}</p>}

                            <div id="accountName">{traduccion.newSurveyor?.nombreTitularCuenta}</div>
                            <Form.Group controlId="bankHolderName">
                                <Form.Control value={surveyor.bankInformation[0]?.bankHolderName}
                                              onChange={handleBankInformation}
                                              className={styles.selects_input}
                                              type="text"
                                              placeholder={traduccion.newSurveyor?.nombreTitularCuentaPlaceHolder}
                                              onKeyDown={e => {
                                                  if (e.key === "Tab") handleBlurForm(e)
                                              }}
                                              onBlur={handleBlurForm} name="bankHolderName-0"
                                              isValid={!errores.bankInformation && !errores.accountName}
                                />
                            </Form.Group>
                            <div>{traduccion.newSurveyor?.direccion}</div>
                            {errores.accountAddress && <p className={styles.errors}>{errores.accountAddress}</p>}

                            <Form.Group controlId="bankHolderAddress">
                                <Form.Control value={surveyor.bankInformation[0]?.bankHolderAddress}
                                              onChange={handleBankInformation}
                                              className={styles.selects_input}
                                              type="text"
                                              placeholder={traduccion.newSurveyor?.direccionPlaceHolder}
                                              onKeyDown={e => {
                                                  if (e.key === "Tab") handleBlurForm(e)
                                              }}
                                              onBlur={handleBlurForm} name="bankHolderAddress-0"
                                              isValid={!errores.bankInformation && !errores.accountAddress}
                                />
                            </Form.Group>
                            <div style={{width: '100%'}}></div>
                            <Button style={{width: 200, margin: '.5rem .7rem'}}
                                    onClick={handleToggleBank}>{toggleBank ? traduccion.newSurveyor?.eliminarSegundaCuenta : traduccion.newSurveyor?.agregarSegundaCuenta}</Button>

                            {toggleBank && <>
                                <hr style={{margin: '40px 0'}}/>
                                <div
                                    className={styles.title}>{traduccion.newSurveyor?.segundaCuenta}</div>

                                <Row>
                                    <Col>
                                        <div>{traduccion.newSurveyor?.nombreBanco}</div>
                                        <Form.Group controlId="bankName1">
                                            {errores.bankName1 && <p className={styles.errors}>{errores.bankName1}</p>}

                                            <Form.Control value={surveyor.bankInformation[1]?.bankName}
                                                          onChange={handleBankInformation}
                                                          className={styles.selects_input} type="text"
                                                          placeholder={traduccion.newSurveyor?.bancoPlaceHolder}
                                                          name="bankName-1"
                                                          onKeyDown={e => {
                                                              if (e.key === "Tab") handleBlurForm(e)
                                                          }}
                                                          onBlur={handleBlurForm}/>

                                        </Form.Group>

                                        <div>{traduccion.newSurveyor?.cuentaBancaria}</div>
                                        <Form.Group controlId="accountNumber1">
                                            {errores.accountNumber1 &&
                                                <p className={styles.errors}>{errores.accountNumber1}</p>}
                                            <Form.Control
                                                value={surveyor.bankInformation[1]?.accountNumber}
                                                onChange={handleBankInformation}
                                                className={styles.selects_input}
                                                type="text"
                                                placeholder={traduccion.newSurveyor?.cuentaBancariaPlaceHolder}
                                                name="accountNumber-1" onKeyDown={e => {
                                                if (e.key === "Tab") handleBlurForm(e)
                                            }}
                                                onBlur={handleBlurForm}/>
                                        </Form.Group>


                                        <div>
                                            <div>{traduccion.newSurveyor?.tipoCodigo}</div>
                                            {errores.codesB && <p className={styles.errors}>{errores.codesB}</p>}

                                            <Form.Group controlId="codesB">
                                                <Form.Select onChange={handleTypeCodeB}
                                                             className={styles.selects_input}
                                                             name="typeCode-1"
                                                             style={{width: '20ch'}}>
                                                    <option value={0} selected={typeCodeB === 0}>Select
                                                        Type
                                                        Code
                                                    </option>
                                                    <option value={1}
                                                            selected={typeCodeB === 1}>{traduccion.newSurveyor?.codigoBic}</option>
                                                    <option value={2}
                                                            selected={typeCodeB === 2}>{traduccion.newSurveyor?.codigoSwift}</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        {typeCodeB === 1 && <div>
                                            <Form.Group controlId="bicCode-1">
                                                <Form.Control
                                                    value={surveyor.bankInformation[1]?.bicCode}
                                                    style={{width: '22ch'}}
                                                    onChange={handleBankInformation}
                                                    placeholder={traduccion.newSurveyor?.codigoBic}
                                                    className={styles.selects_input} type="text"
                                                    name="bicCode-1"
                                                    onKeyDown={e => {
                                                        if (e.key === "Tab") handleBlurForm(e)
                                                    }}
                                                    onBlur={handleBlurForm}/>

                                            </Form.Group>
                                        </div>}
                                        {typeCodeB === 2 && <div>
                                            <Form.Group controlId="swiftCode-1">
                                                <Form.Control
                                                    value={surveyor.bankInformation[1]?.swiftCode}
                                                    style={{width: '22ch'}}

                                                    onChange={handleBankInformation}
                                                    placeholder={traduccion.newSurveyor?.codigoSwift}

                                                    className={styles.selects_input} type="text"
                                                    name="swiftCode-1"
                                                    onKeyDown={e => {
                                                        if (e.key === "Tab") handleBlurForm(e)

                                                    }}
                                                    onBlur={handleBlurForm}/>
                                            </Form.Group>
                                        </div>}


                                        {/*<div>*/}
                                        {/*    <div>{traduccion.newSurveyor?.tipoCodigo}</div>*/}
                                        {/*    <Form.Group controlId="codeType-1">*/}
                                        {/*        <Form.Select className={styles.selects_input}*/}
                                        {/*                     style={{width: '18ch'}} id="codeType-1"*/}
                                        {/*                     name="codeType-1" onChange={handleCodeType}>*/}
                                        {/*            <option key="" value=""*/}
                                        {/*                    selected={codeTypeValue[1] === ""}>{traduccion.newSurveyor?.tipoCodigoEscoger}</option>*/}
                                        {/*            <option key="swiftCode-1" value="swiftCode-1"*/}
                                        {/*                    selected={surveyor.bankInformation[1]?.swiftCode !== ""}>{traduccion.newSurveyor?.codigoSwift}</option>*/}
                                        {/*            <option key="bicCode-1" value="bicCode-1"*/}
                                        {/*                    selected={surveyor.bankInformation[1]?.bicCode !== ""}>{traduccion.newSurveyor?.codigoBic}</option>*/}
                                        {/*        </Form.Select>*/}
                                        {/*    </Form.Group>*/}
                                        {/*</div>*/}
                                        {/*{*/}
                                        {/*    showInputCodeType[1] && (<Form.Group style={{width: '25ch'}}>*/}
                                        {/*        <Form.Control*/}
                                        {/*            value={surveyor.bankInformation[1]?.bicCode || surveyor.bankInformation[1]?.swiftCode}*/}
                                        {/*            onChange={handleBankInformation}*/}
                                        {/*            className={styles.selects_input} type="text"*/}
                                        {/*            placeholder={traduccion.newSurveyor?.codigoSwiftPlaceHolder}*/}
                                        {/*            onKeyDown={e => {*/}
                                        {/*                if (e.key === "Tab") handleBlurForm(e)*/}
                                        {/*            }}*/}
                                        {/*            onBlur={handleBlurForm} id="inputCodeType-1"*/}
                                        {/*            name={codeTypeValue[1]}/>*/}
                                        {/*    </Form.Group>)*/}
                                        {/*}*/}

                                    </Col>
                                    <Col>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div>
                                                <div>Currency</div>
                                                <Form.Group controlId="currency1">
                                                    {errores.currency1 &&
                                                        <p className={styles.errors}>{errores.currency1}</p>}

                                                    <Form.Select onChange={handleBankInformation}
                                                                 className={styles.selects_input}
                                                                 style={{width: '11ch'}}
                                                                 name="currency-1">
                                                        <option value="0"
                                                                selected={surveyor.bankInformation[1]?.currency === 0}>---
                                                        </option>
                                                        {currency.map((currency, index) => (
                                                            <option key={index} value={currency.id}
                                                                    selected={surveyor.bankInformation[1]?.currency === currency.id}>
                                                                {currency.description}
                                                            </option>))}


                                                        {/*<option value="1"*/}
                                                        {/*        selected={surveyor.bankInformation[1]?.currency === 1}>USD*/}
                                                        {/*</option>*/}
                                                        {/*<option value="2"*/}
                                                        {/*        selected={surveyor.bankInformation[1]?.currency === 2}>EUR*/}
                                                        {/*</option>*/}
                                                        {/*<option value="3"*/}
                                                        {/*        selected={surveyor.bankInformation[1]?.currency === 3}>GBP*/}
                                                        {/*</option>*/}
                                                        {/*{currency.map((moneda) => (*/}
                                                        {/*    <option key={moneda.id} value={moneda.id}>{moneda.description}</option>*/}
                                                        {/*))}*/}
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div>{traduccion.newSurveyor?.direccionCuenta}</div>
                                        {errores.bankAddress1 &&
                                            <p className={styles.errors}>{errores.bankAddress1}</p>}

                                        <Form.Group controlId="bankAddress1">
                                            <Form.Control
                                                value={surveyor.bankInformation[1]?.bankAddress}
                                                onChange={handleBankInformation}
                                                className={styles.selects_input}
                                                type="text"
                                                placeholder={traduccion.newSurveyor?.direccionCuentPlaceHolder}
                                                name="bankAddress-1"
                                                isValid={!errores.bankInformation && !errores.bankAddress1}
                                            />
                                        </Form.Group>

                                        <div>{traduccion.newSurveyor?.ubicacion}</div>
                                        {errores.area_id1 && <p className={styles.errors}>{errores.area_id1}</p>}

                                        <Form.Group controlId="area_id1">
                                            <Form.Select onChange={handleBankInformation}
                                                         className={styles.selects_input}
                                                         name="area_id-1"
                                                         isValid={!errores.area_id1}
                                            >
                                                <option value="0"
                                                        selected={surveyor.bankInformation[0].area_id === 0}>Select
                                                    Area
                                                </option>
                                                <option value={1}
                                                        selected={surveyor.bankInformation[0].area_id === 1}>Sudamérica
                                                </option>
                                                <option value={2}
                                                        selected={surveyor.bankInformation[0].area_id === 2}>Norte
                                                    America
                                                </option>
                                                <option value={3}
                                                        selected={surveyor.bankInformation[0].area_id === 3}>Europa
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                        {european2 && <>
                                            <div>{traduccion.newSurveyor?.codigoIBAN}</div>
                                            {errores.iban1 && <p className={styles.errors}>{errores.iban1}</p>}
                                            <Form.Group controlId="iban" style={{width: '25ch'}}>
                                                <Form.Control
                                                    value={surveyor.bankInformation[1].ibanCode}
                                                    onChange={handleBankInformation}
                                                    className={styles.selects_input}
                                                    type="text"
                                                    placeholder="IBAN" name="ibanCode-1"
                                                    isValid={!errores.bankInformation && !errores.iban1}
                                                />
                                            </Form.Group>
                                        </>}
                                    </Col>
                                </Row>
                                <hr></hr>
                                <div
                                    className={styles.title}>{traduccion.newSurveyor?.titularCuenta}</div>
                                {errores.accountName1 && <p className={styles.errors}>{errores.accountName1}</p>}

                                <div>{traduccion.newSurveyor?.nombreTitularCuenta}</div>
                                <Form.Group controlId="accountName1">
                                    <Form.Control value={surveyor.bankInformation[1]?.bankHolderName}
                                                  onChange={handleBankInformation}
                                                  className={styles.selects_input} type="text"
                                                  placeholder={traduccion.newSurveyor?.nombreTitularCuentaPlaceHolder}
                                                  name="bankHolderName-1"
                                                  isValid={!errores.accountName1}
                                    />
                                </Form.Group>
                                <div>{traduccion.newSurveyor?.direccion}</div>
                                {errores.accountAddress1 && <p className={styles.errors}>{errores.accountAddress1}</p>}

                                <Form.Group controlId="accountAddress1">
                                    <Form.Control value={surveyor.bankInformation[1]?.bankHolderAddress}
                                                  onChange={handleBankInformation}
                                                  className={styles.selects_input} type="text"
                                                  placeholder={traduccion.newSurveyor?.direccionPlaceHolder}
                                                  name="bankHolderAddress-1"
                                                  isValid={!errores.accountAddress1}
                                    />
                                </Form.Group>
                            </>}
                        </Row>
                    </div>

                </Col>

            </Row>
            {!paginaSurveyors ? <Row className={styles.contain_all_surveyors}
                                     style={{margin: '1.5rem 0 4rem 0', alignSelf: 'start'}}>
                <Col>
                    <div className={styles.all_surveyors} style={{padding: '.8rem 1.5rem'}}>
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', margin: '0 1.5rem'
                        }}>
                            <div>
                                <div style={{fontWeight: 600}}>{traduccion?.clients?.status}</div>
                                <Form.Group controlId="status_id">
                                    <Form.Select onChange={handleChange}
                                                 className={styles.selects_input}
                                                 style={{width: '25ch'}} name="status_id"
                                                 isValid={!errores.status_id}
                                    >
                                        <option value="0" selected={surveyor.status_id === 0}>Seleccione
                                            estado
                                        </option>
                                        <option value="1" selected={surveyor.status_id === 1}>Active
                                        </option>
                                        <option value="2" selected={surveyor.status_id === 2}>Inactive
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div>
                                {/*<Button style={{marginTop: '1.5rem', padding: '.4rem 2rem'}}>*/}
                                {/*    Cancel*/}
                                {/*</Button>*/}
                                <Button disabled={loading} onClick={handleAllForm}
                                        style={{
                                            marginTop: '1.5rem', marginLeft: '1.5rem', padding: '.4rem 2rem'
                                        }}>
                                    {loading ? <Spinner animation="border" role="status">
                                    </Spinner> : <span>Save</span>}
                                </Button>
                            </div>
                        </div>
                    </div>

                </Col>
                {sendForm && <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                    {sendForm}
                </div>}
                <ToastContainer/>

            </Row> : <Row className={styles.contain_all_surveyors}
                          style={{margin: '1.5rem 1rem 4rem 0', justifyContent: 'end'}}>
                {loading && <div style={{marginRight: '70px', textAlign: 'end', position: 'relative'}}>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>}
                {sendForm && <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                    {sendForm}
                </div>}
                <Button onClick={handleAllForm}
                        style={{
                            width: 200, marginTop: '1.5rem', marginLeft: '1.5rem', padding: '1rem 2rem'
                        }}>
                    {traduccion.newSurveyor?.aplicarCambios}
                </Button>


            </Row>}

        </>

        }

        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size={width > 768 ? "lg" : "sm"}
            aria-labelledby="contained-modal-title-vcenter"
            centered

            style={width <= 768 && {width: '100vw'}}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Main Address
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Mapa mapCode={mapCode} setMapCode={setMapCode} pag={1} setDireccion={setDireccion}
                          direccion={direccion}
                          setAddressForm={setAddressForm} addressForm={addressForm} onlySurveyor={true}
                          range={range} setRange={setRange}/>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {/*primero guarda la direction y después que desaparezca el modal*/}
                <Button onClick={() => setShowModal(false)}>Confirmar</Button>

                {/*<Button onClick={handleCancel}>Cancelar</Button>*/}
            </Modal.Footer>
        </Modal>
    </>)
}
