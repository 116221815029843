//import styles from "../filtros.module.css"
import {Button, Form} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import LangContext from "../../../context/LangContext";
import {useParamsUrl} from "../useParamsUrl";
import {urlConfig} from "../../../config/urlConfig";
import axios from "axios";
import {toast} from "react-toastify";
import DBContext from "../../../context/DBContext";
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import styles from "../surveyors/mantenedor_surveyor.module.css";

export const FiltroCasosReclamadosExporter = ({perPage,url,setUrl,active,setActive,listaClaim, setListaClaim}) => {
    const {claim} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);
    const {searchUrl, handleSearch} = useParamsUrl(
        //`${urlConfig.urlNgrok}/api/v1/order/customers/search`

            `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/customers/search`
    );

    const [status, setStatus] = useState([]);
    const token = localStorage.getItem("token");
    const getClaims = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setListaClaim(res.data)
        }).catch((error) => {
            //console.log(error);
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }

    // useEffect(() => {
    //     getClaims(url)
    // },[url])

    useEffect(() => {
        if (listaClaim?.length > 0) {
            if (listaClaim[0].timeline !== undefined) {
                const statusArray = claim?.map(obj => obj.timeline.map(t => ({id: t.id, name: t.description})))
                    .flat()
                    .filter((obj, index, self) => self.map(t => t.id).indexOf(obj.id) === index);
                setStatus(statusArray);
            }
        }

    }, [listaClaim])
    const searchClaims = () => {
        setActive(0);
        let newUrl =`${searchUrl}&size=${perPage}&page=${0}&type=`;
        setUrl(newUrl);
        return axios.get(newUrl, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            const claims = res.data;
            //console.log(claims)
            setListaClaim(claims);
        }).catch((error) => {
            //console.log(error);
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        });
    }
    //console.log(listaClaim)
    return (
        <>
            <div className={stylesfiltro.filtro_total} style={{margin: 0, width: '100%'}}>
                <div className={stylesfiltro.filtros_busqueda}>
                    <p className={stylesfiltro.title_filter}>{traduccion?.clients?.filtros}</p>
                    <div className={stylesfiltro.opciones_busqueda}>
                            <div>
                                <label htmlFor="exportador">{traduccion?.claim?.exporter}</label>
                                <Form.Control className={stylesfiltro.selects_input}
                                             id="exportador"
                                             name="name"
                                             // size="sm"
                                             onChange={handleSearch}/>
                            </div>
                            <div className={styles.opciones_date}>
                                <div className={styles.opciones_date_in}>
                                    <Form.Label htmlFor="Desde">{traduccion?.factura?.desde}</Form.Label>
                                    <Form.Control
                                        className={stylesfiltro.selects_input}
                                        // size="sm"
                                        type="date"
                                        name="from"
                                        id="Desde"
                                        placeholder={traduccion?.factura?.desde}
                                        onChange={handleSearch}
                                    />
                                </div>
                                <div className={styles.opciones_date_in}>
                                    <Form.Label htmlFor="Hasta">{traduccion?.factura?.hasta}</Form.Label>
                                    <Form.Control
                                        className={stylesfiltro.selects_input}
                                        // size="sm"
                                        type="date"
                                        name="to"
                                        id="Hasta"
                                        placeholder={traduccion?.factura?.hasta}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="estado">{traduccion?.clients?.status}</label>
                                <Form.Select className={stylesfiltro.selects_input}
                                             id="estado"
                                             name="status"
                                             // size="sm"
                                             onChange={handleSearch}>

                                    <option value="">{traduccion?.clients?.selectOption}</option>
                                    <option value="2">{traduccion?.factura?.pendiente}</option>
                                    <option value="4">{traduccion?.factura?.invoiced}</option>
                                    <option value="1">{traduccion?.factura?.paid}</option>
                                </Form.Select>

                            </div>
                            <Button type={"submit"} className={stylesfiltro.boton_filtro}
                                    variant="primary" onClick={searchClaims}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                        fill="white"/>
                                </svg>
                            </Button>
                    </div>
                </div>
            </div>
        </>
    )
};
export default FiltroCasosReclamadosExporter;
