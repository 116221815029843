import {CaptchaDeGoogle, Login} from "./Login"
import App from "../../App"
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Terms from "../Terms";
import React from "react";
// import {TresModulos} from "./TresModulos";

export const Ruta = ({load}) => {

    let direccion = Login

    if (!localStorage.getItem("token")){
        direccion=<CaptchaDeGoogle/>
        // direccion=<CargaTab/>
    }else{
        direccion=<App/>
    }
   // let ingresaNuevoSurveyor = <IngresarSurveyorPorPagina/>
    return (
        <BrowserRouter>
            <Routes>
                {/*<Route exact path='/surveyors' element={ingresaNuevoSurveyor}/>*/}
                <Route path="/terms" element={<Terms/>} />
                <Route exact path='*' element={direccion}/>
            </Routes>
        </BrowserRouter>
    )
}
