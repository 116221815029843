import {DetailAccount} from "../components/home/details_account/DetailAccount";
import Tabla from "../components/home/table_home/Tabla";
import {FiltroCasosReclamados} from "../components/home/ultimos_casos_reclamados/FiltroCasosReclamados";
import styles from "./home.module.css"

export const RskHome = () => {

    return (
        <div className={styles.home}>

        </div>

    )
}