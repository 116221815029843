export default function useExpireToken() {
    const token = localStorage.getItem("token");
    if (token !== null) {
        const payload = JSON.parse(window.atob(token.split(".")[1]));
        //seconds now
        const now = Math.floor(Date.now() / 1000);
        if (payload.exp <=  now ) {
            // //console.log("Token expirado");
            localStorage.removeItem("token");
            window.location.href = "/";
        }else{
            // //console.log("Token no expirado");
            //console.log("Quedan: ", payload.exp - now, " segundos")
        }
    }
}
