import {Col, Row, Form, Button, InputGroup} from "react-bootstrap";
import styles from "./documentos.module.css"
import {FaFileDownload} from "react-icons/fa";
import {FiDownload} from "react-icons/fi";
import {useContext, useEffect, useState} from "react";
import LangContext from "../../../../context/LangContext";
import {AssignmentOfRights} from "../../../../pdf/AssignmentOfRights";
import {PDFDownloadLink} from '@react-pdf/renderer';
import DBContext from "../../../../context/DBContext";
import {LetterOfAuthority} from "../../../../pdf/LetterOfAuthority";
import axios from "axios";
import {urlConfig} from "../../../../config/urlConfig";
export const DocumentosTab = ({claimForm,setClaimForm,
                                  arrFile,setArrFile, //Arreglo de archivos para mandar al final cuando se hace el PUT
                                  documents,setDocuments, // Es un Array de los shipping documents
                                  handleDocuments,
                                  filesClaim,setFilesClaim // Un objeto donde están todos los documentos
}) => {

    const {traduccion} = useContext(LangContext);
    const {especie} = useContext(DBContext);


    useEffect(() => {
        if (claimForm?.documents?.shipping) {
            let documentos = claimForm?.documents?.shipping
            setDocuments({
                ...documents,
                'shippingDocuments': documentos
            });
        }
    }, [])


    // Guardando el nombre si es que existe en el reclamo desde antes

    //console.log(filesClaim)


    //Guardando el nombre mientras se crea el arreglo de files
    useEffect(() => {
        for (const file of arrFile) {
            let nombre = file.name.split("_")[0];
            let indice = file.name.indexOf("_");
            let resto = file.name.slice(indice+1);
            //console.log("AGREGANDO LOS NOMBRES!!!!  ----->",{nombre,indice,resto})

            setFilesClaim({
                ...filesClaim,
                [nombre]:resto
            })
        }
    },[arrFile]);


    useEffect(() => {
        if (claimForm?.documents) {
            setClaimForm({
                ...claimForm,
                documents: filesClaim
            })
        }
    },[filesClaim]);

    useEffect(() => {
        //console.log(claimForm)
        //console.log({filesClaim})
        if (claimForm?.documents) {
            setClaimForm({
                ...claimForm,
                documents: filesClaim

            })
        }
    },[]);

    //console.log(claimForm)
    //data / files
    const arrTypeDocs = [
        {name: "bl", label: "BL"},
        {name: "fito", label: "Fito"},
        {name: "origin", label: "Origen"},
        {name: "invoice", label: "Factura"},
        {name: "packingList", label: "Packing List"},
        {name: "booking", label: "Booking"},
        {name: "ct", label: "CT"},
        {name: "other", label: "Otros"},
    ]
    //Agregar documentos en un array

    //console.log(arrFile)
    const addShippingDoc = () => {
        const newArr = documents?.shippingDocuments;
        newArr.push({
            "bl": 0,
            "fito": 0,
            "origin": 0,
            "invoice": 0,
            "packingList": 0,
            "booking": 0,
            "ct": 0,
            "other": 0,
            "filename": ""
        })
        setDocuments({...documents,
            shippingDocuments:newArr});
        setFilesClaim({...filesClaim,
            shippingDocuments:newArr});
    }
    //Solo estoy borrando el último elemento del array
    const deleteShippingDoc = (index) => {
        const newArr = documents.shippingDocuments
        let strBorra = newArr.pop().filename
        //console.log(strBorra)


        // //borra del arreglo interno del objeto y guarda el nombre de lo que se borra
        // let strBorra = newArr.splice(index, 1)[0].filename;

        // //borra del arreglo de files con el nombre que se guardó
        const arrFiles = arrFile.filter((file) => file.name !== strBorra);
        setArrFile(arrFiles);
        // newArr.splice(index,1);
        //console.log(newArr)
        setDocuments({...documents,
            shippingDocuments:newArr});
        //
        // setFilesClaim({...filesClaim,
        //     [`shipping${index}`]:null,
        //     shippingDocuments:newArr});
    }

    const handleLog = (e,sub,index) => {

        const newArr = documents.shippingDocuments;
        newArr[index][sub] = e.target.checked ? 1 : 0;
        setDocuments({...documents,
             shippingDocuments:newArr});
        setFilesClaim({...filesClaim,
            shippingDocuments:newArr});
    }

    // useEffect(() => {
    //     setClaimForm({...claimForm,
    //         documents:documents});
    // }, [documents])


        const downloadFile = (bytes, fileName) => {
            const blob = new Blob ([bytes], {type:"application/pdf"})   ;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click()
        }
        //

    const descargaUno = (filename) => {
        axios({
            url: `${urlConfig.allUrl}:9004/api/v1/files/download?file=${filename}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        }).then((response) => {
            downloadFile(response.data, filename.split("_")[0])
        }).catch((error) => {
            //console.log(error);
        });
    }
    // //console.log(documents);
    //console.log(documents.shippingDocuments)
    //console.log(filesClaim)
    return (
        <>
        <div className={styles.title_main}>{traduccion.documentos?.documentosEmbarque}</div>
        <div className={styles.container}>
            {filesClaim?.shippingDocuments?.map((item, index) =>{
                    let regex = new RegExp(`^SHIPPING${index}_`);
                    return(
                <Row>
                    <Col>
                        <div className={styles.title} style={{fontSize:20}}>{traduccion.documentos?.documento}
                            {/*{filesClaim.shippingDocuments[index].filename && <span style={{fontSize:13}}>(Sobreescribir archivo existente)</span>}*/}
                        </div>
                        {filesClaim.shippingDocuments[index].filename  && <div style={{fontSize:16, marginTop:-10}}>{filesClaim.shippingDocuments[index].filename.slice(10)}</div>}

                        <Form.Group controlId="type1"  className="mb-3">
                        <div style={{display:'flex'}}>
                            <Form.Control className={item.filename.length > 0 ? styles.file_input2:styles.file_input}
                                          name={`shipping${index}`}
                                          onChange={handleDocuments} type="file" accept="application/pdf"/>
                            {regex.test(filesClaim.shippingDocuments[index].filename) && <div className={styles.button_des} onClick={()=>descargaUno(filesClaim.shippingDocuments[index].filename)}><FiDownload/></div>}

                            {/*<div className={styles.button_des} onClick={()=>descargaUno(filesClaim.shippingDocuments[index].filename)}><FiDownload/></div>*/}
                        </div>
                            <div>
                                {filesClaim.shippingDocuments[index].filename}
                            </div>
                            <div style={{fontSize:16}}>{traduccion.documentos?.soloArchivos}</div>
                        </Form.Group>

                    </Col>
                    <Col>
                        <div className={styles.title}>{traduccion.documentos?.seleccioneSet}</div>
                        {arrTypeDocs.map((subType, idx) => (
                            <Form.Check
                                key={idx}
                                style={{margin: "0 .6rem"}}
                                inline
                                label={subType.label}
                                name={subType.label}
                                type="checkbox"
                                id={subType.label}
                                onChange={(e)=>handleLog(e,subType.name, index)}
                                checked={filesClaim?.shippingDocuments[index][subType.name]}
                            />

                        ))}
                    </Col>
                </Row>
                )}
            // }
            )}
            <Row>
                <Col>
            <button className={styles.add_inputfile} name="addnotacredito" onClick={addShippingDoc}
                    style={{backgroundColor: 'white', display:'flex', 'flexDirection': 'row',margin:'1rem 0', border:'2px #c3c3ff solid', borderRadius:5,minWidth:'27ch'}}>
                <p className={styles.addicon}>+</p><span style={{margin:'.3rem',color:'black'}}>{traduccion.documentos?.agregarDocumento}</span>
            </button>
                </Col>
                {filesClaim?.shippingDocuments?.length > 0 &&
                <Col>
            <button className={styles.add_inputfile} name="addnotacredito" onClick={deleteShippingDoc}
                    style={{backgroundColor: 'white', display:'flex', 'flexDirection': 'row',margin:'1rem 0',border:'2px #ffc3c3 solid', borderRadius:5,minWidth:'27ch'}}>
                <p className={styles.removeicon}>-</p><span style={{margin:'.3rem',color:"black"}}>{traduccion.documentos?.removerDocumento}</span>
            </button>
                </Col>
                }
            </Row>


        </div>

        <div className={styles.title_main}>{traduccion.documentos?.otrosDocumentos}</div>
        <div className={styles.container2}>
            <Row>
                <Col>
                    <div className={styles.title}>{traduccion.documentos?.informeConsolidacion}</div>
                        {/^CONSOLIDATED_/.test(filesClaim.consolidatedReport)  && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.consolidatedReport}</div>}

                    <Form.Group controlId="type3"  style={{display:'flex'}}>
                        <Form.Control name="consolidated" className={/^CONSOLIDATED_/.test(filesClaim.consolidatedReport)  ? styles.file_input2:styles.file_input }  onChange={handleDocuments} type="file" accept="application/pdf" />
                        {/^CONSOLIDATED_/.test(filesClaim.consolidatedReport) && <div className={styles.button_des} onClick={()=>descargaUno(filesClaim.consolidatedReport)}><FiDownload/></div>}
                    </Form.Group>
                        <div className="mb-3" >{traduccion.documentos?.soloArchivos}</div>
                </Col>
                <Col>
                    <div className={styles.title}>{traduccion.documentos?.atmosfera}</div>
                    {/^CONTROLLED/.test(filesClaim.controlledAtmosphere) && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.controlledAtmosphere}</div>}
                    <Form.Group controlId="type4" style={{display:'flex'}} >
                        <Form.Control name="controlled"  className={/^CONTROLLED/.test(filesClaim.controlledAtmosphere) ? styles.file_input2:styles.file_input }  onChange={handleDocuments}  type="file" accept="application/pdf" />
                        {/^CONTROLLED/.test(filesClaim.controlledAtmosphere) &&  <div className={styles.button_des} onClick={()=>descargaUno(filesClaim.controlledAtmosphere)}><FiDownload/></div>}
                    </Form.Group>
                    <div className="mb-3">{traduccion.documentos?.soloArchivos}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={styles.title}>{traduccion.documentos?.termografo}</div>
                    {/^THERMOGRAPH_/.test(filesClaim.thermograph) && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.thermograph}</div>}
                    <Form.Group controlId="type5" style={{display:'flex'}}>
                        <Form.Control name="thermograph" className={/^THERMOGRAPH_/.test(filesClaim.thermograph) ? styles.file_input2:styles.file_input } onChange={handleDocuments}  type="file" accept="application/pdf" />
                        {/^THERMOGRAPH_/.test(filesClaim.thermograph) && <div className={styles.button_des} onClick={()=>descargaUno(filesClaim.thermograph)}><FiDownload/></div>}
                    </Form.Group>
                    <div className="mb-3">{traduccion.documentos?.soloArchivos}</div>
                </Col>
                <Col>
                    <div className={styles.title}>{traduccion.documentos?.termografo2}</div>
                    {/^THERMOGRAPH2_/.test(filesClaim.thermograph2) && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.thermograph2}</div>}
                    <Form.Group controlId="type6" style={{display:'flex'}}>
                        <Form.Control name="thermograph2" className={/^THERMOGRAPH2_/.test(filesClaim.thermograph2) ? styles.file_input2:styles.file_input }  onChange={handleDocuments}  type="file" accept="application/pdf" />
                        {/^THERMOGRAPH2_/.test(filesClaim.thermograph2)  && <div className={styles.button_des} onClick={()=>descargaUno(filesClaim.thermograph2)}><FiDownload/></div>}
                    </Form.Group>
                    <div className="mb-3">{traduccion.documentos?.soloArchivos}</div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className={styles.title}>{traduccion.documentos?.otro} </div>
                    {/^OTHER_/.test(filesClaim.other) && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.other}</div>}
                    <Form.Group controlId="type7" style={{display:'flex'}}>
                        <Form.Control name="other" className={/^OTHER_/.test(filesClaim.other)  ? styles.file_input2:styles.file_input }  onChange={handleDocuments}  type="file" accept="application/pdf"/>
                        {/^OTHER_/.test(filesClaim.other)  &&    <div className={styles.button_des} onClick={()=>descargaUno(filesClaim.other)}><FiDownload/></div>}
                    </Form.Group>
                    <div className="mb-3">{traduccion.documentos?.soloArchivos}</div>
                </Col>

                <Col>

                </Col>
            </Row>
        </div>
        <div className={styles.title_main}>{traduccion.documentos?.documentosRecupero}</div>
        <div className={styles.container2}>
            <Row>
                <Col>
                    <div className={styles.title}>{traduccion.documentos?.assignment} </div>
                    {filesClaim.assignmentLetter && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.assignmentLetter}</div>}
                    <div>
                        <Form.Group controlId="type8"  className="mb-3">
                       <InputGroup>
                            <Form.Control name="letter" className={filesClaim.assignmentLetter ? styles.file_input2:styles.file_input }  onChange={handleDocuments}  type="file" accept="application/pdf"/>
                               {filesClaim.assignmentLetter &&    <div className={styles.button_des} style={{padding:'7px 10px 8px 10px',marginTop:1, borderBottom:'2px solid #bbb'}} onClick={()=>descargaUno(filesClaim.assignmentLetter)}><FiDownload/></div>}
                           <PDFDownloadLink document={<LetterOfAuthority claimForm={claimForm} especie={especie} />} fileName="Letter of Authority.pdf">
                               <Button variant="outline-secondary" id="button-addon2" className={filesClaim.assignmentLetter ? styles.plantilla_des2:styles.plantilla_des } style={{marginLeft:10}} >
                                 <FaFileDownload className={styles.download_icon} />
                                   {/*{traduccion.documentos?.descargar}*/}
                                   {traduccion.documentos?.plantilla}

                               </Button>

                           </PDFDownloadLink>
                       </InputGroup>
                            <div>{traduccion.documentos?.soloArchivos}</div>
                        </Form.Group>
                    </div>
                </Col>
                <Col>
                    <div className={styles.title}>{traduccion.documentos?.right}</div>
                    {filesClaim.assignmentRights && <div style={{fontSize:13, marginTop:-10}}>{filesClaim.assignmentRights}</div>}                    <div>
                        <Form.Group controlId="type9"  className="mb-3">
                            <InputGroup>
                                <Form.Control name="rights" className={filesClaim.assignmentRights ? styles.file_input2:styles.file_input} onChange={handleDocuments}  type="file" accept="application/pdf" />
                                {filesClaim.assignmentRights &&    <div className={styles.button_des} style={{padding:'7px 10px 8px 10px',marginTop:1, borderBottom:'2px solid #bbb'}} onClick={()=>descargaUno(filesClaim.assignmentRights)}><FiDownload/></div>}

                                <PDFDownloadLink document={<AssignmentOfRights claimForm={claimForm} especie={especie} />} fileName="Assignment of Rights.pdf">
                                    <Button variant="outline-secondary" id="button-addon2" className={filesClaim.assignmentRights ? styles.plantilla_des2:styles.plantilla_des } style={{marginLeft:10}} >
                                            <FaFileDownload className={styles.download_icon} />
                                        {/*{traduccion.documentos?.descargar}*/}
                                        {traduccion.documentos?.plantilla}
                                        </Button>
                                </PDFDownloadLink>
                            </InputGroup>
                            <div>{traduccion.documentos?.soloArchivos}</div>
                        </Form.Group>
                    <div>
                    </div>
                    </div>
                </Col>
            </Row>
        </div>
    </>
    )
}
