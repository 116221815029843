import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import styles from "../../home/modal_reclamo/modal.module.css";
import {useContext} from "react";
import LangContext from "../../../context/LangContext";

export const ModalDescargaTodo = ({showDes,onHide,arr,descargaAll,loading}) => {
    const {traduccion} = useContext(LangContext);
    return (
        <>
            <Modal
                show={showDes}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {traduccion?.inspector?.descargarArchivos}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {arr.map((item,index) => (
                            <Row key={index}>
                                <Col xs={12} style={{margin:0,padding:0}}>
                                    <p className={styles.titulo}>{item}</p>
                                </Col>
                            </Row>
                        ))}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Button variant={"secondary"} onClick={onHide}>{traduccion?.inspector?.volver}</Button>
                    </div>
                    <Button style={{minWidth:'16ch'}} variant={"success"} onClick={()=>descargaAll(arr)}>{
                        loading ? <span className="spinner-border spinner-border-sm text-light" role="status"></span> : traduccion?.inspector?.descargarZip
                    }</Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}
