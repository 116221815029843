import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import styles from "../../home/modal_reclamo/modal.module.css";

export const ModalUpdateFinalized = ({show,onHide,handleUpdate,cambios}) => {
    const initialLang = localStorage.getItem("lang") || "es";
    return (
        <>     <Modal
            show={show}
            onHide={onHide}
            // size={width > 768 ? "md" : "sm"}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {initialLang === "es" ? "Está seguro que desea dejar el reclamo en Reissued?" : "Are you sure you want to leave the claim in Reissued?"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{padding:'.5rem 1rem 1rem 1rem'}}>
                    {
                        cambios?.map((cambio,index)=>{
                            return(
                                    <Col sm={12} key={index}>
                                        <b style={{fontSize:'.9rem'}}>{cambio}</b>
                                    </Col>
                            )
                        })
                    }
                </Row>
                <Container>
                    {initialLang === "es" ? "Al dejar el reclamo en Reissued, no podrá volver a modificarlo hasta que el inspector acepte el cambio." : "By leaving the claim in Reissued, you will not be able to modify it until the inspector accepts the change."}
                    <br/><br/>
                    {initialLang === "es" ? "Posteriormente, solo cuando el inspector acepte los cambios usted podrá volver a revisar y/o modificar el reclamo." : "Later, only when the inspector accepts the changes you will be able to review and / or modify the claim."}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button variant={"secondary"} onClick={onHide}>Volver</Button>
                </div>
                    <Button style={{minWidth:'16ch'}} variant={"success"} onClick={handleUpdate}>Confirmar</Button>

            </Modal.Footer>
        </Modal></>
    );
}
