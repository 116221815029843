import styles from './timeline.module.css'
import {BsFilePdf, BsFilePdfFill} from "react-icons/bs";
import {urlConfig} from "../../../../../config/urlConfig";
import axios from "axios";
import {toast} from "react-toastify";
import {useContext, useState} from "react";
import {Spinner} from "react-bootstrap";
import LangContext from "../../../../../context/LangContext";

export const Timeline = (props) => {
    const {traduccion} = useContext(LangContext);

    const [loadingPdf, setLoadingPdf] = useState(false);

    const fechaToDate = (fecha) => {
        let Nfecha = new Date(fecha)
        let anio = Nfecha.getFullYear()
        let mes = Nfecha.getMonth() + 1
        let dia = Nfecha.getDate()
        if (mes < 10) {
            mes = '0' + mes
        }
        if (dia < 10) {
            dia = '0' + dia
        }
        return `${anio}-${mes}-${dia}`
    }
    const fechaToTime = (fecha) => {
        let Nfecha = new Date(fecha)
        let hora = Nfecha.getHours()
        let minutos = Nfecha.getMinutes()
        let segundos = Nfecha.getSeconds()
        if (hora < 10) {
            hora = `0${hora}`
        }
        if (minutos < 10) {
            minutos = `0${minutos}`
        }
        if (segundos < 10) {
            segundos = `0${segundos}`
        }
        return `${hora}:${minutos}:${segundos}`
    }
    //console.log(props)

    const downloadPdf = () => {
        setLoadingPdf(true)
        let token = localStorage.getItem("token");
        const headers = {
            responseType: 'arraybuffer',
            headers: {
                'Content-Disposition': 'attachment',
                Authorization: token
            }
        }
        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me/invoice/${props.idClaim}`, {}, headers)
            .then(res => {
                const url = URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice${props.idClaim}.pdf`);
                document.body.appendChild(link);
                link.click();
                setLoadingPdf(false)
                toast.success(`${traduccion?.success?.downPdf}`)
            }).catch(err => {
            setLoadingPdf(false)
            //console.log(err)
        })
    }

    const stringInvoiced = 'FINALIZED'   // 'INVOICED'
    const stringReissued = 'REISSUED'
    const stringCanceled = 'CANCELED'// 'ACCEPTED'

    return (

        <>
            <div className={styles.all}>

                {
                    props.datos.map((e, idx) =>
                        <span key={idx}>
                        <div className={styles.contenedor}>
                            <div className={styles.circulo_accepted}/>
                            <div className={styles.linea_accepted}/>

                            <div className={styles.texto_accepted}>{e.description}
                                {props.datos[idx] && <div className={styles.fecha}>
                                    <div style={{fontSize: 16}}>{e.time !== "" ? fechaToDate(e.time) : "Today"}</div>
                                    <div style={{fontSize: 14}}>{e.time !== "" ? fechaToTime(e.time) : ""}</div>
                                </div>}
                            </div>
                            </div>
                        </span>
                    )
                }
                {
                    props.datos[props.datos.length - 1].description !== stringInvoiced
                    && !props.datos[props.datos.length - 1].description.includes(stringCanceled) ?
                        (
                            <span>
                                <div className={styles.contenedor}>
                                    <div className={styles.linea}
                                         style={{borderRight: 'transparent', width: '60px', borderStyle: 'dashed'}}/>
                                </div>
                            </span>
                        ) : (
                            <></>
                        )
                }
                {

                    (props.datos[props.datos.length - 1].description === stringInvoiced || props.datos[props.datos.length - 1].description === stringReissued
                        || props.datos[props.datos.length - 1].description.includes(stringCanceled)) &&
                    <div style={{alignSelf: 'center'}}>
                        <div className={styles.pdf_style} onClick={downloadPdf}>
                            {loadingPdf
                                ? <Spinner animation="border" role="status" variant={"primary"}>
                                </Spinner>
                                : <BsFilePdfFill style={{fontSize: 40, color: '#4651cc'}}/>}

                        </div>
                    </div>
                }


            </div>
        </>
    )
}
