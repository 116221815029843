export const surveyHead = {
    head:'<!doctype html>' +
        '<html lang="en">' +
        '<head>' +
        '<meta charset="UTF-8">' +
        '<meta name="viewport" content="width=device-width, initial-scale=1">' +
        '<title>Document</title>' +
        '<link rel="preconnect" href="https://fonts.googleapis.com">' +
        '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
        '<link href="https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500&family=Roboto+Condensed:wght@400;700&display=swap" rel="stylesheet">' +
        `<style> 
            *{
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                font-family: 'Golos Text',sans-serif;      
          }
            table{
            border-spacing: 0px;
            }

        
            p {
                text-align: justify;
                margin-bottom: 1rem;
                
            }
            .hover-img{
                box-shadow:0px 0px 10px 0 rgba(0,0,0,0.8);
            }
            .hover-img:hover{
                cursor: pointer;
                opacity: 0.8;
                box-shadow: #000000 0px 0px 2px 0px;
            }
        </style>`+
        '</head>' +
        `<body style="padding: 3.5rem 8vw; font-size: 17px">
`,
    end:"</body></html>"
}
