import './App.module.css';
import HeadNavbar from "./components/header/HeadNavbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Fragment, useEffect, useState} from "react";
import SideBar from "./components/sidebar/Sidebar";
import {Route, Routes, useLocation} from "react-router-dom";
import {Home} from "./pages/Home";
import {StuffingReport} from "./pages/StuffingReport";
import useWindowSize from "./hooks/useWindowSize";
import {Claim} from "./pages/Claim";
import styles from "./App.module.css"
import {RskHome} from "./pages/RskHome";
import {RskSidebar} from "./components/sidebar/RskSidebar";
import {Forbidden} from "./pages/Errors/Forbidden";
import {MantenedorSurveyor} from "./components/mantenedores/surveyors/MantenedorSurveyor";
import {NotFound} from "./pages/Errors/NotFound";
import {MantenedorCompania} from "./components/mantenedores/company_surveyors/MantenedorCompania";
import {Country} from "./components/mantenedores/country/Country";
import {MantenedorExporter} from "./components/mantenedores/exporters/MantenedorExporter";
import {Specie} from "./components/mantenedores/specie/Specie";
import {MantenedorMoneda} from "./components/mantenedores/currency/MantenedorMoneda";
import {Billing} from "./components/mantenedores/billing/billing";
import {AllClaims} from "./components/mantenedores/all_claims/AllClaims";
import {Damages} from "./components/mantenedores/damages/Damages";
import { Dashboard } from './components/mantenedores/dashboard/Dashboard';
import { Profiles } from './components/mantenedores/profiles/Profiles';
import {OrdenCobro} from "./components/mantenedores/orden_cobro/OrdenCobro";
import {BillingSurveyor} from "./components/mantenedores/billing_surveyors/BillingSurveyor";
import {Users} from "./components/mantenedores/users/Users";
import {AllStuffing} from "./components/mantenedores/all_stuffing/AllStuffing";
import OrdenPago from "./components/mantenedores/orden_cobro/OrdenPago";
import Terms from "./pages/Terms";


function App() {
    //Notificacion Campana
    const [notification, setNotification] = useState(false);
    // const onOff = () => setNotification(!notification)

    //SideBar
    const [collapse, setCollapse] = useState(false);
    const [initStuffing, setInitStuffing] = useState(false);
    const onOffColl = () => setCollapse(!collapse)

    const token = localStorage.getItem("token");
    const [rolUser, setRolUser] = useState(null);

    //Check if token is expired
    useEffect(() => {
        if (token) {
            const payload = JSON.parse(atob(token.split(".")[1]));
            setRolUser(payload.rol);
            const exp = payload.exp;
            const now = Math.floor(Date.now() / 1000);
            if (exp < now) {
                localStorage.removeItem("token");
                window.location.reload();
            }
            //console.log(exp, now);
        }
    }, []);

    const {height, width} = useWindowSize();

    useEffect(() => {
        if (width < 1357) {
            setCollapse(true);
        } else {
            setCollapse(false);
        }
        // return () => {
        // };
    }, [width]);

    //SetRoute
    const location = useLocation();
    const [routeActive, setRouteActive] = useState(location.pathname);
    const [stuff, setStuff] = useState(null);
    const [oneClaim, setOneClaim] = useState(null);

    const listaStatus = [
        {id: 1, nombre: "Activo"},
        {id: 2, nombre: "Inactivo"},
        {id: 3, nombre: "Suspendido"},
        {id: 4, nombre: "Retirado"},
        {id: 5, nombre: "Pendiente"},
        {id: 6, nombre: "Rechazado"},
        {id: 7, nombre: "Ingresado"},
    ]
    useEffect(()=>{
        if(location?.state?.oneClaimAll){
            setOneClaim(location?.state?.oneClaimAll)
        }

    },[])
    //console.log(oneClaim)
    //console.log(location)
    //For surveyor route
    const [newSurveyor, setNewSurveyor] = useState(0);

    //For Company route
    const [newCompany, setNewCompany] = useState(0);

    //For Country route
    const [newCountryRate, setNewCountryRate] = useState(0);

    //For Exporter route
    const [newExporter, setNewExporter] = useState(0);

    //For Specie route
    const [newSpecie, setNewSpecie] = useState(0);

    //For Currency route
    const [newCurrency, setNewCurrency] = useState(0);

    //For Billing route
    const [newBilling, setNewBilling] = useState(0);

    //For Damages route
    const [newDamages, setNewDamages] = useState(0);
    //For Profiles route
    const [newProfiles, setNewProfiles] = useState(0);

    //For Privileges route
    // const [newPrivileges, setNewPrivileges] = useState(0);

    //For Cobro route
    const [newCobro, setNewCobro] = useState(0);

    //For Billing_Surveyor route
    const [newBillingSurveyor, setNewBillingSurveyor] = useState(0);

    //For Payment route
    const [newPago, setNewPago] = useState(0);

    //For User route
    const [newUser, setNewUser] = useState(0);

    //Is pago or cobro
    const [isPago, setIsPago] = useState(false);

    //For Sub-Menu
    const [subMenuA, setSubMenuA] = useState(false);
    //get info from token

    const tokenInfo = JSON.parse(atob(token.split(".")[1]));

    const {modules} = tokenInfo;
    const arrRutas = (modules || '').split(",");
    arrRutas.push("")

    const updateRuta = arrRutas.map((ruta) => "/" + ruta);
    //console.log(updateRuta)
    // const allRutas = ["/", "/invoiced","/payment","/surveyors", "/companies", "/countries", "/exporters", "/fruits", "/currencies", "/billing", "/all_claims", "/damages", "/profiles", "/users","/all_stuffing"]
    const [rutas, setRutas] = useState(updateRuta);


    const routes = [
        {
            path: "/",
            element: <Dashboard setRouteActive={setRouteActive} />
        },
        {
            path: "/surveyors",
            element: <MantenedorSurveyor setRouteActive={setRouteActive} newSurveyor={newSurveyor} setNewSurveyor={setNewSurveyor} listaStatus={listaStatus} />
        },
        {
            path: "/companies",
            element: <MantenedorCompania setRouteActive={setRouteActive} newCompany={newCompany} setNewCompany={setNewCompany} listaStatus={listaStatus} />
        },
        {
            path: "/exporters",
            element: <MantenedorExporter setRouteActive={setRouteActive} newExporter={newExporter} setNewExporter={setNewExporter} listaStatus={listaStatus} />
        },
        {
            path: "/countries",
            element: <Country setRouteActive={setRouteActive} newCountryRate={newCountryRate} setNewCountryRate={setNewCountryRate} />
        },
        {
            path: "/fruits",
            element: <Specie setRouteActive={setRouteActive} newSpecie={newSpecie} setNewSpecie={setNewSpecie} listaStatus={listaStatus} />
        },
        {
            path: "/currencies",
            element: <MantenedorMoneda setRouteActive={setRouteActive} newCurrency={newCurrency} setNewCurrency={setNewCurrency} listaStatus={listaStatus} />
        },
        {
            path: "/invoiced/customers",
            element: <Billing setRouteActive={setRouteActive} newBilling={newBilling} setNewBilling={setNewBilling} listaStatus={listaStatus} />
        },
        {
            path: "/damages",
            element: <Damages setRouteActive={setRouteActive} listaStatus={listaStatus} newDamages={newDamages} setNewDamages={setNewDamages} />
        },
        {
            path: "/profiles",
            element: <Profiles setRouteActive={setRouteActive} listaStatus={listaStatus} newProfiles={newProfiles} setNewProfiles={setNewProfiles} />
        },
        {
            path: "/all_claims",
            element: <AllClaims />
        },
        {
            path: "/all_stuffing",
            element: <AllStuffing />
        },
        {
            path: "/stuffing",
            element: <RskHome stuff={stuff} />
        },
        {
            path: "/informe_inspeccion_all",
            element: <Claim stuff={stuff} setStuff={setStuff} oneClaim={oneClaim} setOneClaim={setOneClaim} setRouteActive={setRouteActive} />
        },
        {
            path: "/invoiced/recovery",
            element: <OrdenCobro setRouteActive={setRouteActive} newCobro={newCobro} setNewCobro={setNewCobro} isPago={isPago} setIsPago={setIsPago} />
        },
        {
            path: "/invoiced/surveyor",
            element: <BillingSurveyor setRouteActive={setRouteActive} newBillingSurveyor={newBillingSurveyor} setNewBillingSurveyor={setNewBillingSurveyor} />
        },
        {
            path: "/invoiced/payment",
            element: <OrdenPago setRouteActive={setRouteActive} newPago={newPago} setNewPago={setNewPago} isPago={isPago} setIsPago={setIsPago} />
        },
        {
            path: "/users",
            element: <Users setRouteActive={setRouteActive} newUser={newUser} setNewUser={setNewUser} listaStatus={listaStatus} />
        },
        {
            path: "/informe_inspeccion_all",
            element:<Claim stuff={stuff} setStuff={setStuff} oneClaim={oneClaim} setOneClaim={setOneClaim} setRouteActive={setRouteActive} />
        },
        {
            path:"/terms",
            element:<Terms setRouteActive={setRouteActive} />
        }
    ];


    return (
        <div className="./App.module">
            <HeadNavbar noti={notification} collap={onOffColl} setNotification={setNotification}/>
            <div className={styles.body_page}>
                <div className={styles.sidebar}>

                    {rolUser && rolUser.includes("RSK")  &&
                         <RskSidebar
                             setNewSurveyor={setNewSurveyor}
                             setNewCountryRate={setNewCountryRate}
                             setNewCompany={setNewCompany}
                             setNewExporter={setNewExporter}
                             setNewSpecie={setNewSpecie}
                             setNewCurrency={setNewCurrency}
                             setNewBilling={setNewBilling}
                             setNewDamages={setNewDamages}
                             setNewProfiles={setNewProfiles}
                             collapse={collapse}
                             routeActive={routeActive}
                             setRouteActive={setRouteActive}
                             setNewCobro={setNewCobro}
                             setNewBillingSurveyor={setNewBillingSurveyor}
                             // setNewPrivileges={setNewPrivileges}
                             setNewPago={setNewPago}
                             setNewUser={setNewUser}
                             setIsPago={setIsPago}
                             setSubMenuA={setSubMenuA}
                             subMenuA={subMenuA}
                         />
                    }
                    {rolUser === "ROLE_EXPORTER" &&
                         <SideBar collapse={collapse} routeActive={routeActive} setRouteActive={setRouteActive}/>
                    }
                </div>


                <Routes>
                    {rolUser && rolUser.includes("RSK") &&
                        <>
                        {routes.map((route, index) => {
                            if (rutas.includes(route.path)) {
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={route.element}
                                    />
                                )
                            }

                            if (rutas.includes("/invoiced")) {
                                if (route.path === "/invoiced/recovery" || route.path === "/invoiced/payment" || route.path === "/invoiced/surveyor" || route.path === "/invoiced/customers") {
                                    return (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            element={route.element}
                                        />
                                    )
                                }
                            }

                            if (rutas.includes("/all_claims")) {
                                if (route.path ==="/informe_inspeccion_all"){
                                    return (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            element={route.element}
                                        />
                                    )
                                }

                            }

                        })}
                        {/* <Route path="*" element={<Navigate replace to="/dashboard" />} /> */}
                    </>

                    }

                    {rolUser === "ROLE_EXPORTER" &&
                        <Fragment>
                            <Route path="/" element={<Home setStuff={setStuff} stuff={stuff} initStuffing={initStuffing} oneClaim={oneClaim}
                                                           setOneClaim={setOneClaim}/>} />
                            <Route path="/dashboard" element={<Home setStuff={setStuff} stuff={stuff} initStuffing={initStuffing}
                                                           oneClaim={oneClaim} setOneClaim={setOneClaim}/>}/>
                            <Route path="/stuffing" element={<StuffingReport stuff={stuff} setStuff={setStuff} setInitStuffing={setInitStuffing} initStuffing={initStuffing} />}/>
                            <Route path="/informe_inspeccion" element={<Claim stuff={stuff} setStuff={setStuff}
                                                                              oneClaim={oneClaim} setOneClaim={setOneClaim} setRouteActive={setRouteActive} />}/>
                            <Route path="/*" element={<NotFound/>}/>
                            <Route path="/all_claims" element={<Home setStuff={setStuff} stuff={stuff} initStuffing={initStuffing}/>} />
                            <Route path="/surveyors" element={<Home setStuff={setStuff} stuff={stuff} initStuffing={initStuffing}/>} />
                            <Route path="/companies" element={<Home setStuff={setStuff} stuff={stuff} initStuffing={initStuffing}/>} />

                        </Fragment>
                    }

                    {rolUser !== "ROLE_RSK" &&
                        <Fragment>
                            <Route path="/mantenedor_inspectores" element={<Forbidden/>}/>
                        </Fragment>
                    }
                    {rolUser !== "ROLE_EXPORTER" &&

                        <Fragment>
                            <Route path="/stuffing" element={<Forbidden/>}/>
                            <Route path="/informe_inspeccion" element={<Forbidden/>}/>
                        </Fragment>
                    }
                </Routes>
            </div>
        </div>
    );
}

export default App;
