export const ImageReport = ({claimForm}) => {
    let allImages = [];
    let allInspection = claimForm.inspection.inspectionReport;
    let finalArray = [];

    const takeImages = (obj, padre, abuelo, bisabuelo, frutaD1, frutaD2) => {
        let position = 0;
        let packageCodeItems = {};
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                takeImages(obj[key], obj, padre, abuelo, bisabuelo, frutaD1);
            }
            if (padre === undefined) {
                //console.log(padre)
            }
            if (obj.hasOwnProperty(key)) {
                const element = obj[key];
                if (/https:\/\/rsk/.test(element)) {
                    let imageName = key.replace(/([A-Z])/g, ' $1');
                    imageName = imageName.charAt(0).toUpperCase() + imageName.slice(1);

                    if (imageName === "Image" || imageName === "Image Url" || imageName === "Url") {
                        if (obj.description !== undefined) {
                            if (frutaD2.fruitName !== undefined) {
                                imageName = `${frutaD2.fruitName} ${obj.description} - (${bisabuelo.packageCode} - ${bisabuelo.packetLabelCode})`;
                            } else {
                                imageName = `${obj.description}`;
                            }
                        } else if (obj.damageDescription !== undefined) {
                            if (frutaD1.fruitName !== undefined) {
                                imageName = `${frutaD1.fruitName} ${obj.damageDescription} (${abuelo.packageCode} - ${abuelo.packetLabelCode})`;
                            } else {
                                imageName = `${obj.damageDescription}`;
                            }
                        }
                    }
                    if (imageName.includes("Url")) {
                        imageName = imageName.replace("Url", "");
                    }

                    let packetLabelCode = "";
                    let packageCode = "";
                    switch (imageName.trim()) {
                        case "":
                            imageName = `Brix (${abuelo.packageCode} - ${abuelo.packetLabelCode})`;
                            position = 6;
                            packetLabelCode = abuelo.packetLabelCode;
                            packageCode = abuelo.packageCode;
                            break;
                        case "Image Temperature":
                            imageName = `Temperature - ${obj.position} - ${obj.packageName}`;
                            position = 3;
                            packageCode = obj.packageName;
                            break;
                        case "Cargo Stored":
                            imageName = "How cargo was stored"
                            position = 0;
                            break;
                        case "Cargo Air Temperature":
                            imageName = "Air temperature of the room"
                            position = 1;
                            break;
                        case "Cargo Place":
                            imageName = "Picture of the place"
                            position = 2;
                            break;
                        case "Compressor":
                            imageName = "Compressor (Engine)"
                            position = 7;
                            break;
                        case "Label Packet":
                            imageName = `Box Label (${obj.packageCode} - ${obj.packetLabelCode})`
                            position = 8;
                            packetLabelCode = obj.packetLabelCode;
                            packageCode = obj.packageCode;
                            break;
                        case "Fullpacket":
                            imageName = `General Packaging Photo (${obj.packageCode} - ${obj.packetLabelCode})`
                            position = 4;
                            packetLabelCode = obj.packetLabelCode;
                            packageCode = obj.packageCode;
                            break;
                        case "Fruit Condition":
                            imageName = `Fruit Condition (${obj.packageCode} - ${obj.packetLabelCode}) `
                            position = 5;
                            packetLabelCode = obj.packetLabelCode;
                            packageCode = obj.packageCode;
                            break;
                        default:
                            imageName = imageName.trim();
                            position = 9;
                            if (abuelo) {
                                packetLabelCode = abuelo.packetLabelCode;
                                packageCode = abuelo.packageCode;
                            }
                            break;
                    }

                    if (element.includes("DAMAGE")) {
                        //console.log(element)
                    } else {
                        if (position > 3 && position < 10) {
                            if (packageCodeItems[packageCode] === undefined) {
                                packageCodeItems[packageCode] = [];
                            }
                            packageCodeItems[packageCode].push({
                                name: imageName,
                                image: element,
                                position,
                                packetLabelCode: packetLabelCode,
                                packageCode: packageCode,
                            });
                        } else {
                            allImages.push({
                                name: imageName,
                                image: element,
                                position,
                                packetLabelCode: packetLabelCode,
                                packageCode: packageCode,
                            });
                        }
                    }
                }
            }
        }

        for (const packageCode in packageCodeItems) {
            allImages.push({
                packageCode,
                position,
                item: packageCodeItems[packageCode],
            });
        }
        allImages.sort((a, b) => a.position - b.position);
    }

    takeImages(allInspection);

    if (allImages.length > 0) {
        const allImages2 = [];
        finalArray = allImages.map((image, index) => {
            if (image.item) {
                image.item.map((item, index) => {
                    if (allImages2.length === 0) {
                        allImages2.push({
                            packageCode: image.packageCode,
                            position: image.position,
                            item: [item],
                        });
                    } else {
                        let existe = false;
                        allImages2.map((item2, index) => {
                            if (item2.packageCode === image.packageCode) {
                                existe = true;
                                item2.item.push(item);
                            }
                        })
                        if (!existe) {
                            allImages2.push({
                                packageCode: image.packageCode,
                                position: image.position,
                                item: [item],
                            });
                        }
                    }
                })
            } else {
                return image;
            }
        })
        finalArray = finalArray.filter((item) => item !== undefined);
        allImages2.forEach((item, index) => {
            finalArray.push(item);
        });
        finalArray.forEach((image, index) => {
            return image.item ? image.item.sort((a, b) => a.position - b.position) : null;
        });
    }

    return (
        <>
            <div style={{
                height: "100%",
                display: "flex",
                justifyContent: 'space-around',
                flexWrap: "wrap",
                margin: '2rem 0',
                gap: '25px'
            }}>
                <h1 style={{textAlign: 'center', width: '100%'}}>Image Report</h1>
                {finalArray.map((image, index) => (
                    <>
                        {
                            image.item ? (
                                image.item.map((item, index) => (
                                    <div style={{height: '100%', width: '30%'}} key={index}>
                                        <a href={item.image} target="_blank" rel="noreferrer">
                                            <div className="image-report">
                                                <div style={{height: '2rem'}}></div>
                                                <div style={{height: 500}}>
                                                    <p style={{
                                                        textAlign: 'center',
                                                        textDecoration: 'none',
                                                        marginBottom: '.4rem'
                                                    }}>{item.name}</p>
                                                    <img src={item.image} alt={item.name}
                                                         style={{
                                                             border: '1px solid black',
                                                             height: '100%',
                                                             width: '100%',
                                                             objectFit: 'cover',
                                                             borderRadius: '3px'
                                                         }}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <div style={{height: '100%', width: '30%'}} key={index}>
                                    <a href={image.image} target="_blank" rel="noreferrer">
                                        <div className="image-report">
                                            <div style={{height: '2rem'}}></div>
                                            <div style={{height: 500}}>
                                                <p style={{
                                                    textAlign: 'center',
                                                    textDecoration: 'none',
                                                    marginBottom: '.4rem'
                                                }}>{image.name}</p>
                                                <img src={image.image} alt={image.name}
                                                     style={{
                                                         border: '1px solid black',
                                                         height: '100%',
                                                         width: '100%',
                                                         objectFit: 'cover',
                                                         borderRadius: '3px'
                                                     }}
                                                />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                    </>
                ))}
            </div>
        </>
    )
}
