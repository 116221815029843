import styles from "../documentos/documentos.module.css";
import {Col, Row, Form, Button, Spinner} from "react-bootstrap";
import axios from "axios";
import {urlConfig} from "../../../../config/urlConfig";
import DBContext from "../../../../context/DBContext";
import {useContext, useState} from "react";
import {FiDownload} from "react-icons/fi";
import LangContext from "../../../../context/LangContext";

export const Recupero = ({handleDocuments,claimForm,setClaimForm,filesClaim,imRsk}) => {
    const {session} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);
    const [loading, setLoading] = useState();


    let fecha = new Date().toISOString()
    const handleChange = (e) => {
        if (e.target.name === "comment") {
            //console.log(e.target.name)
            setClaimForm({...claimForm,
                commentName:nombre,
                commentDate: fecha,
                comment:e.target.value
            });
        }else{
            setClaimForm({
                ...claimForm,
                [e.target.name]: e.target.value
            })

        }
    }

    const handlePDF = (e) => {
        setLoading(true)
        let token = localStorage.getItem("token");
        let pre = '';
        const headers = {
            responseType: 'arraybuffer',
            headers: {
                'Content-Disposition': 'attachment',
                Authorization: token
            }
        }
        if (!imRsk){

            pre = 'me/'
        }
        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${pre}letter/${claimForm.id}`,{}, headers)
            .then((res) => {
                const url = URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'archivo.pdf');
                document.body.appendChild(link);
                link.click();
                setLoading(false)
            })
            .catch((error) => {
                //console.log(error);
                setLoading(false)
            })

    }

    //encontrar el nombre de usuario en un token
    const nombre = session.legalRepresentative===null
        ? session.firstName + " " + session.lastName
        : session.legalRepresentative;

    const downloadFile = (bytes, fileName) => {
        const blob = new Blob ([bytes], {type:"application/pdf"})   ;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click()
    }

    const descargaUno = (filename) => {
        axios({
            url: `${urlConfig.allUrl}:9004/api/v1/files/download?file=${filename}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        }).then((response) => {
            downloadFile(response.data, filename.split("_")[0])
        }).catch((error) => {
            //console.log(error);
        });
    }

    return (
        <>

            <div className={styles.container3} style={{padding:'2rem', margin:'3rem'}}>
            <div className={styles.title} style={{fontWeight:'bold'}}>{traduccion?.exporter?.documentacion}</div>
                <Row>
                    <Col sm={12} lg={6} xl={4}>
                        <div className={styles.title}>{traduccion?.exporter?.finiquito}</div>
                        {filesClaim.settlement ? <div style={{fontSize:13, marginTop:-10}}>{filesClaim.settlement}</div>:<div style={{height:13}}></div>}
                        <Form.Group controlId="type1"  style={{display:'flex'}}>
                           <Form.Control name="settlement" disabled={!imRsk} className={!imRsk? styles.file_input_disabled  :filesClaim.settlement ? styles.file_input2:styles.file_input } type="file" onChange={handleDocuments} />
                                    {/^SETTLEMENT_/.test(filesClaim.settlement) && <div className={styles.button_des} onClick={()=>descargaUno(filesClaim.settlement)}><FiDownload/></div>}
                        </Form.Group>
                            <div className="mb-3">{traduccion?.exporter?.soloArchivos}</div>
                    </Col>
                    <Col sm={12} lg={6} xl={4}>
                        <div className={styles.title}>{traduccion?.exporter?.comprobante}</div>
                        {filesClaim.receipt ? <div style={{fontSize:13, marginTop:-10}}>{filesClaim.receipt}</div> :<div style={{height:12}}></div>}
                        <Form.Group controlId="type2" style={{display:'flex'}}>
                            <Form.Control name="receipt"  disabled={!imRsk} className={!imRsk? styles.file_input_disabled  :filesClaim.receipt ? styles.file_input2:styles.file_input }  onChange={handleDocuments} type="file" />
                                {/^RECEIPT_/.test(filesClaim.receipt) && <div className={styles.button_des} onClick={()=>descargaUno(filesClaim.receipt)}><FiDownload/></div>}
                        </Form.Group>
                            <div className="mb-3">{traduccion?.exporter?.soloArchivos}</div>
                    </Col>


                    {/*Fecha*/}
                    <Col sm={12} lg={4}>
                        <div className={styles.title}>{traduccion?.exporter?.fechadepago}</div>
                        <div style={{fontSize:13,height:16}}> </div>
                            <Form.Group  style={{width:'20ch'}} controlId="type3" >
                            <Form.Control disabled={!imRsk} className={imRsk? styles.selects_input:styles.selects_input_disabled} name="paymentDate" type="date" onChange={handleChange} value={claimForm?.paymentDate?.split("T")[0]}/>
                        </Form.Group>
                            {/*<div className="mb-3 mt-2">{traduccion?.exporter?.soloArchivos}</div>*/}
                    </Col>
                </Row>
            </div>
            <div style={{margin:'3rem'}}>
                <Row style={{padding:0,margin:0,display:'flex',justifyContent:'space-between'}}>
                    <Col className={styles.container3} style={{padding:'1.5rem'}} sm={12} md={5} xl={4} >
                    <div className={styles.title} style={{fontWeight:'bold'}}>Time Extension</div>
                        <div className={styles.title} >{traduccion?.exporter?.timeExtension}</div>
                        <Form.Group controlId="type1" className="mb-3">
                            <Form.Control disabled={!imRsk} className={imRsk? styles.selects_input:styles.selects_input_disabled} name="timeBar" type="date" onChange={handleChange} value={claimForm?.timeBar?.split("T")[0]} />
                        </Form.Group>
                    </Col>

                    <Col style={{height:'2rem'}} sm={0} md={2}>
                    </Col>
                    <Col className={styles.container3} style={{padding:'1.5rem'}} sm={12} md={5} xl={4} >
                        <div className={styles.title} style={{fontWeight:'bold'}}>{traduccion?.exporter?.cartaReclamo}</div>
                        <Button style={{margin:'.5rem 0'}} onClick={handlePDF} disabled={loading}>
                            {loading && <Spinner animation="border" size="sm" />}
                            {!loading && traduccion?.exporter?.verCartadeReclamo}
                        </Button>
                        <div className={styles.title}>{traduccion?.exporter?.fechaDeReclamo}</div>
                        <Form.Group  style={{width:'20ch'}} controlId="type1" className="mb-3">
                            <Form.Control disabled={!imRsk} className={imRsk? styles.selects_input:styles.selects_input_disabled}  name="claimDate" type="date"  onChange={handleChange} value={claimForm?.claimDate?.split("T")[0]} />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
                <div className={styles.container3} style={{padding:'1rem 2rem 2rem 2rem', margin:'1rem 3rem'}}>
                    <div className={styles.title} style={{fontWeight:'bold'}}>{traduccion?.exporter?.segui}</div>
                    <Col className={styles.container3} style={{padding:' 0 .8rem', marginTop:'.5rem'}} sm={12}>
                        {claimForm?.comment ?
                        <div className={styles.title} style={{color:'gray'}}><span className={styles.svg_icon} style={{marginRight:'.5rem',verticalAlign:'text-top'}}></span>Comentado el:<span> {claimForm?.commentDate?.split("T")[0]}</span>{claimForm?.commentName ? `, por ${claimForm.commentName}` : "." }</div>
                        :<div style={{padding:'.5rem 0'}}>{traduccion?.exporter?.insertenuevoComentario}</div>
                        }
                        <Form.Group  style={{width:'100%', height:'150px', margin:0}} controlId="type1" className="mb-3">
                            <Form.Control name="comment" as="textarea" style={{height:'100%'}} onChange={handleChange} value={claimForm?.comment} />
                        </Form.Group>
                    </Col>

                </div>
            </>
    )
}
