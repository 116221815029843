import styles from "./filtros.module.css"
import {Button, Form} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import LangContext from "../../../context/LangContext";
import {useParamsUrl} from "../../mantenedores/useParamsUrl";
import {urlConfig} from "../../../config/urlConfig";
import axios from "axios";
import {toast} from "react-toastify";
import DBContext from "../../../context/DBContext";


export const FiltroCasosReclamados = ({searchClaims}) => {
    const {claim, setClaim, cambioClaims, setCambioClaims} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);

    const {searchUrl, handleSearch} = useParamsUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me/search`);

    const search = () => {
        searchClaims(searchUrl);
    }

    //Status en el filtro
    const [status, setStatus] = useState([
        {
            id: 1,
            name: "New",
        },
        {
            id: 2,
            name: "Survey Report"
        },
        {
            id: 3,
            name: "Pending documents"
        },
        {
            id: 4,
            name: "Ready to Claim"
        },
        {
            id: 5,
            name: "Closed"
        }
    ]);

    /*
    const searchClaims = () => {
        setActive(0);
        let newUrl = `${searchUrl}&size=${perPage}&page=${0}`;
        setUrl(newUrl);
        return axios.get(newUrl, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            const {claims} = res.data;
            //console.log({claims})
            setClaim(claims);
        }).catch((error) => {
            //console.log(error);
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        });
    }*/

    return (
        <>
            <div className={styles.filtro_total}>
                <p className={styles.title_claimed}>{traduccion.claimed?.lastClaim}</p>
                <div className={styles.filtros_busqueda}>
                    <p className={styles.title_filter}>{traduccion.claimed?.filtro}</p>
                    <div className={styles.opciones_busqueda}>
                        <Form>
                            <div style={{display: 'inline'}}>
                                <label htmlFor="container_select">{traduccion.claimed?.NContainer}</label>
                                <Form.Group controlId="container">
                                    <Form.Control type="text" placeholder="RSKXXXXXXX" onChange={handleSearch}
                                                  name="container"/>
                                </Form.Group>
                            </div>
                            <div>
                                <Form.Group controlId="vessel">
                                    <label htmlFor="nave_select">{traduccion.claimed?.nave}</label>
                                    <Form.Control type="text" placeholder="VESSEL NAME" onChange={handleSearch}
                                                  name="vessel"/>
                                </Form.Group>
                            </div>
                            <div>
                                <label htmlFor="estado">{traduccion.claimed?.estado}</label>

                                <Form.Select id="estado" onChange={handleSearch} name="status">
                                    <option value="">-</option>
                                    {
                                        status.length > 0 ?
                                            status.map((item, idx) =>
                                                <option key={idx} value={item.id}>{item.name}</option>
                                            ) : null
                                    }
                                </Form.Select>

                            </div>
                            <Button className={styles.boton_filtro} variant="primary" onClick={search}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                        fill="white"/>
                                </svg>
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>

        </>
    )
}
