import styles from "../../home/table_stuffing/tabla_stuff.module.css";
import {Table} from "react-bootstrap";
import {BsPencil, BsTrash} from "react-icons/bs";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import React, {useContext, useEffect, useRef, useState} from "react";
import DBContext from "../../../context/DBContext";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import LangContext from "../../../context/LangContext";

export const TablaCompanias = ({setNewCompany, setOneCompany, setEditCompanySurveyor, searchCompanySurveyorSort}) => {
    const {company, setCambioCompany, cambioCompany} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);

    const initialNameOrder = useRef(true);
    const initialPeriodOrder = useRef(true);
    const initialEmailOrder = useRef(true);
    const initialStatusOrder = useRef(true);

    const [nameOrder, setNameOrder] = useState(true);
    const [periodOrder, setPeriodOrder] = useState(true);
    const [emailOrder, setEmailOrder] = useState(true);
    const [statusOrder, setStatusOrder] = useState(true);

    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/company_surveyor/search?page=0&size=${20}`);

    useEffect(() => {
        if (initialNameOrder.current) {
            initialNameOrder.current = false;
            return;
        }
        if (!nameOrder) {
            if (!url.includes("sort=cn,desc") && url.includes("sort=cn,asc")) {
                setUrl(url.replace("&sort=cn,asc", "&sort=cn,desc"));
            } else {
                setUrl(url + "&sort=cn,desc");
            }
        } else {
            if (!url.includes("sort=cn,asc") && url.includes("sort=cn,desc")) {
                setUrl(url.replace("&sort=cn,desc", "&sort=cn,asc"))
            } else {
                setUrl(url + "&sort=cn,asc");
            }
        }
    }, [nameOrder]);

    useEffect(() => {
        if (initialPeriodOrder.current) {
            initialPeriodOrder.current = false;
            return;
        }
        if (!periodOrder) {
            if (!url.includes("sort=sp,desc") && url.includes("sort=sp,asc")) {
                setUrl(url.replace("&sort=sp,asc", "&sort=sp,desc"));
            } else {
                setUrl(url + "&sort=sp,desc");
            }
        } else {
            if (!url.includes("sort=sp,asc") && url.includes("sort=sp,desc")) {
                setUrl(url.replace("&sort=sp,desc", "&sort=sp,asc"))
            } else {
                setUrl(url + "&sort=sp,asc");
            }
        }
    }, [periodOrder]);

    useEffect(() => {
        if (initialEmailOrder.current) {
            initialEmailOrder.current = false;
            return;
        }
        if (!emailOrder) {
            if (!url.includes("sort=em,desc") && url.includes("sort=em,asc")) {
                setUrl(url.replace("&sort=em,asc", "&sort=em,desc"));
            } else {
                setUrl(url + "&sort=em,desc");
            }
        } else {
            if (!url.includes("sort=em,asc") && url.includes("sort=em,desc")) {
                setUrl(url.replace("&sort=em,desc", "&sort=em,asc"))
            } else {
                setUrl(url + "&sort=em,asc");
            }
        }
    }, [emailOrder]);

    useEffect(() => {
        if (initialStatusOrder.current) {
            initialStatusOrder.current = false;
            return;
        }
        if (!statusOrder) {
            if (!url.includes("sort=st,desc") && url.includes("sort=st,asc")) {
                setUrl(url.replace("&sort=st,asc", "&sort=st,desc"));
            } else {
                setUrl(url + "&sort=st,desc");
            }
        } else {
            if (!url.includes("sort=st,asc") && url.includes("sort=st,desc")) {
                setUrl(url.replace("&sort=st,desc", "&sort=st,asc"))
            } else {
                setUrl(url + "&sort=st,asc");
            }
        }
    }, [statusOrder]);

    useEffect(() => {
        searchCompanySurveyorSort(url);
    }, [url])

    const editCompanySurveyor = (id) => {
        setEditCompanySurveyor(true);
        //get the token from the local storage
        let token = localStorage.getItem("token");
        const noBearerToken = token.toString().replace("Bearer ", "")
        let header = {
            headers: {
                Authorization: noBearerToken,
            }
        }
        axios.get(`${urlConfig.companyUrl}${id}`, header)
            .then((response) => {
                let dataRes = response.data
                if (response.data.bankInformation.length === 0){
                    dataRes.bankInformation = [{
                        "accountNumber": "",
                        "bankName": "",
                        "bankAddress": "",
                        "swiftCode": "",
                        "ibanCode": "",
                        "bankHolderName": "",
                        "bankHolderAddress": "",
                        "bicCode": "",
                        "currency": 0,
                        "area_id": 0,
                        "areasEntity": {
                            "id": 0,
                        }
                    }]
                }
                //console.log(dataRes)
                setOneCompany(dataRes);
                setNewCompany(1)
            }).catch((error) => {
            //console.log(error);
        })
    }
    const deleteCompanySurveyor = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        axios.delete(`${urlConfig.companyUrl}${id}`, header)
            .then((response) => {
                setCambioCompany(!cambioCompany);
            }).catch((error) => {
            //console.log(error);
        })
    }

    const sortParams = (sort) => {
        if (sort === "cn") {
            setNameOrder(!nameOrder);
        }
        if (sort === "sp") {
            setPeriodOrder(!periodOrder);
        }
        if (sort === "em") {
            setEmailOrder(!emailOrder);
        }
        if (sort === "st") {
            setStatusOrder(!statusOrder);
        }
    }

    return (
        <>
            <div className={styles.contiene_tabla}>
                <Table borderless hover className={styles.tabla}>
                    <thead>
                    <tr>
                        <th>{traduccion?.clients?.name} <a onClick={() => sortParams("cn")}> {nameOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.clients?.pMail} <a onClick={() => sortParams("em")}> {emailOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.clients?.status} <a onClick={() => sortParams("st")}> {statusOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th></th>
                    </tr>

                    </thead>
                    <tbody>

                    {
                        company.length > 0 ?
                            company.map((datos, idx) =>
                                <tr key={idx} className={stylesTabla.tr_data}>
                                    <td>{datos.companyName}</td>
                                    <td>{datos.email}</td>
                                    <td>{datos.status ? `${traduccion?.mantendores?.activo}`:`${traduccion?.mantendores?.inactivo}`}</td>
                                    <td>
                                        <div className={stylesTabla.botones_botones_surveyor} style={{display: 'flex'}}>
                                            <div
                                                onClick={() => editCompanySurveyor(datos.id)}
                                                className={stylesTabla.boton_icono}>
                                                <BsPencil/>
                                            </div>
                                            <div
                                                onClick={() => deleteCompanySurveyor(datos.id)}
                                                className={stylesTabla.boton_icono}>
                                                <BsTrash style={{color: 'red'}}/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ):
                            <tr>
                                <td colSpan="4" style={{textAlign: "center"}}>{traduccion?.factura?.noData}</td>
                            </tr>
                    }
                    </tbody>
                </Table>

            </div>
        </>
    )
}
