import {GoogleMap, useLoadScript, Marker, DirectionsRenderer, Circle, Polyline} from "@react-google-maps/api";
import React, {useContext, useEffect, useState, useRef} from "react";
import axios from "axios";
import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";
import {Typeahead} from "react-bootstrap-typeahead";
import LangContext from "../../../../../context/LangContext";
import {Form} from "react-bootstrap";
import {urlConfig} from "../../../../../config/urlConfig";

export const Mapa = ({
                         surveyorList, setSurveyorList,
                         claimForm, setClaimForm,
                         pag,
                         distance, setDistance,
                         oneSurveyor, setOneSurveyor,
                         setDireccion, direccion, setAddressForm,
                         onlySurveyor, range, setRange,
                         mapCode, setMapCode, addressForm, setDireccionEscrita, direccionEscrita
                     }) => {
    const [libraries] = useState(['places']);
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_APIKEY_GOOGLE,
        libraries
    });

    if (!isLoaded) return <div>Loading...</div>;
    return (
        <>
            <Map direccion={direccion} setDireccion={setDireccion}
                 setAddressForm={setAddressForm}
                 pag={pag}
                 distance={distance}
                 setDistance={setDistance}
                 isLoaded={isLoaded}
                 oneSurveyor={oneSurveyor} setOneSurveyor={setOneSurveyor}
                 claimForm={claimForm} setClaimForm={setClaimForm}
                 surveyorList={surveyorList} setSurveyorList={setSurveyorList}
                 range={range} setRange={setRange}
                 onlySurveyor={onlySurveyor}
                 setMapCode={setMapCode}
                 mapCode={mapCode}
                 addressForm={addressForm}
                 setDireccionEscrita={setDireccionEscrita}
                 direccionEscrita={direccionEscrita}
            />
        </>
    )
}

const Map = (props) => {
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [address, setAddress] = useState({lat: 0, lng: 0});
    const [inicio, setInicio] = useState({lat: 0, lng: 0});
    const [markerAnim, setMarkerAnim] = useState(2);
    const {traduccion} = useContext(LangContext);
    const [zoomMap, setZoomMap] = useState(10);
    const [lastSentLocation, setLastSentLocation] = useState(null);
    const [routeError, setRouteError] = useState(null); // Estado para mostrar errores de ruta
    const [polylinePath, setPolylinePath] = useState(null); // Para la ruta alternativa (Polyline)

    // Estado para el centro del mapa
    const [mapCenter, setMapCenter] = useState({ lat: 51.5074, lng: -0.1278 }); // Inicialmente Londres (puede ser cualquier lugar)

    useEffect(() => {
        if (props.addressForm?.lat === 0 && props.addressForm?.lng === 0) {
            setAddress({lat: 51.50742726621612, lng: -0.08603244575278834});
            setInicio({lat: 51.50742726621612, lng: -0.08603244575278834});
        } else if (props.addressForm) {
            setAddress(props.addressForm);
            setInicio(props.addressForm);
        }
    }, []);

    useEffect(() => {
        if (props.pag === 1 && props?.claimForm) {
            const datos = {
                longitude: address.lng,
                latitude: address.lat
            };
            if (lastSentLocation && lastSentLocation.lat === address.lat && lastSentLocation.lng === address.lng) {
                return;
            }
            props.setClaimForm({...props.claimForm, ...datos});
            const token = localStorage.getItem("token");
            axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/inspector/location`, datos, {
                headers: {
                    Authorization: token?.toString().replace("Bearer ", "")
                }
            })
                .then((res) => {
                    props.setSurveyorList(res.data);
                }).catch((error) => {
                console.log(error);
            });
        }
        setLastSentLocation({lat: address.lat, lng: address.lng});
    }, [address, lastSentLocation]);

    const calculateRoute = () => {
        const directionsService = new window.google.maps.DirectionsService();

        // Intentar primero en auto
        directionsService.route(
            {
                origin: {lat: props.oneSurveyor.latitude, lng: props.oneSurveyor.longitude},
                destination: {lat: props.claimForm.latitude, lng: props.claimForm.longitude},
                travelMode: window.google.maps.TravelMode.DRIVING,
                drivingOptions: {
                    departureTime: new Date(Date.now()),
                    trafficModel: 'pessimistic'
                }
            },
            (result, status) => {
                if (status === 'OK') {
                    props.setOneSurveyor({
                        ...props.oneSurveyor,
                        distance_m: result.routes[0].legs[0].distance.value,
                        duration_m: result.routes[0].legs[0].duration_in_traffic.value
                    });
                    setDirectionsResponse(result);
                    props.setDistance(result.routes[0].legs[0]);
                    setRouteError(null); // Limpiar errores previos
                    setPolylinePath(null); // No se necesita Polyline si hay una ruta disponible

                    // Centrar el mapa en la ruta calculada
                    setMapCenter({ lat: props.claimForm.latitude, lng: props.claimForm.longitude });
                } else {
                    console.error(`Error fetching directions: ${status}`);

                    // Si falla, intentar a pie
                    directionsService.route(
                        {
                            origin: {lat: props.oneSurveyor.latitude, lng: props.oneSurveyor.longitude},
                            destination: {lat: props.claimForm.latitude, lng: props.claimForm.longitude},
                            travelMode: window.google.maps.TravelMode.WALKING
                        },
                        (resultWalking, statusWalking) => {
                            if (statusWalking === 'OK') {
                                props.setOneSurveyor({
                                    ...props.oneSurveyor,
                                    distance_m: resultWalking.routes[0].legs[0].distance.value,
                                    duration_m: resultWalking.routes[0].legs[0].duration.value
                                });
                                setDirectionsResponse(resultWalking);
                                props.setDistance(resultWalking.routes[0].legs[0]);
                                setRouteError(null);
                                setPolylinePath(null); // No se necesita Polyline si hay una ruta disponible

                                // Centrar el mapa en la ruta calculada
                                setMapCenter({ lat: props.claimForm.latitude, lng: props.claimForm.longitude });
                            } else {
                                console.error(`Error fetching walking directions: ${statusWalking}`);

                                // Si falla, intentar en transporte público
                                directionsService.route(
                                    {
                                        origin: {lat: props.oneSurveyor.latitude, lng: props.oneSurveyor.longitude},
                                        destination: {lat: props.claimForm.latitude, lng: props.claimForm.longitude},
                                        travelMode: window.google.maps.TravelMode.TRANSIT
                                    },
                                    (resultTransit, statusTransit) => {
                                        if (statusTransit === 'OK') {
                                            props.setOneSurveyor({
                                                ...props.oneSurveyor,
                                                distance_m: resultTransit.routes[0].legs[0].distance.value,
                                                duration_m: resultTransit.routes[0].legs[0].duration.value
                                            });
                                            setDirectionsResponse(resultTransit);
                                            props.setDistance(resultTransit.routes[0].legs[0]);
                                            setRouteError(null);
                                            setPolylinePath(null); // No se necesita Polyline si hay una ruta disponible

                                            // Centrar el mapa en la ruta calculada
                                            setMapCenter({ lat: props.claimForm.latitude, lng: props.claimForm.longitude });
                                        } else {
                                            console.error(`Error fetching transit directions: ${statusTransit}`);

                                            // Fallback: Mostrar una línea entre el origen y el destino usando Polyline
                                            const fallbackPath = [
                                                { lat: props.oneSurveyor.latitude, lng: props.oneSurveyor.longitude },
                                                { lat: props.claimForm.latitude, lng: props.claimForm.longitude }
                                            ];
                                            setPolylinePath(fallbackPath); // Almacenar la ruta alternativa en Polyline

                                            setRouteError(`No se pudo calcular la ruta en auto, a pie ni en transporte público: ${statusTransit}. Coordenadas de origen: ${props.oneSurveyor.latitude}, ${props.oneSurveyor.longitude}. Coordenadas de destino: ${props.claimForm.latitude}, ${props.claimForm.longitude}`);

                                            // Centrar el mapa en el destino o en el inspector
                                            setMapCenter({ lat: props.claimForm.latitude, lng: props.claimForm.longitude });
                                        }
                                    }
                                );
                            }
                        }
                    );
                }
            }
        );
    };

    useEffect(() => {
        if (props.setClaimForm) {
            props.setClaimForm({
                ...props.claimForm,
                distance: props.distance?.distance.value
            });
        }
    }, [props.distance]);

    useEffect(() => {
        if (props.pag === 2) {
            calculateRoute();
        }
    }, [props.pag]);

    const startAnim = () => {
        setMarkerAnim(1);
    };
    const endAnim = (e) => {
        setMarkerAnim(2);
        nuevoLocation(e);
    };
    const clickAnim = async () => {
        await setMarkerAnim(4);
        setMarkerAnim(2);
    };

    function showPosition(position) {
        let addObj = {lat: position.coords.latitude, lng: position.coords.longitude};
        setAddress(addObj);
        if (props.setAddressForm) {
            props.setAddressForm(addObj);
        }
        setInicio(addObj);
    }

    function changePosition(position) {
        if (props.setAddressForm) {
            props.setAddressForm(position);
        }
        setAddress(position);
        setInicio(position);
    }

    useEffect(() => {
        getLocation();
    }, []);

    function getLocation() {
        if ((props.pag === 1 && !props.addressForm) || (window.location.href.includes("surveyors") && (props.addressForm?.lat === 0 && props.addressForm?.lng === 0))) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            }
        }
    }

    const handleSelectReverse = async (address) => {
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${address.lat},${address.lng}&key=${process.env.REACT_APP_APIKEY_GOOGLE}`)
            .then((res) => {
                let codePaisArr;
                if (res.data.results[res.data.results.length - 1].address_components[0].types.findIndex((e) => e === "country") !== -1) {
                    codePaisArr = res.data.results[res.data.results.length - 1].address_components[0].short_name;
                } else {
                    codePaisArr = res.data.results[res.data.results.length - 2].address_components[0].short_name;
                }
                if (props.setMapCode) {
                    props.setMapCode(codePaisArr);
                }
                if (props.setDireccion) {
                    props.setDireccion(res.data.results[0].formatted_address);
                }
                if (props.pag === 1) {
                    props.setDireccionEscrita(res.data.results[0].formatted_address);
                }
                return res.data.results[0].formatted_address;
            }).catch((error) => {
            console.log({error});
        });
    };

    const nuevoLocation = (e) => {
        let addObj = {lat: e.latLng.lat(), lng: e.latLng.lng()};
        handleSelectReverse(addObj);
        setAddress(addObj);
        if (props.setAddressForm) {
            props.setAddressForm(addObj);
        }
        clickAnim();
    };

    useEffect(() => {
        switch (true) {
            case props.range > 0 && props.range <= 3000:
                setZoomMap(13);
                break;
            case props.range > 3000 && props.range <= 30000:
                setZoomMap(10);
                break;
            case props.range > 30000 && props.range <= 60000:
                setZoomMap(9);
                break;
            case props.range > 60000 && props.range <= 100000:
                setZoomMap(8);
                break;
            case props.range > 100000 && props.range <= 230000:
                setZoomMap(7);
                break;
            case props.range > 230000 && props.range <= 400000:
                setZoomMap(6);
                break;
            case props.range > 400000 && props.range <= 900000:
                setZoomMap(5);
                break;
            case props.range > 900000 && props.range <= 1600000:
                setZoomMap(4);
                break;
            case props.range > 1600000:
                setZoomMap(1);
                break;
            default:
                setZoomMap(13);
        }
    }, [props.range]);

    const handleRangeChange = (e) => {
        const rangeValue = e.target.value;
        if (!isNaN(rangeValue)) {
            props.setRange(rangeValue * 1000);
        }
    };

    return (
        <>
            {routeError && (
                <div className="alert alert-warning" role="alert">
                    {routeError}. Por favor, intenta con otra dirección o modo de viaje.
                </div>
            )}
            {props.pag === 1 &&
                <PlacesAutocomplete changePosition={changePosition} setMapCode={props?.setMapCode}
                                    setDireccion={props.setDireccion} setZoomMap={setZoomMap}
                                    direccion={props.direccion} onlySurveyor={props.onlySurveyor}
                                    traduccion={traduccion} setDireccionEscrita={props.setDireccionEscrita}
                                    direccionEscrita={props.direccionEscrita}
                                    setMapCenter={setMapCenter} // Añadimos la función para cambiar el centro del mapa
                />
            }
            {props.onlySurveyor &&
                <div style={{marginBottom: 20}}>
                    <div>Operation Range</div>
                    <Form.Control
                        type="text"
                        placeholder="Enter range"
                        value={props.range / 1000}
                        onChange={handleRangeChange}
                    />
                </div>
            }
            <GoogleMap
                zoom={zoomMap}
                mapContainerStyle={{ height: "600px", maxHeight: "50vh", width: "100%" }}
                center={mapCenter} // Aquí usamos el centro calculado
                onClick={(e) => nuevoLocation(e)}
            >
                {props.pag === 1 &&
                    <>
                        <Marker
                            clickable={true}
                            position={address}
                            animation={markerAnim}
                            draggable={true}
                            onDragEnd={endAnim}
                            title={props.direccion}
                            onDragStart={startAnim}
                        />
                        {props.onlySurveyor && (
                            <Circle
                                center={address}
                                radius={props.range}
                                options={{
                                    fillColor: "#003cff",
                                    fillOpacity: 0.2,
                                    strokeColor: "#003cff",
                                    strokeOpacity: 1,
                                    strokeWeight: 2,
                                }}
                            />
                        )}
                    </>
                }
                {directionsResponse && (
                    <DirectionsRenderer
                        options={{suppressMarkers: true}}
                        directions={directionsResponse}
                    />
                )}

                {/* Si no hay directionsResponse pero hay Polyline, se dibuja la línea entre los puntos */}
                {polylinePath && (
                    <Polyline
                        path={polylinePath}
                        options={{
                            strokeColor: '#FF0000',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                        }}
                    />
                )}
            </GoogleMap>
        </>
    );
};

const PlacesAutocomplete = ({
                                changePosition,
                                setDireccion,
                                direccion,
                                onlySurveyor,
                                traduccion,
                                setMapCode,
                                setZoomMap,
                                setDireccionEscrita,
                                direccionEscrita,
                                setMapCenter // Añadimos setMapCenter para cambiar el centro del mapa
                            }) => {

    const {
        ready,
        value,
        setValue,
        suggestions: {status, data},
        clearSuggestions,
    } = usePlacesAutocomplete();

    const refContainer = useRef(null);

    useEffect(() => {
        if (refContainer.current !== null) {
            refContainer.current.innerHTML = direccion;
        }
    }, [direccion]);

    if (value !== "" && value !== undefined && value !== null && setDireccionEscrita !== undefined) {
        setDireccionEscrita(value);
    }

    const handleSelect2 = async (address) => {
        if (address[0]) {
            address = address[0].description;
            setValue(address, false);
            clearSuggestions();
            if (setDireccion) {
                setDireccion(address);
                setZoomMap(13);
            }

            const results = await getGeocode({address});
            let codePaisArr;
            for (let i = 1; i <= results[0].address_components.length; i++) {
                if (results[0].address_components[results[0].address_components.length - i].types.findIndex((e) => e === "country") !== -1) {
                    codePaisArr = results[0].address_components[results[0].address_components.length - i].short_name;
                    break;
                } else {
                    setMapCode("INVALID");
                }
            }

            if (setMapCode) {
                setMapCode(codePaisArr);
            }

            const {lat, lng} = await getLatLng(results[0]);
            changePosition({lat, lng});

            // Centramos el mapa en la nueva dirección seleccionada
            setMapCenter({lat, lng});
        }
    };

    return (
        <>
            <div>{traduccion.inspector?.buscarDireccion}</div>
            <Typeahead
                style={{marginBottom: '1rem'}}
                id="basic-typeahead"
                labelKey={option => `${option.description}`}
                onChange={e => handleSelect2(e)}
                onInputChange={e => setValue(e)}
                placeholder={direccion ?? direccionEscrita ?? "Search an address"}
                options={data.map(({place_id, description}) => ({place_id: place_id, description: description}))}
                filterBy={() => true}
            />
        </>
    );
};
