import {Col, Row} from "react-bootstrap";
import styles from './tres.module.css';
import image from './fondoHome.png';
import logo from './logoRSK.png';
import logoClaims from './BOXClaims.png';
import logoStuffing from './BOXStuffing.png';
import logoBOX from './BoxLogo.png';

import naranja from './naranja.png';
import kiwi from './kiwi.png';
import cereza from './cereza.png'
import React, {useEffect, useState} from "react";
import {Login} from "./Login";
import useWindowSize from "../../hooks/useWindowSize";
export const TresModulos = ({setLanding,landing}) => {
    const [video, setVideo] = useState(true);
    const handleClickProject =()=>{
        setLanding(false)
    }
    const handleClickStuff =()=>{
        window.location.href = "http://claims.rskgroup.cl/";
    }
    const handleClickCompany=()=>{
        window.location.href = "./company.html";
    }
    const handleClickClosePanelFromOutside = (e) => {
        if (e.target.className !== "video_div") {
            setVideo(false);
        }
    }
    useEffect(() => {
        if (video) {
            document.body.addEventListener("click", handleClickClosePanelFromOutside)
            // document.body.addEventListener("keydown", handleClickClosePanelFromOutside)
        }
        return () => {
            document.body.removeEventListener("click", handleClickClosePanelFromOutside)
            // document.body.removeEventListener("keydown", handleClickClosePanelFromOutside)
        }
    }, [video])
    const { height, width } = useWindowSize();

    return (
        <>
            {
                landing ?
                    <div className={styles.all} style={{backgroundImage: `url(${image})`,backgroundSize: "cover"}}>
                        <div style={{height: '15%'}}></div>
                        <div style={{height: '20%', display: "flex", justifyContent: 'center'}}>

                            <div style={{width: '100vw'}}>
                                <img style={{height: '100%', display: 'block', margin: 'auto'}} src={logoBOX} alt="logo"/>
                            </div>
                        </div>
                        {video&&
                            <div id="video_div" style={{backdropFilter:"brightness(60%) blur(2px)",position:"absolute",display:"flex", justifyContent:"center",alignItems:"center",top:0,bottom:0,left:0,right:0}}>
                            <iframe allow="fullscreen;" width="55%" height="61.2%%"
                                    src="https://www.youtube.com/embed/g8p2SD4NsMc?autoplay=1&mute=1&enablejsapi=1&loop=1">
                            </iframe>
                            </div>
                        }
                        <div className={styles.cardCont}>
                                <div className={styles.card} onClick={handleClickStuff} >
                                    <img style={{alignSelf: "center",  height: '70%', width:'100%', margin: 'auto',objectFit:"contain"}} src={naranja}
                                         alt="naranja"/>
                                    {width < 788 &&
                                        <div style={{fontSize: 23}}>Stuffing Report</div>
                                    }

                                    <img style={{width:'100%', height:'auto', objectFit:'contain'}} src={logoStuffing} alt="logo stuffing"/>
                                </div>
                            <div className={styles.card} onClick={handleClickProject}>
                                <img style={{alignSelf: "center",  height: '70%', width:'100%', margin: 'auto',objectFit:"contain"}} src={kiwi}  alt="kiwi"/>
                                {width < 788 &&

                                    <div style={{fontSize: 23}}>Claims</div>
                                }
                                <img style={{width:'100%', height:'auto', objectFit:'contain'}} src={logoClaims} alt="logo claims"/>

                            </div>
                            <div className={styles.card} onClick={handleClickCompany} >
                                <img style={{alignSelf: "center",  height: '70%', width:'100%', margin: 'auto',objectFit:"contain"}} src={cereza}
                                     alt="cereza"/>
                                {width < 788 &&
                                    <div style={{fontSize: 23}}>Our Company</div>
                                }
                                <img style={{width:'100%', height:'auto', objectFit:'contain'}} src={logo} alt="logo claims"/>

                            </div>
                        </div>
                        {/*<Row className={styles.fila}>*/}
                        {/*    <Col style={{backgroundColor: '#E68E3D'}}></Col>*/}
                        {/*    <Col style={{backgroundColor: '#86BC59'}}></Col>*/}
                        {/*    <Col style={{backgroundColor: '#7671AE'}}></Col>*/}
                        {/*</Row>*/}
                    </div>

                    :<Login setLanding={setLanding} landing={landing}/>
            }
        </>
    )
}