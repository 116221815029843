import styles from "../../../home/table_stuffing/tabla_stuff.module.css";
import {Table} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import DBContext from "../../../../context/DBContext";
import {BsPencil, BsEye, BsTrash} from "react-icons/bs";
import axios from "axios";
import {urlConfig} from "../../../../config/urlConfig";
import LangContext from "../../../../context/LangContext";
import {FaSortDown, FaSortUp} from "react-icons/fa";


export const TablaSurveyor = ({
                                  setNewSurveyor,
                                  setOneSurveyor,
                                  setSurveyorId,
                                  editSurveyor,
                                  setEditSurveyor,
                                  surveyorList,
                                  getSurveyorsSort
                              }) => {

    let {inspector, cambioInspector, setCambioInspector} = React.useContext(DBContext);


    const {traduccion} = useContext(LangContext);


    const initialNameOrder = useRef(true);
    const initialProfessionOrder = useRef(true);
    const initialCountryOrder = useRef(true);
    const initialPhoneOrder = useRef(true);
    const initialEmailOrder = useRef(true);
    const initialStatusOrder = useRef(true);

    const [nameOrder, setNameOrder] = useState(true);
    const [professionOrder, setProfessionOrder] = useState(true);
    const [countryOrder, setCountryOrder] = useState(true);
    const [phoneOrder, setPhoneOrder] = useState(true);
    const [emailOrder, setEmailOrder] = useState(true);
    const [statusOrder, setStatusOrder] = useState(true);

    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/inspector/search?page=0&size=${20}`);

    useEffect(() => {
        if (initialNameOrder.current) {
            initialNameOrder.current = false;
            return;
        }
        if (!nameOrder) {
            if (!url.includes("sort=na,desc") && url.includes("sort=na,asc")) {
                setUrl(url.replace("&sort=na,asc", "&sort=na,desc"));
            } else {
                setUrl(url + "&sort=na,desc");
            }
        } else {
            if (!url.includes("sort=na,asc") && url.includes("sort=na,desc")) {
                setUrl(url.replace("&sort=na,desc", "&sort=na,asc"))
            } else {
                setUrl(url + "&sort=na,asc");
            }
        }
    }, [nameOrder]);

    useEffect(() => {
        if (initialProfessionOrder.current) {
            initialProfessionOrder.current = false;
            return;
        }
        if (!professionOrder) {
            if (!url.includes("sort=pr,desc") && url.includes("sort=pr,asc")) {
                setUrl(url.replace("&sort=pr,asc", "&sort=pr,desc"));
            } else {
                setUrl(url + "&sort=pr,desc");
            }
        } else {
            if (!url.includes("sort=pr,asc") && url.includes("sort=pr,desc")) {
                setUrl(url.replace("&sort=pr,desc", "&sort=pr,asc"))
            } else {
                setUrl(url + "&sort=pr,asc");
            }
        }
    }, [professionOrder]);

    useEffect(() => {
        if (initialCountryOrder.current) {
            initialCountryOrder.current = false;
            return;
        }
        if (!countryOrder) {
            if (!url.includes("sort=ct,desc") && url.includes("sort=ct,asc")) {
                setUrl(url.replace("&sort=ct,asc", "&sort=ct,desc"));
            } else {
                setUrl(url + "&sort=ct,desc");
            }
        } else {
            if (!url.includes("sort=ct,asc") && url.includes("sort=ct,desc")) {
                setUrl(url.replace("&sort=ct,desc", "&sort=ct,asc"))
            } else {
                setUrl(url + "&sort=ct,asc");
            }
        }
    }, [countryOrder]);

    useEffect(() => {
        if (initialPhoneOrder.current) {
            initialPhoneOrder.current = false;
            return;
        }
        if (!phoneOrder) {
            if (!url.includes("sort=ph,desc") && url.includes("sort=ph,asc")) {
                setUrl(url.replace("&sort=ph,asc", "&sort=ph,desc"));
            } else {
                setUrl(url + "&sort=ph,desc");
            }
        } else {
            if (!url.includes("sort=ph,asc") && url.includes("sort=ph,desc")) {
                setUrl(url.replace("&sort=ph,desc", "&sort=ph,asc"))
            } else {
                setUrl(url + "&sort=ph,asc");
            }
        }
    }, [phoneOrder]);

    useEffect(() => {
        if (initialEmailOrder.current) {
            initialEmailOrder.current = false;
            return;
        }
        if (!emailOrder) {
            if (!url.includes("sort=em,desc") && url.includes("sort=em,asc")) {
                setUrl(url.replace("&sort=em,asc", "&sort=em,desc"));
            } else {
                setUrl(url + "&sort=em,desc");
            }
        } else {
            if (!url.includes("sort=em,asc") && url.includes("sort=em,desc")) {
                setUrl(url.replace("&sort=em,desc", "&sort=em,asc"))
            } else {
                setUrl(url + "&sort=em,asc");
            }
        }
    }, [emailOrder]);

    useEffect(() => {
        if (initialStatusOrder.current) {
            initialStatusOrder.current = false;
            return;
        }
        if (!statusOrder) {
            if (!url.includes("sort=st,desc") && url.includes("sort=st,asc")) {
                setUrl(url.replace("&sort=st,asc", "&sort=st,desc"));
            } else {
                setUrl(url + "&sort=st,desc");
            }
        } else {
            if (!url.includes("sort=st,asc") && url.includes("sort=st,desc")) {
                setUrl(url.replace("&sort=st,desc", "&sort=st,asc"))
            } else {
                setUrl(url + "&sort=st,asc");
            }
        }
    }, [statusOrder]);

    useEffect(() => {
        getSurveyorsSort(url)
    }, [url])

    useEffect(() => {
        setCambioInspector(!cambioInspector);
    }, []);

    const editASurveyor = (id) => {
        setEditSurveyor(true);
        goToOneSurveyor(id, true)
    }

    const seeSurveyor = (id) => {
        setEditSurveyor(false);
        goToOneSurveyor(id, false);
    }

    const goToOneSurveyor = (id, edita) => {
        //get the token from the local storage
        let token = localStorage.getItem("token");
        const noBearerToken = token.toString().replace("Bearer ", "")
        let header = {
            headers: {
                Authorization: noBearerToken,
            }
        }
        setSurveyorId(id);
        //console.log(header)
        axios.get(`${urlConfig.inspectorUrl}/${id}`, header)
            .then((response) => {
                //console.log(response)
                let dataMix = {
                    ...response.data,
                    id: id
                }
                setOneSurveyor(dataMix);
                //console.log(editSurveyor)
                if (edita) {
                    //console.log("editando")
                    if (dataMix.company_id) {
                        setNewSurveyor(3);
                    } else {
                        setNewSurveyor(1);
                    }
                } else {
                    //console.log("viendo")
                    setNewSurveyor(2);
                }
                //console.log(response.data.data)
            }).catch((error) => {
            //console.log(error);
        })
    };
    const deleteSurveyor = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.delete(`${urlConfig.inspectorUrl}/${id}`, header)
            .then((response) => {
                //console.log(response)
                setCambioInspector(!cambioInspector);
            }).catch((error) => {
            //console.log(error);
        })
    }

    const sortParams = (sort) => {
        if (sort === "na") {
            setNameOrder(!nameOrder);
        }
        if (sort === "pr") {
            setProfessionOrder(!professionOrder);
        }
        if (sort === "ct") {
            setCountryOrder(!countryOrder);
        }
        if (sort === "ph") {
            setPhoneOrder(!phoneOrder);
        }
        if (sort === "em") {
            setEmailOrder(!emailOrder);
        }
        if (sort === "st") {
            setStatusOrder(!statusOrder);
        }
    }

    return (
        <>
            <div className={styles.contiene_tabla}>
                <Table borderless hover className={styles.tabla}>
                    <thead>
                    <tr>
                        <th>{traduccion?.clients?.name} <a onClick={() => sortParams("na")}> {nameOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th className={styles.th_container_nr}>{traduccion?.newSurveyor?.profesion} <a
                            onClick={() => sortParams("pr")}> {professionOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.clients?.country} <a onClick={() => sortParams("ct")}> {countryOrder ?
                            <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.clients?.phone} <a onClick={() => sortParams("ph")}> {phoneOrder ?
                            <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.login?.correo} <a onClick={() => sortParams("em")}> {emailOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.clients?.status} <a onClick={() => sortParams("st")}> {statusOrder ?
                            <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th></th>
                    </tr>

                    </thead>
                    <tbody>

                    {surveyorList.length > 0 ?
                        surveyorList.map((datos, idx) =>
                            <tr key={idx} className={styles.tr_data}>
                                <td>{datos.lastName ? `${datos.firstName} ${datos.lastName}` : datos.surveyorName}</td>
                                <td>{datos.profession}</td>
                                <td>{datos.country.country}</td>
                                <td>{datos.phone}</td>
                                <td>{datos.email}</td>
                                <td>{datos.status.status === "Active" ? `${traduccion?.mantendores?.activo}` : `${traduccion?.mantendores?.inactivo}`}</td>
                                <td>
                                    <div className={styles.botones_botones_surveyor} style={{display: 'flex'}}>
                                        <div onClick={() => editASurveyor(datos.id)} className={styles.boton_icono}>
                                            <BsPencil/>
                                        </div>
                                        <div onClick={() => seeSurveyor(datos.id)} className={styles.boton_icono}>
                                            <BsEye/>
                                        </div>
                                        <div onClick={() => deleteSurveyor(datos.id)}
                                             className={styles.boton_icono}>
                                            <BsTrash style={{color: 'red'}}/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ) :
                        <tr>
                            <td colSpan="6" style={{textAlign: "center"}}>{traduccion?.factura?.noData}</td>
                        </tr>
                    }

                    </tbody>
                </Table>
            </div>
        </>
    )
}
