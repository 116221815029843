import React, {useContext, useEffect, useState} from "react";
import styles from "../surveyors/mantenedor_surveyor.module.css";
import {Link} from "react-router-dom";
import LangContext from "../../../context/LangContext";

export const Dashboard = ({setRouteActive}) => {
    const goToStart = () => {
        setRouteActive("/");
    }
    const {traduccion} = useContext(LangContext);
    const token = localStorage.getItem("token");
    const [dashboard, setDashboard] = useState('1');

    useEffect(()=>{
        if (token) {
            const payload = JSON.parse(atob(token.split(".")[1]));
            setDashboard(payload.dashboard)
        }
    }, [token])

    return (
        <>
            <div className={styles.all_surveyor}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>&gt;
                    <span className={styles.bold}>Dashboard</span>
                </div>
                <div className={styles.title_surveyor}><p>Dashboard</p></div>
                <div className={styles.dashboard}>
                   <div style={{margin: '2rem 0'}}>
                       {/*gráfico Operations/Claims*/}

                       {dashboard && dashboard.split(',').includes('3') ?(
                        <iframe title="Grafico1" className={styles.grafico} style={{height: '100vh'}}
                                src="https://rsk-chile.metabaseapp.com/public/dashboard/c5cdef92-5b53-4de2-bdc9-51c5be1c62aa"
                                allowFullScreen={true}/>
                           ):null}
                    </div>
                    <div style={{margin: '2rem 0'}}>
                        {/*gráfico RECOVERY LEGAL*/}
                        {dashboard && dashboard.split(',').includes('1') ?(

                        <iframe title="Grafico2" className={styles.grafico} style={{height: '75vh'}}
                                src="https://rsk-chile.metabaseapp.com/public/dashboard/8707285e-3416-4f45-933c-a7f8dea95eba"
                                frameBorder="1" allowFullScreen={true}/>
                            ):null}
                    </div>
                    <div style={{margin: '2rem 0'}}>
                        {/*gráfico FINANCE*/}
                        {dashboard && dashboard.split(',').includes('2') ?(

                        <iframe title="Grafico3" className={styles.grafico} style={{height: '60vh'}}
                                src="https://rsk-chile.metabaseapp.com/public/dashboard/cb44eeb0-47e2-4821-a864-4fd6d7e1a649"
                                frameBorder="0" allowFullScreen={true}/>
                            ):null}
                    </div>
                    <div style={{margin: '2rem 0'}}>
                        {/*gráfico ADMINISTRACION*/}
                        {dashboard && dashboard.split(',').includes('4') ?(

                        <iframe title="Grafico4" className={styles.grafico} style={{height: '45vh'}}
                                src="https://rsk-chile.metabaseapp.com/public/dashboard/5c453052-f135-4dd6-9809-e343176ac546"
                                frameBorder="0" allowFullScreen={true}/>
                        ):null}
                    </div>
                    <div style={{margin: '2rem 0'}}>
                        {/*gráfico SURVEYOR */}
                        {dashboard && dashboard.split(',').includes('5') ?(

                        <iframe title="Grafico5" className={styles.grafico} style={{minHeight: '45vh'}}
                                src="https://rsk-chile.metabaseapp.com/public/dashboard/da782bad-10c2-481e-910e-f3a823109ecc"
                                frameBorder="0" allowFullScreen={true}/>
                        ):null}
                    </div>
                </div>
            </div>
        </>
    )
}
