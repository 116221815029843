import {createContext, useEffect, useState} from "react";
// import axios from "axios";
import trad from "./traduccion.json"
// import {urlConfig} from "../config/urlConfig";

const LangContext = createContext();

const initialLang = ()=> {
    if (localStorage.getItem("lang")) {
        return localStorage.getItem("lang");
    } else {
        localStorage.setItem("lang", navigator.language.substring(0, 2));
        return navigator.language.substring(0, 2);
    }
}

const LangProvider =({children})=>{
    const [langu, setLangu] = useState(initialLang);
    const [traduccion, setTraduccion] = useState({});
    useEffect(() => {
        setTraduccion(trad[langu])
    }, [setTraduccion]);

    const handleLanguage = (e) => {
        if (e.target.value === "es") {
            setLangu("es");
            setTraduccion(trad.es);
            localStorage.setItem("lang", "es");
        }else{
            setLangu("en");
            setTraduccion(trad.en);
            localStorage.setItem("lang", "en");
        }
    };
    const data ={traduccion, handleLanguage,initialLang}
    return (
        <LangContext.Provider value={data}>{children}</LangContext.Provider>
    );
}
export { LangProvider };
export default LangContext;