import {Button, Col, Container, Form, Modal, Row, Table} from "react-bootstrap";
import styles from "../../home/modal_reclamo/modal.module.css";
import React, {useContext, useEffect, useState} from "react";
import stylesFiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {Rating} from "react-simple-star-rating";
import {forEach} from "react-bootstrap/ElementChildren";
import LangContext from "../../../context/LangContext";

export const ModalExportadores = ({arr,createNew,showDes,onHide,oneExporter,setOneExporter, hasMap}) => {
    const [palabra, setPalabra] = useState("");
    const [arreglo, setArreglo] = useState(arr);
    const {traduccion} = useContext(LangContext);

    const setAExporter = (id) => {
        setOneExporter(arreglo[id]);
    }

    const handleFilter = (string) =>{
        //console.log(string)
        let newArr = arr.filter((item) => {
            return item.companyName.toLowerCase().includes(string.toLowerCase())
        })
        setArreglo(newArr)
        setPalabra(string)
    }
    useEffect(() => {
        setArreglo(arr)
    }, [arr])

    //console.log({arreglo})
    //console.log({arr})
    return (
        <> <Modal
            show={showDes}
            onHide={onHide}
            // size={width > 768 ? "md" : "sm"}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // style={{maxHeight: "80vh"}}
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {hasMap? 'Survey Online': 'Recovery process only'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflowY:'hidden'}}>
                <div className={styles.modal_reclamo}>
                    <div style={{width:'70%'}}>
                        {/*style={{background:'red', display:"grid",gridTemplateColumns:'1fr 6fr 1fr'}}>*/}
                        <p className={styles.selects_p} style={{alignSelf:'start', padding:'1rem 0 0 2rem'}}>{traduccion?.claim.nEmpresa}</p>
                        <div className={styles.selects_input}>
                            <Form.Group id="nombre_empresa" style={{width:'70%'}} >
                                <Form.Control type="text" name="filtro"
                                              onChange={(e)=>handleFilter(e.target.value)}
                                              value={palabra}/>
                            </Form.Group>
                            {/*<Button className={stylesFiltro.boton_filtro} style={{marginLeft:'2rem'}} variant="primary">*/}
                            {/*    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z" fill="white"/></svg>*/}
                            {/*</Button>*/}
                        </div>

                    </div>
                </div>
                <Container style={{height:'45vh', overflowY:'scroll'}}>

                    <Table className={styles.tabla} style={{height:100, overflowY:'hidden'}}>
                        <thead>
                        <tr>
                            <th>{traduccion?.claim.empresa}</th>
                        </tr>

                        </thead>
                        <tbody style={{overflowY:'scroll'}}>
                            {
                                arreglo.map((datos,idx) =>
                                <tr key={idx} className={(datos.id===oneExporter?.id)? styles.tr_data_select : styles.tr_data} onClick={()=>setAExporter(idx)}>
                                    <td>{datos.companyName}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Container>
            </Modal.Body>
            <Modal.Footer style={{justifyContent:'center'}}>
                <div>
                    <Button variant={"secondary"} style={{minWidth:'20ch'}}  onClick={onHide}>{traduccion.clients?.cancelar}</Button>
                </div>
                <Button style={{minWidth:'20ch'}} variant={"success"} onClick={createNew} >
                    {traduccion.claim?.iniciarNuevo}
                    {/*{loading ? */}
                    {/*    <span className="spinner-border spinner-border-sm text-light" role="status"></span> : "Descargar ZIP"*/}
                    {/*}*/}
                </Button>

            </Modal.Footer>
        </Modal></>
    );
}
