import {Button, Form} from "react-bootstrap";
import styles from "../../../home/ultimos_casos_reclamados/filtros.module.css";
import React, {useContext, useEffect, useState} from "react";
import {useParamsUrl} from "../../useParamsUrl";
import {urlConfig} from "../../../../config/urlConfig";
import axios from "axios";
import {toast} from "react-toastify";
import DBContext from "../../../../context/DBContext";
import LangContext from "../../../../context/LangContext";

export const FilterProfile = ({searchClaims}) => {
    //let {profiles, setProfiles} = React.useContext(DBContext);

    const {traduccion} = useContext(LangContext);

    const [status, setStatus] = useState([`${traduccion?.mantendores?.activo}`, `${traduccion?.mantendores?.inactivo}`]);

    const {searchUrl, handleSearch} = useParamsUrl(
        `${urlConfig.allUrl}${urlConfig.portAccount}/api/v1/accounts/profiles`
    );

    const search = () => {
        searchClaims(searchUrl);
    }


    return (
        <>
            <div className={styles.filtro_total} style={{margin: 0, width: "100%"}}>
                <div className={styles.filtros_busqueda}>
                    <p className={styles.title_filter}>{traduccion?.clients?.filtros}</p>
                    <div className={styles.opciones_busqueda}>
                        <Form>
                            <div>
                                <label htmlFor="container_select">{traduccion?.clients?.name}</label>
                                <Form.Control
                                    style={{
                                        backgroundColor: "#f1f3fe",
                                        boxShadow: "inset 1px 1px 3px #ccc",
                                    }}
                                    onChange={handleSearch}
                                    name="name"
                                    type="text"
                                    placeholder={traduccion?.mantendores?.nombre}
                                />
                            </div>

                            <div>
                                <label htmlFor="estado">{traduccion?.clients?.status}</label>

                                <Form.Select
                                    onChange={handleSearch}
                                    className="form-label"
                                    id="estado"
                                    name="status"
                                >
                                    <option value="">-</option>
                                    {status.length > 0
                                        ? status.map((item, idx) => (
                                            <option key={idx} value={idx === 0}>{item}</option>
                                        ))
                                        : null}
                                </Form.Select>
                            </div>

                            <Button
                                className={styles.boton_filtro}
                                onClick={search}
                                variant="primary"
                            >
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                        fill="white"
                                    />
                                </svg>
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
