import React from 'react';
import {Pagination} from "react-bootstrap";

const Pager = ({totalPages, active, goToPage}) => {

    let items = []
    for (let number = 0; number <= totalPages; number++) {
        items.push(
            <Pagination.Item onClick={() => goToPage(number)} key={number} active={number === active}>
                {number + 1}
            </Pagination.Item>,
        );
    }

    return (
        <Pagination style={{justifyContent: 'center'}}>{items}</Pagination>
    );
};

export default Pager;
