import React, {useContext, useEffect, useState} from "react";
import styles from "../surveyors/mantenedor_surveyor.module.css";
import {Link} from "react-router-dom";
import {Button, Form, Pagination} from "react-bootstrap";
import {FiltroSurveyor} from "../surveyors/filter_surveyor/FiltroSurveyor";
import {TablaSurveyor} from "../surveyors/tabla_surveyor/TablaSurveyor";
import {NewSurveyor} from "../surveyors/NewSurveyor";
import {SurveyorDetail} from "../surveyors/SurveyorDetail";
import {NewExporter} from "./NewExporter";
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {TablaExporters} from "./TablaExporters";
import DBContext from "../../../context/DBContext";
import {urlConfig} from "../../../config/urlConfig";
import {useParamsUrl} from "../useParamsUrl";
import axios from "axios";
import {toast} from "react-toastify";
import LangContext from "../../../context/LangContext";

export const MantenedorExporter = ({newExporter,setNewExporter,setRouteActive}) => {
    const {exporter,setCambioExporter,cambioExporter} = useContext(DBContext);

    const [oneExporter, setOneExporter] = useState("");
    const [form, setForm] = useState({});
    const {traduccion} = useContext(LangContext);

        const listaStatusSimple = [{id:1,nombre:`${traduccion?.mantendores?.activo}`},{id:2,nombre:`${traduccion?.mantendores?.inactivo}`}];

    //Para el filtro
    const [perPage, setPerPage] = useState(10);
    const [active, setActive] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const {searchUrl, handleSearch,params} = useParamsUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/search`);
    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/search?page=0&size=${perPage}`);
    const [listaExporters, setListaExporters] = useState(exporter);

    const goToStart=()=>{
        setRouteActive("/")
    }
    const handleNewExporter = () => {
        //setEditSurveyor(false);
        setOneExporter("")
        setNewExporter(1)
    }

    //funcion que se ejecuta cada vez que el usuario escribe en un input
    const handleChange = (e) => {
        //console.log(e.target.value ==="" && e.target.name === "statusId")
        if ((e.target.value === "" && e.target.name === "areaId") || (e.target.value === "" && e.target.name === "statusId")) {
            setForm(delete form[e.target.name])
        }else{
            const {name, value} = e.target;
            setForm({
                ...form,
                [name]: value
            })
        }
    }

    //-----------Paginacion-----------
    const token = localStorage.getItem("token").replace("Bearer ", "")

    const getExporters = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token.replace("Bearer ", ""),
            }
        }).then((res) => {
            setListaExporters(res.data.exporters)
            setTotalPages(res.data.totalPages-1)
        }).catch((error) => {
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }
    const getExportersSort = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token.replace("Bearer ", ""),
            }
        }).then((res) => {
            setListaExporters(res.data.exporters)
            setTotalPages(res.data.totalPages-1)
        }).catch((error) => {
            toast.error(`${traduccion?.errores?.errorSorting}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }


    const searchExporter = (e) => {
        e.preventDefault()
        setActive(0);
        let newUrl = `${searchUrl}&size=${perPage}&page=${0}`;
        setUrl(newUrl);
    }



    const goToPage = (page) => {
        //console.log("GO TO PAGE", page)
        let newUrl =(url.replace(/page=\d+/, `page=${page}`))
        //console.log("URL", newUrl)
        setUrl(newUrl)
        setActive(page)
    }

    useEffect(() => {
        let newUrl =(url.replace(/size=\d+/, `size=${perPage}`))
        newUrl.replace(/page=\d+/, `page=0`)
        setUrl(newUrl)
        setActive(0)
    },[perPage])

    useEffect(() => {
        //console.log("GET...", url)
        getExporters(url)
    },[url])

    // useEffect(() => {
    //     setCambioExporter(!cambioExporter)
    // },[])
    // let numPage = Math.floor(listaClaim.totalItems / perPage);
    let items=[]
    let oneElipsisA = false
    let oneElipsisD = false
    for (let number = 0; number <= totalPages; number++) {
        //add elipsis
        if (number > 2 && number < totalPages - 2) {
            if (number === active + 1 || number === active - 1) {
                items.push(
                    <Pagination.Item className={styles.pagina} key={number} active={number === active} onClick={() => goToPage(number)}>
                        {number+1}
                    </Pagination.Item>,
                );
            }else if(number ===active){
                items.push(
                    <Pagination.Item className={styles.pagina} onClick={()=>goToPage(number)} key={number} active={number === active}>
                        {number+1}
                    </Pagination.Item>,
                );
            }else{
                if (number < active && !oneElipsisA) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisA = true
                }
                if (number > active && !oneElipsisD) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisD = true
                }
            }
        }else{
            items.push(
                <Pagination.Item className={styles.pagina} onClick={()=>goToPage(number)} key={number} active={number === active}>
                    {number+1}
                </Pagination.Item>,
            );
        }
    }

    return (
        <>
            <div className={styles.all_surveyor}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>
                    >
                    {newExporter === 0 && <span className={styles.bold}>{traduccion?.clients?.clients} / {traduccion?.clients?.contracts}</span>}
                    {newExporter === 1 && (
                        <>
              <span onClick={() => setNewExporter(0)} className={styles.like_a}>
                {traduccion?.clients?.clients} / {traduccion?.clients?.contracts}
              </span>
                            >
                            <span className={styles.bold}>{traduccion?.clients?.newExport}</span>
                        </>
                    )}
                </div>
                {newExporter === 0 && (
                    <>
                        <div className={styles.title_surveyor}>
                            <p>{traduccion?.clients?.clients} / {traduccion?.clients?.contracts}</p>
                            <Button className={styles.title_button} variant="primary" onClick={handleNewExporter}>
                                {traduccion?.clients?.newExport}
                            </Button>
                        </div>
                        <div className={stylesfiltro.filtro_total} style={{ margin: 0, width: '100%' }}>
                            <div className={stylesfiltro.filtros_busqueda}>
                                <p className={stylesfiltro.title_filter}>{traduccion?.clients?.filtros}</p>
                                <div className={stylesfiltro.opciones_busqueda}>
                                    <Form onSubmit={searchExporter}>
                                        <div>
                                            <label htmlFor="container_select">{traduccion?.clients?.name}</label>
                                            <Form.Control
                                                style={{ backgroundColor: '#f1f3fe', boxShadow: 'inset 1px 1px 3px #ccc' }}
                                                onChange={handleSearch}
                                                name="name"
                                                type="text"
                                                placeholder={traduccion?.mantendores?.nombre}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="estado">{traduccion?.clients?.status}</label>
                                            <Form.Select onChange={handleSearch} className="form-label" id="estado" name="status">
                                                <option value="" defaultValue={true}>
                                                    {traduccion?.clients?.selectOption}
                                                </option>

                                                {listaStatusSimple.map((item, idx) => {
                                                    return (
                                                        <option key={idx} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </div>
                                        <Button className={stylesfiltro.boton_filtro} variant="primary" onClick={searchExporter}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z" fill="white" />
                                            </svg>
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <TablaExporters
                            exporter={listaExporters}
                            cambioExporter={cambioExporter}
                            setCambioExporter={setCambioExporter}
                            setOneExporter={setOneExporter}
                            setNewExporter={setNewExporter}
                            getExportersSort={getExportersSort}
                        />
                        <div className={styles.pagination}>
                            <Pagination>{items}</Pagination>
                        </div>
                    </>
                )}
                {newExporter === 1 && (
                    <NewExporter
                        setNewExporter={setNewExporter}
                        title={"Nuevo exportador"}
                        oneExporter={oneExporter}
                    />
                )}
            </div>
        </>
    );
};
