import React from 'react';
import {ProSidebar,Menu, MenuItem} from "react-pro-sidebar";
import 'react-pro-sidebar/dist/scss/styles.scss'
import './styleSidebar.scss'
import style from './styleSidebar.module.css'
import {Link} from "react-router-dom";
// import { FaBox,FaHome } from "react-icons/fa";
import { BsBoxSeam,BsHouse } from "react-icons/bs";

const SideBar = (props) => {
    const clickH=()=>{
        props.setRouteActive("/")
    }
    const clickS=()=>{
        props.setRouteActive("/stuffing")
    }
    return (
        <>
            <div style={{height:"100%"}}>
            <ProSidebar className={style.custom_sidebar} collapsed={props.collapse}>
                <Menu className={style.custom_menu}>
                    <Link onClick={clickH} to="/">
                        <MenuItem className={props.routeActive ==="/"? style.custom_menu_item_active : style.custom_menu_item} >
                            <BsHouse style={{fontSize:'2.2rem', marginRight:'1.5rem'}}/>
                                <span  className={style.texto_menu}>Home</span>
                                {props.routeActive ==="/"&&
                                    <div style={{display:"inline"}}>
                                        <svg className="float-end" style={{marginTop:"5px"}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.1717 12L8.22168 7.04999L9.63568 5.63599L15.9997 12L9.63568 18.364L8.22168 16.95L13.1717 12Z" fill="white"/>
                                        </svg>
                                    </div>
                                }
                        </MenuItem>
                    </Link>
                    <Link onClick={clickS} to="/stuffing">
                        <MenuItem className={props.routeActive ==="/stuffing"? style.custom_menu_item_active : style.custom_menu_item}>
                            <BsBoxSeam style={{fontSize:'2.2rem', marginRight:'1.5rem'}}/>

                                <span  className={style.texto_menu}>Stuffing report</span>
                                {props.routeActive ==="/stuffing"&&
                                    <div style={{display:"inline"}}>
                                        <svg className="float-end" style={{marginTop:"5px"}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.1717 12L8.22168 7.04999L9.63568 5.63599L15.9997 12L9.63568 18.364L8.22168 16.95L13.1717 12Z" fill="white"/>
                                        </svg>
                                    </div>
                                }
                        </MenuItem>
                    </Link>
                </Menu>
            </ProSidebar>
            </div>
        </>
    );
};

export default SideBar;
