import React, {useContext, useEffect, useRef, useState} from "react";

import DBContext from "../../../context/DBContext";
import LangContext from "../../../context/LangContext";
import {useNavigate} from "react-router";
import {useParamsUrl} from "../useParamsUrl";
import {urlConfig} from "../../../config/urlConfig";
import {startClaim} from "../../../config/initial";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {Button, Dropdown, DropdownButton, Form, InputGroup, Modal, Pagination, Spinner, Table} from "react-bootstrap";
import thisStyles from "./all_claims.module.css";
import styles from "../../home/table_home/tabla.module.css";
import stylesFiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import {BsPencil, BsTrash} from "react-icons/bs";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import {RiFileExcel2Line} from "react-icons/ri";
import {ModalExportadores} from "./ModalExportadores";
import {IoMdAirplane, IoMdBoat, IoMdBus} from "react-icons/io";
import {Link, useLocation} from "react-router-dom";

export const AllClaims = ({setRouteActive}     //{setStuff, setOneClaim,active,setActive,listaClaim,setListaClaim,perPage,setPerPage, url,setUrl,totalPages,setTotalPages}
) => {
    const {/*exporter,*/
        claim,
        cambioClaims,
        setCambioClaims,
        timelineStatus,
        statusClaim,
        session
    } = useContext(DBContext);
    const location = useLocation();
    const isStuff = location.state?.id;

    const {traduccion} = useContext(LangContext);
    const navigate = useNavigate();
    const {searchUrl, handleSearch, params} = useParamsUrl(urlConfig.searchClaimExportUrl);

    const [exporter, setExporter] = useState([]);
    //Modal borrar
    const [showModal, setShowModal] = useState(false);
    const [modalId, setModalId] = useState(false);

    // const [oneClaimAll, setOneClaimAll] = useState(null);
    const [status, setStatus] = useState([]);
    const [listaClaim, setListaClaim] = useState(claim);

    //Para el filtro
    const [perPage, setPerPage] = useState(20);
    const [active, setActive] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [type, setType] = useState("");
    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/search?page=0&size=${perPage}&type=${type}`);

    //Modal de exportadores
    const [modalData, setModalData] = useState([]);
    const [showModalDes, setShowModalDes] = useState(false);
    const [oneExporter, setOneExporter] = useState(null);

    const [mapa, setMapa] = useState(true);

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [modalStuff, setModalStuff] = useState({claim: "", idUser: "", descUser: "", rskClaim: ""});

    const [users, setUsers] = useState([{email: '...loading'}]);
    const [showModalUsers, setShowModalUsers] = useState(false);

    const initialRefOrder = useRef(true);
    const initialExporterOrder = useRef(true);
    const initialPolOrder = useRef(true);
    const initialContainerOrder = useRef(true);
    const initialDaysOrder = useRef(false);
    const initialTimeOrder = useRef(true);
    const initialRskOrder = useRef(true);
    const initialStatusOrder = useRef(true);

    const sortOptions = [
        "ref",
        "exporter",
        "pol",
        "container",
        "days",
        "time",
        "rsk",
        "estado"
    ];

    const [refOrder, setRefOrder] = useState(true);
    const [exporterOrder, setExporterOrder] = useState(true);
    const [polOrder, setPolOrder] = useState(true);
    const [containerOrder, setContainerOrder] = useState(true);
    const [daysOrder, setDaysOrder] = useState(true);
    const [timeOrder, setTimeOrder] = useState(true);
    const [rskOrder, setRskOrder] = useState(true);
    const [statusOrder, setStatusOrder] = useState(true);


    useEffect(() => {
        if (initialRefOrder.current) {
            initialRefOrder.current = false;
            return;
        }
        if (!refOrder) {
            if (!url.includes("sort=ref,desc") && url.includes("sort=ref,asc")) {
                setUrl(url.replace("&sort=ref,asc", "&sort=ref,desc"));
            } else {
                setUrl(url + "&sort=ref,desc");
            }
        } else {
            if (!url.includes("sort=ref,asc") && url.includes("sort=ref,desc")) {
                setUrl(url.replace("&sort=ref,desc", "&sort=ref,asc"))
            } else {
                setUrl(url + "&sort=ref,asc");
            }
        }
    }, [refOrder]);

    useEffect(() => {
        if (initialExporterOrder.current) {
            initialExporterOrder.current = false;
            return;
        }
        if (!exporterOrder) {
            if (!url.includes("sort=exp,desc") && url.includes("sort=exp,asc")) {
                setUrl(url.replace("&sort=exp,asc", "&sort=exp,desc"));
            } else {
                setUrl(url + "&sort=exp,desc");
            }
        } else {
            if (!url.includes("sort=exp,asc") && url.includes("sort=exp,desc")) {
                setUrl(url.replace("&sort=exp,desc", "&sort=exp,asc"))
            } else {
                setUrl(url + "&sort=exp,asc");
            }
        }
    }, [exporterOrder]);

    useEffect(() => {
        if (initialPolOrder.current) {
            initialPolOrder.current = false;
            return;
        }
        if (!polOrder) {
            if (!url.includes("sort=pol,desc") && url.includes("sort=pol,asc")) {
                setUrl(url.replace("&sort=pol,asc", "&sort=pol,desc"));
            } else {
                setUrl(url + "&sort=pol,desc");
            }
        } else {
            if (!url.includes("sort=pol,asc") && url.includes("sort=pol,desc")) {
                setUrl(url.replace("&sort=pol,desc", "&sort=pol,asc"))
            } else {
                setUrl(url + "&sort=pol,asc");
            }
        }
    }, [polOrder]);

    useEffect(() => {
        if (initialContainerOrder.current) {
            initialContainerOrder.current = false;
            return;
        }
        if (!containerOrder) {
            if (!url.includes("sort=con,desc") && url.includes("sort=con,asc")) {
                setUrl(url.replace("&sort=con,asc", "&sort=con,desc"));
            } else {
                setUrl(url + "&sort=con,desc");
            }
        } else {
            if (!url.includes("sort=con,asc") && url.includes("sort=con,desc")) {
                setUrl(url.replace("&sort=con,desc", "&sort=con,asc"))
            } else {
                setUrl(url + "&sort=con,asc");
            }
        }
    }, [containerOrder]);

    useEffect(() => {
        if (!initialDaysOrder.current) {
            initialDaysOrder.current = true;
            return;
        }
        if (!daysOrder) {
            if (!url.includes("sort=days,desc") && url.includes("sort=days,asc")) {
                setUrl(url.replace("&sort=days,asc", "&sort=days,desc"));
            } else {
                setUrl(url + "&sort=days,desc");
            }
        } else {
            if (!url.includes("sort=days,asc") && url.includes("sort=days,desc")) {
                setUrl(url.replace("&sort=days,desc", "&sort=days,asc"))
            } else {
                setUrl(url + "&sort=days,asc");
            }
        }
    }, [daysOrder]);

    useEffect(() => {
        if (initialTimeOrder.current) {
            initialTimeOrder.current = false;
            return;
        }
        if (!timeOrder) {
            if (!url.includes("sort=time,desc") && url.includes("sort=time,asc")) {
                setUrl(url.replace("&sort=time,asc", "&sort=time,desc"));
            } else {
                setUrl(url + "&sort=time,desc");
            }
        } else {
            if (!url.includes("sort=time,asc") && url.includes("sort=time,desc")) {
                setUrl(url.replace("&sort=time,desc", "&sort=time,asc"))
            } else {
                setUrl(url + "&sort=time,asc");
            }
        }
    }, [timeOrder]);

    useEffect(() => {
        if (initialRskOrder.current) {
            initialRskOrder.current = false;
            return;
        }
        if (!rskOrder) {
            if (!url.includes("sort=rsk,desc") && url.includes("sort=rsk,asc")) {
                setUrl(url.replace("&sort=rsk,asc", "&sort=rsk,desc"));
            } else {
                setUrl(url + "&sort=rsk,desc");
            }
        } else {
            if (!url.includes("sort=rsk,asc") && url.includes("sort=rsk,desc")) {
                setUrl(url.replace("&sort=rsk,desc", "&sort=rsk,asc"));
            } else {
                setUrl(url + "&sort=rsk,asc");
            }
        }
    }, [rskOrder]);

    useEffect(() => {
        if (initialStatusOrder.current) {
            initialStatusOrder.current = false;
            return;
        }
        if (!statusOrder) {
            if (!url.includes("sort=st,desc") && url.includes("sort=st,asc")) {
                setUrl(url.replace("&sort=st,asc", "&sort=st,desc"));
            } else {
                setUrl(url + "&sort=st,desc");
            }
        } else {
            if (!url.includes("sort=st,asc") && url.includes("sort=st,desc")) {
                setUrl(url.replace("&sort=st,desc", "&sort=st,asc"));
            } else {
                setUrl(url + "&sort=st,asc");
            }
        }
    }, [statusOrder]);


    const sortParams = (sort) => {
        if (sort === "ref") {
            setRefOrder(!refOrder);
        }
        if (sort === "exp") {
            setExporterOrder(!exporterOrder);
        }
        if (sort === "pol") {
            setPolOrder(!polOrder);
        }
        if (sort === "con") {
            setContainerOrder(!containerOrder);
        }
        if (sort === "days") {
            setDaysOrder(!daysOrder);
        }
        if (sort === "time") {
            setTimeOrder(!timeOrder);
        }
        if (sort === "rsk") {
            setRskOrder(!rskOrder);
        }
        if (sort === "st") {
            setStatusOrder(!statusOrder);
        }
    }

    // get token
    const token = localStorage.getItem("token");
    const [statusCl, setStatusCl] = useState([
        {
            id: 1,
            name: "New"
        },
        {
            id: 2,
            name: "Survey Report"
        },
        {
            id: 3,
            name: "Pending documents"
        },
        {
            id: 4,
            name: "Ready to Claim"
        },
        {
            id: 5,
            name: "Closed"
        },
        {
            id: 6,
            name: "Out of Court"
        },
        {
            id: 7,
            name: "In court"
        },
        {
            id: 8,
            name: "Recovered"
        },
        {
            id: 9,
            name: "Not Recovered"
        },
        {
            id: 10,
            name: "Signed release"
        }
    ]);

    let tokenAtob = atob(token.split('.')[1]);
    let tokenJson = JSON.parse(tokenAtob);
    //console.log("tokenJson", tokenJson)
    useEffect(() => {
        let statysss = [];
        let permisos = tokenJson.permissions.split(',');
        statusCl.map((obj) => {
            //console.log("obj", obj)
            for (let permiso of permisos) {
                //console.log("permiso", permiso)
                if (permiso == obj.id) {
                    statysss.push(obj)
                }
            }
        })
        setStatusCl(statysss)
    }, [])

    const thisYear = new Date().getFullYear();
    const handleNew = (e) => {
        // let hasMap = true
        // if (e === "noMap") {
        //     hasMap = false
        // }
        startClaim.hasMap = mapa
        //console.log("CREANDO NUEVO RECLAMO...", oneExporter)
        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/`, {
            ...startClaim,
            exporterId: oneExporter.id
        }, {
            headers: {
                Authorization: token.toString().replace("Bearer ", "")
            }
        })
            .then((res) => {
                //console.log("CREANDO NUEVO RECLAMO... este viene con mas cosas :P", res.data)
                let finalMix = {
                    ...startClaim,
                    id: res.data.id,
                    createdTime: res.data.createdTime,
                    hasMap: mapa,
                    companyId: oneExporter.id,
                    companyName: oneExporter.companyName,
                    exporterName: oneExporter.companyName,
                }
                //setOneClaimAll(finalMix)

                navigate('/informe_inspeccion_all', {
                    state: {
                        id: res.data.id,
                        oneClaimAll: finalMix,
                        imRsk: true,
                        oneExporter: oneExporter
                    }
                })
            }).catch((error) => {
            if (error.response.data.message === "The exporter contract has expired") {
                toast.error(`${traduccion.others.expiredContract}`, {
                    position: "top-right",
                    duration: 5000
                })
            }

            //console.log(error);
        })
    }

    const editClaim = (id) => {
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${id}`, {
            headers: {
                Authorization: token.toString().replace("Bearer ", "")
            }
        }).then((res) => {
            let newData = {...startClaim, ...res.data}
            if (newData.comment === null) {
                newData.comment = ""
            }
            if (newData.claimDate === null) {
                newData.claimDate = ""
            }
            if (newData.lossTypeId === null) {
                newData.lossTypeId = 1
            }
            if (newData.cargo?.fruits !== undefined) {
                for (const fruits of newData.cargo.fruits) {
                    for (const harvest of fruits.fruits.harvesting) {
                        if (harvest.harvestDate === null) {
                            harvest.harvestDate = ""
                        }
                    }
                    for (const packa of fruits.fruits.packaging) {
                        if (packa.packageDate === null) {
                            packa.packageDate = ""
                        }
                    }
                }
            }
            newData.documents = {
                "shippingDocuments": res.data.documents?.shipping || [],
                "shipping": res.data.documents?.shipping || [],
                "consolidatedReport": res.data.documents?.consolidatedReport || "",
                "controlledAtmosphere": res.data.documents?.controlledAtmosphere || "",
                "thermograph": res.data.documents?.thermograph || "",
                "thermograph2": res.data.documents?.thermograph2 || "",
                "other": res.data?.documents.other || "",
                "assignmentLetter": res.data.documents?.assignmentLetter || "",
                "assignmentRights": res.data.documents?.assignmentRights || "",
                "survey": res.data.documents?.surveyor?.survey || "",
                "photograph": res.data.documents?.surveyor?.photograph || "",
                "notice": res.data.documents?.surveyor?.notice || "",
                "noticeDate": res.data.documents?.surveyor?.noticeDate || "",
                "destruction": res.data.documents?.surveyor?.destruction || "",
                "receipt": res.data.documents?.recovery?.receipt || "",
                "settlement": res.data.documents?.recovery?.settlement || "",
                "invoice": res.data.documents?.invoice || "",
                "calculation": res.data.documents?.calculation || {
                    "creditNote": [],
                    "extraExpenses": [],
                    "marketPrices": [],
                    "salesResults": []
                }
            };


            if (newData.lossCalculation.length === 0) {
                newData.lossCalculation = startClaim.lossCalculation
            } else {
                newData.lossCalculation = res.data.lossCalculation
            }
            // setOneClaimAll(newData)
            navigate('/informe_inspeccion_all', {
                state: {
                    id: res.data.id,
                    oneClaimAll: newData,
                    imRsk: true,
                    lossCalculation: newData.lossCalculation,
                    editRsk: true
                }
            })
        }).catch((error) => {
            console.log(error);
        })
    }


    const deleteClaim = (id) => {
        axios.delete(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${id}`, {
            //axios.delete(`http://testsiigroup.pagekite.me/api/v1/claim/me/${id}`, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            // setCambioClaims(!cambioClaims)
            getClaims(url)
        }).catch((error) => {
            //console.log(error);
        })
        setShowModal(false)
    }

//-----------Paginacion-----------

    const getClaims = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setListaClaim(res.data.claims)
            setTotalPages(res.data.totalPages - 1)
        }).catch((error) => {
            //console.log(error);
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }
    const goToPage = (page) => {
        //console.log("GO TO PAGE", page)
        let newUrl = (url.replace(/page=\d+/, `page=${page}`))
        //console.log("URL", newUrl)
        setUrl(newUrl)
        setActive(page)
    }


    const handlePerPage = (e) => {
        let page = e.target.value
        //console.log("PAGINANDO...", page)
        setPerPage(page)
    }
    const searchClaims = (e) => {
        e.preventDefault()
        setActive(0);
        let newUrl = `${searchUrl}&size=${perPage}&page=${0}&type=${type}`;
        setUrl(newUrl);
    }

    const updateClaims = () => {
        setActive(0);
        let newUrl = `${searchUrl}&size=${perPage}&page=${active}&type=${type}`;
        getClaims(newUrl)
    }

    useEffect(() => {
        let newUrl = (url.replace(/size=\d+/, `size=${perPage}`))
        newUrl.replace(/page=\d+/, `page=0`)
        setUrl(newUrl)
        setActive(0)
    }, [perPage])

    useEffect(() => {
        getClaims(url)
    }, [url])

    // let numPage = Math.floor(listaClaim.totalItems / perPage);
    let items = []
    let oneElipsisA = false
    let oneElipsisD = false
    for (let number = 0; number <= totalPages; number++) {
        //add elipsis
        if (number > 2 && number < totalPages - 2) {
            if (number === active + 1 || number === active - 1) {
                items.push(
                    <Pagination.Item className={styles.pagina} key={number} active={number === active}
                                     onClick={() => goToPage(number)}>
                        {number + 1}
                    </Pagination.Item>,
                );
            } else if (number === active) {
                items.push(
                    <Pagination.Item className={styles.pagina} onClick={() => goToPage(number)} key={number}
                                     active={number === active}>
                        {number + 1}
                    </Pagination.Item>,
                );
            } else {
                if (number < active && !oneElipsisA) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisA = true
                }
                if (number > active && !oneElipsisD) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisD = true
                }
            }
        } else {
            items.push(
                <Pagination.Item className={styles.pagina} onClick={() => goToPage(number)} key={number}
                                 active={number === active}>
                    {number + 1}
                </Pagination.Item>,
            );
        }
    }


    const handleShow = (id) => {
        setShowModal(true)
        setModalId(id)
    }

    const handleModalClaims = (tipo) => {
        if (tipo === "noMap") {
            setMapa(false)
        } else {
            setMapa(true)
        }
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/company_contract`, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            //console.log("CLAIM", res.data)
            setExporter(res.data)
            setShowModalDes(true)
        })
    }

    const nuevoClaimModal = () => {
        handleNew()
    }

    const handleStatusDays = (statusDate) => {
        //console.log("STATUS DATE", statusDate)
        if (statusDate !== null && statusDate !== undefined && statusDate !== "") {
            let today = new Date()
            let date = new Date(statusDate)
            let diff = Math.abs(today - date)
            let days = Math.ceil(diff / (1000 * 60 * 60 * 24))
            if (days === 1) {
                return "1 día"
            } else {
                return days + " días"
            }
        } else {
            return "Sin Fecha"
        }
    }


    //console.log(location)
    const goToStart = () => {
        setRouteActive("/");
    }
    const handleSearchType = (e) => {
        //console.log(e)
        //console.log("TYPE", type)
        setType(e)
    }
    useEffect(() => {
        let newUrl = (url.replace(/type=[\d+]?/, `type=${type}`))
        setUrl(newUrl)
        setActive(0)
    }, [type])

    const downloadExcel = () => {
        setLoading2(true)
        //console.log("DOWNLOAD EXCEL")
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/export/claims`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Disposition': 'attachment',
                Authorization: token.replace("Bearer ", ""),
            }
        }).then((res) => {
            const url = URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'archivo.xls');
            document.body.appendChild(link);
            link.click();
            //console.log("EXCEL", res.data)
            setLoading2(false)
            toast.success(`${traduccion?.others?.excelDescargado}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        }).catch((error) => {
            setLoading2(false)
            toast.error(`${traduccion?.others?.errorDescargarExcel}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }
    const getAllUsers = () => {
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/rsk/users`, {
            headers: {
                Authorization: token.replace("Bearer ", "")
            }
        }).then((res) => {
            //console.log("RSK USERS", res.data)
            let usuarios = []
            res.data.forEach((user) => {

                usuarios.push({id: user.id, email: user.account.email})

            })
            setUsers(usuarios)
        }).catch((error) => {
            //console.log(error);
        })
    }
    const abrirModalUsers = (idClaim, e, rskClaim) => {
        e.preventDefault()
        const select = e.target;
        const value = select.value;
        const desc = select.options[select.selectedIndex].text;

        //console.log({claim: idClaim, idUser: value, descUser: desc, rskClaim: rskClaim})
        setModalStuff({claim: idClaim, idUser: value, descUser: desc, rskClaim: rskClaim});
        setShowModalUsers(true)
    }

    const changeUserClaim = (claim, idUser) => {
        setLoading(true)
        //console.log("CHANGE USER CLAIM", claim, idUser)
        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/assign/${claim}`,
            {
                "id": idUser
            }
            , {
                headers: {
                    Authorization: token.replace("Bearer ", "")
                }
            }).then((res) => {
            //console.log("CHANGE USER CLAIM", res.data)
            toast.success(`${traduccion?.others?.usuarioAsignado}`, {
                position: toast.POSITION.TOP_RIGHT
            })
            updateClaims()
            setCambioClaims(!cambioClaims)
            setShowModalUsers(false)
            setLoading(false)
        }).catch((error) => {
            if (error.response?.data?.message) {
                toast.error(`${error.response?.data?.message.split(".")[1]}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            } else {
                toast.error(`${traduccion?.others?.errorAsignarUsuario}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
            setShowModalUsers(false)
            setLoading(false)
        })
    }
    //console.log("CLAIMS SESSION", session)
    return (
        <>
            <ToastContainer/>
            <div className={thisStyles.claim_all} style={{paddingTop: '1.3rem'}}>
                <div style={{marginLeft: '.5rem'}}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link to={"/"} style={{textDecoration: 'none'}}>
                        <span onClick={goToStart}> {traduccion?.breadcrumb?.home} ></span>
                    </Link> <span><b>{isStuff ? "Stuffing" : `${traduccion?.claim?.claims}`}</b></span>
                </div>
                <div className={stylesFiltro.filtro_total}>
                    <p className={stylesFiltro.title_claimed}
                       style={{marginLeft: '4vw'}}>{isStuff ? "Stuffing" : `${traduccion?.claim?.claims}`}</p>

                    {isStuff ?
                        <div className={stylesFiltro.filtros_busqueda}>
                            <p className={stylesFiltro.title_filter}>{traduccion.stuffingFilter?.searchFilter}</p>
                            <div className={stylesFiltro.opciones_busqueda}>
                                <Form>
                                    <div>
                                        <label
                                            htmlFor="container_select">{traduccion.stuffingFilter?.ordenEmbarque}</label>
                                        <Form.Control name={"order"} type={"text"}
                                                      onChange={handleSearch}
                                                      className={stylesFiltro.selects_input}/>

                                    </div>
                                    <div>
                                        <label
                                            htmlFor="container_select">{traduccion.stuffingFilter?.nContainer}</label>
                                        <Form.Control name={"container"} type={"text"}
                                                      onChange={handleSearch}
                                                      className={stylesFiltro.selects_input}/>

                                    </div>

                                    <div className={stylesFiltro.opciones_date}>
                                        <div className={stylesFiltro.opciones_date_in}>
                                            <Form.Label
                                                htmlFor="fechaReporteStart">
                                                {/*<div style={{margin:0}}>{traduccion.stuffingFilter?.fechaReporte}</div> */}
                                                {traduccion.factura?.desde}:

                                            </Form.Label>
                                            <Form.Control
                                                name="from"
                                                onChange={handleSearch}
                                                type="date"
                                                id="fechaReporteStart"
                                                placeholder={traduccion?.factura?.desde}
                                                className={stylesFiltro.selects_input}
                                            />
                                        </div>
                                        <div className={stylesFiltro.opciones_date_in}>
                                            <Form.Label
                                                htmlFor="fechaReporteStart">
                                                {traduccion.factura?.hasta}:
                                            </Form.Label>
                                            <Form.Control
                                                name="to"
                                                onChange={handleSearch}
                                                type="date"
                                                id="fechaReporteEnd"
                                                placeholder={traduccion?.factura?.hasta}
                                                className={stylesFiltro.selects_input}
                                            />
                                        </div>
                                    </div>
                                    <Button className={stylesFiltro.boton_filtro} variant="primary"
                                        //onClick={searchStuffing}
                                    >
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                                fill="white"/>
                                        </svg>
                                    </Button>
                                </Form>
                            </div>
                        </div>
                        :
                        <div className={stylesFiltro.filtros_busqueda}>
                            <p className={stylesFiltro.title_filter}>{traduccion.claimed?.filtro}</p>
                            <div className={stylesFiltro.opciones_busqueda}>
                                <Form onSubmit={searchClaims}>

                                    <div>
                                        <label className="ms-3" htmlFor="nave_select">Ref. RSK</label>
                                        <InputGroup className="mb-3" style={{display: 'flex'}}>
                                            <InputGroup.Text id="basic-addon1"
                                                             style={{width: '5ch'}}>RSK</InputGroup.Text>
                                            <Form.Control
                                                type={"text"}
                                                placeholder={`${thisYear}/1`}
                                                name="ref"
                                                onChange={handleSearch}
                                            />
                                        </InputGroup>
                                        {/*<Form.Group controlId="container">*/}
                                        {/*    <label htmlFor="container_select">Ref. RSK</label>*/}
                                        {/*    <Form.Control type="text" placeholder="Ref. RSK" onChange={handleSearch} name="refrsk"/>*/}
                                        {/*</Form.Group>*/}
                                    </div>
                                    <div>
                                        <Form.Group controlId="exporter">
                                            <label htmlFor="nave_select">{traduccion?.claim?.exporter}</label>
                                            <Form.Control type="text" placeholder={`${traduccion?.claim?.exporter}`}
                                                          onChange={handleSearch} name="exporter"/>
                                        </Form.Group>
                                    </div>
                                    <div>

                                        <Form.Group controlId="rskUSer">
                                            <label htmlFor="nave_select">RSK User</label>
                                            <Form.Control type="text" placeholder="RSK User" onChange={handleSearch}
                                                          name="rsk"/>
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <label htmlFor="estado">{traduccion.claimed?.estado}</label>

                                        <Form.Select id="estado" onChange={handleSearch} name="status">
                                            <option value="">-</option>
                                            {

                                                // statusClaim - description
                                                statusCl.length > 0 ?
                                                    statusCl.map((item, idx) =>
                                                        <option key={idx} value={item.id}>{item.name}</option>
                                                    ) : null
                                            }
                                        </Form.Select>

                                    </div>
                                    <Button className={stylesFiltro.boton_filtro} variant="primary"
                                            onClick={searchClaims}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                                fill="white"/>
                                        </svg>
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    }
                </div>
                <div className={styles.mostrar_nuevo_reclamo}
                     style={{margin: '1rem', flexWrap: 'wrap', rowGap: '1rem'}}>
                    <div className={styles.mostrar} style={{height: '2.5rem'}}>
                        <p>{traduccion.claimTable?.mostrar}</p>
                        <Form.Select onChange={handlePerPage} size="sm" id="show">
                            <option value="4">4</option>
                            <option value="8">8</option>
                            <option value="20" selected={true}>20</option>
                        </Form.Select>
                    </div>
                    {!isStuff &&
                        <>
                            <div className={thisStyles.btn_all_claims}
                                 style={{background: "#5A68E8FF", height: '2.5rem'}}
                                 onClick={() => handleSearchType("")}>
                                {traduccion?.claim?.todos}
                            </div>
                            <div className={thisStyles.btn_all_claims}
                                 style={{background: "darkgreen", height: '2.5rem'}}
                                 onClick={() => handleSearchType(1)}>
                                Survey Online
                            </div>
                            <div className={thisStyles.btn_all_claims}
                                 style={{background: "rebeccapurple", height: '2.5rem'}}
                                 onClick={() => handleSearchType(0)}>
                                Recovery Process
                            </div>
                            <Button disabled={loading2} style={{
                                minWidth: '15ch',
                                display: 'flex',
                                alignItems: "center",
                                height: '2.5rem',
                                justifyContent: 'center'
                            }} onClick={downloadExcel}>
                                {loading2 && <Spinner animation="border" size="sm"/>}
                                {!loading2 &&
                                    <>
                                        <RiFileExcel2Line
                                            style={{marginRight: '.5rem'}}/> {traduccion?.claim?.exportarExcel}
                                    </>
                                }
                            </Button>
                        </>
                    }
                    <div className={styles.dropdown}>
                        <DropdownButton id="dropdown-basic-button"
                                        title={traduccion.claimTable?.newClaim || "dropdown"}>
                            <Dropdown.Item onClick={handleModalClaims}>Survey Online</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleModalClaims("noMap")}>Recovery process
                                only</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                <div className={styles.contiene_tabla}>
                    <Table className={styles.tabla} borderless hover>
                        <thead>
                        <tr>
                            <th>Ref.RSK <a onClick={() => sortParams("ref")}> {refOrder ? <FaSortUp/> :
                                <FaSortDown/>}</a></th>
                            <th>{traduccion?.claim?.exporters} <a
                                onClick={() => sortParams("exp")}> {exporterOrder ? <FaSortUp/> :
                                <FaSortDown/>}</a></th>
                            <th>POL <a onClick={() => sortParams("pol")}> {polOrder ? <FaSortUp/> :
                                <FaSortDown/>}</a></th>
                            <th className={styles.th_container_nr}>{traduccion?.claim?.container} <a
                                onClick={() => sortParams("con")}> {containerOrder ? <FaSortUp/> :
                                <FaSortDown/>}</a></th>
                            <th>{traduccion.claimTable?.dEstado} <a
                                onClick={() => sortParams("days")}> {daysOrder ?  <FaSortUp/> : <FaSortDown/>}</a>
                            </th>
                            <th style={{minWidth: '11ch'}}>Time bar <a
                                onClick={() => sortParams("time")}> {timeOrder ? <FaSortUp/> : <FaSortDown/>}</a>
                            </th>
                            <th>{traduccion?.claimTable?.user} <a onClick={() => sortParams("rsk")}> {rskOrder ? <FaSortUp/> :
                                <FaSortDown/>}</a></th>
                            <th>{traduccion.claimTable?.estado} <a
                                onClick={() => sortParams("st")}> {statusOrder ? <FaSortUp/> :
                                <FaSortDown/>}</a></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody style={{
                            position: 'relative',
                            // height:600
                        }}>
                        {
                            loading ?
                                <div style={{
                                    position: "absolute",
                                    height: "200px",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",

                                }}>
                                    <div className={thisStyles.loader}></div>
                                </div> :
                                (
                                    listaClaim?.length > 0 ?
                                        listaClaim?.map((datos, idx) => {
                                            let rskClaim = "RSK" + datos.createdTime.slice(0, 4) + "/" + datos.id
                                            //console.log({datos})
                                            return (
                                                <tr key={idx} className={styles.tr_data}>
                                                    {/*<td>RSK{datos.createdTime.slice(0,4)}/{datos.id}</td>*/}

                                                    <td style={{position: 'relative'}}>{rskClaim}
                                                        <div style={{
                                                            position: "absolute",
                                                            top: 0,
                                                            bottom: 0,
                                                            left: 70,
                                                            color: '#0000ee19',
                                                            overflow: 'hidden'
                                                        }}>
                                                            {datos.transportId === 1 &&
                                                                <IoMdBoat style={{fontSize: 100, height: '100%'}}/>
                                                            }
                                                            {
                                                                datos.transportId === 2 &&
                                                                <IoMdAirplane style={{fontSize: 100, height: '100%'}}/>
                                                            }
                                                            {
                                                                datos.transportId === 3 &&
                                                                <IoMdBus style={{fontSize: 100, height: '100%'}}/>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>{datos.exporterName}</td>
                                                    <td>{datos.pol}</td>
                                                    <td>{datos.containerNumber ?? datos.containerName}</td>
                                                    <td>{handleStatusDays(datos.claimStatusDate)}</td>
                                                    <td>{datos.timeBar?.split("T")[0]}</td>
                                                    <td>
                                                        {session.headOfArea ?
                                                            <Form.Select
                                                                value={datos.rskUser !== null ? datos.rskUser : " --- "}
                                                                onClick={getAllUsers}
                                                                onChange={(e) => abrirModalUsers(datos.id, e, rskClaim)}>
                                                                <option disabled={true}
                                                                        value={datos.rskUser !== null ? datos.rskUser : " --- "}>{datos.rskUser !== null ? datos.rskUser : " --- "}</option>
                                                                {
                                                                    users?.map((user, idx) => {

                                                                        return (
                                                                            <option key={idx}
                                                                                    value={user.id}>{user.email}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </Form.Select>
                                                            : <div disabled={true}
                                                                   value={datos.rskUser !== null ? datos.rskUser : " --- "}>{datos.rskUser !== null ? datos.rskUser : " --- "}</div>
                                                        }
                                                    </td>

                                                    <td>{datos.claimStatusDescription}</td>
                                                    <td style={{textAlign: "center"}}>

                                                        <div className={stylesTabla.botones_botones_surveyor}
                                                             style={{display: 'flex'}}>
                                                            <div
                                                                onClick={() => editClaim(datos.id)}
                                                                className={stylesTabla.boton_icono}>
                                                                <BsPencil/>
                                                            </div>
                                                            <div
                                                                onClick={() => handleShow(datos.id)}
                                                                className={stylesTabla.boton_icono}>
                                                                <BsTrash style={{color: 'red'}}/>
                                                            </div>
                                                            <div>
                                                                {/*<svg width="22" height="18" viewBox="0 0 22 18" fill="none"*/}
                                                                {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                                                {/*    <path*/}
                                                                {/*        d="M10.819 0C16.211 0 20.697 3.88 21.638 9C20.698 14.12 16.211 18 10.819 18C5.427 18 0.941 14.12 0 9C0.94 3.88 5.427 0 10.819 0ZM10.819 16C12.8585 15.9996 14.8374 15.3068 16.4319 14.0352C18.0263 12.7635 19.1419 10.9883 19.596 9C19.1402 7.0133 18.0239 5.24 16.4296 3.97003C14.8353 2.70005 12.8573 2.00853 10.819 2.00853C8.7807 2.00853 6.80269 2.70005 5.20838 3.97003C3.61406 5.24 2.49777 7.0133 2.042 9C2.4961 10.9883 3.61167 12.7635 5.20614 14.0352C6.80061 15.3068 8.77953 15.9996 10.819 16ZM10.819 13.5C9.62553 13.5 8.48093 13.0259 7.63702 12.182C6.79311 11.3381 6.319 10.1935 6.319 9C6.319 7.80653 6.79311 6.66193 7.63702 5.81802C8.48093 4.97411 9.62553 4.5 10.819 4.5C12.0125 4.5 13.1571 4.97411 14.001 5.81802C14.8449 6.66193 15.319 7.80653 15.319 9C15.319 10.1935 14.8449 11.3381 14.001 12.182C13.1571 13.0259 12.0125 13.5 10.819 13.5ZM10.819 11.5C11.482 11.5 12.1179 11.2366 12.5868 10.7678C13.0556 10.2989 13.319 9.66304 13.319 9C13.319 8.33696 13.0556 7.70107 12.5868 7.23223C12.1179 6.76339 11.482 6.5 10.819 6.5C10.156 6.5 9.52007 6.76339 9.05123 7.23223C8.58239 7.70107 8.319 8.33696 8.319 9C8.319 9.66304 8.58239 10.2989 9.05123 10.7678C9.52007 11.2366 10.156 11.5 10.819 11.5Z"*/}
                                                                {/*        fill="#5B69EA"/>*/}
                                                                {/*</svg>*/}
                                                            </div>
                                                            {datos.hasMap === false &&
                                                                <div style={{
                                                                    width: '4rem',
                                                                    height: '100%',
                                                                    background: 'rebeccapurple',
                                                                    fontSize: 10,
                                                                    lineHeight: 1.4,
                                                                    padding: '8px 0',
                                                                    fontWeight: "bolder",
                                                                    color: 'white',
                                                                    borderRadius: 5
                                                                }}>
                                                                    Recovery Process
                                                                </div>

                                                            }
                                                            {(datos.status === 2 ||
                                                                    //datos.timeline.find((obj)=> obj.description === "NEW"))
                                                                    (datos.hasMap && datos.lastTimelineId !== 10)) &&
                                                                <div style={{
                                                                    width: '4rem',
                                                                    height: '100%',
                                                                    background: 'darkgreen',
                                                                    fontSize: 10,
                                                                    lineHeight: 1.4,
                                                                    padding: '8px 0',
                                                                    fontWeight: "bolder",
                                                                    color: 'white',
                                                                    borderRadius: 5
                                                                }}>
                                                                    Survey Online
                                                                </div>

                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan="8"
                                                style={{textAlign: "center"}}>{traduccion?.factura?.noData}</td>
                                        </tr>
                                )

                        }
                        </tbody>
                    </Table>
                </div>
                <Pagination style={{justifyContent: 'center'}}>
                    {active > 0 &&
                        <Pagination.Prev onClick={() => goToPage(active - 1)}/>
                    }
                    {items}
                    {active < totalPages &&
                        <Pagination.Next onClick={() => goToPage(active + 1)}/>
                    }
                </Pagination>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                >
                    <Modal.Body>
                        <div className={stylesTabla.modal_body} style={{padding: '.5rem'}}>
                            <div className={stylesTabla.modal_body_header}>
                                <h3>{traduccion?.clients?.rUSure}</h3>
                            </div>
                            <div className={stylesTabla.modal_body_body}>
                                <div className={stylesTabla.modal_body_body_text}>
                                    <p style={{fontWeight: '500'}}>Claim ID: <span
                                        style={{color: '#f55', fontWeight: '900'}}>{modalId}</span></p>
                                </div>
                                <div className={stylesTabla.modal_body_body_buttons}
                                     style={{justifyContent: 'space-around', display: 'flex', marginTop: '2rem'}}>
                                    <Button onClick={() => setShowModal(false)}>
                                        {traduccion?.clients?.cancelar}
                                    </Button>
                                    <Button variant={"danger"} onClick={() => deleteClaim(modalId)}>
                                        {traduccion?.clients?.borrar}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                <ModalExportadores
                    arr={exporter}
                    showDes={showModalDes}
                    createNew={nuevoClaimModal}
                    onHide={() => setShowModalDes(false)}
                    // loading={loadingFile}
                    hasMap={mapa}
                    oneExporter={oneExporter}
                    setOneExporter={setOneExporter}
                />
                <Modal
                    show={showModalUsers}
                    onHide={() => setShowModalUsers(false)}
                    centered
                >
                    <Modal.Body>
                        <p style={{textAlign: "center", margin: '1rem'}}>
                            Are you sure you want to assign this
                            claim {modalStuff.rskClaim} to <b>{modalStuff.descUser} </b> ?
                        </p>
                        <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '2rem'}}>
                            <Button disabled={loading} variant="warning" onClick={() => setShowModalUsers(false)}>
                                {traduccion?.others?.close}
                            </Button>
                            <Button disabled={loading} variant={"primary"} style={{marginLeft: '1rem'}}

                                    onClick={() => changeUserClaim(modalStuff.claim, modalStuff.idUser)}
                            >
                                {loading && <Spinner/>}
                                {!loading && traduccion?.others?.Accept}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};
