import React, {useContext, useEffect, useState} from 'react';
import stylesSurv from "../surveyors/new_surveyor.module.css";
import {Col, Form, Row} from "react-bootstrap";
import LangContext from "../../../context/LangContext";
import DBContext from "../../../context/DBContext";
import styles from "../surveyors/new_surveyor.module.css";

const Bank = (props) => {

    const {traduccion} = useContext(LangContext);
    const {currency} = useContext(DBContext);


    const [bankInformation, setBankInformation] = useState({
        accountNumber: props.bankInformation.accountNumber,
        area_id: props.bankInformation.area_id,
        bankAddress: props.bankInformation.bankAddress,
        bankHolderAddress: props.bankInformation.bankHolderAddress,
        bankHolderName: props.bankInformation.bankHolderName,
        bankName: props.bankInformation.bankName,
        bicCode: props.bankInformation.bicCode,
        currency: props.bankInformation.currency,
        ibanCode: props.bankInformation.ibanCode,
        surveyor: props.bankInformation.surveyor,
        swiftCode: props.bankInformation.swiftCode,
    });

    const [bankInformationError, setBankInformationError] = useState({
        accountNumber: "",
        area_id: "",
        bankAddress: "",
        bankHolderAddress: "",
        bankHolderName: "",
        bankName: "",
        bicCode: "",
        currency: "",
        ibanCode: "",
        surveyor: "",
        swiftCode: "",
        typeCode: "",
        isValidAccountNumber: false,
        isValidAreaId: false,
        isValidBankAddress: false,
        isValidBankHolderAddress: false,
        isValidBankHolderName: false,
        isValidBankName: false,
        isValidBicCode: false,
        isValidCurrency: false,
        isValidIbanCode: false,
        isValidSurveyor: false,
        isValidSwiftCode: false,
        isValidTypeCode: false
    })

    const [typeCode, setTypeCode] = useState("0");

    const validateBankInformation = (input, value) => {
        switch (input) {
            case 'accountNumber':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: validateNumber(input, value),
                    isValidAccountNumber: validateNumber(input, value) === undefined
                })
                break;
            case 'areas':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: validateString(input, value),
                    isValidAreasEntity: validateString(input, value) === undefined
                })
                break;
            case 'bankAddress':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: validateLength(input, value, 1, 200),
                    isValidBankAddress: validateLength(input, value, 1, 200) === undefined
                })
                break;
            case 'bankHolderAddress':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: validateLength(input, value, 1, 200),
                    isValidBankHolderAddress: validateLength(input, value, 1, 200) === undefined
                })
                break;
            case 'bankHolderName':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: validateLength(input, value, 1, 200),
                    isValidBankHolderName: validateLength(input, value, 1, 200) === undefined
                })
                break;
            case 'bankName':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: validateString(input, value),
                    isValidBankName: validateString(input, value) === undefined
                })
                break;
            case 'currency':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: Number(value) === 0 && `Currency is required`,
                    isValidCurrency: Number(value) !== 0
                })
                break;
            case 'area_id':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: Number(value) === 0 && `Area is required`,
                    isValidAreaId: Number(value) !== 0
                })
                break;
            case 'ibanCode':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: validateIbanCode(input, value),
                    isValidIbanCode: validateIbanCode(input, value) === undefined
                })
                break;
            case 'bicCode':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: validateSwiftBicCode(input, value),
                    isValidBicCode: validateSwiftBicCode(input, value) === undefined
                })
                break;
            case 'swiftCode':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: validateSwiftBicCode(input, value),
                    isValidSwiftCode: validateSwiftBicCode(input, value) === undefined
                })
                break;
            case 'typeCode':
                setBankInformationError({
                    ...bankInformationError,
                    [input]: Number(value) === 0 && `Swift/Bic code is required`,
                    isValidTypeCode: Number(value) === 0
                })
                break;
            default:
                break;
        }
    }

    const validateString = (input, value) => {
        const regexString = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]{0,50}$/;
        if (!value.toString().trim().length > 0) return `The ${input} field is required`;
        if (!regexString.test(value)) return `The ${input} is not valid (Must be letters only)`;
    }
    const validateLength = (input, value, from, to) => {
        const regexLength = new RegExp(`^.{${from},${to}}$`);
        if (!value.toString().trim().length > 0) return `The ${input} field is required`;
        if (!regexLength.test(value)) return `The field ${input} is not valid (Must be between ${from} and ${to} characters)`;
    }
    const validateNumber = (input, value) => {
        const regexNumbers = /^\d+$/;
        if ((value.toString().trim().length === 0 || value === 0)) return `The ${input} field is required`;
        if (!regexNumbers.test(value)) return `The field ${input} is not valid (Must be numbers only)`;
    }
    const validateSwiftBicCode = (input, value) => {
        const regexSwiftBicCode = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
        if (!value.toString().trim().length > 0) return `The ${input} field is required`;
        if (!regexSwiftBicCode.test(value)) return `The field ${input} is not valid`;
    }
    const validateIbanCode = (input, value) => {
        const regexIbanCode = /^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/;
        if (!value.toString().trim().length > 0) return `The ${input} field is required`;
        if (!regexIbanCode.test(value)) return `The field ${input} is not valid`;
    }

    useEffect(() => {
        props.handleBankInfo(bankInformation)
    }, [bankInformation])

    useEffect(() => {
        if(bankInformation.area_id === "1"){
            setBankInformation({
                ...bankInformation,
                ["ibanCode"]: ""
            });
        }
    },[bankInformation.area_id])

    useEffect(() => {
        if(bankInformation.swiftCode === "" && bankInformation.bicCode !== ""){
            setTypeCode("1")
        }
        if(bankInformation.swiftCode !== "" && bankInformation.bicCode === ""){
            setTypeCode("2")
        }
    },[bankInformation.swiftCode, bankInformation.bicCode])


    const handleChange = (e) => {
        const {value, name} = e.target;
        validateBankInformation(name, value)
        setBankInformation({
            ...bankInformation,
            [name]: value
        });
    }
    const handleTypeCode = (e) => {
        const {value, name} = e.target;
        validateBankInformation(name, value)
        cleanTypeCodeInput(value)
        setTypeCode(value)
    }

    const cleanTypeCodeInput = (value) => {
        if(value === "1"){
            setBankInformation({
                ...bankInformation,
                ["swiftCode"]: ""
            });
        }
        if(value === "2"){
            setBankInformation({
                ...bankInformation,
                ["bicCode"]: ""
            });
        }
    }

    return (<>
            <Row className={stylesSurv.contain_all_surveyors} style={{margin: '1.5rem 0'}}>
                <Col sm={12}>
                    <div className={stylesSurv.all_surveyors}>
                        <div className={stylesSurv.title}>{traduccion.newSurveyor?.detallesBancarios}</div>
                        <Row>
                            <Col>
                                <div>{traduccion.newSurveyor?.nombreBanco}</div>
                                <p className={styles.errors}>{bankInformationError.bankName}</p>
                                <Form.Group controlId="bankName">
                                    <Form.Control value={bankInformation.bankName}
                                                  onChange={e => handleChange(e)}
                                                  className={stylesSurv.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.bancoPlaceHolder}
                                                  name="bankName"
                                                  isValid={bankInformationError.isValidBankName}
                                    />
                                </Form.Group>

                                <div>{traduccion.newSurveyor?.cuentaBancaria}</div>
                                <p className={styles.errors}>{bankInformationError.accountNumber}</p>
                                <Form.Group controlId="accountNumber">
                                    <Form.Control value={bankInformation.accountNumber}
                                                  onChange={e => handleChange(e)}
                                                  className={stylesSurv.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.cuentaBancariaPlaceHolder}
                                                  name="accountNumber"
                                                  isValid={bankInformationError.isValidAccountNumber}
                                    />
                                </Form.Group>

                                <div>
                                    <div>{traduccion.newSurveyor?.tipoCodigo}</div>
                                    <p className={styles.errors}>{bankInformationError.typeCode}</p>
                                    <Form.Group controlId="typeCode">
                                        <Form.Select onChange={e => handleTypeCode(e)}
                                                     className={stylesSurv.selects_input}
                                                     name="typeCode"
                                                     value={typeCode}
                                                     style={{width: '20ch'}}
                                        >
                                            <option value="0">Select Type Code</option>
                                            <option value="1">{traduccion.newSurveyor?.codigoBic}</option>
                                            <option value="2">{traduccion.newSurveyor?.codigoSwift}</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>

                                <div>
                                    {typeCode === "1" && (
                                        <Form.Group controlId="bicCode">
                                            <p className={styles.errors}>{bankInformationError.bicCode}</p>
                                            <Form.Control value={bankInformation.bicCode}
                                                          style={{width: '22ch'}}
                                                          onChange={e => handleChange(e)}
                                                          placeholder={traduccion.newSurveyor?.codigoBic}
                                                          className={stylesSurv.selects_input} type="text"
                                                          name="bicCode"
                                                          isValid={bankInformationError.isValidBicCode}
                                            />
                                        </Form.Group>
                                    )}
                                    {typeCode === "2" && (
                                        <Form.Group controlId="swiftCode">
                                            <p className={styles.errors}>{bankInformationError.swiftCode}</p>
                                            <Form.Control value={bankInformation.swiftCode}
                                                          style={{width: '22ch'}}
                                                          onChange={e => handleChange(e)}
                                                          placeholder={traduccion.newSurveyor?.codigoSwift}
                                                          className={stylesSurv.selects_input} type="text"
                                                          name="swiftCode"
                                                          isValid={bankInformationError.isValidSwiftCode}
                                            />
                                        </Form.Group>
                                    )}
                                </div>
                            </Col>
                            <Col>

                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div>
                                        <div>Currency</div>
                                        <Form.Group controlId="currency">
                                            <p className={styles.errors}>{bankInformationError.currency}</p>
                                            <Form.Select onChange={e => handleChange(e)}
                                                         className={stylesSurv.selects_input}
                                                         style={{width: '18ch'}}
                                                         name="currency"
                                                         value={bankInformation.currency}
                                                         isValid={bankInformationError.isValidCurrency}
                                            >
                                                <option value="0">Select currency</option>
                                                {currency.map((currency, index) => (
                                                    <option key={index}
                                                            value={currency.id}>{currency.description}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>


                                <div>{traduccion.newSurveyor?.direccionCuenta}</div>
                                <p className={styles.errors}>{bankInformationError.bankAddress}</p>
                                <Form.Group controlId="bankAddress">
                                    <Form.Control value={bankInformation.bankAddress}
                                                  onChange={e => handleChange(e)}
                                                  className={stylesSurv.selects_input}
                                                  type="text"
                                                  placeholder={traduccion.newSurveyor?.direccionCuentPlaceHolder}
                                                  name="bankAddress"
                                                  isValid={bankInformationError.isValidBankAddress}
                                    />
                                </Form.Group>

                                <div>{traduccion.newSurveyor?.ubicacion}</div>
                                <p className={styles.errors}>{bankInformationError.area_id}</p>
                                <Form.Group controlId="area">
                                    <Form.Select onChange={(e) => handleChange(e)}
                                                 className={stylesSurv.selects_input}
                                                 name="area_id"
                                                 value={bankInformation.area_id}
                                                 isValid={bankInformationError.isValidAreaId}
                                    >
                                        <option value="0">{traduccion?.newSurveyor?.selectArea}</option>
                                        <option value="1">{traduccion?.newSurveyor?.selectAreaOtra}</option>
                                        <option value="3">{traduccion?.newSurveyor?.selectAreaEuropa}</option>
                                    </Form.Select>
                                </Form.Group>

                                {
                                    bankInformation.area_id == "3" && <>
                                        <div>{traduccion.newSurveyor?.codigoIBAN}</div>
                                        <p className={styles.errors}>{bankInformationError.ibanCode}</p>
                                        <Form.Group controlId="ibanCode" style={{width: '25ch'}}>
                                            <Form.Control value={bankInformation.ibanCode}
                                                          onChange={e => handleChange(e)}
                                                          className={stylesSurv.selects_input}
                                                          type="text"
                                                          placeholder="AB1234567890123"
                                                          name="ibanCode"
                                                          isValid={bankInformationError.isValidIbanCode}
                                            />
                                        </Form.Group></>
                                }

                            </Col>
                            <hr></hr>
                            <div className={stylesSurv.title}>{traduccion.newSurveyor?.titularCuenta}</div>

                            <div id="accountName">{traduccion.newSurveyor?.nombreTitularCuenta}</div>
                            <p className={styles.errors}>{bankInformationError.bankHolderName}</p>
                            <Form.Group controlId="bankHolderName">
                                <Form.Control value={bankInformation.bankHolderName}
                                              onChange={e => handleChange(e)}
                                              className={stylesSurv.selects_input}
                                              type="text"
                                              name={"bankHolderName"}
                                              placeholder={traduccion.newSurveyor?.nombreTitularCuentaPlaceHolder}
                                              isValid={bankInformationError.isValidBankHolderName}
                                />
                            </Form.Group>

                            <div>{traduccion.newSurveyor?.direccion}</div>
                            <p className={styles.errors}>{bankInformationError.bankHolderAddress}</p>
                            <Form.Group controlId="bankHolderAddress">
                                <Form.Control value={bankInformation.bankHolderAddress}
                                              onChange={e => handleChange(e)}
                                              className={stylesSurv.selects_input}
                                              type="text"
                                              name={"bankHolderAddress"}
                                              placeholder={traduccion.newSurveyor?.direccionPlaceHolder}
                                              isValid={bankInformationError.isValidBankHolderAddress}
                                />
                            </Form.Group>

                            <div style={{width: '100%'}}></div>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Bank;
