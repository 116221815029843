import {Document, Page, Text, View,StyleSheet, Font} from '@react-pdf/renderer';
import fuenteTitulo from './fonts/PlayfairDisplaySC.ttf';
import fuenteTexto from './fonts/Montserrat-Light.ttf';
import fuenteTextoBold from './fonts/Montserrat-Medium.ttf';


// Register font
Font.register({ family: 'PlayfairDisplaySC', src: fuenteTitulo });
Font.register({ family: 'Montserrat-Light', src: fuenteTexto });
Font.register({ family: 'Montserrat-Medium', src: fuenteTextoBold });

// Reference font
const styles = StyleSheet.create({
    title: {
        marginTop:60,
        marginBottom:60,
        fontFamily: 'PlayfairDisplaySC',
        fontSize: 24,
    },
    claimData: {
        fontSize: 13,
        marginLeft: 58,
        textAlign:'left',
        fontFamily: 'Montserrat-Medium',
    },
    fullText: {
        textAlign:'justify',
        fontSize: 13,
        margin:58,
        fontFamily: 'Montserrat-Light',
    },
    footer:{
        fontFamily: 'Montserrat-Medium',
        marginTop:160,
        textAlign:'left',
    }

})
//fecha de hoy para poner en el texto
const today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
const yyyy = today.getFullYear();
const fecha = dd + '/' + mm + '/' + yyyy;

export const AssignmentOfRights = ({claimForm,especie}) => {

    let arr = [];
    let str = "";
    //console.log(claimForm)
    if(claimForm?.cargo?.fruits) {
        if (claimForm?.cargo?.fruits.length > 0) {
            claimForm?.cargo?.fruits.forEach((fruit) => {
                if (fruit.id !== 0 && fruit.id !== "") {
                    arr.push(fruit.id);
                }
            })
        }
    }
    if (arr.length > 0 && especie.length > 0) {
        arr.forEach((id) => {
            especie.forEach((especie) => {
                if (parseInt(id) === especie.id) {
                    str += especie.description + ", ";
                }
            })
        })
        str = str.substring(0, str.length - 2);
    }

    return (
        <Document>
            <Page size="A4" style={{textAlign:'center'}}>
                <View>
                    <Text style={styles.title}>Assignment of Rights</Text>
                </View>
                <View style={styles.claimData}>
                    <Text>
                        M/Vessel: {claimForm?.vessel}
                    </Text>
                    <Text>
                        POL: {claimForm?.pol}
                    </Text>
                    <Text>
                        POD: {claimForm?.pod}
                    </Text>
                    <Text>
                        BL - AWB - CRT: {claimForm?.blAwb}
                    </Text>
                    <Text>
                        Container Number: {claimForm?.cargo?.containerNumber}
                    </Text>
                    <Text>
                        Product: {str}
                    </Text>
                    <Text>
                        Date: {fecha}
                    </Text>
                </View>
                <View style={styles.fullText}>
                    <Text style={{marginBottom:20}}>
                        We Herewith assign all our rights whatsoever with all joined titles, interests and demands against third persons or parties resulting of or arising out of this incident to cargo Messrs. {claimForm?.exporterName}
                    </Text>
                    <Text>
                        Furthermore, we undertake to produce any documents and other conclusive material, any information within our power and in any way that Messrs. {claimForm?.exporterName}  may reasonably require in order that they can enforce the claims and rights subject to this that any costs or expenses incurred would be borne by them.
                    </Text>
                    <Text style={styles.footer}>
                        {claimForm?.consigneeName}
                    </Text>
                </View>
            </Page>
        </Document>
    )
}
