import styles from "../../../home/table_stuffing/tabla_stuff.module.css";
import {Table} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import DBContext from "../../../../context/DBContext";
import {BsPencil, BsEye, BsTrash, BsArrowCounterclockwise} from "react-icons/bs";
import axios from "axios";
import {urlConfig} from "../../../../config/urlConfig";
import LangContext from "../../../../context/LangContext";
import {toast} from "react-toastify";
import {FaSortDown, FaSortUp} from "react-icons/fa";

export const TablaProfiles = ({
                                  searchUrl,
                                  setNewProfiles,
                                  setOneProfile
                              }) => {
    let {cambioProfiles, setCambioProfiles} = React.useContext(DBContext);
    const {traduccion} = useContext(LangContext);

    const [perPage, setPerPage] = useState(20);

    const initialDescription = useRef(true);
    const initialProfileStatus = useRef(true);

    const [description, setDescription] = useState(true);
    const [profileStatus, setProfileStatus] = useState(true);

    const [profiles, setProfiles] = useState([]);

    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portAccount}/api/v1/accounts/profiles?page=0&size=${perPage}`);


    useEffect(() => {
        if (searchUrl === null) return;
        let header = {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        axios.get(searchUrl, header).then((response) => {
            setProfiles(response.data);
            //isSearchUrl.current = true;
        }).catch((error) => {
            console.log({error})
        })
    }, [searchUrl])

    const editProfile = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        axios.get(`${urlConfig.allUrl}${urlConfig.portAccount}/api/v1/accounts/profile/${id}`, header)
            //axios.get(`${urlConfig.urlKite}${urlConfig.portAccount}/api/v1/accounts/profile/${id}`, header)

            .then((response) => {
                //console.log(response);
                setOneProfile(response.data);
                setNewProfiles(1);
            }).catch((error) => {
            //console.log(error);
        })
    }
    const deleteProfile = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        axios.delete(`${urlConfig.allUrl}${urlConfig.portAccount}/api/v1/accounts/profile/${id}`, header)
            .then((response) => {
                //console.log(response);
                toast.success("Perfil eliminado con éxito");
                setCambioProfiles(!cambioProfiles);
            }).catch((error) => {
            toast.error("Error al eliminar perfil")
            //console.log(error);
        })
    }

    useEffect(() => {
        if (initialDescription.current) {
            initialDescription.current = false;
            return;
        }
        if (!description) {
            if (!url.includes("sort=description,desc") && url.includes("sort=description,asc")) {
                setUrl(url.replace("&sort=description,asc", "&sort=description,desc"));
            } else {
                setUrl(url + "&sort=description,desc");
            }
        } else {
            if (!url.includes("sort=description,asc") && url.includes("sort=description,desc")) {
                setUrl(url.replace("&sort=description,desc", "&sort=description,asc"))
            } else {
                setUrl(url + "&sort=description,asc");
            }
        }
    }, [description]);

    useEffect(() => {
        if (initialProfileStatus.current) {
            initialProfileStatus.current = false;
            return;
        }
        if (!profileStatus) {
            if (!url.includes("sort=profileStatus,desc") && url.includes("sort=profileStatus,asc")) {
                setUrl(url.replace("&sort=profileStatus,asc", "&sort=profileStatus,desc"));
            } else {
                setUrl(url + "&sort=profileStatus,desc");
            }
        } else {
            if (!url.includes("sort=profileStatus,asc") && url.includes("sort=profileStatus,desc")) {
                setUrl(url.replace("&sort=profileStatus,desc", "&sort=profileStatus,asc"))
            } else {
                setUrl(url + "&sort=profileStatus,asc");
            }
        }
    }, [profileStatus]);

    useEffect(() => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        axios.get(url, header).then((response) => {
            setProfiles(response.data);
            initialDescription.current = false;
            initialProfileStatus.current = false;
        }).catch((error) => {
            console.log({error})
        })
    }, [url]);


    const resetSort = () => {
        initialDescription.current = true;
        initialProfileStatus.current = true;
        setDescription(true);
        setProfileStatus(true);
        setUrl(`${urlConfig.allUrl}${urlConfig.portAccount}/api/v1/accounts/profiles?page=0&size=${perPage}`)
    }

    const sortParams = (sort) => {
        if (sort === "description") {
            setDescription(!description);
        }
        if (sort === "profileStatus") {
            setProfileStatus(!profileStatus);
        }
    }


    return (
        <>
            <div className={styles.contiene_tabla}>
                <Table borderless hover className={styles.tabla}>
                    <thead>
                    <tr>
                        <th>{traduccion?.clients?.name} <a
                            onClick={() => sortParams("description")}> {description ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion?.clients?.status} <a
                            onClick={() => sortParams("profileStatus")}> {profileStatus ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th><a onClick={() => resetSort()}><BsArrowCounterclockwise/></a></th>
                    </tr>
                    </thead>
                    <tbody>
                    {profiles.length > 0 ? (
                        profiles.map((profile, idx) => (
                            <tr key={idx} className={styles.tr_data}>
                                <td>
                                    {profile.lastName
                                        ? `${profile.firstName} ${profile.lastName}`
                                        : profile.name}
                                </td>
                                <td>{profile.status ? `${traduccion?.mantendores?.activo}` : `${traduccion?.mantendores?.inactivo}`}</td>
                                <td>
                                    <div
                                        className={styles.botones_botones_surveyor}
                                        style={{display: "flex"}}
                                    >
                                        <div
                                            onClick={() => {
                                                editProfile(profile.id)
                                            }}
                                            className={styles.boton_icono}
                                        >
                                            <BsPencil/>
                                        </div>
                                        <div
                                            onClick={() => {
                                                deleteProfile(profile.id)
                                            }}
                                            className={styles.boton_icono}
                                        >
                                            <BsTrash style={{color: "red"}}/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" style={{textAlign: "center"}}>
                                No hay datos disponibles
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </>
    );
};
