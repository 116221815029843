import styles from "../surveyors/mantenedor_surveyor.module.css";
import {Link} from "react-router-dom";
import {Button, Form, Pagination} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";

import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {TablaCompanias} from "./TablaCompanias";
import {NewSurveyor} from "../surveyors/NewSurveyor";
import {NewCompanySurveyor} from "./NewCompanySurveyor";
import {urlConfig} from "../../../config/urlConfig";
import axios from "axios";
import {toast} from "react-toastify";
import DBContext from "../../../context/DBContext";
import {useParamsUrl} from "../useParamsUrl";
import LangContext from "../../../context/LangContext";

export const MantenedorCompania = ({newCompany,setNewCompany,setRouteActive}) => {

    const {setCambioCompany,cambioCompany, setCompany,companyPages} = useContext(DBContext);
    const [oneCompany, setOneCompany] = useState("");
    const {traduccion} = useContext(LangContext);
    const {searchUrl, handleSearch} = useParamsUrl(urlConfig.searchCompanySurveyorUrl);


    // const [perPage, setPerPage] = useState(2);
    const [active, setActive] = useState(0);
    const [totalPages, setTotalPages] = useState(companyPages?.totalPages-1 || 0);
    const [url, setUrl] = useState(urlConfig.searchCompanySurveyorUrl+"?page=0&size=10");

    const [editCompanySurveyor, setEditCompanySurveyor] = useState(false);

    //console.log({newCompany})

    const goToStart=()=>{
        setRouteActive("/")
    }
    const handleNewCompany = () => {
        setEditCompanySurveyor(false);
        setOneCompany("");
        setNewCompany(1)
    }

    useEffect(() => {
        setCambioCompany(!cambioCompany);
    }, [])

    const justSearch = () => {
        setActive(0);
        let newUrl = `${searchUrl}&size=${10}&page=${0}`;
        setUrl(newUrl);
        searchCompanySurveyor(newUrl)
    }

    const searchCompanySurveyor = (newUrl) => {
        //console.log("URL", newUrl)
        axios.get(newUrl, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            const {companies} = res.data
            setCompany(companies);
            setTotalPages(res.data.totalPages-1)
            //console.log("COMPANIES", companies)
        }).catch((error) => {
            //console.log(error);
            toast.error(`${traduccion?.errores?.filtrComp}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        });
    }

    const searchCompanySurveyorSort = (newUrl) => {
        axios.get(newUrl, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            const {companies} = res.data
            setCompany(companies);
            setTotalPages(res.data.totalPages-1)
        }).catch((error) => {
            toast.error(`${traduccion?.errores?.errorSorting}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        });
    }



    const goToPage = (page) => {
        //console.log("GO TO PAGE", page)
        let newUrl =(url.replace(/page=\d+/, `page=${page}`))
        //console.log("URL", newUrl)
        setUrl(newUrl)
        setActive(page)
    }

    // useEffect(() => {
    //     //console.log("GET...", url)
    //     searchCompanySurveyor(url)
    // },[url])

    // let numPage = Math.floor(listaClaim.totalItems / perPage);
    let items=[]
    let oneElipsisA = false
    let oneElipsisD = false
    for (let number = 0; number <= totalPages; number++) {
        //add elipsis
        if (number > 2 && number < totalPages - 2) {
            if (number === active + 1 || number === active - 1) {
                items.push(
                    <Pagination.Item className={styles.pagina} key={number} active={number === active} onClick={() => goToPage(number)}>
                        {number+1}
                    </Pagination.Item>,
                );
            }else if(number ===active){
                items.push(
                    <Pagination.Item className={styles.pagina} onClick={()=>goToPage(number)} key={number} active={number === active}>
                        {number+1}
                    </Pagination.Item>,
                );
            }else{
                if (number < active && !oneElipsisA) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisA = true
                }
                if (number > active && !oneElipsisD) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisD = true
                }
            }
        }else{
            items.push(
                <Pagination.Item className={styles.pagina} onClick={()=>goToPage(number)} key={number} active={number === active}>
                    {number+1}
                </Pagination.Item>,
            );
        }
    }




    return (
        <>
            <div className={styles.all_surveyor}>

                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>>
                    {newCompany === 0 &&
                        <span className={styles.bold}>{traduccion?.companySurveyor?.survComp}</span>
                    }
                    {newCompany === 1 &&
                        <><span onClick={()=>setNewCompany(0)} className={styles.like_a}>{traduccion?.companySurveyor?.survComp}</span>>
                            <span className={styles.bold}>{traduccion?.companySurveyor?.NewSurvComp}</span></>
                    }
                </div>
                {newCompany === 0 &&
                    <>
                <div className={styles.title_surveyor}>
                    <p>{traduccion?.companySurveyor?.survComp}</p>
                    <Button onClick={handleNewCompany} className={styles.title_button} variant="primary">{traduccion?.companySurveyor?.NewSurvComp}</Button>
                </div>

                    <div className={stylesfiltro.filtro_total} style={{margin: 0, width: '100%'}}>
                        <div className={stylesfiltro.filtros_busqueda}>
                            <p className={stylesfiltro.title_filter}>{traduccion?.clients?.filtros}</p>
                            <div className={stylesfiltro.opciones_busqueda}>
                                <div>
                                    <label htmlFor="container_select">{traduccion?.clients?.name}</label>
                                    <Form.Control
                                        style={{backgroundColor: '#f1f3fe', boxShadow: 'inset 1px 1px 3px #ccc'}}
                                        onChange={handleSearch} name="name" type="text"
                                        placeholder={traduccion?.mantendores?.nombre}/>
                                </div>
                                <div>
                                    <label htmlFor="estado">{traduccion?.clients?.status}</label>
                                    <Form.Select onChange={handleSearch} className="form-label" id="estado"
                                                 style={{backgroundColor: '#f1f3fe', boxShadow: 'inset 1px 1px 3px #ccc'}}
                                                 name="status">
                                        <option value="">-</option>
                                        <option key={1} value={true}>{traduccion?.clients?.active}</option>
                                        <option key={2} value={false}>{traduccion?.clients?.inactive}</option>
                                    </Form.Select>

                                </div>
                                <Button className={stylesfiltro.boton_filtro} variant="primary" onClick={justSearch}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                            fill="white"/>
                                    </svg>
                                </Button>
                            </div>
                        </div>
                    </div>

                <TablaCompanias
                    setOneCompany={setOneCompany}
                    setNewCompany={setNewCompany}
                    setEditCompanySurveyor={setEditCompanySurveyor}
                    searchCompanySurveyorSort={searchCompanySurveyorSort}/>
                        <Pagination>
                            {active > 0 && <Pagination.Prev onClick={() => goToPage(active - 1)} />}
                            {items}
                            {active < totalPages && (
                                <Pagination.Next onClick={() => goToPage(active + 1)} />
                            )}
                        </Pagination>
                    </>
                }
                {newCompany === 1 && <NewCompanySurveyor oneCompany={oneCompany} editCompanySurveyor={editCompanySurveyor} />}

            </div>
        </>
    )
}
