import styles from "../surveyors/mantenedor_surveyor.module.css";
import {Link} from "react-router-dom";
import stylesFiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import TableBilling from "../billing/tableBilling";
import {Button, Spinner} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {TablaBillingSurveyor} from "./TablaBillingSurveyor";
import {FiltroBillingSurveyor} from "./FiltroBillingSurveyor";
import DBContext from "../../../context/DBContext";
import {urlConfig} from "../../../config/urlConfig";
import axios from "axios";
import {toast} from "react-toastify";
import LangContext from "../../../context/LangContext";


export const BillingSurveyor = ({setRouteActive, newBillingSurveyor}) => {
    const {traduccion} = useContext(LangContext);

    const [searchUrl, setSearchUrl] = useState(null);

    const searchClaims = (url) => {
        setSearchUrl(url)
    };

    const goToStart = () => {
        setRouteActive("/")
    }

    return (
        <>
            <div className={styles.all_surveyor}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>>
                    {newBillingSurveyor === 0 &&
                        <span className={styles.bold}>{traduccion?.ordenCobro?.facturaInspector}</span>
                    }
                </div>

                {newBillingSurveyor === 0
                    &&
                    <>
                        <p className={stylesFiltro.title_claimed}
                           style={{marginLeft: '0rem'}}>{traduccion?.ordenCobro?.facturaInspector}</p>
                        <FiltroBillingSurveyor searchClaims={searchClaims}/>
                        <TablaBillingSurveyor searchUrl={searchUrl}/>
                    </>
                }
            </div>

        </>
    );
}
