import React, {useContext, useState} from 'react';
import LangContext from "../../../context/LangContext";
import TablePago from "./TablePago";
import styles from "../surveyors/mantenedor_surveyor.module.css";
import {Link} from "react-router-dom";
import stylesFiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {FiltroPago} from "./FiltroPago";

const OrdenPago = ({setRouteActive}) => {

    const {traduccion} = useContext(LangContext);

    const [searchUrl, setSearchUrl] = useState(null);

    const searchClaims = (url) => {
        setSearchUrl(url)
    };

    const goToStart = () => {
        setRouteActive("/")
    }

    return (
        <div className={styles.all_surveyor}>
            <div className={styles.breadcrumb}>
                {traduccion?.breadcrumb?.uRin}
                <Link className={styles.like_a} to={"/"}>
                    <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                </Link>>
                <span className={styles.bold}>{traduccion?.ordenCobro?.ordPago}</span>
            </div>
            <>
                <p className={stylesFiltro.title_claimed}
                   style={{marginLeft: '0rem'}}>{traduccion?.ordenCobro?.ordPago}</p>
                <FiltroPago searchClaims={searchClaims}/>
                <TablePago searchUrl={searchUrl}/>
            </>
        </div>
    );
};

export default OrdenPago;
