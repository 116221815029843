import error from "./error2.png";

export const NotFound = () => {
    return (
        <>
            <div style={{display:"flex",alignItems:'center',justifyContent:'center', width:'100%', flexDirection:"column"}}>
                    <h1 style={{marginTop:100}}>404 Not Found</h1>
                    <img src={error} alt="forbidden" style={{height:'80vh', width:'auto'}}/>
                </div>
            </>
    )
}