import React, {useContext, useEffect, useState} from "react";

import DBContext from "../../../context/DBContext";
import LangContext from "../../../context/LangContext";
import {useNavigate} from "react-router";
import {useParamsUrl} from "../useParamsUrl";
import {urlConfig} from "../../../config/urlConfig";
import {startClaim} from "../../../config/initial";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {Button, Dropdown, DropdownButton, Form, InputGroup, Modal, Pagination, Spinner, Table} from "react-bootstrap";
import thisStyles from "./all_claims.module.css";
import styles from "../../home/table_home/tabla.module.css";
import stylesFiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import {BsPencil, BsTrash} from "react-icons/bs";
import {RiFileExcel2Line} from "react-icons/ri";
import {ModalExportadores} from "./ModalExportadores";
import {IoMdAirplane, IoMdBoat, IoMdBus} from "react-icons/io";
import {Link, useLocation} from "react-router-dom";
import {FiltroStuffing} from "../../home/filtro_stuffing/FiltroStuffing";

export const AllStuffing = ({setRouteActive}     //{setStuff, setOneClaim,active,setActive,listaClaim,setListaClaim,perPage,setPerPage, url,setUrl,totalPages,setTotalPages}
) => {
    const {/*exporter,*/
        claim,
        cambioClaims,
        setCambioClaims,
        timelineStatus,
        statusClaim,
        session
    } = useContext(DBContext);
    const location = useLocation();
    const isStuff = location.state?.id;

    const {traduccion} = useContext(LangContext);
    const navigate = useNavigate();
    const {searchUrl, handleSearch, params} = useParamsUrl(`${urlConfig.allUrl}:9003/api/v1/stuffings/me`);
    const {stuffing, stuffingPages} = useContext(DBContext);

    const [exporter, setExporter] = useState([]);
    //Modal borrar
    const [showModal, setShowModal] = useState(false);
    const [modalId, setModalId] = useState(false);

    // const [oneClaimAll, setOneClaimAll] = useState(null);
    const [status, setStatus] = useState([]);
    const [listaClaim, setListaClaim] = useState(stuffing);
    const [stuffingData, setStuffingData] = useState(null);

    //Para el filtro
    const [perPage, setPerPage] = useState(20);
    const [active, setActive] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [type, setType] = useState("");
    //`${urlConfig.allUrl}:9003/api/v1/stuffings/me?page=0&size=10`
    const [url, setUrl] = useState(`${urlConfig.allUrl}:9003/api/v1/stuffings/me?page=0&size=${perPage}&type=${type}`);

    //Modal de exportadores
    const [modalData, setModalData] = useState([]);
    const [showModalDes, setShowModalDes] = useState(false);
    const [oneExporter, setOneExporter] = useState(null);

    const [mapa, setMapa] = useState(true);

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [modalStuff, setModalStuff] = useState({claim: "", idUser: "", descUser: "", rskClaim: ""});

    const [users, setUsers] = useState([{email: '...loading'}]);

    const [showModalUsers, setShowModalUsers] = useState(false);
    const searchStatus = (e) => {
        let estado = statusClaim.find(obj => obj.id === e);
        return estado?.description;
    }


    //console.log("listaStuffing", listaClaim)
    // get token
    const token = localStorage.getItem("token");
    const [statusCl, setStatusCl] = useState([
        {
            id: 1,
            name: "New"
        },
        {
            id: 2,
            name: "Survey Report"
        },
        {
            id: 3,
            name: "Pending documents"
        },
        {
            id: 4,
            name: "Ready to Claim"
        },
        {
            id: 5,
            name: "Closed"
        },
        {
            id: 6,
            name: "Out of Court"
        },
        {
            id: 7,
            name: "In court"
        },
        {
            id: 8,
            name: "Recovered"
        },
        {
            id: 9,
            name: "Not Recovered"
        },
        {
            id: 10,
            name: "Signed release"
        }
    ]);
    let tokenAtob = atob(token.split('.')[1]);
    let tokenJson = JSON.parse(tokenAtob);
    //console.log("tokenJson", tokenJson)
    useEffect(() => {
        let statysss = [];
        let permisos = tokenJson.permissions.split(',');
        statusCl.map((obj) => {
            //console.log("obj", obj)
            for (let permiso of permisos) {
                //console.log("permiso", permiso)
                if (permiso == obj.id) {
                    statysss.push(obj)
                }
            }
        })
        setStatusCl(statysss)
        //console.log("statysss", statysss)
    }, [])
    // useEffect(() => {
    //     if (listaClaim?.length > 0) {
    //         if (listaClaim[0].timeline !== undefined) {
    //             const statusArray = claim?.map(obj => obj.timeline.map(t => ({id: t.id, name: t.description})))
    //                 .flat()
    //                 .filter((obj, index, self) => self.map(t => t.id).indexOf(obj.id) === index);
    //             setStatus(statusArray);
    //         }
    //     }
    //
    // }, [listaClaim])
    // const searchClaims = () => {
    //     setActive(0);
    //     let newUrl =`${searchUrl}&size=${perPage}&page=${0}`;
    //     setUrl(newUrl);
    // useEffect(() => {
    //     //console.log("LIMPIANDO ONECLAIM Y STUFF")
    //     setOneClaim(null)
    //     setStuff(null)
    //     // setListaClaim(getClaims(url))
    // }, [])

    // useEffect(() => {
    //     setListaClaim(getClaims(url))
    // },[claim])

    const thisYear = new Date().getFullYear();
    const handleNew = (e) => {
        let data = {
            usuario_id: stuffingData.userId,
            planta_id: stuffingData.plantId,
            embarque_id: stuffingData.stuffingId,
            embarque_planta_id: stuffingData.stuffingPlantId
        }
        let headers = {
            headers: {
                Authorization: token.toString().replace("Bearer ", "")
            }
        }
        let initialStuffing = {};

        axios.post(urlConfig.stuffingUrl + "/" + stuffingData.stuffingId, data, headers)
            .then((res) => {
                //guardar en sessionStorage stuff
                //console.log(res.data.data)
                // props.setStuff(res.data.data)
                initialStuffing = {
                    ...startClaim,
                    hasMap: mapa,
                    isStuffing: true,
                    // blAwb: res.data.data.orden_embarque,
                    blAwb: '',
                    exporterName: res.data.data.exportador_nombre,
                    consigneeName: res.data.data.recibidor_nombre,
                    vessel: res.data.data.motonave,
                    pol: res.data.data.puerto_carga,
                    pod: res.data.data.puerto_destino,
                    stuffingDate: res.data.data.fecha_creacion?.split("T")[0],
                    stuffingId: res.data.data.embarque_id,
                    pallets: res.data.data.palletsNumbers,
                    cargo: {
                        ...startClaim.cargo,
                        containerNumber: res.data.data.numero_contenedor,
                        temperature: res.data.data.temperatura,
                        ventilation: res.data.data.ventilacion,
                        ca: res.data.data.ca === 1,
                        coldTreatment: res.data.data.tratamiento_frio === 1,// ?true:false,
                        o2: res.data.data.percentO2,
                        co2: res.data.data.percentCO2,
                        fruits: res.data.data.especies.map((especie, index) => ({
                            id: especie.especie_id,
                            boxes: especie.especie_cantidad_cajas,
                            name: especie.especie_nombre,
                            fruits: {
                                harvesting: [],
                                packaging: []
                            }
                        })),
                    },
                    // CarbonDioxidePercent: stuff.porcentaje_CO2,
                    // OxygenPercent: stuff.porcentaje_O2,
                }
            }).catch((error) => {
            //console.log(error);
        }).then(() => {
            startClaim.hasMap = mapa
            //console.log("CREANDO NUEVO RECLAMO... DESDE STUFFING", initialStuffing)
            axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/`, {
                ...initialStuffing,
                exporterId: oneExporter.id
            }, {
                headers: {
                    Authorization: token.toString().replace("Bearer ", "")
                }
            })
                .then((res) => {
                    //console.log("CREANDO NUEVO RECLAMO STUFFING... este viene con mas cosas :P", res.data)
                    let finalMix = {
                        ...initialStuffing,
                        id: res.data.id,
                        createdTime: res.data.createdTime,
                        hasMap: mapa,
                        companyId: oneExporter.id,
                        companyName: oneExporter.companyName,
                        exporterName: oneExporter.companyName,
                    }
                    //setOneClaimAll(finalMix)

                    navigate('/informe_inspeccion_all', {
                        state: {
                            id: res.data.id,
                            oneClaimAll: finalMix,
                            imRsk: true,
                            oneExporter: oneExporter
                        }
                    })
                }).catch((error) => {
                if (error.response.data.message === "The exporter contract has expired") {
                    toast.error(`${traduccion.others.expiredContract}`, {
                        position: "top-right",
                        duration: 5000
                    })
                }

                //console.log(error);
            })
        })
    }


    const deleteClaim = (id) => {
        axios.delete(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/${id}`, {
            //axios.delete(`http://testsiigroup.pagekite.me/api/v1/claim/me/${id}`, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            // setCambioClaims(!cambioClaims)
            getClaims(url)
        }).catch((error) => {
            //console.log(error);
        })
        setShowModal(false)
    }

//-----------Paginacion-----------

    const getClaims = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            //console.log(res.data.data.data)
            setListaClaim(res.data.data.data)
            setTotalPages(res.data.data.totalPages - 1)
            // setCambioClaims(!cambioClaims)

        }).catch((error) => {
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }
    const goToPage = (page) => {
        //console.log("GO TO PAGE", page)
        let newUrl = (url.replace(/page=\d+/, `page=${page}`))
        //console.log("URL", newUrl)
        setUrl(newUrl)
        setActive(page)
    }


    const handlePerPage = (e) => {
        let page = e.target.value
        //console.log("PAGINANDO...", page)
        setPerPage(page)
    }
    const searchClaims = (e) => {
        e.preventDefault()
        setActive(0);
        let newUrl = `${searchUrl}&size=${perPage}&page=${0}&type=${type}`;
        setUrl(newUrl);
    }
    const updateClaims = () => {
        setActive(0);
        let newUrl = `${searchUrl}&size=${perPage}&page=${active}&type=${type}`;
        getClaims(newUrl)
    }

    useEffect(() => {
        let newUrl = (url.replace(/size=\d+/, `size=${perPage}`))
        newUrl.replace(/page=\d+/, `page=0`)
        setUrl(newUrl)
        setActive(0)
    }, [perPage])

    useEffect(() => {
        //console.log("GET...", url)
        getClaims(url)
    }, [url])

    // let numPage = Math.floor(listaClaim.totalItems / perPage);
    let items = []
    let oneElipsisA = false
    let oneElipsisD = false
    for (let number = 0; number <= totalPages; number++) {
        //add elipsis
        if (number > 2 && number < totalPages - 2) {
            if (number === active + 1 || number === active - 1) {
                items.push(
                    <Pagination.Item className={styles.pagina} key={number} active={number === active}
                                     onClick={() => goToPage(number)}>
                        {number + 1}
                    </Pagination.Item>,
                );
            } else if (number === active) {
                items.push(
                    <Pagination.Item className={styles.pagina} onClick={() => goToPage(number)} key={number}
                                     active={number === active}>
                        {number + 1}
                    </Pagination.Item>,
                );
            } else {
                if (number < active && !oneElipsisA) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisA = true
                }
                if (number > active && !oneElipsisD) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisD = true
                }
            }
        } else {
            items.push(
                <Pagination.Item className={styles.pagina} onClick={() => goToPage(number)} key={number}
                                 active={number === active}>
                    {number + 1}
                </Pagination.Item>,
            );
        }
    }


    const handleShow = (id) => {
        setShowModal(true)
        setModalId(id)
    }

    const handleModalClaims = (tipo, datos) => {
        if (tipo === "noMap") {
            setMapa(false)
        } else {
            setMapa(true)
        }
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/company_contract`, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            //console.log("CLAIM", res.data)
            //console.log("STUFFING DATOS", datos)
            setExporter(res.data)
            setStuffingData(datos)
            setShowModalDes(true)
        })
    }

    const nuevoClaimModal = () => {
        handleNew()
    }

    const handleStatusDays = (statusDate) => {
        //console.log("STATUS DATE", statusDate)
        if (statusDate !== null && statusDate !== undefined && statusDate !== "") {
            let today = new Date()
            let date = new Date(statusDate)
            let diff = Math.abs(today - date)
            let days = Math.ceil(diff / (1000 * 60 * 60 * 24))
            if (days === 1) {
                return "1 día"
            } else {
                return days + " días"
            }
        } else {
            return "Sin Fecha"
        }
    }


    //console.log(location)
    const goToStart = () => {
        setRouteActive("/");
    }
    const handleSearchType = (e) => {
        //console.log(e)
        //console.log("TYPE", type)
        setType(e)
    }
    useEffect(() => {
        let newUrl = (url.replace(/type=[\d+]?/, `type=${type}`))
        setUrl(newUrl)
        setActive(0)
    }, [type])

    const changeUserClaim = (claim, idUser) => {
        setLoading(true)
        //console.log("CHANGE USER CLAIM", claim, idUser)
        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/assign/${claim}`,
            {
                "id": idUser
            }
            , {
                headers: {
                    Authorization: token.replace("Bearer ", "")
                }
            }).then((res) => {
            //console.log("CHANGE USER CLAIM", res.data)
            toast.success(`${traduccion?.others?.usuarioAsignado}`, {
                position: toast.POSITION.TOP_RIGHT
            })
            updateClaims()
            setCambioClaims(!cambioClaims)
            setShowModalUsers(false)
            setLoading(false)
        }).catch((error) => {
            if (error.response?.data?.message) {
                toast.error(`${error.response?.data?.message.split(".")[1]}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            } else {
                toast.error(`${traduccion?.others?.errorAsignarUsuario}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
            setShowModalUsers(false)
            setLoading(false)
        })
    }
    //console.log("CLAIMS SESSION", listaClaim)
    return (
        <>
            <ToastContainer/>
            <div className={thisStyles.claim_all} style={{paddingTop: '1.3rem'}}>
                <div style={{marginLeft: '.5rem'}}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link to={"/"} style={{textDecoration: 'none'}}>
                        <span onClick={goToStart}> {traduccion?.breadcrumb?.home} ></span>
                    </Link> <span><b>{isStuff ? "Stuffing" : `${traduccion?.claim?.claims}`}</b></span>
                </div>
                <div className={stylesFiltro.filtro_total}>
                    <p className={stylesFiltro.title_claimed}
                       style={{marginLeft: '4vw'}}>{isStuff ? "Stuffing" : `${traduccion?.claim?.claims}`}</p>

                    {isStuff ?
                        <div className={stylesFiltro.filtros_busqueda}>
                            <p className={stylesFiltro.title_filter}>{traduccion.stuffingFilter?.searchFilter}</p>
                            <div className={stylesFiltro.opciones_busqueda}>
                                <Form onSubmit={searchClaims}>
                                    <div>
                                        <label
                                            htmlFor="container_select">{traduccion.stuffingFilter?.ordenEmbarque}</label>
                                        <Form.Control name={"order"} type={"text"}
                                                      onChange={handleSearch}
                                                      className={stylesFiltro.selects_input}/>

                                    </div>
                                    <div>
                                        <label
                                            htmlFor="container_select">{traduccion.stuffingFilter?.nContainer}</label>
                                        <Form.Control name={"container"} type={"text"}
                                                      onChange={handleSearch}
                                                      className={stylesFiltro.selects_input}/>

                                    </div>

                                    <div className={stylesFiltro.opciones_date}>
                                        <div className={stylesFiltro.opciones_date_in}>
                                            <Form.Label
                                                htmlFor="fechaReporteStart">
                                                {/*<div style={{margin:0}}>{traduccion.stuffingFilter?.fechaReporte}</div> */}
                                                {traduccion.factura?.desde}:

                                            </Form.Label>
                                            <Form.Control
                                                name="from"
                                                onChange={handleSearch}
                                                type="date"
                                                id="fechaReporteStart"
                                                placeholder={traduccion?.factura?.desde}
                                                className={stylesFiltro.selects_input}
                                            />
                                        </div>
                                        <div className={stylesFiltro.opciones_date_in}>
                                            <Form.Label
                                                htmlFor="fechaReporteStart">
                                                {traduccion.factura?.hasta}:
                                            </Form.Label>
                                            <Form.Control
                                                name="to"
                                                onChange={handleSearch}
                                                type="date"
                                                id="fechaReporteEnd"
                                                placeholder={traduccion?.factura?.hasta}
                                                className={stylesFiltro.selects_input}
                                            />
                                        </div>
                                    </div>
                                    <Button className={stylesFiltro.boton_filtro} variant="primary"
                                            onClick={searchClaims}
                                    >
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                                fill="white"/>
                                        </svg>
                                    </Button>
                                </Form>
                            </div>
                        </div>
                        :
                        <div className={stylesFiltro.filtros_busqueda}>
                            <p className={stylesFiltro.title_filter}>{traduccion.claimed?.filtro}</p>
                            <div className={stylesFiltro.opciones_busqueda}>
                                <Form onSubmit={searchClaims}>

                                    <div>
                                        <label className="ms-3" htmlFor="nave_select">Ref. RSK</label>
                                        <InputGroup className="mb-3" style={{display: 'flex'}}>
                                            <InputGroup.Text id="basic-addon1"
                                                             style={{width: '5ch'}}>RSK</InputGroup.Text>
                                            <Form.Control
                                                type={"text"}
                                                placeholder={`${thisYear}/1`}
                                                name="ref"
                                                onChange={handleSearch}
                                            />
                                        </InputGroup>
                                        {/*<Form.Group controlId="container">*/}
                                        {/*    <label htmlFor="container_select">Ref. RSK</label>*/}
                                        {/*    <Form.Control type="text" placeholder="Ref. RSK" onChange={handleSearch} name="refrsk"/>*/}
                                        {/*</Form.Group>*/}
                                    </div>
                                    <div>
                                        <Form.Group controlId="exporter">
                                            <label htmlFor="nave_select">{traduccion?.claim?.exporter}</label>
                                            <Form.Control type="text" placeholder={`${traduccion?.claim?.exporter}`}
                                                          onChange={handleSearch} name="exporter"/>
                                        </Form.Group>
                                    </div>
                                    <div>

                                        <Form.Group controlId="rskUSer">
                                            <label htmlFor="nave_select">RSK User</label>
                                            <Form.Control type="text" placeholder="RSK User" onChange={handleSearch}
                                                          name="rsk"/>
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <label htmlFor="estado">{traduccion.claimed?.estado}</label>

                                        <Form.Select id="estado" onChange={handleSearch} name="status">
                                            <option value="">-</option>
                                            {

                                                // statusClaim - description
                                                statusCl.length > 0 ?
                                                    statusCl.map((item, idx) =>
                                                        <option key={idx} value={item.id}>{item.name}</option>
                                                    ) : null
                                            }
                                        </Form.Select>

                                    </div>
                                    <Button className={stylesFiltro.boton_filtro} variant="primary"
                                            onClick={searchClaims}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                                fill="white"/>
                                        </svg>
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    }
                </div>
                <div className={styles.mostrar_nuevo_reclamo}
                     style={{margin: '1rem', flexWrap: 'wrap', rowGap: '1rem'}}>
                    <div className={styles.mostrar} style={{height: '2.5rem'}}>
                        <p>{traduccion.claimTable?.mostrar}</p>
                        <Form.Select onChange={handlePerPage} size="sm" id="show">
                            <option value="4">4</option>
                            <option value="8">8</option>
                            <option value="20" selected={true}>20</option>
                        </Form.Select>
                    </div>

                </div>
                <div className={styles.contiene_tabla}>
                    <Table className={styles.tabla} borderless hover>
                        <thead>
                        <tr>
                            <th>{traduccion.stuffingTable?.shippingOrder}</th>
                            <th>{traduccion.stuffingTable?.exportador}</th>
                            <th className={styles.th_container_nr}>{traduccion.stuffingTable?.nContainer}</th>
                            <th>{traduccion.stuffingTable?.nave}</th>
                            <th>{traduccion.stuffingTable?.consignee}</th>
                            <th>{traduccion.stuffingTable?.pol}</th>
                            <th>{traduccion.stuffingTable?.pod}</th>
                            <th className={styles.th_buttons}></th>
                        </tr>

                        </thead>
                        <tbody style={{
                            position: 'relative',
                            // height:600
                        }}>
                        {loading ?

                            <div style={{
                                position: "absolute",
                                height: "200px",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",

                            }}>
                                <div className={thisStyles.loader}></div>
                            </div> :
                            (
                                listaClaim?.length > 0 ?
                                    listaClaim?.map((datos, idx) => {
                                        // let rskClaim = "RSK"+datos.createdTime.slice(0,4)+"/"+datos.id
                                        //console.log({datos})
                                        return (
                                            <tr key={idx} className={styles.tr_data}>

                                                <td>{datos.boardingOrder}</td>
                                                <td>{datos.exporter}</td>
                                                <td>{datos.containerNumber}</td>
                                                <td>{datos.vessel}</td>
                                                <td>{datos.consignee}</td>
                                                <td>{datos.pol}</td>
                                                <td>{datos.pod}</td>
                                                <td className={styles.th_buttons}>
                                                    {/*<Button variant="primary">Inspeccion</Button>{' '}*/}
                                                    <Button variant="primary" onClick={() => {
                                                        handleModalClaims("", datos)
                                                    }}>
                                                        {traduccion.stuffingTable?.inspeccion}
                                                    </Button>
                                                    <Button variant="primary" onClick={() => {
                                                        handleModalClaims("noMap", datos);
                                                    }}>{traduccion.stuffingTable?.recupero}</Button>{' '}
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                    <tr>
                                        <td colSpan="8" style={{textAlign: "center"}}>{traduccion?.factura?.noData}</td>
                                    </tr>
                            )

                        }
                        </tbody>
                    </Table>
                </div>
                <Pagination style={{justifyContent: 'center'}}>
                    {active > 0 &&
                        <Pagination.Prev onClick={() => goToPage(active - 1)}/>
                    }
                    {items}
                    {active < totalPages &&
                        <Pagination.Next onClick={() => goToPage(active + 1)}/>
                    }
                </Pagination>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                >
                    <Modal.Body>
                        <div className={stylesTabla.modal_body} style={{padding: '.5rem'}}>
                            <div className={stylesTabla.modal_body_header}>
                                <h3>{traduccion?.clients?.rUSure}</h3>
                            </div>
                            <div className={stylesTabla.modal_body_body}>
                                <div className={stylesTabla.modal_body_body_text}>
                                    <p style={{fontWeight: '500'}}>Claim ID: <span
                                        style={{color: '#f55', fontWeight: '900'}}>{modalId}</span></p>
                                </div>
                                <div className={stylesTabla.modal_body_body_buttons}
                                     style={{justifyContent: 'space-around', display: 'flex', marginTop: '2rem'}}>
                                    <Button onClick={() => setShowModal(false)}>
                                        {traduccion?.clients?.cancelar}
                                    </Button>
                                    <Button variant={"danger"} onClick={() => deleteClaim(modalId)}>
                                        {traduccion?.clients?.borrar}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                <ModalExportadores
                    arr={exporter}
                    showDes={showModalDes}
                    createNew={nuevoClaimModal}
                    onHide={() => setShowModalDes(false)}
                    stuffingData={stuffingData}
                    hasMap={mapa}
                    oneExporter={oneExporter}
                    setOneExporter={setOneExporter}
                />
                <Modal
                    show={showModalUsers}
                    onHide={() => setShowModalUsers(false)}
                    centered
                >
                    <Modal.Body>
                        <p style={{textAlign: "center", margin: '1rem'}}>
                            Are you sure you want to assign this
                            claim {modalStuff.rskClaim} to <b>{modalStuff.descUser} </b> ?
                        </p>
                        <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '2rem'}}>
                            <Button disabled={loading} variant="warning" onClick={() => setShowModalUsers(false)}>
                                {traduccion?.others?.close}
                            </Button>
                            <Button disabled={loading} variant={"primary"} style={{marginLeft: '1rem'}}

                                    onClick={() => changeUserClaim(modalStuff.claim, modalStuff.idUser)}
                            >
                                {loading && <Spinner/>}
                                {!loading && traduccion?.others?.Accept}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};
