import React, {useContext, useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import DBContext from "../../../context/DBContext";
// import Select from "react-select/base";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import styles from "../surveyors/mantenedor_surveyor.module.css";
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";

import {Link} from "react-router-dom";
import {Button, Pagination, Form, Table, Spinner} from "react-bootstrap";
import LangContext from "../../../context/LangContext";
import {useParamsUrl} from "../useParamsUrl";
import TableBilling from "./tableBilling";
import {FiltroCasosReclamados, FiltroCasosReclamadosExporter} from "./FiltroClaimsExporter";
import stylesFiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {toast} from "react-toastify";

export const Billing = ({newBilling, setRouteActive
                            // setNewBilling,listaStatus
                        }) => {

    // const {searchUrl} = useParamsUrl(urlConfig.searchClaimExportUrl);
    const [claims, setClaims] = useState([]);
    const {claimsReady,setCambioClaimsReady,cambioClaimsReady} = useContext(DBContext);

    const [active, setActive] = useState(0);
    const [listaClaim, setListaClaim] = useState(claimsReady);
    const [perPage, setPerPage] = useState(20);
    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/customers/search?page=0&size=${perPage}&type=`);
    const [totalPages, setTotalPages] = useState(0);
    const [loadingPdf, setLoadingPdf] = useState(false);

    const [cobroObject, setCobroObject] = useState({claimList: []});
    const [cobroIds, setCobroIds] = useState([]);
    const [selectedClaim, setSelectedClaim] = useState([]);

    const {traduccion} = useContext(LangContext)
    const token = localStorage.getItem("token");

    //console.log(cobroObject)
    //console.log({claimsReady})

    useEffect(() => {
        setCambioClaimsReady(!cambioClaimsReady)
    }, [])
    const goToStart = () => {
        setRouteActive("/")
    }

    const downloadPdf = (id) => {
        setLoadingPdf(true)
        let token = localStorage.getItem("token");
        const headers = {
            responseType: 'arraybuffer',
            headers: {
                'Content-Disposition': 'attachment',
                Authorization: token
            }
        }
        axios.post(
            //`${urlConfig.urlNgrok}/api/v1/order/recovery/${id}`
                 `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/recovery/${id}`
            , {}, headers)
            .then(res => {
                const url = URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Recovery order -${id}.pdf`);
                document.body.appendChild(link);
                link.click();
                setLoadingPdf(false)
                toast.success(`${traduccion?.success?.downPdf}`)
            }).catch(err => {
            setLoadingPdf(false)
            //console.log(err)
        })
    }

    const handlePostClaim = () => {
        axios.post(`${urlConfig.local_url}${urlConfig.portClaim}/api/v1/order/recovery`, cobroObject, {
            //axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/order/pay`,{claimList: [46]},{
            headers: {
                'Authorization': localStorage.getItem("token").replace("Bearer ", ""),
            }
        })
            .then(res => {
                downloadPdf(res.data.id)
                toast.success(`${traduccion?.success?.newclaim}`)

            }).catch(err =>{
            //console.log(err)
            toast.error(`${err.response.data.message}`)
        })
    }

    return (
            <>
                <div className={styles.all_surveyor}>
                    <div className={styles.breadcrumb}>
                        {traduccion?.breadcrumb?.uRin}
                        <Link className={styles.like_a} to={"/"}>
                            <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                        </Link>>
                        {newBilling === 0 &&
                            <span className={styles.bold}>{traduccion?.factura?.facClientes}</span>
                        }
                    </div>

                    {newBilling === 0
                        &&
                        <>

                        <p className={stylesFiltro.title_claimed} style={{marginLeft:'0rem'}}>{traduccion?.factura?.facClientes}</p>
                            {/*------------ Filtro ------------*/}
                            <FiltroCasosReclamadosExporter active={active} setActive={setActive}
                                                           listaClaim={listaClaim} setListaClaim={setListaClaim}
                                                           perPage={perPage} setPerpage={setPerPage}
                                                           url={url} setUrl={setUrl}
                                                           totalPages={totalPages} setTotalPages={setTotalPages}/>
                            {/*------------ Fin Filtro ------------*/}

                            {/*------------ Inicio Tabla  ------------*/}
                            <TableBilling  active={active} setActive={setActive}
                                           listaClaim={listaClaim} setListaClaim={setListaClaim}
                                           setSelectedClaim={setSelectedClaim} selectedClaim={selectedClaim}
                                           setCobroObject={setCobroObject} cobroObject={cobroObject}
                                           setCobroIds={setCobroIds} cobroIds={cobroIds}

                                          perPage={perPage} setPerPage={setPerPage}
                                          url={url} setUrl={setUrl}
                                          totalPages={totalPages} setTotalPages={setTotalPages}/>
                            {/*------------ Fin Tabla ------------*/}

                        </>
                    }

                    {/*newBilling === 1 && <Billing newBilling={billing}/>*/}
                    <div className="w-100 div-btn">

                        <div style={{margin:'2rem'}}>
                            <Button variant="primary btn-secun mb-4"
                                    onClick={handlePostClaim}
                            >{loadingPdf ?
                                <Spinner animation="border" role="status">
                                </Spinner>
                                :
                                <span>{traduccion?.factura?.gener}</span>
                            }
                            </Button>
                        </div>
                    </div>
                </div>

            </>
        )
    }
