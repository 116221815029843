import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import styles from "./modal.module.css"
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import useWindowSize from "../../../hooks/useWindowSize";
import LangContext from "../../../context/LangContext";
import {useContext} from "react";
import {useNavigate} from "react-router";
import {startClaim} from "../../../config/initial";
import {toast} from "react-toastify";


export const ModalReclamo = (props) => {
    const {traduccion} = useContext(LangContext);
    const token = localStorage.getItem("token");
    const {height, width} = useWindowSize();
    //Claim inicial para darle al stuffing
    const initialClaim = startClaim;

    const navigate = useNavigate();

    const clickS = () => {
        let data = {
            usuario_id: props.stuffing.userId,
            planta_id: props.stuffing.plantId,
            embarque_id: props.stuffing.stuffingId,
            embarque_planta_id: props.stuffing.stuffingPlantId
        }
        let headers = {
            headers: {
                Authorization: token.toString().replace("Bearer ", "")
            }
        }
        let initialStuffing = {};
        //console.log(data)

        axios.post(urlConfig.stuffingUrl +"/"+ props.stuffing.stuffingId, data, headers)
            .then((res) => {
                //guardar en sessionStorage stuff
                //console.log(res.data.data)
                // props.setStuff(res.data.data)
                initialStuffing= {
                    ...initialClaim,
                    hasMap: props.hasMap,
                    isStuffing: true,
                    blAwb: res.data.data.orden_embarque,
                    exporterName: res.data.data.exportador_nombre,
                    consigneeName: res.data.data.recibidor_nombre,
                    vessel: res.data.data.motonave,
                    pol: res.data.data.puerto_carga,
                    pod: res.data.data.puerto_destino,
                    stuffingDate: res.data.data.fecha_creacion?.split("T")[0],
                    stuffingId: res.data.data.embarque_id,
                    pallets: res.data.data.palletsNumbers,
                    cargo:{
                        ...initialClaim.cargo,
                        containerNumber: res.data.data.numero_contenedor,
                        temperature: res.data.data.temperatura,
                        ventilation: res.data.data.ventilacion,
                        ca: res.data.data.ca === 1,
                        coldTreatment: res.data.data.tratamiento_frio===1,// ?true:false,
                    },
                    // CarbonDioxidePercent: stuff.porcentaje_CO2,
                    // OxygenPercent: stuff.porcentaje_O2,
                }
            }).catch((error) => {
            //console.log(error);
        }).then((res) => {

            //console.log({initialStuffing})
            props.setInitStuffing(true)

            axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me`, initialStuffing, {
                headers: {
                    Authorization: token.toString().replace("Bearer ", "")
                }
            }).then((res) => {
                //console.log(res.data)
                navigate('/informe_inspeccion', {state: {id: res.data.id, initialStuffing: initialStuffing}}) //res.data -- Si es que quiero la respuesta del post
            }).catch((error) => {
                toast.error(error.message)
                //console.log(error);
            })
        })
        //props.setRouteActive("/informe_inspeccion")
    }


    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size={width > 768 ? "md" : "sm"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {traduccion.modal?.iniciarProceso}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={6} className={styles.columna}>
                            <p className={styles.titulo}>{traduccion.modal?.nContainer}</p>
                            <p>{props.stuffing?.containerNumber}</p>

                        </Col>
                        <Col xs={6} className={styles.columna}>
                            <p className={styles.titulo}>{traduccion.modal?.nave}</p>
                            <p>{props.stuffing?.vessel}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} className={styles.columna}>
                            <p className={styles.titulo}>{traduccion.modal?.pol}</p>
                            <p>{props.stuffing?.pol}</p>
                        </Col>
                        <Col xs={6} className={styles.columna}>
                            <p className={styles.titulo}>{traduccion.modal?.pod}</p>
                            <p>{props.stuffing?.pod}</p>
                        </Col>
                        <Col xs={6} className={styles.columna}>
                            <p className={styles.titulo}>{traduccion.modal?.consignee}</p>
                            <p>{props.stuffing?.consignee}</p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div onClick={clickS}>
                    <Button onClick={props.onHide}>Confirmar</Button>
                </div>
                <Button onClick={props.onHide}>Cancelar</Button>
            </Modal.Footer>
        </Modal>
    )
}
