import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from "./new_surveyor.module.css";
import {Button, Col, Container, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";
import LangContext from "../../../context/LangContext";
import {Typeahead} from "react-bootstrap-typeahead";
import {MdOutlineAddLocationAlt} from "react-icons/md";
import {toast, ToastContainer} from "react-toastify";
import imagen from "./abstract-user.png";
import Maps from "../../informe_reclamo/informe_reclamo_tabs/inspector/map/Maps";
import countries from "countries-list";
import {FlagIcon} from "react-flag-kit";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import DBContext from "../../../context/DBContext";
import {useForm} from "../../../hooks/useForm";
import {onlyNumbers} from "../../../config/functions";

const NewSurveyorCompany = ({oneSurveyor, editSurveyor, setNewSurveyor}) => {

    const errorSurveyor = {
        photo: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        personalId: "",
        mainAddress: "",
        profession: "",
        latitude: "",
        longitude: "",
        operationRange: "",
        country: "",
        country2: "",
        status_id: "",
        country_id: "",
        company_id: null,
        isValidPhoto: false,
        isValidFirstName: false,
        isValidLastName: false,
        isValidEmail: false,
        isValidPhone: false,
        isValidPersonalId: false,
        isValidMainAddress: false,
        isValidProfession: false,
        isValidLatitude: false,
        isValidLongitude: false,
        isValidOperationRange: false,
        isValidCountry: false,
        isValidCountry2: false,
        isValidStatus_id: false,
        isValidCountry_id: false,
        isValidCompany_id: false
    }

    const currentSurveyor = {
        photo: oneSurveyor.photo,
        firstName: oneSurveyor.firstName,
        lastName: oneSurveyor.lastName,
        email: oneSurveyor.email,
        phone: oneSurveyor.phone,
        personalId: oneSurveyor.personalId,
        mainAddress: oneSurveyor.mainAddress,
        profession: oneSurveyor.profession,
        latitude: oneSurveyor.latitude,
        longitude: oneSurveyor.longitude,
        operationRange: oneSurveyor.operationRange,
        country: oneSurveyor.country,
        status_id: oneSurveyor.status_id,
        country_id: oneSurveyor.country_id,
        company_id: oneSurveyor.company_id
    }

    const baseSurveyor = {
        photo: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        personalId: "",
        mainAddress: "",
        profession: "",
        latitude: 0,
        longitude: 0,
        operationRange: 0,
        country: "",
        status_id: 0,
        country_id: null,
        company_id: null
    }

    const {company} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);

    const [surveyorError, setSurveyorError] = useState(errorSurveyor);
    const [surveyor, setSurveyor] = useState(editSurveyor ? currentSurveyor : baseSurveyor);

    const [profileImage, setProfileImage] = useState(imagen);
    const [previewProfileImage, setPreviewProfileImage] = useState(null);
    const [mapCountryCode, setMapCountryCode] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [countryRates, setCountryRates] = useState({});
    const [newCoords, setNewCoords] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    let codeByCountry = (pais) => {
        if (pais == "United StatesNO" || pais == "ChinaNO") {
            setCountryCode("")
            return [];
        } else {
            return countryCodes.filter((code) => (countries.countries[code].name == pais));
        }
    }


    const companiesByCode = () => {
        return company.filter(obj => {
            return obj.countryList.some(country => country.code === mapCountryCode);
        })
    }

    const validateSurveyor = (input, value) => {
        switch (input) {
            case 'firstName':
                setSurveyorError({
                    ...surveyorError,
                    [input]: validateString(input, value),
                    isValidFirstName: validateString(input, value) === undefined
                })
                break;
            case 'lastName':
                setSurveyorError({
                    ...surveyorError,
                    [input]: validateString(input, value),
                    isValidLastName: validateString(input, value) === undefined
                })
                break;
            case 'profession':
                setSurveyorError({
                    ...surveyorError,
                    [input]: validateString(input, value),
                    isValidProfession: validateString(input, value) === undefined
                })
                break;
            case 'personalId':
                setSurveyorError({
                    ...surveyorError,
                    [input]: validateLength(input, value, 5, 20),
                    isValidPersonalId: validateLength(input, value, 5, 20) === undefined
                })
                break;
            case 'phone':
                setSurveyorError({
                    ...surveyorError,
                    [input]: validateLength(input, value, 6, 15),
                    isValidPhone: validateLength(input, value, 5, 20) === undefined
                })
                break;
            case 'email':
                setSurveyorError({
                    ...surveyorError,
                    [input]: validateEmail(input, value),
                    isValidEmail: validateEmail(input, value) === undefined
                })
                break;
            case 'operationRange':
                setSurveyorError({
                    ...surveyorError,
                    [input]: validateNumber(input, value),
                    isValidOperationRange: validateNumber(input, value) === undefined
                })
                break;
            case 'mainAddress':
                setSurveyorError({
                    ...surveyorError,
                    [input]: validateStringLength(input, value),
                    isValidMainAddress: validateStringLength(input, value) === undefined
                })
                break;
            case 'photo':
                setSurveyorError({
                    ...surveyorError,
                    [input]: input === undefined && `Photo is required`,
                    isValidMainAddress: input !== undefined
                })
                break;
            case 'status_id':
                setSurveyorError({
                    ...surveyorError,
                    [input]: Number(value) === 0 && `Status is required`,
                    isValidStatus_id: Number(value) !== 0
                })
                break;
            case 'company_id':
                setSurveyorError({
                    ...surveyorError,
                    [input]: Number(value) === 0 && `Company is required`,
                    isValidCompany_id: Number(value) !== 0
                })
                break;
            case 'country':
                setSurveyorError({
                    ...surveyorError,
                    [input]: countryCode === "" ? `The country field is required` : '',
                    isValidCompany_id: countryCode !== ""
                })
                break;
            default:
                break;
        }
    }

    const validateString = (input, value) => {
        const regexString = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]{0,50}$/;
        if (!value.toString().trim().length > 0) return `The ${input} field is required`;
        if (!regexString.test(value)) return `The ${input} is not valid (Must be letters only)`;
    }
    const validateLength = (input, value, from, to) => {
        const regexLength = new RegExp(`^.{${from},${to}}$`);
        if (!value.toString().trim().length > 0) return `The ${input} field is required`;
        if (!regexLength.test(value)) return `The field ${input} is not valid (Must be between ${from} and ${to} characters)`;
    }
    const validateEmail = (input, value) => {
        const regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/;
        if (!value.toString().trim().length > 0) return `The ${input} field is required`;
        if (!regexEmail.test(value)) return `The field ${input} is not valid`;
    }
    const validateNumber = (input, value) => {
        const regexNumbers = /^\d+$/;
        if ((value.toString().trim().length === 0 || value === 0)) return `The ${input} field is required`;
        if (!regexNumbers.test(value)) return `The field ${input} is not valid (Must be numbers only)`;
    }
    const validateStringLength = (input, value) => {
        const regexStringLength = /.*\S.*/;
        if (!regexStringLength.test(value)) return `The field ${input} is required`;
    }

    useEffect(() => {
        const {country_id} = surveyor;
        if (country_id !== null) {
            const header = {
                headers: {
                    Authorization: localStorage.getItem("token"),
                }
            }
            axios.get(urlConfig.allUrl + `${urlConfig.portClaim}/api/v1/country/${surveyor.country_id}`, header).then(res => {
                const {code} = res.data;
                setMapCountryCode(code);
            }).catch(err => {
                //console.log(err)
            })
        }
    }, [])

    useEffect(() => {
        //console.log(previewProfileImage, editSurveyor)
        if (editSurveyor) {
            if (previewProfileImage == null) {
                setProfileImage(surveyor.photo)
            }
        }
    }, [surveyor])

    useEffect(() => {
        const {country} = surveyor;
        const code = codeByCountry(country);
        if (code.length > 0) {
            setCountryCode(code[0])
        } else {
            setCountryCode("");
        }
    }, [surveyor.country])

    useEffect(() => {
        if (newCoords !== null) {
            //console.log(newCoords.latitude, newCoords.longitude)
            setSurveyor({
                ...surveyor, latitude: newCoords.latitude, longitude: newCoords.longitude
            })
        }
    }, [newCoords, surveyor.latitude, surveyor.longitude])


    useEffect(() => {
        const header = {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        if (typeof mapCountryCode === "string" && mapCountryCode.length === 2) {
            axios.get(urlConfig.allUrl + `${urlConfig.portClaim}/api/v1/country/code/${mapCountryCode}`, header).then(res => {
                const {id} = res.data;
                handleChangeMapCountry(id);
                setCountryRates(res.data);
            }).catch(err => {
                setSurveyor({
                    ...surveyor, country_id: null
                })
                toast.error(`${traduccion?.errores?.countryNot}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
        }
    }, [mapCountryCode])

    const handleChangeCountry = (e) => {
        setSurveyor({
            ...surveyor, country: e
        })

        let code = codeByCountry(e);
        if (code !== undefined) {
            setCountryCode("")
            if (code.length > 0) {
                // //console.log(code)
                setCountryCode(code[0]);
            } else {
                //console.log("No se encuentra codigo")
            }
        }
    }

    const handleChangeCountryEnd = (e) => {
        setSurveyor({
            ...surveyor, country: e[0]
        })
        //console.log(codeByCountry(e))
        if (codeByCountry(e).length > 0) {
            setCountryCode(codeByCountry(e[0]))
        }
    }

    //Reference to the input phone
    const refPhone = useRef("");
    const refPhoneNum = useRef("");

    const handleChange = (e) => {
        const {name, value} = e.target;
        validateSurveyor(name, value)
        if (e.target?.name === "country") {
            //reference to the input phone
            if (e.target?.value === "") {
                setSurveyor({
                    ...surveyor, phone: "",
                })
            }
            setSurveyor({
                ...surveyor, country: countries.countries[e.target.value].name,
            })

            setCountryCode(e.target.value)
        } else if (e.target?.name === "phone") {
            setSurveyor({
                ...surveyor, phone: `+${countries.countries[countryCode].phone}${refPhone.current.children[1].value}`,
            })
        } else if (e.target?.name === "firstName") {
            setSurveyor({
                ...surveyor, surveyorName: e.target.value, firstName: e.target.value,
            })
        } else if (e.target?.name === "email") {
            setSurveyor({
                ...surveyor, email: e.target.value, username: e.target.value,
            })
        } else if (e.target?.name === "status_id") {
            setSurveyor({
                ...surveyor, status_id: +e.target.value,
            })
        } else {
            setSurveyor({
                ...surveyor, [e.target.name]: e.target.value
            })
        }
    }

    const countryCodes = Object.keys(countries.countries);
    useEffect(() => {
        // //console.log(refPhone.current)
        if (countryCode !== "") {
            setSurveyor({
                ...surveyor, phone: `+${countries.countries[countryCode].phone}${refPhoneNum.current.value}`,
            })
        }
    }, [countryCode, refPhoneNum.current.value])

    const handleChangeMapCountryCode = (e) => {
        setMapCountryCode(e);
    }
    const handleChangeRange = (e) => {
        validateSurveyor("operationRange", e);
        setSurveyor({
            ...surveyor, operationRange: e
        });
    }
    const handleChangeAddress = (e) => {
        validateSurveyor("mainAddress", e);
        //console.log(1, e)
        setSurveyor({
            ...surveyor, mainAddress: e
        });
    }
    const handleChangeCoords = (e) => {
        //console.log(2, e)
        setNewCoords({
            latitude: e.lat, longitude: e.lng
        })
    }
    const handleChangeMapCountry = (e) => {
        setSurveyor({
            ...surveyor, country_id: e
        })
    }
    const handlePreviewImg = (e) => {
        validateSurveyor("photo", e.target.files[0]);
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const fileUrl = URL.createObjectURL(file);
            setProfileImage(fileUrl);
            setPreviewProfileImage(file);
        }
    }
    const uploadImage = () => {
        try {
            if (!editSurveyor) {
                if (previewProfileImage === null) {
                    toast.error(`${traduccion?.errores?.imgNeed}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    return false;
                }
                const {type, name, size} = previewProfileImage;
                if (type !== "image/png" && type !== "image/jpeg" && type !== "image/jpg") {
                    toast.error(`${traduccion?.errores?.invalidImg}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    return false;
                }
                if (size > 20000000) {
                    toast.error(`${traduccion?.errores?.invalidImg}`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    return false;
                }
                const image = new File([previewProfileImage], `PROFILE_${name}`, {type});
                let formData = new FormData();
                formData.append('file', image);

                const header = {
                    headers: {
                        'content-type': 'multipart/form-data', Authorization: localStorage.getItem("token")
                    }
                }
                return axios.post(`${urlConfig.filesUrl}/img`, formData, header);
            } else {
                if (previewProfileImage === null) {
                    return true;
                } else {
                    const {type, name, size} = previewProfileImage;
                    if (type !== "image/png" && type !== "image/jpeg" && type !== "image/jpg") {
                        toast.error(`${traduccion?.errores?.invalidImg}`, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        return false;
                    }
                    if (size > 20000000) {
                        toast.error(`${traduccion?.errores?.invalidImg}`, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        return false;
                    }
                    const image = new File([previewProfileImage], `PROFILE_${name}`, {type});
                    let formData = new FormData();
                    formData.append('file', image);

                    const header = {
                        headers: {
                            'content-type': 'multipart/form-data', Authorization: localStorage.getItem("token")
                        }
                    }
                    return axios.post(`${urlConfig.filesUrl}/img`, formData, header);
                }
            }
        } catch (err) {
            console.error(err)
            toast.error(`${traduccion?.errores?.errProfileImg}`, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
    }
    const saveSurveyor = (photo) => {
        const header = {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }
        surveyor.photo = photo;
        axios.post(urlConfig.inspectorUrl, surveyor, header).then(res => {
            toast.success(`${traduccion?.success?.successNewSurv}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch(err => {
            console.error(err);
            if (err.code === "ERR_NETWORK") {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                if (err.response.data.message === "Error in fields") {
                    const {message, errors} = err.response.data;
                    toast.error(<> {message} <br/> {errors.join(", ")} </>);
                } else {
                    toast.error(`${traduccion?.errores?.errNewSurv}`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        })
    }
    const updateSurveyor = (photo) => {
        const header = {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }
        surveyor.photo = photo;
        axios.put(`${urlConfig.inspectorUrl}/${oneSurveyor.id}`, surveyor, header).then(res => {
            toast.success(`${traduccion?.success?.successActSurv}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }).catch(err => {
            console.error(err);
            if (err.code === "ERR_NETWORK") {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                if (err.response.data.message === "Error in fields") {
                    const {message, errors} = err.response.data;
                    toast.error(<> {message} <br/> {errors.join(", ")} </>);
                } else {
                    toast.error(`${traduccion?.errores?.errEditSurv}`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        })
    }
    const sendForm = async () => {
        setLoading(true);
        try {
            const result = await uploadImage();
            if (result === true) {
                if (editSurveyor) {
                    updateSurveyor(profileImage);
                } else {
                    saveSurveyor(profileImage);
                }
            } else if (result === false) {
                return false;
            } else {
                if (editSurveyor) {
                    updateSurveyor(result.data.data[0]);
                } else {
                    saveSurveyor(result.data.data[0]);
                }
            }
        } catch (err) {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT
            })
        } finally {
            setLoading(false);
        }
    }
    const handleCheckCompany = () => {
        setNewSurveyor(1)
    }

    return (<>
        <div className={styles.title_surveyor} style={{justifyContent: "start", alignItems: 'center'}}>
            {editSurveyor ? <div>Editar Perfil</div> : <div>New surveyor</div>}
            {!editSurveyor && <Form.Check
                type="switch"
                checked={true}
                label={"Compañia Surveyors"}
                style={{margin: '0 20px', fontSize: 18, alignSelf: "center"}}
                onChange={handleCheckCompany}>
            </Form.Check>}
        </div>
        <Row className={styles.contain_all_surveyors}
             style={{margin: '20px 0', justifyContent: 'start', alignSelf: 'start'}}>
            <Col className="mt-4" sm={12} lg={6}>
                <div className={styles.all_surveyors}>
                    <div className={styles.profile_and_title}>
                        <div className={styles.title}>{traduccion.newSurveyor?.detallesPersonales}</div>
                        <p className={styles.errors}>{surveyorError.photo}</p>
                        <div style={{textAlign: 'right'}}>
                            <label
                                className={styles.image}
                                htmlFor="file-input">
                                <img src={profileImage} alt="imagen"/>
                            </label>
                        </div>
                        <input style={{display: 'none'}} onChange={handlePreviewImg} id="file-input" type="file"
                               accept={"image/*"} name="photo"/>
                    </div>

                    <Row>
                        <Col>
                            <div>{traduccion.newSurveyor?.nombre}</div>
                            <p className={styles.errors}>{surveyorError.firstName}</p>
                            <Form.Group controlId="surveyorName" style={{width: '90%'}}>
                                <Form.Control value={surveyor.firstName} onChange={handleChange}
                                              name="firstName" placeholder={"Enter First Name"}
                                              isValid={surveyorError.isValidFirstName}/>
                            </Form.Group>
                            <div style={{marginTop: 23}}>{traduccion.newSurveyor?.apellido}</div>
                            <p className={styles.errors}>{surveyorError.lastName}</p>
                            <Form.Group controlId="lastName" style={{width: '90%'}}>
                                <Form.Control value={surveyor.lastName} onChange={handleChange} name="lastName"
                                              placeholder={"Enter Last Name"}
                                              isValid={surveyorError.isValidLastName}/>
                            </Form.Group>
                            <div style={{marginTop: 23}}>{traduccion.newSurveyor?.pais}</div>
                            <p className={styles.errors}>{surveyorError.country}</p>
                            <Form.Group value={surveyor.country} controlId="country"
                                        style={{width: '90%', marginTop: 0}}>
                                <Typeahead
                                    placeholder={surveyor.country || `${traduccion.newSurveyor?.seleccionPais}`}
                                    style={{margin: '0px !important'}}
                                    className={styles.typeahead}
                                    id="basic-typeahead"
                                    name="country"
                                    labelKey={option => (option !== "CN" || option !== "US") ? `${option}` : null}
                                    onChange={handleChangeCountryEnd}
                                    onInputChange={e => handleChangeCountry(e)}
                                    options={countryCodes.map((country) => countries.countries[country].name).sort()}
                                    isValid={surveyorError.isValidCountry_id}
                                />
                            </Form.Group>
                            <div style={{marginTop: 23}}>{traduccion.newSurveyor?.profesion}</div>
                            <p className={styles.errors}>{surveyorError.profession}</p>
                            <Form.Group controlId="profession" style={{width: '90%'}}>
                                <Form.Control value={surveyor.profession}
                                              onChange={handleChange}
                                              name="profession"
                                              placeholder={"Enter Profession"}
                                              isValid={surveyorError.isValidProfession}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <div>{traduccion.newSurveyor?.celular}</div>
                            <p className={styles.errors}>{surveyorError.phone}</p>
                            <InputGroup controlId="phone" ref={refPhone} style={{width: '90%'}}>
                                {countryCode !== "" && <InputGroup.Text
                                    className={styles.selects_input_countryT}>
                                        <FlagIcon code={countryCode} style={{
                                            height: 18,
                                            width: 22,
                                            border: '1px solid #999',
                                            borderRadius: '2px',
                                            marginRight: 6
                                        }}/>
                                        +{countries.countries[countryCode].phone}
                                    </InputGroup.Text>}
                                <Form.Control ref={refPhoneNum} onChange={handleChange}
                                              className={styles.selects_input}
                                              type="text"
                                              onInput={onlyNumbers}
                                              placeholder={countryCode !== "" ? surveyor.phone.replace(`+${countries.countries[countryCode]?.phone}`, "") || traduccion.newSurveyor?.celularPlaceHolder1 : surveyor.phone.replace(`+${countries.countries[countryCode]?.phone}`, "") || traduccion.newSurveyor?.celularPlaceHolder2}
                                              value={surveyor.phone !== '' ? surveyor.phone.replace(`+${countries.countries[countryCode]?.phone}`, "") : ''}
                                              disabled={countryCode === ""} name="phone"
                                              isValid={surveyorError.isValidPhone}
                                />
                            </InputGroup>
                            <div>{traduccion.newSurveyor?.correoElectronico}</div>
                            <p className={styles.errors}>{surveyorError.email}</p>
                            <Form.Group controlId="email" style={{width: '90%'}}>
                                <Form.Control value={surveyor.email} onChange={handleChange}
                                              onBlur={() => {
                                              }} className={styles.selects_input}
                                              type="text"
                                              placeholder={traduccion.newSurveyor?.emailPlaceHolder}
                                              name="email"
                                              isValid={surveyorError.isValidEmail}
                                />
                            </Form.Group>
                            <div>{traduccion.newSurveyor?.idPersonal}</div>
                            <p className={styles.errors}>{surveyorError.personalId}</p>
                            <Form.Group controlId="personalId" style={{width: '90%'}}>
                                <Form.Control value={surveyor.personalId} onChange={handleChange}
                                              onBlur={() => {
                                              }}
                                              className={styles.selects_input} type="text"
                                              placeholder={traduccion.newSurveyor?.idPlaceHolder} name="personalId"
                                              isValid={surveyorError.isValidPersonalId}
                                />
                            </Form.Group>
                        </Col>
                        <div style={{marginTop: 23}}>{traduccion.newSurveyor?.rangoOperacion} (Km)</div>
                        <p className={styles.errors}>{surveyorError.operationRange}</p>
                        <p className={styles.errors}>{surveyorError.mainAddress}</p>
                        <InputGroup controlId="mainAddress" style={{width: '90%'}}>
                            <Button onClick={() => setShowModal(true)} className={styles.icon_container}
                                    id="button-addon1">
                                <MdOutlineAddLocationAlt className={styles.icon_new_location}/>
                            </Button>
                            <Form.Control className={styles.selects_input3} type="text" disabled={true}
                                          value={surveyor.mainAddress}
                                          placeholder={traduccion.newSurveyor?.direccionPlaceHolder}
                                          name="mainAddress"
                                          isValid={surveyorError.isValidMainAddress}
                            />
                            <Form.Control className={styles.selects_input2} type="text" disabled={true}
                                          value={surveyor.operationRange / 1000}
                                          placeholder="Range"
                                          name="operationRange"
                                          isValid={surveyorError.isValidOperationRange}
                            />
                        </InputGroup>
                        {(surveyor.country_id !== null) && <div style={{marginTop: 20}}>
                            <div style={{fontWeight: 'bold'}}>Country Rate</div>
                            <div>Basic rate per hour: {countryRates.basicRatePerHour}</div>
                            <div>Rate per Km: {countryRates.ratePerKm}</div>
                            <div>Rate per hour: {countryRates.ratePerAddHour}</div>
                        </div>}
                    </Row>
                </div>
            </Col>
            <Col className="mt-4" sm={12} lg={6}>
                <div className={styles.all_surveyors} style={{height: "auto"}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <div style={{fontWeight: 600}}>Company</div>
                            <p className={styles.errors}>{surveyorError.company_id}</p>
                            <Form.Group controlId="company_id">
                                <Form.Select onChange={handleChange} className={styles.selects_input}
                                             style={{width: '25ch'}} name="company_id"
                                             isValid={surveyorError.isValidCompany_id}
                                             value={surveyor.company_id ?? 0}>
                                    <option value={0}>Select company</option>
                                    {company.length > 0 ? companiesByCode().map(item => {
                                        return (<option key={item.id}
                                                        value={item.id}>{item.companyName}</option>)
                                    }) : `Companies not found`}
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size={"lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{width: '100vw'}}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Main Address
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Maps handleChange={{
                        handleChangeRange, handleChangeAddress, handleChangeCoords, handleChangeMapCountryCode
                    }}
                          latitude={surveyor.latitude}
                          longitude={surveyor.longitude}
                          range={surveyor.operationRange}
                    />
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    setShowModal(false)
                }}>Confirmar</Button>
            </Modal.Footer>
        </Modal>

        <Row className={styles.contain_all_surveyors} style={{margin: '1.5rem 0 4rem 0', alignSelf: 'start'}}>
            <Col>
                <div className={styles.all_surveyors} style={{padding: '.8rem 1.5rem'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', margin: '0 1.5rem'}}>
                        <div>
                            <div style={{fontWeight: 600}}>{traduccion?.clients?.status}</div>
                            <p className={styles.errors}>{surveyorError.status_id}</p>
                            <Form.Group controlId="status_id">
                                <Form.Select onChange={handleChange} className={styles.selects_input}
                                             style={{width: '25ch'}} name="status_id"
                                             isValid={false}
                                >
                                    <option value="0" selected={surveyor.status_id === 0}>Seleccione estado
                                    </option>
                                    <option value="1"
                                            selected={surveyor.status_id === 1}>{traduccion?.clients?.active}</option>
                                    <option value="2"
                                            selected={surveyor.status_id === 2}>{traduccion?.clients?.inactive}</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div>
                            <Button disabled={false} onClick={sendForm}
                                    style={{marginTop: '1.5rem', marginLeft: '1.5rem', padding: '.4rem 2rem'}}>
                                {loading ? <Spinner animation="border" role="status">
                                </Spinner> : <span>Save</span>}
                            </Button>
                        </div>
                    </div>
                </div>
            </Col>
            <ToastContainer/>
        </Row>
    </>);
};

export default NewSurveyorCompany;
