import React, {useContext, useEffect, useRef, useState} from "react";
import DBContext from "../../../context/DBContext";
import styles from "../surveyors/mantenedor_surveyor.module.css";
import {Link} from "react-router-dom";
import {Button, Form, Pagination, Table} from "react-bootstrap";
import stylesfiltro from "../../home/ultimos_casos_reclamados/filtros.module.css";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import {BsArrowCounterclockwise, BsPencil, BsTrash} from "react-icons/bs";
import {NewCurrency} from "../currency/NewCurrency";
import {NewUsers} from "./NewUsers";
import LangContext from "../../../context/LangContext";
import axios from "axios";
import {toast} from "react-toastify";
import {urlConfig} from "../../../config/urlConfig";
import {useParamsUrl} from "../useParamsUrl";
import {FaSortDown, FaSortUp} from "react-icons/fa";

export const Users = ({setNewUser, newUser, setRouteActive}) => {
    const {rsk, cambioRsk, setCambioRsk, profiles} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);
    const [oneUser, setOneUser] = useState("");

    //Paginacion
    //Para el filtro
    const [perPage, setPerPage] = useState(10);
    const [active, setActive] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const {searchUrl, handleSearch, params} = useParamsUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/rsk`);
    const [listaUsers, setListaUsers] = useState(rsk);
    const [listaPerfiles, setListaPerfiles] = useState([{id: 1, name: "---"}]);

    const initialFirstName = useRef(true);
    const initialEmail = useRef(true);
    const initialPhone = useRef(true);
    const initialProfileId = useRef(true);
    const initialRskStatus = useRef(true);

    const [firstName, setFirstName] = useState(false);
    const [email, setEmail] = useState(false);
    const [phone, setPhone] = useState(false);
    const [profileId, setProfileId] = useState(false);
    const [rskStatus, setRskStatus] = useState(false);


    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/rsk/?page=0&size=${perPage}`);


    const listaStatus = [
        {id: 1, nombre: "Activo"},
        {id: 2, nombre: "Inactivo"},
    ]

    useEffect(() => {
        axios.get(`${urlConfig.profileUrl}`, {
            headers: {
                Authorization: localStorage.getItem("token")
            }
        }).then((res) => {
            setListaPerfiles(res.data)
        }).catch((err) => {
            console.log({err})
        })
    }, []);

    //console.log(rsk)
    const goToStart = () => {
        setRouteActive("/")
    }
    const handleNewUser = () => {
        setOneUser("");
        setNewUser(2)
    }
    const editUser = (user) => {
        setOneUser(user);
        setNewUser(1)
    }
    const deleteUser = (id) => {
        axios.delete(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/rsk/${id}`, {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", "")
            }
        })
            .then((response) => {
                //console.log(response);
                setCambioRsk(!cambioRsk);
                toast.success(`${traduccion?.success?.successDelUsu}`)
            }).catch((error) => {
            //console.log(error);
            if (error.response.status === 401) {
                toast.error(`${traduccion?.errores?.noAccess}`)
            } else if (error.message) {
                toast.error(error.message)
            } else {
                toast.error(`${traduccion?.errores?.delUsu}`)
            }

        })
    }

    useEffect(() => {
        setCambioRsk(!cambioRsk);
    }, []);

    const findProfile = (id) => {
        let profile = listaPerfiles.find(profile => profile.id === id);
        if (profile === undefined) {
            return "profile undefined";
        }
        return profile.name;
    }

    //Paginacion y filtro
    const token = localStorage.getItem("token").replace("Bearer ", "")
    const getUsers = (theUrl) => {
        axios.get(theUrl, {
            headers: {
                Authorization: token.replace("Bearer ", ""),
            }
        }).then((res) => {
            setListaUsers(res.data.rskDtoList);
            setTotalPages(res.data.totalPages - 1);
        }).catch((error) => {
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }
    const searchUser = () => {
        setActive(0);
        let newUrl = `${searchUrl}&size=${perPage}&page=${0}`;
        setUrl(newUrl);
    }
    const goToPage = (page) => {
        //console.log("GO TO PAGE", page)
        let newUrl = (url.replace(/page=\d+/, `page=${page}`))
        setUrl(newUrl)
        setActive(page)
    }
    useEffect(() => {
        getUsers(url)
    }, [url])

    let items = []
    let oneElipsisA = false
    let oneElipsisD = false
    for (let number = 0; number <= totalPages; number++) {
        //add elipsis
        if (number > 2 && number < totalPages - 2) {
            if (number === active + 1 || number === active - 1) {
                items.push(
                    <Pagination.Item className={styles.pagina} key={number} active={number === active}
                                     onClick={() => goToPage(number)}>
                        {number + 1}
                    </Pagination.Item>,
                );
            } else if (number === active) {
                items.push(
                    <Pagination.Item className={styles.pagina} onClick={() => goToPage(number)} key={number}
                                     active={number === active}>
                        {number + 1}
                    </Pagination.Item>,
                );
            } else {
                if (number < active && !oneElipsisA) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisA = true
                }
                if (number > active && !oneElipsisD) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisD = true
                }
            }
        } else {
            items.push(
                <Pagination.Item className={styles.pagina} onClick={() => goToPage(number)} key={number}
                                 active={number === active}>
                    {number + 1}
                </Pagination.Item>,
            );
        }
    }


    useEffect(() => {
        if (initialFirstName.current) {
            initialFirstName.current = false;
            return;
        }
        if (!firstName) {
            if (!url.includes("sort=fn,desc") && url.includes("sort=fn,asc")) {
                setUrl(url.replace("&sort=fn,asc", "&sort=fn,desc"));
            } else {
                setUrl(url + "&sort=fn,desc");
            }
        } else {
            if (!url.includes("sort=fn,asc") && url.includes("sort=fn,desc")) {
                setUrl(url.replace("&sort=fn,desc", "&sort=fn,asc"))
            } else {
                setUrl(url + "&sort=fn,asc");
            }
        }
    }, [firstName]);

    useEffect(() => {
        if (initialEmail.current) {
            initialEmail.current = false;
            return;
        }
        if (!email) {
            if (!url.includes("sort=em,desc") && url.includes("sort=em,asc")) {
                setUrl(url.replace("&sort=em,asc", "&sort=em,desc"));
            } else {
                setUrl(url + "&sort=em,desc");
            }
        } else {
            if (!url.includes("sort=em,asc") && url.includes("sort=em,desc")) {
                setUrl(url.replace("&sort=em,desc", "&sort=em,asc"))
            } else {
                setUrl(url + "&sort=em,asc");
            }
        }
    }, [email]);

    useEffect(() => {
        if (initialPhone.current) {
            initialPhone.current = false;
            return;
        }
        if (!phone) {
            if (!url.includes("sort=ph,desc") && url.includes("sort=ph,asc")) {
                setUrl(url.replace("&sort=ph,asc", "&sort=ph,desc"));
            } else {
                setUrl(url + "&sort=ph,desc");
            }
        } else {
            if (!url.includes("sort=ph,asc") && url.includes("sort=ph,desc")) {
                setUrl(url.replace("&sort=ph,desc", "&sort=ph,asc"))
            } else {
                setUrl(url + "&sort=ph,asc");
            }
        }
    }, [phone]);

    useEffect(() => {
        if (initialProfileId.current) {
            initialProfileId.current = false;
            return;
        }
        if (!profileId) {
            if (!url.includes("sort=pr,desc") && url.includes("sort=pr,asc")) {
                setUrl(url.replace("&sort=pr,asc", "&sort=pr,desc"));
            } else {
                setUrl(url + "&sort=pr,desc");
            }
        } else {
            if (!url.includes("sort=pr,asc") && url.includes("sort=pr,desc")) {
                setUrl(url.replace("&sort=pr,desc", "&sort=pr,asc"))
            } else {
                setUrl(url + "&sort=pr,asc");
            }
        }
    }, [profileId]);

    useEffect(() => {
        if (initialRskStatus.current) {
            initialRskStatus.current = false;
            return;
        }
        if (!rskStatus) {
            if (!url.includes("sort=st,desc") && url.includes("sort=st,asc")) {
                setUrl(url.replace("&sort=st,asc", "&sort=st,desc"));
            } else {
                setUrl(url + "&sort=st,desc");
            }
        } else {
            if (!url.includes("sort=st,asc") && url.includes("sort=st,desc")) {
                setUrl(url.replace("&sort=st,desc", "&sort=st,asc"))
            } else {
                setUrl(url + "&sort=st,asc");
            }
        }
    }, [rskStatus]);

    useEffect(() => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.get(url, header).then((response) => {
            initialEmail.current = false;
            initialFirstName.current = false;
            initialRskStatus.current = false;
            initialPhone.current = false;
            initialProfileId.current = false;
        }).catch((error) => {
            console.log({error})
        })
    }, [url]);

    const resetSort = () => {
        initialEmail.current = true;
        initialFirstName.current = true;
        initialRskStatus.current = true;
        initialPhone.current = true;
        initialProfileId.current = true;
        setFirstName(true);
        setPhone(true);
        setEmail(true);
        setProfileId(true);
        setRskStatus(true);
        setUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/rsk/?page=0&size=${perPage}`)
    }

    const sortParams = (sort) => {
        if (sort === "fn") {
            setFirstName(!firstName);
        }
        if (sort === "em") {
            setEmail(!email);
        }
        if (sort === "ph") {
            setPhone(!phone);
        }
        if (sort === "pr") {
            setProfileId(!profileId);
        }
        if (sort === "st") {
            setRskStatus(!rskStatus);
        }
    }


    return (
        <>
            <div className={styles.all_surveyor}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>
                    {newUser === 0 &&
                        <span className={styles.bold}>{traduccion?.mantenedoresII?.usuario}</span>
                    }
                    {(newUser === 1 && oneUser.id !== undefined) &&
                        <><span onClick={() => setNewUser(0)}
                                className={styles.like_a}>{traduccion?.mantenedoresII?.usuario}</span>
                            <span className={styles.bold}>{traduccion?.mantenedoresII?.editUsuario}</span></>
                    }
                    {(newUser === 2 && newUser.id === undefined) &&
                        <><span onClick={() => setNewUser(0)}
                                className={styles.like_a}>{traduccion?.mantenedoresII?.usuario}</span>
                            <span className={styles.bold}>{traduccion?.mantenedoresII?.nuevoUsuario}</span></>
                    }
                </div>
                {newUser === 0
                    &&
                    <>
                        <div className={styles.title_surveyor}>
                            <p>{traduccion?.mantenedoresII?.nuevoUsuario}</p>
                            <Button className={styles.title_button} variant="primary"
                                    onClick={handleNewUser}>
                                {traduccion?.mantenedoresII?.nuevoUsuario}</Button>
                        </div>
                        {/*------------ Filtro ------------*/}
                        <div className={stylesfiltro.filtro_total} style={{margin: 0, width: '100%'}}>
                            <div className={stylesfiltro.filtros_busqueda}>
                                <p className={stylesfiltro.title_filter}>{traduccion?.clients?.filtros}</p>
                                <div className={stylesfiltro.opciones_busqueda}>
                                    <Form>
                                        <div>
                                            <label htmlFor="container_select">{traduccion?.clients?.name}</label>
                                            <Form.Control
                                                style={{
                                                    backgroundColor: '#f1f3fe',
                                                    boxShadow: 'inset 1px 1px 3px #ccc'
                                                }}
                                                onChange={handleSearch}
                                                name="name" type="text"
                                                placeholder={traduccion?.clients?.name}/>
                                        </div>
                                        <div>
                                            <label htmlFor="estado">{traduccion?.mantenedoresII?.tipoUsuario}</label>
                                            <Form.Select
                                                onChange={handleSearch}
                                                className="form-label" id="estado"
                                                name="profileId">
                                                <option value=""
                                                        defaultValue={true}>{traduccion?.clients?.selectOption}</option>

                                                {listaPerfiles.map((item, idx) => {
                                                    return (
                                                        <option key={idx} value={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                        </div>
                                        <div>
                                            <label htmlFor="estado">{traduccion?.clients?.status}</label>
                                            <Form.Select
                                                onChange={handleSearch}
                                                className="form-label" id="estado"
                                                name="statusId">
                                                <option value=""
                                                        defaultValue={true}>{traduccion?.clients?.selectOption}</option>

                                                {listaStatus.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.nombre}</option>
                                                    )
                                                })}
                                            </Form.Select>

                                        </div>
                                        <Button className={stylesfiltro.boton_filtro} variant="primary"
                                                onClick={searchUser}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                                    fill="white"/>
                                            </svg>
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className={stylesTabla.contiene_tabla}>
                            <Table borderless hover className={stylesTabla.tabla}>
                                <thead>
                                <tr>
                                    <th>{traduccion?.mantenedoresII?.nombre} <a
                                        onClick={() => sortParams("fn")}> {firstName ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th>{traduccion?.mantenedoresII?.perfil} <a
                                        onClick={() => sortParams("pr")}> {profileId ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th>{traduccion?.mantenedoresII?.phone} <a
                                        onClick={() => sortParams("ph")}> {phone ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th>{traduccion?.login?.correo} <a
                                        onClick={() => sortParams("em")}> {email ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th>{traduccion?.mantenedoresII?.estado} <a
                                        onClick={() => sortParams("st")}> {rskStatus ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th><a onClick={() => resetSort()}><BsArrowCounterclockwise/></a></th>

                                </tr>

                                </thead>
                                <tbody>

                                {listaUsers && listaUsers.map((datos, idx) =>
                                    <tr key={idx} className={stylesTabla.tr_data}>
                                        <td>{datos.firstName} {datos.lastName}</td>
                                        <td>{findProfile(datos?.account?.profile_id)}</td>
                                        <td>{datos?.account.phone}</td>
                                        <td>{datos?.account.email}</td>
                                        <td>{datos?.status === true ? `${traduccion?.mantendores?.activo}` : `${traduccion?.mantendores?.inactivo}`}</td>
                                        <td>
                                            <div className={stylesTabla.botones_botones_surveyor}
                                                 style={{display: 'flex'}}>
                                                <div onClick={() => editUser(datos)}
                                                     className={stylesTabla.boton_icono}>
                                                    <BsPencil/>
                                                </div>
                                                <div
                                                    onClick={() => deleteUser(datos.id)}
                                                    className={stylesTabla.boton_icono}>
                                                    <BsTrash style={{color: 'red'}}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                    </>

                }
                {items && <Pagination>{items}</Pagination>}
                {(newUser === 1 || newUser === 2) &&
                    <NewUsers oneUser={oneUser} newUser={newUser} listaStatus={listaStatus}/>}
            </div>
        </>
    );
}
