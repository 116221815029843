import React, {useContext, useEffect, useRef, useState} from 'react';
import {Dropdown, Table} from 'react-bootstrap/'
import styles from "./tabla.module.css"
import stylesTable from "../../home/ultimos_casos_reclamados/filtros.module.css";
import {Button, DropdownButton, Form, Modal, Pagination} from "react-bootstrap";
import DBContext from "../../../context/DBContext";
import LangContext from "../../../context/LangContext";
import axios from "axios";
import {useNavigate} from "react-router";
import {urlConfig} from "../../../config/urlConfig";
import {startClaim} from "../../../config/initial";
import stylesTabla from "../table_stuffing/tabla_stuff.module.css";
import {BsArrowCounterclockwise, BsPencil, BsTrash} from "react-icons/bs";
import {useParamsUrl} from "../../mantenedores/useParamsUrl";
import {toast, ToastContainer} from "react-toastify";
import {IoMdAirplane, IoMdBoat, IoMdBus} from "react-icons/io";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import Pager from "../../mantenedores/orden_cobro/Pager";

const Tabla = ({setOneClaim}) => {

    const {traduccion} = useContext(LangContext);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [modalId, setModalId] = useState(false);
    // get token
    const token = localStorage.getItem("token");

    const [claims, setClaim] = useState([]);

    const initialExporterOrder = useRef(true);
    const initialPolOrder = useRef(true);
    const initialPodOrder = useRef(true);
    const initialContainerOrder = useRef(true);
    const initialDaysOrder = useRef(false);
    const initialVesselOrder = useRef(true);
    const initialFeeOrder = useRef(true);
    const initialStatusOrder = useRef(true);

    const [perPage, setPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [active, setActive] = useState(0);

    const [exporterOrder, setExporterOrder] = useState(true);
    const [containerOrder, setContainerOrder] = useState(true);
    const [vesselOrder, setVesselOrder] = useState(true);
    const [polOrder, setPolOrder] = useState(true);
    const [podOrder, setPodOrder] = useState(true);
    const [statusOrder, setStatusOrder] = useState(true);
    const [daysOrder, setDaysOrder] = useState(true);
    const [feeOrder, setFeeOrder] = useState(true);

    const {searchUrl, handleSearch, params} = useParamsUrl(urlConfig.searchClaimUrl);

    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me/search?page=0&size=${perPage}`);


    const [status, setStatus] = useState([
        {
            id: 1,
            name: "New"
        },
        {
            id: 2,
            name: "Survey Report"
        },
        {
            id: 3,
            name: "Pending documents"
        },
        {
            id: 4,
            name: "Ready to Claim"
        },
        {
            id: 5,
            name: "Closed"
        },
        {
            id: 6,
            name: "Out of Court"
        },
        {
            id: 7,
            name: "In court"
        },
        {
            id: 8,
            name: "Recovered"
        },
        {
            id: 9,
            name: "Not Recovered"
        },
        {
            id: 10,
            name: "Signed release"
        }


    ]);


    const searchClaims = (e) => {
        e.preventDefault()
        setActive(0);
        let newUrl = `${searchUrl}&size=${perPage}&page=${0}`;
        setUrl(newUrl);
    }

    useEffect(() => {
        let newUrl = (url.replace(/size=\d+/, `size=${perPage}`))
        newUrl.replace(/page=\d+/, `page=0`)
        setUrl(newUrl)
        setActive(0)
    }, [perPage])

    useEffect(() => {
        getClaims(url)
    }, [url])

    const handleNew = (e) => {
        let hasMap = true
        if (e === "noMap") {
            hasMap = false
        }
        startClaim.hasMap = hasMap
        axios.post(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me`, startClaim, {
            //axios.post(`${urlConfig.urlKite}/api/v1/claim/me`, startClaim, {
            headers: {
                Authorization: token.toString().replace("Bearer ", "")
            }
        }).then((res) => {
            setOneClaim({
                ...startClaim,
                "id": res.data.id,
                "hasMap": hasMap,
                "cargo": {
                    "damagesId": [],
                    "damages": [],
                    "containerNumber": "",
                    "temperature": "",
                    "ventilation": "",
                    "ca": false,
                    "coldTreatment": false,
                    "eta": "",
                    "etd": "",
                    "reclaimedAmount": 0,
                    "currency": 1,
                    "fruits": []
                }
            })

            axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/exporter/company_contract`, {
                headers: {
                    Authorization: `${localStorage.getItem("token").replace("Bearer ", "")}`,
                }
            }).then((res) => {
                //console.log(res.data[0])
                navigate('/informe_inspeccion', {state: {id: res.data.id, oneExporter: res.data[0]}})
            }).catch((err) => {
                //console.log(err)
            })
        }).catch((error) => {
            toast.error(error.response.data.message)
        })
    }

    const editClaim = (id) => {
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me/${id}`, {
            //axios.get(`${urlConfig.urlKite}/api/v1/claim/me/${id}`,  {

            headers: {
                Authorization: token.toString().replace("Bearer ", "")
            }
        }).then((res) => {
            //console.log("EDITANDO RECLAMO...", res.data)
            let newData = {...startClaim, ...res.data}
            if (newData.comment === null) {
                newData.comment = ""
            }
            if (newData.claimDate === null) {
                newData.claimDate = ""
            }
            if (newData.lossTypeId === null) {
                newData.lossTypeId = 1
            }
            if (newData.cargo?.fruits !== undefined) {
                for (const fruits of newData.cargo.fruits) {
                    for (const harvest of fruits.fruits.harvesting) {
                        if (harvest.harvestDate === null) {
                            harvest.harvestDate = ""
                        }
                    }
                    for (const packa of fruits.fruits.packaging) {
                        if (packa.packageDate === null) {
                            packa.packageDate = ""
                        }
                    }
                }
            }
            newData.documents = {
                "shippingDocuments": res.data.documents?.shipping || [],
                "shipping": res.data.documents?.shipping || [],
                "consolidatedReport": res.data.documents?.consolidatedReport || "",
                "controlledAtmosphere": res.data.documents?.controlledAtmosphere || "",
                "thermograph": res.data.documents?.thermograph || "",
                "thermograph2": res.data.documents?.thermograph2 || "",
                "other": res.data?.documents.other || "",
                "assignmentLetter": res.data.documents?.assignmentLetter || "",
                "assignmentRights": res.data.documents?.assignmentRights || "",
                "survey": res.data.documents?.surveyor?.survey || "",
                "photograph": res.data.documents?.surveyor?.photograph || "",
                "notice": res.data.documents?.surveyor?.notice || "",
                "noticeDate": res.data.documents?.surveyor?.noticeDate || "",
                "destruction": res.data.documents?.surveyor?.destruction || "",
                "receipt": res.data.documents?.recovery?.receipt || "",
                "settlement": res.data.documents?.recovery?.settlement || "",
                "calculation": res.data.documents?.calculation || {
                    "creditNote": [],
                    "extraExpenses": [],
                    "marketPrices": [],
                    "salesResults": []
                }
            };


            if (newData.lossCalculation.length === 0) {
                //console.log("NO HAY CALCULO DE PERDIDA")
                newData.lossCalculation = startClaim.lossCalculation
            } else {
                //console.log("SI HAY CALCULO DE PERDIDA")
                //console.log("CALCULO DE PERDIDA", res.data.lossCalculation)
                //console.log("CALCULO DE PERDIDA", newData)
                newData.lossCalculation = res.data.lossCalculation
            }
            //console.log("NEW DATA", newData)
            setOneClaim(newData)
            navigate('/informe_inspeccion', {state: {id: res.data.id, editExporter: true}});
        }).catch((error) => {
            //console.log(error);
        })
    }


    const deleteClaim = (id) => {
        axios.delete(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me/${id}`, {
            //axios.delete(`${urlConfig.urlKite}/api/v1/claim/me/${id}`, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            // setCambioClaims(!cambioClaims)
            getClaims(url)
        }).catch((error) => {
            //console.log(error);
        })
        setShowModal(false)

    }

//-----------Paginacion-----------

    const getClaims = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            setClaim(res.data.claims)
            setTotalPages(res.data.totalPages - 1)
        }).catch((error) => {
            //console.log(error);
            if (typeof error.message === "string") {
                toast.error(`${error.message}`, {
                    position: toast.POSITION.TOP_RIGHT
                })
            } else {
                toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                    position: toast.POSITION.TOP_RIGHT
                })

            }
        })
    }

    /*
    const goToPage = (page) => {
        //console.log("GO TO PAGE", page)
        url = (url.replace(/page=\d+/, `page=${page}`))
        //console.log("URL", url)
        setUrl(url)
        setActive(page)
    }*/

    const handlePerPage = (e) => {
        let page = e.target.value
        setPerPage(page)
    }


    const handleShow = (id) => {
        setShowModal(true)
        setModalId(id)
    }

    const handleStatusDays = (statusDate) => {
        //console.log("STATUS DATE", statusDate)
        if (statusDate !== null && statusDate !== undefined && statusDate !== "") {
            let today = new Date()
            let date = new Date(statusDate)
            let diff = Math.abs(today - date)
            let days = Math.ceil(diff / (1000 * 60 * 60 * 24))
            if (days === 1) {
                return "1 día"
            } else {
                return days + " días"
            }
        } else {
            return "Sin Fecha"
        }
    }

    const handleCurr = (type, value) => {
        if (value === null || value === undefined || type === null || type === undefined) {
            return "---"
        }
        const lang = localStorage.getItem("lang");
        const formatter = new Intl.NumberFormat(lang, {
            style: 'currency',
            currency: `${type}`,
        });
        return formatter.format(value)
    }


    useEffect(() => {
        if (initialExporterOrder.current) {
            initialExporterOrder.current = false;
            return;
        }
        if (!exporterOrder) {
            if (!url.includes("sort=exp,desc") && url.includes("sort=exp,asc")) {
                setUrl(url.replace("&sort=exp,asc", "&sort=exp,desc"));
            } else {
                setUrl(url + "&sort=exp,desc");
            }
        } else {
            if (!url.includes("sort=exp,asc") && url.includes("sort=exp,desc")) {
                setUrl(url.replace("&sort=exp,desc", "&sort=exp,asc"))
            } else {
                setUrl(url + "&sort=exp,asc");
            }
        }
    }, [exporterOrder]);

    useEffect(() => {
        if (initialPolOrder.current) {
            initialPolOrder.current = false;
            return;
        }
        if (!polOrder) {
            if (!url.includes("sort=pol,desc") && url.includes("sort=pol,asc")) {
                setUrl(url.replace("&sort=pol,asc", "&sort=pol,desc"));
            } else {
                setUrl(url + "&sort=pol,desc");
            }
        } else {
            if (!url.includes("sort=pol,asc") && url.includes("sort=pol,desc")) {
                setUrl(url.replace("&sort=pol,desc", "&sort=pol,asc"))
            } else {
                setUrl(url + "&sort=pol,asc");
            }
        }
    }, [polOrder]);

    useEffect(() => {
        if (initialContainerOrder.current) {
            initialContainerOrder.current = false;
            return;
        }
        if (!containerOrder) {
            if (!url.includes("sort=con,desc") && url.includes("sort=con,asc")) {
                setUrl(url.replace("&sort=con,asc", "&sort=con,desc"));
            } else {
                setUrl(url + "&sort=con,desc");
            }
        } else {
            if (!url.includes("sort=con,asc") && url.includes("sort=con,desc")) {
                setUrl(url.replace("&sort=con,desc", "&sort=con,asc"))
            } else {
                setUrl(url + "&sort=con,asc");
            }
        }
    }, [containerOrder]);

    useEffect(() => {
        if (!initialDaysOrder.current) {
            initialDaysOrder.current = true;
            return;
        }
        if (!daysOrder) {
            if (!url.includes("sort=days,desc") && url.includes("sort=days,asc")) {
                setUrl(url.replace("&sort=days,asc", "&sort=days,desc"));
            } else {
                setUrl(url + "&sort=days,desc");
            }
        } else {
            if (!url.includes("sort=days,asc") && url.includes("sort=days,desc")) {
                setUrl(url.replace("&sort=days,desc", "&sort=days,asc"))
            } else {
                setUrl(url + "&sort=days,asc");
            }
        }
    }, [daysOrder]);


    useEffect(() => {
        if (initialStatusOrder.current) {
            initialStatusOrder.current = false;
            return;
        }
        if (!statusOrder) {
            if (!url.includes("sort=st,desc") && url.includes("sort=st,asc")) {
                setUrl(url.replace("&sort=st,asc", "&sort=st,desc"));
            } else {
                setUrl(url + "&sort=st,desc");
            }
        } else {
            if (!url.includes("sort=st,asc") && url.includes("sort=st,desc")) {
                setUrl(url.replace("&sort=st,desc", "&sort=st,asc"));
            } else {
                setUrl(url + "&sort=st,asc");
            }
        }
    }, [statusOrder]);


    useEffect(() => {
        if (initialPodOrder.current) {
            initialPodOrder.current = false;
            return;
        }
        if (!podOrder) {
            if (!url.includes("sort=pod,desc") && url.includes("sort=pod,asc")) {
                setUrl(url.replace("&sort=pod,asc", "&sort=pod,desc"));
            } else {
                setUrl(url + "&sort=pod,desc");
            }
        } else {
            if (!url.includes("sort=pod,asc") && url.includes("sort=pod,desc")) {
                setUrl(url.replace("&sort=pod,desc", "&sort=pod,asc"));
            } else {
                setUrl(url + "&sort=pod,asc");
            }
        }
    }, [podOrder]);



    useEffect(() => {
        if (initialVesselOrder.current) {
            initialVesselOrder.current = false;
            return;
        }
        if (!vesselOrder) {
            if (!url.includes("sort=vsl,desc") && url.includes("sort=vsl,asc")) {
                setUrl(url.replace("&sort=vsl,asc", "&sort=vsl,desc"));
            } else {
                setUrl(url + "&sort=vsl,desc");
            }
        } else {
            if (!url.includes("sort=vsl,asc") && url.includes("sort=vsl,desc")) {
                setUrl(url.replace("&sort=vsl,desc", "&sort=vsl,asc"));
            } else {
                setUrl(url + "&sort=vsl,asc");
            }
        }
    }, [vesselOrder]);

    useEffect(() => {
        if (initialFeeOrder.current) {
            initialFeeOrder.current = false;
            return;
        }
        if (!feeOrder) {
            if (!url.includes("sort=fee,desc") && url.includes("sort=fee,asc")) {
                setUrl(url.replace("&sort=fee,asc", "&sort=fee,desc"));
            } else {
                setUrl(url + "&sort=fee,desc");
            }
        } else {
            if (!url.includes("sort=fee,asc") && url.includes("sort=fee,desc")) {
                setUrl(url.replace("&sort=fee,desc", "&sort=fee,asc"));
            } else {
                setUrl(url + "&sort=fee,asc");
            }
        }
    }, [feeOrder]);


    const goToPage = (page) => {
        //console.log("GO TO PAGE", page)
        let newUrl = (url.replace(/page=\d+/, `page=${page}`))
        //console.log("URL", newUrl)
        setUrl(newUrl)
        setActive(page)
    }

    const resetSort = () => {
        initialExporterOrder.current = true;
        initialPolOrder.current = true;
        initialPodOrder.current = true;
        initialContainerOrder.current = true;
        initialDaysOrder.current = false;
        initialVesselOrder.current = true;
        initialFeeOrder.current = true;
        initialStatusOrder.current = true;
        setExporterOrder(true);
        setPolOrder(true);
        setPodOrder(true);
        setContainerOrder(true);
        setDaysOrder(false);
        setVesselOrder(true);
        setFeeOrder(true);
        setStatusOrder(true);
        setUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/claim/me/search?page=0&size=${perPage}`)
    }

    const sortParams = (sort) => {
        if (sort === "exp") {
            setExporterOrder(!exporterOrder);
        }
        if (sort === "pol") {
            setPolOrder(!polOrder);
        }
        if (sort === "pod") {
            setPodOrder(!podOrder);
        }
        if (sort === "con") {
            setContainerOrder(!containerOrder);
        }
        if (sort === "days") {
            setDaysOrder(!daysOrder);
        }
        if (sort === "vsl") {
            setVesselOrder(!vesselOrder);
        }
        if (sort === "fee") {
            setFeeOrder(!feeOrder);
        }
        if (sort === "st") {
            setStatusOrder(!statusOrder);
        }
    }


    return (
        <>
            <ToastContainer/>

            <div className={stylesTable.filtro_total}>
                <p className={stylesTable.title_claimed}>{traduccion.claimed?.lastClaim}</p>
                <div className={stylesTable.filtros_busqueda}>
                    <p className={stylesTable.title_filter}>{traduccion.claimed?.filtro}</p>
                    <div className={stylesTable.opciones_busqueda}>
                        <Form>
                            <div style={{display: 'inline'}}>
                                <label htmlFor="container_select">{traduccion.claimed?.NContainer}</label>
                                <Form.Group controlId="container">
                                    <Form.Control type="text" placeholder="RSKXXXXXXX" onChange={handleSearch}
                                                  name="container"/>
                                </Form.Group>
                            </div>
                            <div>
                                <Form.Group controlId="vessel">
                                    <label htmlFor="nave_select">{traduccion.claimed?.nave}</label>
                                    <Form.Control type="text" placeholder="VESSEL NAME" onChange={handleSearch}
                                                  name="vessel"/>
                                </Form.Group>
                            </div>
                            <div>
                                <label htmlFor="estado">{traduccion.claimed?.estado}</label>

                                <Form.Select id="estado" onChange={handleSearch} name="status">
                                    <option value="">-</option>
                                    {
                                        status.length > 0 ?
                                            status.map((item, idx) =>
                                                <option key={idx} value={item.id}>{item.name}</option>
                                            ) : null
                                    }
                                </Form.Select>

                            </div>
                            <Button className={stylesTable.boton_filtro} variant="primary" onClick={searchClaims}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                        fill="white"/>
                                </svg>
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>

            <div className={styles.mostrar_nuevo_reclamo}>
                <div className={styles.mostrar}>
                    <p>{traduccion.claimTable?.mostrar}</p>
                    <Form.Select onChange={handlePerPage} size="sm" id="show">
                        <option value="4">4</option>
                        <option value="8">8</option>
                        <option value="20" selected={true}>20</option>
                    </Form.Select>
                </div>
                <div className={styles.dropdown}>
                    <DropdownButton id="dropdown-basic-button" title={traduccion.claimTable?.newClaim || "dropdown"}>
                        <Dropdown.Item onClick={handleNew}>Survey Online</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleNew("noMap")}>Recovery proccess only</Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>
            <div className={styles.contiene_tabla}>
                <Table className={styles.tabla} borderless hover>
                    <thead>
                    <tr>
                        <th>{traduccion.claimTable?.exporter} <a onClick={() => sortParams("exp")}> {exporterOrder ?
                            <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th className={styles.th_container_nr}>{traduccion.claimTable?.nContainer} <a
                            onClick={() => sortParams("con")}> {containerOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion.claimTable?.nave} <a onClick={() => sortParams("vsl")}> {vesselOrder ?
                            <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>POL <a onClick={() => sortParams("pol")}> {polOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>POD <a onClick={() => sortParams("pod")}> {podOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion.claimTable?.estado} <a onClick={() => sortParams("st")}> {statusOrder ?
                            <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th>{traduccion.claimTable?.dEstado} <a onClick={() => sortParams("days")}> {daysOrder ?
                            <FaSortUp/> : <FaSortDown/>}</a></th>
                        <th className={styles.th_survey_fees} style={{minWidth: '12ch'}}>{traduccion.claimTable?.fee} <a
                            onClick={() => sortParams("fee")}> {feeOrder ? <FaSortUp/> :
                            <FaSortDown/>}</a></th>
                        <th><a onClick={() => resetSort()}><BsArrowCounterclockwise/></a></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        claims?.length > 0 ?
                            claims?.map((datos, idx) => {
                                if (datos.has_map !== undefined) {
                                    datos.hasMap = datos.has_map
                                }
                                return (
                                    <tr key={idx} className={styles.tr_data}>
                                        <td style={{position: 'relative'}}>{datos.exporterName}
                                            <div style={{
                                                position: "absolute",
                                                top: 0,
                                                bottom: 0,
                                                left: 70,
                                                color: '#0000ee19',
                                                overflow: 'hidden'
                                            }}>
                                                {datos.transportId === 1 &&
                                                    <IoMdBoat style={{fontSize: 100, height: '100%'}}/>
                                                }
                                                {
                                                    datos.transportId === 2 &&
                                                    <IoMdAirplane style={{fontSize: 100, height: '100%'}}/>
                                                }
                                                {
                                                    datos.transportId === 3 &&
                                                    <IoMdBus style={{fontSize: 100, height: '100%'}}/>
                                                }
                                            </div>
                                        </td>

                                        <td>{datos.cargo?.containerNumber ?? datos.containerName}</td>
                                        <td>{datos.vessel}</td>
                                        <td>{datos.pol}</td>
                                        <td>{datos.pod}</td>
                                        <td>{datos.claimStatus?.description}</td>
                                        {/*<td>{datos.timeline.length > 0 && (datos.timeline?.slice(-1)[0].description ?? datos.status)}</td>*/}
                                        <td>{handleStatusDays(datos?.claimStatus?.date)}</td>
                                        <td>{datos.inspection && datos.inspection.summaryCalculation ? `${handleCurr(datos.currencySurveyor, datos.inspection.summaryCalculation.totalIncome)}` : datos.totalIncome ? `${handleCurr(datos.currencySurveyor, datos.totalIncome)}` : "---"}</td>
                                        <td style={{textAlign: "center"}}>

                                            <div className={stylesTabla.botones_botones_surveyor}
                                                 style={{display: 'flex'}}>
                                                <div
                                                    onClick={() => editClaim(datos.id)}
                                                    className={stylesTabla.boton_icono}>
                                                    <BsPencil/>
                                                </div>
                                                <div
                                                    onClick={() => handleShow(datos.id)}
                                                    className={stylesTabla.boton_icono}>
                                                    <BsTrash style={{color: 'red'}}/>
                                                </div>
                                                <div>
                                                    {/*<svg width="22" height="18" viewBox="0 0 22 18" fill="none"*/}
                                                    {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                                    {/*    <path*/}
                                                    {/*        d="M10.819 0C16.211 0 20.697 3.88 21.638 9C20.698 14.12 16.211 18 10.819 18C5.427 18 0.941 14.12 0 9C0.94 3.88 5.427 0 10.819 0ZM10.819 16C12.8585 15.9996 14.8374 15.3068 16.4319 14.0352C18.0263 12.7635 19.1419 10.9883 19.596 9C19.1402 7.0133 18.0239 5.24 16.4296 3.97003C14.8353 2.70005 12.8573 2.00853 10.819 2.00853C8.7807 2.00853 6.80269 2.70005 5.20838 3.97003C3.61406 5.24 2.49777 7.0133 2.042 9C2.4961 10.9883 3.61167 12.7635 5.20614 14.0352C6.80061 15.3068 8.77953 15.9996 10.819 16ZM10.819 13.5C9.62553 13.5 8.48093 13.0259 7.63702 12.182C6.79311 11.3381 6.319 10.1935 6.319 9C6.319 7.80653 6.79311 6.66193 7.63702 5.81802C8.48093 4.97411 9.62553 4.5 10.819 4.5C12.0125 4.5 13.1571 4.97411 14.001 5.81802C14.8449 6.66193 15.319 7.80653 15.319 9C15.319 10.1935 14.8449 11.3381 14.001 12.182C13.1571 13.0259 12.0125 13.5 10.819 13.5ZM10.819 11.5C11.482 11.5 12.1179 11.2366 12.5868 10.7678C13.0556 10.2989 13.319 9.66304 13.319 9C13.319 8.33696 13.0556 7.70107 12.5868 7.23223C12.1179 6.76339 11.482 6.5 10.819 6.5C10.156 6.5 9.52007 6.76339 9.05123 7.23223C8.58239 7.70107 8.319 8.33696 8.319 9C8.319 9.66304 8.58239 10.2989 9.05123 10.7678C9.52007 11.2366 10.156 11.5 10.819 11.5Z"*/}
                                                    {/*        fill="#5B69EA"/>*/}
                                                    {/*</svg>*/}
                                                </div>
                                                {!datos.hasMap &&
                                                    <div style={{
                                                        width: '4rem',
                                                        height: '100%',
                                                        background: 'rebeccapurple',
                                                        fontSize: 10,
                                                        lineHeight: 1.4,
                                                        padding: '8px 0',
                                                        fontWeight: "bolder",
                                                        color: 'white',
                                                        borderRadius: 5
                                                    }}>
                                                        Recovery Process
                                                    </div>

                                                }
                                                {/*{(datos.hasMap && datos.lastTimelineId !== 10) &&*/}
                                                {datos.hasMap &&

                                                    <div style={{
                                                        width: '4rem',
                                                        height: '100%',
                                                        background: 'darkgreen',
                                                        fontSize: 10,
                                                        lineHeight: 1.4,
                                                        padding: '8px 0',
                                                        fontWeight: "bolder",
                                                        color: 'white',
                                                        borderRadius: 5
                                                    }}>
                                                        Survey Online
                                                    </div>

                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan="8" style={{textAlign: "center"}}>{traduccion?.factura?.noData}</td>
                            </tr>
                    }
                    </tbody>
                </Table>
            </div>
            <Pager totalPages={totalPages} active={active} goToPage={goToPage}/>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
            >
                <Modal.Body>
                    <div className={stylesTabla.modal_body} style={{padding: '.5rem'}}>
                        <div className={stylesTabla.modal_body_header}>
                            <h3>{traduccion?.clients?.rUSure}</h3>
                        </div>
                        <div className={stylesTabla.modal_body_body}>
                            <div className={stylesTabla.modal_body_body_text}>
                                <p style={{fontWeight: '500'}}>Claim ID: <span
                                    style={{color: '#f55', fontWeight: '900'}}>{modalId}</span></p>
                            </div>
                            <div className={stylesTabla.modal_body_body_buttons}
                                 style={{justifyContent: 'space-around', display: 'flex', marginTop: '2rem'}}>
                                <Button onClick={() => setShowModal(false)}>
                                    {traduccion?.clients?.cancelar}
                                </Button>
                                <Button variant={"danger"} onClick={() => deleteClaim(modalId)}>
                                    {traduccion?.clients?.borrar}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default Tabla;
