import styles from "./surveyor_details.module.css"
import stylest from "../../home/table_stuffing/tabla_stuff.module.css";
import stylesNew from "./new_surveyor.module.css";
import {AiFillStar} from "react-icons/ai";
import {Button, Form, InputGroup, Table} from "react-bootstrap";
import React, {useContext, useEffect} from "react";
import axios from "axios";
import {forEach} from "react-bootstrap/ElementChildren";
import {urlConfig} from "../../../config/urlConfig";
import LangContext from "../../../context/LangContext";
import {FaFileDownload} from "react-icons/fa";
import {onlyNumbers} from "../../../config/functions";

export const SurveyorDetail = ({oneSurveyor,surveyorId}) => {

    const {traduccion} = useContext(LangContext);

    const [claims, setClaims] = React.useState([]);
    //formatear fecha

    const formatDate = (date) => {
        const year = date.substring(0, 4);
        const month = date.substring(5, 7);
        const day = date.substring(8, 10);
        return day + "/" + month + "/" + year;
    }

    useEffect(() => {
        //token
        //console.log(surveyorId)
        const token = localStorage.getItem('token');
        let url = `${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/inspector/payment/`+surveyorId;
        axios.get(url, {
            headers: {
                'Authorization': `${token}`
            }
        }).then((response) => {
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].claimDate !== null) {
                    response.data[i].claimDate = formatDate(response.data[i].claimDate);
                }
                else{
                    response.data[i].claimDate = "Sin fecha"
                }
            }
            // let data = {...response.data, claimDate: formatDate(response.data.claimDate)}
            setClaims(response.data);
        }).catch((error) => {
            //console.log(error);

        })
    }   ,[])

    const downloadFile = (bytes, fileName) => {
        const blob = new Blob([bytes], {type: "application/pdf"});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click()
    }
    const handleDownload = (e) => {
        //console.log("handleDownload" + e)
        axios({
            url: `${urlConfig.allUrl}:9004/api/v1/files/download?file=${e}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        }).then((response) => {
            downloadFile(response.data, e)
        }).catch((error) => {
            //console.log(error);
        });
    }
    return (
        <>
            <div className={styles.surveyor_details_big_title}>{oneSurveyor.surveyorName}</div>
            <div className={styles.surveyor_details_all}>
                <div className={styles.surveyor_details_title}>Personal details</div>
                <div className={styles.personal_details_container}>
                    <div className={styles.foto_perf_cont}>
                        <img className={styles.foto_perfil} src={oneSurveyor.photo} alt=""/>

                        <div>
                            <span style={{fontWeight: '500'}}>{oneSurveyor.surveyorName}</span>
                            <span className={styles.rating}><AiFillStar
                                className={styles.star_rating}/>{oneSurveyor.rating}</span>
                            <div style={{color: '#666'}}>{oneSurveyor.profession}</div>
                        </div>
                    </div>
                    <div>
                        <div>ID</div>
                        <div>{oneSurveyor.personalId}</div>
                    </div>
                    <div>
                        <div>{traduccion?.clients?.phone}</div>
                        <div>{oneSurveyor.phone}</div>
                    </div>
                    <div>
                        <div>{traduccion?.login?.correo}</div>
                        <div>{oneSurveyor.email}</div>
                    </div>
                </div>
            </div>
            <div className={styles.surveyor_details_all}>
                <div className={styles.surveyor_details_title}>Supporting documents</div>
                <div className={styles.personal_details_container2}>
                    {/*<div>*/}
                    {/*    <div>Passport</div>*/}
                    {/*    <Form.Group controlId="passport">*/}
                    {/*        <Form.Control className={styles.file_input} size="md" type="file" name="passport"/>*/}
                    {/*    </Form.Group>*/}
                    {/*</div>*/}
                    <div>
                        <div>Passport</div>
                        <Form.Group controlId="passportFile" style={{width:'19vw'}}>
                            <InputGroup >
                                <Form.Control
                                    type="text"
                                    className={styles.file_input_look}
                                    placeholder={typeof oneSurveyor.passportDocument === "string" && oneSurveyor.passportDocument.length > 1 && oneSurveyor.passportDocument.split('_')[1]}
                                    aria-label="Disabled input example"
                                    readOnly
                                />
                                {typeof oneSurveyor.passportDocument === "string" &&
                                    <Button
                                            onClick={() => handleDownload(oneSurveyor.passportDocument)}
                                            variant="outline-secondary" id="button-addon2"
                                            style={{width: '50px'}}
                                            className={styles.button}
                                    >
                                        <FaFileDownload/>
                                    </Button>}
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div>
                        <div>Degree certificate</div>
                        <Form.Group controlId="degreeCert" style={{width:'19vw'}}>
                            <InputGroup >
                                <Form.Control
                                    type="text"
                                    className={styles.file_input_look}
                                    placeholder={typeof oneSurveyor.degreeCertificate === "string" && oneSurveyor.degreeCertificate.length > 1 && oneSurveyor.degreeCertificate.split('_')[1]}
                                    aria-label="Disabled input example"
                                    readOnly
                                />
                                {typeof oneSurveyor.degreeCertificate === "string" &&
                                    <Button
                                        onClick={() => handleDownload(oneSurveyor.degreeCertificate)}
                                        variant="outline-secondary" id="button-addon2"
                                        style={{width: '50px'}}
                                        className={styles.button}
                                    >
                                        <FaFileDownload/>
                                    </Button>}
                            </InputGroup>
                        </Form.Group>
                    </div>
                    <div>
                        <div>Address certificate</div>
                        <Form.Group controlId="addressCert" style={{width:'19vw'}}>
                            <InputGroup >
                                <Form.Control
                                    type="text"
                                    className={styles.file_input_look}
                                    placeholder={typeof oneSurveyor.addressCertificate === "string" && oneSurveyor.addressCertificate.length > 1 && oneSurveyor.addressCertificate.split('_')[1]}
                                    aria-label="Disabled input example"
                                    readOnly
                                />
                                {typeof oneSurveyor.addressCertificate === "string" &&
                                    <Button
                                        onClick={() => handleDownload(oneSurveyor.addressCertificate)}
                                        variant="outline-secondary" id="button-addon2"
                                        style={{width: '50px'}}
                                        className={styles.button}
                                    >
                                        <FaFileDownload/>
                                    </Button>}
                            </InputGroup>
                        </Form.Group>

                    </div>
                </div>
            </div>

            {/*-------- Filtro de claims del surveyor -----------*/}

            <div className={styles.surveyor_details_all}>
                <div className={styles.surveyor_details_title} style={{marginTop:'.5rem', marginBottom:'-.5rem'}}>Last Surveys</div>
                <div className={styles.surveyor_details_all}>

                <div className={styles.personal_details_container2}>
                    <Form className={styles.form_group}>

                        <div>
                            <label htmlFor="container_select" style={{whiteSpace:'nowrap',textOverflow:'ellipsis'}}>Survey ref. number</label>
                            <Form.Control className={styles.input_style} type="text" onInput={onlyNumbers} placeholder="123456789"/>

                        </div>
                        <div>
                            <label htmlFor="container_select">From</label>
                            <Form.Control className={styles.input_style} type="date"/>

                        </div>
                        <div>
                            <label htmlFor="nave_select">To</label>
                            <Form.Control className={styles.input_style} type="date"/>

                        </div>
                        <div>
                            <label htmlFor="estado">{traduccion?.clients?.status}</label>

                            <div style={{display: 'flex'}}>
                                <div>
                                    <Form.Select className={styles.input_style}  id="estado">
                                        <option value="1">Pagado</option>
                                        <option value="2">Pendiente</option>
                                    </Form.Select>
                                </div>
                                <div className={styles.boton_filtro} >
                                    <Button variant="primary">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
                                                fill="white"/>
                                        </svg>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                    </div>
                </div>
            </div>

            {/*-------- Tabla de claims del surveyor -----------*/}
            <div className={styles.contiene_tabla}>
                <Table borderless hover className={stylest.tabla}>
                    <thead>
                        <tr>
                            <th>Survey ref. number</th>
                            <th>Date</th>
                            <th>Zone</th>
                            <th>{traduccion?.clients?.exportador}</th>
                            <th>Payment status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {/*"id": 1,*/}
                    {/*"survetRef": "123455",*/}
                    {/*"date": "11/11/2020",*/}
                    {/*"zone": "santiago",*/}
                    {/*"exporter": "fruticola",*/}
                    {/*"payment": "Pagado",*/}
                        {claims.map((datos,idx) =>
                            <tr key={idx} className={stylest.tr_data}>
                                <td>{datos?.surveyorRefNumber}</td>
                                <td>{datos?.claimDate}</td>
                                <td>{datos?.zone}</td>
                                <td>{datos?.exporterName}</td>
                                <td>{datos?.paymentStatus}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

        </>
)
}
