import styles from "./home.module.css";
import {DetailAccount} from "../components/home/details_account/DetailAccount";
import React, {useContext, useEffect, useState} from "react";
import {TablaStuff} from "../components/home/table_stuffing/TablaStuff";
import {FiltroStuffing} from "../components/home/filtro_stuffing/FiltroStuffing";
import DBContext from "../context/DBContext";
import {urlConfig} from "../config/urlConfig";

export const StuffingReport = ({stuff,setStuff,initStuffing,setInitStuffing, }) => {
    const {stuffing,stuffingPages} = useContext(DBContext);
    const [active, setActive] = useState(1);
    const [listaStuffing, setListaStuffing] = useState(stuffing);
    const [perPage, setPerPage] = useState(20);
    const [url, setUrl] = useState(`${urlConfig.allUrl}:9003/api/v1/stuffings/me?page=0&size=${perPage}`);
    const [totalPages, setTotalPages] = useState(stuffingPages?.totalPages ?? 0);
    //console.log("stuffing",stuffing)
    //console.log("listaStuffing",listaStuffing)
    //console.log("stuff",stuff)

    //pagination
    //console.log("url",url)
    //console.log("perPage",perPage)
    //console.log("totalPages",totalPages)
    //console.log("active",active)


    return (
            <>
                <div className={styles.home}>
                    <DetailAccount />
                    <FiltroStuffing active={active} setActive={setActive}
                                    listaStuffing={listaStuffing} setListaStuffing={setListaStuffing}
                                    perPage={perPage} setPerpage={setPerPage}
                                    url={url} setUrl={setUrl}
                                    totalPages={totalPages} setTotalPages={setTotalPages} />

                    <TablaStuff stuff={stuff} setStuff={setStuff}
                                listaStuffing={listaStuffing} setListaStuffing={setListaStuffing}
                                setInitStuffing={setInitStuffing} initStuffing={initStuffing}
                                //Pagination
                                perPage={perPage} setPerPage={setPerPage}
                                totalPages={totalPages} setTotalPages={setTotalPages}
                                active={active} setActive={setActive}
                                url={url} setUrl={setUrl}

                    />
                </div>
            </>
    )
}
