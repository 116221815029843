import styles from "./maritimo.module.css";
import {FaTruck} from "react-icons/fa";
import {Col, Container, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import AccountContext from "../../../context/DBContext";
import DBContext from "../../../context/DBContext";
import useWindowSize from "../../../hooks/useWindowSize";
import {Typeahead} from "react-bootstrap-typeahead";
import airports from "../../../data/airports.json";
import {TbArrowNarrowLeft, TbArrowNarrowRight, TbLineDotted} from "react-icons/tb";
import cities from 'cities.json';
import countries from "countries-list";
import LangContext from "../../../context/LangContext";
import {urlConfig} from "../../../config/urlConfig";
import axios from "axios";
import {onlyNumbers} from "../../../config/functions";

export const TransporteTerrestre = ({claimForm,setClaimForm,stuff,setStuff,imRsk,setExportersName,exportersName,editRsk, handleChangeExporter}) => {
    const {stuffing} = useContext(AccountContext);
    const {currency} = useContext(DBContext);
    const [citiesArray1, setCitiesArray1] = useState([]);
    const [citiesArray2, setCitiesArray2] = useState([]);
    const {traduccion} = useContext(LangContext);
    const [receiverList, setReceiverList] = useState([]);
    const [listForInput, setListForInput] = useState([]);

    ////console.log({stuffing})
    const {height, width} = useWindowSize();


    const getCities = (e) => {
        if (e.length > 3) {
            const city = cities.filter(city => city.name.toLowerCase().includes(e.toLowerCase()))
            for (let i = 0; i < city.length; i++) {
                if (countries.countries[city[i].country]){
                    city[i].country = countries.countries[city[i].country].name
                }
                if (i>0){
                    ////console.log([city[i].country] + " " + i + " " + city[i-1].country)
                    if(city[i].country === city[i-1].country && city[i].name === city[i-1].name ){
                        city.splice(i,1)
                        ////console.log([city[i].country])
                    }
                }
            }
            setCitiesArray1(city)
        }
    }

    const getCities2 = (e) => {
        if (e.length > 3) {
            const city = cities.filter(city => city.name.toLowerCase().includes(e.toLowerCase()))
            for (let i = 0; i < city.length; i++) {
                if (countries.countries[city[i].country]){
                    city[i].country = countries.countries[city[i].country].name
                }
                if (i>0){
                    //console.log([city[i].country] + " " + i + " " + city[i-1].country)
                    if(city[i].country === city[i-1].country && city[i].name === city[i-1].name ){
                        city.splice(i,1)
                        ////console.log([city[i].country])
                    }
                }
            }
            setCitiesArray2(city)
        }
    }
    const handleChange = (e) => {
        const opciones = e.target.options;
        const valorSeleccionado = e.target.value;
        let textoSeleccionado;
        let value;
        switch (e.target.name) {
            case "currencyId":
                value = parseInt(e.target.value);
                break;
            case "currency":
                value = parseInt(e.target.value);
                break;
            default:
                value = e.target.value;
        }

        if (e.target.name === "ata") {
            setClaimForm({...claimForm,
                ata:e.target.value,
                dischargeDate: e.target.value,
            })
        }else {
            setClaimForm({
                ...claimForm,
                [e.target.name]: e.target.value
            })
        }
        if (e.target.name === "companyName") {
            //console.log("CAMBIO COMPANY NAME TERRESTRE")

            for (let i = 0; i < opciones.length; i++) {
                if (opciones[i].value === valorSeleccionado) {
                    textoSeleccionado = opciones[i].text;
                    //console.log(textoSeleccionado);
                }
            }
            //console.log(opciones)
            //console.log(textoSeleccionado)
            //console.log(value)
            setClaimForm({
                ...claimForm,
                [e.target.name]: textoSeleccionado,
                exporterName: textoSeleccionado,
                companyId: value
            })
        }
        //change claim on sessionStorage
        //sessionStorage.setItem("claim", JSON.stringify(claimForm))
        if (e.target.name === "reclaimedAmount"){

            let addRecoveredCargo = claimForm?.cargo;
            addRecoveredCargo = {
                ...addRecoveredCargo,
                reclaimedAmount:e.target.value
            };
            setClaimForm({
                ...claimForm,
                cargo:addRecoveredCargo
            })
        }
        if(e.target.name === "currency") {
            let addRecoveredCargo = claimForm?.cargo;
            addRecoveredCargo = {
                ...addRecoveredCargo,
                currency: e.target.value
            };
            setClaimForm({
                ...claimForm,
                cargo: addRecoveredCargo
            })
        }
    }

    const handleChangePod = (e) => {
        let string = `${e[0].name}-${e[0].country}`
        setClaimForm({
            ...claimForm,
            pod: string
        })
    }
    const handleChangePol = (e) => {
        let string = `${e[0].name}-${e[0].country}`
        setClaimForm({
            ...claimForm,
            pol: string
        })
    }
    const getReceiverList = () => {
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/receiver`, {
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            }
        }).then((res) => {
            //console.log(res.data)
            setReceiverList(res.data)
        }).catch((error) => {
            //console.log(error)
        });
    }


    const getReceiver = (e) => {
        setClaimForm({
            ...claimForm,
            consigneeName: e
        })
        if (e.length > 2) {
            const city = receiverList.filter(receiver => receiver.description.toLowerCase().includes(e.toLowerCase()))
            setListForInput(city)
        }else{
            setListForInput([])
        }
    }
    const setReceiverName = (e) => {
        if (e[0]?.description !== undefined) {
            setClaimForm({
                ...claimForm,
                consigneeName: `${e[0].description}`
            })
        }
        //  sessionStorage.setItem("claim", JSON.stringify(claimForm))
    }
    useEffect(() => {
        getReceiverList()
    }, [])
    return (
        <>
            <div>
                {/* <p className={styles.transporte_titulo}> */}
                <Row style={{width:'100%', marginBottom:'1.5rem', justifyContent:"center"}}>
                    <Col sm={3} xs={12} className={styles.polPod} style={{position:'relative'}} >
                        <div style={{width:'35px', height:'30px'}}>
                            <FaTruck style={{alignSelf:'auto', fontSize:'2rem',color:'#5a68e9'}}/>
                        </div>
                        {/* <span className={styles.texto_inOut}>{traduccion.transport?.salida}</span>
                        <img src={loadingImg} alt="loading" className={styles.loading} style={{display:loadingPol ? "block" : "none", position:'absolute', top:-20, bottom:0,  left:30, width:50, height:100}}/> */}
                        <span className={styles.texto_inOut} style={{minWidth:'15ch', fontSize:'15px', alignSelf:'center'}}>{traduccion.transport?.salida3}</span>

                        {stuff ?
                            <span className={styles.inicio_destino}>{stuff.data && stuff.data.puerto_carga}</span>:

                            <Typeahead
                                className={styles.typeahead2}
                                style={{marginLeft:'1rem', width:'20ch'}}
                                id="basic-typeahead"
                                defaultInputValue={claimForm?.pol}
                                labelKey={option => `${option.name} - ${option.country}`}
                                onChange={e=>handleChangePol(e)}
                                onInputChange={e=> getCities(e)}
                                options={citiesArray1}
                            />
                        }
                    </Col>
                    {width > 1449 &&
                        <Col sm={2} style={{textAlign: "end"}}>
                            <TbArrowNarrowLeft
                                style={{width: '2.2rem', transform: 'scaleX(3) scaleY(3)', color: '#b9bffe'}}/>
                            <TbLineDotted style={{
                                width: '4.2rem',
                                transform: 'scaleX(5) scaleY(5)',
                                color: '#b9bffe',
                                margin: '0 .3rem'
                            }}/>
                            <TbArrowNarrowRight
                                style={{width: '2.2rem', transform: 'scaleX(3) scaleY(3)', color: '#b9bffe'}}/>
                        </Col>
                    }
                    {/* <span style={{marginLeft:"1rem"}}> */}
                    <Col  sm={3} xs={12} className={styles.polPod} style={{position:'relative'}} >
                        <div style={{width:'35px', height:'30px'}}>
                            <FaTruck style={{margin:'0', fontSize:'2rem',color:'#5a68e9'}}/>
                        </div>
                        {/* <span className={styles.texto_inOut}>{traduccion.transport?.llegada}</span> */}
                        {/* <img src={loadingImg} alt="loading" className={styles.loading} style={{display:loadingPod ? "block" : "none", position:'absolute', top:-20, bottom:0,  left:30, width:50, height:100}}/> */}
                        <span className={styles.texto_inOut} style={{minWidth:'15ch', fontSize:'15px', alignSelf:'center'}}>{traduccion.transport?.llegada3}</span>

                        {stuff ?
                            <span className={styles.inicio_destino}>{stuffing.data&& stuffing.data.puerto_destino}</span> :

                            <Typeahead
                                className={styles.typeahead2}
                                style={{marginLeft:'1rem', width:'27ch'}}
                                id="basic-typeahead"
                                defaultInputValue={claimForm?.pod}
                                labelKey={option => `${option.name} - ${option.country}`}
                                onChange={handleChangePod}
                                onInputChange={e=> getCities2(e)}
                                options={citiesArray2}
                            />
                        }
                    </Col>
                    {/* </span> */}
                </Row>
                {/* </p> */}
                <Container className={styles.container}>

                    <Row className={styles.row}>
                        <Col>
                            <div className={styles.selects}>
                                <p className="pe-2">CRT</p>

                                <Form.Group className="me-3" controlId="awb_A">
                                    <Form.Control type="text" placeholder="CRT" name="blAwb" onChange={handleChange} value={claimForm?.blAwb} />
                                </Form.Group>
                            </div>

                            <div className={styles.selects}>

                                <p className="pe-2">{traduccion?.claim?.exporter}
                                    {/*<span style={{fontSize:"9px",margin:0}}>exporter_name</span>*/}
                                </p>
                            {(imRsk&& exportersName?.affiliatedCompanies?.length==0)?
                                <p>{claimForm?.companyName}</p>
                                :
                                imRsk?
                                    <Form.Select aria-label="Default select example" name="companyName"
                                                 onClick={handleChangeExporter}
                                                 onChange={handleChange}
                                                 value={claimForm?.companyId}>
                                        <option value={exportersName.id}>{exportersName.companyName}</option>
                                        {exportersName?.affiliatedCompanies?.map((item, index) => {
                                            //console.log(item)
                                            //console.log(exportersName)
                                            return <option key={index} value={item.id}>{item.companyName}</option>

                                        })}
                                    </Form.Select>
                                    :
                                    <div className={styles.selects}>
                                        <Form.Select aria-label="Default select example" name="companyName"
                                                     onClick={handleChangeExporter}
                                                     onChange={handleChange}
                                                     value={claimForm?.companyId}>
                                            <option value={exportersName.id}>{exportersName.companyName}</option>
                                            {exportersName?.affiliatedCompanies?.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.companyName}</option>

                                            })}
                                        </Form.Select>
                                    </div>
                                // <Form.Group className="me-3" controlId="companyName">
                                //     <Form.Control type="text" placeholder="Nombre Exportador" name="companyName"
                                //                   onChange={handleChange}
                                //                   value={claimForm?.companyName}
                                //     />
                                // </Form.Group>
                            }
                            </div>
                            <div className={styles.selects}>
                                <p className="pe-2">{traduccion?.maritime?.recibidor}</p>
                                <Form.Group className="me-0" controlId="Recibidor">
                                    <Typeahead
                                        style={{position:"relative"}}
                                        className={styles.typeahead}
                                        id="basic-typeahead"
                                        defaultInputValue={claimForm?.consigneeName}
                                        labelKey={option => `${option.description}`}
                                        onChange={setReceiverName}
                                        onInputChange={e => getReceiver(e)}
                                        options={listForInput}
                                    />
                                </Form.Group>
                            </div>
                        </Col>
                        <Col>

                            <div className={styles.selects}>
                                <p className="pe-2">Carrier</p>
                                <Form.Group className="me-3" controlId="Carrier">
                                    <Form.Control type="text" placeholder="Carrier" name="carrierName"
                                                  onChange={handleChange}
                                                  value={claimForm?.carrierName}/*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                </Form.Group>
                            </div>
                            <div className={styles.selects}>
                                <p className="pe-2">{traduccion?.mantendores?.fechaCRT}</p>
                                <Form.Group className="me-3" controlId="FechaAWB_A">
                                    <Form.Control type="date"  name="blAwbDate" onChange={handleChange} value={claimForm.blAwbDate?.substring(0,10)} /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                </Form.Group>
                            </div>
                            <div className={styles.selects}>
                                <p className="pe-2">ATA</p>
                                <Form.Group className="me-3" controlId="ATA_A">
                                    <Form.Control type="date" disabled={!imRsk} className={imRsk? styles.selects_input:styles.selects_input_disabled} name="ata"  onChange={handleChange}  value={claimForm.ata?.substring(0,10)}  /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                </Form.Group>
                            </div>
                            <div className={styles.selects}>
                                <p className="pe-2">Timebar</p>
                                <Form.Group className="me-3" controlId="Timebar_A">
                                    <Form.Control type="date" disabled={!imRsk} className={imRsk? styles.selects_input:styles.selects_input_disabled} name="timeBar" onChange={handleChange} value={claimForm.timeBar?.substring(0,10)}   /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                </Form.Group>
                            </div>

                        </Col>
                        <Col>
                            <div className={styles.monto_recuperado}>
                                <div>{traduccion.carga?.montoReclamado}</div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.moneda}</div>

                                    <Form.Select  disabled={!imRsk} className={styles.moneda} name="currency" onChange={handleChange} value={claimForm?.cargo?.currency} >
                                        <option value="">---</option>
                                        {
                                            currency.map((currency) => (
                                                <option key={currency.id}
                                                        value={currency.id}>{currency?.description}</option>))
                                        }
                                    </Form.Select>
                                </div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.monto}</div>
                                    <Form.Group className={styles.moneda} style={{borderRadius:6}} controlId="Monto">
                                        <Form.Control  disabled={!imRsk} type="text" placeholder={traduccion?.maritime?.monto} step=".01" name="reclaimedAmount"
                                                       onChange={handleChange}
                                                       onInput={onlyNumbers}
                                                       value={claimForm?.cargo?.reclaimedAmount}  /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className={styles.monto_recuperado}>
                                <div>{traduccion.maritime?.montoRecuperado}</div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.moneda}</div>

                                    <Form.Select disabled={!imRsk} className={styles.moneda} name="currencyId" onChange={handleChange} value={claimForm?.currencyId} >
                                        <option value="">---</option>
                                        {
                                            currency.map((currency) => (
                                                <option key={currency.id}
                                                        value={currency.id}>{currency?.description}</option>))
                                        }
                                    </Form.Select>
                                </div>
                                <div className={styles.selects_m}>
                                    <div className="pe-2">{traduccion.maritime?.monto}</div>
                                    <Form.Group className={styles.moneda} style={{borderRadius:6}} controlId="Monto">
                                        <Form.Control disabled={!imRsk} type="text" placeholder={traduccion?.maritime?.monto} name="recoveredAmount"
                                                      onChange={handleChange}
                                                      onInput={onlyNumbers}
                                                      value={claimForm?.recoveredAmount}  /*value={stuffing.data&& stuffing.data.orden_embarque}*/ />
                                    </Form.Group>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>    )
}
