import styles from "./mantenedor_surveyor.module.css";
import {Button, Pagination} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {TablaSurveyor} from "./tabla_surveyor/TablaSurveyor";
import {NewSurveyor} from "./NewSurveyor";
import {Link} from "react-router-dom";
import {SurveyorDetail} from "./SurveyorDetail";
import NewSurveyorCompany from "./NewSurveyorCompany";
import LangContext from "../../../context/LangContext";
import {useParamsUrl} from "../useParamsUrl";
import {urlConfig} from "../../../config/urlConfig";
import DBContext from "../../../context/DBContext";
import axios from "axios";
import {toast} from "react-toastify";
import {FiltroSurveyor} from "./filter_surveyor/FiltroSurveyor";

export const MantenedorSurveyor = ({newSurveyor,setNewSurveyor,setRouteActive,listaStatus}) => {
    let {inspector, cambioInspector, setCambioInspector} = React.useContext(DBContext);
    const [oneSurveyor, setOneSurveyor] = useState({});
    const [accountSurveyor, setAccountSurveyor] = useState({});
    const [surveyorList, setSurveyorList] = useState([]);
    const [surveyorId,setSurveyorId] = useState(null);
    const [editSurveyor, setEditSurveyor] = useState(false);
    const {traduccion} = useContext(LangContext);

    //Para el filtro
    const [perPage, setPerPage] = useState(10);
    const [active, setActive] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const {handleSearch, searchUrl} = useParamsUrl(urlConfig.searchSurveyorUrl);
    const [url, setUrl] = useState(`${urlConfig.searchSurveyorUrl}?page=0&size=10`);
    const [listaSurveyors, setListaSurveyors] = useState(inspector);

    const goToStart=()=>{
        setRouteActive("/")
    }
    const handleNewSurveyor = () => {
        setEditSurveyor(false);
        setNewSurveyor(1)
    }

    const token = localStorage.getItem("token").replace("Bearer ", "")


    const getSurveyors = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token.replace("Bearer ", ""),
            }
        }).then((res) => {
            setListaSurveyors(res.data.surveyors)
            setTotalPages(res.data.totalPages-1)
        }).catch((error) => {
            toast.error(`${traduccion?.errores?.erroralFiltrarIntente}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }

    const getSurveyorsSort = (url) => {
        axios.get(url, {
            headers: {
                Authorization: token.replace("Bearer ", ""),
            }
        }).then((res) => {
            setListaSurveyors(res.data.surveyors)
            setTotalPages(res.data.totalPages-1)
        }).catch((error) => {
            toast.error(`${traduccion?.errores?.errorSorting}`, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }

    const searchSurveyor = (e) => {
        e.preventDefault()
        setActive(0);
        let newUrl = `${searchUrl}&size=${perPage}&page=${0}`;
        setUrl(newUrl);
    }



    const goToPage = (page) => {
        //console.log("GO TO PAGE", page)
        let newUrl =(url.replace(/page=\d+/, `page=${page}`))
        //console.log("URL", newUrl)
        setUrl(newUrl)
        setActive(page)
    }

    useEffect(() => {
        let newUrl =(url.replace(/size=\d+/, `size=${perPage}`))
        newUrl.replace(/page=\d+/, `page=0`)
        setUrl(newUrl)
        setActive(0)
    },[perPage])

    useEffect(() => {
        //console.log("GET...", url)
        getSurveyors(url)
    },[url])

    // useEffect(() => {
    //     setCambioExporter(!cambioExporter)
    // },[])
    // let numPage = Math.floor(listaClaim.totalItems / perPage);
    let items=[]
    let oneElipsisA = false
    let oneElipsisD = false
    for (let number = 0; number <= totalPages; number++) {
        //add elipsis
        if (number > 2 && number < totalPages - 2) {
            if (number === active + 1 || number === active - 1) {
                items.push(
                    <Pagination.Item className={styles.pagina} key={number} active={number === active} onClick={() => goToPage(number)}>
                        {number+1}
                    </Pagination.Item>,
                );
            }else if(number ===active){
                items.push(
                    <Pagination.Item className={styles.pagina} onClick={()=>goToPage(number)} key={number} active={number === active}>
                        {number+1}
                    </Pagination.Item>,
                );
            }else{
                if (number < active && !oneElipsisA) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisA = true
                }
                if (number > active && !oneElipsisD) {
                    items.push(
                        <Pagination.Ellipsis key={number} disabled/>
                    );
                    oneElipsisD = true
                }
            }
        }else{
            items.push(
                <Pagination.Item className={styles.pagina} onClick={()=>goToPage(number)} key={number} active={number === active}>
                    {number+1}
                </Pagination.Item>,
            );
        }
    }


    //console.log(newSurveyor,listaStatus)
    return (
        <>
            <div className={styles.all_surveyor}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                        <Link className={styles.like_a} to={"/"}>
                            <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                        </Link>>
                    {newSurveyor === 0 &&
                         <span className={styles.bold}>{traduccion?.tabs?.inspector}</span>
                    }
                    {(newSurveyor === 1 || newSurveyor === 3) &&
                        <><span onClick={()=>setNewSurveyor(0)} className={styles.like_a}>{traduccion?.tabs?.inspector}</span>>
                            <span className={styles.bold}>{traduccion?.companySurveyor?.surv}</span></>
                    }
                    {newSurveyor === 2 &&
                        <><span onClick={()=>setNewSurveyor(0)} className={styles.like_a}>{traduccion?.tabs?.inspector}</span>>
                            <span className={styles.bold}>{traduccion?.companySurveyor?.survDetails}</span></>
                    }
                </div>
                {newSurveyor === 0
                    &&
                    <>
                        <div className={styles.title_surveyor}>
                            <p>{traduccion?.sidebar?.surveyors}</p>
                            <Button className={styles.title_button} variant="primary" onClick={handleNewSurveyor}>{traduccion?.companySurveyor?.surv}</Button>
                        </div>
                        <FiltroSurveyor searchSurveyor={searchSurveyor} handleSearch={handleSearch}/>

                        <TablaSurveyor setSurveyorId={setSurveyorId} listaStatus={listaStatus} surveyorList={listaSurveyors} oneSurveyor={oneSurveyor} getSurveyorsSort={getSurveyorsSort}
                                       setNewSurveyor={setNewSurveyor} editSurveyor={editSurveyor} setEditSurveyor={setEditSurveyor} setOneSurveyor={setOneSurveyor}
                        />
                    </>
                }

                {newSurveyor === 1 && <NewSurveyor setNewSurveyor={setNewSurveyor} title={"New surveyor"} editSurveyor={editSurveyor}  oneSurveyor={oneSurveyor}/>}

                {newSurveyor === 2 && <SurveyorDetail surveyorId={surveyorId} accountSurveyor={accountSurveyor} oneSurveyor={oneSurveyor} />}
                {newSurveyor === 3 && <NewSurveyorCompany setNewSurveyor={setNewSurveyor} title={"New surveyor"} editSurveyor={editSurveyor}  oneSurveyor={oneSurveyor}/>}
                {newSurveyor === 0 &&
                <div className={styles.pagination}>
                    <Pagination>{items}</Pagination>
                </div>
                }
            </div>
        </>
    )
}
