
import forbidden from "./forbidden.jpg";
export const Forbidden = () => {
    return (
        <>
            <div style={{display:"flex",alignItems:'center',justifyContent:'center', width:'100%', flexDirection:"column"}}>
            <h1 style={{marginTop:100}}>403 Forbidden</h1>
                <img src={forbidden} alt="forbidden" style={{height:'80vh', width:'auto'}}/>
            </div>
        </>
    )
}