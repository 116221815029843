import {Form} from "react-bootstrap";
import styles from "./lineatiempo.module.css"

import {useContext, useEffect, useState} from "react";
import LangContext from "../../../../context/LangContext";
import {TbPackage, TbPackgeImport, TbTree, TbTruck} from "react-icons/tb";
// import {BsAirplane,BsTruckFront} from "react-icons/bs";
import {IoAirplaneOutline} from "react-icons/io5";
import {HiOutlineTruck} from "react-icons/hi";
import {MdOutlineDirectionsBoat} from "react-icons/md";
import {RiShoppingBasket2Line} from "react-icons/ri";

export const LineaTiempo = ({setClaimForm,claimForm,fechaHar,fechaPack,setFechaHar,setFechaPack,imRsk, surveyDate}) => {
    const {traduccion} = useContext(LangContext);
    //console.log(surveyDate)
    const handleChange = (e) => {
        const {name, value} = e.target;
        //console.log({name, value})
        setClaimForm({...claimForm, [name]: value})
    }
    //Poner fecha de cosecha al principio
    useEffect(() => {
        let date ="Unknown";
        let lowDate = new Date(1776, 6, 4).toISOString().split('T')[0];
        if(claimForm) {
            if (claimForm.cargo?.fruits[0]?.fruits?.harvesting.length > 0) {
                setFechaHar(claimForm.cargo?.fruits[0].fruits.harvesting[0].harvestDate.split("T")[0])
            }
            //console.log("claimForm.cargo?.fruits", claimForm.cargo?.fruits)

        }
        // setFechaHar(date);

    },[claimForm])

    //Poner fecha de embalaje al principio
    useEffect(() => {
        let date ="Unknown";
        if(claimForm) {
            if (claimForm.cargo?.fruits[0]?.fruits?.packaging.length > 0) {
                setFechaPack(claimForm.cargo?.fruits[0].fruits.packaging[0].packageDate.split("T")[0])

                // if (claimForm.cargo?.fruits[0].fruits.packaging?.length > 0) {
                //     date = claimForm.cargo.fruits[0].fruits.packaging[0].packageDate.split("T")[0];
                //     //console.log(claimForm.cargo.fruits[0].fruits.packaging[0].packageDate.split("T")[0])
                // }
            }
        }
        // setFechaPack(date);

    },[claimForm])




    // useEffect(() => {
    //     if (claimForm?.blAwbDate !== undefined || claimForm?.blAwbDate !== "") {
    //         //console.log(claimForm?.blAwbDate)
    //         // setBlDate(claimForm.blAwbDate.split("T")[0])
    //     }
    //     if (claimForm?.timeline?.length > 0) {
    //         setSurveyDate(claimForm?.timeline[0].time.split("T")[0])
    //     }
    //     //console.log("====================================" +claimForm)
    // }, [claimForm]);

    //sum between dates
    const dateDiffInDays = (a, b) => {
        // Text to date
        let date1 = new Date(a);
        let date2 = new Date(b);
        // To calculate the time difference of two dates
        let differenceInTime = date1.getTime() - date2.getTime();
        // To calculate the no. of days between two dates
        return differenceInTime / (1000 * 3600 * 24);
    }




    const iconStyle =()=>{
        if(claimForm?.transportId === 1){
            return <MdOutlineDirectionsBoat style={{minWidth:'30px', fontSize:'2rem',color:'#5a68e9', alignContent:'center'}}/>
        }else if (claimForm?.transportId === 2) {
            return <IoAirplaneOutline style={{minWidth: '30px', fontSize: '2rem', color: '#5a68e9', alignContent: 'center'}}/>
        }else{
            return <HiOutlineTruck style={{minWidth: '30px', fontSize: '2rem', color: '#5a68e9', alignContent: 'center'}}/>
        }
    }
    //console.log(surveyDate)
    return (
        <>
            <div className={styles.containerIcons}>
                <div className={styles.containerIcon}>
                    <TbTree style={{minWidth:'30px', fontSize:'2rem',color:'#5a68e9', alignContent:'center'}}/>
                    <p className={styles.title}>{traduccion?.timeline?.fechaCosecha}</p>
                    <p className={styles.text}>{fechaHar}</p>
                </div>
                <div className={styles.containerIcon}>
                    <TbPackage style={{minWidth:'30px', fontSize:'2rem',color:'#5a68e9', alignContent:'center'}}/>
                    <p className={styles.title}>{traduccion?.timeline?.fechaPacking}</p>
                    <p className={styles.text}>{fechaPack}</p>
                </div>
                <div className={styles.containerIcon}>
                    <RiShoppingBasket2Line style={{minWidth:'30px', fontSize:'2rem',color:'#5a68e9', alignContent:'center'}}/>
                    <p className={styles.title}>{traduccion?.timeline?.fechaStuffing}</p>
                    <Form.Control onChange={handleChange} disabled={!imRsk} name="stuffingDate" value={claimForm?.stuffingDate?.split("T")[0]} style={{width:'17ch',marginTop:0}} className={imRsk? styles.selects_input:styles.selects_input_disabled} type={"date"}></Form.Control>
                </div>
                <div className={styles.containerIcon}>
                    {iconStyle()}
                    <p className={styles.title}>{traduccion?.timeline?.fechaLoading}</p>
                    <p className={styles.text}>{claimForm?.blAwbDate ? claimForm.blAwbDate.split("T")[0] : "Unknown"}</p>
                </div>
                <div className={styles.containerIcon}>
                    {iconStyle()}
                    <p className={styles.title} >{traduccion?.timeline?.fechaDischarge}</p>
                    <Form.Control onChange={handleChange} disabled={!imRsk}  name="ata" value={claimForm?.ata?.split("T")[0]} max={(claimForm?.gateOutDate !== null && claimForm?.gateOutDate !== "") ? claimForm?.gateOutDate?.split("T")[0] : surveyDate} style={{width:'17ch',marginTop:0}} className={imRsk? styles.selects_input:styles.selects_input_disabled} type={"date"}></Form.Control>
                </div>
                {/*<div> ->{(claimForm?.gateOutDate !== null && claimForm?.gateOutDate !== "") ? claimForm?.gateOutDate?.split("T")[0] : surveyDate }|- </div>*/}
                {claimForm?.transportId !==3 &&
                    <div className={styles.containerIcon}>
                        <TbTruck style={{minWidth:'30px', fontSize:'2rem',color:'#5a68e9', alignContent:'center'}}/>
                        <p className={styles.title}>{traduccion?.timeline?.fechaGateOut}</p>
                        <Form.Control onChange={handleChange} disabled={!imRsk} name="gateOutDate" value={claimForm?.gateOutDate?.split("T")[0]} min={claimForm?.ata?.split("T")[0]} max={surveyDate} style={{width:'17ch',marginTop:0}} className={imRsk? styles.selects_input:styles.selects_input_disabled} type={"date"}></Form.Control>
                    </div>
                }
                <div className={styles.containerIcon}>
                    <TbPackgeImport style={{minWidth:'30px', fontSize:'2rem',color:'#5a68e9', alignContent:'center'}}/>
                    <p className={styles.title}>{traduccion?.timeline?.fechaSurvey}</p>
                    {claimForm.hasMap
                        ?<p className={styles.text}>{surveyDate}</p>
                        :<Form.Control onChange={handleChange} disabled={!imRsk} name="surveyDate" value={claimForm?.surveyDate?.split("T")[0]} style={{width:'17ch',marginTop:0}} className={imRsk? styles.selects_input:styles.selects_input_disabled} type={"date"}></Form.Control>
                    }
                </div>
            </div>

            <div style={{display:'flex', justifyContent:'end'}}>
                <div className={styles.containerInfo}>
                    <div className={styles.containerRow}>
                        <div className={styles.itemRow}>
                            <div className={styles.title}>ETD</div>
                            <div>{claimForm?.cargo?.etd !== ""? claimForm?.cargo?.etd?.split("T")[0] : ` ${traduccion?.exporter?.unknown}`}</div>
                        </div>
                        <div className={styles.itemRow}>
                            <div className={styles.title}>Offer days</div>
                            {claimForm?.cargo?.eta !== "" && claimForm?.cargo?.etd !== "" && claimForm?.cargo?.eta !== null && claimForm?.cargo?.etd !== null
                                ?        <div>{(dateDiffInDays(claimForm?.cargo?.eta,claimForm?.cargo?.etd) > 0)?
                                    dateDiffInDays(claimForm?.cargo?.eta,claimForm?.cargo?.etd) + ` ${traduccion?.exporter?.days}`
                                    : "Invalid date"}
                                </div>
                                :<div>Unknown</div>
                            }
                        </div>
                        <div className={styles.itemRow}>
                            <div className={styles.title}>ETA</div>
                            <div>{claimForm?.cargo?.eta !== ""? claimForm?.cargo?.eta?.split("T")[0] : ` ${traduccion?.exporter?.unknown}`}</div>
                        </div>
                        <div className={styles.itemRow}>
                            <div className={styles.title}>Delay days</div>
                            {claimForm?.cargo?.eta !== "" && claimForm?.ata !== "" &&
                            claimForm?.cargo?.eta !== null && claimForm?.ata !== null
                                ?<div>{dateDiffInDays(claimForm?.ata,claimForm?.cargo?.eta) > 0
                                    ? dateDiffInDays(claimForm?.ata,claimForm?.cargo?.eta) + ` ${traduccion?.exporter?.days}`
                                    : "Invalid date"}
                                </div>
                                :<div>Unknown</div>
                            }
                        </div>
                        <div className={styles.itemRow}>
                            <div className={styles.title}>ATA</div>
                            <div>{claimForm?.ata !== ""? claimForm?.ata?.split("T")[0] : `${traduccion?.exporter?.unknown}`}</div>
                        </div>
                    </div>
                    <hr></hr>
                    <div>
                        <div className={styles.containerRow}>
                            <div className={styles.itemRow}>{traduccion?.exporter?.totalOverseasTime}</div>
                            <div className={styles.itemRow}>{!(claimForm?.ata === "" || claimForm?.ata === null) && !(claimForm?.blAwbDate === "" || claimForm?.blAwbDate === null)
                                ?`${dateDiffInDays(claimForm?.ata,claimForm?.blAwbDate)} ${traduccion?.exporter?.days}`
                                : `${traduccion?.exporter?.unknown}`}</div>
                        </div>
                        <div className={styles.containerRow}>
                            <div className={styles.itemRow}>{traduccion?.exporter?.totalTransportTime}</div>
                            <div className={styles.itemRow}>{!(claimForm?.gateOutDate === "" || claimForm?.gateOutDate === null) && !(claimForm?.blAwbDate === ""|| claimForm?.blAwbDate === null)
                                ?`${dateDiffInDays(claimForm?.gateOutDate,claimForm?.blAwbDate)}  ${traduccion?.exporter?.days}`
                                : `${traduccion?.exporter?.unknown}`}</div>
                        </div>
                        <div className={styles.containerRow}>
                            <div className={styles.itemRow}>{traduccion?.exporter?.packingTime}</div>
                            <div className={styles.itemRow}>{!(fechaPack === "" || fechaPack == null) && !(surveyDate === "" || surveyDate === null)
                                ? `${dateDiffInDays(surveyDate,fechaPack)} ${traduccion?.exporter?.days}`
                                : `${traduccion?.exporter?.unknown}`}</div>

                        </div>
                        <div className={styles.containerRow}>
                            <div className={styles.itemRow}>{traduccion?.exporter?.ageOfTheFruit}</div>
                            <div className={styles.itemRow}>{!(surveyDate === "" || surveyDate === null) && !(fechaHar === "" || fechaHar === null)
                                ? `${dateDiffInDays(surveyDate,fechaHar)} ${traduccion?.exporter?.days}`
                                : `${traduccion?.exporter?.unknown}`}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
