import React from 'react';
import {Document, Page} from 'react-pdf';
import terms from '../../src/pdf/TC.pdf';
import {Container} from "react-bootstrap";

const Terms = () => {
    return (
        <div>
            <Container style={{overflowY: 'scroll', height: 'fit-content', width: 'fit-content'}}>
                <Document file={terms} style={{width: '100%'}}>
                    {[...Array(9)].map((x, i) =>
                        <Page key={i} pageNumber={i + 1}/>
                    )}
                </Document>
            </Container>
        </div>
    );
};

export default Terms;
