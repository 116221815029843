import {DetailAccount} from "../components/home/details_account/DetailAccount";
import Tabla from "../components/home/table_home/Tabla";
import React, {useState} from "react";
import styles from "./home.module.css"

export const Home = ({setOneClaim}) => {
    return (

        <div className={styles.home}>
            <DetailAccount/>
            <Tabla setOneClaim={setOneClaim}/>
        </div>

    )
}
