import {toast, ToastContainer} from "react-toastify";
import stylesSurv from "../surveyors/new_surveyor.module.css";
import {Button, Form} from "react-bootstrap";
import styles from "../company_surveyors/new_company.module.css";
import {useContext, useEffect, useState} from "react";
import damageImg from "./damage.png"
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import LangContext from "../../../context/LangContext";

export const NewDamages = ({oneDamage, cambioDamage, setCambioDamage}) => {
    const {traduccion} = useContext(LangContext);
    const [damageImage, setDamageImage] = useState(damageImg);
    // const [isImage, setIsImage] = useState(false);

    const [imageFile, setImageFile] = useState(null);


    const fakeSeverity = [{id:1,description:'Low'}, {id:2,description:'Medium'}, {id:3,description:'High'}]
    const fakeType = [{id:1,description:'Quality'}, {id:2,description:'Condition'},{id:3,description:'General'}]

    const [damageObj, setDamageObj] = useState({
        description: "",
        status: null,
        damage: null,
        image: null,
        severity: null,

    });
    const listaStatus = [{booleano:false,description:"Inactivo"}, {booleano:true,description:"Activo"}];



    useEffect(() => {
        if (oneDamage.id !== undefined) {
            setDamageObj({
                ...damageObj,
                id: oneDamage.id,
                description: oneDamage.description,
                status: oneDamage.status,
                damage: oneDamage.damage.id,
                image: oneDamage.image,
                severity: oneDamage.severity?.id || null,
            })
            if (oneDamage.image !== null) {
                setDamageImage(oneDamage.image);
            }
        }
    },[oneDamage]);

    const handleDamage = (e) => {
        //console.log(damageObj)
        if (e.target.name === "status") {
            setDamageObj({
                ...damageObj,
                [e.target.name]: e.target.value === "true",
            })
        }else{
            setDamageObj({
                ...damageObj,
                [e.target.name]: e.target.value,
            })

        }
    }

    const handlePreviewImg = (e) => {
        if (e.target?.files.length > 0) {
            const file = e.target.files[0];
            const fileUrl = URL.createObjectURL(file);
            setDamageImage(fileUrl);
            setImageFile(file);
            // setIsImage(true);
            setDamageObj({...damageObj, image: file})
        }
    }

    const uploadImage = () => {
        //console.log("uploadImage")
        const file = imageFile;

        if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/jpg") {
            throw new Error('Formato de imagen inválido.');
        }
        if (file.size > 20000000) {
            throw new Error('Imagen excede el tamaño permitido (20mb).');
        }

        const image = new File([file], `DAMAGE_${file.name}`, {type: file.type});
        let formData = new FormData();
        formData.append('file', image);
        //console.log("formData", formData)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                //'Authorization': process..env.REACT_APP_TOKEN_RSK
                'Authorization': "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIzY2E3YWI2ZC0wOGZiLTQ3MDMtYTI0Yi1hZjNiNzYwYTFiOWMiLCJzdWIiOiJ2Y2VsaXNAcnNrY2hpbGUuY29tIiwicm9sIjoiUk9MRV9SU0siLCJpYXQiOjE2Njg0MzAxNTAsImV4cCI6MjA2ODQ2NjE1MH0.r7MyaqiC-2wpKv6Ai6gmJnDFaFL8_lPEZvl44n2aed0"
            }
        }
        return axios.post(`${urlConfig.allUrl}:9004/api/v1/files/img`, formData, config);
    }

    const putDamage = (obj, header) => {
        if(obj.severity === ""){
            obj.severity = null;
        }
        axios.put(`${urlConfig.damageUrl}${oneDamage.id}`, obj, header)
            .then((response) => {
                //console.log(response.data)
                toast.success(`${traduccion?.success?.successActDamage}`);
                if (obj.image !== null) {
                    setDamageImage(obj.image);
                }
                setCambioDamage(!cambioDamage);
            }).catch((error) => {
                //console.log(error);
                toast.error(`${traduccion?.errores?.damageEdit}`);
        })
    }
    const postDamage = (obj, header) => {
        if(obj.severity === ""){
            obj.severity = null;
        }
        axios.post(`${urlConfig.damageUrl}`, obj, header)
            .then((response) => {
                //console.log(response.data)

                toast.success(`${traduccion?.success?.successNewDamage}`);
                if (obj.image !== null) {
                    setDamageImage(obj.image);
                }                // setIsImage(false);
                setCambioDamage(!cambioDamage);
            }).catch((error) => {
            toast.error(`${traduccion?.errores?.damageNew}`);
            //console.log(error);
        })
    }
    const handlePostDamage = (e) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        //Al menos una descripción
        //console.log(damageObj)
        let finalObj = damageObj;
        if (damageObj.description !== "") {


            if (oneDamage.id !== undefined) {
                //console.log("Daño existente")
                //Daño existente
                if (imageFile) {
                    uploadImage().then((response) => {
                        finalObj.image = response.data.data[0];
                        putDamage(finalObj, header);
                    }).catch((error) => {
                        //console.log(error);
                    })
                } else {
                    putDamage(finalObj, header);
                }
            }
            if (oneDamage?.id == undefined) {
                //console.log("Daño nuevo")
                //Daño nuevo

                if (imageFile) {
                    uploadImage().then((response) => {
                        finalObj.image = response.data.data[0];
                        postDamage(finalObj, header);
                    }).catch((error) => {
                        //console.log(error);
                    })
                } else {
                    postDamage(finalObj, header);
                }

            }
        } else {
            toast.error(`${traduccion?.errores?.damageDesc}`);
        }
    }
    //console.log(damageObj)
    return (
        <>
            <ToastContainer />
            <div className={stylesSurv.title_surveyor} style={{marginBottom:15}}>
                {/*<div>{oneSpecie !== "" ? "Editar" : "Crear"} Empresa</div>*/}
                {oneDamage.description !== undefined ?
                    <div className={stylesSurv.title}>{traduccion?.mantendores?.editDanio}</div> :
                    <div className={stylesSurv.title}>{traduccion?.mantendores?.nuevoDanio}</div>
                }
            </div>
            <div style={{display:'flex', justifyContent:'left', width:'100%', marginTop:'2rem'}}>
                <div className={stylesSurv.all_surveyors}>
                    <div className={stylesSurv.title_surveyor} style={{justifySelf:'left', height:'1.5rem'}}>
                        {/*{oneDamage.description !== undefined ?*/}
                        {/*    <p className={stylesSurv.title}>Editar Daño</p> :*/}
                        {/*    <p className={stylesSurv.title}>Nuevo Daño</p>*/}
                        {/*}*/}
                    </div>

                    <div style={{display:'flex'}}>
                        <div>
                            <div style={{margin: '0 2rem'}}>{traduccion?.mantendores?.imgDanio}</div>
                            <div className={styles.photo} style={{width:200, margin: '.5rem 2rem'}}>
                                <label className={styles.photocon} htmlFor="file-input">
                                    <div>
                                        <img style={{backgroundColor:'transparent'}}
                                             src={damageImage}
                                             alt="damage_img"/>
                                    </div>
                                </label>
                                <input style={{display: 'none'}}
                                       onChange={handlePreviewImg}
                                       id="file-input" type="file" accept={"image/*"}
                                       name="image"/>

                            </div>
                        </div>
                        <div style={{margin:'0 1.5rem'}}>
                            <div>{traduccion?.mantendores?.nameDanio}</div>
                                <Form.Control
                                    value={damageObj.description}
                                    onChange={handleDamage}
                                    className={styles.selects_input_details}  type="text"
                                    placeholder={traduccion?.mantendores?.danios} name="description"/>

                            <div>{traduccion?.mantendores?.tipoDanio}</div>
                            <Form.Select
                                onChange={handleDamage}
                                className={styles.selects_input_details}
                                placeholder={traduccion?.mantendores?.danios} name="damage"
                            >

                                <option value="" defaultValue={true}>{traduccion?.clients?.selectOption}</option>
                                {fakeType.map((item,idx) => {
                                    return (
                                        <option key={idx} value={item.id} selected={oneDamage?.damage?.id === item.id}>{item.description}</option>
                                    )
                                })
                                }
                            </Form.Select>

                                <div>{traduccion?.mantendores?.severidad}</div>
                                <Form.Select
                                    onChange={handleDamage}
                                    className={styles.selects_input_details}
                                    placeholder={traduccion?.mantendores?.danios} name="severity">
                                    <option value="" defaultValue={true}>{traduccion?.mantendores?.sinSeveridad}</option>
                                    {fakeSeverity.map((item,idx) => {

                                        return (
                                            <option key={idx} value={item.id} selected={oneDamage.severity !== null && oneDamage?.severity?.id === item.id}>{item.description}</option>
                                        )
                                    })
                                    }
                                </Form.Select>


                            <div>{traduccion?.mantendores?.estado}</div>
                            <Form.Select
                                onChange={handleDamage}
                                className={styles.selects_input_details} id="estado" name="status">
                                <option value="" defaultValue={true}>{traduccion?.clients?.selectOption}</option>

                                {listaStatus.map((item,idx) => {
                                    return (
                                        <option key={idx} value={item.booleano} selected={oneDamage.status === item.booleano}>{item.description}</option>
                                    )
                                })}

                            </Form.Select>
                        </div>
                    </div>
                    <Button
                        onClick={handlePostDamage}
                        >
                        {traduccion?.mantendores?.save}
                    </Button>
                </div>
            </div>
        </>
    );
}
