import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Col, Container, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";
import axios from "axios";
import logo from './BOXClaims.png'
import styles from './login.module.css'
import {FaKey, FaLock, FaRegUserCircle} from "react-icons/fa";
import {BiMailSend} from "react-icons/bi";

import {Document, Page, pdfjs} from 'react-pdf';

import terms from './TC.pdf';

import {urlConfig} from "../../config/urlConfig";
import LangContext from "../../context/LangContext";
import {TresModulos} from "./TresModulos";
import spain from "../../components/header/spain.png";
import england from "../../components/header/united-kingdom.png";
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {toast, ToastContainer} from "react-toastify";

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const CaptchaDeGoogle = () => {
    //console.log('Llega el env?',process.env.REACT_APP_RECAPTCHA_KEY);

    return (
        <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
            scriptProps={{async: true, defer: true, appendTo: 'head'}}
            container={{ // optional to render inside custom element
                element: "captcha_id",
                parameters: {
                    badge: 'bottomleft', // optional, default undefined
                    theme: 'light', // optional, default undefined
                }
            }}
        >
            <Login/>

            <div id="captcha_id" style={{display: 'none'}}></div>
        </GoogleReCaptchaProvider>
    );
}

export const Login = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [token, setToken] = useState('');
    const [dynamicAction, setDynamicAction] = useState('login');
    // const [actionToChange, setActionToChange] = useState('');


    const {traduccion, handleLanguage} = useContext(LangContext);
    const [usuario, setUsuario] = useState({});
    const [unerror, setUnerror] = useState(false)
    const [loading, setLoading] = useState(false)
    const [landing, setLanding] = useState(true)
    const [forgotPass, setForgotPass] = useState(false);
    const [putCode, setPutCode] = useState(false);
    const [loadButton, setLoadButton] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [scrollBottom, setScrollBottom] = useState(false);
    const handleChange = (e) => {
        //console.log(e.target.value)
        //console.log(e.target.name)
        setUsuario({
            ...usuario, [e.target.name]: e.target.value
        })
    }


    const clickHandler = async () => {
        if (!executeRecaptcha) {
            return;
        }
        const result = await executeRecaptcha('login');
        setToken(result);

        iniciarSesion(result)
    }

    // const handleCommitAction = useCallback(() => {
    //     setDynamicAction(actionToChange);
    //
    // }, [actionToChange]);

    useEffect(() => {
        if (!executeRecaptcha || !dynamicAction) {
            return;
        }
        const handleReCaptchaVerify = async () => {
            const token = await executeRecaptcha(dynamicAction);
            setToken(token);
        };
        handleReCaptchaVerify();
    }, [executeRecaptcha, dynamicAction]);


    // //Listen Enter
    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                //console.log(usuario)
                iniciarSesion(token);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [usuario]);


    //Go back
    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    const onBackButtonEvent = () => {
        setLanding(true)
    }

    //console.log(usuario)
    const iniciarSesion = (token) => {
        setLoading(true)
        setUnerror(false)
        const finalUser = {
            "username": usuario.username,
            "password": usuario.password,
            "token": token
        }
        axios.post(urlConfig.authUrl, finalUser)
            .then(function (res) {
                setLoading(false)
                window.location.href = "/"
                if (res.data.token) {
                    localStorage.setItem("token", "Bearer " + res.data.token)
                }
            }).catch(function (err) {
            if (err.response.data.message === "You must accept terms and conditions") {
                toast.warn(`${traduccion?.warning?.mustAcceptTerms}`)
                setModalShow(true)
            }
            //console.log(err)
            setLoading(false)
            setUnerror(true)
        })
    }

    const handleForgotPass = () => {
        setLoading(false)
        setUnerror(false)
        setForgotPass(true)
    }
    const handleBackButton = () => {
        setLoading(false)
        setUnerror(false)
        setForgotPass(false)
        setPutCode(false)
    }

    const handleSendCode = (e) => {
        setLoadButton(true)
        const emailData = {"email": usuario.username}
        //console.log(emailData)
        axios.post(urlConfig.allUrl + `${urlConfig.portAccount}/api/v1/accounts/recovery`, emailData,
            {
                headers: {
                    "X-MAILTYPE": 'claims'
                }
            })
            .then(function (res) {
                if (res.data.data === "Password recovery email sent") {
                    toast.success(`${traduccion?.success?.mailSend}`)
                    setPutCode(true)
                    setForgotPass(false)
                    setLoadButton(false)
                }
            }).catch(function (err) {
            if (err.response.data.message === "User not found") {
                toast.error(`${traduccion?.errores?.imgNeed}`)
            } else {
                toast.error(`${traduccion?.errores?.userNot}`)
            }
            setLoadButton(false)

        })

        // setLoading(false)
        // setUnerror(false)
        // setForgotPass(false)
    }
    const handleRecovery = (e) => {
        const recover = {"email": usuario.username, "code": usuario.codigo}
        axios.post(urlConfig.allUrl + `${urlConfig.portAccount}/api/v1/accounts/recovery/confirm`, recover,
            {
                headers: {
                    "X-MAILTYPE": 'claims'
                }
            })
            .then(function (res) {
                //console.log(res)
                if (res.data.code === 200) {
                    toast.success(`${traduccion?.success?.codeConfirmed}`)
                    setForgotPass(false)
                    setPutCode(false)

                }
            }).catch(function (err) {
            //console.log(err)
        })
    }
    // const handleTextChange = useCallback(event => {
    //     setActionToChange(event.target.value);
    // }, []);
    useEffect(() => {
        //console.log(usuario)
    }, [usuario])
    // function onDocumentLoadSuccess({ numPages }) {
    //     setNumPages(numPages);
    // }
    const handleScroll = (e) => {
        let bottom = (e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop)
        if (bottom > 10) {
            setScrollBottom(false)
        } else {
            setScrollBottom(true)
        }
    }
    const handleHide = () => {
        setScrollBottom(false)
        setModalShow(false)
    }
    const handleLoginTerms = () => {
        const finalUser = {
            "username": usuario.username,
            "password": usuario.password,
            "token": token,
            "terms": true
        }
        axios.post(urlConfig.authUrl, finalUser)
            .then(function (res) {
                setLoading(false)
                window.location.href = "/"
                if (res.data.token) {
                    localStorage.setItem("token", "Bearer " + res.data.token)
                }
            }).catch(function (err) {
            //console.log(err)
        })
    }
    return (
        <>
            <ToastContainer/>
            <div className={styles.login_page}>
                <img src={logo} className={styles.imagen_logo} alt="logo"/>
                <p className={styles.welcome}>{traduccion.login?.titulo}</p>
                <div className={styles.login_box}>
                    <Form>

                        <div className={styles.login_box_inside} style={{margin: '0 .5rem'}}>
                            {!putCode &&
                                <div>
                                    <Form.Label className={styles.form_label}
                                                htmlFor="email">{traduccion.login?.correo}</Form.Label>
                                    <InputGroup className={styles.input_icon}>
                                        <InputGroup.Text>
                                            <FaRegUserCircle/>
                                        </InputGroup.Text>
                                        <Form.Control type="email"
                                                      className={styles.form_control}
                                                      name="username"
                                                      value={usuario.username}
                                                      onChange={handleChange}
                                        />
                                    </InputGroup>
                                </div>
                            }
                            {/*<input type="text" onChange={handleTextChange} value={actionToChange} />*/}
                            {forgotPass &&
                                <div style={{marginTop: 20, textAlign: 'center'}}>
                                    {traduccion.login?.ingreseMail}<br/>{traduccion.login?.ingreseMail2}
                                </div>

                            }
                            {putCode &&
                                <>
                                    <div style={{marginTop: 20, textAlign: 'center'}}>
                                        {traduccion.login?.ingreseCode}
                                    </div>
                                    <div>
                                        <Form.Label className={styles.form_label}
                                                    htmlFor="password">{traduccion.login?.codigo}</Form.Label>
                                        <InputGroup className={styles.input_icon}>
                                            <InputGroup.Text>
                                                <BiMailSend style={{fontSize: 25}}/>
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                className={styles.form_control}
                                                name="codigo"
                                                // value={usuario.password}
                                                onChange={handleChange}
                                                style={{fontSize: 25, width: '5ch'}}
                                            />
                                        </InputGroup>
                                    </div>

                                </>
                            }

                            {(!forgotPass && !putCode) &&
                                <div style={{marginTop: 20}}>
                                    <Form.Label className={styles.form_label}
                                                htmlFor="password">{traduccion.login?.pass}</Form.Label>
                                    <InputGroup className={styles.input_icon}>
                                        <InputGroup.Text>
                                            <FaKey/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="password"
                                            className={styles.form_control}
                                            name="password"
                                            value={usuario.password}
                                            onChange={handleChange}

                                        />
                                    </InputGroup>

                                    {/*<div className={styles.captcha}>*/}
                                    {/*    <span style={{fontSize:'.7rem'}} >This site is protected by reCAPTCHA and the Google </span>*/}
                                    {/*    <a style={{fontSize:'.7rem'}} href="https://policies.google.com/privacy">Privacy Policy</a><span style={{fontSize:'.7rem'}}> and </span>*/}
                                    {/*    <a style={{fontSize:'.7rem'}} href="https://policies.google.com/terms">Terms of Service</a> apply.*/}
                                    {/*</div>*/}
                                </div>
                            }


                            <div>
                                <div>{loading ? "Espere un momento..." : " "}</div>
                                <div>{unerror ? "Contraseña o usuario incorrecto" : " "}</div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                {(forgotPass && !putCode) &&
                                    <Button className={styles.boton} style={{marginRight: '2rem'}} id="botonLogin"
                                        //onClick={iniciarSesion}
                                            onClick={handleBackButton}
                                    >{traduccion.login?.atras}</Button>
                                }

                                {(!forgotPass && !putCode) &&
                                    <Button className={styles.boton} id="botonLogin"
                                        //onClick={iniciarSesion}
                                            onClick={clickHandler}
                                    >
                                        {traduccion.login?.submitP}
                                    </Button>
                                }
                                {(forgotPass && !putCode) &&
                                    <Button className={styles.boton} id="botonLogin" onClick={handleSendCode}>
                                        {loadButton ?
                                            <Spinner animation="border" role="status"/>
                                            : <>{traduccion.login?.sendPass}</>
                                        }
                                    </Button>
                                }
                                {
                                    (!forgotPass && putCode) &&
                                    <Button className={styles.boton} id="botonLogin" onClick={handleRecovery}>
                                        {loadButton ?
                                            <Spinner animation="border" role="status"/>
                                            : <>{traduccion.login?.resetPass}</>
                                        }
                                    </Button>
                                }
                            </div>
                        </div>
                    </Form>
                </div>
                <div className={styles.lang}>
                    {(localStorage.getItem("lang") === 'es') ?
                        <img src={spain} alt="es" style={{marginRight: 5, marginBottom: 4}}/> :
                        <img src={england} alt="en" style={{marginRight: 5, marginBottom: 4}}/>}
                    <select defaultValue={localStorage.getItem("lang")} name="language" onChange={handleLanguage}>
                        <option value="es">ES</option>
                        <option value="en">EN</option>
                    </select>
                </div>

                {(!forgotPass && !putCode) &&
                    <div className={styles.olvido_pass}
                         onClick={handleForgotPass}>
                        {traduccion.login?.olvidoPass}
                    </div>
                }
                {/*<div style={{color:'#bbb'}}>{!loading? "Usuario: nicolas.ortega@siigroup.cl - Contraseña: 1234": ""}</div>*/}
                <Row className={styles.fila}>
                    <Col style={{backgroundColor: '#E68E3D'}}></Col>
                    <Col style={{backgroundColor: '#86BC59'}}></Col>
                    <Col style={{backgroundColor: '#7671AE'}}></Col>
                </Row>
                {/*<Button className={styles.botonA} id="botonLogin"  variant="primary"  onClick={handleClickProject}>{traduccion.login?.atras}</Button>*/}
            </div>
            <Modal
                show={modalShow}
                onHide={handleHide}
                // size={width > 768 ? "md" : "sm"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                style={{justifyContent: 'center', alignItems: 'center'}}
                contentClassName={styles.modalContent}
            >
                <Modal.Header closeButton className={styles.modalParent}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {traduccion?.login?.terms}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display: 'contents'}}>
                    <Container style={{overflowY: 'scroll', height: 'fit-content', width: 'fit-content'}}
                               onScroll={handleScroll}>
                        <Document file={terms} style={{width: '100%'}}>
                            {[...Array(9)].map((x, i) =>
                                <Page key={i} pageNumber={i + 1}/>
                            )}
                        </Document>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={!scrollBottom}
                        onClick={handleLoginTerms}
                    >Confirmar</Button>

                    <Button variant="secondary"
                            onClick={handleHide}
                    >Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
