import { useState } from "react";

export const useForm = (initialForm, validateForm) => {
    const [form, setForm] = useState(initialForm);
    const [errors, setErrors] = useState({});

    const handleChangeForm = (e) => {
        //console.log(e.target.value)
        //console.log("Changeform")
        const { name, value } = e.target;
        let newForm = { ...form, [name]: value };

        if (e.target !== undefined) {
            setForm(newForm);
            setErrors(validateForm(newForm, e.target.name));
        }
    };

    const handleBlurForm = (e) => {
        //console.log("BlurForm")
        if (e.target !== undefined) {
            handleChangeForm(e);
            setErrors(validateForm(form, e.target.name));
        }
        else{
            setErrors(validateForm(form, e));
        }
    };

    const handleSubmitForm = (e) => {
        let objError = validateForm(form);
        //console.log({objError})
        if (Object.keys(objError).length === 0) {
            return true;
        } else {
            //console.log("Hay errores en el formulario");
            return false;
        }
    };

    return {
        form,
        errors,
        handleChangeForm,
        handleBlurForm,
        handleSubmitForm,
    };
};
