//prod
//let url= "https://www.rskgroupco.com" //BBDD Lightsail

//pre-prod
let url= "https://www.rsklog.com" //BBDD .tk 3306
//let url= "http://boxforclaims.rskgroupco.com" //BBDD .tk 3306

//dev
//let url = "http://54.235.87.30" // BBDD .tk 3307

//local
//let url = "http://127.0.0.1" // BBDD .tk 3307


// REMOTE
// let url = "https://ed48-2800-150-11e-2e87-1d35-5d69-c99c-d414.sa.ngrok.io"
// //ngrok
// let Naccount ="https://testsiigroup.pagekite.me"

let portCl=":9002"//:9002
let portAc=":9001"//:9001
let Naccount = url

export const  urlConfig = {
    portClaim:portCl,
    portAccount:portAc,
    urlKite:'https://testsiigroup.pagekite.me',
    allUrl:url,
    urlNgrok :"https://f01c-2800-150-11e-2e87-454d-a9ce-473e-3c3.sa.ngrok.io",
    local_url: url,
    //ratesUrl: `https://testsiigroup.pagekite.me/api/v1/country`,
    changePass:`${Naccount}${portAc}/api/v1/password`,
    accountAllUrl:`${Naccount}${portAc}/api/v1/accounts/`,
    accountUrl:`${Naccount}${portAc}/api/v1/accounts/me`,
    authUrl:`${Naccount}${portAc}/api/v1/accounts/auth`,
    profileUrl:`${Naccount}${portAc}/api/v1/accounts/profiles/`,
    ratesUrl: `${url}${portCl}/api/v1/country`,
    claimUrl:`${url}${portCl}/api/v1/claim/me`,
    claimUrlALL:`${url}${portCl}/api/v1/claim/all`,
    inspectorUrl:`${url}${portCl}/api/v1/inspector`,
    companyUrl:`${url}${portCl}/api/v1/company_surveyor/`,
    exporterUrl:`${url}${portCl}/api/v1/exporter`,
    especieUrl:`${url}${portCl}/api/v1/fruit/`,
    currencyUrl:`${url}${portCl}/api/v1/currency/`,
    damageUrl:`${url}${portCl}/api/v1/damage/`,
    filesUrl:`${url}:9004/api/v1/files`,
    searchSurveyorCompanyUrl:`${url}${portCl}/api/v1/inspector/company/search`,
    searchCompanySurveyorUrl:`${url}${portCl}/api/v1/company_surveyor/search`,
    searchSurveyorUrl:`${url}${portCl}/api/v1/inspector/search`,
    searchClaimExportUrl:`${url}${portCl}/api/v1/claim/search`,
    searchClaimUrl:`${url}${portCl}/api/v1/claim/me/search`,
    searchProfileUrl:`${url}${portAc}/api/v1/accounts/profiles`,
    stuffingUrl:`${url}:9003/api/v1/stuffings/me`,
};


