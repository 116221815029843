import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Circle, GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";
import {Typeahead} from "react-bootstrap-typeahead";
import usePlacesAutocomplete, {getGeocode, getLatLng,} from "use-places-autocomplete";
import {Form} from "react-bootstrap";
import style from "./map.module.css";


const Maps = (props) => {

    const {setValue, suggestions: {data}} = usePlacesAutocomplete();

    const [libraries] = useState(['places']);
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_APIKEY_GOOGLE, libraries
    });

    const [zoom, setZoom] = useState(13);
    const [addressDesc, setAddressDesc] = useState("");
    const [markerAnimation, setMarkerAnimation] = useState(2);
    const [loading, setLoading] = useState(true);

    const [range, setRange] = useState(props.range);
    const [address, setAddress] = useState({
        lat: props.latitude, lng: props.longitude
    })

    useEffect(() => {
        const {handleChange} = props;
        handleChange.handleChangeRange(range);
    }, [range])

    useEffect(() => {
        const {handleChange} = props;
        fetchGeo(address.lat, address.lng).then(res => {
            handleChange.handleChangeMapCountryCode(res.codePaisArr);
            handleChange.handleChangeCoords(address);
        }).catch(console.error)
    }, [address])

    useEffect(() => {
        getLocation();
    }, []);

    useEffect(() => {
        switch (true) {
            case range > 0 && range <= 3000:
                setZoom(13)
                break;
            case range > 3000 && range <= 30000:
                setZoom(10)
                break;
            case range > 30000 && range <= 60000:
                setZoom(9)
                break;
            case range > 60000 && range <= 100000:
                setZoom(8)
                break;
            case range > 100000 && range <= 230000:
                setZoom(7)
                break;
            case range > 230000 && range <= 400000:
                setZoom(6)
                break;
            case range > 400000 && range <= 900000:
                setZoom(5)
                break;
            case range > 900000 && range <= 1600000:
                setZoom(4)
                break;
            case range > 1600000:
                setZoom(1)
                break;
            default:
                setZoom(13)
        }

    }, [range]);

    const getLocation = () => {
        //console.log(window.location.href.includes("surveyors"),address, props.pag,window.location.href)
        if ((props.pag === 1 && !address) || (window.location.href.includes("surveyors") && (address.lat === 0 && address.lng === 0))) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((pos) => {
                    fetchGeo(pos.coords.latitude, pos.coords.longitude).then(res => {
                        setAddressDesc(res.address);
                        setAddress({lat: pos.coords.latitude, lng: pos.coords.longitude});
                    }).catch(console.error);
                });
            } else {
                //console.log("Geolocation is not supported by this browser/user.")
            }
        }
    }

    const handleChangeAddress = async (e) => {
        if (e.length === 0) return false;
        const results = await getGeocode({address: e[0].description});
        const coords = await getLatLng(results[0]);
        const {lat, lng} = coords;
        setAddress({lat: lat, lng: lng})
    }

    const handleChangeRange = (e) => {
        const value = e.target.value;
        const intValue = parseInt(value) * 1000;
        if (isNaN(intValue)) {
            setRange(0);
            return false;
        }
        setRange(intValue)
    }

    const handleClickMap = async (e) => {
        setAddress({lat: e.latLng.lat(), lng: e.latLng.lng()})
        clickAnim().then(r => endAnim());
    }


    const fetchGeo = async (lat, lng) => {
        setLoading(true);
        try {
            const {handleChange} = props;
            let codePaisArr;
            let formatedAddress;
            const res = await axios.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=" + process.env.REACT_APP_APIKEY_GOOGLE)
            if (res.data.results.length === 0) return false;
            if (res.data.results[res.data.results.length - 1].address_components[0].types.findIndex((e) => e === "country") !== -1) {
                codePaisArr = res.data.results[res.data.results.length - 1].address_components[0].short_name;
                formatedAddress = res.data.results[0].formatted_address;
                handleChange.handleChangeMapCountryCode(codePaisArr);
                handleChange.handleChangeAddress(formatedAddress)
                setAddressDesc(formatedAddress)
            }
            return {codePaisArr, address: res.data.results[0].formatted_address}
        } catch (ex) {
            //console.log(ex)
        }finally {
            setLoading(false);
        }
    }

    const startAnim = () => {
        setMarkerAnimation(1)
    }
    const endAnim = () => {
        setMarkerAnimation(2);
    }
    const clickAnim = async () => {
        setMarkerAnimation(4);
    }

    if (!isLoaded) return <div className={style.loader}></div>;


    return (<div>
        {loading ?

            <div style={{
                height: "50vh", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"
            }}>
                <div className={style.loader}></div>
            </div> :

            <>
                <div style={{marginBottom: 20}}>
                    <div>Find Address</div>
                    <Typeahead
                        style={{marginBottom: '1rem'}}
                        id="basic-typeahead"
                        labelKey={option => `${option.description}`}
                        onChange={handleChangeAddress}
                        onInputChange={e => setValue(e)}
                        placeholder={"Choose your location"}
                        key={addressDesc}
                        defaultInputValue={addressDesc}
                        options={data.map(({place_id, description}) => ({
                            place_id: place_id, description: description
                        }))}
                        filterBy={() => true}
                    />
                </div>
                <div style={{marginBottom: 20}}>
                    <div>Operation Range</div>
                    <Form.Control
                        type="text"
                        placeholder="Enter range"
                        value={range / 1000}
                        onChange={handleChangeRange}
                    />
                </div>
                <GoogleMap
                    zoom={zoom}
                    mapContainerStyle={{height: "600px", maxHeight: "50vh", width: "100%"}}
                    center={address}
                    onClick={handleClickMap}>
                    <Circle
                        center={address}
                        radius={range}
                        options={{
                            fillColor: "#003cff",
                            fillOpacity: 0.2,
                            strokeColor: "#003cff",
                            strokeOpacity: 1,
                            strokeWeight: 2,
                        }}
                    />
                    <Marker
                        clickable={true}
                        position={address}
                        animation={markerAnimation}
                        draggable={true}
                        onDragEnd={endAnim}
                        onDragStart={startAnim}
                    />
                </GoogleMap>
            </>}
    </div>);
};

export default Maps;
