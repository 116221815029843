import styles from "../surveyors/mantenedor_surveyor.module.css";
import stylesTabla from "../../home/table_stuffing/tabla_stuff.module.css";
import {Link} from "react-router-dom";
import {Button, Table} from "react-bootstrap";

import React, {useContext, useEffect, useRef, useState} from "react";
import {NewCountry} from "./NewCountry";
import LangContext from "../../../context/LangContext";
import DBContext from "../../../context/DBContext";
import {BsArrowCounterclockwise, BsEye, BsPencil, BsTrash} from "react-icons/bs";
import axios from "axios";
import {urlConfig} from "../../../config/urlConfig";
import {FaSortDown, FaSortUp} from "react-icons/fa";
import {toast} from "react-toastify";

export const Country = ({newCountryRate, setNewCountryRate, setRouteActive}) => {
    const [oneCountryRate, setOneCountryRate] = useState({});
    const {paisesRates, setCambioRates, cambioRates} = useContext(DBContext);
    const {traduccion} = useContext(LangContext);


    const initialCountry = useRef(true);
    const initialRateHr = useRef(true);
    const initialRateKm = useRef(true);
    const initialRateAddHr = useRef(true);

    const [country, setCountry] = useState(true);
    const [rateHr, setRateHr] = useState(true);
    const [rateKm, setRateKm] = useState(true);
    const [rateAddHr, setRateAddHr] = useState(true);

    const [countryList, setCountryList] = useState([]);

    const [url, setUrl] = useState(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/country?page=0`);


    const handleNewCountryRate = () => {
        setOneCountryRate({});
        setNewCountryRate(1)
    }
    const goToStart = () => {
        setRouteActive("/")
    }

    const editCountryRate = (id) => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        axios.get(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/country/${id}`, header).then((response) => {
            let dataRes = response.data
            setOneCountryRate(dataRes);
            setNewCountryRate(1)
        }).catch((error) => {
            console.log({error});
        })
    }

    const deleteCountryRate = (id) => {
        let currentCountryList = [...countryList];
        let header = {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        }
        axios.delete(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/country/${id}`, header).then((response) => {
            const indiceEliminar = currentCountryList.findIndex(c => c.id === id);
            if (indiceEliminar !== -1) {
                currentCountryList.splice(indiceEliminar, 1);
            }
            setCountryList(currentCountryList);
            toast.success(`${traduccion?.mantendores?.elimSuccess}`)
        }).catch((error) => {
            console.log({error});
            toast.error(`${traduccion?.mantendores?.elimError}`)
        })
    }

    useEffect(() => {
        if (initialCountry.current) {
            initialCountry.current = false;
            return;
        }
        if (!country) {
            if (!url.includes("sort=country,desc") && url.includes("sort=country,asc")) {
                setUrl(url.replace("&sort=country,asc", "&sort=country,desc"));
            } else {
                setUrl(url + "&sort=country,desc");
            }
        } else {
            if (!url.includes("sort=country,asc") && url.includes("sort=country,desc")) {
                setUrl(url.replace("&sort=country,desc", "&sort=country,asc"))
            } else {
                setUrl(url + "&sort=country,asc");
            }
        }
    }, [country]);

    useEffect(() => {
        if (initialRateHr.current) {
            initialRateHr.current = false;
            return;
        }
        if (!rateHr) {
            if (!url.includes("sort=basicRatePerHour,desc") && url.includes("sort=basicRatePerHour,asc")) {
                setUrl(url.replace("&sort=basicRatePerHour,asc", "&sort=basicRatePerHour,desc"));
            } else {
                setUrl(url + "&sort=basicRatePerHour,desc");
            }
        } else {
            if (!url.includes("sort=basicRatePerHour,asc") && url.includes("sort=basicRatePerHour,desc")) {
                setUrl(url.replace("&sort=basicRatePerHour,desc", "&sort=basicRatePerHour,asc"))
            } else {
                setUrl(url + "&sort=basicRatePerHour,asc");
            }
        }
    }, [rateHr]);

    useEffect(() => {
        if (initialRateKm.current) {
            initialRateKm.current = false;
            return;
        }
        if (!rateKm) {
            if (!url.includes("sort=ratePerKm,desc") && url.includes("sort=ratePerKm,asc")) {
                setUrl(url.replace("&sort=ratePerKm,asc", "&sort=ratePerKm,desc"));
            } else {
                setUrl(url + "&sort=ratePerKm,desc");
            }
        } else {
            if (!url.includes("sort=ratePerKm,asc") && url.includes("sort=ratePerKm,desc")) {
                setUrl(url.replace("&sort=ratePerKm,desc", "&sort=ratePerKm,asc"))
            } else {
                setUrl(url + "&sort=ratePerKm,asc");
            }
        }
    }, [rateKm]);

    useEffect(() => {
        if (initialRateAddHr.current) {
            initialRateAddHr.current = false;
            return;
        }
        if (!rateAddHr) {
            if (!url.includes("sort=ratePerAddHour,desc") && url.includes("sort=ratePerAddHour,asc")) {
                setUrl(url.replace("&sort=ratePerAddHour,asc", "&sort=ratePerAddHour,desc"));
            } else {
                setUrl(url + "&sort=ratePerAddHour,desc");
            }
        } else {
            if (!url.includes("sort=ratePerAddHour,asc") && url.includes("sort=ratePerAddHour,desc")) {
                setUrl(url.replace("&sort=ratePerAddHour,desc", "&sort=ratePerAddHour,asc"))
            } else {
                setUrl(url + "&sort=ratePerAddHour,asc");
            }
        }
    }, [rateAddHr]);


    useEffect(() => {
        let header = {
            headers: {
                Authorization: localStorage.getItem("token").replace("Bearer ", ""),
            }
        }
        axios.get(url, header).then((response) => {
            setCountryList(response.data);
            initialCountry.current = false;
            initialRateKm.current = false;
            initialRateHr.current = false;
            initialRateAddHr.current = false;
        }).catch((error) => {
            console.log({error})
        })
    }, [url]);

    const resetSort = () => {
        initialCountry.current = true;
        initialRateKm.current = true;
        initialRateHr.current = true;
        initialRateAddHr.current = true;
        setCountry(true);
        setRateHr(true);
        setRateKm(true);
        setRateAddHr(true);
        setUrl(`${urlConfig.allUrl}${urlConfig.portClaim}/api/v1/country?page=0`)
    }

    const sortParams = (sort) => {
        if (sort === "country") {
            setCountry(!country);
        }
        if (sort === "basicRatePerHour") {
            setRateHr(!rateHr);
        }
        if (sort === "ratePerKm") {
            setRateKm(!rateKm);
        }
        if (sort === "ratePerAddHour") {
            setRateAddHr(!rateAddHr);
        }
    }

    return (
        <>
            <div className={styles.all_surveyor}>
                <div className={styles.breadcrumb}>
                    {traduccion?.breadcrumb?.uRin}
                    <Link className={styles.like_a} to={"/"}>
                        <span onClick={goToStart}>{traduccion?.breadcrumb?.home}</span>
                    </Link>>
                    {newCountryRate === 0 &&
                        <span className={styles.bold}>{traduccion?.mantenedoresII?.countries}</span>
                    }
                    {(newCountryRate === 1 && oneCountryRate.id !== undefined) &&
                        <><span onClick={() => setNewCountryRate(0)}
                                className={styles.like_a}>{traduccion?.mantenedoresII?.countries}</span>>
                            <span className={styles.bold}>{traduccion?.mantenedoresII?.editCountry}</span></>
                    }
                    {(newCountryRate === 1 && oneCountryRate.id === undefined) &&
                        <><span onClick={() => setNewCountryRate(0)}
                                className={styles.like_a}>{traduccion?.mantenedoresII?.countries}</span>>
                            <span className={styles.bold}>{traduccion?.mantenedoresII?.newCountry}</span></>
                    }
                </div>
                {newCountryRate === 0
                    &&
                    <>
                        <div className={styles.title_surveyor}>
                            <p>{traduccion?.mantenedoresII?.newCountry}</p>
                            <Button className={styles.title_button} variant="primary"
                                    onClick={handleNewCountryRate}>{traduccion?.mantenedoresII?.newCountry}</Button>
                        </div>

                        <div className={stylesTabla.contiene_tabla}>
                            <Table borderless hover className={stylesTabla.tabla}>
                                <thead>
                                <tr>
                                    <th>{traduccion?.clients?.country} <a
                                        onClick={() => sortParams("country")}> {country ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th>{traduccion?.mantenedoresII?.basicRatePerHour} <a
                                        onClick={() => sortParams("basicRatePerHour")}> {rateHr ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th>{traduccion?.mantenedoresII?.ratePerKm} <a
                                        onClick={() => sortParams("ratePerKm")}> {rateKm ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th>{traduccion?.mantenedoresII?.rateperadditionalhour} <a
                                        onClick={() => sortParams("ratePerAddHour")}> {rateAddHr ? <FaSortUp/> :
                                        <FaSortDown/>}</a></th>
                                    <th><a onClick={() => resetSort()}><BsArrowCounterclockwise/></a></th>
                                </tr>

                                </thead>
                                <tbody>

                                {countryList.map((datos, idx) =>
                                    <tr key={idx} className={stylesTabla.tr_data}>
                                        <td>{datos.country}</td>
                                        <td>{datos.basicRatePerHour}</td>
                                        <td>{datos.ratePerKm}</td>
                                        <td>{datos.ratePerAddHour}</td>
                                        <td>
                                            <div className={stylesTabla.botones_botones_surveyor}
                                                 style={{display: 'flex'}}>
                                                <div onClick={() => editCountryRate(datos.id)}
                                                     className={stylesTabla.boton_icono}>
                                                    <BsPencil/>
                                                </div>
                                                <div
                                                    onClick={() => deleteCountryRate(datos.id)}
                                                    className={stylesTabla.boton_icono}>
                                                    <BsTrash style={{color: 'red'}}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}

                                </tbody>
                            </Table>
                        </div>
                    </>

                }

                {newCountryRate === 1 && <NewCountry oneCountryRate={oneCountryRate}/>}

            </div>
        </>
    )
}
